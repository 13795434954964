import { Colors, invertDark } from "../Assets/Config/color.config";
import { useTranslation } from 'react-i18next';
import Store from "../Store";

export const MyUploadImage = (props) => {
  const { _handleUploadImage, uploadLogoImage, photo, _resetImage, label, theme, width } = props;
  const {addDefaultSrc} = Store.common;
  const Theme=theme? theme: 'dark';

  const { t } = useTranslation("common");

  return (
    <span
      className="position-relative"
      style={{
        // background: Colors.darkBlue,
        width: width || '80%'
      }}
    >
      <div className="text-left" style={{background: Colors[Theme].secondBackground, borderRadius:5}}>
        <label
          style={{            
            width: "100%",
            height: photo ? 115 : 155,
            borderRadius: 5,
            cursor: "pointer",
            border: uploadLogoImage === "" ? `1px dashed ${invertDark(theme)}` : "",
          }}
          htmlFor="image-upload"
        >
          <input
            type="file"
            style={{ display: "none" }}
            id="image-upload"
            accept="image/x-png,image/gif,image/jpeg"
            onClick={(e)=> e.target.value=null}
            onChange={(e) => _handleUploadImage(e)}
          />
          {uploadLogoImage === "" ? (
            <span className="d-flex flex-column justify-content-center align-items-center h-100">
              <i style={{ fontSize: 30 }} className="bi bi-plus-lg"/>
              <span className="text-center" style={{opacity:0.5, color: invertDark(theme), fontSize: 14, whiteSpace:"normal"}}>{label || t("Add an Image") }</span>
            </span>
          ) : (
            <div className="d-flex justify-content-center" style={{height: '100%', position:'relative'}}>
              <img onError={addDefaultSrc} src={uploadLogoImage} width={'80%'} style={{marginTop: 5}} />
            </div>
          )}
        </label>
        {photo && (
        <div className="d-flex justify-content-center px-1">
          <span  style={{textOverflow: 'ellipsis', whiteSpace:'nowrap', overflow:'hidden'}}>
            {photo?.name}
            {!isNaN(photo.size)&&<div style={{ color: Colors.greenMaterial }}>
              {photo?.size / 1000}Kb
            </div>}
          </span>
        </div>
        )}
      </div>
      {uploadLogoImage !== "" && (
        <span
          onClick={() => _resetImage()}
          style={{
            position: "absolute",
            top: -7,
            right: 0,
            color: Colors.Dark[5],
            cursor: "pointer",
            background: "red",
            borderRadius: "50%",
            width: 15,
            height: 15,
          }}
          className="d-flex align-items-center justify-content-center"
        >
          <i className="bi bi-x m-1"></i>
        </span>
      )}
    </span>
  );
};
