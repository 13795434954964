import { inject, observer } from "mobx-react";
import { Colors } from "../../Assets/Config/color.config";
import { MyButton } from "../../Tools";
import rightArrow from "../../Assets/images/rightArrow.svg";
import { useTranslation } from 'react-i18next';
import './table.css';

export const FrameworkProtocolTbl = inject("store")(
  observer((props) => {
    const { theme } = props.store.common;
    const { tableHeaders, data } = props;
    const { t } = useTranslation("summary");
    
    return (
      <div className="p-2" 
      style={{
        boxShadow: "2px 4px 11px rgba(0, 0, 0, 0.25)",
        borderRadius: "10px",
        marginBottom: 8,
        color: "#c6c6c6"
      }}
      >
      <div
        className="frameworkProtocolTbl-scrollbar"
        style={{
          maxHeight: 180, overflow:"auto"
        }}
      >
        <table
          className="table mb-0 "
          style={{ color: Colors[theme].textColor, borderColor: "transparent", fontSize: 14 }}
        >
          <tr style={{ color: '#f0f0f0' }}>
            {tableHeaders.map((v,i) => (
              <th
              className="p-2"
                style={{
                  textAlign:i!==0&&'center',
                  fontSize: 15,
                  top:0, position: "sticky",
                  // padding: 8,
                  borderLeft: i!== 0 && " 1px solid",
                  backgroundColor: Colors[theme].tableBgColor,
                  borderLeftColor: i!== 0 &&
                    theme === "dark" ? "rgb(255, 255, 255,0.1)" : Colors.Dark[4],
                }}
              >
                {v}
              </th>
            ))}
          </tr>

          {data.map((v) => (
            <tr>
              <td align="start" className="p-2">
                {v["site"]}
              </td>
              <td align="center" className="my-1 p-2" style={{borderLeft: " 1px solid",
                borderLeftColor:
                  theme === "dark" ? "rgb(255, 255, 255,0.1)" : Colors.Dark[4]}}>
                {v["tracking"]}
              </td>
              <td align="center" className="my-1 p-2" style={{borderLeft: " 1px solid",
                borderLeftColor:
                  theme === "dark" ? "rgb(255, 255, 255,0.1)" : Colors.Dark[4]}}>
                {v["notYetTracking"]}
              </td>
              <td align="center" className="my-1 p-2" style={{borderLeft: " 1px solid",
                borderLeftColor:
                  theme === "dark" ? "rgb(255, 255, 255,0.1)" : Colors.Dark[4]}}>
                {v["notApplicable"]}
              </td>
              <td align="end" className="my-1 p-2" style={{borderLeft: " 1px solid",
                borderLeftColor:
                  theme === "dark" ? "rgb(255, 255, 255,0.1)" : Colors.Dark[4]}}>
                <span
                  style={{ padding: 5, color: Colors.water, cursor: "pointer" }}
                  data-bs-toggle="modal"
                  data-bs-target="#summaryModal"
                >
                  {t("Details")}
                  <img className="ps-2" src={rightArrow} />
                </span>
              </td>
            </tr>
          ))}
        </table>
      </div>
      </div>
      
    );
  })
);
