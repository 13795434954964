import { Selector } from "../../Tools";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { inject, observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import Store from "../../Store";
import { toJS } from "mobx";
import { baseURLImage, noImageUrl } from "../../API/URL";
import { useTranslation } from 'react-i18next';
import Navigator from "../../Tools/MyNavigator";
import { MyButton } from "../../Tools";
import { useEffect } from "react";
import { Cookies } from "react-cookie";

const FilterBar = (props) => {
  // const params = new URLSearchParams(window.location.search);
  const cookies = new Cookies();
  const userName = cookies.get("userName");

  const { addDefaultSrc, permittedBuilding, companyName, userId, groupStatus } = Store.common;
  const buildingName = permittedBuilding && permittedBuilding[0]?.buildingName;
  const { modules } = props;
  const { t } = useTranslation("dashboard");

  const { setSelectedYear, selectedYear, role, theme,siteModuleData, list,_handleGetYearOptions,minBaselineYear } = props.store.common;
  const navigate = useNavigate();

  useEffect(()=> {

    if (!siteModuleData) {
      return
    }
    const baselineYear = siteModuleData.baselineYear ?? minBaselineYear
    _handleGetYearOptions(baselineYear)
  }, [_handleGetYearOptions, minBaselineYear, siteModuleData])

  return (
    <>
      <Navigator
        pageName={
          <span className="">
            {role === "user" ? (
              <img
                crossorigin="anonymous"
                // onError={addDefaultSrc}
                className="me-2"
                src={
                  baseURLImage + permittedBuilding?.[0].photo
                }
                width={50}
              />
            ) : null}
            {role === "user" ? buildingName : t("Overview")}
          </span>
        }
        goTo={t('Back')}
        // handleBack = {() => window.history.back()}
        link={groupStatus ? '/home-group' : '/home'}
      />
      {/* <div>
        <span>
            {role === "user" ? (
              <img
              // onError={addDefaultSrc}
                className="me-2"
                src={
                  baseURLImage + permittedBuilding?.[0].photo
                }
                width={50}
              />
            ) : null}
            {role === "user" ? buildingName : t("Overview")}
        </span>
      </div> */}
      <div className={`pt-3 pb-4 d-flex ${modules?.Button ? 'justify-content-between' : 'justify-content-between'} pt-0 p-3 flex-wrap`} style={{ color: Colors[theme].textColor }}>
        {/* <span style={{ fontSize: 20 }}><img src={"/sites/conrad.png"} width={50} />Overview</span> */}
        <div className="d-flex">
          {modules?.Button ? <span className="me-2">
            <MyButton
              // text={modules.Button}
              text={t(modules?.Button)}
              onClick={() => { navigate(modules.Button === 'Summary' ? '/summary' : '/year-to-year-comparison') }}
              id={"button"}
              style={{ width: "100%", marginBottom: 10 }}
              customColor={theme === "dark" ? Colors.lightBlue : Colors.blue}
              customHoverColor={theme === "dark" ? '#35469b' : '#0148AE'} />
          </span> : null}
          {modules?.Button1 ? <span className="me-2">
            <MyButton
              text={t('Summary')}
              onClick={() => { navigate('/summary') }}
              id={"button"}
              style={{ width: "100%", marginBottom: 10 }}
              customColor={theme === "dark" ? Colors.lightBlue : Colors.blue}
              customHoverColor={theme === "dark" ? '#35469b' : '#0148AE'} />
          </span>: null}
          {modules?.Button2 ? <span className="me-2">
            <MyButton
              text={t('Analysis Report')}
              onClick={() => { navigate('/analysis-report') }}
              id={"button"}
              style={{ width: "100%", marginBottom: 10 }}
              customColor={theme === "dark" ? Colors.lightBlue : Colors.blue}
              customHoverColor={theme === "dark" ? '#35469b' : '#0148AE'} />
          </span>: null}
        </div>
        <span
          style={{ width: 300 }}
          className={`d-flex ${role === 'viewer' ? 'justify-content-end' : 'justify-content-between'} flex-wrap`}
        >
          <Selector
            menuListColor={Colors.Dark[0]}
            isSearchable={false}
            options={companyName === 'Digital Edge' ? list.filter(ls=> Number(ls.label) > 2019) : list}
            value={list.filter((option) => option.label == selectedYear)}
            _handleSelect={(e) => setSelectedYear(e.value)}
          />
          {
            role !== 'viewer' ?
              <span
                style={{
                  border: `1px solid ${Colors.Dark[4]}`,
                  borderRadius: 5,
                  width: window.screen.width < 330 && 200,
                  marginTop: window.screen.width < 330 && 10,
                  cursor: "pointer",
                }}
                onClick={() => navigate("/target-setting")}
                className="d-flex align-items-center"
              >
                <i className="bi bi-sliders px-3"></i>
                <span className="pe-3 m-1">{t("Target Setting")}</span>
              </span>

              : null
          }
        </span>
      </div>
    </>
  );
};

export default inject("store")(observer(FilterBar));
