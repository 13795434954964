import * as API from "./URL";

export const fetchAnswer = async (params, data, callback) => {
  return fetch(API.addAnswer(params), {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
      // "token": `${token}`
    },
    body: JSON.stringify(data)
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err.toString(), null));
};

export const fetchManualByCategoryAnswer = async (params, data, callback) => {
  return fetch(API.addManualByCategoryAnswer(params), {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
      // "token": `${token}`
    },
    body: JSON.stringify(data)
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err.toString(), null));
};