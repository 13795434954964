import * as API from "./URL";

export const fetchAnalysisReport = async (year, callback) => {
  return fetch(API.getAnalysisReportAPI(year), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err.toString(), null));
};

export const fetchDyEnergyComparison = async (callback) => {
  return fetch(API.getReportDyEnergyComparison, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
      // "token": `${token}`
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err.toString(), null));
}

export const fetchDyBaselineEmission = async (url,callback) => {
  return fetch(API.getReportDyBaselineEmission(url), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
      // "token": `${token}`
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err.toString(), null));
}

export const fetchDyGHGVerification = async (callback) => {
  return fetch(API.getReportDyGHGVerification, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
      // "token": `${token}`
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err.toString(), null));
}

export const fetchDyGHGEmissionAndTarget = async (callback) => {
  return fetch(API.getReportDyGHGEmissionAndTarget, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
      // "token": `${token}`
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err.toString(), null));
}

export const fetchDyAnalysisGHGEmission = async (year,callback) => {
  return fetch(API.getReportDyAnalysisGHGEmission(year), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
      // "token": `${token}`
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err.toString(), null));
}



export const fetchDyAssetAndEmissionFactor = async (callback) => {
  return fetch(API.getReportDyAssetAndEmissionFactor, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
      // "token": `${token}`
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err.toString(), null));
}

export const fetchReportConfiguration = async (callback) => {
  return fetch(API.getReportConfiguration, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
      // "token": `${token}`
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err.toString(), null));
}

export const fetchReportCustomConfig = async (templateName, callback) => {
  return fetch(API.getReportCustomConfig(templateName), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err.toString(), null));
}

export const fetchGetDefaultTempNames = async ( callback) => {
  return fetch(API.getDefaultTempNames, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err.toString(), null));
}


export const fetchUpdateCustomCom = async (body, callback) => {
  return fetch(API.UpdateCustomCom, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify(body),
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};



export const fetchDeleteCustomTempCom = async (body, callback) => {
  return fetch(API.DeleteCustomTempCom, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify(body),
  })
  .then((res) => res.json())
  .then((data) => callback(null, data))
  .catch((err) => callback(err.toString(), null));
}


export const updateReportTemplate = async (action,data, callback) => {

  return fetch(action === "Save As"? API.createReportTemplate:API.editReportTemplate, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err.toString(), null));
};

export const deleteReportTemplate = async (data, callback) => {
  return fetch(API.deleteReportTemplate, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err.toString(), null));
};