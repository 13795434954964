import React,{useState,useEffect} from "react";
import {

    ComposedChart,
    Line,
    Area,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    Scatter,
    ResponsiveContainer,
} from "recharts";

import { Colors, invertDark } from "../../Assets/Config/color.config";
import { inject, observer } from "mobx-react";
import ReactDOMServer from "react-dom/server";

export const DyScopeYearlyChart = (props) => {

        const theme = 'light';
        const data = props.data.chartData.data;
        
          const CustomTooltip = ({ active, payload, label }) => {
            if (active) {

                return (
                    <div style={{
                        background: 'white',
                        border: '1px solid #ccc',
                        padding: 10
                    }}>
                        <p className="label" style={{ textAlign: 'center' }}>{`${label}`}</p>
                        {
                            payload.map(d => (
                                <div key={d}>
                                    <p style={{ color: d.color }}>{`${d.dataKey} : ${d.value}`}</p>
                                </div>
                            ))
                        }
                    </div>
                );
            }

            return null;
        };

        const colors =[
          "#1273DE",
          "#ff7300",
          "rgb(155, 155, 160)",
          "rgb(238, 238, 30)"
        
        ]

        const years =[
          2020,
          2021,
          2022,
          2023
        
        ]
   
        const formatTick = (value) => {
            if (value >= 1000000000) {
              return `${(value / 1000000000).toFixed(2)}B`;
            } else if (value >= 1000000) {
              return `${(value / 1000000).toFixed(2)}M`;
            } else if (value >= 1000) {
              return `${(value / 1000).toFixed(2)}K`;
            } else {
              return value;
            }
          };


        const CustomizedYAxisTick = (props) => {
            const { x, y, payload } = props;
            return (
              <text x={x} y={y} dy={0} fontSize={12} textAnchor="end" fill="#666">
                {formatTick(payload.value)}
              </text>
            );
          };
        

        return (
            <div style={{ textAlign:'center',marginBottom: 20, border: '1px solid #E5DFDF', padding: 10 }}>
                {/* <div className="d-flex" style={{ fontSize: 22, justifyContent: 'center', color: 'gray', margin: 10 }}>
                    {("Consumption & Emission for Year 2020")}
                </div> */}
                <ResponsiveContainer width={"100%"} height={300} style={{ background: Colors.white, color: invertDark(theme) }}>
                    <ComposedChart
                        width={500}
                        height={300}
                        data={data}
                       
                      
                    >
                        <CartesianGrid stroke="#f5f5f5" strokeDasharray="3 3" />

                        <XAxis dataKey="name"/>
                        <YAxis tick={CustomizedYAxisTick}/>
                       
                        <Tooltip />
                        <Legend />

                        {
                      data?.map((v,k)=> (
                          <Bar  barSize={30} key={k} dataKey={years[k]} fill={colors[k]} />
                      ))
                  }


                    </ComposedChart>
                </ResponsiveContainer>
            </div>
        );
};




// export const DyScopeYearlyChart = (props) => {
//   const [chartHtmlState, setChartHtmlState] = useState('');

//   useEffect(() => {
//     const chartHtml = ReactDOMServer.renderToStaticMarkup(
//       <DynamicChart data={props.data} />
//     );
//     setChartHtmlState(chartHtml);
//   }, [props.data]);


//   return <div dangerouslySetInnerHTML={{ __html: chartHtmlState }} />;
// };