import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  CartesianGrid,
} from "recharts";

import { Colors, invertDark } from "../../Assets/Config/color.config";
import ReactDOMServer from "react-dom/server";
import React, { useRef, useState, useEffect } from "react";
import { LineChart, Line } from "recharts";

export const DynamicGhGChart = (props) => {
  const chartRef = useRef(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [delay, setDelay] = useState(1000);
  const data = props.data.chartData.data;
  const theme = "light";
 




  const chartData = data? data.reduce((r, c) => {
          const R = [...r];
          for (let dt of c.data) {
            if (dt.year !== "Baseline Year") {
              const i = R.findIndex((v) => v.year === dt.year);
              if (i < 0) {
                R.push({
                  year: dt.year,
                  [c.label || c.target]: dt.value || 0,
                });
              } else {
                R[i][c.label || c.target] = dt.value || 0;
              }
            }
          }
          return R;
        }, [])
        ?.sort((a, b) => a.year - b.year)
    : [];
  //  console.log('chart data ===> ', chartData)

  const COLORS = [
    "#1273DE",
    "#ff7300",
    "rgb(155, 155, 160)",

    // "#663567",
    // "#B04E6F",
    // "#E77866",
    // "#607d8b",
    // "#ffeb3b",
    // "#1273DE",
    // "#388e3c",
    // "#7b64ff",
    // "#86442d",
    // "#7bdcb5",

    // color:#663567;
    // color:#B04E6F;
    // color:#E77866;
    // color:#607d8b;
    // color:#ffeb3b;
    // color:#1273DE;
    // color:#388e3c;
    // color:#7b64ff;
    // color:#86442d;
    // color:#7bdcb5;
  ];

  const formatTick = (value) => {
    if (value >= 1000000000) {
      return `${(value / 1000000000).toFixed(2)}B`;
    } else if (value >= 1000000) {
      return `${(value / 1000000).toFixed(2)}M`;
    } else if (value >= 1000) {
      return `${(value / 1000).toFixed(2)}K`;
    } else {
      return value;
    }
  };

  const CustomizedYAxisTick = (props) => {
    const { x, y, payload } = props;
    return (
      <text x={x} y={y} dy={0} fontSize={12} textAnchor="end" fill="#666">
        {formatTick(payload.value)}
      </text>
    );
  };

  return (
    <div style={{ border: "1px solid #E5DFDF", padding: 10,textAlign:'center' }}>
       <div
          className="d-flex"
          style={{
            fontSize: 22,
            justifyContent: "center",
            color: "gray",
            margin: 10,
          }}
        >
          {"GHG Emission"}
        </div>
        <ResponsiveContainer
          width={"100%"}
          height={300}
          style={{
            background: theme === "dark" ? Colors.darkBlue : Colors.white,
            color: invertDark(theme),
          }}
        >
          <BarChart width={500} height={300} data={chartData} style={{}}>
            <CartesianGrid stroke="#f5f5f5" strokeDasharray="3 3" />
            <XAxis dataKey="year" />
            <YAxis tick={CustomizedYAxisTick}/>
            <Tooltip cursor={{ fill: "rgba(212,212,212,0.2)" }} />
            <Legend />

            {data?.map((v, k) => (
              <Bar
                barSize={30}
                key={k}
                dataKey={v.label || v.target}
                fill={COLORS[k]}
              />
            ))}
          </BarChart>
        </ResponsiveContainer>
    </div>
  );
};


// export const DyScopeYearlyChart = (props) => {
//   const [chartHtmlState, setChartHtmlState] = useState('');

//   useEffect(() => {
//     const chartHtml = ReactDOMServer.renderToStaticMarkup(
//       <DynamicChart data={props.data} />
//     );
//     setChartHtmlState(chartHtml);
//   }, [props.data]);


//   return <div dangerouslySetInnerHTML={{ __html: chartHtmlState }} />;
// };