import { inject, observer, useAsObservableSource } from "mobx-react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import Store from "../../Store";
import "../../App.css";
import {
  useEffect,
  useRef,
  React,
  useState,
  Children,
  isValidElement,
  cloneElement,
} from "react";

import SearchBar from "material-ui-search-bar";
import { useTranslation } from "react-i18next";
import Navigator from "../../Tools/MyNavigator";
import { Wrapper } from "@googlemaps/react-wrapper";
import { createCustomEqual } from "fast-equals";
import { isLatLngLiteral } from "@googlemaps/typescript-guards";
import { baseURLImage } from "../../API/URL";
import buildingImage from "../../Assets/images/building.jpeg";
import Location from "../../Assets/images/location.png";
import { noImageUrl } from "../../API/URL";
import { active, map } from "d3";

const GOOGLD_API_KEY = "AIzaSyAcWK8WHabUh0BMDZuIIPo0qfWXWarBzoo";

const google = window.google;

var searched = '';

const SiteLocationContainer = () => {

  const { getBuildingList, permittedBuilding, theme, addDefaultSrc } = Store.common;
  const { t } = useTranslation("site_location");

  const [zoom, setZoom] = useState(2); // initial zoom
  const [center, setCenter] = useState({
    lat: 0,
    lng: 0,
  });
  const [selectedCard, setSelectedCard] = useState(null);
  const [permittedBuildingFilter, setPermittedBuildingFilter] = useState([]);

  useEffect(() => {
    getBuildingList();
  }, []);

  const requestSearch = (searchedVal) => {
    if (!searchedVal) {
      cancelSearch();
      return;
    }
    setPermittedBuildingFilter(permittedBuilding.filter(x => {
      return (
        x.buildingName?.toLowerCase().includes(searchedVal.toLowerCase()) ||
        x.city?.toLowerCase().includes(searchedVal.toLowerCase()))
    }));
  };

  const cancelSearch = () => {
    document.getElementById('modal').innerHTML = null;
    setPermittedBuildingFilter([]);
  };

  const onClick = (data) => {
    document.getElementById('modal').innerHTML = null;
  };

  const onIdle = (m) => {
    setZoom(m.getZoom());
    setCenter(m.getCenter().toJSON());
  };


  const showDetail = (siteinfo) => {
    setSelectedCard(siteinfo);
    var contentString = `
    <div id="info" style="width: 300px">
    <div style="width: 100%; text-align: center; height: 200px">
      <img crossorigin="anonymous"  onerror="this.src='${noImageUrl}'" src="${baseURLImage}${siteinfo.photo.replaceAll("\\", "/")}" width="100px" style="margin-top: 30%"/>
    </div>
      <div style="padding: 10px; color: white;font-weight: bold;">
      <div style="display: flex">
        <h5 style="margin-top: 15px; margin-left: 15px;">${siteinfo.buildingName}</h5>
        </div>
        <div style="fontsize: 12px; margin: 10px 0px; border-bottom: 1px solid #9b9b9b;
        padding-bottom: 10px;">
        <img style="margin-bottom: 5px" onError=${addDefaultSrc}
              src=${Location}
              width="20px" height="20px"
            />
            ${siteinfo?.city ? siteinfo.city : ''},${siteinfo?.country ? siteinfo.country : ''}
        </div>
        <div>
          <div style="margin-bottom: 10px">Frameworks</div>
          <div>
            ${siteinfo.surveyHeader.map((x) => {
      return `
                <div style="float: left; margin-right: 10px">
                <img style="margin-bottom: 5px" crossorigin="anonymous"
              src="${baseURLImage}${x.imageURL.replaceAll("\\", "/")}"
              width="50px" height="50px"
            /><br>
            <span style="padding: 5px; font-size: 12px;">${x.name}</span>
          </div>`;
    })}
      </div>
  </div>
  </div>
  <button onclick="document.getElementById('modal').innerHTML = null;" draggable="false" aria-label="Close" title="Close" type="button" class="gm-ui-hover-effect" style="background: none; display: block; border: 0px; margin: 0px; padding: 0px; text-transform: none; appearance: none; position: absolute; cursor: pointer; user-select: none; top: -6px; right: -6px; width: 30px; height: 30px;"><span style="-webkit-mask-image: url(&quot;data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20d%3D%22M19%206.41L17.59%205%2012%2010.59%206.41%205%205%206.41%2010.59%2012%205%2017.59%206.41%2019%2012%2013.41%2017.59%2019%2019%2017.59%2013.41%2012z%22/%3E%3Cpath%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22/%3E%3C/svg%3E&quot;); pointer-events: none; display: block; width: 14px; height: 14px; margin: 8px;"></span></button>
  </div>`
  
  var iwindow= new window.google.maps.InfoWindow({closeBoxMargin: "10px 20px 2px 2px",
  content: '',
  pixelOffset: new window.google.maps.Size(-140, 180)});
  iwindow.setContent(contentString);
  globalMap.setCenter({lat: siteinfo.lat, lng: siteinfo.lng}); 
  if (activeInfoWindow) { activeInfoWindow.close(); }
  iwindow.open({anchor: globalMarker}); 
  activeInfoWindow = iwindow;
  }

  return (
    <>
      <Navigator
        pageName={t("Site Location")}
        goTo={t("Back")}
        handleBack = {() => window.history.back()}
      />
      <div className="m-3 mt-3" style={{ color: invertDark(theme) }}>
        <div className="row d-flex justify-content-center">
          <div style={{ display: "flex", height: "100vh" }}>
            <div
              style={{
                background: "rgb(0,0,0,0.5)",
                position: "absolute",
                zIndex: 3000,
                padding: 12
              }}
            >
              <SearchBar
                // className={classes.customTextField}
                placeholder={t("Search_")}
                style={{
                  width: "100%",
                  height: 35,
                  background: "transparent",
                  boxShadow: "none",
                  borderBottom: `1px solid ${invertDark(theme)}`,
                  borderRadius: 0,
                }}
                value={searched}
                onChange={(searchVal) => requestSearch(searchVal)}
                onCancelSearch={() => cancelSearch()}
              />
              {permittedBuildingFilter && permittedBuildingFilter.length > 0 ? (
                <ul style={{ listStyleType: 'none', paddingLeft: 10, height: '90vh', overflowY: 'auto'}}>
                  {permittedBuildingFilter.map((x, i) => {
                    return (
                      <li key={i} style={{ marginTop: 12, cursor: 'pointer' }} className={selectedCard == x ? 'selectedColor' : theme == 'light' ? 'whiteColor' : null} onClick={() => showDetail(x)}>
                        <img key={i} onError={addDefaultSrc} crossorigin="anonymous"
                          style={{ marginBottom: '5px', marginRight: 12, borderRadius: '10%' }}
                          src={baseURLImage + '/' + x.photo}
                          width={50}
                        />
                        {x.buildingName}
                      </li>
                    );
                  })}
                </ul>
              ) : (
                <></>
              )}
            </div>
            <div
              id="modal"
              style={{
                background: "rgb(0,0,0,0.5)",
                position: "absolute",
                zIndex: 4000,
                width: 300,
                marginLeft: 290,
                marginTop: 46
              }}
            >
            </div>
            <Wrapper apiKey={GOOGLD_API_KEY} render={render}>
              <Map
                center={center}
                onClick={(data) => onClick(data)}
                onIdle={onIdle}
                zoom={zoom}
                style={{ flexGrow: "1", height: "100vh" }}
              >
                {permittedBuilding &&
                  permittedBuilding.map((siteinfo, i) => (
                    <Marker
                      key={i}
                      requestSearch={requestSearch}
                      position={siteinfo}
                      options={siteinfo}
                      title={siteinfo.buildingName}
                      label={siteinfo.buildingName ? siteinfo.buildingName[0] : null}
                    />
                  ))}
              </Map>
            </Wrapper>
            {/* Basic form for controlling center and zoom of map. */}
          </div>
        </div>
      </div>
    </>
  );
};

let x = null;
let y = null;
var activeInfoWindow;
var globalMarker;
const Marker = (options) => {
  const [marker, setMarker] = useState();
  useEffect(() => {
    if (!marker) {
      setMarker(new window.google.maps.Marker());
    }
    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);
  useEffect(() => {
    if (marker) {
      marker.setOptions({ options });
      globalMarker = marker;
    }
  }, [marker, options]);

  var infowindow = new window.google.maps.InfoWindow({
    closeBoxMargin: "10px 20px 2px 2px",
    content: '',
    pixelOffset: new window.google.maps.Size(-140, 180),
  });

  const siteinfo = options.options;
  marker?.addListener("click", event => {
    if (!(x === event.pixel.x && y === event.pixel.y)) {
      if (activeInfoWindow) { activeInfoWindow.close(); }
      infowindow.setContent(contentString);
      infowindow.open({
        anchor: marker,
        shouldFocus: true,
      });
      activeInfoWindow = infowindow;
      x = event.pixel.x;
      y = event.pixel.y;
    }
  });

  const contentString = `
  <div style="width: 300px">
  <div style="width: 100%; text-align: center; height: 200px">
  <img onerror="this.src='${noImageUrl}'" crossorigin="anonymous" src="${baseURLImage}${siteinfo.photo.replaceAll("\\", "/")}" width="100px" style="margin-top: 30%"/>
</div>
    <div style="padding: 10px; color: white;font-weight: bold;">
    <div style="display: flex">
      <h5 style="margin-top: 15px; margin-left: 15px;">${siteinfo.buildingName}</h5>
      </div>
      <div style="fontsize: 12px; margin: 10px 0px; border-bottom: 1px solid #9b9b9b;
      padding-bottom: 10px;">
      <img style="margin-bottom: 5px"
            src=${Location}
            width="20px" height="20px"
          />
        ${siteinfo?.city ? siteinfo.city : ''},${siteinfo?.country ? siteinfo.country : ''}
      </div>
      <div>
        <div style="margin-bottom: 10px">Frameworks</div>
        <div>
          ${siteinfo.surveyHeader.map((x) => {
    return `
              <div style="float: left; margin-right: 10px">
              <img style="margin-bottom: 5px" crossorigin="anonymous"
            src="${baseURLImage}${x.imageURL.replaceAll("\\", "/")}"
            width="50px" height="50px"
          /><br>
          <span style="padding: 5px; font-size: 12px;">${x.name}</span>
        </div>`;
  })}
    </div>
</div>
</div>
</div>`;
  return null;
};

const render = (status) => {
  return <h1>{status}</h1>;
};

function useDeepCompareEffectForMaps(callback, dependencies) {
  useEffect(callback, dependencies.map(useDeepCompareMemoize));
}

function useDeepCompareMemoize(value) {
  const ref = useRef();

  if (!deepCompareEqualsForMaps(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

const deepCompareEqualsForMaps = createCustomEqual((deepEqual) => (a, b) => {
  if (
    isLatLngLiteral(a) ||
    a instanceof google.maps.LatLng ||
    isLatLngLiteral(b) ||
    b instanceof google.maps.LatLng
  ) {
    return new google.maps.LatLng(a).equals(new google.maps.LatLng(b));
  }

  // TODO extend to other types

  // use fast-equals for other objects
  return deepEqual(a, b);
});

let prevTheme = '';
var globalMap;
const Map = ({ onClick, onIdle, children, style, ...options }) => {
  const { theme } = Store.common;
  const ref = useRef(null);
  const [map, setMap] = useState();

  // useEffect(() => {
  if ((ref.current && !map) || (map && prevTheme != theme)) {
    setMap(
      new window.google.maps.Map(ref.current, {
        disableDefaultUI: true,
        styles: theme != 'light' ? [
          {
            elementType: "geometry",
            stylers: [
              {
                color: "#1d2c4d",
              },
            ],
          },
          {
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#8ec3b9",
              },
            ],
          },
          {
            elementType: "labels.text.stroke",
            stylers: [
              {
                color: "#1a3646",
              },
            ],
          },
          {
            featureType: "administrative.country",
            elementType: "geometry.stroke",
            stylers: [
              {
                color: "#4b6878",
              },
            ],
          },
          {
            featureType: "administrative.land_parcel",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#64779e",
              },
            ],
          },
          {
            featureType: "administrative.province",
            elementType: "geometry.stroke",
            stylers: [
              {
                color: "#4b6878",
              },
            ],
          },
          {
            featureType: "landscape.man_made",
            elementType: "geometry.stroke",
            stylers: [
              {
                color: "#334e87",
              },
            ],
          },
          {
            featureType: "landscape.natural",
            elementType: "geometry",
            stylers: [
              {
                color: "#023e58",
              },
            ],
          },
          {
            featureType: "poi",
            elementType: "geometry",
            stylers: [
              {
                color: "#283d6a",
              },
            ],
          },
          {
            featureType: "poi",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#6f9ba5",
              },
            ],
          },
          {
            featureType: "poi",
            elementType: "labels.text.stroke",
            stylers: [
              {
                color: "#1d2c4d",
              },
            ],
          },
          {
            featureType: "poi.park",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#023e58",
              },
            ],
          },
          {
            featureType: "poi.park",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#3C7680",
              },
            ],
          },
          {
            featureType: "road",
            elementType: "geometry",
            stylers: [
              {
                color: "#304a7d",
              },
            ],
          },
          {
            featureType: "road",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#98a5be",
              },
            ],
          },
          {
            featureType: "road",
            elementType: "labels.text.stroke",
            stylers: [
              {
                color: "#1d2c4d",
              },
            ],
          },
          {
            featureType: "road.highway",
            elementType: "geometry",
            stylers: [
              {
                color: "#2c6675",
              },
            ],
          },
          {
            featureType: "road.highway",
            elementType: "geometry.stroke",
            stylers: [
              {
                color: "#255763",
              },
            ],
          },
          {
            featureType: "road.highway",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#b0d5ce",
              },
            ],
          },
          {
            featureType: "road.highway",
            elementType: "labels.text.stroke",
            stylers: [
              {
                color: "#023e58",
              },
            ],
          },
          {
            featureType: "transit",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#98a5be",
              },
            ],
          },
          {
            featureType: "transit",
            elementType: "labels.text.stroke",
            stylers: [
              {
                color: "#1d2c4d",
              },
            ],
          },
          {
            featureType: "transit.line",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#283d6a",
              },
            ],
          },
          {
            featureType: "transit.station",
            elementType: "geometry",
            stylers: [
              {
                color: "#3a4762",
              },
            ],
          },
          {
            featureType: "water",
            elementType: "geometry",
            stylers: [
              {
                color: "#0e1626",
              },
            ],
          },
          {
            featureType: "water",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#4e6d70",
              },
            ],
          },
        ] : [],
      })
    );
  }
  prevTheme = theme;
  // }, [ref, map]);

  // because React does not do deep comparisons, a custom hook is used
  // see discussion in https://github.com/googlemaps/js-samples/issues/946
  useDeepCompareEffectForMaps(() => {
    if (map) {
      map.setOptions(options);
      globalMap = map;
    }
  }, [map, options]);

  useEffect(() => {
    if (map) {
      if (onClick) {
        map.addListener("click", onClick);
      }

      if (onIdle) {
        map.addListener("idle", () => onIdle(map));
      }
    }
  }, [map, onClick, onIdle]);

  return (
    <>
      <div ref={ref} style={style} />
      {Children.map(children, (child) => {
        if (isValidElement(child)) {
          // set the map prop on the child component
          return cloneElement(child, { map });
        }
      })}
    </>
  );
};
export default inject("store")(observer(SiteLocationContainer));
