import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import { useEffect } from "react";
import { MyMatrixTable, MyCollapsibleTable, MyDynamicTable } from "../../Tools";
import { useTranslation } from "react-i18next";
import { MonthlyDynamicTable } from "../../Tools";
import QuarterlyDynamicTable from "../../Tools/QuarterlyDynamicTable";
import { EquipmentModal } from "./EquipmentModal";
import { PurchasedElectricityModal } from "./purchaseElectricityModal";
import moment from "moment";
import { EntryDataByCateTbl } from "./EntryDataByCateTable";
import { CustomInputs } from "./CustomInputs";

export const Input = inject("store")(
  observer((props) => {
    const {
      id,
      type,
      options,
      category,
      unit,
      questions,
      childSections,
      equipmentData,
      header,
    } = props;
    const {viewStatus} = props.store.common;
    const {
      subSectionIndex,
      subSectionArray,
      surveyInfo,
      sectionIndex,
      createPurchasedList,
      createTransportationList,
      _handleSelectEquip,
      surveyYear,
      buildingId,
      surveyFramework,
      frameWorkIndex,
      _handleSelectedFile,
      selectedYear,
      ghgDataType,
    } = props.store.survey;

    const { getSiteList, siteList } = props.store.siteRelevant;

    const frameworkId = toJS(surveyFramework[frameWorkIndex]?.framework_id);

    useEffect(() => {
      getSiteList(buildingId, frameworkId);
    }, []);

    const siteListData = siteList?.map((s) => {
      return {
        label: s.fileName,
        value: s._id,
      };
    });

    const { sectionName } = surveyInfo?.surveySection[sectionIndex];
    

    const dataList =
      subSectionArray[subSectionIndex]?.category[0] === "Category"
        ? subSectionArray[subSectionIndex]?.equipmentData?.map((v) => {
            return {
              name: v.name,
              year: v.year,
              category: v.category,
              transportMode: v.transportMode,
              vehicleType: v.vehicleType,
              emissionFactor: v.emissionFactor,
              activityType: v.activityType,
              unit: v.unit,
              dailyData: v.dailyData?.map((d) => {
                return {
                  data: moment(new Date(d.date).toString()).format(
                    "DD-MMM-YYYY HH:MM"
                  ),
                  carbonEmission: d.carbonEmission,
                  distance: d.distance,
                };
              }),
            };
          })
        : [];

    const optionData = options?.map((opt) => {
      return { value: opt._id, label: opt.text };
    });

    const YearlyManualEntry =
      toJS(equipmentData)?.filter((dd) => dd.recordPeriod === "Yearly") || [];
    const MonthlyManualEntry =
      toJS(equipmentData)?.filter((dd) => dd.recordPeriod === "Monthly") || [];
    const QuarterlyManualEntry = 
      toJS(equipmentData)?.filter((dd) => dd.recordPeriod === "Quarterly") || [];

    const CurrentInput = () => {
      switch (type) {
        case "":
          return (
            <CustomInputs
              {...props}
              optionData={optionData}
              siteListData={siteListData}
              selectedYear = {selectedYear}
              _handleSelectedFile={_handleSelectedFile}
              buildingId={buildingId}
              frameworkId={frameworkId}
            />
          );

        case "table":
          return (
            <MyMatrixTable
              id={id}
              optionData={optionData}
              category={category}
              unit={unit}
              questions={questions ? questions : childSections}
              childSection={questions ? null : childSections[id]}
              siteListData={siteListData}
            />
          );
        case "dynamic_question_table":
          return ghgDataType === 'Category' ? 
          header === "Business Travel" ? 
          surveyYear ? (
            <MyCollapsibleTable
              columns={transportationColumn}
              collapseColumns={dailyDataColumn}
              dataList={dataList || []}
              header={sectionName}
              sectionIndex={sectionIndex}
              surveyInfo={surveyInfo}
              subSectionArray={subSectionArray}
              subSectionIndex={subSectionIndex}
              createTransportationList={createTransportationList}
              surveyYear={surveyYear}
            />
          ): <EmptyYear surveyYear={surveyYear}/>
           : siteListData?.length > 0 && surveyYear ? (
            <EntryDataByCateTbl
              siteListData={siteListData}
              frameworkId={frameworkId}
              buildingId={buildingId}
            />
          ) : surveyYear ? (
            <EmptyData
              subSectionIndex={subSectionIndex}
              subSectionArray={subSectionArray}
              surveyInfo={surveyInfo}
              sectionIndex={sectionIndex}
              _handleSelectEquip={_handleSelectEquip}
              createPurchasedList={createPurchasedList}
              surveyYear={surveyYear}
            />
          ) : (
            <EmptyYear surveyYear={surveyYear} />
          ):
          (equipmentData?.length > 0 ? (
            sectionName === "Scope 3" ? (
              <MyCollapsibleTable
                columns={transportationColumn}
                collapseColumns={dailyDataColumn}
                dataList={dataList || []}
                header={sectionName}
                sectionIndex={sectionIndex}
                surveyInfo={surveyInfo}
                subSectionArray={subSectionArray}
                subSectionIndex={subSectionIndex}
                createTransportationList={createTransportationList}
                surveyYear={surveyYear}
              />
            ) : (
              <>
                {YearlyManualEntry.length > 0 ? (
                  <MyDynamicTable
                    id={id}
                    category={category}
                    unit={unit}
                    options={siteListData}
                    questions={questions ? questions : childSections}
                    equipmentData={YearlyManualEntry}
                    childSection={questions ? null : childSections[id]}
                    muiSelector={viewStatus === "detailsView"}
                  />
                ) : null}
                {MonthlyManualEntry.length > 0 ? (
                  <MonthlyDynamicTable
                    id={id}
                    category={category}
                    unit={unit}
                    options={siteListData}
                    questions={questions ? questions : childSections}
                    equipmentData={MonthlyManualEntry}
                    childSection={questions ? null : childSections[id]}
                    muiSelector={viewStatus === "detailsView"}
                  />
                ) : null}
                {QuarterlyManualEntry.length>0 ? (
                  <QuarterlyDynamicTable
                  id={id}
                  category={category}
                    unit={unit}
                    options={siteListData}
                    questions={questions ? questions : childSections}
                    equipmentData={QuarterlyManualEntry}
                    childSection={questions ? null : childSections[id]}
                    muiSelector={viewStatus === "detailsView"} />
                ) : null}
              </>
            )
          ) : surveyYear ? (
            <EmptyData
              subSectionIndex={subSectionIndex}
              subSectionArray={subSectionArray}
              surveyInfo={surveyInfo}
              sectionIndex={sectionIndex}
              _handleSelectEquip={_handleSelectEquip}
              createPurchasedList={createPurchasedList}
              surveyYear={surveyYear}
            />
          ): <EmptyYear surveyYear={surveyYear}/>
          );
        default:
          return null;
      }
    };

    return CurrentInput();
  })
);
export default Input;

const transportationColumn = [
  { id: "name", numberic: false, label: "Name", sortable: true },
  { id: "year", numberic: false, label: "Year", sortable: true },
  { id: "category", numberic: false, label: "Category", sortable: true },
  {
    id: "transportMode",
    numberic: false,
    label: "Mode of transport",
    sortable: true,
  },
  { id: "vehicleType", numberic: false, label: "Vehicle Type", sortable: true },
  {
    id: "emissionFactor",
    numberic: false,
    label: "Emission Factor",
    sortable: true,
  },
  {
    id: "activityType",
    numberic: false,
    label: "Activity Type",
    sortable: true,
  },
  { id: "unit", numberic: false, label: "Unit", sortable: true },
];

const dailyDataColumn = [
  { id: "date", numberic: false, label: "Date", sortable: true },
  {
    id: "carbonEmission",
    numberic: false,
    label: "Carbon Emission",
    sortable: true,
  },
  { id: "distance", numberic: false, label: "Distance", sortable: true },
];

const categoryOption = [
  { value: 1, label: "Stationary Combustion" },
  { value: 2, label: "Mobile Combustion" },
  { value: 3, label: "Refrigerants and Fugitives" },
];

const EmptyYear = (props) => {
  const {surveyYear} = props;
  const { t } = useTranslation("target_setting");

  return (
    <div className="text-center my-2">
        <div className="rounded p-4 text-secondary" style={{ fontSize: 18 }}>
          {t("Please select a year.")}
        </div>
      </div>
  );
}

const EmptyData = (props) => {
  const {
    subSectionIndex,
    subSectionArray,
    surveyInfo,
    sectionIndex,
    _handleSelectEquip,
    createPurchasedList,
    surveyYear,
  } = props;
  const currentSectionName =
    surveyInfo.surveySection[sectionIndex]?.sectionName;
  const currentSubSectionHeader =
    subSectionArray[subSectionIndex]?.subSectionHeader;

  const addEquipmentData = () => {
    if (currentSubSectionHeader === "Purchased Heat and Steam") {
      createPurchasedList.category = "Purchased Heat and Steam";
      createPurchasedList.year = surveyYear;
    }
  };
  const { t } = useTranslation("target_setting");

  useEffect(() => {
    if (currentSectionName === "Scope 1") {
      _handleSelectEquip(
        "category",
        categoryOption[subSectionIndex],
        "equipment"
      );
    }
  }, [subSectionIndex, sectionIndex]);



  return (
    <>
      {currentSectionName === "Scope 1" ? (
        <EquipmentModal />
      ) : (
        <PurchasedElectricityModal />
      )}

      <div className="text-center my-2 text-secondary">
        <div className="rounded p-4" style={{ fontSize: 18 }}>
          <i className="bi bi-file-earmark-fill pe-1" />
          {t("No equipment added yet.")}
          {currentSectionName === "Scope 1" ||
          currentSectionName === "Scope 2" ? (
            <a
              data-bs-toggle="modal"
              onClick={() => addEquipmentData()}
              data-bs-target={"#addEquipmentModal"}
              href="javascript:void(0);"
              style={{ fontSize: "14px", paddingLeft: "2px" }}
            >
              {t("Add " + currentSubSectionHeader)}
            </a>
          ) : null}
        </div>
      </div>
    </>
  );
};
