import { useNavigate } from "react-router-dom";
import { Colors } from "../Assets/Config/color.config";
import { useEffect, useState } from "react";
import Store from "../Store";
import { Cookies } from "react-cookie";
import PieChartFill from "../Assets/images/PieChartFill.png";
import { inject, observer } from "mobx-react";
import { baseURLImage } from "../API/URL";
import { ThemeSwitch } from "../Tools";
import { useTranslation } from "react-i18next";
import UserMenuItem from "./UserMenuItem";
import LanguageMenuItem from "./LanguageMenuItem";
import { MyToggleButton } from "../Tools";
import { toJS } from "mobx";
import PageSearch from "./PageSearch";

export const NavBar = inject("store")(
  observer((props) => {
    const navigate = useNavigate();
    const { _handleLogout } = Store.login;
    // const { showDropDown, _handleUserDropDown } = props;
    const cookies = new Cookies();
    const userName = cookies.get("userName");
    const userRole = cookies.get("role");
    const params = new URLSearchParams(window.location.search);
    const pathName = window.location.pathname;
    const buildingName = params.get("name");
    const siteImage = cookies.get("siteImage");
    const { t } = useTranslation("authentication");

    const [showLanguageDropDown, setShowLanguageDropDown] = useState(false);
    const [showDropDown, setShowDropDown] = useState(false);

    const { surveyInfo } = props.store.survey;
    const { addDefaultSrc, theme, onChangeTheme, groupStatus, siteModuleData } = props.store.common;
    const { authenticated } = props;

    const _handleLanguageDropDown = (e) => {
      e.stopPropagation();
      setShowDropDown(false);
      setShowLanguageDropDown(!showLanguageDropDown);
    };

    const _handleUserDropDown = (e) => {
      e.stopPropagation();
      setShowLanguageDropDown(false);
      setShowDropDown(!showDropDown);
    };

    useEffect(() => {
      // to hide drop downs
      const hideDropDowns = (e) => {
        setShowDropDown(false);
        setShowLanguageDropDown(false);
      };
      document.body.addEventListener("click", hideDropDowns);

      return () => {
        document.body.removeEventListener("click", hideDropDowns);
      };
    }, []);


    return (
      <div
        style={{
          fontSize: 18,
          color:
            theme === "dark" ? Colors.dark.textColor : Colors.light.textColor,
          // background: theme === 'dark' ? Colors[theme].navBackground : Colors.white,
        }}
        className="d-flex flex-row justify-content-between align-items-center p-3"
      >
        {authenticated && (
          <><div className="d-flex ">
            <h4
              style={{ fontWeight: "bold", cursor: "pointer", margin: 0 }}
              onClick={() => userRole === 'auditor' ? 
              navigate('/auditor-overview') : 
              (userName === 'Kumo Admin' || groupStatus)? navigate('/home-group') : navigate("/home")}
            >
              <img
                className="ms-3"
                // src={PieChartFill}
                src="/nxmap_logo.png"
                height={"40px"}
              />
              {/*<img
                className="me-3"
                src={PieChartFill}
                width={"45px"}
                height={"45px"}
              />
              <span style={{ color: Colors[theme].primaryColor }}>
                {userRole === "auditor" ? t("ESG Map - Auditor View") : t("ESGmap")}
                &nbsp;
              </span>*/}
              {buildingName && (
                <>
                  <i className="bi bi-chevron-right"></i>
                  <span className="px-2">
                    <img
                      crossorigin="anonymous"
                      onError={addDefaultSrc}
                      src={baseURLImage + siteImage}
                      alt={t("Company Logo")}
                      height={40}
                      style={{ borderRadius: 5 }}
                    />{" "}
                    {buildingName}
                  </span>
                  <i className="bi bi-chevron-right" /> {surveyInfo.surveyTitle}
                </>
              )}
            </h4>
            {pathName === "/rapid-assessment/" &&
            <div className="mx-2">
              <MyToggleButton />
            </div>
            }
          </div>
          <div className="col-4 ms-3">
              {/* <PageSearch /> */}
            </div>
          </>
        )}
        <div
          className="d-flex align-items-center"
          style={{ marginLeft: "auto" }}
        >
          <LanguageMenuItem
            userName={userName}
            theme={theme}
            authenticated={authenticated}
            showDropDown={showLanguageDropDown}
            onClick={_handleLanguageDropDown}
          />
          <ThemeSwitch
            style={{ marginRight: 0 }}
            onChangeTheme={onChangeTheme}
            theme={theme}
          />
          {authenticated && (
            <UserMenuItem
              userName={userName}
              theme={theme}
              showDropDown={showDropDown}
              _handleLogout={_handleLogout}
              onClick={_handleUserDropDown}
            />
          )}
        </div>
      </div>
    );
  })
);

export default NavBar;
