import { useEffect } from "react";
import { inject, observer } from "mobx-react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import SideBar from "../../Components/GroupView/SideBar";
import Groupview from "../../Components/GroupView/GroupView";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toJS } from "mobx";

export const GroupOverView = inject("store")(
    observer((props) => {
        const { siteModuleData,theme } = props.store.common;
        const { _handleSelectGroupId, _handleSelectedViewChange, getAllGroups, listGroup ,selectedGroupId,selectedView} = props.store.groupmanagement;
        const navigate = useNavigate();
        const { t } = useTranslation("assetSetupAndGroup");

        useEffect(() => {
            getAllGroups()
        }, [])

        const params = new URLSearchParams(window.location.search);
       
        useEffect(() => {
            if(!params.get('view') && !params.get('groupId')){
                _handleSelectGroupId('-1')
                _handleSelectedViewChange('Emission Activity')
                navigate('/group-view?groupId=-1&view=Emission%20Activity')
            }
            if (params.get('groupId')) {
                _handleSelectGroupId(params.get('groupId'))
            }
            if (params.get('view')) {
                _handleSelectedViewChange(params.get('view'))
            }
        }, [])

        

        return (
            <div className="container-fluid">
                <div className="cardStyle"
                    style={{
                        backgroundColor: theme === "dark" ? Colors.darkBlue : '#efefef',
                        color: Colors[theme].textColor,
                        borderRadius: 24
                    }}>
                    <div className="mx-2 d-flex justify-content-between" style={{ color: Colors[theme].textColor }}>
                        <h2 className=""><span><i class="bi bi-building px-2"></i>{siteModuleData.companyName} {t("Overview")}</span></h2>
                        <div
                            style={{
                                fontSize: 20,
                                color: invertDark(theme),
                                alignItems: "center",
                                display: "flex",
                                cursor: "pointer",
                            }}
                            onClick={() => navigate("/home-group")}
                            // onClick={() => window.history.back()}
                        >
                            <i className="bi bi-arrow-left-circle-fill pe-3"></i>
                            {t("Back")}
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className={`row cardStyle`}
                            style={{ borderRadius: 24, width: '100%', padding: 0, backgroundColor: theme === "dark" ? '#020845' : Colors.Dark[5], margin: '10px 0' }}
                        >
                            <div className="col-2 py-3 px-0" style={{ backgroundColor : theme === "dark" ? '#010637' : '#00000010' , borderTopLeftRadius : 24 , borderBottomLeftRadius : 24 }}>
                                <SideBar />
                            </div>
                            <div className="col-10 py-3">
                                <Groupview />
                            </div>

                        </div>
                    </div>
                </div>

            </div>

        );
    })
);