import { Colors, invertDark } from "../../Assets/Config/color.config"
import { inject, observer } from "mobx-react";
import { useTranslation } from 'react-i18next';


const ConfirmDelteModal = inject("store")(
    observer((props) => {
        const { _handleDeleteSite } = props.store.siteRelevant;
        const { frameworkId, buildingId } = props;
        const { theme } = props.store.common;
        const { t } = useTranslation("target_setting");

        return (
            <div
                className={`modal fade ${theme}`}
                id="confirmModal"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div
                        className="modal-content"
                        style={{
                            background: Colors[theme].secondBackground,
                            borderRadius: 10,
                            color: invertDark(theme)
                        }}
                    >
                        <div className="modal-header mx-3 px-0">
                            <h4 className="modal-title" id="exampleModalLabel">
                                { t("Confirm Delete") }
                            </h4>
                            <i
                                style={{ cursor: "pointer" }}
                                className="bi bi-x-lg "
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            // onClick={() => _handleClose()}
                            ></i>
                        </div>
                        <div className="modal-body">{ t("Are you sure want to delete")}</div>
                        <div className="modal-footer d-flex justify-content-center mx-3">
                            <button
                                type="button"
                                // onClick={() => _handleCreateNewSite()}
                                className="btn col-2 text-white"
                                style={{
                                    background: theme === 'dark' ? Colors.darkBlue : '#888'
                                }}
                                data-bs-dismiss="modal"
                            >
                                { t("Cancel") }
                            </button>
                            <button
                                type="button"
                                data-bs-dismiss="modal"
                                className="btn col-2 text-white"
                                style={{
                                    background: 'rgb(255 0 0 / 50%)'
                                }}
                                onClick={() => _handleDeleteSite(frameworkId, buildingId)}
                            >
                                { t("Yes") }
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        )
    }))
export default ConfirmDelteModal