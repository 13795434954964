import React, { useEffect } from 'react'
import * as d3 from "d3";
import * as d3_sankey from 'd3-sankey'
import { Colors } from '../../Assets/Config/color.config'
import '../../App.css'
import { width } from '@mui/system';
import { inject, observer } from "mobx-react";
import Store from '../../Store';

class SankeyDiagram extends React.PureComponent {
    constructor(props) {        
        super(props)
        
        this.state = {
            DataSankey: this.props.DataSankey,
            windowWidth: this.props.windowWidth,
            theme: this.props.theme
        }
    }
    loadSankeyDiagram = () => {
        const Sankeydata = this.state.DataSankey?.reduce((r, c) => {
            const R = [...r]
            R.push(
                {
                    sdg_name: c.sdg_title,
                    sector_name: `${c.result} - ` + c.sector_name,
                    name: c.group_name,
                    color: c.color,
                    value: c.value
                })
            return R
        }, [])
        this.setState({ DataSankey: Sankeydata }, () => this.DrawSankey(this.state.theme))
    }
    componentDidMount() {
        this.loadSankeyDiagram()  
        if(this.props.DataSankey.length > 0){
            this.props.store.esg._handleSankeyChartClick(null, null, this.props.DataSankey[0]);
        }
        
    }
    async componentDidUpdate(prevProps) {
        if (this.props.DataSankey !== prevProps.DataSankey) {
            const elements = document.getElementsByClassName("viz__sankey-diagram");
            while (elements.length > 0) elements[0].remove();
            await this.setState({ DataSankey: this.props.DataSankey }, () => this.loadSankeyDiagram())
            if(this.props.DataSankey.length > 0){
                this.props.store.esg._handleSankeyChartClick(null, null, this.props.DataSankey[0]);
            }
        }
        if (this.props.windowWidth !== prevProps.windowWidth) {
            const elements = document.getElementsByClassName("viz__sankey-diagram");
            while (elements.length > 0) elements[0].remove();
            this.setState({
                DataSankey: this.props.DataSankey,
                windowWidth: this.props.windowWidth
            },
                () => this.loadSankeyDiagram())
        }

        
    }
    

    DrawSankey = () => {
        const { windowWidth } = this.state;
        const { _handleSankeyChartClick } = this.props.store.esg;
        const { theme } = this.props.store.common;
        const sankeyDiagram = d3
            .select('.viz')
            .append('article')
            .attr('class', 'viz__sankey-diagram');
        // svg visualization
        const sankeyDiagramMargin = {
            top: 5,
            right: 80,
            bottom: 5,
            left: 80,
        };
        const height = (windowWidth >= 1800 && windowWidth < 1920) ? 300
            : (windowWidth < 1800 && windowWidth >= 1700) ? 320
                : (windowWidth <= 1699 && windowWidth > 1540) ? 350
                    : (windowWidth >= 1367 && windowWidth < 1453) ? 410
                        : (windowWidth >= 1198 && windowWidth <= 1258) ? 410
                            : (windowWidth >= 1259 && windowWidth <= 1320) ? 390 : 375
        // describe a rectangle
        //const sankeyDiagramWidth = 460 - (sankeyDiagramMargin.left + sankeyDiagramMargin.right);
        //const sankeyDiagramHeight = height - (sankeyDiagramMargin.top + sankeyDiagramMargin.bottom);
        const sankeyDiagramWidth = 260 - (sankeyDiagramMargin.left + sankeyDiagramMargin.right);
        const sankeyDiagramHeight = 100;
        const sankeyDiagramSVG = sankeyDiagram
            .append('svg')
            .attr('viewBox', `0 0 ${sankeyDiagramWidth + (sankeyDiagramMargin.left + sankeyDiagramMargin.right)} ${sankeyDiagramHeight + (sankeyDiagramMargin.top + sankeyDiagramMargin.bottom)}`);
        const sankeyDiagramGroup = sankeyDiagramSVG
            .append('g')
            .attr('transform', `translate(${sankeyDiagramMargin.left} ${sankeyDiagramMargin.top})`);
        // color scale to color the sankey chords differently
        var sankeyColor = d3.scaleOrdinal([`#f44336`, `#e91e63`, `#9c27b0`, `#673ab7`, `#2196f3`, `#009688`, `#ffc107`, `#ff5722`, `#5D4037`]);
        /* the sankey function requires an object detailing the nodes and links in two properties
        {
          nodes,
          links
        }
        */
        /* nodes describes one object for each sector
        {
          sector,
        }
        */
        // retrieve the sectors from the dataSankey array
        const sectors = this.state.DataSankey?.map(({ sector_name }) => sector_name);
        const sdg_names = this.state.DataSankey?.map(({ sdg_name }) => (sdg_name));


        // let sdg_names = this.state.DataSankey?.map((value) => {
        //     return {
        //         sector_name: value.sdg_name,
        //         name: value.name,
        //     }
        // })

        // let sectors = this.state.DataSankey?.map((value) => {
        //     return {
        //         sector_name: value.sector_name,
        //         name: value.name,
        //     }
        // })     

        const uniqueSectors = new Set([...sectors, ...sdg_names]);
        const nodes = [...uniqueSectors].map(sector_name => ({
            sector_name,
        }));

        // const nodes = [...uniqueSectors].map((v)=> {
        //     return {
        //         sector_name : v.sector_name,
        //         name: v.name
        //     }
        // })

        /* links describes one object with source, target and value properties
        {
          source,
          target,
          value,
        }
        the structure is simular to the DataSankey array, just with different labels
        */
        const links = this.state.DataSankey?.map(({ sector_name: target, sdg_name: source, value }) => ({
            source,
            target,
            value,
        }));

        // larger object for the sankey function
        const data = {
            nodes,
            links,
        };
        // sankey function
        const sankey = d3_sankey.sankey()
            .nodeWidth(0.5)
            .nodePadding(1)
            .nodeId(d => d.sector_name)
            .size([sankeyDiagramWidth, sankeyDiagramHeight]);
        // process the data to retrieve the values necessary to draw the nodes and connections
        const { nodes: nds, links: lnks } = sankey(data);
        // for each link add a path element connecting the source and target
        // gengerator function describing the path elements based on the links' attributes
        const sankeyLinks = d3_sankey
            .sankeyLinkHorizontal();
        var tooltip = d3.select("body").append("div").attr("class", "sankey_toolTip");
        const setNodeColor = (d, i) => {
            if (d.targetLinks.length > 0) {                
                let index = this.state.DataSankey?.filter((value) => value.sdg_name === d.targetLinks[0].source.sector_name)
                return index[0].color;
            }else{
                let index = this.state.DataSankey?.filter((value) => value.sdg_name === d.sector_name)
                return index[0].color;
            }
            

            //return Colors.greenMaterial
        };
        const lnkColor = (d, i) => {
            let index = this.state.DataSankey?.filter((value) => value.sdg_name === d.source.sector_name)
            return index[0].color;
        }

        const sanKeyClickAction = (source, target, index) => {
            _handleSankeyChartClick(source, target, this.props.DataSankey[index]);
        }

        const lnkOpacity = (d,i) => {
            if(i === 0){
                return 0.7;
            }else{
                return 0.3;
            }
        }
        // path elements
        sankeyDiagramGroup
            .selectAll('path.link')
            .data(lnks)
            .enter()
            .append('path')
            .attr('class', 'link')
            .attr('d', sankeyLinks)
            .attr('fill', 'none')
            .attr('stroke', lnkColor)
            .attr('stroke-width', ({ width }) => width)
            .attr('opacity', lnkOpacity)
            .attr('cursor',"pointer")
            // on hover show the information connected with the path element
            .on('mouseenter', function (event, { source, target, value }, i) {
                let opt = d3
                    .select(this)                    
                    .attr('opacity');
                    console.log(opt);
                if(opt <= 0.6){
                    d3
                    .select(this)
                    .transition()
                    .attr('opacity', 0.6);
                }
                
                //     tooltip
                //         // .append('p')
                //         .style('left', `${event.pageX}px`)
                //         .style('top', `${event.pageY}px`)
                //         .style('display', 'flex')
                //         .style('align-items', 'center')
                //         .html(`
                //   <svg width="6" height="6" viewBox="0 0 10 10" style="margin: 0; margin-right: 0.35rem;">
                //     <circle cx="5" cy="5" r="5" fill="#fff"></circle>
                //   </svg>
                //   <strong>${value}</strong>&nbsp; from '${source.sector_name}' to '${target.sector_name}'
                // `);
            })
            .on('click', function (event, { source, target, value }, i){   
                console.log("event",event.currentTarget);
                d3.selectAll(".link").transition().attr("opacity", 0.3);
                d3.select(this).transition().attr("opacity", 0.7);
                //event.target.setAttribute("opacity", 0.7);\
                console.log("DataKey", value)
                sanKeyClickAction(source, target, target.index);
            })
            .on('mouseout', function (d) {
                let opt = d3
                    .select(this)                    
                    .attr('opacity');
                    console.log(opt);
                    if(opt <= 0.6){
                        d3
                        .select(this)
                        .transition()
                        .attr('opacity', 0.3);
                        tooltip.style("display", "none");
                    }
               
            });


        // for each node draw include a group element to group the rectangle and text labels
        // position the group (and the subsequent elements) using the computed properties x0, x1, y0, y1
        const sankeyDiagramNodes = sankeyDiagramGroup
            .selectAll('g.node')
            .data(nds)
            .enter()
            .append('g')
            .attr('class', 'node')
            .attr('transform', ({ x0, y0 }) => `translate(${x0} ${y0})`);
        sankeyDiagramNodes
            .data(nds)
            .append('rect')
            .attr('x', 0)
            .attr('y', 0)
            .attr('width', ({ x0, x1 }) => (x1 - x0) + 2)
            .attr('height', ({ y0, y1 }) => y1 - y0)
            .attr('fill', setNodeColor);
        // position the labels inwards
        sankeyDiagramNodes
            .append('text')
            .attr('x', ({ depth }) => depth === 0 ? -15 : 15)
            .attr('y', ({ y0, y1 }) => (y1 - y0) / 2)
            .attr('text-anchor', ({ depth }) => depth === 0 ? 'end' : 'start')
            .attr('dominant-baseline', 'middle')
            .attr('font-size', 3)
            .attr('fill', Colors.Dark[3])
            //.attr('fill', "#FFF")
            .html(function ({ sector_name }) {
                return sector_name;
            });

        // .text((value) => {
        //     return value;
        // //    if(value.sourceLinks.length > 0){
        // //     return value.name;
        // //    }else{
        // //     return value.sector_name;
        // //    }
        // });

       
    }
    
    
    render() {
        
        return (
            <div>
                <div className="viz" style={{
                    borderRadius: 10,
                    boxShadow: `1px 1px 2px ${Colors.greyColor}`,
                }}></div>
                {/* <div className="text-right my-2 mr-3">
                  <button
                    className="btn px-4"
                    style={{
                      background: color.primaryColor,
                      color: "#fff",
                      borderRadius: 50,
                    }}
                    onClick={() =>
                  >
                    View More <i className="fa fa-caret-right pl-1" />
                  </button>
                </div> */}
            </div>
        )
    }
}
export default inject("store")(observer(SankeyDiagram))