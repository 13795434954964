import { inject, observer } from "mobx-react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { MyButton, MyInput, Selector, MyRadioButton } from "../../Tools";
import { toJS } from "mobx";
import { ConfirmResetModal } from "../ESG/ConfirmResetModal";
import { useEffect } from "react";
import { ConfirmDeleteModel } from "../ESG/ConfirmDeleteModel";

export const CreateNewKPI = inject("store")(
    observer((props) => {
        const { theme } = props.store.common;
        const { _handleSelectChange, createKpiList, _handleCreateKpi,
            isExpand, targetOption, expandKpiList, _handleInputChange, _handleRadioChange, actionMode,
            baseYearOption, ownerTitleOption, ownerNameOption, unitOption, frequencyOption, dependenciesOption, standardsOption, _handleConfirmReset,
            _createKPIManagement, _updateKPIManagement
        } = props.store.kpiManagement;
        const { pillarOption, subPillarOption, _fetchAllPillars, _fetchAllSubPillars} = props.store.esg;
        useEffect(() => {
            _fetchAllPillars();
            _fetchAllSubPillars();
        },[])
        return (
            <div style={{ fontSize: 14, color: invertDark(theme) }}>
                <form className="px-2">
                    <h5
                        className="pb-2"
                        style={{
                            color: theme === "dark" ? Colors.Dark[5] : Colors.Dark[0],
                        }}
                    >{actionMode === "edit" ? "" : "Create New KPI"}</h5>
                    <div className="d-flex row my-2 ps-3 pb-3" style={{ borderBottom: isExpand ? "1px solid rgb(255,255,255,10%)" : "" }}>
                        <div className="col-lg-4 col-12 px-3 py-1">
                            <div className="row">
                                <span className="col-4 py-2" style={{ fontWeight: 'bold', color: theme === 'dark' ? Colors.Dark[5] : Colors.Dark[0] }}>
                                    ESG Pillar :
                                </span>
                                <span className="col-7" style={{ color: Colors.Dark[0], fontWeight: 'normal' }}>
                                    <Selector
                                        id={"pillarSelector"}
                                        _handleSelect={(e) => _handleSelectChange(e, "pillar")}
                                        options={pillarOption}
                                        value={pillarOption.filter((option) => option.value === createKpiList.pillar)}
                                        placeholder={"Select ESG Pillar"}
                                    />
                                </span>
                            </div>
                        </div>
                        <div className="col-lg-4 col-12 px-3 py-1">
                            <div className="row">
                                <span className="col-4" style={{ fontWeight: 'bold' }}>
                                    Materiality Topic/ESG Sub-Pillar :
                                </span>
                                <span className="col-7" style={{ color: Colors.Dark[0], fontWeight: 'normal' }}>
                                    <Selector
                                        id={"subPillarSelector"}
                                        placeholder={"Select Materiality Topic"}
                                        _handleSelect={(e) => _handleSelectChange(e, "subPillar")}
                                        options={subPillarOption}
                                        value={subPillarOption.filter((option) => option.value === createKpiList.subPillar)}
                                    />
                                </span>
                            </div>
                        </div>
                        {!isExpand && <div className="col py-3 text-end">
                            <MyButton
                                small
                                style={{ height: '40px', width: '30%' }}
                                text="Create"
                                customColor='#066CFF'
                                customHoverColor={Colors.water}
                                onClick={_handleCreateKpi}
                            />
                        </div>}
                    </div>
                    {toJS(isExpand) === true &&
                        <div className="d-flex row my-3 ps-3" style={{ color: theme === 'dark' ? Colors.Dark[5] : Colors.Dark[0] }}>
                            <div className="col-lg-4 col-12">
                                <div className="row d-flex py-2">
                                    <span className="col-4" style={{ fontWeight: 'bold', color: theme === 'dark' ? Colors.Dark[5] : Colors.Dark[0] }}>KPI :</span>
                                    <span className="col-7">
                                        <MyInput
                                            id="kpi"
                                            isTextArea={true}
                                            value={expandKpiList.kpi}
                                            placeHolder={"Please insert KPI"}
                                            onChange={(e) => _handleInputChange(e, "expandForm","kpi")}
                                            height={'100px'}
                                        />
                                    </span>
                                </div>
                                <div className="row d-flex py-2">
                                    <span className="col-4" style={{ fontWeight: 'bold', color: theme === 'dark' ? Colors.Dark[5] : Colors.Dark[0] }}>Objectives : </span>
                                    <span className="col-7">
                                        <MyInput
                                            id="objectives"
                                            isTextArea={true}
                                            placeHolder={"Please insert objectives"}
                                            value={expandKpiList.objectives}
                                            onChange={(e) => _handleInputChange(e, "expandForm","objectives")}
                                            height={'100px'}
                                        />
                                    </span>
                                </div>
                                <div className="row d-flex py-2">
                                    <span className="col-4" style={{ fontWeight: 'bold', color: theme === 'dark' ? Colors.Dark[5] : Colors.Dark[0] }}>Data Source :</span>
                                    <span className="col-7">
                                        <MyInput
                                            id="dataSource"
                                            placeholder=""
                                            value={expandKpiList.dataSource}
                                            onChange={(e) => _handleInputChange(e, "expandForm","dataSource")}
                                        />
                                    </span>
                                </div>
                                <div className="row d-flex py-2">
                                    <span className="col-4" style={{ fontWeight: 'bold', color: theme === 'dark' ? Colors.Dark[5] : Colors.Dark[0] }}>Progress :</span>
                                    <span className="col-7">
                                        <MyInput
                                            id="progress"
                                            placeholder="Progress"
                                            value={expandKpiList.progress}
                                            onChange={(e) => _handleInputChange(e, "expandForm","progress")}
                                        />
                                    </span>
                                </div>
                                <div className="row d-flex py-2">
                                    <span className="col-4" style={{ fontWeight: 'bold', color: theme === 'dark' ? Colors.Dark[5] : Colors.Dark[0] }}>Result :</span>
                                    <span className="col-7">
                                        <MyInput
                                            id="result"
                                            placeholder="Result"
                                            value={expandKpiList.result}
                                            onChange={(e) => _handleInputChange(e, "expandForm","result")}
                                        />
                                    </span>
                                </div>
                                {/* <div className="row d-flex py-2">
                                    <span className="col-4" style={{ fontWeight: 'bold', color: theme === 'dark' ? Colors.Dark[5] : Colors.Dark[0] }}>Target Set :</span>
                                    <span className="col-7">
                                        <div className="row">
                                            {targetOption.map((v, k) => (
                                                <span className="col-6 text-light">
                                                    <MyRadioButton
                                                        id={v.value + k}
                                                        onChange={(e) => _handleRadioChange(e)}
                                                        name={v.value}
                                                        value={v.label}
                                                        checked={expandKpiList.targetSet === v.label}
                                                    />
                                                </span>
                                            ))}
                                        </div>
                                    </span>
                                </div> */}
                            </div>
                            <div className="col-lg-4 col-12">
                                <div className="row d-flex py-2">
                                    <span className="col-4 py-2" style={{ fontWeight: 'bold', color: theme === 'dark' ? Colors.Dark[5] : Colors.Dark[0] }}>
                                        Base Year :
                                    </span>
                                    <span className="col-7" style={{ color: Colors.Dark[0], fontWeight: 'normal' }}>
                                        <Selector
                                            id={"baseYearSelector"}
                                            _handleSelect={(e) => _handleSelectChange(e, "baseYear")}
                                            options={baseYearOption}
                                            value={baseYearOption.filter((option) => (option.value) === (expandKpiList.baseYear))}
                                            placeholder={"Select base year"}
                                        />
                                    </span>
                                </div>
                                <div className="row d-flex py-2">
                                    <span className="col-4 py-2" style={{ fontWeight: 'bold', color: theme === 'dark' ? Colors.Dark[5] : Colors.Dark[0] }}>
                                        KPI Owner Title :
                                    </span>
                                    <span className="col-7" style={{ color: Colors.Dark[0], fontWeight: 'normal' }}>
                                        <Selector
                                            id={"ownerTitleSelector"}
                                            _handleSelect={(e) => _handleSelectChange(e, "ownerTitle")}
                                            options={ownerTitleOption}
                                            value={ownerTitleOption.filter((option) => option.value === expandKpiList.ownerTitle)}
                                            placeholder={"Select owner title"}
                                        />
                                    </span>
                                </div>
                                <div className="row d-flex py-2">
                                    <span className="col-4 py-2" style={{ fontWeight: 'bold', color: theme === 'dark' ? Colors.Dark[5] : Colors.Dark[0] }}>
                                        KPI Owner Name :
                                    </span>
                                    <span className="col-7" style={{ color: Colors.Dark[0], fontWeight: 'normal' }}>
                                        <Selector
                                            id={"ownerNameSelector"}
                                            _handleSelect={(e) => _handleSelectChange(e, "ownerName")}
                                            options={ownerNameOption}
                                            value={ownerNameOption.filter((option) => option.value === expandKpiList.ownerName)}
                                            placeholder={"Select owner name"}
                                        />
                                    </span>
                                </div>
                                <div className="row d-flex py-2">
                                    <span className="col-4" style={{ fontWeight: 'bold', color: theme === 'dark' ? Colors.Dark[5] : Colors.Dark[0] }}>Status:</span>
                                    <span className="col-7">
                                        <MyInput
                                            id="status"
                                            isTextArea={true}
                                            value={expandKpiList.status}
                                            placeHolder={"Please insert status"}
                                            onChange={(e) => _handleInputChange(e, "expandForm","status")}
                                            height={'100px'}
                                        />
                                    </span>
                                </div>
                                <div className="row d-flex py-2">
                                    <span className="col-4 d-flex align-items-center" style={{ fontWeight: 'bold', color: theme === 'dark' ? Colors.Dark[5] : Colors.Dark[0] }}>
                                        Unit of Measurement :
                                    </span>
                                    <span className="col-7" style={{ color: Colors.Dark[0], fontWeight: 'normal' }}>
                                        <Selector
                                            id={"unitSelector"}
                                            _handleSelect={(e) => _handleSelectChange(e, "unit")}
                                            options={unitOption}
                                            value={unitOption.filter((option) => option.value === expandKpiList.unit)}
                                            placeholder={"Select unit"}
                                        />
                                    </span>
                                </div>
                            </div>
                            <div className="col-lg-4 col-12">
                                <div className="row d-flex py-2">
                                    <span className="col-5 py-2" style={{ fontWeight: 'bold', color: theme === 'dark' ? Colors.Dark[5] : Colors.Dark[0] }}>
                                        Frequency of Measurement :
                                    </span>
                                    <span className="col-7" style={{ color: Colors.Dark[0], fontWeight: 'normal' }}>
                                        <Selector
                                            id={"frequencySelector"}
                                            _handleSelect={(e) => _handleSelectChange(e, "frequency")}
                                            options={frequencyOption}
                                            value={frequencyOption.filter((option) => option.value === expandKpiList.frequency)}
                                            placeholder={"Select frequency"}
                                        />
                                    </span>
                                </div>
                                <div className="row d-flex py-2">
                                    <span className="col-5 py-2" style={{ fontWeight: 'bold', color: theme === 'dark' ? Colors.Dark[5] : Colors.Dark[0] }}>
                                        Key Dependencies :
                                    </span>
                                    <span className="col-7" style={{ color: Colors.Dark[0], fontWeight: 'normal' }}>
                                        <Selector
                                            id={"dependencies"}
                                            _handleSelect={(e) => _handleSelectChange(e, "dependencies")}
                                            options={dependenciesOption}
                                            value={dependenciesOption.filter((option) => option.value === expandKpiList.dependencies)}
                                            placeholder={"Select dependencies"}
                                        />
                                    </span>
                                </div>
                                <div className="row d-flex py-2">
                                    <span className="col-5 py-2" style={{ fontWeight: 'bold', color: theme === 'dark' ? Colors.Dark[5] : Colors.Dark[0] }}>
                                        Standards Alignment :
                                    </span>
                                    <span className="col-7" style={{ color: Colors.Dark[0], fontWeight: 'normal' }}>
                                        <Selector
                                            id={"standardsSelector"}
                                            _handleSelect={(e) => _handleSelectChange(e, "standards")}
                                            options={standardsOption}
                                            value={standardsOption.filter((option) => option.value === expandKpiList.standards)}
                                            placeholder={"Select standards"}
                                        />
                                    </span>
                                </div>
                                <div className="row d-flex py-2">
                                    <span className="col-5" style={{ fontWeight: 'bold', color: theme === 'dark' ? Colors.Dark[5] : Colors.Dark[0] }}>Notes :</span>
                                    <span className="col-7">
                                        <MyInput
                                            id="notes"
                                            placeHolder={"Please insert note"}
                                            isTextArea={true}
                                            value={expandKpiList.notes}
                                            onChange={(e) => _handleInputChange(e, "expandForm","notes")}
                                            height={'100px'}
                                        />
                                    </span>
                                </div>
                                <div className="row d-flex py-2">
                                    <span className="col-5" style={{ fontWeight: 'bold', color: theme === 'dark' ? Colors.Dark[5] : Colors.Dark[0] }}>Target :</span>
                                    <span className="col-7">
                                        <MyInput
                                            id="establishedTarget"
                                            placeHolder={"Please insert target"}
                                            isTextArea={true}
                                            value={expandKpiList?.establishedTarget}
                                            onChange={(e) => _handleInputChange(e, "expandForm","establishedTarget")}
                                            height={'100px'}
                                        />
                                    </span>
                                </div>
                                <div className="d-flex justify-content-between py-2">
                                    <span
                                        className="col-6"
                                    >
                                        <MyButton style={{ width: "90%" }} text="Reset All" customColor={Colors.primaryBule} customHoverColor={Colors.lightBlue} 
                                        onClick={(e) => _handleConfirmReset()}/>
                                    </span>
                                    <MyButton
                                        style={{ width: "90%", textAlign: "end" }}
                                        text={actionMode === "edit" ? "Update KPI" : "Add KPI"}
                                        onClick={(e) => _createKPIManagement() }
                                        customColor='#066CFF'
                                        customHoverColor={Colors.water}
                                    />
                                </div>
                            </div>
                        </div>
                    }

                </form>
                <ConfirmResetModal _handleConfirmReset={_handleConfirmReset} theme={theme} />
                <ConfirmDeleteModel _handleConfirmDelete={_handleConfirmReset} theme={theme}/>
            </div>
        );
    })
);