import { observable, action, makeObservable, runInAction, toJS } from "mobx";
import { Cookies } from "react-cookie";
import { toast } from "react-toastify";
import {
  fetchBuildingList,
  fetchBuildingType,
  addNewBuilding,
  fetchDeleteBuilding,
  fetchAssetList,
} from "../API/api.building";
import {
  fetchAccessmentFramework,
  fetchAccessmentFrameworkByBuildingId,
  fetchAddNewFramework,
  fetchCreateNewFramework,
  fetchFrameWorkProgress,
} from "../API/api.accessment";
import jwt_decode from "jwt-decode";
import {
  LoginFormValidation,
  RegisterFormValidation,
} from "../util/formValidation";
import {
  fetchActivityLog,
  fetchGroupReports,
  fetchY2yEnergy,
  fetchY2yS1Emissions,
  fetchY2yS2Emissions,
  fetchY2yS3Emissions,
  fetchY2yWater,
} from "../API/api.user";
import { noImageUrl } from "../API/URL";
import {
  FetchResendOTP,
  FetchVerifyOTP,
  fetchSiteModule,
} from "../API/api.user";
import jwtDecode from "jwt-decode";
import Store from ".";
import survey from "./survey";
import building from "./building";
import target from "./target";
import { fetchDataColumns } from "../API/api.quickOrganizationSetUp";

class Common {
  cookies = new Cookies();

  focusArea = this.cookies.get("focusArea");
  userId = this.cookies.get("userId");
  permittedBuilding = null;
  allBuildingType = null;
  accessmentList = null;
  token = this.cookies.get("Token");
  role = this.token && jwt_decode(this.token).role;
  siteModuleData = this.cookies.get("siteModuleData");
  companyName = this.siteModuleData?.companyName;
  trackingChart = this.siteModuleData?.trackingChart || "Monthly";
  groupStatus = this.siteModuleData?.modules?.Group_Report;
  buildingName = null;
  deleteCheckedArray = [];
  selectedCertifications = [];
  isClickedDeleteSite = false;
  certification = null;
  certificateArray = [];
  tokenStatus = "INACTIVE";
  uploadLogoImage = "";
  photo = null;
  frameworkDescription = "";
  frameworkTitle = "";
  activityLog = null;
  y2yEnergy = null;
  y2yS1Emissions = null;
  y2yS2Emissions = null;
  y2yS3Emissions = null;
  y2yWater = null;
  frameworkProgress = [];
  theme = localStorage.getItem("theme") === "light" ? "light" : "dark";
  isHover = false;
  hoverId = null;
  permittedBuildingLoading = false;
  demoSitePerformanceData = {};
  list = [];
  selectedYear = new Date().getFullYear();
  showBaseLineModal = false;
  viewStatus= "normalView";
  tableColumns = [];
  groupReports= [];
  yearOptions = [];
  minBaselineYear = 2018;
  assetList = [];

  constructor() {
    makeObservable(this, {
      token: observable,
      selectedYear: observable,
      role: observable,
      userId: observable,
      buildingName: observable,
      trackingChart: observable,
      permittedBuilding: observable,
      allBuildingType: observable,
      focusArea: observable,
      accessmentList: observable,
      deleteCheckedArray: observable,
      isClickedDeleteSite: observable,
      selectedCertifications: observable,
      certificateArray: observable,
      tokenStatus: observable,
      uploadLogoImage: observable,
      photo: observable,
      frameworkTitle: observable,
      frameworkDescription: observable,
      activityLog: observable,
      y2yEnergy: observable,
      y2yS1Emissions: observable,
      y2yS2Emissions: observable,
      y2yS3Emissions: observable,
      y2yWater: observable,
      frameworkProgress: observable,
      theme: observable,
      isHover: observable,
      hoverId: observable,
      permittedBuildingLoading: observable,
      demoSitePerformanceData: observable,
      siteModuleData: observable,
      list: observable,
      showBaseLineModal: observable,
      viewStatus: observable,
      tableColumns: observable,
      groupReports: observable,
      groupStatus: observable,
      yearOptions: observable,
      assetList: observable,

      setSelectedYear: action.bound,
      _handleViewDetail: action.bound,
      getBuildingList: action.bound,
      getAllBuildingType: action.bound,
      getAccessmentFramework: action.bound,
      setDeleteSiteChecked: action.bound,
      _handleDeleteSite: action.bound,
      setClickedDeleteSite: action.bound,
      _cancelDeleteSite: action.bound,
      _handleCertificationCheck: action.bound,
      _handleTargetCheck: action.bound,
      addDefaultSrc: action.bound,
      _handleSaveCertificate: action.bound,
      _handleDeleteBuildingFromOneTimePage: action.bound,
      _handleVerifyOTP: action.bound,
      _handleResendOTP: action.bound,
      _handleUploadImage: action.bound,
      _resetImage: action.bound,
      _handleFrameworkDescription: action.bound,
      _handleFrameworkTitle: action.bound,
      hideBaseLineModal: action.bound,
      _handleCreateFramework: action.bound,
      getActivityLog: action.bound,
      getGroupReports: action.bound,
      getY2yEnergy: action.bound,
      getY2yS1Emissions: action.bound,
      getY2yS2Emissions: action.bound,
      getY2yS3Emissions: action.bound,
      getY2yWater: action.bound,
      getFrameworkProgress: action.bound,
      onChangeTheme: action.bound,
      _handleMouseHover: action.bound,
      _handleSelectPeriod: action.bound,
      _handleChangeDetailView: action.bound,
      _handleGetTableColumns: action.bound,
      _handleGetYearOptions: action.bound,
      getAssetList: action.bound,
    });
  }

  _handleGetYearOptions = (baselineYear) => {
    let years = [];
    const currentYear = new Date().getFullYear()
    for (let i = baselineYear; i <= currentYear; i++) {
      years.push({
        label: String(i),
        value: Number(i)
      })
    }
    this.yearOptions = years;
    this.list = years;
  }

  _handleGetTableColumns = () => {
    fetchDataColumns((err, data) => {
      if (data && data.success) {
        runInAction(() => {
          this.tableColumns = data.payload;
        });
      } else {
        toast.error(err);
      }
    });    
  }

  _handleMouseHover(k) {
    runInAction(() => (this.isHover = !this.isHover), (this.hoverId = k));
  }

  onChangeTheme() {
    survey.status = undefined;
    this.theme = this.theme === "dark" ? "light" : "dark";
    localStorage.setItem("theme", this.theme);
    var x = document.getElementsByTagName("BODY")[0];
    this.theme === "dark"
      ? (x.style.backgroundImage = "linear-gradient(-60deg,#101841,#202b60)")
      : (x.style.backgroundImage = "linear-gradient(-60deg,#F6F6F6,#F6F6F6)");
  }

  setSelectedYear = (year) => {
    this.selectedYear = year;
    this.getY2yEnergy();
    this.getGroupReports();
  };

  _handleUploadImage = (e) => {
    this.uploadLogoImage = URL.createObjectURL(e.target.files[0]);
    this.photo = e.target.files[0];
  };

  _handleChangeDetailView = (label) => {
    this.viewStatus = label;
  }

  _resetImage = () => {
    this.uploadLogoImage = "";
    this.photo = null;
  };
  _handleCreateFramework = (buildingId, frameworkData, checkFrameworks) => {
    
    const frameworkList = toJS(frameworkData)?.reduce((r,c) => {
      const R = [...r];
      const index = toJS(checkFrameworks)?.findIndex(v => v._id === c._id);
      if(index > -1){
        R.push(toJS(checkFrameworks[index]));
      }else {
        R.push(c);
      }
      return R;
    }, []);

    const framework = frameworkList?.filter((v) => v.checked === true);

    const frameworkName = frameworkList?.filter((v) => v.checked === true)
      ?.map((v1) => v1.name)
      ?.toString();

    let confirmBox = window.confirm(
      `Are you sure you want to save ${frameworkName} changes?`
    );

    if (confirmBox === true) {
      fetchAddNewFramework(
        buildingId,
        framework.map((v) => v._id),
        (err, data) => {
          if (err) {
            toast.error(err);
          } else {
            if (!data.error) {
              toast.success("Updated Successfully.");
              this.getBuildingList()
              building.getCheckedFramework(this.accessmentList, this.permittedBuilding?.find((v1) => v1._id === buildingId)?.surveyHeader);
              survey.getAllSurvey(buildingId);
              if (
                framework.filter((d) => d._id === "62da2e0c8dc52269efb8e7b1")
                  .length
              ) {
                this.showBaseLineModal = true;
              }

              // window.location.reload();
            } else toast.error(data.error);
          }
        }
      );
    } else {
      console.log("cancel");
    }



    // console.log("create framework > ", framework);
    // if (this.frameworkDescription && this.frameworkTitle && this.photo) {

    //   var formData = new FormData();
    //   formData.append("title", this.frameworkTitle);
    //   formData.append("photo", this.photo);
    //   formData.append("header", this.frameworkDescription);

    //   fetchCreateNewFramework(buildingId, formData, (err, data) => {
    //     if (err) {
    //       toast.error(err);
    //     } else {
    //       if (!data.error) {
    //         toast.success(data.payload);
    //         window.location.reload();
    //       } else toast.error(data.error);
    //     }
    //   });
    // }
  };

  hideBaseLineModal = () => {
    this.showBaseLineModal=false;
    // building._handleFrameworkCheck('62da2e0c8dc52269efb8e7b1')
  };

  _handleFrameworkTitle = (e) => {
    this.frameworkTitle = e.target.value;
  };
  _handleFrameworkDescription = (e) => {
    this.frameworkDescription = e.target.value;
  };

  _handleViewDetail = (focusArea, nevigate) => {
    this.focusArea = focusArea;
    this.cookies.set("focusArea", focusArea);
    window.location.pathname = "/tracking";
  };

  getAssetList() {
    fetchAssetList((err, data) => {
      if (err) {
        toast.error(err);
      } else {
        if (!data.error) {
          runInAction(() => {
            this.assetList = data.payload;            
          });
        }
      }
    });
  }

  getBuildingList() {
    this.permittedBuildingLoading = true;
    const temp = [];
    fetchBuildingList((err, data) => {
      if (err) {
        toast.error(err);
      } else {
        if (!data.error) {
          runInAction(() => {
            this.permittedBuilding = data.payload;
            // for(let v=0; v<data.payload.length; v++){
            //   for(let a=0; a<data.payload[v].surveyHeader.length; a++){
            //     temp.push({
            //       ...data.payload[v].surveyHeader[a],
            //       isTracking: false
            //     });
            //     this.certificateArray = temp;
            //   }
            // }

            // change sitePerformance data from excel
            const excelData = {
              "SYSCOM Office": 90,
              Conrad: 100,
              Regent: 75,
              "Ritz Carlton": 0,
              Capella: 75,
              "Bugis Office": 119,
              "Bukit Merah Office": 100,
              "Solaris Office": 100,
              "Innovis Office": 100,
            };
            // for (var i = 0; i < this.permittedBuilding.length; i++) {
            //   this.demoSitePerformanceData.push(
            //     Math.floor(Math.random() * 100) + 1
            //   );
            // }
            this.demoSitePerformanceData = excelData;
            this.permittedBuildingLoading = false;
            this.certificateArray =
              data?.payload?.[Store.target.selectedIndex]?.surveyHeader;
          });
        }
      }
    });
  }

  getActivityLog(status) {
    this.activityLog = null;
    fetchActivityLog(status, (err, data) => {
      this.activityLog = [];
      if (err) {
        toast.error(err);
        this.activityLog = null;
      } else {
        if (!data.error) {
          runInAction(() => {
            this.activityLog = data.payload;
          });
        }
      }
    });
  }

  getGroupReports() {
    this.groupReports = null;
    fetchGroupReports(this.selectedYear, (err, data) => {
      if (err) {
        toast.error(err);
      } else {
        if (!data.error) {
          runInAction(() => {
            this.groupReports = data.payload;
          });
        }
      }
    });
  }

  

  getY2yEnergy() {
    this.y2yEnergy = null;
    fetchY2yEnergy((err, data) => {
      this.y2yEnergy = [];
      if (err) {
        toast.error(err);
        this.y2yEnergy = null;
      } else {
        if (!data.error) {
          if (data.payload?.length > 0) {
            //const totalValue = data.payload.length > 0 && data.payload[0].data.find(v => v.year == toJS(this.selectedYear))?.value;
            //let listTotalValue = [];
            data.payload.forEach((data) => {
              if (data.data.length > this.list.length) {
                const tempArr = [];
                data.data.forEach((d) =>
                  tempArr.push({ label: d.year, value: d.year })
                );
                this.list = tempArr;
              }
            });
            let listTotalValue = data.payload[0].data.reduce((p, c) => {
              p.set(c.year, c.value);
              return p;
            }, new Map());

            runInAction(() => {
              const percentageValues = data.payload.map((v, k) => {
                return {
                  ...v,
                  data: v.data.map((vv, kk) => {
                    //const convertedValue = vv.value*0.0036;
                    //const totalYear = v.data.filter(t=> t.year == vv.year)[0]
                    return {
                      year: vv.year,
                      value: vv.value.toFixed(2),
                      percentage:
                        (vv.value / listTotalValue.get(vv.year)) * 100,
                    };
                  }),
                };
              });
              this.y2yEnergy = percentageValues;
            });
          }
        }
        // else {
        //   const dummyY2yEnergy = [
        //     { label: "Total y2y energy", data: [{year: "2022", value: 18238242}, {year: "2021", value: 83313}] },
        //     { label: "Percentage energy 1", data: [{year: "2022", value: 428540}, {year: "2021", value: 0}] },
        //     { label: "Percentage energy 2", data: [{year: "2022", value: 900}, {year: "2021", value: 0}] },
        //     { label: "Percentage energy 3", data: [{year: "2022", value: 0}, {year: "2021", value: 0}] }
        //   ];
        //   const totalValue = dummyY2yEnergy[0].data[0]?.value;
        //   runInAction(() => {
        //     const percentageValues = dummyY2yEnergy.map((v,k) => {
        //       return {
        //         ...v,
        //         data: v.data.map((vv,kk) => {
        //             const convertedValue = vv.value*0.0036;
        //               return {
        //                 year: vv.year,
        //                 value: k === 0 ? convertedValue.toFixed(2) : ((convertedValue * 100) / totalValue).toFixed(2)
        //               }
        //             })
        //       }
        //     });
        //     this.y2yEnergy = percentageValues;
        //   });
        // }
      }
    });
  }

  getY2yS1Emissions() {
    this.y2yS1Emissions = null;
    fetchY2yS1Emissions((err, data) => {
      this.y2yS1Emissions = [];
      if (err) {
        toast.error(err);
        this.y2yS1Emissions = null;
      } else {
        if (!data.error) {
          if (data.payload?.length > 0) {
            data.payload.forEach((data) => {
              if (data.data.length > this.list.length) {
                const tempArr = [];
                data.data.forEach((d) =>
                  tempArr.push({ label: d.year, value: d.year })
                );
                this.list = tempArr;
              }
            });
            runInAction(() => {
              //////////// Convert MtCo2e from kgCo2 for Digital Edge ///////////
              const convertedValues = data.payload.map((v, k) => {
                return {
                  ...v,
                  data: v.data.map((vv, kk) => {
                    return {
                      ...vv,
                      value: vv.value * 0.001,
                    };
                  }),
                };
              });
              this.y2yS1Emissions = convertedValues;
            });
          }
        }
      }
    });
  }

  getY2yS2Emissions() {
    this.y2yS2Emissions = null;
    fetchY2yS2Emissions((err, data) => {
      this.y2yS2Emissions = [];
      if (err) {
        toast.error(err);
        this.y2yS2Emissions = null;
      } else {
        if (!data.error) {
          if (data.payload?.length > 0) {
            data.payload.forEach((data) => {
              if (data.data.length > this.list.length) {
                const tempArr = [];
                data.data.forEach((d) =>
                  tempArr.push({ label: d.year, value: d.year })
                );
                this.list = tempArr;
              }
            });
            runInAction(() => {
              const convertedValues = data.payload.map((v, k) => {
                return {
                  ...v,
                  data: v.data.map((vv, kk) => {
                    return {
                      ...vv,
                      value: vv.value * 0.001,
                    };
                  }),
                };
              });
              this.y2yS2Emissions = convertedValues;
            });
          }
        }
      }
    });
  }

  getY2yS3Emissions() {
    this.y2yS3Emissions = null;
    fetchY2yS3Emissions((err, data) => {
      this.y2yS3Emissions = [];
      if (err) {
        toast.error(err);
        this.y2yS3Emissions = null;
      } else {
        if (!data.error) {
          if (data.payload?.length > 0) {
            data.payload.forEach((data) => {
              if (data.data.length > this.list.length) {
                const tempArr = [];
                data.data.forEach((d) =>
                  tempArr.push({ label: d.year, value: d.year })
                );
                this.list = tempArr;
              }
            });
            runInAction(() => {
              const convertedValues = data.payload.map((v, k) => {
                return {
                  ...v,
                  data: v.data.map((vv, kk) => {
                    return {
                      ...vv,
                      value: vv.value * 0.001,
                    };
                  }),
                };
              });
              this.y2yS3Emissions = convertedValues;
            });
          }
        }
      }
    });
  }

  getY2yWater() {
    this.y2yWater = null;
    fetchY2yWater((err, data) => {
      this.y2yWater = [];
      if (err) {
        toast.error(err);
        this.y2yWater = null;
      } else {
        if (!data.error) {
          if (data.payload?.length > 0) {
            data.payload.forEach((data) => {
              if (data.data.length > this.list.length) {
                const tempArr = [];
                data.data.forEach((d) =>
                  tempArr.push({ label: d.year, value: d.year })
                );
                this.list = tempArr;
              }
            });
            runInAction(() => {
              this.y2yWater = data.payload;
            });
          }
        }
      }
    });
  }

  getFrameworkProgress() {
    fetchFrameWorkProgress((err, data) => {
      this.frameworkProgress = [];
      if (err) {
        this.frameworkProgress = null;
      } else {
        if (!data.error) {
          runInAction(() => {
            this.frameworkProgress = data.payload;
          });
        }
      }
    });
  }

  getAllBuildingType() {
    fetchBuildingType((err, data) => {
      if (err) {
        toast.error(err);
      } else {
        if (!data.error) {
          runInAction(() => {
            this.allBuildingType = data.payload;
          });
        }
      }
    });
  }

  getFrameworkByBuildingId(buildingId) {
    fetchAccessmentFrameworkByBuildingId(buildingId, (err, data) => {
      if (err) {
        toast.error(err);
      } else {
        if (!data.error) {
          runInAction(() => {
            this.accessmentList = data.payload;
            // this.certificateArray = data.payload.map(v => {
            //   return {
            //     ...v,
            //     imageURL: v.imageURL,
            //     name: v.name,
            //     id: v._id,
            //     isTracking: false,
            //   }
            // });
          });
        } else {
          toast.error(data.error);
        }
      }
    });
  }

  async getAccessmentFramework() {
    await fetchAccessmentFramework((err, data) => {
      if (err) {
        toast.error(err);
      } else {
        if (!data.error) {
          runInAction(() => {
            this.accessmentList = data.payload;
            let value = data.payload[0];

            this.certification = {
              imageURL: value.imageURL,
              name: value.name,
              id: value._id,
              isTracking: false,
            };
            const isSelected = this.selectedCertifications.findIndex(
              (v) => v.id === value._id && v.name === value.name
            );

            if (isSelected === -1) {
              runInAction(() => {
                this.selectedCertifications = [
                  ...this.selectedCertifications,
                  this.certification,
                ];
              });
            }

            // this.certificateArray = [...this.selectedCertifications];
          });
        }
      }
    });

    return this.accessmentList;
  }

  setClickedDeleteSite(isClicked) {
    this.isClickedDeleteSite = isClicked;
  }

  setDeleteSiteChecked(id, isChecked) {
    var index = this.deleteCheckedArray.indexOf(
      this.deleteCheckedArray.find((v) => v === id)
    );
    if (isChecked) {
      this.deleteCheckedArray.push(id);
    } else this.deleteCheckedArray.splice(index, 1);
  }

  _cancelDeleteSite() {
    this.setClickedDeleteSite(false);
    this.deleteCheckedArray = [];
  }

  // _handleConfirmDeleteSite(){
  //     this.showConfirmDeleteModal=true
  // }

  _handleDeleteBuildingFromOneTimePage(b_id) {
    this.deleteCheckedArray.push(b_id);
    this._handleDeleteSite();
  }

  _handleDeleteSite() {
    fetchDeleteBuilding(
      { buildingIdArray: this.deleteCheckedArray },
      (err, data) => {
        if (err) {
          toast.error(err);
        } else {
          if (data.error) {
            toast.error(data.error);
          } else {
            toast.success(data.payload);
            window.location.reload();
          }
        }
      }
    );
    //
  }

  _handleCertificationCheck(value) {
    this.certification = {
      imageURL: value.imageURL,
      name: value.name,
      id: value._id,
      isTracking: false,
    };
    const isSelected = this.selectedCertifications.findIndex(
      (v) => v.id === value._id && v.name === value.name
    );

    if (isSelected === -1) {
      runInAction(() => {
        this.selectedCertifications = [
          ...this.selectedCertifications,
          this.certification,
        ];
      });
    } else {
      this.selectedCertifications.splice(isSelected, 1);
    }
  }

  _handleSaveCertificate() {
    this.certificateArray = [...this.selectedCertifications];
  }

  _handleTargetCheck(value) {
    value = { ...value, isTracking: !value.isTracking };
    const isSelected = this.certificateArray.findIndex(
      (v) => v.id === value.id
    );

    if (isSelected === -1) {
    } else {
      this.certificateArray[isSelected] = value;
    }
  }

  addDefaultSrc = (ev) => {
    // ev.target.src = noImageUrl;
  };

  _handleVerifyOTP = (otp) => {
    FetchVerifyOTP(otp, (err, data) => {
      if (err) {
        toast.error(err);
      }
      {
        if (data.error) {
          toast.error(data.error);
        } else {
          const buildingCount =
            this.token && jwtDecode(this.token).buildingId.length;
          const { tokenStatus } = data.payload;
          toast.success("User Verified");
          runInAction(() => {
            this.tokenStatus = tokenStatus;
          });
          this.cookies.set("tokenStatus", tokenStatus);
          if (this.tokenStatus === "ACTIVE" && buildingCount === 0) {
            window.location.pathname = "/quick-organization-setup"; //building-and-user-registration
          } else if (this.tokenStatus === "ACTIVE" && buildingCount !== 0)
            window.location.pathname = "/home";
          toast.error("Someting Went Wrong.");
        }
      }
    });
  };

  _handleResendOTP = () => {
    FetchResendOTP((err, data) => {
      if (data.error) {
        toast.error(data.error);
      } else {
        toast.success("OTP code send to your email.");
      }
    });
  };

  _handleSelectPeriod = (e) => {
    this.trackingChart = e.value;
  };
}

export default new Common();
