import Store from "../Store";
import * as API from "./URL";

export const fetchMaualEntryData = async (buildingId,frameworkId,year, category, callback) => {
    return fetch(API.manualEntryDataAPI(buildingId,frameworkId,year,category), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((res) => res.json())
      .then((data) => callback(null, data))
      .catch((err) => callback(err.toString(), null));
  };

  export const fetchIndividualTracking = async (year, callback) => {
    const {focusArea,companyName} = Store.common;
    let url;
    if(focusArea == "Scope 3 Emission"){
      url = companyName == 'Enterprise SG' ? API.scope3Tracking : API.scope3BuildingTracking;
    }else if(focusArea==='Scope 2 Emission'){
      url = API.scope2Tracking;
    }else if(focusArea==='Scope 1 Emission'){
      url = companyName !== 'Enterprise SG' ? API.scope1TrackingByBuilding: API.scope1Tracking;
    }else if(focusArea==='Water') {
      url = API.waterTracking;
    }else{
      url = API.energyTracking;
    }
    
    return fetch(
                  // focusArea==='Scope 3 Emission'?
                  //   companyName == 'Enterprise SG' ? API.scope3Tracking : 
                  //         focusArea==='Scope 2 Emission'? 
                  //           API.scope2Tracking : 
                  //           focusArea==='Scope 1 Emission' && companyName==='Syscom' ?
                  //               API.scope1TrackingByBuilding:focusArea==='Scope 1 Emission'?
                  //               API.scope1Tracking:focusArea==='Water'?
                  //                 API.waterTracking:API.energyTracking, 
                  url+`?year=${year}`
                  ,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((res) => res.json())
      .then((data) => callback(null, data))
      .catch((err) => callback(err.toString(), null));
  };

  export const fetchBreakDownReport = async (buildingId, year, callback) => {
    return fetch(API.breakDownReport(buildingId, year), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        "Access-Control-Allow-Origin": "*",
      },
    }) 
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err.toString(), null));
  }

  export const fetchScopeDataByCategory = async (focusArea, callback) => {
    const api = focusArea === 'Scope 1 Emission' ? API.scope1Tracking : API.scope3Tracking
    return fetch(api, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        "Access-Control-Allow-Origin": "*",
      },
    }) 
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err.toString(), null));
  }

  export const fetchY2yTrackingEnergy = async (callback) => {
    return fetch(API.getY2yTrackingEnergy, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((res) => res.json())
      .then((data) => callback(null, data))
      .catch((err) => callback(err, null));
  };

  export const fetchY2yTrackingS1Emissions = async (callback) => {
    return fetch(API.getY2yTrackingS1Emissions, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((res) => res.json())
      .then((data) => callback(null, data))
      .catch((err) => callback(err, null));
  };