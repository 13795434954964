import { group, json } from "d3";
import * as API from "./URL";

export const fetchAllGroups = async(callback) => {
    return fetch(API.getAllGroups,{
        method : "get",
        headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            "Access-Control-Allow-Origin": "*",
        },
    })
        .then((res) => res.json())
        .then((data) => callback(null,data))
        .catch((err) => callback(err.toString(),null));
}

export const createNewGroup = async(groupData,callback) => {
    
    return fetch(API.createNewGroup, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(groupData)
    })
        .then((res) => res.json())
        .then((data) => callback(null,data))
        .catch(err => callback(err.toString(),null));
}

export const updateGroupName = async(groupData,callback) => {
    return fetch(API.editGroupName,{
        method: "PUT",
        headers: {
            "content-Type": "application/json",
            Accept: "*/*",
            "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(groupData)
    })
        .then((res) => res.json())
        .then((data) => callback(null,data))
        .catch(err => callback(err.toString(),null));
}

export const fetchConsumptionDataByYear = async(params,callback)=> {
    return fetch(API.getConsumptionDataByYear(params),{
        method : "get",
        headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            "Access-Control-Allow-Origin": "*",
        },
    })
        .then((res) => res.json())
        .then((data) => callback(null,data))
        .catch((err) => callback(err.toString(),null));
}

export const fetchUpdateMultiConsumption = async(data,year,callback) => {
    return fetch(API.updateMultiConsumptionAPI(year),{
        method: "POST",
        headers: {
            "content-Type": "application/json",
            Accept: "*/*",
            "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(data)
    })
        .then((res) => res.json())
        .then((data) => callback(null,data))
        .catch(err => callback(err.toString(),null));
}

export const fetchEmissionDataByYear = async(params,callback)=> {
    return fetch(API.getEmissionDataByYear(params),{
        method : "get",
        headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            "Access-Control-Allow-Origin": "*",
        },
    })
        .then((res) => res.json())
        .then((data) => callback(null,data))
        .catch((err) => callback(err.toString(),null));
}

export const fetchUpdateMultiEmission = async(data,year,callback) => {
    return fetch(API.updateMultiEmissionAPI(year),{
        method: "POST",
        headers: {
            "content-Type": "application/json",
            Accept: "*/*",
            "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(data)
    })
        .then((res) => res.json())
        .then((data) => callback(null,data))
        .catch(err => callback(err.toString(),null));
}

export const fetchAssetData = async(groupId,callback)=> {
    return fetch(API.getAssetData(groupId),{
        method : "get",
        headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            "Access-Control-Allow-Origin": "*",
        },
    })
        .then((res) => res.json())
        .then((data) => callback(null,data))
        .catch((err) => callback(err.toString(),null));
}

export const fetchUpdateAssetsData = async (data, scope, callback) => {
    return fetch(API.updateAssetsData,{
        method: "PUT",
        headers: {
            "content-Type": "application/json",
            Accept: "*/*",
            "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(data)
    })
        .then((res) => res.json())
        .then((data) => callback(null,data))
        .catch(err => callback(err.toString(),null));
}

export const fetchDeleteGroup = async (data, callback) => {
    return fetch(API.deleteGroupData,{
        method: "PUT",
        headers: {
            "content-Type": "application/json",
            Accept: "*/*",
            "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(data)
    })
        .then((res) => res.json())
        .then((data) => callback(null,data))
        .catch(err => callback(err.toString(),null));
}

export const fetchDeleteAssets = async(data,callback)=> {
    return fetch(API.deleteAssets,{
        method: "POST",
        headers: {
            "content-Type": "application/json",
            Accept: "*/*",
            "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(data)
    })
        .then((res) => res.json())
        .then((data) => callback(null,data))
        .catch(err => callback(err.toString(),null));
}

export const fetchCompanyFileList = async(callback)=> {
    return fetch(API.companyFileUpload,{
        method: "GET",
        headers: {
            "content-Type": "application/json",
            Accept: "*/*",
            "Access-Control-Allow-Origin": "*",
        },
    })
        .then((res) => res.json())
        .then((data) => callback(null,data))
        .catch(err => callback(err.toString(),null));
}

export const fetchCompanyFileUpload = async(data,callback)=> {
    return fetch(API.companyFileUpload,{
        method: "POST",
        headers: {
            // "content-Type": "application/json",
            Accept: "*/*",
            // "Access-Control-Allow-Origin": "*",
        },
        body: (data)
    })
        .then((res) => res.json())
        .then((data) => callback(null,data))
        .catch(err => callback(err.toString(),null));
}

export const fetchCheckUploadedFile = async(id,fileName,callback)=>{
    return fetch(API.checkUploadFile(id,fileName),{
        method: "Get",
        headers: {
            "content-Type": "application/json",
            Accept: "*/*",
            "Access-Control-Allow-Origin": "*",
        },
    })
        .then((res) => res.json())
        .then((data) => callback(null,data))
        .catch(err => callback(err.toString(),null));
}

export const fetchDeleteUploadedFile = async(id,fileName,callback)=>{
    return fetch(API.forceDeleteUploadFile(id, fileName),{
        method: "DELETE",
        headers: {
            "content-Type": "application/json",
            Accept: "*/*",
            "Access-Control-Allow-Origin": "*",
        },
    })
        .then((res) => res.json())
        .then((data) => callback(null,data))
        .catch(err => callback(err.toString(),null));
}

export const fetchAddConsumptionDataByYear = async(data,year,callback)=>{
    return fetch(API.addConsumptionDataByYear(year),{
        method: "POST",
        headers: {
            "content-Type": "application/json",
            Accept: "*/*",
            "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(data)
    })
        .then((res) => res.json())
        .then((data) => callback(null,data))
        .catch(err => callback(err.toString(),null))
}