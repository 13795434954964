import { inject, observer } from "mobx-react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import UserForm from "../../Components/UserManagement/UserForm";
import UserTable from "../../Components/UserManagement/UserTable";
import { MyButton } from "../../Tools";
import Store from "../../Store";
import "../../App.css";
import { useEffect, useState } from "react";
import ConfirmationModal from "../../Components/UserManagement/ConfirmationModal";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Navigator from "../../Tools/MyNavigator";


const UserManagementContainer = () => {
  const {
    showUserForm,
    formMode,
    formData,
    visible,
    editedUser,
    allUsers,
    loading,
    getUserData,
    _handleView,
    _handleEditUser,
    _handleCreateUser,
    _handleCloseForm,
    _handleInputChange,
    _handleRadioChange,
    _handleSelect,
    _handleSubmit,
    _handleDeleteUser,
    _handleRoleSelect
  } = Store.manageUsers;
  const { getBuildingList, permittedBuilding, theme, groupStatus } = Store.common;
  const { t } = useTranslation("user_management");
  const [selectedId, setSelectedId]= useState(null)
  
  useEffect(()=> {
    setSelectedId(formData?._id)
  }, [formData?._id])

  useEffect(() => {
    getUserData();
    getBuildingList();
  }, []);
  
  return (
    <>
    <Navigator pageName={t("Account Management And Creation")} 
      goTo={t('Back')} 
      link={groupStatus? '/home-group' : '/home'}
      // handleBack = {() => window.history.back()} 
    />
    <div className="m-3 mt-0" style={{ color: invertDark(theme) }}>
      {/* <span
        style={{
          fontSize: 20,
          color: invertDark(theme),
          alignItems: "center",
          display: "flex",
          cursor: "pointer",
        }}
        onClick={() => navigate("/home")}
      >
        <i className="bi bi-arrow-left-circle-fill pe-3"></i>Back
      </span> */}
     
      <div className="d-flex justify-content-between pt-3 ps-2">
        {/* <div style={{ fontSize: 25 }}>{ t("Account Management And Creation") }</div> */}
        <MyButton
          text={ t("Create New User") }
          fontSize={15}
          leftIcon={<i className="bi bi-person-plus-fill me-1"></i>}
          customColor={"#0d6efd"}
          onClick={_handleCreateUser}
        // customHoverColor={Colors.lightBlue}
        />
      </div>
      <div className="row d-flex justify-content-center">
        <div className={`col-lg-8 col-md-7 col-sm-12 mb-2 p-4`}>
          <div className="p-3"
            style={{ background: theme === 'dark' ? Colors[theme].secondBackground : Colors.Dark[5], borderRadius: 4, boxShadow: '2px 4px 11px rgba(0,0,0,0.25)' }}>
            <UserTable
              theme={theme}
              allUsers={allUsers}
              selectedId={selectedId}
              _handleEditUser={_handleEditUser}
              permittedBuilding={permittedBuilding}
              loading={loading}
            />
          </div>
        </div>

        <div className={`col-lg-4 col-md-5 col-sm-12 my-2 p-4 `}>
          {(showUserForm) ? (
            <UserForm
              theme={theme}
              _handleRoleSelect={_handleRoleSelect}
              formMode={formMode}
              editedUser={editedUser}
              formData={{ ...formData }}
              visible={visible}
              _handleView={_handleView}
              _handleCloseForm={_handleCloseForm}
              _handleInputChange={_handleInputChange}
              _handleSelect={_handleSelect}
              _handleRadioChange={_handleRadioChange}
              _handleSubmit={_handleSubmit}
              permittedBuilding={permittedBuilding}
              _handleDeleteUser={_handleDeleteUser}
            />
          ) : (
            <div
              className="d-flex align-items-center justify-content-center p-3 text-secondary h-100 text-center"
              style={{ background: theme === 'dark' ? Colors[theme].secondBackground : Colors.Dark[5], borderRadius: 5, boxShadow: '2px 4px 11px rgba(0,0,0,0.25)' }}
            >
              <h5 className="mb-0">
                { t("Create New User") }
              </h5>
            </div>
          )}
        </div>
        <ConfirmationModal />
      </div>
    </div>
    </>
  );
};

export default inject("store")(observer(UserManagementContainer));
