import React,{useState,useEffect} from "react";
import {
  ComposedChart,
  Label,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Scatter,
  ResponsiveContainer,
} from "recharts";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { inject, observer } from "mobx-react";
import ReactDOMServer from "react-dom/server";

export const DyAnalysisChart = (props) => {
  const theme = "light";

  const data = props.data.chartData.data

  const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <div
          style={{
            background: "white",
            border: "1px solid #ccc",
            padding: 10,
          }}
        >
          <p className="label" style={{ textAlign: "center" }}>{`${label}`}</p>
          {payload.map((d) => (
            <div key={d}>
              <p style={{ color: d.color }}>{`${d.dataKey} : ${d.value}`}</p>
            </div>
          ))}
        </div>
      );
    }

    return null;
  };

  const CustomizedTick = ({ x, y, payload }) => (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={8}
        fontSize={12}
        textAnchor="end"
        fill="#666"
        transform="rotate(-45)"
      >
        {payload.value}
      </text>
    </g>
  );

  return (
    <div style={{ textAlign:'center',marginBottom: 20, border: "1px solid #E5DFDF", padding: 10 }}>
      <div
        className="d-flex"
        style={{
          fontSize: 22,
          justifyContent: "center",
          color: "gray",
          margin: 10,
        }}
      >
        {"Analysis Result of GHG emission"}
      </div>
      <ResponsiveContainer
        width={"100%"}
        height={400}
        style={{ background: Colors.white, color: invertDark(theme) }}
      >
        <ComposedChart data={data}>
          <CartesianGrid stroke="#f5f5f5" strokeDasharray="3 3" />

          <XAxis dataKey="name" height={80} />

          <YAxis type="number">
            <Label
              value="Emission kg CO2"
              position="insideLeft"
              angle={-90}
              style={{ textAnchor: "middle" }}
            />
          </YAxis>
          <Tooltip />

          <Bar dataKey="Emission" barSize={30} fill="#ff7400" />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};



// export const DyAnalysisChart = (props) => {
//   const [chartHtmlState, setChartHtmlState] = useState('');

//   useEffect(() => {
//     const chartHtml = ReactDOMServer.renderToStaticMarkup(
//       <DynamicChart data={props.data} />
//     );
//     setChartHtmlState(chartHtml);
//   }, [props.data]);


//   return <div dangerouslySetInnerHTML={{ __html: chartHtmlState }} />;
// };