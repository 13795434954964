import { SiteInfo } from "../../Components/Dashboard/SiteInfo";
import FilterBar from "../../Components/Dashboard/FilterBar";
import { FocusArea, FocusAreaKW } from "../../Components/Dashboard/FocusAreaOld";
import { FocusAreaNew } from "../../Components/Dashboard/FocusArea";
import { SitePerformance } from "../../Components/Dashboard/SitePerformance";
import { GroupSitePerformance } from "../../Components/Dashboard/GroupSitePerformance"

import focusArea from "../../Assets/focusArea.json";
import kingWanArea from "../../Assets/kingWanArea.json";
import kingWanData from "../../Assets/kingWan.json";
import { ActivityLog, ActivityLog1 } from "../../Components/Dashboard/ActivityLog";
import NetZeroChart from "../../Components/Dashboard/netZeroChart";
import Store from "../../Store";
import { useEffect } from "react";
import { inject, observer } from "mobx-react";
import { Cookies } from "react-cookie";
import rightArrow from "../../Assets/images/rightArrow.svg";
import { AssessmentTracking } from "../../Components/Dashboard/AssessmentTracking";
import { GroupReport } from "../../Components/Dashboard/GroupReports";

import { Colors, invertDark } from "../../Assets/Config/color.config";
import ActivityLogModal from "../../Components/Dashboard/ActivityLogModal"
import { EsgTracking } from "../../Components/Dashboard/EsgTracking";
import { toJS } from "mobx";
import { ThousandSeparator } from "../../Assets/Config/thousandSeparator.config";
import { HistoryDataView } from "../HistoryData/HistoryDataView";


export const DashboardContainer = inject("store")(
  observer((props) => {
    const cookies = new Cookies();
    const userName = cookies.get("userName");
    const Token = cookies.get("Token")
    const { getBuildingList, getGroupReports,activityLog, getActivityLog, selectedYear, getFrameworkProgress, theme, siteModuleData, getY2yEnergy, getY2yS1Emissions, getY2yS2Emissions, getY2yS3Emissions, getY2yWater } = props.store.common;
    const modules = siteModuleData ? siteModuleData?.modules : null;
    const focusAreaList = [];
    const areas = siteModuleData ? siteModuleData.modules ? siteModuleData.modules.FocusArea ? siteModuleData.modules.FocusArea.map((f, x) => focusAreaList.push(f)) : null : null : null

    useEffect(() => {
      if (siteModuleData?.modules?.Percent_Api_Call) {
        modules?.FocusArea.forEach((f, x) => {

          switch (f) {
            case "Energy":
              getY2yEnergy()
              break;
            case "Scope 1 Emission":
              getY2yS1Emissions()
              break;
            case "Scope 2 Emission":
              getY2yS2Emissions()
              break;
            case "Scope 3 Emission":
              getY2yS3Emissions()
              break;
            case "Water":
              getY2yWater()
              break;
          }
        });
      }

    }, [siteModuleData]);

    useEffect(() => {
      getBuildingList();
      getActivityLog();
      getFrameworkProgress();
      getGroupReports();
      // focusArea.map((f,x) => {
      //   if(theme === "light" && f.key === "plastic"){
      //     f.key = "plastic_black";
      //   } else if(theme === "dark" && f.key === "plastic_black"){
      //     f.key = "plastic"
      //   }
      // });

    }, []);

    if (modules) {
      return (
        <>

          <div className={`${modules.Activity_Log ? 'container-fluid' : 'container'}`}
            style={{ color: invertDark(theme) }}
          >
            <FilterBar modules={modules} />
            <div className="row m-0">
              {/* col 1 */}
              <div className={`px-2 ${modules.Activity_Log ? 'col-lg-4' : 'col-lg-6'} col-md-6`}>
                {
                  // userName === "King Wan"
                  //   ? kingWanArea.map((f, x) => <FocusAreaKW focusArea={f} kingWanData={kingWanData} key={x} name={f.key} />)
                  //   : 
                  // <FocusAreaNew />
                  modules?.Percent_Api_Call ?

                    (modules?.FocusArea.map((f, x) => {

                      const { y2yEnergy, y2yS1Emissions, y2yS2Emissions, y2yS3Emissions, y2yWater } = props.store.common;
                      // const totalValue = toJS(y2yEnergy)?.[0]?.data.find(v => v.year == selectedYear)?.value;

                      const convertedY2yEnergy = toJS(y2yEnergy)?.filter(a => a.label !== 'Purchased Heat and Steam');

                      // eslint-disable-next-line default-case
                      switch (f) {
                        case "Energy":
                          const sortTypeE = [{ name: 'Total energy consumed [Gj]', index: 0 }, { name: 'Grid Electricity', index: 1 }, { name: 'Carbon-Free Grid Electricity', index: 2 }, { name: 'Renewables', index: 3 }];
                          const setDataE = convertedY2yEnergy?.map(d => {
                            const res = sortTypeE.filter(e => e.name === d.label)
                            return res.length ? { ...d, index: res[0].index } : d
                          })?.sort((a, b) => a.index - b.index);
                          return <FocusArea focusArea={f} data={setDataE} selectedYear={selectedYear} index={x} key={x} />;
                        case "Scope 1 Emission":
                          const sortType1 = [{ name: 'Scope 1 Emissions (MtCO2e)', index: 0 }, { name: 'Stationary Combustion', index: 1 }, { name: 'Mobile Combustion', index: 2 }, { name: 'Refrigerants and Fugitives', index: 3 }];
                          const setData1 = y2yS1Emissions?.map(d => {
                            const res = sortType1.filter(e => e.name === d.label)
                            return res.length ? { ...d, index: res[0].index } : d
                          })?.sort((a, b) => a.index - b.index);
                          return <FocusArea focusArea={f} data={setData1} selectedYear={selectedYear} index={x} key={x} />;
                        case "Scope 2 Emission":
                          return <FocusArea focusArea={f} data={y2yS2Emissions} selectedYear={selectedYear} index={x} key={x} />;
                        case "Scope 3 Emission":
                          const changeLabels = toJS(y2yS3Emissions)?.map((d, i) => {
                            if (i !== 0) {
                              d.label = `${d.label}`
                            }
                            return d
                          })?.sort((a, b) => a.year - b.year)
                          return <FocusArea focusArea={f} data={changeLabels} selectedYear={selectedYear} index={x} key={x} />;
                        case "Water":
                          if (!focusAreaList.includes("Total Emissions")) {
                            return <FocusArea focusArea={f} data={y2yWater} selectedYear={selectedYear} index={x} key={x} />;
                          }
                          return false;
                        case "Total Emissions":
                          var S1 = 0;
                          var S2 = 0;
                          var S3 = 0;
                          y2yS1Emissions?.map((dataValue, i) => {
                            const data = dataValue.data.filter(m => m.year == selectedYear)[0];
                            if (i == 0)
                              S1 += data?.value;
                          })

                          y2yS2Emissions?.map((dataValue, i) => {

                            const data = dataValue.data.filter(m => m.year == selectedYear)[0];
                            if (i == 0)
                              S2 += data?.value;
                          })

                          y2yS3Emissions?.map((dataValue, i) => {
                            const data = dataValue.data.filter(m => m.year == selectedYear)[0];
                            if (i == 0)
                              S3 += data?.value;
                          })
                          const totalEmissions = ThousandSeparator((S1 + S2 + S3).toFixed(2));
                          return <FocusArea focusArea={f} data={(totalEmissions === "NaN")? '':totalEmissions} selectedYear={selectedYear} index={x} key={x} />;
                      }
                    }))
                    :
                    (modules?.targets.map((f, x) => {
                      return <FocusArea focusArea={f.title} data={f.targets?.map(d => ({ label: d.name, data: [{ year: selectedYear, value: d.progress, isProgress: true }] }))} selectedYear={selectedYear} index={x} key={x} />
                    }))

                }
              </div>
              {/* col 2 */}
              <div className={`px-2 ${modules.Activity_Log ? 'col-lg-4' : 'col-lg-6'} col-md-6`}>
                {modules?.ESG_Pillar_Tracking && <EsgTracking rightArrow={rightArrow} />}
                {(modules?.FocusArea.map((f, x) => {
                  const { y2yWater } = props.store.common;
                  if (focusAreaList.includes("Total Emissions") && f === "Water") {
                    return <FocusArea focusArea={f} data={y2yWater} selectedYear={selectedYear} index={x} key={x} />;
                  }
                  return false;

                })
                )}
                {modules?.Site_Performance && <SitePerformance rightArrow={rightArrow} />}
                {modules?.Group_By_Site_Performance && <GroupSitePerformance />}
                {/* {modules?.Assessment_Tracking && <AssessmentTracking rightArrow={rightArrow} />} */}
                
                {modules?.Group_Report && <GroupReport />}
              </div>
              {/* col 3 */}
              <div className="px-2 col-lg-4 col-md-6">
                {modules.Activity_Log && <ActivityLog rightArrow={rightArrow} />}
                <ActivityLogModal />

                {
                  modules.Activity_Log_V2 && <HistoryDataView rightArrow={rightArrow}/>
                }
              </div>
              {/* <SiteInfo /> */}
              {/* <div className="px-1">
          <NetZeroChart
            currentMonth={0}
            data={data["2021"]}
            keys={allKeys}
            // _handleYearClick={setCurrentYear}
            currentYear={2021}
            currentCo2Emission={126}
            baseLineCo2Emission={168.43}
            offset={44}
          />
        </div> */}
            </div>

          </div>

        </>
      );
    }
    // else{
    // return (
    //   <>
    //     <FilterBar />
    //     <div className="container-fluid"
    //       style={{ color: invertDark(theme) }}
    //     >
    //       <div className="row m-0">
    //         <div className="px-2 col-lg-4 col-md-6">
    //           {userName === "King Wan"
    //             ? kingWanArea.map((f, x) => <FocusAreaKW focusArea={f} kingWanData={kingWanData} key={x} name={f.key} />)
    //             : focusArea.map((f, x) => <FocusArea focusArea={f} key={x} />)}
    //         </div>
    //         <div className="px-2 col-lg-4 col-md-6">
    //           <EsgTracking rightArrow={rightArrow} />
    //           <SitePerformance rightArrow={rightArrow} />
    //           <AssessmentTracking rightArrow={rightArrow} />

    //         </div>
    //         <div className="px-2 col-lg-4 col-md-6">
    //           <ActivityLog rightArrow={rightArrow} />
    //         </div>
    //         {/* <SiteInfo /> */}
    //         {/* <div className="px-1">
    //       <NetZeroChart
    //         currentMonth={0}
    //         data={data["2021"]}
    //         keys={allKeys}
    //         // _handleYearClick={setCurrentYear}
    //         currentYear={2021}
    //         currentCo2Emission={126}
    //         baseLineCo2Emission={168.43}
    //         offset={44}
    //       />
    //     </div> */}
    //       </div>
    //       <ActivityLogModal />
    //     </div>
    //   </>
    // );}
  })
);
