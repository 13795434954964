import { inject, observer } from "mobx-react";
import { Colors } from "../../Assets/Config/color.config";
import Input from "./Inputs";
import loading from "../../Assets/images/loading.svg";
import { useTranslation } from "react-i18next";
import { toJS } from "mobx";
import { Selector } from "../../Tools";
import { useState } from "react";

export const Subsection = inject("store")(
  observer((props) => {
    const {
      subSectionArray,
      subSectionIndex,
      sectionIndex,
      indexAlphabet,
      status,
      yearOptions,
      surveyYear,
      _handleSelectSurveyYear,
      surveyInfo,
      answerObj,
      _handleAlertForOtherAction
    } = props.store.survey;
    const sectionAlphabet = indexAlphabet[sectionIndex];
    const { theme } = props.store.common;
    const { surveyPeriod } = surveyInfo;

    return (
      <div className="m-1">
        <SubsectionHeader
          status={status}
          subSectionArray={subSectionArray}
          subSectionIndex={subSectionIndex}
          sectionAlphabet={sectionAlphabet}
          yearOptions={yearOptions}
          surveyYear={surveyYear}
          _handleSelectSurveyYear={_handleSelectSurveyYear}
          theme={theme}
          surveyPeriod={surveyPeriod}
          answerObj={answerObj}
          _handleAlert={_handleAlertForOtherAction}
        />
        <SubsectionBody
          subSectionArray={subSectionArray}
          subSectionIndex={subSectionIndex}
          sectionAlphabet={sectionAlphabet}
          theme={theme}
        />
      </div>
    );
  })
);

const SubsectionHeader = (props) => {
  const {
    subSectionArray,
    subSectionIndex,
    sectionAlphabet,
    theme,
    status,
    yearOptions,
    surveyYear,
    _handleSelectSurveyYear,
    surveyPeriod,
    answerObj,
    _handleAlert
  } = props;
  const { t } = useTranslation("survey");
  const [alertMsg, setAlertMsg] = useState(null);
  const header = subSectionArray[subSectionIndex]?.subSectionHeader;

  const SlideMessage = () => {
    if (status === "")
      return (
        <div
          className="d-flex align-items-center"
          style={{ height: 20, fontSize: 14 }}
        >
          <img src={loading} />
          {t("updating")}
        </div>
      );
    else if (status === undefined) return null;
    else
      return (
        <div style={{ fontSize: 14, width: 200 }} className="left-slider">
          <span style={{ color: Colors.greenMaterial }}>
            <i className="bi bi-check" /> {status}
          </span>
        </div>
      );
  };
  // const _handleAlert = () => {
  //   setAlertMsg("Please ensure that you save progress.");
  //   setTimeout(function () {
  //     setAlertMsg(null);
  //   }, 4000);
  // };

  return (
    <>
      <span>
        <div
          className="d-flex justify-content-between"
          style={{ color: Colors[theme].textColor, fontSize: 20 }}
        >
          <span className="pt-1">
            <span className="fw-bold">
              {sectionAlphabet}
              {subSectionIndex + 1 + ". "}
            </span>{" "}
            {t(subSectionArray[subSectionIndex]?.subSectionHeader)}
          </span>
          {surveyPeriod && surveyPeriod.length > 0 && header !== "Transportation" &&
            <div className="me-auto ps-2 " onClick={() => answerObj.answer != null && _handleAlert(true)}>
              <Selector
                disabled={answerObj.answer != null}
                options={yearOptions}
                placeholder={t("Select year")}
                _handleSelect={_handleSelectSurveyYear}
                menuListColor={Colors.Dark[0]}
                value={yearOptions.find((v) => v.label === surveyYear)}
              />
            </div>
          }
          {
            alertMsg ?
              <div style={{ fontSize: 14, width: 250 }} className="left-slider">
                <span style={{ color: "#FF3400" }}>
                  <i className="bi bi-exclamation-circle" /> {alertMsg}
                </span>
              </div>
              : ''
          }
          <SlideMessage />
        </div>
        <p style={{ color: Colors[theme].textColor }}>
          {subSectionArray[subSectionIndex]?.description}
        </p>
      </span>
    </>
  );
};

const SubsectionBody = (props) => {
  const { subSectionArray, subSectionIndex, sectionAlphabet, theme } = props;
  //
  const childSections = subSectionArray[subSectionIndex]?.childSections;
  const sections = subSectionArray[subSectionIndex];
  const header = subSectionArray[subSectionIndex]?.subSectionHeader;

  
  //
  return (
    <div>
      {subSectionArray[subSectionIndex]?.questions?.length > 0 && (
        <Input
          id={subSectionIndex}
          questions={sections.questions}
          type={sections.inputType}
          options={sections.questions[0].answerOption}
          equipmentData={sections.equipmentData}
          category={sections.category}
          childSections={childSections}
          header = {header}
        />
      )}
      {childSections?.length > 0 && (
        <ChildSession
          childSections={childSections}
          subSectionIndex={subSectionIndex}
          subSectionArray={subSectionArray}
          sectionAlphabet={sectionAlphabet}
          theme={theme}
        />
      )}
    </div>
  );
};
const ChildSession = ({
  childSections,
  subSectionIndex,
  subSectionArray,
  sectionAlphabet,
  theme,
}) => {
  return (
    <div /*style={{ overflow: "scroll", minHeight: "330px" }}*/>
      {childSections.map((child, i) => (
        <div key={i} className="m-1">
          <span style={{ fontSize: 18, color: Colors[theme].textColor }}>
            <span className="fw-bold">
              {sectionAlphabet}
              {subSectionIndex + 1 + "." + (i + 1)}
            </span>
            <span className="ps-3">{child.childSectionHeader}</span>
          </span>
          <p style={{ color: Colors[theme].textColor }}>{child.description}</p>
          <Input
            childSections={childSections}
            id={i}
            type={childSections[0].inputType}
            options={childSections[0]?.questions[0]?.answerOption}
            // answer={subSectionArray[subSectionIndex].questions[0].answer}
            category={
              subSectionArray[subSectionIndex].childSections[i].category
            }
          />
        </div>
      ))}
    </div>
  );
};
