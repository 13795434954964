import { inject, observer } from "mobx-react";
import { invertDark } from "../../Assets/Config/color.config";
import {
  FrameworkProtocol,
  FrameworkProtocolTbl,
} from "../../Components/Summary/FrameworkProtocolTbl";
import { OverAllProtocolTbl } from "../../Components/Summary/OverallProtocolTbl";
import { EmissionTbl } from "../../Components/Summary/EmissionTbl";
import { SummaryModal } from "../../Components/Summary/SummaryModal";
import { BaselineTbl } from "../../Components/Summary/BaselineTbl";
import TotalGHGPieCharts from "../../Components/Summary/TotalGHGPieCharts";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { GHGbarchart } from "../../Components/Summary/GHGbarchart";
import { useTranslation } from 'react-i18next';
import { t } from "i18next";
import { toJS } from "mobx";


export const SummaryContainer = inject("store")(
  observer((props) => {
    const navigate = useNavigate();
    const { selectedSummaryRow,_fetchAvgFrameworkProtocol,avgFrameworkProtocol } = props.store.esg;
    const { theme, companyName } = props.store.common;
    const { t } = useTranslation("summary");
    const { getBaselineEmission,getScopeAverage,baselineEmission, baselineAverage } = props.store.siteRelevant;
    
    console.log("framework protocol >> ", toJS(avgFrameworkProtocol), toJS(companyName));

    useEffect(() => {      
      getScopeAverage();
      _fetchAvgFrameworkProtocol();
    }, []);
    
    return (
      <div className="mx-3" style={{ color: invertDark(theme) }}>
        <div className="d-flex justify-content-between">
          <div style={{ fontSize: 25, fontWeight: "bold" }}>{t("Summary")}</div>
          <span
            style={{
              fontSize: 20,
              color: invertDark(theme),
              alignItems: "center",
              display: "flex",
              cursor: "pointer",
            }}
            onClick={() => navigate("/dashboard")}
          >
            <i className="bi bi-arrow-left-circle-fill pe-3"></i>
            {t("Back to Dashboard")}
          </span>
        </div>
        <>
        <div className="d-flex" style={{ fontSize: 22 }}>
        {t("GHG Emission")}
        </div>
        <GHGbarchart data={companyName === 'Syscom' ? syscomTotalEmissionData : baselineEmission} />
          {/* <TotalGHGPieCharts data={companyName === 'Syscom' ? syscomTotalEmissionData : baselineEmission}/> */}
        </>
        <div className="my-4">

          <div className="d-flex row flex-wrap">
            <div className="col-lg-4 col-md-4 col-sm-12 py-1">
              <div className="d-flex" style={{ fontSize: 22 }}>
                {t("Baseline Emission")}
              </div>
              <BaselineTbl
                tableHeaders={[t("Target"), t("Baseline Emission")+" (kg CO₂)", t("Baseline Year"), ""]}
                data={companyName === 'Syscom' ? syscomBaselineEmissionData : baselineEmission}
                baselineAverage={baselineAverage}
              />
            </div>
            <div className="col-lg-8 col-md-8 col-sm-12 py-1">
              <div className="d-flex" style={{ fontSize: 22 }}>
                {t("Total GHG Emission & Target")}
              </div>
              <EmissionTbl
                tableHeaders={["GHG "+ t("Target"), t("Total Emission") + " (kg CO₂)"]}
                data={companyName === 'Syscom' ? syscomTotalEmissionData : baselineEmission}
                baselineAverage={baselineAverage}
                subHeaders={companyName === 'Syscom' ? [2020, 2021, 2022] : [t("Baseline Year"), 2020, 2021, 2022]}
              />
            </div>
            {/* <div className="col-lg-6 col-md-6 col-sm-12 py-1">
=======

export const SummaryContainer = inject("store")(
    observer((props) => {
        const { theme } = props.store.common;
        return (
            <div className="mx-3" style={{ color: invertDark(theme) }}>
                <div className="d-flex flex-row">
                    <div style={{ fontSize: 25 }}>Summary</div>
                </div>
                <div className="my-2">
                    <div className="d-flex" style={{ fontSize: 22 }}>
                        Total Emission & Target
                    </div>
                    <div className="d-flex row flex-wrap">
                        <div className="col-lg-12 col-md-12 col-sm-12 py-1">
                            <EmissionTbl 
                                tableHeaders={['Targets', 'Total Emissions (tonnes)']}
                                data={totalEmissionData}
                                subHeaders={[2019,2020,2021,2022]}
                            />
                        </div>
                        {/* <div className="col-lg-6 col-md-6 col-sm-12 py-1">
>>>>>>> c948f4507386e24c7f71d63a22cd391b5a6b6578
                            <EmissionTbl 
                                tableHeaders={['Targets', 'Baseline Emissions (tonnes)']}
                                data={baselineEmissionData}
                                subHeaders={[2019,2020,2021,2022]}
                            />
                        </div> */}
          </div>
        </div>
        <hr />
        {/* frameworkProtocolData={companyName === 'Syscom' ? syscomProtocolData[selectedSummaryRow] : protocolData[selectedSummaryRow]} */}
        <ProtocolFollows
          companyName={companyName} protocolName={'Greenhouse Gas Protocol (GHG)'} frameworkProtocolData={toJS(avgFrameworkProtocol)}
        />
        <SummaryModal data={companyName === 'Syscom' ? syscomData : companyName ===  'Asian Tigers' ? asianTigerData : data} column={column} row={companyName === 'Syscom' ? syscomEmissionFactor: emissionFactor} />
      </div>
    );
  })
);

const ProtocolFollows = (props) => {
  const { t } = useTranslation("summary");
  const { frameworkProtocolData, companyName,protocolName } = props;
  const syscomCountData = [ 
    {
      notApplicable: 13,
      notYetTracking: 5,
      photo: "/upload/building/no_image.jpeg",
      site: "SYSCOM Office",
      tracking: 1
    }
  ];
  const otherCountData = [ 
    {
      notApplicable: 13,
      notYetTracking: 3,
      photo: "/upload/building/no_image.jpeg",
      site: "SYSCOM Office",
      tracking: 3
    }
  ];
  const frameworkProtocolTblData = frameworkProtocolData.map(v=> (
    {
      ...v,
      notApplicable: 13,
      notYetTracking: v.site === 'SYSCOM Office' ? 5 : 3,
      tracking: v.site === 'SYSCOM Office' ? 1 : 3
    }
  ))

  return (
    <div className="row">
      <div className="mt-2 col-lg-6 col-sm-12 ">
        <div className="d-flex" style={{ fontSize: 22 }}>
          {t("Protocol Follows")}
        </div>
        <div>
          <div >
            <OverAllProtocolTbl
              tableHeaders={[
                t("Protocol"),
                t("Tracking"),
                t("Not Yet Tracking"),
                t("Not Applicable"),
              ]}
              data={companyName === 'Syscom' ? syscomOverAllProtocolData : overAllProtocolData}
            />
          </div>
        </div>
      </div>

      <div className="col-lg-6 col-sm-12 mt-2">
        <div className="d-flex" style={{ fontSize: 22 }}>
          {/* <img
            height={50}
            style={{ borderRadius: 5 }}
            src={frameworkProtocolData.image}
          /> */}

          {t(protocolName)}
        </div>

        <div>
          <FrameworkProtocolTbl
            tableHeaders={[
              t("Site"),
              t("Tracking"),
              t("Not Yet Tracking"),
              t("Not Applicable"),
              "",
            ]}
            data={frameworkProtocolTblData}
          />
        </div>
      </div>
    </div>
  );
};
const data = [
  {
    title: "Scope 1",
    module: [
      { name: "Stationary Combustion", status: "Not Applicable" },
      { name: "Mobile Combustion", status: "Tracking" },
      { name: "Refrigerant", status: "Not Applicable" },
    ],
  },
  {
    title: "Scope 2",
    module: [
      {
        name: "Purchased Electricity",
        status: "Tracking",
        remark: "Market-based with official grid emission factor",
      },
    ],
  },
  {
    title: "Scope 3",
    category: [
      {
        name: "Upstream",
        module: [
          {
            name: "Purchased Goods and Services",
            status: "Not Yet Tracking",
          },
          {
            name: "Capital Goods",
            status: "Not Applicable",
          },
          {
            name: "Fuel & Energy related Activities",
            status: "Not Applicable",
          },
          {
            name: "Upstream Transportation & Distribution",
            status: "Not Applicable",
          },
          {
            name: "Waste generated in operations",
            status: "Not Yet Tracking",
          },
          {
            name: "Business Travel",
            status: "Tracking",
          },
          {
            name: "Employee Commuting",
            status: "Not Yet Tracking",
          },
          {
            name: "Upstream leased assets",
            status: "Not Applicable",
          },
        ],
      },
      {
        name: "Downstream",
        module: [
          {
            name: "Downstream transportation and distriubtion",
            status: "Not Applicable",
          },
          {
            name: "Processing of sold products",
            status: "Not Applicable",
          },
          {
            name: "Use of sold products",
            status: "Not Applicable",
          },
          {
            name: "End-of-life treatment of sold products",
            status: "Not Applicable",
          },
          {
            name: "Downstream leased assets",
            status: "Not Applicable",
          },
          {
            name: "Franchises",
            status: "Not Applicable",
          },
          {
            name: "Investments",
            status: "Not Applicable",
          },
        ],
      },
    ],
  },
];

const asianTigerData = [
  {
    title: "Scope 1",
    module: [
      { name: "Stationary Combustion", status: "Not Applicable" },
      { name: "Mobile Combustion", status: "Tracking" },
      { name: "Refrigerant", status: "Not Applicable" },
    ],
  },
  {
    title: "Scope 2",
    module: [
      {
        name: "Purchased Electricity",
        status: "Tracking",
        remark: "Market-based with official grid emission factor",
      },
    ],
  },
  {
    title: "Scope 3",
    category: [
      {
        name: "Upstream",
        module: [
          {
            name: "Purchased Goods and Services",
            status: "Not Yet Tracking",
          },
          {
            name: "Capital Goods",
            status: "Not Applicable",
          },
          {
            name: "Fuel & Energy related Activities",
            status: "Not Applicable",
          },
          {
            name: "Upstream Transportation & Distribution",
            status: "Not Applicable",
          },
          {
            name: "Waste generated in operations",
            status: "Not Yet Tracking",
          },
          {
            name: "Business Travel",
            status: "Not Yet Tracking",
          },
          {
            name: "Employee Commuting",
            status: "Not Yet Tracking",
          },
          {
            name: "Upstream leased assets",
            status: "Not Applicable",
          },
        ],
      },
      {
        name: "Downstream",
        module: [
          {
            name: "Downstream transportation and distriubtion",
            status: "Not Applicable",
          },
          {
            name: "Processing of sold products",
            status: "Not Applicable",
          },
          {
            name: "Use of sold products",
            status: "Not Applicable",
          },
          {
            name: "End-of-life treatment of sold products",
            status: "Not Applicable",
          },
          {
            name: "Downstream leased assets",
            status: "Not Applicable",
          },
          {
            name: "Franchises",
            status: "Not Applicable",
          },
          {
            name: "Investments",
            status: "Not Applicable",
          },
        ],
      },
    ],
  },
];

const syscomData = [
  {
    title: "Scope 1",
    module: [
      { name: "Stationary Combustion", status: "Not Yet Tracking" },
      { name: "Mobile Combustion", status: "Not Yet Tracking" },
      { name: "Refrigerant", status: "Not Yet Tracking" },
    ],
  },
  {
    title: "Scope 2",
    module: [
      {
        name: "Purchased Electricity",
        status: "Tracking",
        remark: "Market-based with official grid emission factor",
      },
    ],
  },
  {
    title: "Scope 3",
    category: [
      {
        name: "Upstream",
        module: [
          {
            name: "Purchased Goods and Services",
            status: "Not Applicable",
          },
          {
            name: "Capital Goods",
            status: "Not Applicable",
          },
          {
            name: "Fuel & Energy related Activities",
            status: "Not Applicable",
          },
          {
            name: "Upstream Transportation & Distribution",
            status: "Not Applicable",
          },
          {
            name: "Waste generated in operations",
            status: "Not Applicable",
          },
          {
            name: "Business Travel",
            status: "Not Yet Tracking",
          },
          {
            name: "Employee Commuting",
            status: "Not Yet Tracking",
          },
          {
            name: "Upstream leased assets",
            status: "Not Applicable",
          },
        ],
      },
      {
        name: "Downstream",
        module: [
          {
            name: "Downstream transportation and distriubtion",
            status: "Not Applicable",
          },
          {
            name: "Processing of sold products",
            status: "Not Applicable",
          },
          {
            name: "Use of sold products",
            status: "Not Applicable",
          },
          {
            name: "End-of-life treatment of sold products",
            status: "Not Applicable",
          },
          {
            name: "Downstream leased assets",
            status: "Not Applicable",
          },
          {
            name: "Franchises",
            status: "Not Applicable",
          },
          {
            name: "Investments",
            status: "Not Applicable",
          },
        ],
      },
    ],
  },
];

const syscomEmissionFactor = [
  {
    country: "Singapore",
    emissionByYear: [0.4206, 0.4085, 0.408, 0.408, 0.408],
  },
  {
    country: "Taiwan",
    emissionByYear: [0.533, 0.509, 0.502, 0.509, 0.509],
  },
];

const emissionFactor = [
  {
    country: "Singapore",
    emissionByYear: [0.4206, 0.4085, 0.408, 0.408, 0.408],
  },
  
];

const column = ["Country", 2018, 2019, 2020, 2021, 2022];

// const baselineEmissionData = [
//   {
//     target: "Scope 1",
//     data: [
//       { year: 2019, value: 120 },
//       { year: 2020, value: 120 },
//       { year: 2021, value: 120 },
//       { year: 2022, value: 120 },
//     ],
//   },
//   {
//     target: "Scope 2",
//     data: [
//       { year: 2019, value: 120 },
//       { year: 2020, value: 120 },
//       { year: 2021, value: 120 },
//       { year: 2022, value: 120 },
//     ],
//   },
//   {
//     target: "Scope 3",
//     data: [
//       { year: 2019, value: 120 },
//       { year: 2020, value: 120 },
//       { year: 2021, value: 120 },
//       { year: 2022, value: 120 },
//     ],
//   },
// ];

const totalEmissionData = [
  {
    target: "Scope 1",
    data: [
      { year: 2019, value: 19 },
      { year: 2020, value: null },
      { year: 2021, value: null },
      { year: 2022, value: 5.86 },
    ],
  },
  {
    target: "Scope 2",
    data: [
      { year: 2019, value: 473818.333 },
      { year: 2020, value: 423462.996 },
      { year: 2021, value: 300607.26 },
      { year: 2022, value: 309163.70 },
    ],
  },
  {
    target: "Scope 3",
    data: [
      { year: 2019, value: 3841115.87 },
      { year: 2020, value: null },
      { year: 2021, value: null },
      { year: 2022, value: 852430.42 },
    ],
  },
];

const syscomTotalEmissionData = [
  {
    label: "Scope 1",
    data: [

      { year: 2020, value: null },
      { year: 2021, value: 7348.68 },
      { year: 2022, value: 8877.92 },
    ],
  },
  {
    label: "Scope 2",
    data: [

      { year: 2020, value: 1020766.8 },
      { year: 2021, value: 1072869.18 },
      { year: 2022, value: 1062685.12 },
    ],
  },
  {
    label: "Scope 3",
    data: [

      { year: 2020, value: null },
      { year: 2021, value: null },
      { year: 2022, value: null },
    ],
  }
];
const protocolData = [
  {
    protocol: "Greenhouse Gas Protocol (GHG)",
    image:
      "http://172.104.40.242:3000/url/upload/framework/5d6197a3-01ad-41f5-8596-cfef5f4a3ff5.jpeg",
    data: [
      {
        site: "Bugis",
        tracking: 3,
        notYetTracking: 3,
        notApplicable: 13 ,
        button: "Details",
      }
    ],
  },
  {
    protocol: "Task Force on Climate-Related Financial Disclosures (TCFD)",
    image:
      "http://172.104.40.242:3000/url/upload/framework/c8dc9b9d-eaba-43b8-a5cf-516a70ff04f4.png",
    data: [
      {
        site: "Bugis",
        tracking: "-",
        notYetTracking: "-",
        notApplicable: "-",
        button: "Details",
      },
      {
        site: "Bukit Merah",
        tracking: "-",
        notYetTracking: "-",
        notApplicable: "-",
        button: "Details",
      },
      {
        site: "Solaris",
        tracking: "-",
        notYetTracking: "-",
        notApplicable: "-",
        button: "Details",
      },
      {
        site: "Innovis",
        tracking: "-",
        notYetTracking: "-",
        notApplicable: "-",
        button: "Details",
      },
    ],
  },
  {
    protocol: "WWF PACT (For Hotels)",
    image: "http://172.104.40.242:3000/url/upload/framework/wwf.png",
    data: [
      {
        site: "Bugis",
        tracking: "-",
        notYetTracking: "-",
        notApplicable: "-",
        button: "Details",
      },
      {
        site: "Bukit Merah",
        tracking: "-",
        notYetTracking: "-",
        notApplicable: "-",
        button: "Details",
      },
      {
        site: "Solaris",
        tracking: "-",
        notYetTracking: "-",
        notApplicable: "-",
        button: "Details",
      },
      {
        site: "Innovis",
        tracking: "-",
        notYetTracking: "-",
        notApplicable: "-",
        button: "Details",
      },
    ],
  },
];

const syscomProtocolData = [
  {
    protocol: "Greenhouse Gas Protocol (GHG)",
    image:
      "http://172.104.40.242:3000/url/upload/framework/5d6197a3-01ad-41f5-8596-cfef5f4a3ff5.jpeg",
    data: [
      {
        site: "Syscom Office",
        tracking: 1,
        notYetTracking: 5,
        notApplicable: 13,
        button: "Details",
      }
    ],
  },
]

const itriProtocolData = [
  {
    protocol: "Greenhouse Gas Protocol (GHG)",
    image:
      "http://172.104.40.242:3000/url/upload/framework/5d6197a3-01ad-41f5-8596-cfef5f4a3ff5.jpeg",
    data: [
      {
        site: "ITRI",
        tracking: 3,
        notYetTracking: 3,
        notApplicable: 13,
        button: "Details",
      },
    ],
  },
]



const overAllProtocolData = [
  {
    protocol: "GHG",
    tracking: 3,
    notYetTracking: 3,
    notApplicable: 13,
    button: "Details",
  }
];

const syscomOverAllProtocolData = [
  {
    protocol: "GHG",
    tracking: 1,
    notYetTracking: 5,
    notApplicable: 13,
    button: "Details",
  }
];

const baselineEmissionData = [
  {
    target: "Scope 1",
    baseline: 2019,
    data: [
      { year: 2019, value: 19, verified: false },
      { year: 2020, value: 120, verified: true },
      { year: 2021, value: 120, verified: true },
      { year: 2022, value: 120, verified: true },
    ],
  },
  {
    target: "Scope 2",
    baseline: 2019,
    data: [
      { year: 2019, value: 473818.333, verified: true },
      { year: 2020, value: 120, verified: true },
      { year: 2021, value: 120, verified: true },
      { year: 2022, value: 120, verified: true },
    ],
  },
  {
    target: "Scope 3",
    baseline: 2019,
    data: [
      { year: 2019, value: 3841115.87, verified: false },
      { year: 2020, value: 120, verified: true },
      { year: 2021, value: 120, verified: true },
      { year: 2022, value: 120, verified: true },
    ],
  },
];

const syscomBaselineEmissionData = [
  {
    label: "Scope 1",
    baseline: 2021,
    data: [
      { year: 2021, value: 7348.683503, verified: true },
      { year: 2022, value: 120, verified: true },
    ],
  },
  {
    label: "Scope 2",
    baseline: 2020,
    data: [
      { year: 2021, value: 1020766.8, verified: true },
      { year: 2022, value: 120, verified: true },
    ],
  },
  {
    label: "Scope 3",
    baseline: '-',
    data: [
      { year: 2019, value: null, verified: false },
      { year: 2020, value: 120, verified: true },
      { year: 2021, value: 120, verified: true },
      { year: 2022, value: 120, verified: true },
    ],
  },
];
