import { inject, observer } from "mobx-react";
import { Colors, invertDark  } from "../../Assets/Config/color.config";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { withStyles, makeStyles } from "@material-ui/core/styles";

export const ESGKPITable = inject("store")(
  observer((props) => {
    const { theme } = props.store.common;
    const { esgKpiT_R, _handleEditEsgKPIs, selectedESGYear } = props.store.esg;

    const StyledTableRow = withStyles((theme) => ({
      root: {
        maxHeight: "50px",
        borderRadius: "10px",
        margin: "20px",
      },
    }))(TableRow);

    const useStyles = makeStyles({
      table: {
        width: "100%",
        borderCollapse: "separate",
        borderSpacing: "0px 3px",
      },
    });

    const classes = useStyles();

    const headerColumns = ["ESG Pillars", "Materiality Topics", "Base Year", "Targets", `Result, ${selectedESGYear}`, " "]

    const style = {
        color: invertDark(theme),
        textAlign: "left",
        fontSize: 14,
        padding: "5px 30px",
        borderBottom: `1px solid ${Colors.Dark[2]}`,
        paddingLeft: 13,
    };

    return (
    <div style={{ position: "relative" }}>
        <TableContainer
        component={Paper}
        style={{ 
            maxHeight: esgKpiT_R === null || esgKpiT_R.length === 0 ? "auto" : "70vh",
            overflow: esgKpiT_R === null || esgKpiT_R.length === 0 ? "hidden" : "auto",
            background: "transparent"
        }}
        
        >
            <Table
                id="custom_tracking_tbl"
                className={classes.table}
                stickyHeader
                aria-label="customized table"
            >
                <TableHead>
                    <TableRow>
                        {headerColumns.map((columnName, index) => {
                            return  <TableCell style={{
                            backgroundColor: theme === "dark" ? Colors.primaryBule : Colors.blue,
                            color: Colors.white,
                            fontWeight: "bold",
                            padding: "8px 13px 8px 13px",
                            position: "sticky",               
                            }}>{columnName}</TableCell>
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                    esgKpiT_R === null ?
                        <div><i className="spinner-border mt-2 mx-auto d-flex" /></div>
                        : esgKpiT_R?.length > 0 ?
                            (esgKpiT_R.slice().sort((a,b) => a.pillar.pillarName.localeCompare(b.pillar.pillarName))).map((v, k) => {
                                return <StyledTableRow>
                                    <TableCell style={{...style, minWidth: 280}}>{v.pillar.pillarName || '-'}</TableCell>
                                    <TableCell style={{...style, minWidth: 200}}>{v.subPillar.subPillarName || '-'}</TableCell>
                                    <TableCell style={{...style, minWidth: 100}}>{v.baseYear.yearName || '-'}</TableCell>
                                    <TableCell style={{...style, minWidth: 500}}>{v.establishedTarget || '-'}</TableCell>
                                    <TableCell style={{...style, minWidth: v.yearlyResult?.filter( v => v?.year == selectedESGYear)[0]?.result ? 500 : 150}}>{v.yearlyResult?.filter( v => v?.year == selectedESGYear)[0]?.result || "-"}</TableCell>
                                    {/* <TableCell style={style}>{''}</TableCell> */}
                                    <TableCell style={style}>
                                        <span
                                            onClick={() => _handleEditEsgKPIs(v)}
                                            data-bs-toggle="modal"
                                            data-bs-target="#esgKpiModal"
                                            className="col-1 text-end"
                                            style={{ cursor: 'pointer' }}>
                                            <i className="bi bi-pencil-square" />
                                        </span>
                                    </TableCell>
                                </StyledTableRow>
                            }) :
                            <StyledTableRow>
                                <TableCell colSpan={7}><div className="text-secondary h6 text-center my-4">{("No Data.")}</div></TableCell>
                            </StyledTableRow>                  
                    }
                </TableBody>
            </Table>
        </TableContainer>
    </div>
    );
  })
);
