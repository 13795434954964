import { Colors } from "../../Assets/Config/color.config"
import Store from "../../Store"
import { useTranslation } from 'react-i18next';


const ConfirmationModal = (props) => {
    const {editedUser,_handleDeleteUser } = Store.manageUsers;
    const { theme } = Store.common;
    const { t } = useTranslation("user_management");
     return(
        <div
        className={`modal fade ${theme}`}
        id="confirmModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
    >
        <div className="modal-dialog modal-dialog-centered">
            <div
                className="modal-content"
                style={{
                    background: Colors[theme].background,
                    borderRadius: 10,
                }}
            >
                <div className="modal-header mx-3 px-0">
                    <h4 className="modal-title" id="exampleModalLabel">
                        { t("Confirm Delete") }
                    </h4>
                    <i
                        style={{ cursor: "pointer" }}
                        className="bi bi-x-lg "
                        data-bs-dismiss="modal"
                        aria-label={ t("Close") }
                    ></i>
                </div>
                <div className="modal-body">
                    { t("Are you sure want to delete user", {user: editedUser.name})}
                </div>
                <div className="modal-footer d-flex justify-content-center mx-3">
                    <button
                        type="button"
                        className="btn col-2 text-white"
                        style={{
                            background: theme === 'dark' ? Colors.darkBlue : '#888'
                        }}
                        data-bs-dismiss="modal"
                    >
                        { t("Cancel") }
                    </button>
                    <button
                        type="button"
                        data-bs-dismiss="modal"
                        className="btn col-2 text-white"
                        style={{
                            background: 'rgb(255 0 0 / 50%)'
                        }}
                       onClick={()=>_handleDeleteUser(editedUser._id)}
                    >
                        { t("Yes") }
                    </button>
                </div>

            </div>
        </div>
    </div>
    )
}

export default ConfirmationModal