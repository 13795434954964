import { Fragment, useEffect } from "react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { MyButton } from "../../Tools";
import { useNavigate } from "react-router-dom";
import { inject, observer } from "mobx-react";
import Display from "../../Assets/images/Display.png";
import Form from "../../Assets/images/form.png";
import CreateNewSiteModal from "./CreateNewSiteModal";
import PlusIcon from "../../Assets/images/PlusSquareFill.svg";
import "../../App.css";
import UserIcon from "../../Assets/images/users.svg";
import { toJS } from "mobx";
import ConfirmDelteSiteModal from "./ConfirmDeleteSiteModal";
import { baseURLImage } from "../../API/URL";
import { Cookies } from "react-cookie";
import BuildingWizard from "../../Assets/images/buildingWizard.svg";
import { LoadingPage } from "../../Page/Loading";
import { useTranslation } from "react-i18next";
import getNsTrans from "../../util/makeNsTrans.js";
import digitalEdgeIcon from "../../Assets/images/digitalEdgeIcon.png";
const Trans = getNsTrans("landing");

const LandingComponent = inject("store")(
  observer((props) => {
    const navigate = useNavigate();
    const { t } = useTranslation("landing");
    const {
      getBuildingList,
      permittedBuilding,
      permittedBuildingLoading,
      role,
      theme,
      companyName
    } = props.store.common;
    useEffect(() => {
      getBuildingList();
    }, []);
    //
    return permittedBuildingLoading ? (
      <LoadingPage />
    ) : (
      <Fragment>
        {companyName === 'Digital Edge' && <div className="text-end">
          <img
            crossorigin="anonymous"
            className="pe-2 pt-2"
            width={"160px"}
            src={digitalEdgeIcon}
            alt={t("Digital Edge Icon")}
          />
        </div>}
        <div className="row m-0 justify-content-center align-items-start">
          <div
            className="w-100 mt-5 px-5 py-5"
            style={{ color: Colors[theme].textColor }}
          >
            <div style={{ fontSize: 30, paddingBottom: 20 }}>
              {t("Welcome")}
            </div>
            <span>
              <Trans i18nKey="welcome_text_">
                This platform is specifically designed in accordance to the ESG
                framework,
                <br />
                which assesses the impact of the sustainability and ethical
                practices of the company.
              </Trans>
            </span>
            {/* <h6 className="text-left pt-4">{ t("Please Select a tool to start") }</h6> */}
          </div>
          <div className="d-flex px-1 justify-content-end">
            <div className="col col-12 col-sm-12 col-lg-6 col-md-6 col-xl-6 py-2 d-flex justify-content-center">
              <div className="mx-3" style={{ maxWidth: "600px" }}>
                <div className="d-flex flex-row justify-content-end">
                  {role === "admin" ? (
                    <>
                      {/* <div className="col-4 ps-2">
                        <div
                          className={`tableStyle d-flex align-items-center flex-column h-100 text-center px-2 ${theme === 'dark' ? 'user_management_btn' : 'user_management_light_btn'} align-items-center`}
                          style={{
                            borderRadius: 10,
                            cursor: "pointer",
                            minWidth: 160,
                            color: Colors[theme].textColor,
                          }}
                          onClick={() => { window.location.pathname = "/site-management" }}
                        >
                          <i className="bi bi-building" style={{ fontSize: 35, marginTop:-5 }} />
                          <div className="p-1" style={{marginTop:-10}}>
                            Site Management
                          </div>
                        </div>
                      </div> */}
                      {/* <div className="col-4 ps-2">
                        <div
                          className={`tableStyle d-flex flex-column h-100 text-center px-2 ${theme === "dark"
                              ? "user_management_btn"
                              : "user_management_light_btn"
                            }`}
                          style={{
                            borderRadius: 10,
                            cursor: "pointer",
                            width: 160,
                            color: Colors[theme].textColor,
                          }}
                          onClick={() => {
                            window.location.pathname =
                              "/quick-organization-setup";
                          }}
                        >
                          <div className="">
                            <i className="bi bi-building" style={{ fontSize: 30 }} />
                          </div>
                          <div>{t("Company SetUp")}</div>
                        </div>
                      </div> */}
                      <div className="col-6 ps-2">
                        <div
                          className={`tableStyle d-flex flex-column h-100 text-center px-2 ${theme === "dark"
                              ? "user_management_btn"
                              : "user_management_light_btn"
                            }`}
                          style={{
                            borderRadius: 10,
                            cursor: "pointer",
                            width: 160,
                            color: Colors[theme].textColor,
                          }}
                          onClick={() => {
                            window.location.pathname =
                              "/building-and-user-registration";
                          }}
                        >
                          <div className="pt-1">
                            <img
                            crossorigin="anonymous"
                              src={BuildingWizard}
                              alt="building_wizard"
                              height="38px"
                              width="43px"
                            />
                          </div>
                          <div>{t("Site Wizard")}</div>
                        </div>
                      </div>
                      <div className="col-6 ps-2">
                        <div
                          className={`tableStyle d-flex flex-column h-100 text-center px-2 ${theme === "dark"
                              ? "user_management_btn"
                              : "user_management_light_btn"
                            } align-items-center`}
                          style={{
                            borderRadius: 10,
                            cursor: "pointer",
                            minWidth: 160,
                            color: Colors[theme].textColor,
                          }}
                          onClick={() => {
                            window.location.pathname =
                              "/user-management&creation";
                          }}
                        >
                          <div style={{ marginTop: -4 }}>
                            <img
                            crossorigin="anonymous"
                              src={UserIcon}
                              alt="user_icon"
                              height="45px"
                              width="45px"
                            />
                          </div>
                          <div className="p-0">{t("User Management")}</div>
                        </div>
                      </div>
                      {/* <div className="col-4 ps-2">
                        <div
                          className={`tableStyle d-flex flex-column h-100 text-center px-2 ${
                            theme === "dark"
                              ? "user_management_btn"
                              : "user_management_light_btn"
                          } align-items-center`}
                          style={{
                            borderRadius: 10,
                            cursor: "pointer",
                            minWidth: 160,
                            color: Colors[theme].textColor,
                          }}
                          onClick={() => {
                            window.location.pathname =
                              "/site-location";
                          }}
                        >
                          <div style={{ marginTop: -4 }}>
                            <img
                              src={BuildingWizard}
                              alt="user_icon"
                              height="45px"
                              width="45px"
                            />
                          </div>
                          <div className="p-0">{t("Site Location")}</div>
                        </div>
                      </div> */}
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <TargetTrackingCard
            permittedBuilding={permittedBuilding}
            theme={theme}
          />
          {role !== "admin" ? (
            <RapidAssessmentCard
              permittedBuilding={permittedBuilding?.[0]}
              role={role}
              theme={theme}
            />
          ) : (
            <SiteCard theme={theme} />
          )}
        </div>
        <CreateNewSiteModal />
        <ConfirmDelteSiteModal />
      </Fragment>
    );
  })
);

const TargetTrackingCard = (props) => {
  const { permittedBuilding, role, theme } = props;

  const navigate = useNavigate();
  const { t } = useTranslation("landing");
  const linkURL = "/dashboard";

  return (
    <div
      id={"tracking_card_id"}
      className="col col-12 col-sm-12 col-lg-6 col-md-6 col-xl-6 py-2"
      style={{ color: Colors[theme].textColor }}
    >
      <div
        className="tableStyle container p-2 text-center"
        style={{
          maxWidth: 600,
          minHeight: 320,
          overflowY: "scroll",
          backgroundColor: Colors[theme].active,
          color: Colors[theme].textColor,
        }}
      >
        <div className="py-2">
          <img crossorigin="anonymous" src={Display} alt="TrackingIcon" />
        </div>
        <h5>{t("Target Tracking")}</h5>
        <hr className="m-0" />
        <div className="px-5 py-3 text-left">
          {t("Target Tracking Description_")}
          <br />
          {/* <MyLink text={ t("Learn more") } url={"#"} /> */}
        </div>
        <div className="col mb-3 pb-1">
          <MyButton
            disabled={permittedBuilding?.length === 0}
            text={t("Dashboard")}
            rounded
            fontSize={15}
            customColor={theme === "dark" ? Colors.lightBlue : Colors.blue}
            onClick={() =>
              role === "user"
                ? navigate(
                  linkURL +
                  "/?site=" +
                  permittedBuilding[0]._id +
                  "&survey=" +
                  permittedBuilding[0].surveyHeader[0].id +
                  "&name=" +
                  permittedBuilding[0].buildingName
                )
                : navigate(linkURL)
            }
          />
        </div>
      </div>
    </div>
  );
};

const RapidAssessmentCard = (props) => {
  const { permittedBuilding, role, theme } = props;
  const navigate = useNavigate();
  const { linkURL } = rapidAssessmentData;
  const { t } = useTranslation("landing");

  return (
    <div className="col col-12 col-sm-12 col-lg-6 col-md-6 col-xl-6 py-2">
      <div
        className="tableStyle container p-2 text-center"
        style={{
          maxWidth: 600,
          borderRadius: 10,
          backgroundColor: Colors[theme].active,
          color: Colors[theme].textColor,
        }}
      >
        <div className="py-2">
          <img crossorigin="anonymous" src={Form} alt={t("Assessment")} />
        </div>
        <h5>{t("Rapid Assessment")}</h5>
        <hr className="m-0" />
        <div className="px-5 py-3 text-left">
          {t("Rapid Assessment Description_")}
          <br />
          {/* <MyLink text={ t("Learn more") } url={"#"} /> */}
        </div>
        <div className="col mb-4">
          <MyButton
            text={
              role === "viewer" ? t("Data Input Unavailable") : t("Data Input")
            }
            leftIcon={
              role === "viewer" ? (
                <i className="bi bi-exclamation-triangle-fill"></i>
              ) : null
            }
            disabled={role === "viewer" ? true : false}
            rounded
            fontSize={15}
            onClick={() => {
              navigate(
                linkURL +
                "/?site=" +
                permittedBuilding._id +
                "&survey=" +
                permittedBuilding.surveyHeader[0].id +
                "&name=" +
                permittedBuilding.buildingName
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

const SiteCard = inject("store")(
  observer((props) => {
    const navigate = useNavigate();
    const { linkURL } = rapidAssessmentData;
    const { t } = useTranslation("landing");
    const {
      permittedBuilding,
      setDeleteSiteChecked,
      deleteCheckedArray,
      isClickedDeleteSite,
      setClickedDeleteSite,
      _cancelDeleteSite,
      addDefaultSrc,
      theme,
      _handleMouseHover,
      hoverId,
      isHover,
    } = props.store.common;
    const { setCreateNewSiteApiFetch } = props.store.building;
    const { _handlePageClick } = props.store.target;
    const cookies = new Cookies(); //
    const userId = cookies.get("userId");
    return (
      <div
        id={"site-card"}
        className="col col-12 col-sm-12 col-lg-6 col-md-6 col-xl-6 py-2"
      >
        <div
          className="tableStyle container p-2 text-center position-relative"
          style={{
            maxWidth: 600,
            height: 340,
            borderRadius: 10,
            backgroundColor: Colors[theme].active,
            color: Colors[theme].textColor,
          }}
        >
          <div className="d-flex flex-row justify-content-between p-2">
            <div className="ps-2">
              <i className="bi bi-building pe-2" style={{ fontSize: 40 }} />
              <span style={{ fontSize: 20, fontWeight: 500 }}>
                {t("Sites")}
              </span>
            </div>
            <div className="row  d-flex align-items-center">
              {permittedBuilding?.length > 0 && (
                <div
                  className="col d-flex justify-content-center align-items-center rounded text-white"
                  onClick={() => setClickedDeleteSite(!isClickedDeleteSite)}
                  title={t("Delete Sites")}
                  style={{
                    height: 26.3,
                    width: 26.3,
                    cursor: "pointer",
                  }}
                >
                  <i style={{ color: Colors[theme].textColor }} className="bi bi-trash3"></i>
                </div>
              )}
              <img
              crossorigin="anonymous"
                onClick={() => {
                  window.location.pathname =
                    "/building-and-user-registration";
                }}
                className="col"
                src={PlusIcon}
                alt="plus_icon"
                // data-bs-toggle="modal"
                // data-bs-target="#exampleModal"
                style={{ cursor: "pointer" }}
                title={t("Create New Site")}
              />
            </div>
          </div>

          <hr className="m-0 mb-1" />
          <div
            className="mx-2 scroll"
            style={{
              overflowY: "scroll",
              maxHeight: isClickedDeleteSite ? 200 : 240,
            }}
          >
            {permittedBuilding ? (
              permittedBuilding.length === 0 ? (
                <h5 className="pt-5 text-center">{t("No Site Found!")}</h5>
              ) : (
                permittedBuilding.map((v, k) => (
                  <div
                    key={k}
                    className={`flex-row  d-flex align-items-center ${theme}`}
                    style={{ overflow: "hidden" }}
                  >
                    {isClickedDeleteSite && (
                      <input
                        style={{ cursor: "pointer" }}
                        type="checkbox"
                        className="col-1 m-0"
                        value={permittedBuilding[k]._id}
                        onClick={(e) =>
                          setDeleteSiteChecked(e.target.value, e.target.checked)
                        }
                      />
                    )}

                    <div
                      className={`d-flex flex-row justify-content-between p-2 my-1 rounded ${isClickedDeleteSite ? "col-11" : "col-12"
                        } m-0 ${v.active ? "" : "buildingList"} `}
                      style={{
                        background: Colors[theme].secondBackground,
                        // theme === "light"
                        //   ? Colors.Dark[4]
                        //   : Colors.primaryBule,
                        //cursor: userId === '62fa2506c5088927521af69f' ?( v.buildingName === 'Bugis Office' ? "pointer" : "not-allowed") : 'pointer',
                        cursor: v.active ? "pointer" : "not-allowed",
                        //opacity: userId === '62fa2506c5088927521af69f' ? (v.buildingName === 'Bugis Office' ? 1 : 0.7) : 1,
                        opacity: v.active ? 1 : 0.7,
                        color: invertDark(theme),
                      }}
                      // onMouseEnter={() => _handleMouseHover(k)}
                      // onMouseLeave={() => _handleMouseHover(k)}
                      key={k}
                      onClick={(e) => {
                        if (userId === "62fa2506c5088927521af69f") {
                          if (v.buildingName === "Bugis Office") {
                            // _handlePageClick(permittedBuilding?.findIndex((v) => v._id === permittedBuilding[k]._id))
                            cookies.set("siteImage", v.photo);
                            !isClickedDeleteSite &&
                              navigate(
                                linkURL +
                                "/?site=" +
                                permittedBuilding[k]._id +
                                "&survey=" +
                                permittedBuilding[k].surveyHeader[0]?.id +
                                "&name=" +
                                permittedBuilding[k].buildingName
                              );
                          }
                        } else {
                          cookies.set("siteImage", v.photo);
                          !isClickedDeleteSite &&
                            navigate(
                              linkURL +
                              "/?site=" +
                              permittedBuilding[k]._id +
                              "&survey=" +
                              permittedBuilding[k].surveyHeader[0]?.id +
                              "&name=" +
                              permittedBuilding[k].buildingName
                            );
                        }
                      }}
                    >
                      <div>
                        <img
                        crossorigin="anonymous"
                          onError={addDefaultSrc}
                          // src={
                          //   imageUrl +
                          //   `${v.buildingName
                          //     ?.replace(/\s/g, "")
                          //     .toLowerCase()}.png`
                          // }
                          src={baseURLImage + v.photo}
                          alt={t("Site Logo")}
                          width={38}
                          className="rounded me-2"
                        />
                        {v.buildingName}
                      </div>
                      {/* {k === hoverId && (
                      <i className="bi bi-arrow-right pt-2 pe-2" />
                    )} */}
                    </div>
                  </div>
                ))
              )
            ) : (
              <div className="pt-5">
                <i className="spinner-border mt-2" />
              </div>
            )}
          </div>
          {isClickedDeleteSite && (
            <div className="d-flex justify-content-end m-0 p-2">
              <div>
                <MyButton
                  text={t("Cancel")}
                  rounded
                  fontSize={15}
                  onClick={() => _cancelDeleteSite()}
                  customColor={theme === 'dark' ? Colors.lightBlue : '#888'}
                  customHoverColor={theme === 'dark' ? Colors.lightBlue : '#aaa'}
                />
                <span
                  data-bs-toggle={
                    deleteCheckedArray.length !== 0 ? "modal" : null
                  }
                  data-bs-target={
                    deleteCheckedArray.length !== 0
                      ? "#confirmDeleteModal"
                      : null
                  }
                  style={{
                    cursor: "pointer",
                    paddingLeft: 20,
                  }}
                >
                  <MyButton
                    disabled={deleteCheckedArray.length === 0}
                    text={t("Delete")}
                    rounded
                    fontSize={15}
                    customColor={"rgb(255 0 0 / 50%)"}
                  />
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  })
);

export default LandingComponent;

const rapidAssessmentData = {
  linkURL: "/rapid-assessment",
};

// process.env.PUBLIC_URL +
// `/sites/${v.buildingName
//   ?.replace(/\s/g, "")
//   .toLowerCase()}.png`
