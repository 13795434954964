
import SearchIcon from '@mui/icons-material/Search';
import { makeStyles } from '@mui/styles';
import { Autocomplete, Box, debounce, Divider, IconButton, InputAdornment, Paper, TextField } from "@mui/material";
import { inject, observer } from "mobx-react";
import React from 'react';
import { useNavigate } from 'react-router-dom';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

const PageSearch = inject("store")(


    observer((props) => {
        const { searchTypeText, searchSelectText, searchOptions, _handleSelectPageSearch, _handleTextChangePageSearch, _changeSearchOptions, _fetchSearchData } = props.store.pageSearch;

        return (<>
            <SearchAutocomplete searchSelectText={searchSelectText} searchTypeText={searchTypeText} searchOptions={searchOptions} _handleSelectPageSearch={_handleSelectPageSearch} _handleTextChangePageSearch={_handleTextChangePageSearch}
                _changeSearchOptions={_changeSearchOptions}
                _fetchSearchData={_fetchSearchData}
            />
        </>)
    })


)

function SearchAutocomplete(props) {
    const navigate = useNavigate();
    const themeMode = localStorage.getItem("theme");
    const useStyles = makeStyles({
        
        inputLabel: {
            color: themeMode === 'dark' ? '#b1b1b1 !important' : '#3030303 !important',            
        },
    });
    const classes = useStyles();

    const { searchTypeText, searchSelectText, searchOptions, _handleSelectPageSearch, _handleTextChangePageSearch, _fetchSearchData, _changeSearchOptions } = props;

    const fetch = React.useMemo(
        () =>
            debounce((request, callback) => {
                _fetchSearchData(request.input, callback)
                //autocompleteService.current.getPlacePredictions(request, callback);
            }, 400),
        [],
    );

    React.useEffect(() => {
        let active = true;


        if (searchTypeText === '') {
            _changeSearchOptions(searchSelectText ? [searchSelectText] : []);
            return undefined;
        }

        fetch({ input: searchTypeText }, (results) => {

            if (active) {
                let newOptions = [];

                if (searchSelectText) {
                    newOptions = [searchSelectText];
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }

                _changeSearchOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [searchSelectText, searchTypeText, fetch]);



    return (
        <Autocomplete size="small"
            // autoComplete={true} 
            fullWidth={true}  renderInput={
                (params) =>
                <Paper
                elevation={1}
                component="form"
                sx={{  display: 'flex', alignItems: 'center', width: 400 , background: 'rgba(0,0,0,0.0)'}}
              >
                <IconButton><SearchIcon sx={{ color: "rgb(198, 198, 198)" }} /></IconButton>
                    
                    <TextField InputProps={{classes:{notchedOutline:classes.noBorder}}} margin="dense"  {...params} variant="outlined" size="small" label="Search Page by keyword"
                        InputLabelProps={{ classes: { root: classes.inputLabel } }}
                    />      
                </Paper>
            }
            sx={
                {
                    ".MuiOutlinedInput-root": {
                        color: themeMode === 'dark' ? '#b1b1b1 !important' : '#3030303 !important',
                    },                    
                    ".MuiOutlinedInput-notchedOutline": {
                        border: "none"
                    },
                    ".Mui-focused": {
                        paddingTop: "5px",
                        paddingLeft: "5px"
                    },
                    ".MuiIconButton-root": {
                        //color: themeMode === 'dark' ? '#b1b1b1 !important' : '#3030303 !important',
                    }
                }}
            getOptionLabel={(option) => typeof option === 'string' ? option : option.pageName}
            renderOption={(props, option) => {
                let matches = match(option.pageName, searchTypeText);

                const parts = parse(
                    option.pageName,
                    matches
                );
                

                return (
                    <li {...props}>
                        {parts.map((part, index) => (
                            <Box
                                key={index}
                                component="span"
                                sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                            >
                                {part.text}
                            </Box>
                        ))}
                    </li>
                )
            }}
            autoHighlight
            filterOptions={(x) => x}
            options={searchOptions}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={searchSelectText}
            onChange={(e, newValue) => {
                let { route } = _handleSelectPageSearch(e, newValue);
                navigate(route);
            }}
            // onChange={(event, newValue) => {
            //     console.log("NewValue", newValue)
            //   }}
            inputValue={searchTypeText}
            onInputChange={_handleTextChangePageSearch} />
    )
}


export default PageSearch;