import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { inject, observer } from "mobx-react";

export const StepPagination = inject("store")(
  observer((props) => {
    const { selectedPageIndex, _handleSetPageIndex } =
      props.store.quickOrganizationSetUp;
    const { theme } = props.store.common;
    const { steps } = props;

    return (
      <div className={theme === "dark" ? "darkTheme" : "lightTheme"}>
        <Box sx={{ width: '100%' }}>
          <Stepper activeStep={selectedPageIndex} alternativeLabel>
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel></StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
      </div>
    );
  })
);