import { observable, action, makeObservable, runInAction, toJS } from "mobx";
import { toast } from "react-toastify";
import {
  addNewBuilding,
  addOneTimeBuilding,
  fetchUpdateBuilding,
} from "../API/api.building";
import { baseURL } from "../API/URL";
import {
  CreateNewSiteValidation,
  CreateOneTimeSiteValidation,
} from "../util/formValidation";
import common from "./common";
import Common from "./common";
import groupmanagement from "./groupmanagement";
import specialOneTime from "./specialOneTime";

class Building {
  inputs = {
    buildingName: "",
    country: "",
    city: "",
    type: "",
    lat: "",
    lng: "",
    address: "",
    admin: {
      userName: "",
      email: "",
      password: "",
    },
    framework: [],
  };
  defaultProps = {
    center: {
      lat: 1.3521,
      lng: 103.8198
    },
    zoom: 11
  };
  email = "";
  userName = "";
  password = "";
  buildingName = "";
  buildingType = "";
  country = "";
  city = "";
  lat = "";
  lng = "";
  framework = [];
  checkedArray = [];
  err = {};
  isDisabled = false;
  showModal = false;
  uploadLogoImage = "";
  building = null;
  buildingArr = [];
  photo = null;
  createNewSiteApiFetch = false;
  disabledSaveFramework = true;
  checkFrameworks = [];
  allFrameworks = [];
  editMode = '';
  editBuilding = {};
  showGHGModal = false;
  unit_floor = [''];
  group = []

  constructor() {
    makeObservable(this, {
      inputs: observable,
      email: observable,
      userName: observable,
      password: observable,
      buildingName: observable,
      buildingType: observable,
      country: observable,
      city: observable,
      checkedArray: observable,
      err: observable,
      isDisabled: observable,
      showModal: observable,
      uploadLogoImage: observable,
      building: observable,
      buildingArr: observable,
      framework: observable,
      createNewSiteApiFetch: observable,
      disabledSaveFramework: observable,
      allFrameworks: observable,
      editMode: observable,
      editBuilding: observable,
      showGHGModal: observable,
      checkFrameworks: observable,
      unit_floor: observable,
      group: observable,

      setInputs: action.bound,
      _handleClose: action.bound,
      setCheckedValue: action.bound,
      _handleUploadImage: action.bound,
      _handleBuildingAdd: action.bound,
      _handleRemove: action.bound,
      _handleCreateNewSite: action.bound,
      _handleCreateOneTimeSite: action.bound,
      _handleFrameworkCheck: action.bound,
      _resetImage: action.bound,
      setCreateNewSiteApiFetch: action.bound,
      _handleCheckBox: action.bound,
      _handleAddLocation: action.bound,
      _handleSetBuildingLocation: action.bound,
      getCheckedFramework: action.bound,
      setEditMode: action.bound,
      _handleAddUnit_Floor: action.bound,
      _handleDeleteUnit_Floor: action.bound,
      _handleGroup: action.bound
    });
  }

  _handleCheckBox = (key, e) => {
    this.disabledSaveFramework = false;
    const index = this.checkFrameworks.findIndex(v => v._id === e.target.id);

    runInAction(() => {
      this.checkFrameworks[index].checked = e.target.checked;
    });
  }

  _handleAddLocation = (value) => {
    runInAction(() => {
      this.showModal = value;
    });
  }

  _handleSetBuildingLocation = ({ lat, lng }) => {
    
    this.inputs.lat = lat;
    this.inputs.lng = lng;
    this.defaultProps.center.lat = lat;
    this.defaultProps.center.lng = lng;
    // this.inputs.address = this.getReverseGeocodingData(lat, lng);
  }

  getCheckedFramework = (accessmentList, permittedBuilding) => {
    const checkedList = accessmentList.reduce((r, c) => {
      const R = [...r];
      const index = toJS(permittedBuilding)?.findIndex(v => v.framework_id === c._id);
      // console.log("matched index > ", index);
      R.push({ ...c, checked: index > -1 })

      return R
    }, [])
    
    this.allFrameworks = checkedList;
    this.checkFrameworks = checkedList.filter(v => !v.checked);
  }

  setInputs = (v, e, k) => {
    switch (v) {
      case "unit_floor":{
        this.unit_floor[k] = e.target.value
      };
        break;
      case "email":
        this.email = e.target.value;
        break;
      case "username":
        this.userName = e.target.value;
        break;
      case "password":
        this.password = e.target.value;
        break;
      case "buildingName":
        this.buildingName = e.target.value;
        break;
      case "country":
        this.country = e.value;
        break;
      case "city":
        this.city = e.target.value;
        break;
      case "buildingType":
        this.buildingType = e.value;
        break;
      case "Latitude": {
        this.defaultProps.center.lat = Number(e.target.value);
        this.inputs.lat = Number(e.target.value);
      };
        break;
      case "Longtitude": {
        this.inputs.lng = Number(e.target.value);
        this.defaultProps.center.lng = Number(e.target.value);
      }
        break;
      case "framework":
        const temp = [...this.framework];
        var index = temp.indexOf(e.target.value);
        if (index > -1) {
          // temp.filter((v,k) => k !== index);
          this.framework = temp.filter((v, k) => k !== index);
          
        } else {
          temp.push(e.target.value);
          this.framework = temp;
        }
        this.disabledSaveFramework = false;
       
    }

    if (this.isDisabled) {
      const data = {
        siteName: this.buildingName,
        country: this.country,
        city: this.city,
        email: this.email,
        userName: this.userName,
        password: this.password,
        buildingType: this.buildingType,
        frameworks: this.framework,
        lat: this.inputs.lat,
        lng: this.inputs.lng
      };
      const ValidatedErr = CreateNewSiteValidation(data);
      this.err = ValidatedErr;
      if (ValidatedErr.siteNameErr) {
        document.getElementById("buildingName").focus();

      } else if (ValidatedErr.emailErr) {
        document.getElementById("email").focus();

      } else if (ValidatedErr.userNameErr) {
        document.getElementById("userName").focus();

      } else if (ValidatedErr.passwordErr) {
        document.getElementById("password").focus();

      } else if (ValidatedErr.buildingTypeErr) {

      } else if (ValidatedErr.frameworksErr) {

      } else {
        runInAction(() => {
          this.isDisabled = false
        });
      }
    }
  };

  _handleCreateNewSite = (accessmentList) => {
    runInAction(() => {
      this.isDisabled = true;
    });
    const data = {
      siteName: this.buildingName,
      country: this.country,
      city: this.city,
      email: this.email,
      userName: this.userName,
      password: this.password,
      buildingType: this.buildingType,
      frameworks: this.framework,
    };

    this.inputs = {
      buildingName: this.buildingName,
      country: this.country,
      city: this.city,
      type: this.buildingType,
      admin: {
        userName: this.userName,
        email: this.email,
        password: this.password,
      },
      framework: this.framework,
    };

    let admin = {
      userName: this.userName,
      email: this.email,
      password: this.password,
    };

    var formdata = new FormData();
    if (this.photo) {
      formdata.append("photo", this.photo, "blank.jpeg");
    }
    formdata.append("buildingName", this.buildingName);
    formdata.append("type", this.buildingType);
    formdata.append("framework", JSON.stringify(this.framework));
    formdata.append("country", this.country);
    formdata.append("city", this.city);

    var formUser = new FormData();
    formUser.append("userName", this.userName);
    formUser.append("email", this.email);
    formUser.append("password", this.password);

    formdata.append("admin", JSON.stringify(admin));

    const isDuplicateSite = toJS(accessmentList).findIndex(v => v.name?.toLocaleLowerCase() === this.buildingName?.toLocaleLowerCase())

    const ValidatedErr = CreateNewSiteValidation(data);
    this.err = ValidatedErr;
    if (ValidatedErr.siteNameErr) {
      document.getElementById("buildingName").focus();
      toast.error(this.err.siteNameErr);
    } else if (ValidatedErr.buildingTypeErr) {
      toast.error(this.err.buildingTypeErr);
    } else if (ValidatedErr.emailErr) {
      document.getElementById("email").focus();
      toast.error(this.err.emailErr);
    } else if (ValidatedErr.userNameErr) {
      document.getElementById("user_name").focus();
      toast.error(this.err.userNameErr);
    } else if (ValidatedErr.passwordErr) {
      document.getElementById("new_password").focus();
      toast.error(this.err.passwordErr);
    } else if (ValidatedErr.frameworksErr) {
      toast.error(this.err.frameworksErr);
    } else if (isDuplicateSite > -1) {
      toast.error("Site Name already Exists!");
    } else {
      addNewBuilding(formdata, (err, data) => {
        if (err) {
          toast.error(err);
          runInAction(() => {
            this.isDisabled = false;
          });
        } else {
          if (!data.error) {
            toast.success(data.payload);
            window.location.reload();
            runInAction(() => {
              this.isDisabled = false;
            });
          } else toast.error(data.error);
          runInAction(() => {
            this.isDisabled = false;
          });
        }
      });

    }
  };

  _handleCreateOneTimeSite = (permittedBuilding) => {

    runInAction(() => {
      this.isDisabled = true;
    });
    const data = {
      siteName: this.buildingName,
      buildingType: this.buildingType,
      frameworks: this.framework,
      photo: this.photo,
    };

    this.inputs = {
      buildingName: this.buildingName,
      type: this.buildingType,
      framework: this.framework,
      lat: this.inputs.lat,
      lng: this.inputs.lng,
      'unit/floor': this.unit_floor,
      group: this.group
    };

    const ValidatedErr = CreateOneTimeSiteValidation(data);
    this.err = ValidatedErr;

    const duplicateBuilding = toJS(permittedBuilding).findIndex(v => v.buildingName?.toLocaleLowerCase() === this.buildingName?.toLocaleLowerCase())

    if (ValidatedErr.siteNameErr) {
      toast.error(this.err.siteNameErr);
      document.getElementById("buildingName").focus();
    } else if (ValidatedErr.buildingTypeErr) {
      toast.error(this.err.buildingTypeErr);
      // document.getElementById("buildingType").focus();
    } else if (ValidatedErr.frameworksErr) {
      toast.error(this.err.frameworksErr);
    } else if (duplicateBuilding > -1) {
      toast.error('Site Name already Exists!')
    }
    // else if (ValidatedErr.imageErr) {
    //   toast.error(this.err.imageErr);
    // }

    if (Object.keys(ValidatedErr).length === 0 && duplicateBuilding < 0) {
      var formdata = new FormData();
      if (this.photo) {
        formdata.append("photo", this.photo, "blank.jpeg");
      }
      formdata.append("buildingName", this.buildingName);
      formdata.append("type", this.buildingType);
      formdata.append("framework", JSON.stringify(this.framework));
      formdata.append("country", this.country)
      formdata.append("city", this.city)
      formdata.append("lat", this.inputs.lat)
      formdata.append("lng", this.inputs.lng)
      formdata.append('unit/floor', JSON.stringify(this.inputs["unit/floor"]))
      formdata.append("group",this.inputs.group)

      addOneTimeBuilding(formdata, (err, data) => {
        if (err) {
          toast.error(err);
          runInAction(() => {
            this.isDisabled = false;
          });
        } else {
          if (!data.error) {
            toast.success(data.payload);
            // window.location.reload();
            Common.getBuildingList();
            this.photo = null;
            
            if(this.framework.includes('62da2e0c8dc52269efb8e7b1')){
              specialOneTime._createBaselineYear(data.id)
            }
            this._handleClose();
            runInAction(() => {
              this.isDisabled = false;
              this.buildingArr.push(data);
            });
          } else toast.error(data.error);
          runInAction(() => {
            this.isDisabled = false;
          });
        }
      });
    } else {
      runInAction(() => {
        this.isDisabled = false;
      });
    }

  };

  setCheckedValue = (value, checked) => {
    var index = this.checkedArray.indexOf(
      this.checkedArray.find((v) => v === value)
    );
    if (checked) {
      this.checkedArray.push(value);
    } else this.checkedArray.splice(index, 1);

    // if (value !== 0)
    //   checked ? this.checkedArray.push(value) : this.checkedArray.splice(value, 1);
    // //
    // else this.checkedArray = [];
  };

  _handleClose = () => {
    this.setCheckedValue(0);

    runInAction(
      () => (
        (this.buildingName = ""),
        (this.buildingType = ""),
        (this.userName = ""),
        (this.lat = ""),
        (this.lng = ""),
        (this.email = ""),
        (this.password = ""),
        (this.framework = []),
        (this.uploadLogoImage = "")
      )
    );

    var clist = document.getElementsByTagName("input");
    for (var i = 0; i < clist.length; ++i) {
      clist[i].checked = false;
    }
  };

  _handleUploadImage(image) {
    this.uploadLogoImage = URL.createObjectURL(image.target.files[0]);
    this.photo = image.target.files[0];
  }

  getReverseGeocodingData = (lat, lng) => {
    var latlng = new window.google.maps.LatLng(lat, lng);
    // This is making the Geocode request
    var geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ 'latLng': latlng }, (results, status) => {
      if (status !== window.google.maps.GeocoderStatus.OK) {
        alert(status);
      }
      // This is checking to see if the Geoeode Status is OK before proceeding
      if (status == window.google.maps.GeocoderStatus.OK) {
        
        var address = (results[0].formatted_address);
        return address;
      }
    });
    return "";
  }
  _resetImage = () => {
    this.uploadLogoImage = "";
    this.photo = null;
  };
  _handleBuildingAdd() {
    this.building = {
      buildingName: this.buildingName,
      type: this.buildingType,
      logo: this.uploadLogoImage,
      frameworks: this.framework,
    };

    this.buildingArr.push(this.building);
    this._handleClose();
  }

  _handleRemove(index) {
    this.buildingArr.splice(index, 1);
  }

  _handleFrameworkCheck(value) {
    
    const isSelected = this.framework.findIndex((v) => v === value);
    if (isSelected === -1) {
      runInAction(() => {
        this.framework = [...this.framework, value];
      });
    } else {
      this.framework.splice(isSelected, 1);
    }
    if(value==='62da2e0c8dc52269efb8e7b1'&&this.framework.includes(value))
    common.showBaseLineModal= true;

  }

  setCreateNewSiteApiFetch = (value) => {
    this.createNewSiteApiFetch = value

  }

  setEditMode = (value) => {
    
    this.editMode = value
    // console.log(toJS(Common.permittedBuilding).find(v=>v._id===value),toJS(this.building))

    if (value === '') {
      this._resetImage()
      this.buildingName = ''
      this.framework = []
      this.country = []
      this.city = ''
      this.buildingType = ''
      this.inputs.lat = ''
      this.inputs.lng = ''
      this.unit_floor = ['']
      this.group = groupmanagement.selectedGroupId ? [groupmanagement.selectedGroupId] : []
    }
    else {
      this.isDisabled = false;
      const buildingObject = Common.permittedBuilding?.find(v => v._id === value)
      this.editBuilding = buildingObject
      
      this.buildingName = buildingObject?.buildingName
      this.buildingType = buildingObject?.type._id
      this.country = buildingObject?.country
      this.city = buildingObject?.city
      this.photo = baseURL.slice(0, -1) + buildingObject?.photo
      this.uploadLogoImage = baseURL.slice(0, -1) + buildingObject?.photo
      const framework = buildingObject.surveyHeader.map(v => v.framework_id)
      this.framework = framework
      this.lat = buildingObject?.lat
      this.lng = buildingObject?.lng
      this.unit_floor = buildingObject?.unit_floor?.length > 0 ? buildingObject?.unit_floor : [""]
      this.group = buildingObject?.group
    }
    
  }

  _handleEditBuilding = () => {
    let formdata = new FormData();
    if (typeof this.photo === "object") {
      formdata.append("photo", this.photo, "blank.jpeg");
    }
    formdata.append("buildingName", this.buildingName);
    formdata.append("type", this.buildingType);
    formdata.append("framework", JSON.stringify(this.framework));
    formdata.append("country", this.country)
    formdata.append("city", this.city)
    formdata.append("lat", this.inputs.lat)
    formdata.append("lng", this.inputs.lng)
    formdata.append('unit/floor', JSON.stringify(this.inputs["unit/floor"]))
    formdata.append("group",this.inputs.group)

    fetchUpdateBuilding(this.editMode, formdata, (err, data) => {
      if (err) {
        toast.error(err);

      } else {
        if (!data.error) {
          toast.success(data.payload);
          toast.success("Building Updated Successfully!");
          Common.getBuildingList();
          this.setEditMode('')
        } else toast.error(data.error);

      }
    });
  }

  _handleAddUnit_Floor = () => {
    this.unit_floor.push("")
  }

  _handleGroup = (value) => {
    runInAction(() => {
      this.group = value;
    })
  }

  _handleDeleteUnit_Floor = (k) => {
    const temp = [...this.unit_floor];
    temp.splice(k,1);
    this.unit_floor = temp;
  }
}



export default new Building();
