import * as React from 'react';
import { useState, useEffect } from 'react';
import { inject, observer } from "mobx-react";
import {
    Box,
    Collapse,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Paper,
    Grid,
    TableSortLabel,
} from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { makeStyles } from "@material-ui/core/styles";
import { Colors, invertDark } from '../../Assets/Config/color.config';
import { useTranslation } from 'react-i18next';

export const GroupListDetailTable = inject('store')(
    observer((props) => {

        const { data, statusActions } = props;
        const { theme } = props.store.common;
        const { _handleAssetActionChange } = props.store.audit;

        const [rows, setRows] = useState([]);
        const [order, setOrder] = useState("asc");
        const [orderBy, setOrderBy] = useState("");

        const handleRequestSort = (event, property) => {
            const isAsc = orderBy === property && order === "asc";
            setOrder(isAsc ? "desc" : "asc");
            setOrderBy(property);
        };

        const stableSort = (array, comparator) => {
            const stabilizedThis = array.map((el, index) => [el, index]);
            stabilizedThis.sort((a, b) => {
                const order = comparator(a[0], b[0]);
                if (order !== 0) return order;
                return a[1] - b[1];
            });
            return stabilizedThis.map((el) => el[0]);
        };

        const getComparator = (order, orderBy) => {
            return order === "desc"
                ? (a, b) => descendingComparator(a, b, orderBy)
                : (a, b) => -descendingComparator(a, b, orderBy);
        };

        const descendingComparator = (a, b, orderBy) => {
            if (b[orderBy] < a[orderBy]) {
                return -1;
            }
            if (b[orderBy] > a[orderBy]) {
                return 1;
            }
            return 0;
        };

        useEffect(() => {
            setRows(data)
        }, [data])

        const useStyles = makeStyles({
            table: {
                width: "100%",
            },
        });

        const classes = useStyles();
        return (
            <TableContainer
                style={{ background: theme === 'dark' ? Colors[theme].secondBackground : '#f1f1f1', boxShadow: 'none', height: '64vh' }}
                component={Paper}
            >
                <Table className={classes.table} aria-label="">
                    <SortableTHead
                        id={"id"}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        headCells={headers}
                        theme={theme}
                    />
                    <TableBody>
                        {
                            stableSort(rows, getComparator(order, orderBy))
                                ?.map((row, rowIndex) => (
                                    <Row key={rowIndex} row={row} theme={theme} statusActions={statusActions} _handleAssetActionChange={_handleAssetActionChange} />
                                ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        )
    })
)

const SortableTHead = (props) => {
    const { order, orderBy, onRequestSort, headCells, theme } = props;
    const { t } = useTranslation("audit");
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    const styles = makeStyles({
        // Fully visible for active icons
        activeSortIcon: {
            opacity: '1!important',
            color: `${invertDark(theme)}!important`
        },
        // Half visible for inactive icons
        inactiveSortIcon: {
            opacity: '0.3!important',
            color: `${invertDark(theme)}!important`
        },
    });
    const classes = styles();
    return (
        <TableHead sx={{ position: 'sticky', top: 0, zIndex: 10, background: theme === 'dark' ? Colors[theme].secondBackground : '#e5e5e5' }}>
            <TableRow>
                {/* <TableCell /> */}
                {headCells.map((headCell, index) => (
                    <TableCell
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={{
                            color: invertDark(theme),
                            fontWeight: "bold",
                            padding: "18px 13px 18px 13px",
                            textAlign: "center",
                            position: "static",
                            borderLeft: headCell.id === 'actionStatus' ? (`0.5px solid ${theme === 'dark' ? '#696969' : '#c1c1c1'} `) : null
                        }}
                    >
                        {
                            (headCell.id === "" || headCell.id === 'file' || headCell.id === 'remark' || headCell.id === 'actionStatus') ?
                               ( t(headCell.label) ):
                                <TableSortLabel
                                    active={true}
                                    direction={orderBy === headCell.id ? order : "asc"}
                                    onClick={createSortHandler(headCell.id)}
                                    style={{
                                        color: invertDark(theme),
                                    }}
                                    classes={{
                                        icon: ((orderBy === headCell.id) ? classes.activeSortIcon : classes.inactiveSortIcon)
                                    }}
                                >
                                    {t(headCell.label)}
                                </TableSortLabel>
                        }

                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

const Row = (props) => {
    const { row, theme, statusActions, _handleAssetActionChange } = props;
    const [open, setOpen] = React.useState(false);
    const tableCellStyle = { border: 0, color: invertDark(theme), padding: '10px 13px' };
    const { t } = useTranslation("audit");
    const tMonth = useTranslation("month");
    const tEquip = useTranslation("equipment");
    return (
        <React.Fragment>
            <TableRow style={{ borderTop: `0.5px solid ${theme === 'dark' ? '#696969' : '#c1c1c1'} `, background: theme === 'dark' ? Colors[theme].secondBackground : '#f1f1f1' }} sx={{ '& > *': { borderBottom: 'unset' } }}>
                {/* <TableCell style={{ border: 0, padding: '10px 13px', borderTopLeftRadius: 4, borderBottomLeftRadius: open ? 0 : 4 }}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {
                            open ?
                                <KeyboardArrowUpIcon style={{ color: invertDark(theme), fontWeight: 'bold' }} /> :
                                <KeyboardArrowDownIcon style={{ color: invertDark(theme), fontWeight: 'bold' }} />
                        }
                    </IconButton>
                </TableCell> */}
                <TableCell style={{ ...tableCellStyle }} component="th" scope="row">{row.assetName}</TableCell>
                <TableCell style={tableCellStyle} align="center">{tMonth.t(row.month)}</TableCell>
                <TableCell style={tableCellStyle} align="center">{tEquip.t(row.energySource)}</TableCell>
                <TableCell style={tableCellStyle} align="center">{row.consumption}</TableCell>
                <TableCell style={tableCellStyle} align="center">{row.emission}</TableCell>
                <TableCell style={tableCellStyle} align="center">
                    {row.file ?
                        <div className="d-flex justify-content-center align-items-center">
                            <div
                                className="px-2 py-1 me-2"
                                style={{
                                    background: "#00d2133d",
                                    borderRadius: 15,
                                    cursor: 'pointer'
                                }}
                                title="Click to preview"

                            >
                                {row.file?.fileName}
                            </div>
                            <i className="bi bi-download" />
                        </div>
                        : '-'
                    }
                </TableCell>
                <TableCell style={tableCellStyle} align="center">{row.remark}</TableCell>
                <TableCell style={{ ...tableCellStyle, borderLeft: `0.5px solid ${theme === 'dark' ? '#696969' : '#c1c1c1'} ` }} align="center">
                    <div
                        className='d-flex px-1 py-2 justify-content-between m-auto'
                        style={{
                            background: Colors[theme].borderColor,
                            borderRadius: 4,
                            maxWidth: '400px'
                        }}
                    >
                        {
                            statusActions?.map((a, ai) => {
                                return (
                                    <label
                                        key={ai}
                                        className='d-flex col justify-content-center px-2'
                                        style={{
                                            cursor: 'pointer',
                                            color: a.color,
                                            opacity: row.actionStatus !== a.label && 0.7,
                                            whiteSpace: 'nowrap',
                                            borderRight: ai < statusActions.length - 1 ? `0.5px solid ${theme === 'dark' ? '#696969' : '#c1c1c1'} ` : null
                                        }}>
                                        <input
                                            onChange={(e) => _handleAssetActionChange(e, row.assetId, row.month)}
                                            style={{ cursor: 'pointer' }}
                                            className="me-1"
                                            id={a.label}
                                            type='radio'
                                            checked={(row.actionStatus) === a.label}
                                        />
                                        <i>{t(a.label)}</i>
                                    </label>
                                )
                            })
                        }
                    </div>
                </TableCell>
            </TableRow>
            <TableRow style={{ padding: 0, margin: 0, borderSpacing: 0 }}>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0, border: 0, color: invertDark(theme), borderBottomRightRadius: 4, borderBottomLeftRadius: 4 }} colSpan={9}>
                    {/* <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <hr />
                            <Grid container display={'flex'} alignItems={'center'} columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 2 }}>
                                Expand
                            </Grid>
                        </Box>
                    </Collapse> */}
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

const headers = [
    { id: "assetName", numeric: false, label: "Assets" },
    { id: "month", numeric: false, label: "Month" },
    { id: "energySource", numeric: false, label: "Energy Source" },
    { id: "consumption", numeric: true, label: "Consumption" },
    { id: "emission", numeric: true, label: "Emission (KgCo2)" },
    { id: "file", numeric: false, label: "Attached File" },
    { id: "remark", numeric: false, label: "Remark" },
    { id: "actionStatus", numeric: false, label: "Actions" },
]