import { useState, useEffect } from "react";
import { Colors, invertDark } from "../Assets/Config/color.config";
import Store from "../Store";
import { baseURLImage } from "../API/URL";
import { toJS } from "mobx";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { useTranslation } from 'react-i18next';


export const MySortableTable = ({
  columns,
  dataList,
}) => {
  const [rows, setRows] = useState([]);
  const [originalRows, setOriginalRows] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(7);
  const { theme } = Store.common;

  const { t } = useTranslation("equipment");


  useEffect(() => {
    if (window.innerHeight > 700) {
      setRowsPerPage(12);
    }
  }, []);
  const data = dataList
 
  // const data = dataList?.map((v,k) => {
  //   return {
  //       ...v,
  //       key: k
  //   }
  // });

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]).sort().reverse();
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const useStyles = makeStyles({
    table: {
      width: "100%",
      borderCollapse: "separate",
      borderSpacing: "0px 3px",
    },
  });

  const classes = useStyles();

  const StyledTableRow = withStyles((theme) => ({
    root: {
      maxHeight: "50px",
      borderRadius: "10px",
      margin: "20px",
      "&:hover": {
        // backgroundColor: `${Colors[theme].active} !important`,
      },
    },
  }))(TableRow);

  const style = {
    color: invertDark(theme),
    textAlign: "left",
    fontSize: 14,
    padding: "0px 5px",
    borderBottom: `1px solid ${Colors.Dark[2]}`,
  };


  return (
    <>
      <TableContainer
        component={Paper}
        id="sortable_table_container"
        style={{ background: "transparent", boxShadow: "none", overflow: "auto" }}
      >
        <Table
          id="custom_sorting_tbl"
          className={classes.table}
          stickyHeader
          aria-label="customized table"
        >
          <SortableTHead
            id={"id"}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={columns}
            theme={theme}
          />
          <TableBody>
            {data?.length &&
              (stableSort(data, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage))
                .map((v, k) =>
                  <StyledTableRow
                    key={k+v}
                    hover
                  >
                    {Object.values(v).map(value =>
                      <TableCell style={style} key={value}>
                        {(typeof value !== 'string'?value:t(value)) || "-"}
                      </TableCell>
                    )
                    }
                  </StyledTableRow>
                )
            }
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        // className={`${theme}`}
        style={{ color: invertDark(theme), paddingBottom: 0, marginBottom: 0 }}
        rowsPerPageOptions={[]}
        component="div"
        count={data?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  )
};

const SortableTHead = (props) => {
  const { order, orderBy, onRequestSort, headCells, theme } = props;
  const { t } = useTranslation("equipment");
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            key={index}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{
              backgroundColor:
                theme === "dark" ? Colors.primaryBule : Colors.blue,
              color: invertDark(theme),
              fontWeight: "bold",
              padding: "10px 5px",
              textAlign: headCell.id === "size" ? "end" : "left",
              position: "static",
              textAlign: "left",
            }}
          >
            {headCell.sortable === true ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                style={{
                  color: Colors.white,
                }}
              >
                {headCell.label}
              </TableSortLabel>)
              : headCell.label
            }
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
