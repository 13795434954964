import * as API from "./URL";

export const fetchLogHistory = async (callback) => {
    return fetch(API.getLogHistory, {
        method: "get",
        headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            "Access-Control-Allow-Origin": "*",
        },
    }).then((res) => res.json())
        .then((data) => callback(null, data))
        .catch((err) => callback(err.toString(), null));
}