import { Selector } from "../../Tools";
import Store from "../../Store";
import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import { Colors } from "../../Assets/Config/color.config";
import { useTranslation } from 'react-i18next';

export const SurveySelector = inject("store")(
    observer((props) => {
        const { surveyInfo, sectionIndex, _handleSectionClick } =
            props.store.survey;
        const { t } = useTranslation("survey");
        const sections = surveyInfo?.surveySection?.map((s, i) => ({
            value: i,
            label: s.sectionName,
        }));
        return (
            <div className="m-3">
                <Selector
                    menuListColor={Colors.Dark[0]}
                    _handleSelect={(e) => _handleSectionClick(e.value)}
                    label={ t("Choose Survey Section") }
                    options={sections}
                    value={sections?.filter((option) => option.value === sectionIndex)}
                />
            </div>
        );
    })
);
