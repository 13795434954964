import { inject, observer } from "mobx-react";
import { useEffect } from "react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { ReportNavBar } from "../../Components/ReportConfigure/ReportNavBar";
import { ReportSideBar } from "../../Components/ReportConfigure/ReportSideBar";
import { ReportComponentView } from "../../Components/ReportConfigure/ReportComponentView";
import { Selector } from "../../Tools";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AnalysisChart } from "../../Components/AnalysisReport/AnalysisChart";
import Navigator from "../../Tools/MyNavigator";

export const ReportConfigureContainer = inject("store")(
    observer((props) => {
        const navigate = useNavigate();
        const { t } = useTranslation("analysisReport");
        const { theme } = props.store.common;
        const {
            _handleGetYearOptions,
            reportTblLoading,
            yearOptions,
            selectedYear,
            _handleSelectedYear,
            getReportConfiguration,
            getDefaultTempNames,
            getReportCustomConfig,
            _handleReportVarClear,
            selectedTemplate
        } = props.store.reportConfigure;

        useEffect(() => {
            _handleGetYearOptions();
            getReportConfiguration();
            getDefaultTempNames();
        }, []);


        useEffect(() => {
            // Define a function that handles the contextmenu event
            function handleContextMenu(event) {
                event.preventDefault(); // prevent the default contextmenu behavior
            }

            function handleonkeydown(e, flash) {
                if (e.keyCode == 123) {
                    return flash;
                }
                if (e.ctrlKey && e.shiftKey && e.keyCode == 'I'.charCodeAt(0)) {
                    return flash;
                }
                if (e.ctrlKey && e.shiftKey && e.keyCode == 'J'.charCodeAt(0)) {
                    return flash;
                }
                if (e.ctrlKey && e.keyCode == 'U'.charCodeAt(0)) {
                    return flash;
                }
                if (e.ctrlKey && e.shiftKey && e.keyCode == 'C'.charCodeAt(0)) {
                    return flash;
                }
                return true
            }

            // Add the event listener to the document object
            document.addEventListener('contextmenu', handleContextMenu);

            document.onkeydown = function (e) {
                return handleonkeydown(e, false)
            }

            // Remove the event listener when the component unmounts
            return () => {
                document.removeEventListener('contextmenu', handleContextMenu);

                document.onkeydown = function (e) {
                    return handleonkeydown(e, true)
                }
                
                _handleReportVarClear();
            };
        }, []);
  

        useEffect(() => {
            if(selectedTemplate !== ""){
                getReportCustomConfig();
            }
        }, [selectedTemplate]);

        return (
            <>

                <div className="container-fluid">
                    <div className="cardStyle"
                        style={{
                            backgroundColor: theme === "dark" ? Colors.darkBlue : '#efefef',
                            color: Colors[theme].textColor,
                            borderRadius: 24
                        }}>
                        <div className="mx-2 d-flex justify-content-between" style={{ color: Colors[theme].textColor }}>
                            <h4  className="d-flex justify-content-center fw-bold text-nowrap mb-3 " style={{marginTop:10,fontSize:20, color: Colors[theme].textColor }}>
                                <span>{t("Report Configuration")}</span></h4>





                            <div
                                style={{
                                    fontSize: 20,
                                    color: invertDark(theme),
                                    alignItems: "center",
                                    display: "flex",
                                    cursor: "pointer",
                                }}
                                onClick={() => navigate("/dashboard")}
                            // onClick={() => window.history.back()}
                            >
                                <i className="bi bi-arrow-left-circle-fill pe-3"></i>
                                {t("Back")}
                            </div>
                        </div>
                        {reportTblLoading?
                            <div className="m-4 d-flex justify-content-center">
                                <i className="spinner-border mt-2" />
                                </div>
                        :

                        <div>

                        {/* <div className="d-flex justify-content-center"
                        // style={{
                        //     borderRadius: '10px',
                        //     background: theme === 'dark' ? 'rgba(255,255,255,0.06)' : 'rgba(217,217,217,0.6)'
                        // }}
                        >
                            <div className="col-12">
                            <ReportNavBar />
                            </div>
                            

                        </div> */}


                        <div className="">
                            <div className={`row cardStyle`}
                                style={{ borderRadius: 24, width: '100%', padding: 0, backgroundColor: theme === "dark" ? '#020845' : Colors.Dark[5], margin: '10px 0' }}
                            >
                                <div className="col-lg-2 col-md-3 col-sm-4 py-3 px-0" style={{ backgroundColor: theme === "dark" ? '#010637' : '#00000010', borderTopLeftRadius: 24, borderBottomLeftRadius: 24 }}>
                                    <ReportSideBar />
                                </div>
                                <div id="report_component_container" className="col-lg-10 col-md-9 col-sm-8 py-3 text-dark">
                                    <ReportNavBar />
                                    <ReportComponentView/>
                                </div>

                            </div>
                        </div>
                        </div>
                        }
                    </div>

                </div>
            </>
        )
    })

)

