import * as API from "./URL";

export const createTargetSetting = async (data, callback) => {
    return fetch(API.targetSetting, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(data)
    })
        .then((res) => res.json())
        .then((data) => callback(null, data))
        .catch((err) => callback(err.toString(), null));
}

export const createBaselineYear = async (data, callback) => {
    return fetch(API.createBaselineYear(data.buildingId, data.year), {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(data)
    })
        .then((res) => res.json())
        .then((data) => callback(null, data))
        .catch((err) => callback(err.toString(), null));
}

export const fetchTargetSetting = async (buildingId, callback) => {
    return fetch(API.targetSetting + "/" + buildingId, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            "Access-Control-Allow-Origin": "*",
        },
    })
        .then((res) => res.json())
        .then((data) => callback(null, data))
        .catch((err) => callback(err.toString(), null));
}

