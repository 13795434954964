import { MyInput, Selector } from "../../Tools";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { useEffect } from "react";
import { inject, observer } from "mobx-react";
import { MyCheckbox } from "../../Tools";
import { toJS } from "mobx";
import { baseURLImage } from "../../API/URL";
import { MyUploadImage } from "../../Tools";
import { toast } from 'react-toastify'
import allCountry from '../../Assets/countries.json'
import { useTranslation } from 'react-i18next';

const CreateNewSiteModal = inject("store")(
  observer((props) => {
    const {
      getAllBuildingType,
      allBuildingType,
      getAccessmentFramework,
      accessmentList,
      theme
    } = props.store.common;
    const { _handleView, visible } = props.store.login;
    const { t } = useTranslation("landing");

    const {
      setInputs,
      userName,
      email,
      password,
      buildingName,
      buildingType,
      country,
      city,
      _handleCreateNewSite,
      _handleClose,
      checkedArray,
      buildingLogo,
      isDisabled,
      createNewSiteApiFetch
    } = props.store.building;
    useEffect(() => {
      createNewSiteApiFetch && getAllBuildingType();
      createNewSiteApiFetch && getAccessmentFramework();
    }, [createNewSiteApiFetch]);

    const buildingTypeOptions = allBuildingType?.map((building) => ({
      value: building._id,
      label: building.building_type,
    }));
    const CountryOptions = allCountry?.map((country) => (
      {
        value: country.name,
        label: country.name,
        code: country.code.toLocaleLowerCase(),
      }
    ))
    // const [ setInputs] = useState({
    //   buildingName: "",
    //   type: "",
    //   admin: {
    //     userName: "",
    //     email: "",
    //     password: "",
    //   },
    //   framework: [],
    // });

    return (
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"

      >
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div
            className={`modal-content ${theme}`}
            style={{
              background: Colors[theme].active,
              borderRadius: 10,
              color: invertDark(theme)
            }}
          >
            <div className="modal-header mx-3 px-0" >
              <h4 className="modal-title" id="exampleModalLabel">
                { t("Create New Site") }
              </h4>
              <i
                style={{ cursor: "pointer" }}
                className="bi bi-x-lg "
                data-bs-dismiss="modal"
                aria-label={ t("Close") }
                onClick={() => _handleClose()}
              ></i>
            </div>
            {accessmentList && allBuildingType ? <><div className="modal-body py-0">
              <ModalFilterBar
                buildingTypeOptions={buildingTypeOptions}
                CountryOptions={CountryOptions}
                setInputs={setInputs}
                buildingName={buildingName}
                buildingType={buildingType}
                country={country}
                city={city}
                buildingLogo={buildingLogo}
                theme={theme}
              />
              <ModalInputs
                visible={visible}
                _handleView={_handleView}
                accessmentList={accessmentList}
                setInputs={setInputs}
                checkedFramework={checkedArray}
                email={email}
                userName={userName}
                password={password}
                theme={theme}
              />
            </div>
              <div className="modal-footer d-flex justify-content-center mx-3">
                <button
                  type="button"
                  onClick={() => _handleCreateNewSite(accessmentList)}
                  style={{ cursor: "pointer" }}
                  className="btn btn-primary col-2"
                >
                  { t("Create") }
                </button>
              </div></> : <div style={{ height: 500 }} className="d-flex justify-content-center align-items-center"><i className="spinner-border mt-2" /></div>}
          </div>
        </div>
      </div>
    );
  })
);

const ModalFilterBar = inject("store")(
  observer((props) => {
    const { buildingTypeOptions,CountryOptions, setInputs, buildingName, buildingType, country,city, buildingLogo, theme } = props;
    const { _handleUploadImage, photo, _resetImage, uploadLogoImage } = props.store.building;
    const { t } = useTranslation("landing");
    //const { uploadLogoImage } = props.store.building;
    return (
      <div
        className="row  m-0 px-4 py-3"
        style={{ borderBottom: theme === 'dark' ? "1px solid rgb(255, 255, 255,0.1)" : "1px solid rgb(0, 0, 0,0.1)" }}
      >
        <span
          className="d-flex justify-content-between pb-3 p-0"
          style={{ fontSize: 18, fontWeight: 500 }}
        >
          { t("Site Information") }
        </span>
        <div className="row">
          <div className="row col-lg-3 col-sm-12 align-items-center">
            <MyUploadImage theme={theme} _handleUploadImage={_handleUploadImage} photo={photo} _resetImage={_resetImage} uploadLogoImage={uploadLogoImage} />
          </div>
          <div className="col-lg-4 col-sm-12 align-items-center">
            {/* <span className="col-lg-3 col-sm-12 p-0 m-0">Site Name</span> */}
            <span className="my-2">
              <MyInput
                label={ t("Site Name") }
                required={true}
                id={"buildingName"}
                type={"text"}
                value={buildingName}
                onChange={(e) => setInputs("buildingName", e)}
                placeHolder={ t("Site Name" )}
              />
            </span>
            {/* <span className="col-lg-3 col-sm-12 p-0 m-0">Type</span> */}
            <span className="">
              <Selector
                label={ t("Country") } 
                placeholder={ t("Select Country") }
                menuListColor={Colors.Dark[0]}
                options={CountryOptions}
                value={CountryOptions?.filter(
                  (option) => option.value === country
                )}
                _handleSelect={(e) => setInputs("country", e)}
              />
            </span>
          </div>

          <div className="col-lg-4 col-sm-12 align-items-center ms-lg-5 ms-xl-5">
            {/* <span className="col-lg-3 col-sm-12 p-0 m-0">Site Name</span> */}
            <span className="my-2">
              <Selector
                label={ t("Type") }
                placeholder={ t("Select Building Type") }
                menuListColor={Colors.Dark[0]}
                options={buildingTypeOptions}
                value={buildingTypeOptions?.filter(
                  (option) => option.value === buildingType
                )}
                _handleSelect={(e) => setInputs("buildingType", e)}
              />
            </span>
            {/* <span className="col-lg-3 col-sm-12 p-0 m-0">Type</span> */}
            <span className="">
              <MyInput
                label={ t("City") }
                required={true}
                id={"city"}
                type={"text"}
                value={city}
                onChange={(e) => setInputs("city", e)}
                placeHolder={ t("City") }
              />
            </span>
          </div>
        </div>
      </div>
    );
  }));

const ModalInputs = (props) => {
  const {
    accessmentList,

    setInputs,
    checkedFramework,
    _handleView,
    visible,
    userName,
    email,
    password,
    theme
  } = props;
  return (
    <div className="row m-0 px-2">
      <div
        className="col-lg-7 col-sm-12 py-3"
        style={{ borderRight: theme === 'dark' ? "1px solid rgb(255, 255, 255,0.1)" : "1px solid rgb(0, 0, 0,0.1)" }}
      >
        <CreateUser
          setInputs={setInputs}
          _handleView={_handleView}
          visible={visible}
          email={email}
          userName={userName}
          password={password}
          theme={theme}
        />
      </div>
      <div className="col-lg-5 col-sm-12 py-3">
        <RapidAssessment
          accessmentList={accessmentList}
          setInputs={setInputs}
          checkedFramework={checkedFramework}
          theme={theme}
        />
      </div>
    </div>
  );
};
const CreateUser = ({
  setInputs,
  _handleView,
  visible,
  userName,
  email,
  password,
  theme
}) => {
  const { t } = useTranslation("landing");

  return (
    <>
      <span
        className="d-flex justify-content-between pb-3"
        style={{ fontSize: 18, fontWeight: 500 }}
      >
        { t("Create Site Admin") }
      </span>
      <div
        className="row align-items-center m-0"
        style={{
          background: Colors[theme].active,
          borderRadius: 5,
        }}
      >
        <span className="col-6">
          <MyInput
            required={true}
            id={"email"}
            type={"text"}
            value={email}
            onChange={(e) => setInputs("email", e)}
            placeHolder={ t("Email") }
            label={ t("Email") }
          />
        </span>

        <span className="col-6">
          <MyInput
            required={true}
            id={"user_name"}
            type={"text"}
            value={userName}
            onChange={(e) => setInputs("username", e)}
            placeHolder={ t("User Name") }
            label={ t("User Name") }
          />
        </span>

        <span className="col-6 py-2">
          <MyInput
            _handleView={_handleView}
            visible={visible}
            required={true}
            id={"new_password"}
            type={"password"}
            value={password}
            onChange={(e) => setInputs("password", e)}
            placeHolder={ t("Password") }
            label={ t("Password")}
          />
        </span>
      </div>
    </>
  );
};

const RapidAssessment = (props) => {
  const { accessmentList, setInputs, theme } = props;
  const activeStyle = {
    background: Colors[theme].secondBackground,
    cursor: "pointer",
    borderRadius: 5,
  };
  const noActiveStyle = {
    background: Colors[theme].secondBackground,
    cursor: "pointer",
    borderRadius: 5,
  };
  const { t } = useTranslation("landing");

  return (
    <>
      <div
        className="pb-3"
        style={{ fontSize: 18, fontWeight: "500" }}
      >
        { t("Rapid Assessment Framework") }
      </div>
      <div className="scroll" style={{ maxHeight: 250, overflow: 'scroll' }}>
        {accessmentList?.map((r, i) => (
          <label onClick={() => !r.active && toast.error(r.status)} key={i} htmlFor={r._id} style={{ display: "block" }}>
            <div
              className="d-flex justify-content-between align-items-center p-2 mb-2"
              style={r.active ? activeStyle : noActiveStyle}
              title={r.status}
            >
              <span>
                <img
                  className="me-2"
                  crossorigin="anonymous"
                  src={baseURLImage + r.imageURL}
                  height={25}
                  style={{ borderRadius: 2 }}
                />
                {r.name}
              </span>

              <MyCheckbox id={r._id} value={r._id} setInputs={setInputs} disabled={r.active ? false : true} />
            </div>
          </label>
        ))}</div>
    </>
  );
};
export default CreateNewSiteModal;
