import {
    action,
    makeObservable,
    observable,
} from "mobx";
import { toast } from "react-toastify";
import { setGHGBaseline } from "../API/api.accessment";
import {
    createBaselineYear
} from "../API/api.targetSetting";
import common from "./common";

class SpecialOneTime {

    pageIndex = 0;
    baselineYear = new Date(new Date().setFullYear(new Date().getFullYear() - 1));

    constructor(props) {
        makeObservable(this, {
            pageIndex: observable,
            baselineYear: observable,

            setPageIndex: action.bound,
            setBaselineYear: action.bound,
            _handleSetGHGBaseline: action.bound,
            _createBaselineYear: action.bound,

        });
    }


    setPageIndex = (index) => {
        this.pageIndex = index;
    }

    setBaselineYear = (value) => {
        this.baselineYear = value
    }

    _handleSetGHGBaseline = (buildingId) => {
        common.showBaseLineModal=false;
        const fullYear= this.baselineYear.getFullYear();
        // setGHGBaseline(buildingId,fullYear, (err, data) => {
        //     if (data) {
        //         console.log(data)
        //     } else {
        //         console.log(err);
        //         toast.error(err);
        //     }
        // });
    }

    _createBaselineYear(buildingId) {
        const year = new Date(this.baselineYear).getFullYear()
        createBaselineYear({ buildingId, year }, (err, data) => {
            if (err) {
                toast.error(err);
            } else {
                if (!data.error) {
                    toast.success("Created Successfully.");
                    // window.location.reload();
                } else toast.error(data.error);
            }
        });
        common.hideBaseLineModal()
    }
}

export default new SpecialOneTime();