import { Fragment, useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { toJS } from "mobx";
import Pie_Chart from "../../Assets/Charts/PieChart";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { FreeMode, Pagination } from "swiper";
import "../../App.css";

const TotalGHGPieCharts = inject("store")(
  observer((props) => {
    const { theme } = props.store.common;
    const { data } = props;
    const COLORS = [
      "#663567", 
      "#B04E6F", 
      "#E77866",
      "#607d8b",
      "#ffeb3b",
      "#1273DE",
      "#388e3c",
      "#7b64ff",
      "#86442d",
      "#7bdcb5",
    ];
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const ChartDataList = toJS(data).reduce((r, c) => {
      const R = [...r];
      for (let i = 0; i < c.data.length; i++) {
        const index = R.findIndex((v) => v.year === c.data[i].year);
        if (index > -1) {
          R[index].targets.push({ name: c.label, value: c.data[i].value });
        } else {
          R.push({
            year: c.data[i].year,
            targets: [{ name: c.label, value: c.data[i].value }],
          });
        }
      }
      return R;
    }, []);
    
    window.addEventListener('resize', (e) => {
      setScreenWidth(e.target.innerWidth);
    });

    return (
      <div className="d-flex flex-row my-2" style={{color: invertDark(theme)}}>
      <Swiper
        slidesPerView={screenWidth > 960 ? 3: screenWidth > 770 ? 2 : 1}
        spaceBetween={20}
        freeMode={true}
        pagination={{
          clickable: true,
        }}
        modules={[FreeMode, Pagination]}
        className="mySwiper"
      >
        {ChartDataList?.map((v) => (
        <SwiperSlide>
          <div className="col-12 d-flex flex-wrap justify-content-center cardStyle mx-2" style={{background: theme === "dark" ? Colors.darkBlue : Colors.white, color: invertDark(theme) }}>
            <span style={{fontSize: 20}}>GHG Emission ({v.year})</span>
            <div className="container d-flex justify-content-center" style={{color: "#303030"}}>
              <Pie_Chart year={v.year} data={v.targets} />
            </div>

            <div className="row d-flex justify-content-lg-center justify-content-md-start justify-content-md-center">
              {v.targets.length > 0 && v.targets.map((v, k) => {
                return (
                  <div
                    className="col-xl-8 col-lg-12 col-md-10 col-sm-6 d-flex flex-nowrap align-items-center"
                    key={k}
                  >
                    <div
                      className="me-2"
                      style={{
                        height: 12,
                        width: 12,
                        minWidth: 12,
                        background: COLORS[k % COLORS.length],
                      }}
                    >
                      {" "}
                    </div>
                    {v.name} : {v.value?.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }) || 0} {"kg CO₂"}
                  </div>
                );
              })}
            </div>
          </div>
          </SwiperSlide>
        ))}
      </Swiper>
       
      </div>
    );
  })
);
export default inject("store")(observer(TotalGHGPieCharts));
