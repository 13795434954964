import {
    action,
    makeObservable,
    observable,
    runInAction,
    set,
    toJS,
} from "mobx";
import { Cookies } from "react-cookie";
import { toast } from "react-toastify";
import { uploadSiteRelevantFile, fetchGetSiteReleventData,fetchBaselineEmission, fetchScopeAverage, fetchDeleteSite, fetchDownloadFile } from "../API/api.siteRelevant";
import { baseURLImage } from "../API/URL";
import {
    updateUploadDescription
  } from "../API/api.survey";
import survey from "./survey";

class SiteRelevant {
    categoryList = [
        { value: 1, label: "Bill Payment" },
        { value: 2, label: "Layout" },
        { value: 3, label: "Electricity Consumption" },
        { value: 4, label: "Water Consumption" },
        { value: 5, label: "Fuel Consumption" },
        { value: 6, label: "Others " },
    ];
    selectedCategory = null;
    file = null;
    siteList = [];
    baselineEmission = [];
    baselineAverage = [];
    previewFile = {};
    uploadDescription = null;
    linkedFileId = null;
    linkedFileName = null;
    linkedFileMonth = null;
    linkedFileDynamicId = null;
    linkedFileQuestionId = null;
    desAndFileId = "";
    cookies = new Cookies();
    siteModuleData = this.cookies.get("siteModuleData");

    constructor() {
        makeObservable(this, {
            categoryList: observable,
            selectedCategory: observable,
            file: observable,
            siteList: observable,
            baselineEmission: observable,
            baselineAverage: observable,
            previewFile: observable,
            uploadDescription: observable,
            linkedFileId: observable,
            linkedFileName: observable,
            linkedFileMonth: observable,
            desAndFileId: observable,
            linkedFileDynamicId: observable,
            linkedFileQuestionId: observable,

            getSiteList: action.bound,
            getBaselineEmission: action.bound,
            getScopeAverage: action.bound,
            _handleSelectCategory: action.bound,
            _handleUploadFile: action.bound,
            _handleUploadFileAndAddDescription: action.bound,
            _handleChangeFile: action.bound,
            _handleSetPreviewFile: action.bound,
            _handleCloseModal: action.bound,
            _handleDeleteSite: action.bound,
            _handleDownloadFile: action.bound,
            _handleChangeText: action.bound,
            handleSaveUploadedFile: action.bound
        });
    }

    _handleSetPreviewFile = (data) => {
        // 
        this.previewFile = data;
        // runInAction=(()=> {
        //     // this.previewFile = url;
        // })
    }

    _handleSelectCategory(e) {
        runInAction(() => {
            this.selectedCategory = e.label;
        });
    }

    _handleChangeText(e) {
        runInAction(() => {
            this.selectedCategory = e.target.value;
        })
    }

    _handleDescriptionChange = (e, monthYear) => {
        runInAction(() => {
          this.uploadDescription = e.target.value;
        });
      }

    _handleUploadFileAndAddDescription(buildingId, frameworkId, monthYear, btnId, scopeType){

        if (this.file && this.selectedCategory) {
            var formData = new FormData();
            formData.append("file", this.file);
            formData.append("category", this.selectedCategory);
            if (buildingId && frameworkId) {
                const params = {
                    buildingId,
                    frameworkId
                };
                uploadSiteRelevantFile(params, formData, (err, data) => {
                    if (err) {
                        toast.error(err);
                    } else {
                        if (!data.error) {
                            document
                                .getElementById(btnId)
                                .setAttribute("data-bs-dismiss", "modal");
                            let clickEvent = new MouseEvent("click", {
                                view: window,
                                bubbles: true,
                                cancelable: false,
                            });
                            document.getElementById(btnId).dispatchEvent(clickEvent);

                            document
                                .getElementById(btnId)
                                .removeAttribute("data-bs-dismiss");
                            runInAction(() => {
                                this.linkedFileId = data.payload.fileId;
                                this.linkedFileName = data.payload.fileName;
                                this.linkedFileMonth = survey.selectedMonth;   
                                this.getSiteList(buildingId,frameworkId);        
                            });

                            if(btnId === "surveyAns_File_Upload_NotGHG" || btnId === "surveyAns_SubSection_File_Upload_NotGHG" || btnId === "scope2_save_file_and_description"){  
                               
                                survey._handleSurveyFileChange(
                                    this.linkedFileId, 
                                    survey.surveyFileUploadData.questionId,
                                    survey.surveyFileUploadData.childSection,
                                    survey.surveyFileUploadData.questionIndex,
                                    survey.surveyFileUploadData.dynamicDataId,
                                    survey.surveyFileUploadData.monthIndex,
                                    this.uploadDescription?this.uploadDescription:survey.surveyFileUploadData.desValue,
                                    survey.surveyFileUploadData.answerValue);
                                this.selectedCategory = null;
                                this.file = null;
                                this.uploadDescription = null;                               
                            }else{                       
                                survey.handleDataChange(data.payload.fileId, scopeType + "LinkedFileId", survey.selectedMonth, this.uploadDescription, scopeType + "Description", btnId)
                            }                            
                        } else toast.error(data.error);
                    }
                });
            }
        } else if (!toJS(this.file)) {
            toast.error("Please choose file!");
        } else if (!toJS(this.selectedCategory)) {
            toast.error("Please choose category!");
        }
    }

    // to update

    handleSaveUploadedFile = (buildingId, frameworkId, monthYear, scopeTypeParam) => {
        const dataBody = {
          linkedFileId: this.linkedFileId,     
          description: this.uploadDescription,
          scopeType: scopeTypeParam
        }

        const params = survey.selectedAnswerId ? 
        {
          buildingId: buildingId,
          frameworkId: frameworkId,
          answer_id: survey.selectedAnswerId,
          year: survey.selectedYear
        }
        : {
          buildingId: buildingId,
          frameworkId: frameworkId,
          year: survey.selectedYear,
          month: survey.selectedMonth
        }; 
    
        // const params = {
        //   buildingId: buildingId,
        //   frameworkId: frameworkId,
        //   year: monthYear.split("_")[1],
        //   month: monthYear.split("_")[0]
        // };   
    
        updateUploadDescription(params, dataBody, (err, data) => {
          if (data && data.success) {
            toast.success("Successfully Save!");
            runInAction(() => {
                this.desAndFileId = this.linkedFileId;  
            });
            survey.getAllQuestion(frameworkId);
          } else {
            toast.error(err);
          }
        });
      };


///MMP//
    // const month = monthYear.split("_")[0];
                            // const year = monthYear.split("_")[1];

                            // const fileId = data.payload.fileId;

                            // const dataBody = {
                            //     linkedFileId: fileId,     
                            //     description: this.uploadDescription
                            //   }
                          
                            //   const params = {
                            //     buildingId:buildingId,
                            //     frameworkId: frameworkId,
                            //     year: monthYear.split("_")[1],
                            //     month: monthYear.split("_")[0]
                            //   };   
                          
                            //   updateUploadDescription(params, dataBody, (err, dataValue) => {
                            //     if (dataValue && dataValue.success) {
                            //       toast.success("Success!");
 
                            //     } else {
                            //       toast.error(err);
                            //     }
                            //   });

                            ///MMP//

    
   
    _handleUploadFile(buildingId, frameworkId) {
        if (this.file && this.selectedCategory) {
            var formData = new FormData();
            formData.append("file", this.file);
            formData.append("category", this.selectedCategory);
            if (buildingId && frameworkId) {
                const params = {
                    buildingId,
                    frameworkId
                };
                uploadSiteRelevantFile(params, formData, (err, data) => {
                    if (err) {
                        toast.error(err);
                    } else {
                        if (!data.error) {
                            toast.success(data.payload.message);
                            window.location.reload();
                        } else toast.error(data.error);
                    }
                });
            }
        } else if (!toJS(this.file)) {
            toast.error("Please choose file!");
        } else if (!toJS(this.selectedCategory)) {
            toast.error("Please choose category!");
        }
    }

    _handleChangeFile(e) {
        runInAction(() => {
            this.file = e.target.files[0];
        });
    }

    _handleCloseModal() {
        runInAction(
            () => (
                (this.selectedCategory = null),
                (this.file = null)
            )
        );
    }

    getSiteList = (buildingId, frameworkId) => {
        const params = {
            buildingId,
            frameworkId,
        }

        fetchGetSiteReleventData(params, (err, data) => {
            if (!data.error) {
                runInAction(() => {
                    this.siteList = data.payload;
                });

            } else {
                this.siteList=null;
            }
        });
    }

    getBaselineEmission = () => {
        fetchBaselineEmission((err, data) => {
            if (!data.error) {
                runInAction(() => {
                    let result = data.payload;
                    const companyBaselineYear = (this.siteModuleData.companyBaselineYear || 2018 )
                    result.forEach(v=>{
                        let baseline = this.baselineAverage.find(avg=>{
                            return avg.target == v.label
                        });
                        let createdObj = {value: baseline.baselineEmission, year: "Baseline Year"}
                        // if(baseline.target == "Scope 1" || baseline.target == "Scope 3"){
                          
                        // }
                        
                        createdObj.value = result.find(v=>v.label==baseline.target)?.data.find(v=>v.year== companyBaselineYear)?.value;
                        baseline.baselineEmission = createdObj.value;
                        baseline.baselineYear = companyBaselineYear;
                        
                        v.data.unshift(createdObj);
                    });
                    this.baselineEmission = data.payload;

                });

            } else {
                this.baselineEmission=null;
            }
        });
    }

    getScopeAverage = () => {
        fetchScopeAverage((err, data) => {
            if (!data.error) {
                runInAction(() => {
                    this.baselineAverage = data.payload;
                });
                this.getBaselineEmission();
            } else {
                this.baselineAverage=null;
            }
        });
    }
    

    _handleDeleteSite(frameworkId, buildingId) {
        const params = {
            frameworkId, buildingId,
            uploadedId: this.previewFile._id
        }
        fetchDeleteSite(params, (err, data) => {
            if (!data.error) {
                runInAction(() => {
                    this.getSiteList(buildingId, frameworkId);
                    this.previewFile = {}
                });
                toast.success('Deleted Successfully!');
                window.location.reload();
            } else {
                toast.error(err);
            }
        });
    }

    _handleDownloadFile() {
        const filePath = this.previewFile?.filePath;
        const name = this.previewFile?.fileName;
        const type = this.previewFile?.fileType;
        if (type === 'PDF') {
            var link = document.createElement('a');
            link.href = baseURLImage + filePath;
            link.download = name;
            link.dispatchEvent(new MouseEvent('click'));
        } else {
            // 
            fetchDownloadFile(filePath, (err, data) => {
                if (!data.error) {
                    const url = URL.createObjectURL(data);
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    a.download = name;
                    document.body.appendChild(a);
                    a.click();
                    URL.revokeObjectURL(url);

                } else {
                    toast.error(err);
                }
            });
        }

    }
}

export default new SiteRelevant();