import * as React from "react";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import { Colors } from "../Assets/Config/color.config";
import "../App.css";
import { inject, observer } from "mobx-react";

export const MuiInput = inject("store")(
  observer((props) => {
    const { theme } = props.store.common;
    const {
      style,
      placeHolder,
      readOnly,
      required,
      type,
      id,
      height,
      width,
      onChange,
      value,
      maxLength,
      pattern,
      myRef,
      customColor,
      visible,
      endAdornment,
    } = props;

    const defaultStyle = {
      width: width === undefined ? "100%" : width,
      color: "cyan",
      fontSize: 14,
      height: `${height}`,
      boxShadow: "none",
      shapeOutline: "none",
      outline: "none",
      border: `1px solid ${theme === "dark" ? Colors.Dark[2] : "transparent"}`,
      borderRadius: 5,
      background: Colors[theme].secondBackground,
      marginBottom: "-8px",
      marginTop: "-4px",
    };
    
    const userStyle = style === undefined ? {} : style;

    return (
      <Box sx={{ display: "flex", flexWrap: "wrap"}}>
        <FormControl sx={{ m: 1, margin: "8px 0px", width: "100%" }} variant="outlined">
          <OutlinedInput
            id={id}
            value={value != null ? value : ""}
            onChange={onChange}
            endAdornment={endAdornment}
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              readOnly: readOnly
            }}
            style={{
              ...defaultStyle,
              ...userStyle,
              height: "40px",
            }}
            ref={myRef}
            spellCheck="false"
            required={required}
            pattern={pattern ? pattern : null}
            placeholder={placeHolder}
            className={readOnly? "ReadOnly-Input": !readOnly && theme === "dark" ? "Dark-Input" : "Light-Input"}
            type={visible ? "text" : type}
            maxLength={maxLength}
          />
        </FormControl>
      </Box>
    );
  })
);
