import * as React from 'react';
import { inject, observer } from "mobx-react";
import { Colors } from "../../Assets/Config/color.config";
import ReactToPrint from "react-to-print";
import { MyButton } from "../../Tools";
import { useTranslation } from 'react-i18next';
import { MultiLineApexChart } from "../../Assets/Charts/MultiLineApexChart";
import './report.css'
import pdf from "../../Assets/EmissionReportPDF.pdf";
import { Cookies } from "react-cookie";

export const ReportPreviewModal = inject("store")(
    observer((props) => {
        const { headers, thousandSeparator } = props;
        const { theme, groupReports } = props.store.common;
        const { t } = useTranslation("dashboard");

        const cookies = new Cookies();
        const userName = cookies.get("userName");

        return (
            <div
                className={`modal fade ${theme}`}
                id="reportPreviewModal"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-xl">
                    <div
                        className="modal-content"
                        style={{
                            background: Colors[theme].background,
                            borderRadius: 10,
                        }}
                    >
                        <div className="modal-header mx-3 px-0 d-flex justify-content-end">
                            <i
                                style={{ cursor: "pointer" }}
                                className="bi bi-x-lg "
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></i>
                        </div>
                        <div className={`modal-body d-flex justify-content-start flex-wrap m-0 p-0`} style={{ maxHeight: 500, overflow: 'auto' }}>
                            {userName === "ITRIdemo"?
                                (
                                    <>
            
                                        <a href={pdf} download={t("Report")} 
                                        className="btn btn-block rounded text-light text-center m-3"
                                        style={{
                                            background: "rgb(45, 60, 134)",
                                            outline: "none",
                                            boxShadow: "none",
                                            transition: "all 0.5s ease 0s"}}
                                            >{t("Download PDF")}</a>
                                        <iframe src={pdf+"#toolbar=0"} width="100%" height="500px"></iframe>
                                    </>
                                    
                                ):(
                                    <DownloadReportBtn
                                    groupReports={groupReports}
                                    headers={headers}
                                    theme={theme}
                                    t={t}
                                    thousandSeparator={thousandSeparator}
                                /> 
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    })
)

class DownloadReportBtn extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        const { t } = this.props;
        return (
            <>
                <ReactToPrint
                    trigger={() => (
                        <div className='d-flex col-12 py-2 ps-3'>
                            <MyButton
                                id={"downloadPDF"}
                                text={t("Download PDF")}
                                customHoverColor={'#35469b'}
                                onClick={() => null}>
                            </MyButton>
                        </div>
                    )}
                    content={() => this.componentRef}
                    pageStyle="{size: A4 landscape;}"
                    documentTitle={`Report`}
                />
                <ComponentToPrint {...this.props} ref={el => (this.componentRef = el)} />
            </>
        )
    }
}

class ComponentToPrint extends React.Component {
    constructor(props) {
        super(props)
    }
    render() {
        const { groupReports, headers, t, thousandSeparator, theme } = this.props;
        return (
            <div
                className='report-source d-flex flex-wrap justify-content-center'
            >
                <div className='bg-white report-cover mb-2' style={{ width: '592px', height: '842px', paddingTop: '150px', boxShadow: theme !== 'dark' ? '1px 1px 10px #ddd' : 'none' }}>
                    <div
                        className='d-flex align-items-end text-primary'
                        style={{ height: "45vh" }}
                    >
                        <div className='h2 text-center' style={{ width: '100vw' }}>
                            {t('Report')}
                        </div>
                    </div>
                </div>
                {
                    groupReports?.map((v, k) => {
                        return (
                            <div
                                className='report-content mb-2 bg-white'
                                key={k}
                                style={{ width: '592px', height: '842px', padding: '15vh', boxShadow: theme !== 'dark' ? '1px 1px 10px #ddd' : 'none' }}
                            >
                                <table className="table my-3">
                                    <thead>
                                        <tr className=''>
                                            {
                                                headers.map((header, hkey) =>
                                                    <th key={hkey} scope="col" style={{ borderTop: '1px solid #aaa', textAlign: 'center' }}>{t(header.label)}</th>
                                                )
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={{ borderTop: '1px solid #aaa', textAlign: 'center' }}>{v.groupName}</td>
                                            <td style={{ borderTop: '1px solid #aaa', textAlign: 'center' }}>{thousandSeparator((v.totalGroupEmission % 1) === 0 ? v.totalGroupEmission : v.totalGroupEmission?.toFixed(2))}</td>
                                            <td style={{ borderTop: '1px solid #aaa', textAlign: 'center' }}>{thousandSeparator((v.totalGroupPercent % 1) === 0 ? v.totalGroupPercent : v.totalGroupPercent?.toFixed(2))}%</td>
                                            <td style={{ borderTop: '1px solid #aaa', textAlign: 'center' }}>{v.totalGroupAsset}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={5}>
                                                <table className='table table-bordered my-3'>
                                                    <tbody>
                                                        {
                                                            v.scopes.map((v1, k1) => {
                                                                return (
                                                                    <tr key={k1}>
                                                                        <td>{t(`Scope${v1.scope}`)}</td>
                                                                        <td>{thousandSeparator((v1.totalEmissionScope % 1) === 0 ? v1.totalEmissionScope : v1.totalEmissionScope.toFixed(2))}</td>
                                                                        <td>{thousandSeparator((v1.totalScopePercent % 1) === 0 ? v1.totalScopePercent : v1.totalScopePercent.toFixed(2))}%</td>
                                                                        <td>{thousandSeparator((v1.totalAssetScope % 1) === 0 ? v1.totalAssetScope : v1.totalAssetScope.toFixed(2))}</td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={5}>
                                                <MultiLineApexChart data={v.scopes} reportTheme={'light'} />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        )
                    })
                }

                <div className='bg-white report-cover mb-2' style={{ width: '592px', height: '842px', paddingTop: '150px', boxShadow: theme !== 'dark' ? '1px 1px 10px #ddd' : 'none' }}>
                    <div
                        className='d-flex align-items-end text-primary'
                        style={{ height: "45vh" }}
                    >
                        <div className='h4 text-center' style={{ width: '100vw' }}>
                            {t('END OF REPORT')}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}