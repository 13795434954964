import Select from "react-select";
import { Colors, invertDark } from "../Assets/Config/color.config";
import { useTranslation } from 'react-i18next';
import { toJS } from "mobx";

export const Selector = (props) => {
  const { t } = useTranslation("common");
  const {
    options,
    value,
    label,
    labelStyle2,
    // defaultValue,
    _handleSelect,
    disabled,
    menuListColor,
    isMulti,
    id,
    isSearchable,
    placeholder = t("Select_"),
    background,
    getOptionLabel,
    noBorder,
    isMenuPortalTarget,
    isClearable,
    small
  } = props;

  
  const theme = localStorage.getItem("theme");
  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: background? background:Colors[theme].secondBackground,
      color: theme === "dark" ? Colors.Dark[4] : Colors.Dark[1],
      borderRadius: 5,
      minHeight: small ? 20 : 43,
      fontSize: 14,
      width: window.screen.width < 330 && 200,
      borderColor: noBorder? 'transparent': theme === "dark" ? Colors.Dark[2] : Colors.Dark[4],
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        borderColor: Colors.Dark[3],
        cursor: "pointer",
      },
    }),
    menu: (base) => ({
      ...base,
      fontSize: 14,
      borderBottomRadius: 10,
      zIndex: 9999,
      position: 'absolute'
    }),
    menuList: (base) => ({
      ...base,
      color: menuListColor ? menuListColor : null
    }),
    input: (base, state) => ({
      ...base,
      color: 'inherit',
      paddingTop:small ? 0 : 2,
      paddingBottom:small ? 0 : 2,
    }),
    singleValue: (base) => ({
      ...base,
      color: disabled ? '#808080' : invertDark(theme)
    }),
    menuPortal: base => ({ ...base, zIndex: 9999 })
  };
  const Icon = ({ innerRef, innerProps }) => (
    <span className="px-2" role="img" aria-label="Dropdown emoji" ref={innerRef} {...innerProps}>
      <i className="bi bi-caret-down-fill" style={{color:Colors.greenMaterial}}/>
    </span>
  );
  const components = {
    DropdownIndicator: Icon
  };

  return (
    <div id={id}  className={`${id === "manualSector" && "d-flex m-2"}`}>
   
      {label && (
        <>
          <label
            className={`${id === "manualSector" && "mt-2"}`}
            style={{
              // fontWeight: "bold",
              position:'relative',
              top:labelStyle2&&10,
              left:labelStyle2&&10,
              zIndex:1,
              fontSize: 13,
              padding: '2px',
              color: invertDark(theme),
            }}
          >
            {label}{!labelStyle2&&' :'}
          </label>
          <br />
        </>
      )}
      {label==='Country'?
      <Select
        getOptionLabel={e => (
         
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img width={30} src={`/countryflags/${e.code}.svg`}/>
            <span style={{ marginLeft: 5 }}>{e.label}</span>
          </div>
        )}
        isClearable={isClearable}
        placeholder={placeholder}
        isSearchable={isSearchable}
        value={value}
        closeMenuOnSelect={isMulti ? false : true}
        isMulti={isMulti ? true : false}
        styles={customStyles}
        menuPortalTarget={isMenuPortalTarget ? document.body : null} 
        options={options}
        defaultValue={value}
        onChange={(e) => _handleSelect(e)}
        isDisabled={disabled}
      />:
      <Select
      isClearable={isClearable}
        getOptionLabel={getOptionLabel}
        placeholder={placeholder}
        isSearchable={isSearchable}
        value={value || null}
        closeMenuOnSelect={isMulti ? false : true}
        isMulti={isMulti ? true : false}
        styles={customStyles}
        menuPortalTarget={isMenuPortalTarget ? document.body : false} 
        options={options}
        defaultValue={value || null}
        onChange={(e) => _handleSelect(e)}
        isDisabled={disabled}
        components={components}

        
      />}
    </div>
  );
};
