import { Colors, invertDark } from "../../Assets/Config/color.config";
import { ComparisonChart } from "../../Components/YearToYearComparison/ComparisonChart";
import { YearComparisonTable1 } from "../../Components/YearToYearComparison/YearComparisonTable1";
import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import digitalEdgeIcon from "../../Assets/images/digitalEdgeIcon.png";
import { useEffect, useState } from "react";

import { toJS } from "mobx";

export const YearToYearComparison = inject("store")(
  observer((props) => {
    const { theme, getY2yEnergy, getY2yS1Emissions, getY2yS2Emissions, getY2yS3Emissions, getY2yWater } = props.store.common;
    const { y2yEnergy, y2yS1Emissions, y2yS2Emissions, y2yS3Emissions, y2yWater,  companyName } = props.store.common
    const { tabIndex } = props.store.esg;
    const navigate = useNavigate();
    const { t } = useTranslation("one_time");
    const t1 = useTranslation("target_setting")

    const setIntialTable = () => {
      let arr = [];
      for (let index = 0; index < 11; index++) {
        arr.push({
          title: "",
          value: []
        })
      }
      return arr
    }

    const [tableData, setTableData] = useState(setIntialTable());


    useEffect(() => {
      switch (tabIndex) {
        case 0:
          getY2yEnergy()
          break;
        case 2:
          getY2yS1Emissions()
          break;
        case 3:
          getY2yS2Emissions()
          break;
        case 4:
          getY2yS3Emissions()
          break;
        case 1:
          getY2yWater()
          break;
      }
    }, [tabIndex]);

    useEffect(() => {
      setY2yTableData()
    }, [tabIndex === 0 ? y2yEnergy : tabIndex === 2 ? y2yS1Emissions : tabIndex === 3 ? y2yS2Emissions : tabIndex === 4 ? y2yS3Emissions : tabIndex === 1 ? y2yWater : '']);



    const setY2yTableData = () => {
      let data;
      let value;

      switch (tabIndex) {
        case 0:
          const energy =toJS(y2yEnergy)?.filter(d=> d.label !== 'Purchased Heat and Steam')          
          value = setY2yValues(energy)
          
          data = { title: "Energy Consumption", value}
          break;
        case 1:
          value = setY2yValues(y2yWater)
          data = { title: "Water Consumption", value }
          break;
        case 2:
          value = setY2yValues(y2yS1Emissions)
          data = { title: "Scope 1 Emissions", value }
          break;
        case 3:
          value = setY2yValues(y2yS2Emissions)
          data = { title: "Scope 2 Emissions", value }
          break;
        case 4:
          value = setY2yValues(y2yS3Emissions)
          data = { title: "Scope 3 Emissions", value }
          break;

      }

      let tableArr = setIntialTable();
      tableArr[tabIndex] = data || { title: "", value: [] }
      

      setTableData(tableArr)
    }


    const setY2yValues = (Values) => {
      const years = [2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030]

      const labels = toJS(Values)?.map((d) => d.label);

      let yearValues = toJS(Values)?.map((d, j) => {
        return d.data.map((v, k) => {
          v.year = Number(v.year)
          v[d.label] = Number(v.value)
          v[d.label+'per'] = v.percentage
          delete v.value
          return v
        })
      }).flat();

      return years.map((year, i) => {
        let yearObj = {};
        labels?.forEach((l) => yearObj[l] = null)
        const target = yearValues?.filter((y) => y.year == year)

        if (target?.length) {

          target.forEach((t) => {
            yearObj = { ...yearObj, ...t }
          })
        } else {
          yearObj['year'] = year;
        }
        return yearObj
      })
    }



    return (
      <>
        <div className="d-flex justify-content-end  px-3">
          <span
            style={{
              fontSize: 20,
              color: invertDark(theme),
              alignItems: "center",
              display: "flex",
              cursor: "pointer",
            }}
            onClick={() => navigate("/dashboard")}
          >
            <i className="bi bi-arrow-left-circle-fill pe-3"></i>
            {t1.t("Back to Dashboard")}
          </span>
        </div>
        <div className="d-flex justify-content-between align-items-center px-2 mx-2 pb-2">
          <span
            style={{
              fontSize: 25,
              color: theme === "dark" ? "#fff" : "#303030",
            }}
          >
            {t("Overview, Carbon Reduction Year to Year Comparison")}
          </span>
          {
            companyName === 'Digital Edge' && <img
            className="pe-2 pt-2"
            width={"160px"}
            src={digitalEdgeIcon}
            alt={t("Digital Edge Icon")}
          />
          }
          
        </div>

        <div className="mx-3">
          <YearComparisonTable1 data={tableData} />
          <div className="" style={{ height: 410 }}>
            <ComparisonChart data={tableData} />
          </div>
        </div>

      </>
    );
  })
);

// const data = [
//   {
//     title: "Energy Consumption",
//     value: [
//       {
//         year: "2020",
//         "Total energy consumed [Gj]": '-',
//         "Percentage grid electricity": Math.floor(Math.random() * 1000),
//         "Percentage renewables": Math.floor(Math.random() * 1000),
//         "Percentage carbon-free": Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2021",
//         "Total energy consumed [Gj]": Math.floor(Math.random() * 1000),
//         "Percentage grid electricity": Math.floor(Math.random() * 1000),
//         "Percentage renewables": Math.floor(Math.random() * 1000),
//         "Percentage carbon-free": Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2022",
//         "Total energy consumed [Gj]": Math.floor(Math.random() * 1000),
//         "Percentage grid electricity": Math.floor(Math.random() * 1000),
//         "Percentage renewables": Math.floor(Math.random() * 1000),
//         "Percentage carbon-free": Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2023",
//         "Total energy consumed [Gj]": Math.floor(Math.random() * 1000),
//         "Percentage grid electricity": Math.floor(Math.random() * 1000),
//         "Percentage renewables": Math.floor(Math.random() * 1000),
//         "Percentage carbon-free": Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2024",
//         "Total energy consumed [Gj]": Math.floor(Math.random() * 1000),
//         "Percentage grid electricity": Math.floor(Math.random() * 1000),
//         "Percentage renewables": Math.floor(Math.random() * 1000),
//         "Percentage carbon-free": Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2025",
//         "Total energy consumed [Gj]": Math.floor(Math.random() * 1000),
//         "Percentage grid electricity": Math.floor(Math.random() * 1000),
//         "Percentage renewables": Math.floor(Math.random() * 1000),
//         "Percentage carbon-free": Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2026",
//         "Total energy consumed [Gj]": Math.floor(Math.random() * 1000),
//         "Percentage grid electricity": Math.floor(Math.random() * 1000),
//         "Percentage renewables": Math.floor(Math.random() * 1000),
//         "Percentage carbon-free": Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2027",
//         "Total energy consumed [Gj]": Math.floor(Math.random() * 1000),
//         "Percentage grid electricity": Math.floor(Math.random() * 1000),
//         "Percentage renewables": Math.floor(Math.random() * 1000),
//         "Percentage carbon-free": Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2028",
//         "Total energy consumed [Gj]": Math.floor(Math.random() * 1000),
//         "Percentage grid electricity": Math.floor(Math.random() * 1000),
//         "Percentage renewables": Math.floor(Math.random() * 1000),
//         "Percentage carbon-free": Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2029",
//         "Total energy consumed [Gj]": Math.floor(Math.random() * 1000),
//         "Percentage grid electricity": Math.floor(Math.random() * 1000),
//         "Percentage renewables": Math.floor(Math.random() * 1000),
//         "Percentage carbon-free": Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2030",
//         "Total energy consumed [Gj]": Math.floor(Math.random() * 1000),
//         "Percentage grid electricity": Math.floor(Math.random() * 1000),
//         "Percentage renewables": Math.floor(Math.random() * 1000),
//         "Percentage carbon-free": Math.floor(Math.random() * 1000),
//       },
//     ],
//   },
//   {
//     title: "Scope 1 Emissions",
//     value: [
//       {
//         year: "2020",
//         "Scope 1 Emissions (MtCO2e)": Math.floor(Math.random() * 1000),
//         "Stationary combustion (E.g., Generators)": Math.floor(
//           Math.random() * 1000
//         ),
//         "Mobile combustion": Math.floor(Math.random() * 1000),
//         "Fugitive emissions from air-con conditioning": Math.floor(
//           Math.random() * 1000
//         ),
//       },
//       {
//         year: "2021",
//         "Scope 1 Emissions (MtCO2e)": Math.floor(Math.random() * 1000),
//         "Stationary combustion (E.g., Generators)": Math.floor(
//           Math.random() * 1000
//         ),
//         "Mobile combustion": Math.floor(Math.random() * 1000),
//         "Fugitive emissions from air-con conditioning": Math.floor(
//           Math.random() * 1000
//         ),
//       },
//       {
//         year: "2022",
//         "Scope 1 Emissions (MtCO2e)": Math.floor(Math.random() * 1000),
//         "Stationary combustion (E.g., Generators)": Math.floor(
//           Math.random() * 1000
//         ),
//         "Mobile combustion": Math.floor(Math.random() * 1000),
//         "Fugitive emissions from air-con conditioning": Math.floor(
//           Math.random() * 1000
//         ),
//       },
//       {
//         year: "2023",
//         "Scope 1 Emissions (MtCO2e)": Math.floor(Math.random() * 1000),
//         "Stationary combustion (E.g., Generators)": Math.floor(
//           Math.random() * 1000
//         ),
//         "Mobile combustion": Math.floor(Math.random() * 1000),
//         "Fugitive emissions from air-con conditioning": Math.floor(
//           Math.random() * 1000
//         ),
//       },
//       {
//         year: "2024",
//         "Scope 1 Emissions (MtCO2e)": Math.floor(Math.random() * 1000),
//         "Stationary combustion (E.g., Generators)": Math.floor(
//           Math.random() * 1000
//         ),
//         "Mobile combustion": Math.floor(Math.random() * 1000),
//         "Fugitive emissions from air-con conditioning": Math.floor(
//           Math.random() * 1000
//         ),
//       },
//       {
//         year: "2025",
//         "Scope 1 Emissions (MtCO2e)": Math.floor(Math.random() * 1000),
//         "Stationary combustion (E.g., Generators)": Math.floor(
//           Math.random() * 1000
//         ),
//         "Mobile combustion": Math.floor(Math.random() * 1000),
//         "Fugitive emissions from air-con conditioning": Math.floor(
//           Math.random() * 1000
//         ),
//       },
//       {
//         year: "2026",
//         "Scope 1 Emissions (MtCO2e)": Math.floor(Math.random() * 1000),
//         "Stationary combustion (E.g., Generators)": Math.floor(
//           Math.random() * 1000
//         ),
//         "Mobile combustion": Math.floor(Math.random() * 1000),
//         "Fugitive emissions from air-con conditioning": Math.floor(
//           Math.random() * 1000
//         ),
//       },
//       {
//         year: "2027",
//         "Scope 1 Emissions (MtCO2e)": Math.floor(Math.random() * 1000),
//         "Stationary combustion (E.g., Generators)": Math.floor(
//           Math.random() * 1000
//         ),
//         "Mobile combustion": Math.floor(Math.random() * 1000),
//         "Fugitive emissions from air-con conditioning": Math.floor(
//           Math.random() * 1000
//         ),
//       },
//       {
//         year: "2028",
//         "Scope 1 Emissions (MtCO2e)": Math.floor(Math.random() * 1000),
//         "Stationary combustion (E.g., Generators)": Math.floor(
//           Math.random() * 1000
//         ),
//         "Mobile combustion": Math.floor(Math.random() * 1000),
//         "Fugitive emissions from air-con conditioning": Math.floor(
//           Math.random() * 1000
//         ),
//       },
//       {
//         year: "2029",
//         "Scope 1 Emissions (MtCO2e)": Math.floor(Math.random() * 1000),
//         "Stationary combustion (E.g., Generators)": Math.floor(
//           Math.random() * 1000
//         ),
//         "Mobile combustion": Math.floor(Math.random() * 1000),
//         "Fugitive emissions from air-con conditioning": Math.floor(
//           Math.random() * 1000
//         ),
//       },
//       {
//         year: "2030",
//         "Scope 1 Emissions (MtCO2e)": Math.floor(Math.random() * 1000),
//         "Stationary combustion (E.g., Generators)": Math.floor(
//           Math.random() * 1000
//         ),
//         "Mobile combustion": Math.floor(Math.random() * 1000),
//         "Fugitive emissions from air-con conditioning": Math.floor(
//           Math.random() * 1000
//         ),
//       },
//     ],
//   },
//   {
//     title: "Scope 2 Emissions",
//     value: [
//       {
//         year: "2020",
//         "Scope 2 Emissions (MtCO2e)": Math.floor(Math.random() * 1000),
//         "Purchased electricity": Math.floor(Math.random() * 1000),
//         "Purchased heat and steam": Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2021",
//         "Scope 2 Emissions (MtCO2e)": Math.floor(Math.random() * 1000),
//         "Purchased electricity": Math.floor(Math.random() * 1000),
//         "Purchased heat and steam": Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2022",
//         "Scope 2 Emissions (MtCO2e)": Math.floor(Math.random() * 1000),
//         "Purchased electricity": Math.floor(Math.random() * 1000),
//         "Purchased heat and steam": Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2023",
//         "Scope 2 Emissions (MtCO2e)": Math.floor(Math.random() * 1000),
//         "Purchased electricity": Math.floor(Math.random() * 1000),
//         "Purchased heat and steam": Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2024",
//         "Scope 2 Emissions (MtCO2e)": Math.floor(Math.random() * 1000),
//         "Purchased electricity": Math.floor(Math.random() * 1000),
//         "Purchased heat and steam": Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2025",
//         "Scope 2 Emissions (MtCO2e)": Math.floor(Math.random() * 1000),
//         "Purchased electricity": Math.floor(Math.random() * 1000),
//         "Purchased heat and steam": Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2026",
//         "Scope 2 Emissions (MtCO2e)": Math.floor(Math.random() * 1000),
//         "Purchased electricity": Math.floor(Math.random() * 1000),
//         "Purchased heat and steam": Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2027",
//         "Scope 2 Emissions (MtCO2e)": Math.floor(Math.random() * 1000),
//         "Purchased electricity": Math.floor(Math.random() * 1000),
//         "Purchased heat and steam": Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2028",
//         "Scope 2 Emissions (MtCO2e)": Math.floor(Math.random() * 1000),
//         "Purchased electricity": Math.floor(Math.random() * 1000),
//         "Purchased heat and steam": Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2029",
//         "Scope 2 Emissions (MtCO2e)": Math.floor(Math.random() * 1000),
//         "Purchased electricity": Math.floor(Math.random() * 1000),
//         "Purchased heat and steam": Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2030",
//         "Scope 2 Emissions (MtCO2e)": Math.floor(Math.random() * 1000),
//         "Purchased electricity": Math.floor(Math.random() * 1000),
//         "Purchased heat and steam": Math.floor(Math.random() * 1000),
//       },
//     ],
//   },
//   {
//     title: "Scope 3 Emissions",
//     value: [
//       {
//         year: "2020",
//         "Scope 3 Emissions (MtCO2e)": Math.floor(Math.random() * 1000),
//         "Waste generated in operations (start measure in Q3 2023)": Math.floor(
//           Math.random() * 1000
//         ),
//         "Business travel (start measure in Q3 2024)": Math.floor(
//           Math.random() * 1000
//         ),
//         "Employee commuting (start measure in Q3 2024)": Math.floor(
//           Math.random() * 1000
//         ),
//         "Purchased goods and services (start measure in Q3 2025)": Math.floor(
//           Math.random() * 1000
//         ),
//         "Fuel and energy related activities not in scope 1 or 2 (start measure in Q3 2026)":
//           Math.floor(Math.random() * 1000),
//         "Upstream transportation and distribution (start measure in Q3 2026)":
//           Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2021",
//         "Scope 3 Emissions (MtCO2e)": Math.floor(Math.random() * 1000),
//         "Waste generated in operations (start measure in Q3 2023)": Math.floor(
//           Math.random() * 1000
//         ),
//         "Business travel (start measure in Q3 2024)": Math.floor(
//           Math.random() * 1000
//         ),
//         "Employee commuting (start measure in Q3 2024)": Math.floor(
//           Math.random() * 1000
//         ),
//         "Purchased goods and services (start measure in Q3 2025)": Math.floor(
//           Math.random() * 1000
//         ),
//         "Fuel and energy related activities not in scope 1 or 2 (start measure in Q3 2026)":
//           Math.floor(Math.random() * 1000),
//         "Upstream transportation and distribution (start measure in Q3 2026)":
//           Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2022",
//         "Scope 3 Emissions (MtCO2e)": Math.floor(Math.random() * 1000),
//         "Waste generated in operations (start measure in Q3 2023)": Math.floor(
//           Math.random() * 1000
//         ),
//         "Business travel (start measure in Q3 2024)": Math.floor(
//           Math.random() * 1000
//         ),
//         "Employee commuting (start measure in Q3 2024)": Math.floor(
//           Math.random() * 1000
//         ),
//         "Purchased goods and services (start measure in Q3 2025)": Math.floor(
//           Math.random() * 1000
//         ),
//         "Fuel and energy related activities not in scope 1 or 2 (start measure in Q3 2026)":
//           Math.floor(Math.random() * 1000),
//         "Upstream transportation and distribution (start measure in Q3 2026)":
//           Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2023",
//         "Scope 3 Emissions (MtCO2e)": Math.floor(Math.random() * 1000),
//         "Waste generated in operations (start measure in Q3 2023)": Math.floor(
//           Math.random() * 1000
//         ),
//         "Business travel (start measure in Q3 2024)": Math.floor(
//           Math.random() * 1000
//         ),
//         "Employee commuting (start measure in Q3 2024)": Math.floor(
//           Math.random() * 1000
//         ),
//         "Purchased goods and services (start measure in Q3 2025)": Math.floor(
//           Math.random() * 1000
//         ),
//         "Fuel and energy related activities not in scope 1 or 2 (start measure in Q3 2026)":
//           Math.floor(Math.random() * 1000),
//         "Upstream transportation and distribution (start measure in Q3 2026)":
//           Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2024",
//         "Scope 3 Emissions (MtCO2e)": Math.floor(Math.random() * 1000),
//         "Waste generated in operations (start measure in Q3 2023)": Math.floor(
//           Math.random() * 1000
//         ),
//         "Business travel (start measure in Q3 2024)": Math.floor(
//           Math.random() * 1000
//         ),
//         "Employee commuting (start measure in Q3 2024)": Math.floor(
//           Math.random() * 1000
//         ),
//         "Purchased goods and services (start measure in Q3 2025)": Math.floor(
//           Math.random() * 1000
//         ),
//         "Fuel and energy related activities not in scope 1 or 2 (start measure in Q3 2026)":
//           Math.floor(Math.random() * 1000),
//         "Upstream transportation and distribution (start measure in Q3 2026)":
//           Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2025",
//         "Scope 3 Emissions (MtCO2e)": Math.floor(Math.random() * 1000),
//         "Waste generated in operations (start measure in Q3 2023)": Math.floor(
//           Math.random() * 1000
//         ),
//         "Business travel (start measure in Q3 2024)": Math.floor(
//           Math.random() * 1000
//         ),
//         "Employee commuting (start measure in Q3 2024)": Math.floor(
//           Math.random() * 1000
//         ),
//         "Purchased goods and services (start measure in Q3 2025)": Math.floor(
//           Math.random() * 1000
//         ),
//         "Fuel and energy related activities not in scope 1 or 2 (start measure in Q3 2026)":
//           Math.floor(Math.random() * 1000),
//         "Upstream transportation and distribution (start measure in Q3 2026)":
//           Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2026",
//         "Scope 3 Emissions (MtCO2e)": Math.floor(Math.random() * 1000),
//         "Waste generated in operations (start measure in Q3 2023)": Math.floor(
//           Math.random() * 1000
//         ),
//         "Business travel (start measure in Q3 2024)": Math.floor(
//           Math.random() * 1000
//         ),
//         "Employee commuting (start measure in Q3 2024)": Math.floor(
//           Math.random() * 1000
//         ),
//         "Purchased goods and services (start measure in Q3 2025)": Math.floor(
//           Math.random() * 1000
//         ),
//         "Fuel and energy related activities not in scope 1 or 2 (start measure in Q3 2026)":
//           Math.floor(Math.random() * 1000),
//         "Upstream transportation and distribution (start measure in Q3 2026)":
//           Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2027",
//         "Scope 3 Emissions (MtCO2e)": Math.floor(Math.random() * 1000),
//         "Waste generated in operations (start measure in Q3 2023)": Math.floor(
//           Math.random() * 1000
//         ),
//         "Business travel (start measure in Q3 2024)": Math.floor(
//           Math.random() * 1000
//         ),
//         "Employee commuting (start measure in Q3 2024)": Math.floor(
//           Math.random() * 1000
//         ),
//         "Purchased goods and services (start measure in Q3 2025)": Math.floor(
//           Math.random() * 1000
//         ),
//         "Fuel and energy related activities not in scope 1 or 2 (start measure in Q3 2026)":
//           Math.floor(Math.random() * 1000),
//         "Upstream transportation and distribution (start measure in Q3 2026)":
//           Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2028",
//         "Scope 3 Emissions (MtCO2e)": Math.floor(Math.random() * 1000),
//         "Waste generated in operations (start measure in Q3 2023)": Math.floor(
//           Math.random() * 1000
//         ),
//         "Business travel (start measure in Q3 2024)": Math.floor(
//           Math.random() * 1000
//         ),
//         "Employee commuting (start measure in Q3 2024)": Math.floor(
//           Math.random() * 1000
//         ),
//         "Purchased goods and services (start measure in Q3 2025)": Math.floor(
//           Math.random() * 1000
//         ),
//         "Fuel and energy related activities not in scope 1 or 2 (start measure in Q3 2026)":
//           Math.floor(Math.random() * 1000),
//         "Upstream transportation and distribution (start measure in Q3 2026)":
//           Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2029",
//         "Scope 3 Emissions (MtCO2e)": Math.floor(Math.random() * 1000),
//         "Waste generated in operations (start measure in Q3 2023)": Math.floor(
//           Math.random() * 1000
//         ),
//         "Business travel (start measure in Q3 2024)": Math.floor(
//           Math.random() * 1000
//         ),
//         "Employee commuting (start measure in Q3 2024)": Math.floor(
//           Math.random() * 1000
//         ),
//         "Purchased goods and services (start measure in Q3 2025)": Math.floor(
//           Math.random() * 1000
//         ),
//         "Fuel and energy related activities not in scope 1 or 2 (start measure in Q3 2026)":
//           Math.floor(Math.random() * 1000),
//         "Upstream transportation and distribution (start measure in Q3 2026)":
//           Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2030",
//         "Scope 3 Emissions (MtCO2e)": Math.floor(Math.random() * 1000),
//         "Waste generated in operations (start measure in Q3 2023)": Math.floor(
//           Math.random() * 1000
//         ),
//         "Business travel (start measure in Q3 2024)": Math.floor(
//           Math.random() * 1000
//         ),
//         "Employee commuting (start measure in Q3 2024)": Math.floor(
//           Math.random() * 1000
//         ),
//         "Purchased goods and services (start measure in Q3 2025)": Math.floor(
//           Math.random() * 1000
//         ),
//         "Fuel and energy related activities not in scope 1 or 2 (start measure in Q3 2026)":
//           Math.floor(Math.random() * 1000),
//         "Upstream transportation and distribution (start measure in Q3 2026)":
//           Math.floor(Math.random() * 1000),
//       },
//     ],
//   },
//   {
//     title: "Carbon offsets",
//     value: [
//       {
//         year: "2020",
//         "Carbon offsets": Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2021",
//         "Carbon offsets": Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2022",
//         "Carbon offsets": Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2023",
//         "Carbon offsets": Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2024",
//         "Carbon offsets": Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2025",
//         "Carbon offsets": Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2026",
//         "Carbon offsets": Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2027",
//         "Carbon offsets": Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2028",
//         "Carbon offsets": Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2029",
//         "Carbon offsets": Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2030",
//         "Carbon offsets": Math.floor(Math.random() * 1000),
//       },
//     ],
//   },
//   {
//     title: "PUE for Acquired, Legacy Sites",
//     value: [
//       {
//         year: "2020",
//         "Average PUE for Acquired, Legacy Sites": Math.floor(
//           Math.random() * 1000
//         ),
//       },
//       {
//         year: "2021",
//         "Average PUE for Acquired, Legacy Sites": Math.floor(
//           Math.random() * 1000
//         ),
//       },
//       {
//         year: "2022",
//         "Average PUE for Acquired, Legacy Sites": Math.floor(
//           Math.random() * 1000
//         ),
//       },
//       {
//         year: "2023",
//         "Average PUE for Acquired, Legacy Sites": Math.floor(
//           Math.random() * 1000
//         ),
//       },
//       {
//         year: "2024",
//         "Average PUE for Acquired, Legacy Sites": Math.floor(
//           Math.random() * 1000
//         ),
//       },
//       {
//         year: "2025",
//         "Average PUE for Acquired, Legacy Sites": Math.floor(
//           Math.random() * 1000
//         ),
//       },
//       {
//         year: "2026",
//         "Average PUE for Acquired, Legacy Sites": Math.floor(
//           Math.random() * 1000
//         ),
//       },
//       {
//         year: "2027",
//         "Average PUE for Acquired, Legacy Sites": Math.floor(
//           Math.random() * 1000
//         ),
//       },
//       {
//         year: "2028",
//         "Average PUE for Acquired, Legacy Sites": Math.floor(
//           Math.random() * 1000
//         ),
//       },
//       {
//         year: "2029",
//         "Average PUE for Acquired, Legacy Sites": Math.floor(
//           Math.random() * 1000
//         ),
//       },
//       {
//         year: "2030",
//         "Average PUE for Acquired, Legacy Sites": Math.floor(
//           Math.random() * 1000
//         ),
//       },
//     ],
//   },
//   {
//     title: "PUE for New Developments",
//     value: [
//       {
//         year: "2020",
//         "PUE for New Developments": Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2021",
//         "PUE for New Developments": Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2022",
//         "PUE for New Developments": Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2023",
//         "PUE for New Developments": Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2024",
//         "PUE for New Developments": Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2025",
//         "PUE for New Developments": Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2026",
//         "PUE for New Developments": Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2027",
//         "PUE for New Developments": Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2028",
//         "PUE for New Developments": Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2029",
//         "PUE for New Developments": Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2030",
//         "PUE for New Developments": Math.floor(Math.random() * 1000),
//       },
//     ],
//   },
//   {
//     title: "WUE for Acquired, Legacy Sites",
//     value: [
//       {
//         year: "2020",
//         "Average WUE for Acquired, Legacy Sites": Math.floor(
//           Math.random() * 1000
//         ),
//       },
//       {
//         year: "2021",
//         "Average WUE for Acquired, Legacy Sites": Math.floor(
//           Math.random() * 1000
//         ),
//       },
//       {
//         year: "2022",
//         "Average WUE for Acquired, Legacy Sites": Math.floor(
//           Math.random() * 1000
//         ),
//       },
//       {
//         year: "2023",
//         "Average WUE for Acquired, Legacy Sites": Math.floor(
//           Math.random() * 1000
//         ),
//       },
//       {
//         year: "2024",
//         "Average WUE for Acquired, Legacy Sites": Math.floor(
//           Math.random() * 1000
//         ),
//       },
//       {
//         year: "2025",
//         "Average WUE for Acquired, Legacy Sites": Math.floor(
//           Math.random() * 1000
//         ),
//       },
//       {
//         year: "2026",
//         "Average WUE for Acquired, Legacy Sites": Math.floor(
//           Math.random() * 1000
//         ),
//       },
//       {
//         year: "2027",
//         "Average WUE for Acquired, Legacy Sites": Math.floor(
//           Math.random() * 1000
//         ),
//       },
//       {
//         year: "2028",
//         "Average WUE for Acquired, Legacy Sites": Math.floor(
//           Math.random() * 1000
//         ),
//       },
//       {
//         year: "2029",
//         "Average WUE for Acquired, Legacy Sites": Math.floor(
//           Math.random() * 1000
//         ),
//       },
//       {
//         year: "2030",
//         "Average WUE for Acquired, Legacy Sites": Math.floor(
//           Math.random() * 1000
//         ),
//       },
//     ],
//   },
//   {
//     title: "WUE for New Developments",
//     value: [
//       {
//         year: "2020",
//         "Average WUE for New Developments": Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2021",
//         "Average WUE for New Developments": Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2022",
//         "Average WUE for New Developments": Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2023",
//         "Average WUE for New Developments": Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2024",
//         "Average WUE for New Developments": Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2025",
//         "Average WUE for New Developments": Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2026",
//         "Average WUE for New Developments": Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2027",
//         "Average WUE for New Developments": Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2028",
//         "Average WUE for New Developments": Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2029",
//         "Average WUE for New Developments": Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2030",
//         "Average WUE for New Developments": Math.floor(Math.random() * 1000),
//       },
//     ],
//   },
//   {
//     title: "Water consumption",
//     value: [
//       {
//         year: "2020",
//         "Total Water consumption (m³)": Math.floor(Math.random() * 1000),
//         "Percentage water consumed in regions with High or Extremely High Baseline Water Stress":
//           Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2021",
//         "Total Water consumption (m³)": Math.floor(Math.random() * 1000),
//         "Percentage water consumed in regions with High or Extremely High Baseline Water Stress":
//           Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2022",
//         "Total Water consumption (m³)": Math.floor(Math.random() * 1000),
//         "Percentage water consumed in regions with High or Extremely High Baseline Water Stress":
//           Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2023",
//         "Total Water consumption (m³)": Math.floor(Math.random() * 1000),
//         "Percentage water consumed in regions with High or Extremely High Baseline Water Stress":
//           Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2024",
//         "Total Water consumption (m³)": Math.floor(Math.random() * 1000),
//         "Percentage water consumed in regions with High or Extremely High Baseline Water Stress":
//           Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2025",
//         "Total Water consumption (m³)": Math.floor(Math.random() * 1000),
//         "Percentage water consumed in regions with High or Extremely High Baseline Water Stress":
//           Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2026",
//         "Total Water consumption (m³)": Math.floor(Math.random() * 1000),
//         "Percentage water consumed in regions with High or Extremely High Baseline Water Stress":
//           Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2027",
//         "Total Water consumption (m³)": Math.floor(Math.random() * 1000),
//         "Percentage water consumed in regions with High or Extremely High Baseline Water Stress":
//           Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2028",
//         "Total Water consumption (m³)": Math.floor(Math.random() * 1000),
//         "Percentage water consumed in regions with High or Extremely High Baseline Water Stress":
//           Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2029",
//         "Total Water consumption (m³)": Math.floor(Math.random() * 1000),
//         "Percentage water consumed in regions with High or Extremely High Baseline Water Stress":
//           Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2030",
//         "Total Water consumption (m³)": Math.floor(Math.random() * 1000),
//         "Percentage water consumed in regions with High or Extremely High Baseline Water Stress":
//           Math.floor(Math.random() * 1000),
//       },
//     ],
//   },
//   {
//     title: "Water withdrawn",
//     value: [
//       {
//         year: "2020",
//         "Total Water withdrawn (m³)": Math.floor(Math.random() * 1000),
//         "Percentage water withdrawn in regions with High or Extremely High Baseline Water Stress":
//           Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2021",
//         "Total Water withdrawn (m³)": Math.floor(Math.random() * 1000),
//         "Percentage water withdrawn in regions with High or Extremely High Baseline Water Stress":
//           Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2022",
//         "Total Water withdrawn (m³)": Math.floor(Math.random() * 1000),
//         "Percentage water withdrawn in regions with High or Extremely High Baseline Water Stress":
//           Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2023",
//         "Total Water withdrawn (m³)": Math.floor(Math.random() * 1000),
//         "Percentage water withdrawn in regions with High or Extremely High Baseline Water Stress":
//           Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2024",
//         "Total Water withdrawn (m³)": Math.floor(Math.random() * 1000),
//         "Percentage water withdrawn in regions with High or Extremely High Baseline Water Stress":
//           Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2025",
//         "Total Water withdrawn (m³)": Math.floor(Math.random() * 1000),
//         "Percentage water withdrawn in regions with High or Extremely High Baseline Water Stress":
//           Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2026",
//         "Total Water withdrawn (m³)": Math.floor(Math.random() * 1000),
//         "Percentage water withdrawn in regions with High or Extremely High Baseline Water Stress":
//           Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2027",
//         "Total Water withdrawn (m³)": Math.floor(Math.random() * 1000),
//         "Percentage water withdrawn in regions with High or Extremely High Baseline Water Stress":
//           Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2028",
//         "Total Water withdrawn (m³)": Math.floor(Math.random() * 1000),
//         "Percentage water withdrawn in regions with High or Extremely High Baseline Water Stress":
//           Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2029",
//         "Total Water withdrawn (m³)": Math.floor(Math.random() * 1000),
//         "Percentage water withdrawn in regions with High or Extremely High Baseline Water Stress":
//           Math.floor(Math.random() * 1000),
//       },
//       {
//         year: "2030",
//         "Total Water withdrawn (m³)": Math.floor(Math.random() * 1000),
//         "Percentage water withdrawn in regions with High or Extremely High Baseline Water Stress":
//           Math.floor(Math.random() * 1000),
//       },
//     ],
//   },
// ];
