import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableHead,
    TableSortLabel,
    TableRow,
    Paper,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { format } from 'date-fns'
import '../../App.css'
import { baseURLImage } from '../../API/URL';

import Store from "../../Store";
import SearchBar from 'material-ui-search-bar';
import { toJS } from 'mobx';
import { useTranslation } from 'react-i18next';

const SiteManagementTable = (props) => {
    const [rows, setRows] = useState([]);
    const [originalRows, setOriginalRows] = useState([]);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const { t } = useTranslation("site_management"); 

    const headCell = [
        { id: 'site', numeric: false, label: t("Site"), },
        { id: 'siteType', numeric: false, label: t("Site Type"), },
        { id: 'floorOrUnit', numeric: false, label: t("Unit or Floor"), },
        { id: 'frameworks', numeric: false, label: t("Frameworks"), }
    ]

    const {
        permittedBuilding,
        theme
    } = Store.common;

    const { _handleEditRow, editFormList } = Store.siteManagement;

    useEffect(() => {
        setPage(0);
        if (toJS(permittedBuilding)?.length > 0) {
            const rows = (toJS(permittedBuilding).map((v) => {
                return {
                    id: v._id,
                    buildingName: v.buildingName,
                    photo: v.photo,
                    type: v.type.building_type,
                    floorOrUnit: "",
                    frameworks: v.surveyHeader,
                    // unit: v.unit
                    unit: [{ _id: '62da32a440ad0cd33ac18a90', unitName: 'ChangeFloor573' },
                    { _id: '3eregw35342te18a90', unitName: 'ChangeFloor111' }
                    ]
                }
            }));
            setOriginalRows(rows);
            setRows(rows);
        }

    }, [permittedBuilding]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const requestSearch = (searchedVal) => {
        const filteredRows = originalRows.filter((row) => {
            return (
                row.buildingName?.toLowerCase().includes(searchedVal.toLowerCase()) ||
                row.type?.toLowerCase().includes(searchedVal.toLowerCase())
            )
        });
        setRows(filteredRows)
    };

    const cancelSearch = () => {
        setRows(originalRows)
    };

    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(e.target.value)
    }

    const StyledTableRow = withStyles((defaultTheme) => ({
        root: {
            // borderBottom: '0.5px solid #e0e0e0',
            // height: '50px',
            maxHeight: '50px',
            // background: Colors.darkBlue,
            borderRadius: '10px',
            margin: '20px',
            "&:hover": {
                backgroundColor: `${Colors[theme].active} !important`
            }
        },

    }))(TableRow);

    const useStyles = makeStyles({
        table: {
            //minWidth: 800,
            width: '100%',
            borderSpacing: '0px 3px'
            // minHeight: 328
        },
    });


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const descendingComparator = (a, b, orderBy) => {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    const getComparator = (order, orderBy) => {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    const stableSort = (array, comparator) => {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    SortableTHead.propTypes = {
        onRequestSort: PropTypes.func.isRequired,
        order: PropTypes.oneOf(['asc', 'desc']).isRequired,
        orderBy: PropTypes.string.isRequired,
    };

    const classes = useStyles();

    return (
        <div className="">
            <div className={`d-flex justify-content-between mb-3 ${theme}`}>
                <div className="pt-1">
                    <span className="fw-bold">
                        {permittedBuilding && t("Building Count", {count: permittedBuilding.length})}
                    </span>
                </div>
                <SearchBar
                    placeholder={ t("Search_") }
                    style={{
                        width: "30%",
                        height: 35,
                        background: "transparent",
                        boxShadow: "none",
                        borderBottom: `1px solid ${invertDark(theme)}`,
                        borderRadius: 0,
                    }}
                    // value={searched}
                    onChange={(searchVal) => requestSearch(searchVal)}
                    onCancelSearch={() => cancelSearch()}
                />
            </div>
            <TableContainer component={Paper} id='tblContainer' style={{ background: 'transparent', boxShadow: 'none' }}>
                <Table id="custom_tbl" className={classes.table} stickyHeader aria-label="customized table" >
                    <SortableTHead
                        id={'id'}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        headCells={headCell}
                        theme={theme}
                    />
                    <TableBody >
                        {
                            stableSort(rows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((v, k) => {
                                    const units = v.unit?.length - 1;
                                    const style = { color: Colors[theme].textColor, textAlign: 'left', fontSize: 14, paddingLeft: '12px' }
                                    return (
                                        <StyledTableRow hover key={v.id} style={{ cursor: 'pointer', background: editFormList.id === v.id ? Colors[theme].active : '' }} onClick={() => _handleEditRow(v)} >
                                            <td style={{ ...style, cursor: 'pointer' }}>
                                                <img
                                                    className="me-2"
                                                    src={`${baseURLImage}${v.photo}`}
                                                    width={30}
                                                    height={30}
                                                />
                                                {v.buildingName}
                                            </td>
                                            <td style={{ ...style }}>
                                                {v.type}
                                            </td>
                                            <td style={{ ...style }} >
                                                {v.unit?.length > 0 ?
                                                    v.unit.map((v, k) => k === units ?
                                                        v.unitName
                                                        : v.unitName + ","
                                                    )
                                                    : null
                                                }
                                            </td>
                                            <td style={{ ...style }} >
                                                {v.frameworks?.map((value, key) => (
                                                    <span title={value.name} key={key}>
                                                        <img
                                                            className="mx-1"
                                                            crossorigin="anonymous"
                                                            src={baseURLImage + value.imageURL}
                                                            width={20}
                                                        />
                                                    </span>
                                                ))}
                                            </td>
                                        </StyledTableRow>
                                    )
                                })


                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                className={`${theme}`}
                style={{ color: invertDark(theme) }}
                rowsPerPageOptions={[]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </div>
    )
}

export default inject("store")(observer(SiteManagementTable))

const SortableTHead = (props) => {
    const { order, orderBy, onRequestSort, headCells, theme } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={{
                            backgroundColor: theme === 'dark' ? Colors.primaryBule : Colors.blue,
                            color: invertDark(theme),
                            fontWeight: "bold",
                            padding: '8px 13px 8px 13px',
                            textAlign: headCell.id === 'size' ? 'end' : 'center',
                            position: 'static',
                            textAlign: "left"
                        }}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                            style={{
                                color: Colors.white
                            }}
                        >
                            {headCell.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}
