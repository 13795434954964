import { useState } from 'react'; 
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useTranslation } from "react-i18next";
import { Colors } from "../Assets/Config/color.config";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export const MuiSelector = (props) => {
    const { t } = useTranslation("common");
    const {
      options,
      value,
      _handleSelect,
      style,
      disabled,
      linkedFileId,
      menuListColor,
      isMulti,
      id,
      isSearchable,
      placeholder = t("Select_"),
      background,
      getOptionLabel,
      noBorder,
      uploadFileModalId,
      _handleUploadBtn,
      type
    } = props;
    const [open, setOpen] = useState(false);


    const onClose = () => {
      setOpen(false);
      _handleUploadBtn()
    }
  
    const theme = localStorage.getItem("theme");

    const customStyle = {
        ...style,
        background: background ? background : Colors[theme].secondBackground,
        color: '#5E5E5E',
        minHeight: 43,
        fontSize: 14,
        cursor: disabled ? 'not-allowed !important' : 'default',
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: theme === "dark"?Colors.Dark[2]:Colors.Dark[4],
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: theme === "dark"?Colors.Dark[2]:Colors.Dark[4],
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: theme === "dark"?Colors.Dark[2]:Colors.Dark[4],
        },
        '.MuiSvgIcon-root ': {
          fill: 'rgb(4, 197, 0)',
        },
        '.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input' : {
          paddingTop : 0,
          paddingBottom: 0
        },
        '.MuiSelect-select': {
          cursor : disabled ? 'not-allowed !important' : 'default',
        }
      };
    const Icon = ({ innerRef, innerProps }) => (
      <span
        className="px-2"
        role="img"
        aria-label="Dropdown emoji"
        ref={innerRef}
        {...innerProps}
      >
        <i
          className="bi bi-caret-down-fill"
          style={{ color: Colors.greenMaterial }}
        />
      </span>
    );
    const components = {
      DropdownIndicator: Icon,
    };

  return (
    <Box sx={{ width: '100%' , paddingLeft : '0 !important' , paddingRight : '0 !important', paddingTop : '10px' }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label" style={{
              color : disabled ? '#808080' : Colors.Dark[4],
              lineHeight : '1em',
              overflow : 'hidden',
              fontSize: "12px",
              width: '80%',
              whiteSpace: 'nowrap',
              textAlign: 'start',
              display: `${type === "monthly_dynamic_tbl" && value ? "none" : 'unset' }`,
              textOverflow: 'ellipsis'
           }}>{value ? value : t("Select_")}</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                placeholder={placeholder}
                value={value}
                disabled={disabled}
                sx={customStyle}
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                onChange={(e) => {
                  _handleSelect(e)
                }}
                components={components}
            >
            {options.map((option,index) => (
                <MenuItem
                  key={index}
                  value={option.value}
                >
                  {option.label}
                </MenuItem>
            ))}
              {/* <div style={{ textAlign : 'center' }}>
                <span
                  data-bs-toggle="modal"
                  data-bs-target={uploadFileModalId}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <button
                    type="button"
                    style={{
                      background: "#202b60",
                      width: "50%",
                      color: "rgb(248, 249, 250)",
                      cursor: "pointer",
                      border: "2px solid #202b60",
                      borderRadius: "3px",
                    }}
                    onClick={() => onClose()}
                  >
                    {t("upload_new")}
                  </button>
                </span>
              </div> */}
            </Select>
      </FormControl>
    </Box>
  );
}
