import { inject, observer } from "mobx-react";
import { Colors } from "../../Assets/Config/color.config";
import Step1 from '../../Assets/images/QuickSetup/Step1.png'
import Step1_light from '../../Assets/images/QuickSetup/Step1_light.png'
import Step1TW from '../../Assets/images/QuickSetup/Step1-tw.png'
import Step2 from '../../Assets/images/QuickSetup/Step2.png'
import Step2_light from '../../Assets/images/QuickSetup/Step2_light.png'
import Step2TW from '../../Assets/images/QuickSetup/Step2-tw.png'
import Step3 from '../../Assets/images/QuickSetup/Step3.png'
import Step3_light from '../../Assets/images/QuickSetup/Step3_light.png'
import Step4 from '../../Assets/images/QuickSetup/Step4.png'
import Step4_light from '../../Assets/images/QuickSetup/Step4_light.png'
import Step4TW from '../../Assets/images/QuickSetup/Step4-tw.png'
import Step5 from '../../Assets/images/QuickSetup/Step5.png'
import Step5_light from '../../Assets/images/QuickSetup/Step5_light.png'
import Step5TW from '../../Assets/images/QuickSetup/Step5-tw.png'
import Step6 from '../../Assets/images/QuickSetup/Step6.png'
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

const SetUpGuide = inject("store")(
    observer((props)=> {
        const { theme } = props.store.common;

        const {t,i18n} = useTranslation("quickOrganizationSetUp")

        const guides = (i18n) => [
            {
                info: 'Asset can be an object that emits CO₂ directly/indirectly or an energy source that effects on global warming.',
                imgTitle: null,
                img: i18n.resolvedLanguage === "tw" ? Step1TW : ( theme === 'dark' ? Step1 : Step1_light ),
                description: []
            },
            {
                info: 'Asset may be Chiller, Car, Generator, etc...',
                imgTitle: null,
                img: i18n.resolvedLanguage === "tw" ? Step2TW : ( theme === 'dark' ? Step2 : Step2_light ),
                description: []
            },
            {
                info: 'Group contain one or many assets.',
                imgTitle: 'Group',
                img: ( theme === 'dark' ? Step3 : Step3_light ),
                description: []
            },
            {
                info: 'Group may be an Organization, a Building,  an Office or a Fleet.',
                imgTitle: null,
                img: i18n.resolvedLanguage === "tw" ? Step4TW : ( theme === 'dark' ? Step4 : Step4_light ),
                description: []
            }, 
            {
                info: 'One Asset can contain in one or many groups.',
                imgTitle: 'Community',
                img: i18n.resolvedLanguage === "tw" ? Step5TW : ( theme === 'dark' ? Step5 : Step5_light ),
                description: []
            },
            {
                info: null,
                imgTitle: null,
                img: Step6,
                description: ['The purpose of creating groups is to calculate CO₂ emission by organization, building, office or fleet.', 'You can combine assets as group later.']
            }
        ]

        const romanNumbers = ["I","II","III","IV","V","VI"];

        return(
            <div
                className="p-3 d-flex row"
                style={{
                    background: Colors[theme].background,
                    borderRadius: 8
                }}
            >
                {
                    guides(i18n).map((v,k)=> {
                        return(
                            <div
                                key={k}
                                className="col-lg-4 col-md-4 col-sm-12 p-1 d-flex flex-stretch"
                            >
                                <div
                                    className="p-2 w-100"
                                    style={{
                                        background: Colors[theme].borderColor,
                                        borderRadius: 6
                                    }}
                                >
                                    <div className="d-flex">
                                        <span 
                                            className="fw-bold me-1" 
                                            style={{
                                                minWidth: 23, 
                                                minHeight: 23,
                                                maxWidth: 23, 
                                                maxHeight: 23,
                                                textAlign: 'center', 
                                                borderRadius: '50%',
                                                background: theme === 'dark' ? "rgba(210,210,210,0.33)" : 'rgba(255,255,255,0.6)',
                                                color: theme === 'dark' ? Colors.white : Colors.dark[0],
                                                fontSize: 14
                                            }}>
                                                {romanNumbers[k]}
                                        </span>
                                        {
                                            v.info ?
                                            <span>
                                                {t(v.info)}
                                            </span>
                                            : null
                                        }
                                    </div>
                                    <div className="fw-bold mt-2 d-flex justify-content-center">{t(v.imgTitle)}</div>
                                    <div className={`d-flex w-100 justify-content-center align-items-center`} style={{height: k===0 ? '65%' : ''}}>
                                        <img src={v.img} width={(k===0 || k===5) ? '30%' : '100%'}/>
                                    </div>
                                    {
                                        v.description.length > 0 ?
                                        <ul className="mt-2">
                                            {
                                                v.description.map((des,desIndex)=> {
                                                    return(
                                                        <li key={desIndex}>
                                                            {t(des)}
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                        : null
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        )
    })
)

export default SetUpGuide;

