import { Colors, invertDark } from "../../Assets/Config/color.config";
import { inject, observer } from "mobx-react";
import { Selector, MySwitch } from "../../Tools";
import { toJS } from "mobx";
import { useEffect, useState } from "react";
import { UnitModal } from "./UnitModal";
import { useTranslation } from 'react-i18next';
import { ManualEntryDetailForm } from "./ManualEntryDetailForm";
import { ManualEntryNormalForm } from "./ManualEntryNormalForm";

const ManualDataInput = inject("store")(
  observer((props) => {
    const { theme, viewStatus } = props.store.common;
    const { t } = useTranslation("common");
    const [status, setStatus] = useState(null);
    const {
      yearOptions,
      selectedYear,
      selectedField,
      _handleSelectYear,
      entryFields,
      manualEntryData,
      isNewVal,
      _handleManualInputChange,
      getManualEntryDataByYear,
      _handleAddManualEntry,
      _handleSelectField,
      _handleResetManualEntry,
      tabOption,
      selectedCarbonTab,
      _handleClickCarbonTab,
      unitOptions,
      _handleSelectUnit,
      selectedUnit,
      buildingId,
      setSelectedUnit,
      _handleAlertForOtherAction,
      manualEditMode, _handleManualEditMode,
      surveyFramework,
      frameWorkIndex,
      desFileId,
      _handleGetBaseLineYear
    } = props.store.survey;
    const {
      _fetchTargetSetting,
      settings
    } = props.store.target;

    const {
      getSiteList,      
      siteList,
      desAndFileId,
    } = props.store.siteRelevant;
    
    const frameworkId = surveyFramework?.[frameWorkIndex]?.framework_id;

    const uploadedFiles = [];
    if (toJS(siteList).length > 0) {
      toJS(siteList).forEach((element, index) => {        
        uploadedFiles.push({
          label: element.fileName,     
          value: element._id
        })
      });
    }  

    useEffect(() => {
      getManualEntryDataByYear();
      _fetchTargetSetting(buildingId);
      getSiteList(buildingId, frameworkId);
    }, []);

    useEffect(() => {
      getManualEntryDataByYear();
      getSiteList(buildingId, frameworkId);
    }, [desFileId, desAndFileId]);

    useEffect(() => {
      if (settings){
        _handleGetBaseLineYear(settings);
        setSelectedUnit(settings);

      } 
    }, [settings, selectedField, selectedCarbonTab])

    // const _handleShowAlert = () => {
    //   setStatus("You can't select without save progress.");
    //   setTimeout(function () {
    //     setStatus(null);
    //   }, 4000);
    // };

    const defaultStyle = {
      width: 90,
      height: 38,
      background: Colors[theme].active,
      lineHeight: 2.3,
      cursor: "pointer",
      transition: "0.1s",
      border: `1px solid ${Colors[theme].borderColor}`,
      borderRadius: 7,
      color: invertDark(theme),
      boxShadow: "rgb(0 0 0 / 10%) 2px 3px 10px"
    };
    const selectedStyle = {
      ...defaultStyle,
      width: 120,
      height: 45,
      background: theme === "dark" ? Colors.lightBlue : Colors.white,
      lineHeight: 2.8,
      fontWeight: "bold",
      boxShadow: Colors.shadow,
    };
    
    return (
      <div
        className="p-2"
        style={{
          background:
            theme === "dark" ? Colors[theme].secondBackground : Colors.Dark[5],
          borderRadius: 10,
          boxShadow: "2px 4px 11px rgba(0,0,0,0.25)",
        }}
      >
        <div className="row d-flex flex-wrap justify-content-between align-items-center">
          <div
            className="fw-bold col ms-2"
            style={{
              fontSize: 20,
              color: invertDark(theme),
            }}
          >
            {
              //temporarily closed @seine

              // isManualEditMode ?
              // `Editing 
              // ${selectedField === 'carbonEmission' 
              // ? tabOption?.find((v) => v.value === selectedCarbonTab)?.label  
              // :entryFields.find((v) => v.key === selectedField)?.label} 
              // for ${selectedYear}`
              // :
              t("Manual")
            }

            <i className="bi bi-gear text-secondary ms-2"></i>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6  col-sm-12 d-flex justify-content-end me-2">
            <div
              className="mx-1 mt-2"
              onClick={() => isNewVal && _handleAlertForOtherAction()}
              style={{ minWidth: 80 }}
            >
              <Selector
                id={"yearSelector"}
                disabled={isNewVal}
                options={yearOptions}
                menuListColor={Colors.Dark[0]}
                value={yearOptions.find((v) => v.label === selectedYear)}
                _handleSelect={(e) => _handleSelectYear(e.label)}
              />
            </div>

            {/* temporarily closed @seine */}
            {/* {
              isManualEditMode ?
                <div
                  className="mx-1 mt-2"
                  onClick={() => isNewVal && _handleShowAlert()}
                  style={{ width: 90 }}
                  id={'unit_selector'}
                  data-bs-toggle="" data-bs-target=""
                >
                  <Selector
                    id={"unitSelector"}
                    disabled={isNewVal}
                    menuListColor={Colors.Dark[0]}
                    options={unitOptions.filter(v => selectedField === 'carbonEmission' ? (v.dataSource === selectedCarbonTab) : (v.dataSource === selectedField))}
                    value={selectedUnit ? (unitOptions.filter(v => selectedField === 'carbonEmission' ? (v.dataSource === selectedCarbonTab) : (v.dataSource === selectedField)))?.find((v) => v.value === selectedUnit) : null}
                    _handleSelect={(e) => _handleSelectUnit(e.value)}
                  />
                </div>
                :
                <div
                  className="mx-1 mt-2"
                  onClick={() => isNewVal && _handleShowAlert()}
                >
                  <Selector
                    id={"fieldSelector"}
                    disabled={isNewVal}
                    menuListColor={Colors.Dark[0]}
                    options={entryFields.filter((v) => v.label !== "Month")}
                    value={entryFields.find((v) => v.key === selectedField)}
                    _handleSelect={(e) => _handleSelectField(e.key)}
                  />
                </div>
            } */}

            <div
              className="mx-1 mt-2"
              onClick={() => isNewVal && _handleAlertForOtherAction()}
            >
              <Selector
                id={"fieldSelector"}
                disabled={isNewVal}
                menuListColor={Colors.Dark[0]}
                options={entryFields.filter((v) => v.label !== "Month")}
                value={entryFields.find((v) => v.key === selectedField)}
                _handleSelect={(e) => _handleSelectField(e.key)}
              />
            </div>
            <div
              className="mx-1 mt-2"
              onClick={() => isNewVal && _handleAlertForOtherAction()}
              style={{ width: 90 }}
              id={'unit_selector'}
              data-bs-toggle="" data-bs-target=""
            >
              <Selector
                id={"unitSelector"}
                disabled={isNewVal}
                menuListColor={Colors.Dark[0]}
                options={unitOptions.filter(v => selectedField === 'carbonEmission' ? (v.dataSource === selectedCarbonTab) : (v.dataSource === selectedField))}
                value={selectedUnit ? (unitOptions.filter(v => selectedField === 'carbonEmission' ? (v.dataSource === selectedCarbonTab) : (v.dataSource === selectedField)))?.find((v) => v.value === selectedUnit) : null}
                _handleSelect={(e) => _handleSelectUnit(e.value)}
              />
            </div>

          </div>
        </div>
        
        <div className="d-flex row flex-wrap justify-content-between align-items-center">
          <div className="my-2 col d-flex">
            <span className="mx-2" style={{ color: Colors[theme]?.textColor }}>{t("Edit Mode")}: </span>
            <MySwitch 
              title={manualEditMode ? "Disable to edit": "To make data entry, enable to edit"} 
              active={manualEditMode} 
              onChange={_handleManualEditMode} 
            />
          </div>
          <div className="col-lg-5 col-md-5 col-sm-12" style={{ height: 30 }}>
            {status ? (
              <div
                style={{ fontSize: 14, width: 200 }}
                className="left-slider w-100"
              >
                <span style={{ color: "#FF3400" }}>
                  <i className="bi bi-exclamation-circle"></i> 
                  {status}
                </span>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        {(selectedField === "carbonEmission" /* (temporarily closed @seine) && !isManualEditMode */) && <div className="d-flex flex-row flex-wrap text-center"
          style={{ height: 65, color: Colors[theme].textColor }}
        >
          {tabOption.map((v, k) =>
            <span
              key={k}
              id={k}
              className="align-self-center m-2 rouded"
              onClick={() => _handleClickCarbonTab(v.value)}
              style={v.value === selectedCarbonTab ? selectedStyle : defaultStyle}
            >
              {v.label}
            </span>
          )}
        </div>}

        {/* temporarily closed @seine */}
        {/* {
          isManualEditMode ? */}
          {viewStatus === "normalView"? 
          <ManualEntryNormalForm
              theme={theme}
              selectedCarbonTab={selectedCarbonTab}
              isNewVal={isNewVal}
              manualEntryData={manualEntryData}
              selectedField={selectedField}
              _handleManualInputChange={_handleManualInputChange}
              _handleAddManualEntry={_handleAddManualEntry}
              _handleResetManualEntry={_handleResetManualEntry}
              manualEditMode={manualEditMode}
              selectedUnit={selectedUnit}
            /> :
            <ManualEntryDetailForm
              theme={theme}
              selectedCarbonTab={selectedCarbonTab}
              isNewVal={isNewVal}
              manualEntryData={manualEntryData}
              selectedYear={selectedYear}
              selectedField={selectedField}
              _handleManualInputChange={_handleManualInputChange}
              _handleAddManualEntry={_handleAddManualEntry}
              _handleResetManualEntry={_handleResetManualEntry}
              manualEditMode={manualEditMode}
              uploadedFiles={uploadedFiles}
              frameworkId={frameworkId}
              buildingId={buildingId}
              selectedUnit={selectedUnit}
            />
        }
        <UnitModal />
      </div>
    );
  })
);

export default ManualDataInput;