import { inject, observer } from "mobx-react";
import { useEffect } from "react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { MyButton, MyInput, Selector, MyDatepicker } from "../../Tools";

export const SyscomEquipmentModal = inject("store")(
    observer((props) => {
        const { theme, companyName } = props.store.common;
        const {
            _handleCreateEquipmentSetup,
            createEquipmentList,
            _handleChangeInput,
            _handleSelectEquip,
            _handleChangeDate,
        } = props.store.survey;

        return (
            <div
                className={`modal fade ${theme}`}
                id="addEquipmentModal"
                tabIndex="-1"
                aria-labelledby="addEquipmentModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-xl">
                    <div
                        className="modal-content"
                        style={{
                            background: Colors[theme].background,
                            borderRadius: 10,
                            color: invertDark(theme)
                        }}
                    >
                        <div className="modal-header">
                            <h4>Add Data for Scope 1</h4>
                            <i
                                style={{ cursor: "pointer" }}
                                className="bi bi-x-lg "
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></i>
                        </div>

                        <div className="modal-body">
                            <table style={{ color: invertDark(theme) }} className="table">
                                <thead><tr><th>Name</th><th>Equipment Type</th><th>Fuel Type</th><th>Brand</th><th>Unit</th><th>Commissioned Date</th></tr></thead>
                                <tbody>
                                    {syscomData.map(v => (<tr>
                                        <td>{v.name}</td>
                                        <td><Selector
                                            // label={"Equipment Type"}
                                            menuListColor={Colors.Dark[0]}
                                            options={deviceTypeOption}
                                            value={
                                                deviceTypeOption.filter(
                                                    (v) => v.label === createEquipmentList.deviceType
                                                ) || ""
                                            }
                                            placeholder={"Select device type"}
                                            _handleSelect={(e) =>
                                                _handleSelectEquip("deviceType", e, "equipment")
                                            }
                                        /></td>
                                        <td><Selector
                                            // label={"Fuel Type"}
                                            menuListColor={Colors.Dark[0]}
                                            options={fuelTypeOption.filter(v => v.category === createEquipmentList.category)}
                                            value={
                                                fuelTypeOption.filter(
                                                    (v) => v.label === createEquipmentList.fuelType
                                                ) || ""
                                            }
                                            // placeholder={"Select fuel type"}
                                            _handleSelect={(e) =>
                                                _handleSelectEquip("fuelType", e, "equipment")
                                            }
                                        /></td>
                                        <td><MyInput
                                            // label={"Brand"}
                                            required={true}
                                            id={"brand"}
                                            type={"text"}
                                            value={createEquipmentList.brand}
                                            onChange={(e) => _handleChangeInput(e, "equipment")}
                                            placeHolder={"Brand"}
                                        /></td>
                                        <td><Selector
                                            // label={"Unit"}
                                            menuListColor={Colors.Dark[0]}
                                            options={unitOption.filter(v => v.activityType === createEquipmentList.activityType)}
                                            value={
                                                unitOption.filter(
                                                    (v) => v.label === createEquipmentList.unit
                                                ) || ""
                                            }
                                            // placeholder={"Select Unit"}
                                            _handleSelect={(e) => _handleSelectEquip("unit", e, "equipment")}
                                        /></td>
                                        <td><MyDatepicker
                                            // label={"Commissioned Date"}
                                            _handleChange={(e) => _handleChangeDate(e, "equipment")}
                                            value={createEquipmentList.commissionedDate}
                                        /></td>
                                    </tr>))}
                                </tbody>
                            </table>
                        </div>

                        <div className="modal-footer mx-3 d-flex justify-content-end">
                            <span data-bs-dismiss="modal" aria-label="Close">
                                <MyButton
                                    style={{ width: 200 }}
                                    text={"Cancel"}
                                    customColor={theme === 'dark' ? Colors.primaryBule : '#888'}
                                    customHoverColor={theme === 'dark' ? Colors.lightBlue : '#aaa'}
                                />
                            </span>
                            <span id={'create_setUp'}>
                                <MyButton
                                    onClick={() => _handleCreateEquipmentSetup("equipment")}
                                    style={{ width: 200 }}
                                    text={"Add"}
                                    customColor="#066CFF"
                                    customHoverColor={Colors.water}
                                />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    })
);

const deviceTypeOption = [
    { value: 1, label: "Generator", category: "Stationary Combustion" },
    { value: 2, label: "Gasoline Passenger Cars", category: "Mobile Combustion" },
    { value: 3, label: "Gasoline Light-duty Trucks (Vans, Pickup Trucks, SUVs)", category: "Mobile Combustion" },
    { value: 4, label: "Gasoline Heavy-duty Vehicles", category: "Mobile Combustion" },
    { value: 5, label: "Hybrid (Gasoline) Passenger Cars", category: "Mobile Combustion" },
    { value: 6, label: "Gasoline Agricultural Equipment", category: "Mobile Combustion" },
    { value: 7, label: "Gasoline Ships and Boats", category: "Mobile Combustion" },
    { value: 8, label: "Gasoline Motorcycles", category: "Mobile Combustion" },
    { value: 9, label: "Other Gasoline Non-Road Vehicles", category: "Mobile Combustion" },
    { value: 10, label: "General", category: "Mobile Combustion" },
    { value: 10, label: "Boiler", category: "Stationary Combustion" },
    { value: 10, label: "Convector", category: "Stationary Combustion" },
    { value: 10, label: "Kiln", category: "Stationary Combustion"},
    { value: 10, label: "Dryer", category: "Stationary Combustion" },
    { value: 10, label: "Backup generator", category: "Stationary Combustion" },
    { value: 10, label: "Combustion Turbine", category: "Stationary Combustion" },
    { value: 10, label: "Gas Stove", category: "Stationary Combustion" },
    { value: 10, label: "Pulverized-coal fired boiler", category: "Stationary Combustion" },
    { value: 10, label: "Incinerator", category: "Stationary Combustion" },
    { value: 10, label: "Flare Tower", category: "Stationary Combustion" },
    { value: 10, label: "Regenerative Thermal Oxidizer (RTO)", category: "Stationary Combustion" },
    { value: 10, label: "Chillers", category: "Stationary Combustion" },
    { value: 10, label: "Fire extinguisher", category: "Stationary Combustion" },
    { value: 10, label: "GCB/GIS", category: "Stationary Combustion" },
  ];

const fuelTypeOption = [
    // { value: 1, label: "Diesel" },
    // { value: 2, label: "Petrol" },
    // { value: 3, label: "CNG" },
    // { value: 4, label: "Motor Gasoline" },
    // { value: 5, label: "Example S1-Mobile" },
    { value: 1, label: "Motor Gasoline", category: "Mobile Combustion" },
    { value: 2, label: "Diesel Fuel", category: "Mobile Combustion" },
    { value: 3, label: "Biodiesel (100%)", category: "Mobile Combustion" },
    { value: 4, label: "Compressed Natural Gas", category: "Mobile Combustion" },
    { value: 5, label: "Ethanol (100%)", category: "Mobile Combustion" },
    { value: 6, label: "Jet Fuel", category: "Mobile Combustion" },
    { value: 7, label: "Aviation Gasoline", category: "Mobile Combustion" },

    { value: 8, label: "Anthracite Coal", category: "Stationary Combustion" },
    { value: 9, label: "Bituminous Coal", category: "Stationary Combustion" },
    { value: 10, label: "Sub-bituminous Coal", category: "Stationary Combustion" },
    { value: 11, label: "Lignite Coal", category: "Stationary Combustion" },
    { value: 12, label: "Mixed (Commercial Sector)", category: "Stationary Combustion" },
    { value: 13, label: "Mixed (Electric Power Sector)", category: "Stationary Combustion" },
    { value: 14, label: "Mixed (Industrial Coking)", category: "Stationary Combustion" },
    { value: 15, label: "Mixed (Industrial Sector)", category: "Stationary Combustion" },
    { value: 16, label: "Coal Coke", category: "Stationary Combustion" },
    { value: 17, label: "Municipal Solid Waste", category: "Stationary Combustion" },
    { value: 18, label: "Petroleum Coke (Solid)", category: "Stationary Combustion" },
    { value: 19, label: "Plastics", category: "Stationary Combustion" },
    { value: 20, label: "Tires", category: "Stationary Combustion" },
    { value: 21, label: "Agricultural Byproducts", category: "Stationary Combustion" },
    { value: 22, label: "Peat", category: "Stationary Combustion" },
    { value: 23, label: "Solid Byproducts", category: "Stationary Combustion" },
    { value: 24, label: "Wood and Wood Residuals", category: "Stationary Combustion" },
    { value: 25, label: "Natural Gas", category: "Stationary Combustion" },
    { value: 26, label: "Blast Furnace Gas", category: "Stationary Combustion" },
    { value: 28, label: "Coke Oven Gas", category: "Stationary Combustion" },
    { value: 29, label: "Fuel Gas", category: "Stationary Combustion" },
    { value: 30, label: "Propane Gas", category: "Stationary Combustion" },
    { value: 31, label: "Landfill Gas", category: "Stationary Combustion" },
    { value: 32, label: "Other Biomass Gases", category: "Stationary Combustion" },
    { value: 33, label: "Asphalt and Road Oil", category: "Stationary Combustion" },
    { value: 34, label: "Aviation Gasoline", category: "Stationary Combustion" },
    { value: 35, label: "Butane", category: "Stationary Combustion" },
    { value: 36, label: "Butylene", category: "Stationary Combustion" },
    { value: 37, label: "Crude Oil", category: "Stationary Combustion" },
    { value: 38, label: "Distillate Fuel Oil No. 1", category: "Stationary Combustion" },
    { value: 39, label: "Distillate Fuel Oil No. 2", category: "Stationary Combustion" },
    { value: 40, label: "Distillate Fuel Oil No. 4", category: "Stationary Combustion" },
    { value: 41, label: "Ethane", category: "Stationary Combustion" },
    { value: 42, label: "Ethylene", category: "Stationary Combustion" },
    { value: 43, label: "Heavy Gas Oils", category: "Stationary Combustion" },
    { value: 44, label: "Isobutane", category: "Stationary Combustion" },
    { value: 45, label: "Isobutylene", category: "Stationary Combustion" },
    { value: 46, label: "Kerosene", category: "Stationary Combustion" },
    { value: 47, label: "Kerosene-Type Jet Fuel", category: "Stationary Combustion" },
    { value: 48, label: "Liquefied Petroleum Gases (LPG)", category: "Stationary Combustion" },
    { value: 49, label: "Lubricants", category: "Stationary Combustion" },
    { value: 50, label: "Motor Gasoline", category: "Stationary Combustion" },
    { value: 51, label: "Naphtha (<401 deg F)", category: "Stationary Combustion" },
    { value: 52, label: "Natural Gasoline", category: "Stationary Combustion" },
    { value: 53, label: "Other Oil (>401 deg F)", category: "Stationary Combustion" },
    { value: 54, label: "Pentanes Plus", category: "Stationary Combustion" },
    { value: 55, label: "Petrochemical Feedstocks", category: "Stationary Combustion" },
    { value: 56, label: "Petroleum Coke", category: "Stationary Combustion" },
    { value: 57, label: "Propane", category: "Stationary Combustion" },
    { value: 58, label: "Propylene", category: "Stationary Combustion" },
    { value: 59, label: "Residual Fuel Oil No. 5", category: "Stationary Combustion" },
    { value: 60, label: "Residual Fuel Oil No. 6", category: "Stationary Combustion" },
    { value: 61, label: "Special Naphtha", category: "Stationary Combustion" },
    { value: 62, label: "Unfinished Oils", category: "Stationary Combustion" },
    { value: 63, label: "Used Oil", category: "Stationary Combustion" },
    { value: 64, label: "Biodiesel (100%)", category: "Stationary Combustion" },
    { value: 65, label: "Ethanol (100%)", category: "Stationary Combustion" },
    { value: 66, label: "Rendered Animal Fat", category: "Stationary Combustion" },
    { value: 67, label: "Vegetable Oil", category: "Stationary Combustion" },

    { value: 68, label: "Diesel oil", category: "Mobile Combustion" },
    { value: 69, label: "Petrol", category: "Mobile Combustion" },
];

const unitOption = [
    { value: 1, label: "L", activityType: 'all' },
    { value: 2, label: "gal (US)", activityType: 'all' },

    { value: 3, label: "gal (US)", activityType: 'Fuel Use' },
    { value: 4, label: "L", activityType: 'Fuel Use' },
    { value: 5, label: "bbl", activityType: 'Fuel Use' },
    { value: 6, label: "scf", activityType: 'Fuel Use' },
    { value: 7, label: "ccf", activityType: 'Fuel Use' },
    { value: 8, label: "m³", activityType: 'Fuel Use' },

    { value: 9, label: "nautical mile", activityType: 'Distance Activity' },
    { value: 10, label: "mile", activityType: 'Distance Activity' },
    { value: 11, label: "km", activityType: 'Distance Activity' },

    { value: 12, label: "gal (US)", activityType: 'Custom emission factor' },
    { value: 13, label: "L", activityType: 'Custom emission factor' },
    { value: 14, label: "bbl", activityType: 'Custom emission factor' },
    { value: 15, label: "scf", activityType: 'Custom emission factor' },
    { value: 16, label: "ccf", activityType: 'Custom emission factor' },
    { value: 17, label: "m³", activityType: 'Custom emission factor' },
];



const syscomData = [
    { name: 'Boiler', equipmentType: 'Generator' },
    { name: 'Boiler', equipmentType: 'Generator' },
    { name: 'Boiler', equipmentType: 'Generator' }
]