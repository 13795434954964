import { inject, observer } from "mobx-react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { useState, useEffect } from "react";
import { toJS } from "mobx";
import { useTranslation } from "react-i18next";
import { AuditGroupMenu } from "../../Components/AuditGroup/AuditGroupMenu";
import { AuditGroupTab } from "../../Components/AuditGroup/AuditGroupTabs";
import { Selector } from "../../Tools";
import { GroupListDetailTable } from "../../Components/AuditGroup/GroupListDetailTable";

const AuditGroupVerification = inject("store")(
  observer((props) => {
    const { theme, yearOptions } = props.store.common;
    const { selectedSourceTab, getGroupDetailList, getBaseLineYear, selectedDetailYear, _handleDetailYearSelect, carbonDetailList, sourceTabsOptions, groupDetailTableLoading } = props.store.audit;
    const params = new URLSearchParams(window.location.search);
    const groupName = params.get("groupName");
    const groupId = params.get("groupId");
    const companyId = params.get("companyId");
    const { t } = useTranslation("audit");
    const t1 = useTranslation("one_time");

    const statusActions = [
      { label: 'Verified', key: 'verified', color: Colors.greenMaterial },
      { label: 'Rejected', key: 'reject', color: Colors.low },
      { label: 'Not Applicable', key: 'notApplicable', color: invertDark(theme) },
      { label: 'Not Verified', key: 'notVerified', color: '#FAD200' },
    ];

    useEffect(() => {
      getBaseLineYear(companyId);
    }, []);

    useEffect(() => {
      getGroupDetailList(groupId);
    }, [selectedDetailYear, groupId]);

    const currentCategory = sourceTabsOptions[selectedSourceTab]?.label
    const currentCategoryData = carbonDetailList?.filter(v => v.category === currentCategory)
    return (
      <div className="mx-4 my-2">
        <div
          className="d-flex justify-content-between p-2"
          style={{ color: Colors[theme].textColor }}
        >
          <div className="h5 my-auto" style={{ fontWeight: "bold" }}>
            {groupName}
          </div>
          <div className="my-auto text-secondary">
            <AuditGroupMenu />
          </div>
          <div className="my-auto text-secondary">
            <div style={{ width: 100 }}>
              <Selector
                placeholder={t("Year")}
                menuListColor={Colors.Dark[0]}
                options={yearOptions}
                value={yearOptions?.filter(
                  (f) => f.value === selectedDetailYear
                )}
                _handleSelect={(e) => _handleDetailYearSelect(e.value)}
              />
            </div>
          </div>
        </div>
        <div>
          <AuditGroupTab />
        </div>
        <div className="d-flex justify-content-between col-6 my-4 fw-bold" style={{ maxWidth: 450, fontSize: 14 }}>
          {
            currentCategoryData.length > 0 ?
              (statusActions?.map((action, actionIndex) => {
                return (
                  <div
                    key={actionIndex}
                    style={{
                      color: action.color
                    }}
                  >
                    {t(action.label)}
                    {" : "}
                    {currentCategoryData?.reduce((r, c) => { return r + (c.actionStatus === action.label ? 1 : 0) }, 0)}
                  </div>
                )
              }))
              : null
          }
        </div>
        {
          groupDetailTableLoading ?
            <div className="d-flex justify-content-center">
              <i className="spinner-border mt-2" />
            </div>
            :
            (
              currentCategoryData.length > 0 ?
                <GroupListDetailTable data={currentCategoryData?.map(v => toJS(v)) || []} statusActions={statusActions?.filter(v => v.key !== 'notVerified')} />
                :
                <div className="text-center text-muted"><h5><i className="bi bi-file-earmark-fill pe-1" />{t1.t("No Data")}</h5></div>
            )
        }
      </div>
    );
  })
);

export default AuditGroupVerification;
