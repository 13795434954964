import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import { Navigate, useNavigate } from "react-router-dom";
import { Colors } from "../../Assets/Config/color.config";
import { MyButton } from "../../Tools";
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify'

export const Navigator = inject("store")(
  observer((props) => {
    const {
      sectionIndex,
      subSectionIndex,
      subSectionArray,
      _handleNextClick,
      _handlePreviousClick,
      isOpenAllQuestions,
      surveyFramework,
      frameWorkIndex,
      surveyInfo,
      _handleAnswerSubmit,
      _handleSectionClick,
      answerObj,
    } = props.store.survey;

    const { theme } = props.store.common;

    const surveyHeaderId = surveyFramework?.[frameWorkIndex].id;
    const { surveyTitle } = surveyInfo;
    const { t } = useTranslation("survey");
    const params = new URLSearchParams(window.location.search);
    //const surveyHeaderId = params.get("survey");
    const buildingId = params.get("site");

    const isNextSection = (subSectionIndex + 1 === subSectionArray.length && sectionIndex + 1 === toJS(surveyInfo).surveySection?.length) ? 'yes' : 'no';

    return surveyTitle === "WWF" && !isOpenAllQuestions ? null : (
      <div className="d-flex flex-row justify-content-end pt-1">
        {subSectionIndex > 0 && (
          <MyButton
            text={t("Previous")}
            customColor={theme === 'dark' ? Colors.primaryBule : '#888'}
            customHoverColor={theme === 'dark' ? Colors.lightBlue : '#aaa'}
            onClick={_handlePreviousClick}
            leftIcon={<i className="bi bi-caret-left-fill" />}
          />
        )}

        {subSectionIndex + 1 === subSectionArray.length ? (
          <div className="ms-2">

            {
              surveyFramework?.[frameWorkIndex].framework_id == "62ce4ce0a3e1ea86f7c1cef3" ?

                <>
                  {
                    isNextSection && sectionIndex < toJS(surveyInfo).surveySection?.length - 1 ? <MyButton
                      id={"SubmitAnswer"}
                      text={(isNextSection && sectionIndex < toJS(surveyInfo).surveySection?.length - 1) ? t("Next Section") : t("Submit")}
                      customColor={Colors.greenMaterial}
                      onClick={() => {
                        (isNextSection && sectionIndex < toJS(surveyInfo).surveySection?.length - 1) ?
                          _handleSectionClick(sectionIndex + 1)
                          : _handleAnswerSubmit(surveyFramework?.[frameWorkIndex].framework_id, buildingId, isNextSection);
                      }}
                      disabled={
                        (isNextSection && sectionIndex < toJS(surveyInfo).surveySection?.length - 1) ?
                          false : (
                            answerObj.childSection
                              ? !answerObj.childSection
                              : answerObj.answer == null)
                      }
                      rightIcon={
                        (isNextSection && sectionIndex < toJS(surveyInfo).surveySection?.length - 1) ?
                          <i className="bi bi-caret-right-fill" />
                          :
                          <i className="bi bi-send-fill" />
                      }
                    /> : <MyButton text={t("Finish")} customColor={Colors.greenMaterial}
                    onClick={() => { toast.success(t("Updated Successfully!")) }} rightIcon={<i className="bi bi-send-fill" />}></MyButton>  
                  }
                  </> : <MyButton
                  id={"SubmitAnswer"}
                  text={(isNextSection && sectionIndex < toJS(surveyInfo).surveySection?.length - 1) ? t("Next Section") : t("Submit")}
                  customColor={Colors.greenMaterial}
                  onClick={() => {
                    (isNextSection && sectionIndex < toJS(surveyInfo).surveySection?.length - 1) ?
                      _handleSectionClick(sectionIndex + 1)
                      : _handleAnswerSubmit(surveyFramework?.[frameWorkIndex].framework_id, buildingId, isNextSection);
                  }}
                  disabled={
                    (isNextSection && sectionIndex < toJS(surveyInfo).surveySection?.length - 1) ?
                      false : (
                        answerObj.childSection
                          ? !answerObj.childSection
                          : answerObj.answer == null)
                  }
                  rightIcon={
                    (isNextSection && sectionIndex < toJS(surveyInfo).surveySection?.length - 1) ?
                      <i className="bi bi-caret-right-fill" />
                      :
                      <i className="bi bi-send-fill" />
                  }
                />
            }
          </div>
        ) : (
          <div className="ms-2">
            <MyButton
              text={t("Next")}
              onClick={_handleNextClick}
              customColor={theme === 'dark' ? Colors.primaryBule : Colors.blue}
              rightIcon={<i className="bi bi-caret-right-fill" />}
            />
          </div>
        )}
      </div>
    );
  })
);
