import * as React from "react";
import Box from "@mui/material/Box";
import { Colors, invertDark } from "../Assets/Config/color.config";
import "../App.css";
import { inject, observer } from "mobx-react";
import { NumericFormat } from "react-number-format";
import Grid from "@mui/material/Grid";

export const NumericInput = inject("store")(
  observer((props) => {
    const { theme } = props.store.common;
    const {
      style,
      readOnly,
      id,
      onChange,
      value,
    } = props;

    const defaultStyle = {
      color: readOnly ? "#808080" : invertDark(theme),     
      borderRadius: 5,
      background: theme === "dark" ? "transparent" : "#fff",
    };

    const inputStyle = {
      color: readOnly ? "#808080" : invertDark(theme),
      width: "100%",
      paddingRight: 40,
      border: `1px solid ${theme === "dark" ? Colors.Dark[2] : Colors.Dark[4]}`,
      borderRadius: 5,
      background: theme === "dark" ? "transparent" : "#fff",
    };

    const userStyle = style === undefined ? {} : style;

    return (
      <Box sx={{ flexGrow: 1 }}>
        <Grid item
          container
          spacing={0}
          style={{
            ...defaultStyle,
            ...userStyle,
            height: "40px",
            textAlign: "right",
          }}
        >
          <Grid xs={12} item>
            <NumericFormat
              allowLeadingZeros
              thousandSeparator=","
              value={value}
              id={id}
              onChange={onChange}
              style={{
                ...inputStyle,
                height: "40px",
                padding: '5px',
              }}
              disabled={readOnly}
            />
          </Grid>
        </Grid>
      </Box>
    );
  })
);
