
import * as API from "./URL";

export const fetchAccessmentFramework = async (callback) => {
  return fetch(API.getAccessmentFramework, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err.toString(), null));
};

export const fetchAccessmentFrameworkByBuildingId = async (buildingId, callback) => {
  return fetch(API.getAccessmentFramework(buildingId), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err.toString(), null));
};

export const fetchCreateNewFramework = async (buildingId, data, callback) => {
  return fetch(API.createNewFramework(buildingId), {
    method: "POST",
    headers: {
      //"Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
    },
    body: (data)
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err.toString(), null));
}

export const fetchFrameWorkProgress = async (callback) => {
  return fetch(API.getFrameworkProgressAPI, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err.toString(), null));
};


export const fetchAddNewFramework = async (buildingId,frameworkData, callback) => {
  return fetch(API.addNewFramework+"?buildingId="+buildingId, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({buildingData:{frameworkData}})
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err.toString(), null));
}

export const setGHGBaseline = async (buildingId,year, data, callback) => {
  return fetch(API.setGHGBaseline+'?buildingId='+buildingId+'&year='+year, {
    method: "POST",
    headers: {
      //"Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
    },
    body: (data)
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err.toString(), null));
}

