import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { invertDark } from "../../Assets/Config/color.config";
import {
  BuildingRegistration,
  UserRegistration,
  FinalPage,
  SelectLocationModal,
  StepPagination
} from "../../Components/SpecialOneTime";
import { useTranslation } from 'react-i18next';
import Navigator from "../../Tools/MyNavigator";

const SpecialOneTimeContainer = inject("store")(
  observer((props) => {
    // const pageIndex = 1;
    const { pageIndex } = props.store.specialOneTime;
    const { theme, permittedBuilding, groupStatus } = props.store.common;
    const navigate = useNavigate();
    const { t } = useTranslation("one_time");

    return (
      <>
        <Navigator pageName={t("Site Wizard")} goTo={t('Back')} 
        // handleBack = {() => window.history.back()} 
        link={groupStatus? '/home-group' : '/home'}
        />
        <div className="d-flex justify-content-center">
          {/* <div className="col-4 d-flex justify-content-start ps-3">
            { permittedBuilding?.length > 0 && pageIndex === 0 ? (
              <span
                style={{
                  fontSize: 20,
                  color: invertDark(theme),
                  alignItems: "center",
                  display: "flex",
                  cursor: "pointer",
                }}
                onClick={() => navigate("/home")}
              >
                <i className="bi bi-arrow-left-circle-fill pe-3"></i>{ t("Back") }
              </span>
            ) : null}
          </div> */}
          <div className="col-4 d-flex justify-content-center py-4">
            <StepPagination steps={[
              'Create New Site',
              'Account Management and Creation',
              'Completed Setup',
            ]} />
          </div>
          {/* <div className="col-4"></div> */}
        </div>
        <div className="d-flex justify-content-center" >
          <div className="container">
            {pageIndex === 0 ? (
              <BuildingRegistration />
            ) : pageIndex === 1 ? (
              <UserRegistration />
            ) : (
              <FinalPage />
            )}

          </div>

        </div>

      </>
    );
  })
);

export default SpecialOneTimeContainer;
