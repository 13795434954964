import * as URL from "./URL";

export const fetchEquipmentList = async (
  buildingId,
  frameworkId,
  type,
  callback
) => {
  return fetch(URL.equipmentSetupAPI({ buildingId, frameworkId, type }), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err.toString(), null));
};

export const fetchEquipmentActive = async (
  dynamicDataId,
  type,
  active,
  buildingId,
  callback
) => {
  return fetch(URL.equipmentActiveAPI({ dynamicDataId, type, active, buildingId }), {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
    },
    // body: JSON.stringify({ type: type, active: active})
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err.toString(), null));
};

export const addEquipmentSetup = async (
  buildingId,
  frameworkId,
  type,
  data,
  callback
) => {
  return fetch(URL.equipmentSetupAPI({ buildingId, frameworkId, type }), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
    },
    body:  JSON.stringify({data}) ,
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err.toString(), null));
};

export const updateEquipmentSetup = async (
  buildingId,
  frameworkId,
  type,
  data,
  callback
) => {
  return fetch(URL.equipmentSetupAPI({ buildingId, frameworkId, type }), {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
    },
    body:  JSON.stringify({data}) ,
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err.toString(), null));
};

export const addEquipmentData = async (
  buildingId,
  frameworkId,
  type,
  data,
  callback
) => {
  return fetch(URL.equipmentDataAPI({ buildingId, frameworkId, type }), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
    },
    body:  JSON.stringify({...data}) ,
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err.toString(), null));
};
