import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableHead,
    TableSortLabel,
    TableRow,
    Paper,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { format } from "date-fns";
import "../../App.css";

import Store from "../../Store";
import SearchBar from "material-ui-search-bar";
import { toJS } from "mobx";
import { useTranslation } from 'react-i18next';


const headCell = [
    { id: "updatedBy", numeric: false, label: "Updated By" },
    { id: "type", numeric: false, label: "Type" },
    { id: "field", numeric: false, label: "Field" },
    { id: "updatedValue", numeric: false, label: "Value" },
    { id: "updatedDate", numeric: false, label: "Date" },
];

const ActivityLogTable = (props) => {
    const [rows, setRows] = useState([]);
    const [originalRows, setOriginalRows] = useState([]);
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("");
    const [page, setPage] = useState(0);
    const { t } = useTranslation("dashboard");
    const t1 = useTranslation("activityLog");
    // const [rowsPerPage, setRowsPerPage] = useState(200);

    const { activityLog, theme,getActivityLog } = props.store.common;

    useEffect(() => {
        setPage(0);
        if (activityLog?.length > 0) {
            const rows = (activityLog.map((v) =>
                createData(v.updatedBy, v.type, v.field,v.framework, v.updatedValue, v.updatedDate))
            );
            setOriginalRows(rows);
            setRows(rows);
        }
    }, [activityLog]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const requestSearch = (searchedVal) => {
        const filteredRows = originalRows.filter((row) => {
            return (
                row.updatedBy?.toLowerCase().includes(searchedVal.toLowerCase()) ||
                row.type?.toLowerCase().includes(searchedVal.toLowerCase()) ||
                row.field?.toLowerCase().includes(searchedVal.toLowerCase()) ||
                row.updatedValue?.toLowerCase().includes(searchedVal.toLowerCase()) ||
                format(new Date(row.updatedDate), "MMM dd yyyy HH mm ss")
                    ?.toLowerCase()
                    .includes(searchedVal.toLowerCase())
            );
        });
        setRows(filteredRows);
    };

    const cancelSearch = () => {
        setRows(originalRows);
    };

    // const handleChangeRowsPerPage = (e) => {
    //     setRowsPerPage(e.target.value);
    // };

    const StyledTableRow = withStyles((theme) => ({
        root: {
            maxHeight: "50px",
            borderRadius: "10px",
            margin: "20px",
            "&:hover": {
                backgroundColor: `${Colors.Dark[5]} !important`,
                color:`${Colors.Dark[1]} !important`
            },
        },
    }))(TableRow);

    function createData(
        updatedBy,
        type,
        field,
        framework,
        updatedValue,
        updatedDate
    ) {
        return {
            updatedBy,
            type,
            field,
            updatedValue,
            updatedDate,
            framework
        };
    }

    const useStyles = makeStyles({
        table: {
            //minWidth: 800,
            width: "100%",
            borderSpacing: "0px 3px",
            // minHeight: 328
        },
    });

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const descendingComparator = (a, b, orderBy) => {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    };

    const getComparator = (order, orderBy) => {
        return order === "desc"
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    };

    const stableSort = (array, comparator) => {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    };

    SortableTHead.propTypes = {
        onRequestSort: PropTypes.func.isRequired,
        order: PropTypes.oneOf(["asc", "desc"]).isRequired,
        orderBy: PropTypes.string.isRequired,
    };

    const classes = useStyles();

    return (
        <>
            <div className={`d-flex justify-content-between mb-2 ${theme}`}>
                <div className="pt-1"><span className="fw-bold">{ t("Total Log") } </span>{' : ' + activityLog.length}</div>
                <SearchBar
                    placeholder={ t("Search_") }
                    style={{
                        width: "30%",
                        height: 35,
                        background: "transparent",
                        boxShadow: "none",
                        borderBottom: `1px solid ${invertDark(theme)}`,
                        borderRadius: 0,
                        // color: "white",
                    }}
                    // value={searched}
                    onChange={(searchVal) => requestSearch(searchVal)}
                    onCancelSearch={() => cancelSearch()}
                />
            </div>
            <TableContainer
                component={Paper}
                id="tblContainer"
                style={{ background: "transparent", boxShadow: "none", maxHeight: '70vh' }}
            >
                <Table
                    className={classes.table}
                    stickyHeader
                    aria-label="customized table"
                >
                    <SortableTHead
                        theme={theme}
                        id={"id"}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        headCells={headCell}
                    />
                    <TableBody>
                        {stableSort(rows, getComparator(order, orderBy))
                            // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            // rows
                            .map((v, k) => {
                                const style = { color: invertDark(theme), textAlign: 'left', fontSize: 14, paddingLeft: '12px',borderBottom:`1px solid ${Colors.Dark[4]}` }
                                return (
                                    <StyledTableRow
                                        hover
                                        key={k}
                                        style={{ cursor: "pointer" }}
                                    >
                                        <td style={{ ...style, cursor: "pointer" }}>
                                            {v.updatedBy}
                                        </td>
                                        <td style={{ ...style }}>{t1.t(v.type)}</td>
                                        <td style={{ ...style }}>{v.framework? " " + t1.t(v.framework) + " " + t1.t(v.field):t1.t(v.field)}</td>
                                        <td style={{ ...style }}>{v.updatedValue}</td>
                                        <td style={{ ...style }}>
                                            {v.updatedDate
                                                ? format(new Date(v.updatedDate), "MMM-dd-yyyy HH:mm:ss")
                                                : null}
                                        </td>

                                    </StyledTableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            {/* <TablePagination
                className="light"
                style={{ color: "#fff" }}
                rowsPerPageOptions={[]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
        </>
    );
};

export default inject("store")(observer(ActivityLogTable));

const SortableTHead = (props) => {
    const { order, orderBy, onRequestSort, headCells, theme } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    const { t } = useTranslation("dashboard");

    return (
        <TableHead>
            <TableRow style={{ position: 'sticky', top: 0 }}>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={{
                            backgroundColor:theme==="dark"?Colors.lightBlue: Colors.water,
                            color: Colors.Dark[5],
                            fontWeight: "bold",
                            padding: "8px 13px 8px 13px",
                            textAlign: "start",
                            position: "static",
                        }}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        // style={{
                        //     color: "#fff",
                        // }}
                        >
                            { t(headCell.label) }
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};
