import { invertDark,Colors } from "../Assets/Config/color.config";
import { useTranslation } from 'react-i18next';

export const MyFileInput = (props) => {
    const { onChange, file, fontSize, fileType, theme, text } = props;
    const { t } = useTranslation("common");

    return (
        <div className="d-flex flex-column">
            <div
                className="row w-100 p-0 m-0 btn text-center text-light"
                style={{
                    backgroundColor: Colors[theme].secondBackground,
                    width: "130px",
                    minWidth: 130,
                    borderRadius: 5,
                    fontSize: fontSize || 14
                }}
            >
                <label
                    className="py-1 d-flex align-items-center justify-content-center"
                    htmlFor={'id'}
                    style={{ cursor: "pointer", border: `1px solid ${invertDark(theme)}` , opacity:0.6, borderRadius:5, height:"43px", color:invertDark(theme) }}
                >
                    <input
                        style={{
                            position: "absolute",
                            width: "130px",
                            display: "none",
                            margin: 12,
                            top: 10,
                            cursor: 'pointer'
                        }}
                        type="file"
                        id={'id'}
                        onChange={onChange}
                        accept={fileType || ".xlsx,.pdf, image/*, .txt"}
                    />
                    <span style={{  }}>
                    { t("Choose a file") + text}
                    </span>
                </label>
            </div>
            <div className="row">
                <div className="d-flex flex-column mt-1">
                    {file? 
                    <span 
                    className="py-auto px-1"
                    style={{fontSize:14, textOverflow:'ellipsis', whiteSpace:'nowrap', overflow:'hidden'}}
                    >
                        {file.name}
                    </span>
                    : <span className="my-auto px-1" style={{opacity:0.6, fontSize:14}}>{ t("No File Chosen") }</span>
                }
                </div>
            
            </div>
           
        </div>
    )
}