import * as React from "react";
import { useEffect } from "react";
import { inject, observer } from "mobx-react";
import { toJS } from "mobx";
import { Colors } from "../../Assets/Config/color.config";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { MyDatepicker, MyInput, MuiSelectorWithDefaultValue, MuiNonLabelSelector } from "../../Tools";
import "../../Style/QuickOrganizationSetUp.style.css";
import { useTranslation } from "react-i18next";
import { TableBody } from "@mui/material";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child th, td": {
    border: 0,
  },
}));

export const EquipmentListDetails = inject("store")(
  observer((props) => {
    const { t } = useTranslation("assetSetupAndGroup");
    const t1 = useTranslation("equipment");
    const t2 = useTranslation("transport");
    const t3 = useTranslation("country");

    const { theme, siteModuleData, _handleGetYearOptions, minBaselineYear } = props.store.common;
    const {
      _handleSetEquipmentListDetails,
      equipmentListDetail,
      _handleEquipTextChange,
      validationErr,
      validDetail,
      activityTypeOptions,
      yearOptions,
      vehicleTypeOptions,
      emissionFactorOptions,
      deviceTypeOptions,
      unitOptions,
      wasteTreatmentOptions,
      energySourceOptions
    } = props.store.quickOrganizationSetUp;

    const { tableColumns } = props;

    useEffect(() => {
      _handleSetEquipmentListDetails();
    }, []);

    useEffect(()=> {

      if (!siteModuleData) {
        return
      }
      const baselineYear = siteModuleData.baselineYear ?? minBaselineYear
      _handleGetYearOptions(baselineYear)
    }, [_handleGetYearOptions, minBaselineYear, siteModuleData])

    const columnArray = tableColumns;

    return (
      <>     
        {equipmentListDetail?.map((dataByCategory, catIndex) => (
          <div key={dataByCategory.category}>
            {dataByCategory.companyAsset.map((equipmentData, eIndex) => {
              return (
                <div key={catIndex + "-" + eIndex}>
                  <br />
                  <label
                    className={
                      theme === "dark"
                        ? "detail-table-header-dark"
                        : "detail-table-header-white"
                    }
                  >
                    {t(equipmentData.title.split(" - ")[0])} -{" "}
                    {equipmentData.title.split(" - ")[0] === "Upstream T&D"
                      ? t2.t(
                          equipmentData.title.split(" - ").slice(1).join(" - ")
                        )
                      : equipmentData.assets[0].type === "Location Based"
                      ? t3.t(
                          equipmentData.title.split(" - ").slice(1).join(" - ")
                        )
                      : t1.t(
                          equipmentData.title.split(" - ").slice(1).join(" - ")
                        )}
                  </label>
                  <TableContainer
                    component={Paper}
                    sx={{ backgroundColor: "transparent" }}
                  >
                    <Table
                      sx={{ minWidth: 700 }}
                      style={{ marginBottom: 3 }}
                      aria-label="customized table"
                      className="quick-table"
                    >
                      <TableHead>
                        <StyledTableRow>
                          {columnArray
                            ?.filter(
                              (col) => col.cat === equipmentData.scopeName
                            )[0]
                            ?.dataFields?.filter((d) => d.label !== "Group")
                            .map((v, index) => {
                              return (
                                <TableCell
                                  key={index}
                                  className={
                                    theme === "dark"
                                      ? "table-header-cell-dark"
                                      : "table-header-cell-white"
                                  }
                                >
                                  {t(v.label)}
                                </TableCell>
                              );
                            })}
                        </StyledTableRow>
                      </TableHead>
                      <TableBody>
                        {equipmentData.assets.map((equipData, equipIndex) => {                        
                          return (
                            <>
                            <StyledTableRow
                              // style={{
                              //   background:
                              //     validationErr.findIndex(
                              //       (verr) =>
                              //         verr ===
                              //         equipmentData.title + "_" + equipIndex
                              //     ) > -1
                              //       ? "#E74C3C"
                              //       : null,
                              // }}
                              // className=""
                              key={dataByCategory.name + equipIndex}
                            >
                              {columnArray
                                ?.filter(
                                  (col) => col.cat === equipmentData.scopeName
                                )[0]
                                ?.dataFields?.filter((d) => d.label !== "Group")
                                .map((header, dindex) => {
                                  return (
                                    <TableCell
                                      style={{
                                        color:
                                          theme === "dark" ? "white" : "black",
                                        borderBottom:
                                          validationErr.findIndex(
                                            (verr) =>
                                              verr ===
                                              equipmentData.title +
                                                "_" +
                                                equipIndex
                                          ) > -1 &&
                                          validationErr.findIndex(
                                            (verr) =>
                                              verr ===
                                              equipmentData.title +
                                                "_" +
                                                (equipIndex + 1)
                                          ) > -1
                                            ? "1px solid white"
                                            : null,
                                      }}
                                    >                                      
                                      { header.key === "purchasedGood" ||
                                        header.key === "componentName" ||
                                        header.key === "wasteWaterUnit" ||
                                        header.key === "removalUnit" ||
                                        header.key === "removalEquipmentType" ||
                                        header.key === "model" ? (
                                        <MyInput
                                          id={
                                            header.key +
                                            "-" +
                                            eIndex +
                                            "-" +
                                            equipIndex +
                                            "_" +
                                            dindex
                                          }
                                          value={equipData[header.key]}
                                          placeHolder={t(header.label)}
                                          onChange={(e) =>
                                            _handleEquipTextChange(
                                              e.target.value,
                                              catIndex,
                                              eIndex,
                                              equipIndex,
                                              header.key,
                                              equipmentData.catValue,
                                              equipmentData.nameKey,
                                              equipmentData.sourceKey,
                                              equipmentData.sourceType,
                                              equipmentData.checkedValue
                                            )
                                          }
                                        />
                                      ) : header.key === "transportationName" ||
                                      header.key === "assetName" ||
                                      header.key === "supplierName" ||
                                      header.key === "brand" ||
                                      header.key === "model" || header.dataType === "textInput" ? (
                                        <MyInput
                                          id={
                                            header.key +
                                            "-" +
                                            eIndex +
                                            "-" +
                                            equipIndex +
                                            "_" +
                                            dindex
                                          }
                                          value={equipData[header.key]}
                                          placeHolder={t(header.label)}
                                          onChange={(e) =>
                                            _handleEquipTextChange(
                                              e.target.value,
                                              catIndex,
                                              eIndex,
                                              equipIndex,
                                              header.key,
                                              equipmentData.catValue,
                                              equipmentData.nameKey,
                                              equipmentData.sourceKey,
                                              equipmentData.sourceType,
                                              equipmentData.checkedValue
                                            )
                                          }
                                        />
                                      ) : header.key === "equipmentType" ? (
                                        <MuiSelectorWithDefaultValue
                                          style={{
                                            height: "40px !important",
                                            paddingTop: "0 !important",
                                          }}
                                          tran={t1.t}
                                          options={deviceTypeOptions
                                            .filter(
                                              (dt) =>
                                                dt.category ===
                                                equipmentData.scopeName
                                            )
                                            ?.sort((a, b) =>
                                              b.label > a.label ? -1 : 0
                                            )}
                                          value={
                                            deviceTypeOptions.find(
                                              (v) =>
                                                v.label ===
                                                equipData[header.key]
                                            )?.label || ""
                                          }
                                          handleSelect={(e) =>
                                            _handleEquipTextChange(
                                              e.target.value,
                                              catIndex,
                                              eIndex,
                                              equipIndex,
                                              header.key,
                                              equipmentData.catValue,
                                              equipmentData.nameKey,
                                              equipmentData.sourceKey,
                                              equipmentData.sourceType,
                                              equipmentData.checkedValue
                                            )
                                          }
                                        />
                                      ) : header.key === "energySource" ? (
                                        <MuiSelectorWithDefaultValue
                                          style={{
                                            height: "40px !important",
                                            paddingTop: "0 !important",
                                          }}
                                          options={energySourceOptions.filter(
                                            (dt) =>
                                              dt.category ===
                                              equipmentData.scopeName
                                          )?.sort((a, b) =>
                                              b.label > a.label ? -1 : 0
                                            )}
                                          value={
                                            energySourceOptions.find(
                                              (v) =>
                                                v.label ===
                                                equipData[header.key]
                                            )?.label || ""
                                          }
                                          handleSelect={(e) =>
                                            _handleEquipTextChange(
                                              e.target.value,
                                              catIndex,
                                              equipIndex,
                                              eIndex,
                                              header.key,
                                              equipmentData.catValue,
                                              equipmentData.nameKey,
                                              equipmentData.sourceKey,
                                              equipmentData.sourceType,
                                              equipmentData.checkedValue
                                            )
                                          }
                                        />
                                      ) : header.key === "wasteTreatmentMethod" ? (
                                        <MuiSelectorWithDefaultValue
                                          style={{
                                            height: "40px !important",
                                            paddingTop: "0 !important",
                                          }}
                                          options={wasteTreatmentOptions?.sort((a, b) =>
                                              b.label > a.label ? -1 : 0
                                            )}
                                          value={
                                            wasteTreatmentOptions.find(
                                              (v) =>
                                                v.label ===
                                                equipData[header.key]
                                            )?.label || ""
                                          }
                                          handleSelect={(e) =>
                                            _handleEquipTextChange(
                                              e.target.value,
                                              catIndex,
                                              eIndex,
                                              equipIndex,
                                              header.key,
                                              equipmentData.catValue,
                                              equipmentData.nameKey,
                                              equipmentData.sourceKey,
                                              equipmentData.sourceType,
                                              equipmentData.checkedValue
                                            )
                                          }
                                        />
                                      ) : header.key === "vehicleType" ? (
                                        dataByCategory.category ===
                                        "Mobile Combustion" ? (
                                          <MuiNonLabelSelector
                                            style={{
                                              height: "40px !important",
                                              paddingTop: "0 !important",
                                            }}
                                            tran={t1.t}
                                            options={vehicleTypeOptions
                                              .filter(
                                                (dt) =>
                                                  dt.fuelType ===
                                                  equipmentData.checkedValue
                                              )
                                              ?.sort((a, b) =>
                                                b.label > a.label ? -1 : 0
                                              )}
                                            value={
                                              vehicleTypeOptions.find(
                                                (v) =>
                                                  v.label ===
                                                  equipData[header.key]
                                              )?.label || ""
                                            }
                                            handleSelect={(e) =>
                                              _handleEquipTextChange(
                                                e.target.value,
                                                catIndex,
                                                eIndex,
                                                equipIndex,
                                                header.key,
                                                equipmentData.catValue,
                                                equipmentData.nameKey,
                                                equipmentData.sourceKey,
                                                equipmentData.sourceType,
                                                equipmentData.checkedValue
                                              )
                                            }
                                          />
                                        ) 
                                        : (
                                          <MuiSelectorWithDefaultValue
                                            style={{
                                              height: "40px !important",
                                              paddingTop: "0 !important",
                                            }}
                                            tran={t1.t}
                                            options={vehicleTypeOptions
                                              .filter(
                                                (dt) =>
                                                  dt.fuelType ===
                                                  equipmentData.checkedValue
                                              )
                                              ?.sort((a, b) =>
                                                b.label > a.label ? -1 : 0
                                              )}
                                            value={
                                              vehicleTypeOptions.find(
                                                (v) =>
                                                  v.label ===
                                                  equipData[header.key]
                                              )?.label || ""
                                            }
                                            handleSelect={(e) =>
                                              _handleEquipTextChange(
                                                e.target.value,
                                                catIndex,
                                                eIndex,
                                                equipIndex,
                                                header.key,
                                                equipmentData.catValue,
                                                equipmentData.nameKey,
                                                equipmentData.sourceKey,
                                                equipmentData.sourceType,
                                                equipmentData.checkedValue
                                              )
                                            }
                                          />
                                        )
                                      ) : header.key === "unit" ? (
                                        dataByCategory.category ===
                                          "Refrigerants and Fugitives" ||
                                        dataByCategory.category ===
                                          "Stationary Combustion" ? (
                                          equipmentData.checkedValue ===
                                          "Mixed(Electric Power Sector)" ? (
                                            <MuiSelectorWithDefaultValue
                                              options={unitOptions
                                                .filter(
                                                  (v) =>
                                                    v.activityType ===
                                                    dataByCategory.category
                                                )
                                                ?.sort((a, b) =>
                                                  b.label > a.label ? -1 : 0
                                                )}
                                              tran={t1.t}
                                              value={
                                                unitOptions.find(
                                                  (v) =>
                                                    v.label ===
                                                    equipData[header.key]
                                                )?.label || ""
                                              }
                                              handleSelect={(e) =>
                                                _handleEquipTextChange(
                                                  e.target.value,
                                                  catIndex,
                                                  eIndex,
                                                  equipIndex,
                                                  header.key,
                                                  equipmentData.catValue,
                                                  equipmentData.nameKey,
                                                  equipmentData.sourceKey,
                                                  equipmentData.sourceType,
                                                  equipmentData.checkedValue
                                                )
                                              }
                                            />
                                          ) : (
                                            <MuiSelectorWithDefaultValue
                                              options={unitOptions
                                                .filter(
                                                  (v) =>
                                                    v.activityType ===
                                                      dataByCategory.category 
                                                      &&
                                                    (v.label !== "kWh")
                                                )
                                                ?.sort((a, b) =>
                                                  b.label > a.label ? -1 : 0
                                                )}
                                              tran={t1.t}
                                              value={
                                                unitOptions.find(
                                                  (v) =>
                                                    v.label ===
                                                    equipData[header.key]
                                                )?.label || ""
                                              }
                                              handleSelect={(e) =>
                                                _handleEquipTextChange(
                                                  e.target.value,
                                                  catIndex,
                                                  eIndex,
                                                  equipIndex,
                                                  header.key,
                                                  equipmentData.catValue,
                                                  equipmentData.nameKey,
                                                  equipmentData.sourceKey,
                                                  equipmentData.sourceType,
                                                  equipmentData.checkedValue
                                                )
                                              }
                                            />
                                          )
                                        ) : dataByCategory.category ===
                                          "Mobile Combustion" ? (
                                          equipmentData.checkedValue ===
                                          "Electricity - Mobile" ? (
                                            <MuiNonLabelSelector
                                              options={unitOptions
                                                .filter(
                                                  (v) =>
                                                    v.activityType ===
                                                    equipData.activityType
                                                )
                                                ?.sort((a, b) =>
                                                  b.label > a.label ? -1 : 0
                                                )}
                                              tran={t1.t}
                                              value={
                                                unitOptions.find(
                                                  (v) =>
                                                    v.label ===
                                                    equipData[header.key]
                                                )?.label || ""
                                              }
                                              handleSelect={(e) =>
                                                _handleEquipTextChange(
                                                  e.target.value,
                                                  catIndex,
                                                  eIndex,
                                                  equipIndex,
                                                  header.key,
                                                  equipmentData.catValue,
                                                  equipmentData.nameKey,
                                                  equipmentData.sourceKey,
                                                  equipmentData.sourceType,
                                                  equipmentData.checkedValue
                                                )
                                              }
                                            />
                                          ) : (
                                            <MuiNonLabelSelector
                                              options={unitOptions
                                                .filter(
                                                  (v) =>
                                                    v.activityType ===
                                                      equipData.activityType &&
                                                    v.label !== "kWh"
                                                )
                                                ?.sort((a, b) =>
                                                  b.label > a.label ? -1 : 0
                                                )}
                                              tran={t1.t}
                                              value={
                                                unitOptions.find(
                                                  (v) =>
                                                    v.label ===
                                                    equipData[header.key]
                                                )?.label || ""
                                              }
                                              handleSelect={(e) =>
                                                _handleEquipTextChange(
                                                  e.target.value,
                                                  catIndex,
                                                  eIndex,
                                                  equipIndex,
                                                  header.key,
                                                  equipmentData.catValue,
                                                  equipmentData.nameKey,
                                                  equipmentData.sourceKey,
                                                  equipmentData.sourceType,
                                                  equipmentData.checkedValue
                                                )
                                              }
                                            />
                                          )
                                        ) : dataByCategory.category ===
                                          "Imported energy (steam, heating, cooling and compressed air)" ? (
                                          <MuiSelectorWithDefaultValue
                                            options={unitOptions
                                              .filter(
                                                (v) =>
                                                  v.activityType === "scope2"
                                              )
                                              ?.sort((a, b) =>
                                                b.label > a.label ? -1 : 0
                                              )}
                                            value={
                                              unitOptions.find(
                                                (v) =>
                                                  v.label ===
                                                  equipData[header.key]
                                              )?.label || ""
                                            }
                                            tran={t1.t}

                                            handleSelect={(e) =>
                                              _handleEquipTextChange(
                                                e.target.value,
                                                catIndex,
                                                eIndex,
                                                equipIndex,
                                                header.key,
                                                equipmentData.catValue,
                                                equipmentData.nameKey,
                                                equipmentData.sourceKey,
                                                equipmentData.sourceType,
                                                equipmentData.checkedValue
                                              )
                                            }
                                          />
                                        ) 
                                        // : dataByCategory.category ===
                                        //   "Purchased Goods" ? (
                                        //   <MuiSelectorWithDefaultValue
                                        //     options={unitOptions
                                        //       .filter(
                                        //         (v) =>
                                        //           v.activityType === dataByCategory.category
                                        //       )
                                        //       ?.sort((a, b) =>
                                        //         b.label > a.label ? -1 : 0
                                        //       )}
                                        //     value={
                                        //       unitOptions.find(
                                        //         (v) =>
                                        //           v.label ===
                                        //           equipData[header.key]
                                        //       )?.label || ""
                                        //     }
                                        //     tran={t1.t}

                                        //     handleSelect={(e) =>
                                        //       _handleEquipTextChange(
                                        //         e.target.value,
                                        //         catIndex,
                                        //         eIndex,
                                        //         equipIndex,
                                        //         header.key,
                                        //         equipmentData.catValue,
                                        //         equipmentData.nameKey,
                                        //         equipmentData.sourceKey,
                                        //         equipmentData.sourceType,
                                        //         equipmentData.checkedValue
                                        //       )
                                        //     }
                                        //   />
                                        // ) 
                                        :(
                                          // <div>Other Unit 3</div>
                                          <MuiSelectorWithDefaultValue
                                            id={equipmentData.scopeName+equipData[header.key]}
                                            options={unitOptions
                                              .filter(
                                                (v) =>
                                                  v.activityType === dataByCategory.category
                                              )
                                              ?.sort((a, b) =>
                                                b.label > a.label ? -1 : 0
                                              )}
                                            tran={t1.t}
                                            value={
                                              unitOptions.find(
                                                (v) =>
                                                  v.label ===
                                                  equipData[header.key]
                                              )?.label || ""
                                            }
                                            handleSelect={(e) =>
                                              _handleEquipTextChange(
                                                e.target.value,
                                                catIndex,
                                                eIndex,
                                                equipIndex,
                                                header.key,
                                                equipmentData.catValue,
                                                equipmentData.nameKey,
                                                equipmentData.sourceKey,
                                                equipmentData.sourceType,
                                                equipmentData.checkedValue
                                              )
                                            }
                                          />
                                        )
                                      ) : header.key === "commissionedDate" ? (
                                        <MyDatepicker
                                          background={Colors.lightBlue}
                                          value={equipData[header.key]}
                                          _handleChange={(e) =>
                                            _handleEquipTextChange(
                                              e,
                                              catIndex,
                                              eIndex,
                                              equipIndex,
                                              header.key,
                                              equipmentData.catValue,
                                              equipmentData.nameKey,
                                              equipmentData.sourceKey,
                                              equipmentData.sourceType,
                                              equipmentData.checkedValue
                                            )
                                          }
                                        />
                                      ) : header.key === "activityType" ? (
                                          dataByCategory.category == "Mobile Combustion"?
                                          <MuiNonLabelSelector
                                          style={{
                                            height: "40px !important",
                                            paddingTop: "0 !important",
                                          }}
                                          tran={t1.t}
                                          options={activityTypeOptions
                                            .filter(
                                              (dt) =>
                                                dt.category ===
                                                equipmentData.scopeName
                                            )
                                            ?.sort((a, b) =>
                                              b.label > a.label ? -1 : 0
                                            )}
                                          value={
                                            activityTypeOptions.find(
                                              (v) =>
                                                v.label ===
                                                equipData[header.key]
                                            )?.label || ""
                                          }
                                          handleSelect={(e) =>
                                            _handleEquipTextChange(
                                              e.target.value,
                                              catIndex,
                                              eIndex,
                                              equipIndex,
                                              header.key,
                                              equipmentData.catValue,
                                              equipmentData.nameKey,
                                              equipmentData.sourceKey,
                                              equipmentData.sourceType,
                                              equipmentData.checkedValue
                                            )
                                          }
                                        />:
                                        <MuiSelectorWithDefaultValue
                                          style={{
                                            height: "40px !important",
                                            paddingTop: "0 !important",
                                          }}
                                          tran={t1.t}
                                          options={activityTypeOptions
                                            .filter(
                                              (dt) =>
                                                dt.category ===
                                                equipmentData.scopeName
                                            )
                                            ?.sort((a, b) =>
                                              b.label > a.label ? -1 : 0
                                            )}
                                          value={
                                            activityTypeOptions.find(
                                              (v) =>
                                                v.label ===
                                                equipData[header.key]
                                            )?.label || ""
                                          }
                                          handleSelect={(e) =>
                                            _handleEquipTextChange(
                                              e.target.value,
                                              catIndex,
                                              eIndex,
                                              equipIndex,
                                              header.key,
                                              equipmentData.catValue,
                                              equipmentData.nameKey,
                                              equipmentData.sourceKey,
                                              equipmentData.sourceType,
                                              equipmentData.checkedValue
                                            )
                                          }
                                        />
                                      ) : header.key === "emissionFactor" ? (
                                        <MuiSelectorWithDefaultValue
                                          style={{
                                            height: "40px !important",
                                            paddingTop: "0 !important",
                                          }}
                                          tran={t1.t}
                                          options={emissionFactorOptions
                                            .filter(
                                              (dt) =>
                                                dt.category ===
                                                equipmentData.scopeName
                                            )
                                            ?.sort((a, b) =>
                                              b.label > a.label ? -1 : 0
                                            )}
                                          value={
                                            emissionFactorOptions.find(
                                              (v) =>
                                                v.label ===
                                                equipData[header.key]
                                            )?.label || ""
                                          }
                                          handleSelect={(e) =>
                                            _handleEquipTextChange(
                                              e.target.value,
                                              catIndex,
                                              eIndex,
                                              equipIndex,
                                              header.key,
                                              equipmentData.catValue,
                                              equipmentData.nameKey,
                                              equipmentData.sourceKey,
                                              equipmentData.sourceType,
                                              equipmentData.checkedValue
                                            )
                                          }
                                        />
                                      ) : header.key === "year" ? (
                                        <MuiSelectorWithDefaultValue
                                          options={yearOptions}
                                          value={
                                            yearOptions.find(
                                              (v) =>
                                                v.value ===
                                                equipData[header.key]
                                            )?.label || ""
                                          }
                                          tran={t1.t}
                                          handleSelect={(e) =>
                                            _handleEquipTextChange(
                                              e.target.value,
                                              catIndex,
                                              eIndex,
                                              equipIndex,
                                              header.key,
                                              equipmentData.catValue,
                                              equipmentData.nameKey,
                                              equipmentData.sourceKey,
                                              equipmentData.sourceType,
                                              equipmentData.checkedValue
                                            )
                                          }
                                        />
                                      ) : header.dataType === "textInput" &&
                                        header.fieldType === "number" ? (
                                        <MyInput
                                          type="number"
                                          id={
                                            header.key +
                                            "-" +
                                            eIndex +
                                            "-" +
                                            equipIndex +
                                            "_" +
                                            dindex
                                          }
                                          value={equipData[header.key]}
                                          placeHolder={t(header.label)}
                                          onChange={(e) =>
                                            _handleEquipTextChange(
                                              e.target.value,
                                              catIndex,
                                              eIndex,
                                              equipIndex,
                                              header.key,
                                              equipmentData.catValue,
                                              equipmentData.nameKey,
                                              equipmentData.sourceKey,
                                              equipmentData.sourceType,
                                              equipmentData.checkedValue
                                            )
                                          }
                                        />
                                      ) : header.key === "baselineYear" ? (
                                        <MuiSelectorWithDefaultValue
                                          options={props.store.common.yearOptions}
                                          value={
                                            props.store.common.yearOptions.find(
                                              (v) =>
                                                v.value ===
                                                equipData[header.key]
                                            )?.value || ""
                                          }
                                          handleSelect={(e) =>
                                            _handleEquipTextChange(
                                              e.target.value,
                                              catIndex,
                                              eIndex,
                                              equipIndex,
                                              header.key,
                                              equipmentData.catValue,
                                              equipmentData.nameKey,
                                              equipmentData.sourceKey,
                                              equipmentData.sourceType,
                                              equipmentData.checkedValue
                                            )
                                          }
                                        />
                                      ): null}
                                    </TableCell>
                                  );
                                })}
                            </StyledTableRow>
                            <StyledTableRow>
                              <TableCell colSpan={4}>
                              <div style={{color:"red", width: "100%"}}>{validationErr.findIndex(
                                    (verr) =>
                                      verr ===
                                      equipmentData.title + "_" + equipIndex
                                  ) > -1
                                    ? "Please fill ": ""}
                                  {validationErr.findIndex(
                                    (verr) =>
                                      verr ===
                                      equipmentData.title + "_" + equipIndex
                                  ) > -1
                                    ? validDetail.map(a=> 
                                      {
                                        if(a.baselineYear === equipmentData.title + "_" + equipIndex){
                                          return t("Baseline Year") + ", ";
                                        }

                                        if(a.unit === equipmentData.title + "_" + equipIndex){
                                          return t("Unit") + ", ";
                                        }

                                        if(a.assetName === equipmentData.title + "_" + equipIndex){
                                          return t("Asset Name") + ", ";
                                        }

                                        if(a.equipmentType === equipmentData.title + "_" + equipIndex){
                                          return t("Equipment Type") + ", ";
                                        }

                                        if(a.brand === equipmentData.title + "_" + equipIndex){
                                          return t("Brand") + ", ";
                                        }

                                        if(a.activityType === equipmentData.title + "_" + equipIndex){
                                          return t("Activity Type") + ", ";
                                        }

                                        if(a.vehicleType === equipmentData.title + "_" + equipIndex){
                                          return t("Vehicle Type") + ", ";
                                        }

                                        if(a.model === equipmentData.title + "_" + equipIndex){
                                          return t("Model") + ", ";
                                        }

                                        if(a.supplierName === equipmentData.title + "_" + equipIndex){
                                          return t("Supplier Name") + ", ";
                                        }

                                        if(a.emissionFactor === equipmentData.title + "_" + equipIndex){
                                          return t("Emission Factor") + ", ";
                                        }

                                        if(a.transportationName === equipmentData.title + "_" + equipIndex){
                                          return t("Transportation Name") + ", ";
                                        }

                                        if(a.name === equipmentData.title + "_" + equipIndex){
                                          return t("Name") + ", ";
                                        }

                                      })
                                    : null}</div>
                              </TableCell>
                            </StyledTableRow>                            
                            </>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              );
            })}
          </div>
        ))}
      </>
    );
  })
);
