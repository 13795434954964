import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import { Colors, invertDark } from "../Assets/Config/color.config";
import { FileUploadSelector } from "./FileUploadSelector";
import { MyInput } from "./MyInput";
import { useTranslation } from "react-i18next";
import SurveyFileUploadingModal from "../Components/Survey/SurveyFileUploadingModal";
import Stack from '@mui/material/Stack';
import Upload from '@mui/icons-material/Upload';

export const MyMatrixTable = inject("store")(
  observer((props) => {
    const { category, optionData, questions, id, childSection, siteListData } = props;
    const { buildingId, surveyFramework, frameWorkIndex, answerArray, _handleRadioChange, subSectionIndex, _handleScope2DescriptionChange, _handleSurveyFileChange } =
      props.store.survey;
    const { theme, viewStatus } = props.store.common;

    const { t } = useTranslation("common");

    const frameworkId = surveyFramework?.[frameWorkIndex]?.framework_id;
    return (
      <div
        className="tableStyle"
        style={{
          borderRadius: 10,
          backgroundColor: theme==="dark"?"#141c43":"#fff",
          overflow: "scroll", maxHeight: "200px"
        }}
      >
        <table
          className="table mb-0"
          style={{ color: Colors[theme].textColor, borderColor: "transparent" }}
        >
          <thead>
            <tr>
              <th style={{position:"sticky", top:-15, backgroundColor:theme==="dark"?"#141c43":"#fff"}}/>
              {category?.map((v, k) => (
                <th className="text-center" key={k} style={{position:"sticky", top:-10, backgroundColor:theme==="dark"?"#141c43":"#fff"}}>
                  {v}
                </th>
              ))}
              {optionData?.map((obj, k) => (
                <th
                  className="text-center"
                  style={{
                    width: "100px",
                    borderLeft: " 1px solid",
                    position:"sticky", top:-10, backgroundColor:theme==="dark"?"#141c43":"#fff",
                    borderLeftColor: theme === "dark" ? 'rgb(255, 255, 255,0.1)' : Colors.Dark[4]
                  }}
                  key={k}
                >
                  {obj.label}
                </th>
              ))}
              {
                viewStatus === 'detailsView' ?
                <th 
                  style={{
                    borderLeft: " 1px solid", paddingRight: -10,
                    position:"sticky", top:-10, backgroundColor:theme==="dark"?"#141c43":"#fff",
                    borderLeftColor: theme === "dark" ? 'rgb(255, 255, 255,0.1)' : Colors.Dark[4] ,
                  }}
                />
                : null
              }              
            </tr>
          </thead>
          <tbody>
            {questions[id]?.questions
              ? questions[id].questions?.map((q, k) => {
                return (
                  <tr
                    key={q._id}
                    style={{
                      borderBottom:
                        k + 1 !== questions[id].questions.length &&
                        " 1px solid rgb(255, 255, 255,0.1)",
                      borderBottomColor: k + 1 !== questions[id].questions.length && (theme === "dark" ? 'rgb(255, 255, 255,0.1)' : Colors.Dark[4]),
                      borderTop: " 1px solid ",
                      borderTopColor: theme === "dark" ? 'rgb(255, 255, 255,0.1)' : Colors.Dark[4],
                    }}
                  >
                    <td className="text-center fw-bold" style={{ width: 40 }}>
                      {k + 1}
                    </td>
                    <td>{q.questionName}</td>
                    {q.answerOption.map((opt, k1) => {
                      return (
                        <td
                          key={opt._id}
                          className="text-center"
                          style={{
                            verticalAlign: 'middle',
                            borderLeft: " 1px solid",
                            borderLeftColor: theme === "dark" ? 'rgb(255, 255, 255,0.1)' : Colors.Dark[4],
                          }}
                        >
                          <input
                            style={{ cursor: "pointer" }}
                            id={q._id + k1}
                            type="radio"
                            name={q._id}
                            value={opt._id}
                            onChange={(e) => _handleRadioChange(opt, q._id, childSection, null, k, null,
                              answerArray?.filter(a => a.questionId === q._id).length > 0
                              ? answerArray.filter(a => a.questionId === q._id)[0]?.linkedFileId
                              : q?.auditData?.linkedFileId,
                              answerArray?.filter(a => a.questionId === q._id).length > 0
                              ? answerArray?.filter(a => a.questionId === q._id)[0]?.description
                              : q?.auditData?.description)}
                            checked={                             
                              answerArray?.filter(a => a.questionId === q._id).length > 0
                                ? answerArray.filter(a => a.questionId === q._id)[0].childSection?.answer === opt._id
                                : q.answer[0] === opt._id
                            }
                          />
                        </td>
                      );
                    })}
                    {
                      viewStatus === 'detailsView' ?
                      <td
                        style={{
                          minWidth: 150,
                          borderLeft: " 1px solid",
                          borderLeftColor: theme === "dark" ? 'rgb(255, 255, 255,0.1)' : Colors.Dark[4] ,
                        }}
                      >
                        <div className="tableStyle" style={{backgroundColor: Colors[theme].active, color: invertDark(theme)}}>
                      <div className="my-1" id={'uploaded_file_selector'} >                         
                          <label style={{ fontSize: "12px" }}>
                            {t("Data Validation File")}
                          </label>
                          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={0}
                          >
                            <FileUploadSelector
                              options={siteListData}
                              menuListColor={Colors.Dark[0]}
                              width="200px"
                              value={siteListData.find(o => o.value === (answerArray?.filter(
                                (a) =>
                                  a.questionId === q._id
                              ).length > 0
                                ? answerArray?.filter(
                                    (a) =>
                                      a.questionId === q._id
                                  )[0]?.linkedFileId: q?.auditData?.linkedFileId))}
                              _handleSelect={(e) =>
                                _handleSurveyFileChange(
                                  e.value, 
                                  q._id, 
                                  childSection, 
                                  k, //question index
                                  null, 
                                  k, 
                                  answerArray?.filter(
                                    (a) =>
                                      a.questionId === q._id
                                  ).length > 0
                                    ? answerArray?.filter(
                                        (a) =>
                                          a.questionId === q._id
                                      )[0]?.description
                                    : q?.auditData?.description,
                                      q.answer[0]
                              )}
                            />
                            <span data-bs-toggle="modal" data-bs-target="#SurveyAnsSubSectionFileModalForNotGHG" style={{ cursor: "pointer" }}>
                            <button title={t("Browse your files")} onClick={(e) =>
                                _handleSurveyFileChange(
                                  "uploadBtn", 
                                  q._id, 
                                  childSection, 
                                  k, //question index
                                  null, 
                                  k, 
                                  answerArray?.filter(
                                    (a) =>
                                      a.questionId === q._id
                                  ).length > 0
                                    ? answerArray?.filter(
                                        (a) =>
                                          a.questionId === q._id
                                      )[0]?.description
                                    : q?.auditData?.description,
                                      q.answer[0]
                              )} style={{ border: theme === "dark"? "1px solid #5e5e5e" : "1px solid #c6c6c6", minWidth: "36px !important", maxWidth: "36px !important", padding: "7px", borderRadius: "5px", background: theme === "dark"? "transparent": "#fff"}}><Upload style={{ color: "rgb(4, 197, 0)" }}/></button>
                              </span>
                          </Stack>                          
                        </div>
                        <div className="my-1">
                          <label style={{ fontSize: "12px" }}>
                            {t("Description of the uploaded file")}
                          </label>
                          <MyInput 
                            id={q._id+"_"}
                            required={true}
                            isTextArea={true}
                            placeHolder={t('Description of the uploaded file')}
                            value={answerArray?.filter(
                              (a) =>
                                a.questionId === q._id
                            ).length > 0
                              ? answerArray?.filter(
                                  (a) =>
                                    a.questionId === q._id
                                )[0]?.description
                              : q?.auditData?.description}
                            onChange={(e) =>
                              _handleScope2DescriptionChange(
                                e, 
                                q._id, 
                                childSection, 
                                null, 
                                k, 
                                null, 
                                k, 
                                answerArray?.filter(a => a.questionId === q._id).length > 0
                                ? answerArray.filter(a => a.questionId === q._id)[0]?.linkedFileId
                                : q?.auditData?.linkedFileId,
                                q.answer[0]
                              )}
                          />
                        </div>
                        <SurveyFileUploadingModal modalId={"SurveyAnsSubSectionFileModalForNotGHG"}  frameworkId={frameworkId} buildingId={buildingId} btnId="surveyAns_SubSection_File_Upload_NotGHG" />
                        </div>
                        
                      </td>
                      : null
                    }
                  </tr>
                );
              })
              : questions.map((q, k) => (
                <tr
                  key={q._id}
                  style={{
                    borderBottom:
                      k + 1 !== questions.length &&
                      " 1px solid",
                      borderBottomColor: k + 1 !== questions.length && (theme === "dark" ? 'rgb(255, 255, 255,0.1)' : Colors.Dark[4]) ,
                    borderTop: " 1px solid",
                    borderTopColor: theme === "dark" ? 'rgb(255, 255, 255,0.1)' : Colors.Dark[4] ,
                  }}
                >
                  <td className="text-center fw-bold" style={{ width: 40 }}>
                    {k + 1}
                  </td>
                  <td>{q.questionName}</td>
                  {q.answerOption.map((opt, k1) => (
                    <td
                      key={opt._id}
                      className="text-center"
                      style={{
                        verticalAlign: 'middle',
                        borderLeft: " 1px solid",
                        borderLeftColor: theme === "dark" ? 'rgb(255, 255, 255,0.1)' : Colors.Dark[4] ,
                      }}
                    >
                      <input
                        style={{ cursor: "pointer" }}
                        type="radio"
                        name={subSectionIndex.toString() + k}
                        value={opt._id}
                        onChange={(e) => _handleRadioChange(opt, q._id, childSection, null, k, null,
                          answerArray?.filter(a => a.questionId === q._id).length > 0
                          ? answerArray.filter(a => a.questionId === q._id)[0]?.linkedFileId
                          : q?.auditData?.linkedFileId,
                          answerArray?.filter(a => a.questionId === q._id).length > 0
                          ? answerArray?.filter(a => a.questionId === q._id)[0]?.description
                          : q?.auditData?.description)}
                        checked={
                          answerArray?.filter(
                            (a) =>
                              a.questionId === q._id
                          ).length > 0
                            ? answerArray.filter(
                              (a) =>
                                a.questionId === q._id
                            )[0].answer === opt._id
                            : questions[k].answer[0] === opt._id
                        }
                      />
                    </td>
                  ))}
                  {
                    viewStatus === 'detailsView' ?
                    <td
                      style={{
                        minWidth: 150,
                        borderLeft: "1px solid",
                        borderLeftColor: theme === "dark" ? 'rgb(255, 255, 255,0.1)' : Colors.Dark[4] ,
                      }}
                    >
                      <div className="tableStyle" style={{backgroundColor: Colors[theme].active, color: invertDark(theme)}}>
                        <div className="my-1" id={'uploaded_file_selector'}>
                          <label style={{ fontSize: "12px" }}>
                            {t("Data Validation File")}
                          </label>          
                          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={0}
                            >
                            <FileUploadSelector
                              width="200px"
                              options={siteListData}
                              menuListColor={Colors.Dark[0]}
                              value={siteListData.find(o => o.value === (answerArray?.filter(
                                (a) =>
                                  a.questionId === q._id
                              ).length > 0
                                ? answerArray?.filter(
                                    (a) =>
                                      a.questionId === q._id
                                  )[0]?.linkedFileId: q?.auditData?.linkedFileId))}
                              _handleSelect={(e) =>
                                _handleSurveyFileChange(
                                  e.value, 
                                  q._id, 
                                  childSection, 
                                  k, //question index
                                  null, 
                                  k, 
                                  answerArray?.filter(
                                    (a) =>
                                      a.questionId === q._id
                                  ).length > 0
                                    ? answerArray?.filter(
                                        (a) =>
                                          a.questionId === q._id
                                      )[0]?.description
                                    : q?.auditData?.description,
                                      q.answer[0]
                              )}
                            />
                            <span data-bs-toggle="modal" data-bs-target="#SurveyAnsFileModalForNotGHG" style={{ cursor: "pointer" }}>
                              <button title={t("Browse your files")} onClick={(e) =>
                                  _handleSurveyFileChange(
                                    "uploadBtn", 
                                    q._id, 
                                    childSection, 
                                    k, //question index
                                    null, 
                                    k, 
                                    answerArray?.filter(
                                      (a) =>
                                        a.questionId === q._id
                                    ).length > 0
                                      ? answerArray?.filter(
                                          (a) =>
                                            a.questionId === q._id
                                        )[0]?.description
                                      : q?.auditData?.description,
                                        q.answer[0]
                                )} style={{ border: theme === "dark"? "1px solid #5e5e5e" : "1px solid #c6c6c6", minWidth: "36px !important", maxWidth: "36px !important", padding: "7px", borderRadius: "5px", background: theme === "dark"?"transparent" : "#fff"}}><Upload style={{ color: "rgb(4, 197, 0)" }}/></button>
                                </span>
                          </Stack>
                        </div>
                        <div className="my-1">
                          <label style={{ fontSize: "12px" }}>
                            {t("Description of the uploaded file")}
                          </label>
                          <MyInput 
                            id={q._id+"_"}
                            required={true}
                            isTextArea={true}
                            placeHolder={t('Description of the uploaded file')}
                            value={answerArray?.filter(
                              (a) =>
                                a.questionId === q._id
                            ).length > 0
                              ? answerArray?.filter(
                                  (a) =>
                                    a.questionId === q._id
                                )[0]?.description
                              : q?.auditData?.description}
                            onChange={(e) =>
                              _handleScope2DescriptionChange(
                                e, 
                                q._id, 
                                childSection, 
                                null, 
                                k, 
                                null, 
                                k, 
                                answerArray?.filter(a => a.questionId === q._id).length > 0
                                ? answerArray.filter(a => a.questionId === q._id)[0]?.linkedFileId
                                : q?.auditData?.linkedFileId,
                                q.answer[0]
                              )}
                          />
                        </div>
                      </div>
                    </td>
                    : null
                  }
                </tr>
              ))}              
          </tbody>        
        </table>
        <SurveyFileUploadingModal modalId={"SurveyAnsFileModalForNotGHG"}  frameworkId={frameworkId} buildingId={buildingId} btnId="surveyAns_File_Upload_NotGHG" />
      </div>
    );
  })
);