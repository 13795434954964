import * as React from 'react';
import { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import {
    Box,
    Collapse,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Paper,
    Grid, 
    TableSortLabel
} from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { makeStyles } from "@material-ui/core/styles";

import { Colors, invertDark } from '../../Assets/Config/color.config';
import Capella from '../../Assets/images/capella.png'
import Conrad from '../../Assets/images/conrad.png'
import { SummaryDonutChart } from './SummaryDonutChart';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { baseURLImage } from '../../API/URL';

export const OverviewTable = inject("store")(
    observer((props) => {
        const { data } = props;
        const { theme, addDefaultSrc } = props.store.common;
        

        const [rows, setRows] = useState([]);
        const [order, setOrder] = useState("asc");
        const [orderBy, setOrderBy] = useState("");
        const summaryKeys = [
            { label: 'Verified', key: 'verified' },
            { label: 'Not Verified', key: 'notVerified' },
            { label: 'Rejected', key: 'rejected' },
            { label: 'Not Applicable', key: 'notApplicable' }
        ]
        const COLORS = [
            { status: 'Verified', color: '#B0D86F' },
            { status: 'Not Verified', color: '#8CDDD7' },
            { status: 'Rejected', color: '#F26B74' },
            { status: 'Not Applicable', color: '#85879E' },
        ]

        const createData = (data) => {
            const pieChartData = summaryKeys?.reduce((r, c) => {
                const R = [...r];

                R.push({
                    name: c.label,
                    value: data.summary.reduce((r1, c1) => r1 + (c1[c.key]), 0),
                    fill: COLORS.find(v => v.status === c.label)?.color
                })

                return R
            }, [])

            const frameworks = data.summary?.map((v => ({ framework: v.framework })))
            const tableData = summaryKeys?.reduce((r, c, i) => {
                const R = [...r];

                R.push({
                    status: c.label,
                    color: COLORS.find(v => v.status === c.label)?.color
                })

                for (let f of frameworks) {
                    R[i][f.framework] = data.summary?.find(v => v.framework === f.framework)?.[c.key]
                }

                R[i].total = frameworks.reduce((r, c) => r + R[i][c.framework], 0)

                return R
            }, [])
            // console.log('piechart data ', tableData)
            return {
                site: data.site,
                type: data.type.building_type,
                buildingId: data.buildingId,
                buildingPhoto: data.buildingPhoto,
                framework: data.framework,
                verified: data.verified,
                notVerified: data.notVerified,
                summary: {
                    piechartData: pieChartData,
                    summaryTable: tableData
                },
                frameworks: frameworks
            };
        }

        const handleRequestSort = (event, property) => {
            const isAsc = orderBy === property && order === "asc";
            setOrder(isAsc ? "desc" : "asc");
            setOrderBy(property);
        };

        const stableSort = (array, comparator) => {
            const stabilizedThis = array.map((el, index) => [el, index]);
            stabilizedThis.sort((a, b) => {
                const order = comparator(a[0], b[0]);
                if (order !== 0) return order;
                return a[1] - b[1];
            });
            return stabilizedThis.map((el) => el[0]);
        };

        const getComparator = (order, orderBy) => {
            return order === "desc"
                ? (a, b) => descendingComparator(a, b, orderBy)
                : (a, b) => -descendingComparator(a, b, orderBy);
        };

        const descendingComparator = (a, b, orderBy) => {
            if (b[orderBy] < a[orderBy]) {
                return -1;
            }
            if (b[orderBy] > a[orderBy]) {
                return 1;
            }
            return 0;
        };

        useEffect(() => {
            const rowsdata = data.map(v => createData(v));
            setRows(rowsdata)
        }, [data])

        const useStyles = makeStyles({
            table: {
                width: "100%",
                //   borderCollapse: "separate!important",
                //   borderSpacing: "0px 3px!important",
            },
        });

        const classes = useStyles();
        return (
            <TableContainer style={{ background: 'transparent', boxShadow: 'none' }} component={Paper}>
                <Table className={classes.table} aria-label="">
                    <SortableTHead
                        id={"id"}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        headCells={headers}
                        theme={theme}
                    />
                    <TableBody>
                        {
                            stableSort(rows, getComparator(order, orderBy))
                                ?.map((row, rowIndex) => (
                                    <Row key={rowIndex} row={row} theme={theme} addDefaultSrc={addDefaultSrc} />
                                ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    })
)

const SortableTHead = (props) => {
    const { order, orderBy, onRequestSort, headCells, theme } = props;
    const { t } = useTranslation("audit");
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    const styles = makeStyles({
        // Fully visible for active icons
        activeSortIcon: {
            opacity: '1!important',
            color: `${invertDark(theme)}!important`
        },
        // Half visible for inactive icons
        inactiveSortIcon: {
            opacity: '0.3!important',
            color: `${invertDark(theme)}!important`
        },
    });
    const classes = styles();
    return (
        <TableHead>
            <TableRow>
                <TableCell />
                {headCells.map((headCell, index) => (
                    <TableCell
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={{
                            color: invertDark(theme),
                            fontWeight: "bold",
                            padding: "10px 13px 10px 13px",
                            textAlign: "center",
                            position: "static",
                        }}
                    >
                        {
                            headCell.id === "" ?
                                headCell.label :
                                <TableSortLabel
                                    active={true}
                                    direction={orderBy === headCell.id ? order : "asc"}
                                    onClick={createSortHandler(headCell.id)}
                                    style={{
                                        color: invertDark(theme),
                                    }}
                                    classes={{
                                        icon: ((orderBy === headCell.id) ? classes.activeSortIcon : classes.inactiveSortIcon)
                                    }}
                                >
                                    {t(headCell.label)}
                                </TableSortLabel>
                        }

                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

const Row = (props) => {
    const { row, theme, addDefaultSrc } = props;
    const [open, setOpen] = React.useState(false);
    const tableCellStyle = { border: 0, color: invertDark(theme), padding: '10px 13px' };
    const navigate = useNavigate();
    const params = new URLSearchParams(window.location.search);
    const companyName = params.get("companyName");
    // const { t } = useTranslation("dashboard");
    const { t } = useTranslation("audit");
    const tBuilding = useTranslation("building");

    return (
        <React.Fragment>
            <TableRow style={{ background: Colors[theme].secondBackground, }} sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell style={{ border: 0, padding: '10px 13px', borderTopLeftRadius: 4, borderBottomLeftRadius: open ? 0 : 4 }}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {
                            open ?
                                <KeyboardArrowUpIcon style={{ color: Colors.water, fontWeight: 'bold' }} /> :
                                <KeyboardArrowDownIcon style={{ color: Colors.water, fontWeight: 'bold' }} />
                        }
                    </IconButton>
                </TableCell>
                <TableCell style={{ ...tableCellStyle, minWidth: 150 }} component="th" scope="row">
                    <img
                        className="me-2"
                        crossorigin="anonymous"
                        src={baseURLImage+row.buildingPhoto}
                        onError={addDefaultSrc}
                        width={"40px"}
                        height={"40px"}
                        style={{ borderRadius: '5px' }}
                    />{row.site}
                </TableCell>
                <TableCell style={tableCellStyle} align="center">{tBuilding.t(row.type)}</TableCell>
                <TableCell style={tableCellStyle} align="center">{row.framework}</TableCell>
                <TableCell style={tableCellStyle} align="center">{row.verified}</TableCell>
                <TableCell style={tableCellStyle} align="center">{row.notVerified}</TableCell>
                <TableCell onClick={()=>navigate(`/audit-company-verification?companyName=${companyName}&buildingId=${row.buildingId}&buildingName=${row.site}`)} style={{ color: Colors.blue, border: 0, padding: '10px 13px', borderTopRightRadius: 4, borderBottomRightRadius: open ? 0 : 4, cursor: 'pointer' }} align="center">{t("More Detail")+ ' >>'}</TableCell>
            </TableRow>
            <TableRow style={{ background: Colors[theme].secondBackground, padding: 0, margin: 0, borderSpacing: 0 }}>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0, border: 0, color: invertDark(theme), borderBottomRightRadius: 4, borderBottomLeftRadius: 4 }} colSpan={7}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <hr />
                            <Typography variant="h6" gutterBottom component="div">
                                {t("Summary")}
                            </Typography>
                            <Grid container display={'flex'} alignItems={'center'} columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 2 }}>
                                <Grid display={'flex'} justifyContent={'center'} item xs={12} md={4} lg={4}>
                                    <SummaryDonutChart
                                        data={row.summary?.piechartData}
                                    />
                                </Grid>
                                <Grid item xs={12} md={8} lg={8}>
                                    <Table size="small" aria-label="purchases">
                                        <TableHead>
                                            <TableRow style={{ background: 'transparent' }}>
                                                <TableCell></TableCell>
                                                {
                                                    row.frameworks?.map((head, key) => (
                                                        <TableCell key={key} style={{ color: invertDark(theme), textAlign: 'center' }}>{head.framework}</TableCell>
                                                    ))
                                                }
                                                <TableCell style={{ color: invertDark(theme), textAlign: 'center' }}>{t('Total')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {row.summary.summaryTable?.map((td, td_key) => (
                                                <React.Fragment key={td_key}>
                                                    <TableRow className='' key={td_key} style={{ background: Colors[theme].active, }}>
                                                        <TableCell style={{ whiteSpace: 'nowrap', alignItems: 'center', overflow: 'hidden', color: invertDark(theme), border: 0, borderTopLeftRadius: 4, borderBottomLeftRadius: 4 }}>
                                                            <div
                                                                className='me-2'
                                                                style={{
                                                                    background: td.color,
                                                                    width: 10,
                                                                    maxWidth: 10,
                                                                    height: 10,
                                                                    borderRadius: '50%',
                                                                    display: 'inline-block'
                                                                }}
                                                            >
                                                            </div>
                                                            <div style={{ display: 'inline-block' }}>{t(td.status)}</div>
                                                        </TableCell>
                                                        {
                                                            row.frameworks.map((fw, fw_key) => (
                                                                <TableCell key={fw_key} style={{ color: td.status === 'Rejected' ? td.color : invertDark(theme), textAlign: 'center', border: 0 }}>
                                                                    {td[fw.framework]}
                                                                </TableCell>
                                                            ))
                                                        }
                                                        <TableCell style={{ color: td.status === 'Rejected' ? td.color : invertDark(theme), textAlign: 'center', border: 0, borderTopRightRadius: 4, borderBottomRightRadius: 4 }}>
                                                            {td.total}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow style={{ background: 'transparent', height: 4 }} />
                                                </React.Fragment>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </Grid>
                            </Grid>

                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
            <TableRow style={{ background: 'transparent', height: 6 }} />
        </React.Fragment>
    );
}

// Row.propTypes = {
//     row: PropTypes.shape({
//         calories: PropTypes.number?.isRequired,
//         carbs: PropTypes.number.isRequired,
//         fat: PropTypes.number.isRequired,
//         history: PropTypes.arrayOf(
//             PropTypes.shape({
//                 amount: PropTypes.number.isRequired,
//                 customerId: PropTypes.string.isRequired,
//                 date: PropTypes.string.isRequired,
//             }),
//         ).isRequired,
//         name: PropTypes.string.isRequired,
//         price: PropTypes.number.isRequired,
//         protein: PropTypes.number.isRequired,
//     }).isRequired,
// };

const headers = [
    { id: "site", numeric: false, label: "Site" },
    { id: "type", numeric: false, label: "Type" },
    { id: "framework", numeric: true, label: "Framework" },
    { id: "verified", numeric: true, label: "Verified" },
    { id: "notVerified", numeric: true, label: "Not-Verified" },
    { id: "", numeric: false, label: "" },
]

