import { observable, action, makeObservable, runInAction, toJS } from "mobx";
import { toast } from "react-toastify";
import {
    fetchUpdateCustomCom,
    fetchDeleteCustomTempCom,
    fetchReportConfiguration,
    fetchReportCustomConfig,
    updateReportTemplate,
    deleteReportTemplate,
    fetchGetDefaultTempNames,
    
    fetchDyEnergyComparison,
    fetchDyBaselineEmission,
    fetchDyGHGVerification,
    fetchDyGHGEmissionAndTarget,
    fetchDyAssetAndEmissionFactor,
    fetchDyAnalysisGHGEmission
} from "../API/api.analysisReport";
import * as Showdown from 'showdown';
import { NodeHtmlMarkdown } from 'node-html-markdown'
import { renderToStaticMarkup } from 'react-dom/server';

import { DynamicComponents } from "../Components/ReportConfigure/DynamicComponents";
import { DynamicGhGChart } from "../Components/ReportConfigure/DynamicGhGChart";


class ReportConfigure {
  yearOptions = [];
  selectedYear = null;
  analysisReportData = [];
  reportTblLoading = false;
  reportConfigureList = [];
  selectedTemplate = "";
  currentAction = null;

  reportCustomConfigList = null;
  allComponentList = {};
  editingContent = null;
  selectedIndex = 0;
  customTempName = null;
  componentName = null;
  defaultTempNames = [];
  tempNameError = false;
  resizedImage = null;
  previewModal = false;
  isDeleteReport = false;
  deleteCheckedComs = [];

  dynEnergyComparison = [];
  dyGHGVerification = [];
  dyBaselineEmission = [];
  dyGHGEmissionAndTarget = [];
  dyAssetEmissionFactor = [];
  dyAnalysisGHGEmission = [];
  dyStationaryCombustion = [];
  dyPurchaseElectricity = [];
  dyUpstream = [];
  dyConEmisReport= [];
  dyEmisYearlyReport= [];

  dyData = null

  constructor() {
    makeObservable(this, {
      yearOptions: observable,
      selectedYear: observable,
      analysisReportData: observable,
      reportTblLoading: observable,
      reportConfigureList: observable,
      selectedTemplate: observable,
      currentAction: observable,

      reportCustomConfigList: observable,
      allComponentList: observable,
      editingContent: observable,
      selectedIndex: observable,
      customTempName: observable,
      resizedImage: observable,
      tempNameError: observable,
      isDeleteReport: observable,
      componentName: observable,
      deleteCheckedComs: observable,
      dynEnergyComparison: observable,
      dyGHGVerification: observable,
      dyAssetEmissionFactor: observable,
      dyAnalysisGHGEmission: observable,
      dyStationaryCombustion: observable,
      dyPurchaseElectricity: observable,
      dyUpstream: observable,
      dyConEmisReport: observable,
      dyEmisYearlyReport: observable,
      dyData: observable,

      _handleGetYearOptions: action.bound,
      _handleSelectedYear: action.bound,
      _handleTempNameChange: action.bound,
      _handleSelectTemplate: action.bound,
      _handleChangeEditingContent: action.bound,
      _handleSelectedIndex: action.bound,
      getEditCom: action.bound,
      deleteComp: action.bound,

      getReportConfiguration: action.bound,
      getReportCustomConfig: action.bound,
      getComponentSelectors: action.bound,
      _handleUpdateReportTemp: action.bound,
      _handleModifyCustomTemp: action.bound,
      _handleDeleteReportTemp: action.bound,

      updateCustomCom: action.bound,
      deleteCustomTempCom: action.bound,
      getDefaultTempNames: action.bound,
      _handleChangeImage: action.bound,
      _handleResizeImage: action.bound,
      _handleDnD: action.bound,
      _handleDeleteReportChecked: action.bound,
      _setDeleteReport: action.bound,
      _cancelDeleteReport: action.bound,
      _handleModifyComponent: action.bound,
      isDefaultTemp: action.bound,
      handleDeleteComp: action.bound,
      _handleLockCells: action.bound,
      _handleLockImage: action.bound,
      _handleLockText: action.bound,
      _handleReportVarClear: action.bound,
      _handleCurrentAction: action.bound,

      getDyEnergyConsumption: action.bound,
      getDyBaselineEmission: action.bound,
      getDyGHGVerification: action.bound,
      getDyGHGEmissionAndTarget: action.bound,
      getDyAssetEmissionFactor: action.bound,
      getDyAnalysisGHGEmission: action.bound,
      getDyUrls: action.bound,
      _handleDyContents: action.bound,
    });
  }

  
  _handleDyContents = () => {
    if(!this.reportCustomConfigList) return
    const generates = this.reportCustomConfigList.componentList.filter(c=> c.contentType === "Generated" && c.urls.length>0)
    if(!generates.length) return
    console.log('ufn',generates)
    for (let index = 0; index < generates.length; index++) {
      const data = generates[index];

      if(data.name.includes('Chart') ||data.name==='GHG Emisssion') continue

      Promise.all(
        data.urls.map(async (url) => {
          const result = await this.getDyUrls(url);
          return result;
        })
      ).then((results) => {
          console.log("All promises resolved successfully:", results);

          const [
            // this.dyBaselineEmission,
            // this.dynEnergyComparison,
            // this.dyGHGVerification,
            // this.dyAssetEmissionFactor,
            // this.dyGHGEmissionAndTarget,
            // this.dyAnalysisGHGEmission,
            // this.dyStationaryCombustion,
            // this.dyPurchaseElectricity,
            // this.dyUpstream,
            // this.dyConEmisReport,
            // this.dyEmisYearlyReport,
            dyContentData
          ] = results;

          let htmlString = ''
        
          if(dyContentData){
             htmlString = renderToStaticMarkup(
             
               <DynamicComponents dyData={toJS(dyContentData)} />
              // <DynamicComponents
              //   dyBaselineEmission={toJS(this.dyBaselineEmission)}
              //   dynEnergyComparison={toJS(this.dynEnergyComparison)}
              //   dyGHGVerification={toJS(this.dyGHGVerification)}
              //   dyAssetEmissionFactor={toJS(this.dyAssetEmissionFactor)}
              //   dyGHGEmissionAndTarget={toJS(this.dyGHGEmissionAndTarget)}
              //   dyAnalysisGHGEmission={toJS(this.dyAnalysisGHGEmission)}
              //   dyStationaryCombustion={toJS(this.dyStationaryCombustion)}
              //   dyPurchaseElectricity={toJS(this.dyPurchaseElectricity)}
              //   dyUpstream={toJS(this.dyUpstream)}
              //   dyConEmisReport={toJS(this.dyConEmisReport)}
              //   dyEmisYearlyReport={toJS(this.dyEmisYearlyReport)}
              // />
            );
          }

            this.reportCustomConfigList.componentList = {
              ...this.reportCustomConfigList,
            }.componentList.map((d) => {
              if (d.index === data.index) {
                return { ...d, content: htmlString };
              }
              return d;
            });
          

       
        })
        .catch((error) => {
          console.error("At least one promise failed:", error);
        });
    }
  };

  _setDeleteReport = () => {
    this.deleteCheckedComs = [];
    this.isDeleteReport = !this.isDeleteReport;
  };

  _cancelDeleteReport() {
    this.isDeleteReport = false;
  }

  _handleCurrentAction(action) {
    this.currentAction = action;
  }

  _handleDeleteReportChecked = (id, isChecked) => {
    const delArr = this.deleteCheckedComs;
    const index = delArr.indexOf(delArr.find((v) => v === id));
    if (isChecked) {
      delArr.push(id);
    } else delArr.splice(index, 1);

    this.deleteCheckedComs = toJS(delArr);
    console.log(toJS(this.deleteCheckedComs));
  };

  handleDeleteComp = () => {
    const comList = this.reportCustomConfigList.componentList;
    let exitComponents = toJS(comList).filter(
      (r) => !toJS(this.deleteCheckedComs).includes(r._id)
    );
    exitComponents = exitComponents.map((n, i) => {
      if(n.contentType === 'Generated'){
        n.content = ''
      }
      return { ...n, index: i };
    });
    const body = {
      ...this.reportCustomConfigList,
      componentList: exitComponents,
    };
    this._handleModifyCustomTemp("Save", body);
    this.deleteCheckedComs = [];
  };

  isDefaultTemp() {
    if (this.defaultTempNames.length && this.reportCustomConfigList) {
      return this.defaultTempNames.includes(
        this.reportCustomConfigList.templateName
      );
    }
    return false;
  }

  _handleDnD = (result) => {
    console.log("_handleDnD", result);
    if (!result.destination) {
      return;
    }
    const oldIndex = result.source.index;
    const newIndex = result.destination.index;

    const newItems = Array.from(this.reportCustomConfigList.componentList);
    let [reorderedItem] = newItems.splice(oldIndex, 1);

    newItems.splice(newIndex, 0, reorderedItem);

    this.reportCustomConfigList.componentList = newItems.map((n, i) => {
      return { ...n, index: i };
    });
    this._handleSelectedIndex(newIndex);
    // setState({ items: newItems });
  };

  _handleChangeImage = (e) => {
    // const changedFile = e.target.files[0];
    // console.log("changedFile > ", changedFile)
    // runInAction(() => {
    //     this.resizedImage = changedFile;
    // });

    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      this.resizedImage = reader.result;
    };
    reader.readAsDataURL(files[0]);
  };

  _handleResizeImage = () => {};

  _handleGetYearOptions = () => {
    let years = [];
    const currentYear = new Date().getFullYear();
    for (let i = 2018; i <= currentYear; i++) {
      years.push({
        label: String(i),
        value: String(i),
      });
    }
    this.yearOptions = years;
    this.selectedYear = String(currentYear);
  };

  _handleSelectedYear = (e) => {
    this.selectedYear = e.value;
  };

  _handleSelectTemplate = (e) => {
    this.deleteCheckedComs = [];
    this.reportCustomConfigList = null;
    this.selectedTemplate = e.value;
    this.editingContent=null
    this._handleSelectedIndex(0);
  };

  _handleSelectedIndex = (index) => {
    this.selectedIndex = index;
  };

  _handleTempNameChange = (e) => {
    this.tempNameError = false;
    const value = e.target.value;
    this.customTempName = value;
  };

  _handleComponentName = (e) => {
    const value = e.target.value;
    this.componentName = value;
  };

  _handleChangeEditingContent = (editor) => {
    console.log("editor", editor);
    const data = editor;
    this.editingContent = { ...this.editingContent, content: data };

    this.reportCustomConfigList.componentList = {
      ...this.reportCustomConfigList,
    }.componentList.map((d) => {
      if (d.index === this.editingContent.index) {
        return { ...d, content: data };
      }
      return d;
    });
  };

  // getEditCom = () => {
  //     const markdownRegex = /(#|\*|\-|\+|\d\.)\s+/;
  //     const contentTemp = this.reportCustomConfigList?.componentList[this.selectedIndex]?.content;
  //     // console.log("markdownRegex >> ", markdownRegex.test(contentTemp));

  //     const selectedContent = this.reportCustomConfigList ?
  //         {
  //             ...this.reportCustomConfigList.componentList[this.selectedIndex],

  //             content: markdownRegex.test(contentTemp) ? ((new Showdown.Converter({
  //                 tables: true,
  //                 simplifiedAutoLink: true,
  //                 strikethrough: true,
  //                 tasklists: true,
  //             })).makeHtml(contentTemp))
  //             : contentTemp
  //         }
  //         : null;

  //     this.editingContent = selectedContent;
  // }

  getEditCom = () => {
    if (!this.reportCustomConfigList) return;

    this.editingContent = null;
    this.dyData = null;
    

    const markdownRegex = /(#|\*|\-|\+|\d\.)\s+/;
    const selectedCom =
      this.reportCustomConfigList?.componentList[this.selectedIndex];
    if (selectedCom?.contentType === "Generated" && selectedCom.urls.length) {
      Promise.all(
        selectedCom.urls.map(async (url) => {
          const result = await this.getDyUrls(url);
          return result;
        })
      ).then((results) => {
          console.log("All promises resolved successfully:", results);

            [this.dyData] = results;
            this.editingContent = { ...selectedCom};
  
            this.reportCustomConfigList.componentList = {
              ...this.reportCustomConfigList,
            }.componentList.map((d) => {
              if (d.index === this.editingContent.index) {
                return { ...d};
              }
              return d;
            });
          })
     
    } else {
      // console.log("markdownRegex >> ", markdownRegex.test(contentTemp));

      const selectedContent = {
        ...this.reportCustomConfigList?.componentList[this.selectedIndex],

        content: markdownRegex.test(selectedCom.content)
          ? new Showdown.Converter({
              tables: true,
              simplifiedAutoLink: true,
              strikethrough: true,
              tasklists: true,
            }).makeHtml(selectedCom.content)
          : selectedCom.content,
      };
      this.editingContent = selectedContent;
    }

    console.log("selectedContent", this.editingContent);
  };

  _handleUpdateReportTemp = (action) => {
    if (this.customTempName?.trim() === "" || this.customTempName === null) {
      toast.error("Template Name cannot be empty!");
      this.customTempName = null;
      return;
    }

    if (action === "Save As" && this.reportConfigureList.length === 10) {
      toast.error("Sorry, you have reached the limit of template 10 !");
      return;
    }

    this.tempNameError = false;

    const templateName = this.customTempName;
    const title = this.customTempName;
    let bodyData = JSON.parse(JSON.stringify(this.reportCustomConfigList));

    let componentList = bodyData.componentList;

    componentList = componentList.map((n, i) => {
      if(n.contentType === 'Generated'){
        n.content = ''
      }
      return { ...n};
    });

    componentList = componentList.sort((a, b) =>
      a.index > b.index ? 1 : b.index > a.index ? -1 : 0
    );

    bodyData.componentList.map((v) => {
      const content = v.content;
      const htmlRegex = /<[a-z][\s\S]*>/i;
      if (htmlRegex.test(content)) {
        return {
          ...v,
          content: NodeHtmlMarkdown.translate(
            /* html */ content,
            /* options (optional) */ {},
            /* customTranslators (optional) */ undefined,
            /* customCodeBlockTranslators (optional) */ undefined
          ),
        };
      } else return v;
    });
    this._handleModifyCustomTemp(action, {
      ...bodyData,
      title,
      templateName,
      componentList,
    });
  };

  _handleModifyCustomTemp(action, body) {
    console.log("here", body);
    updateReportTemplate(action, body, (err, data) => {
      if (data && data.success) {
        runInAction(() => {
          this.reportCustomConfigList = data.payload || [];
          this.selectedTemplate = this.reportCustomConfigList.templateName;
          this.getReportConfiguration();
          this._handleSelectedIndex(0);
          toast.success("Updated Template Successfully!");

          const tempNameModal = document.getElementById("add_tempName");
          if (tempNameModal) {
            tempNameModal.setAttribute("data-bs-dismiss", "modal");
            let clickEvent = new MouseEvent("click", {
              view: window,
              bubbles: true,
              cancelable: false,
            });
            tempNameModal.dispatchEvent(clickEvent);
            tempNameModal.removeAttribute("data-bs-dismiss");
          }
          this.currentAction = null;
          // this.getReportCustomConfig()
        });
      }
      if (!data.success) {
        this.tempNameError = true;
        toast.error("Template Name already Exists!");
      } else {
        toast.error(err);
      }
    });
  }

  _handleDeleteReportTemp = () => {
    console.log("delete temp");
    deleteReportTemplate(this.reportCustomConfigList, (err, data) => {
      if (data && data.success) {
        this.reportCustomConfigList = null;
        this.selectedTemplate = null;
        this.customTempName = null;
        this._handleSelectedIndex(-1);
        this._handleReportVarClear();
        this.getReportConfiguration();
        toast.success("Deleted Template Successfully!");
      } else {
        toast.error(err);
      }
    });
  };

  _handleReportVarClear = () => {
    this.reportCustomConfigList = null;
    this.selectedTemplate = null;
    this.customTempName = null;
  };

  deleteComp = (value, index) => {
    const delComp = value[0];

    let delBody = this.reportCustomConfigList.componentList.filter(
      (d) => d.index === delComp.index
    );
    if (delBody.length) {
      delBody = delBody[0];
    }
    console.log(toJS(delBody), index);
    this.deleteCustomTempCom(toJS(delBody));
  };

  _handleModifyComponent() {
    if (this.componentName?.trim() === "" || this.componentName === null) {
      toast.error("Component Name cannot be empty!");
      this.componentName = null;
      return;
    }
    const componentList = this.reportCustomConfigList.componentList;

    if (
      componentList.length &&
      componentList.map((c) => c.name).includes(this.componentName)
    ) {
      toast.error("Component Name already Exists!");
      this.componentName = null;
      return;
    }

    const body = {
      name: this.componentName,
      title: this.componentName,
      content: null,
      contentType: 'RichText',
      editable: true,
      index: null,
      urls: [],
    };
    this.updateCustomCom(body);
  }

  updateCustomCom = (body) => {
    body.templateName = toJS(this.selectedTemplate);
    // console.log("update >> ", toJS(body));
    fetchUpdateCustomCom(body, (err, data) => {
      if (data && data.success) {
        runInAction(() => {
          this.componentName = null;
          this.reportCustomConfigList = data.payload || null;
          // const updateObj = customConfigList.componentList.filter(s => body.index === s.index)[0]

          // this.reportCustomConfigList.componentList =
          //     this.reportCustomConfigList.componentList.map(d => {
          //         if (d.index === body.index) {
          //             return updateObj
          //         }
          //         return d
          //     })
        });

        const comModal = document.getElementById("add_com");
        if (comModal) {
          const comModal = document.getElementById("add_com");
          comModal.setAttribute("data-bs-dismiss", "modal");
          let clickEvent = new MouseEvent("click", {
            view: window,
            bubbles: true,
            cancelable: false,
          });
          comModal.dispatchEvent(clickEvent);
          comModal.removeAttribute("data-bs-dismiss");
        }

        toast.success("Successfully created new component.");
      } else {
        toast.error(err);
      }

      this.reportTblLoading = false;
    });
  };

  deleteCustomTempCom = (body) => {
    body.templateName = toJS(this.selectedTemplate);
    fetchDeleteCustomTempCom(body, (err, data) => {
      if (data && data.success) {
        this.reportCustomConfigList.componentList =
          this.reportCustomConfigList.componentList.map((d) => {
            if (d.index === body.index) {
              delete d._id;
              return {
                name: null,
                title: null,
                content: null,
                contentType: null,
                editable: true,
                index: body.index,
                urls: [],
              };
            }
            return d;
          });
        this._handleSelectedIndex(body.index);
        toast.success("Successfully Deleted");
      } else {
        toast.error(err);
      }

      this.reportTblLoading = false;
    });
  };

  getReportConfiguration = () => {
    this.reportTblLoading = true;
    fetchReportConfiguration((err, data) => {
      this.reportTblLoading = false;
      if (data && data.success) {
        this.reportConfigureList = data.payload || [];
      } else {
        toast.error(err);
      }
    });
  };

  getDyEnergyConsumption = () => {
    fetchDyEnergyComparison((err, data) => {
      if (data && data.success) {
        this.dynEnergyComparison = data.payload || [];
      } else {
        toast.error(err);
      }
    });
  };

  getDyUrls = (url) => {
    return fetchDyBaselineEmission(url, (err, data) => {
     
      if (data && data.success) {
          return data.payload || [];

      } else {
          console.log('err',err)
          return [];
      }
    });
  };

  getDyBaselineEmission = () => {
      fetchDyBaselineEmission((err, data) => {

          if (data && data.success) {
              this.dyBaselineEmission = data.payload || [];
          } else {
              toast.error(err);
          }
      })
  }

  getDyGHGVerification = () => {
    fetchDyGHGVerification((err, data) => {
      if (data && data.success) {
        this.dyGHGVerification = data.payload || [];
      } else {
        toast.error(err);
      }
    });
  };

  getDyGHGEmissionAndTarget = () => {
    fetchDyGHGEmissionAndTarget((err, data) => {
      if (data && data.success) {
        this.dyGHGEmissionAndTarget = data.payload || [];
      } else {
        toast.error(err);
      }
    });
  };

  getDyAssetEmissionFactor = () => {
    fetchDyAssetAndEmissionFactor((err, data) => {
      if (data && data.success) {
        this.dyAssetEmissionFactor = data.payload || [];
      } else {
        toast.error(err);
      }
    });
  };

  getDyAnalysisGHGEmission = () => {
    fetchDyAnalysisGHGEmission(2020, (err, data) => {
      if (data && data.success) {
        this.dyAnalysisGHGEmission = data.payload || [];
      } else {
        toast.error(err);
      }
    });
  };

  getDefaultTempNames = () => {
    fetchGetDefaultTempNames((err, data) => {
      this.reportTblLoading = false;
      if (data && data.success) {
        this.defaultTempNames = data.payload || [];
      } else {
        toast.error(err);
      }
    });
  };

  getReportCustomConfig = () => {
    if (!this.selectedTemplate) return;
    fetchReportCustomConfig(this.selectedTemplate, (err, data) => {
      if (data && data.success) {
        this.reportCustomConfigList = data.payload || [];
       

        this.customTempName = this.selectedTemplate;
        this._handleSelectedIndex(0);
        this.getEditCom();
      } else {
        toast.error(err);
        this.reportCustomConfigList = [];
        this.customTempName = null;

        this._handleSelectedIndex(null);
        this.editingContent = null;
      }
    });
  };

  getComponentSelectors = () => {
    // const defaultComponents = toJS(this.reportConfigureList.find(r => r.templateName === this.selectedTemplate)?.componentList);
    // const customComponents = toJS(this.reportCustomConfigList?.templateName === this.selectedTemplate ? this.reportCustomConfigList.componentList : []);
    // let result=[]; let custom = [...customComponents];
    // for(let i=0; i<customComponents.length; i++){
    //     if(i === 0){
    //         result.push(customComponents);
    //     } else {
    //         custom.shift();
    //         result.push([...custom]);
    //     }
    // }
    // this.allComponentList = {
    //     defaultComponents: defaultComponents,
    //     customComponents: result
    // }
  };

  _handleLockImage = (that) => {
    const selected = that.image.get();
    selected.attr("contentEditable", "false");
    const img = selected[0];
    console.log("img", img);
    img.style.opacity = 0.8;
    img.style.userSelect = "none";
    img.style.color = "rgba(0, 0, 0, 0.5)";
    img.style.cursor = "not-allowed";
    img.style.pointerEvents = "none";
  };

  _handleLockCells = (that) => {
    const selectedCells = that.table.selectedCells();

    for (let cell of selectedCells) {
      cell.setAttribute("contentEditable", "false");
      cell.style.userSelect = "none";
      cell.style.color = "rgba(0, 0, 0, 0.5)";
      cell.style.cursor = "not-allowed";
    }
  };

  _handleLockText = (that) => {
    const target = that.selection.text();
    const selected = that.selection.element();
    if (
      target?.trim() === selected.innerHTML?.trim() &&
      selected?.tagName !== "P"
    ) {
      selected.setAttribute("contentEditable", "false");
      selected.style.userSelect = "none";
      selected.style.color = "rgba(0, 0, 0, 0.5)";
      selected.style.cursor = "not-allowed";
    } else {
      that.selection.save();
      that.html.insert(
        target.replace(
          target,
          `<span contentEditable="false" style="user-select:none;color:rgba(0, 0, 0, 0.5);cursor:not-allowed">${target}</span>`
        )
      );
      that.selection.restore();
    }
  };
}

export default new ReportConfigure();
