import { EquipmentModal } from "../Components/Survey/EquipmentModal"
import { PurchasedElectricityModal } from "../Components/Survey/purchaseElectricityModal"

export const MyEmptyComponent = ({ text, currentSectionName }) => {
  return (
    <>
      {
        currentSectionName === 'Scope 1' ?
          <EquipmentModal />
          :
          <PurchasedElectricityModal />
      }
      <div className="text-center my-2 text-secondary">
        <div className="rounded p-4" style={{ /*background: Colors.darkBlue,*/fontSize: 18 }}>
          <i className="bi bi-file-earmark-fill pe-1" />
          {text}{" "}
          {
            currentSectionName === 'Scope 1' || currentSectionName === 'Scope 2' ?
              <a data-bs-toggle="modal" data-bs-target={'#addEquipmentModal'} href="javascript:void(0);" style={{ fontSize: '14px' }}>
                Add {currentSectionName === 'Scope 1' ? 'Equipment Data' : 'Purchased Electricity'}
              </a>
              : null
          }
        </div>
      </div>
    </>
  )
}