import { inject, observer } from "mobx-react";
import { ESGBreadCrumb } from "../../Components/ESG/ESGBreadCrumb"
import digitalEdgeIcon from "../../Assets/images/digitalEdgeIcon.png";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { MyButton, ProgressBar } from "../../Tools";
import SankeyDiagram from "../../Components/ESG/SankeyDiagram"
import { useNavigate } from "react-router-dom";
import {
    Selector,
} from "../../Tools";
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { toJS } from "mobx";
import  {EditNewKPIModal} from "../../Components/KPIManagement/EditNewKPIModal"
import { ESGKPITable } from "../../Components/ESG/ESGKPITable";
import { ESGKPISetupModal } from "../../Components/ESG/ESGKPISetupModal";
import { ConfirmDeleteModel } from "../../Components/ESG/ConfirmDeleteModel";

export const ESGTrackingContainer = inject("store")(

    observer((props) => {
        const { theme, companyName , yearOptions} = props.store.common;        
        const { _fetchAllPillars , _fetchBaseYears, _fetchKPIOwnerNames, _fetchKPIOwnerTitles, 
            _fetchUnitOfMeasurement, _fetchFreqOfMeasurement, _fetchStandardAligment, 
            _fetchKeyDependencies } = props.store.kpiManagement;
        const { _fetchAllESGKPI, _handleCreateEsgKpi, _handleESGYear, selectedESGYear, _deleteEsgKpi} = props.store.esg;
        const { t } = useTranslation("esg_tracking");

        const cardStyle = {
            background: Colors[theme].secondBackground,
            boxShadow: "0 4px 13px rgb(21,21,21,25%)",
            borderRadius: 10,
        };

        useEffect(() => {
            _fetchAllPillars();
            _fetchBaseYears();
            _fetchKPIOwnerNames();
            _fetchKPIOwnerTitles();
            _fetchUnitOfMeasurement();
            _fetchFreqOfMeasurement();
            _fetchStandardAligment();
            _fetchKeyDependencies();
            if(companyName === "Digital Edge" || companyName === "ClientA"){
                _fetchAllESGKPI()
            }
        },[]);

        return (
            <div className="mx-4 my-2">
                <div className="d-flex justify-content-between mb-2">
                    <ESGBreadCrumb />
                    <img
                        className="mb-1 pe-2"
                        width={"100px"}
                        src={digitalEdgeIcon}
                        alt={ t("Digital Edge Icon") }
                    />
                </div>
                {
                    companyName === "Digital Edge" || companyName === "ClientA" ?
                        <div>
                            <div style={cardStyle} className="cardStyle me-1 row d-flex align-items-center">
                                <div className="d-flex justify-content-between align-items-center mb-2">
                                    <div className="d-flex" style={{ color: invertDark(theme) }}>
                                        <sapn className="d-flex align-items-center me-2">
                                            <h5 className="m-0" style={{ color: theme === "dark" ? Colors.Dark[5] : Colors.Dark[0] }}>ESG KPIs - Targets & Results</h5>
                                        </sapn>
                                        <div className="mx-2" style={{ width: 100 }}>
                                            <Selector
                                                // id={"editYearSelector"}
                                                _handleSelect={(e) => _handleESGYear(e, "year")}
                                                options={yearOptions}
                                                menuListColor={Colors.Dark[0]}
                                                value={yearOptions.filter((option) => (option.value) == (selectedESGYear))}
                                                placeholder={"Select year"}
                                                small={true}
                                            />
                                        </div>
                                    </div>

                                    <div className="me-2">
                                        <MyButton
                                            style={{ height: 40, width: '100px' }}
                                            text="Create"
                                            customColor='#066CFF'
                                            customHoverColor={Colors.water}
                                            dataToggle="modal"
                                            dataTarget="#esgKpiModal"
                                            onClick={_handleCreateEsgKpi}
                                        />
                                    </div>                                    
                                </div>
                                <ESGKPITable />
                            </div>
                            <ESGKPISetupModal />
                            <ConfirmDeleteModel deleteESG={_deleteEsgKpi} theme={theme}/>
                        </div>
                    :
                    <div className="d-flex justify-content-between">
                        <div className="col-8 cardStyle p-3" style={{
                            background: Colors[theme].secondBackground, minWidth: 300, color: Colors[theme].textColor,
                            borderTopRightRadius: 0, borderBottomRightRadius: 0
                        }}>
                            <KPITrackingOverView />
                        </div>
                        <div className="col-4 cardStyle p-0 pe-4"
                            style={{
                                background: Colors[theme].active, minWidth: 300, color: Colors[theme].textColor, borderTopLeftRadius: 0, borderBottomLeftRadius: 0,
                                borderLeft: theme === 'dark' ? '1px solid rgb(255,255,255,10%)' : '1px solid rgb(0,0,0,10%)',
                                fontSize:14
                            }}>
                            <KPIInfo />
                            <EditNewKPIModal />
                        </div>
                </div>
                }
            </div>
        );
    })
);

export const KPITrackingOverView = inject("store")(
    observer((props) => {
        const navigate = useNavigate();
        const { theme } = props.store.common;
        const { pillarOption,pillar,_handlePillarChange, sankeyData } = props.store.kpiManagement;
        
        const titleColor = { color: theme === 'dark' ? Colors.Dark[5] : Colors.Dark[0], fontWeight: 'bold' };

        // const [esgOptionValue,setEsgOptionValue] = useState(pillarOption[0]);
        const [chartData,setChartData] = useState(dataSetDemo["Respect for Resources"]);
        const { t } = useTranslation("esg_tracking"); 
        const pillarName = toJS(pillarOption).find(v=> v.label === pillar)?.label;
        return (
            <div>
                <span className="d-flex justify-content-between">
                    <h5 style={{color: theme === 'dark' ? Colors.Dark[5] : Colors.Dark[0]}}>{ t("KPI Tracking Overview") }</h5>
                    <MyButton
                        customColor={theme === 'dark' ? Colors.primaryBule : Colors.blue}
                        customHoverColor={Colors.lightBlue}
                        className="m-1"
                        style={{ width: 200 }}
                        text={ t("Setup ESG Pillars") }
                        onClick={() => { navigate('/esg-pillar-setup') }}
                    />
                </span>

                <span className="d-flex align-items-center pb-3">
                    <div className="pb-2" style={{ ...titleColor, fontSize: 14 }}>{ t("ESG Pillar") } :</div>
                    <span className="m-3">
                        <Selector
                            placeholder={ t("Select ESG Pillar") }
                            menuListColor={Colors.Dark[0]}
                            options={pillarOption}
                            value={toJS(pillarOption).filter((option) => option.label === pillar)}
                            _handleSelect={(e) => _handlePillarChange(e)}
                        />
                    </span>
                </span>

                <span className="d-flex justify-content-between">
                    <div className="col-3 text-center p-0 m-0" style={{ ...titleColor, fontSize: 14 }}>
                        { t("Materiality Topics/ESG Sub-Pillars") }
                    </div>
                    <div className="col-3 text-center p-0 m-0" style={{ ...titleColor, fontSize: 14 }}>
                        { t("KPIs") }
                    </div>
                </span>

                {pillarName&&sankeyData.length!=0 ? 
                <SankeyDiagram DataSankey={sankeyData} windowWidth={1259} theme={theme} /> 
                : <span><i className="spinner-border" /></span>}

                {/* <div
                    className="py-2 text-center"
                    style={{
                        position: "relative",
                        right: 0,
                        bottom: 3,
                        fontSize: 14,
                        zIndex: 1,
                    }}
                >
                    <span
                        className="rounded"
                        style={{
                            background: "rgba(0,0,0,0.5)",
                            padding: 10,
                            color: invertDark(theme),
                            lineHeight: 3
                        }}
                    >

                        <i
                            className="bi bi-circle-fill px-2"
                            style={{ fontSize: 10, color: Colors.greenMaterial }}
                        />
                        <span style={{ fontWeight: 'bold' }}>{ t("Note") }:</span> <span style={{ fontStyle: 'italic' }}>{ t("ESG Tracking Note") }</span>
                    </span>
                </div> */}
            </div >
        );
    })
)

export const KPIInfo = inject("store")( 
    observer((props) => {
        const { theme } = props.store.common;
        const { selectedKplInfo, editKpi } = props.store.esg;
        const {_handleEditKPIModal, kpiManagements} = props.store.kpiManagement;
        const titleColor = { color: theme === 'dark' ? Colors.Dark[5] : Colors.Dark[0], fontWeight: 'bold' };
        const { t } = useTranslation("esg_tracking");

        

        return (
            <div>
                <h5 className="p-3" style={titleColor}>{t("KPI Information")}  <span 
                                        onClick={()=> _handleEditKPIModal(editKpi)}
                                        data-bs-toggle="modal"
                                        data-bs-target="#kpiEditModal"
                                        className="col-1 text-end"
                                        style={{ cursor: 'pointer' }}>
                                            <i className="bi bi-pencil-square" />
                                        
                                        </span></h5>
                <div className="p-3" style={{ borderBottom: theme === 'dark' ? '1px solid rgb(255,255,255,10%)' : '1px solid rgb(0,0,0,10%)' }}>
                    <div className="pb-2" style={titleColor}>{t("KPIs")}</div>
                    {/* { t("Measure PUE in all operational datacenters") } */}
                    {selectedKplInfo?.target}

                </div>
                <div className="p-3" style={{ borderBottom: theme === 'dark' ? '1px solid rgb(255,255,255,10%)' : '1px solid rgb(0,0,0,10%)' }}>
                    <div className="pb-2" style={titleColor}>{t("Objectives")}</div>
                    {/* { t("Best-in-class Power Usage Effectiveness (PUE) in datacenter industry") } */}
                    {selectedKplInfo?.objectives}
                </div>
                <div className="p-3" style={{ borderBottom: theme === 'dark' ? '1px solid rgb(255,255,255,10%)' : '1px solid rgb(0,0,0,10%)' }}>
                    <div className="pb-2" style={titleColor}>{t("Target")}</div>
                    {/* <ul className="mb-0"><li>{t("Design new greenfield developments to achieve annualised PUE of 1.2 or less")}</li></ul> */}
                    {selectedKplInfo?.established_target}
                </div>
                <div className="p-3 d-flex justify-content-between" style={{ borderBottom: theme === 'dark' ? '1px solid rgb(255,255,255,10%)' : '1px solid rgb(0,0,0,10%)' }}>
                    <div className="pb-2" style={titleColor}>{t("Progress")}</div>
                    {/* <ul className="mb-0"><li>{t("Design new greenfield developments to achieve annualised PUE of 1.2 or less")}</li></ul> */}
                    <ProgressBar width={165} progress={Number(selectedKplInfo.progress==null?0:selectedKplInfo.progress)}/>
                </div>
                <div className="p-3" style={{ borderBottom: theme === 'dark' ? '1px solid rgb(255,255,255,10%)' : '1px solid rgb(0,0,0,10%)' }}>
                    <div className="pb-2" style={titleColor}>{t("Detail")}</div>
                    <div className="row pb-2">
                        <span className="col-4 fw-bold my-1">
                            Key Dependencies
                        </span>
                        <span className="col-1 my-1">
                            :
                        </span>
                        <span className="col-7 my-1">
                            {selectedKplInfo?.dependencies}
                        </span>

                        <span className="col-4 fw-bold my-1">
                            KPIs Owners
                        </span>
                        <span className="col-1 my-1">
                            :
                        </span>
                        <span className="col-7 my-1">
                            {selectedKplInfo?.kpis_owner}
                        </span>                        

                        <span className="col-4 fw-bold my-1">
                            Base Year
                        </span>
                        <span className="col-1 my-1">
                            :
                        </span>
                        <span className="col-7 my-1">
                            {selectedKplInfo?.base_year}
                        </span>

                        <span className="col-4 fw-bold my-1">
                            Frequency of Measurement
                        </span>
                        <span className="col-1 my-1">
                            :
                        </span>
                        <span className="col-7 my-1">
                            {selectedKplInfo?.freq_of_measurement}
                        </span>

                        <span className="col-4 fw-bold my-1">
                            Unit of Measurement
                        </span>
                        <span className="col-1 my-1">
                            :
                        </span>
                        <span className="col-7 my-1">
                            {selectedKplInfo?.unit_of_measurement}
                        </span>

                        <span className="col-4 fw-bold my-1">
                            Status
                        </span>
                        <span className="col-1 my-1">
                            :
                        </span>
                        <span className="col-7 my-1">
                            {selectedKplInfo?.status}
                        </span>

                        <span className="col-4 fw-bold my-1">
                            Notes
                        </span>
                        <span className="col-1 my-1">
                            :
                        </span>
                        <span className="col-7 my-1">
                            {selectedKplInfo?.notes}
                        </span>

                        <span className="col-4 fw-bold my-1">
                            Result
                        </span>
                        <span className="col-1 my-1">
                            :
                        </span>
                        <span className="col-7 my-1">
                            {selectedKplInfo?.result}
                        </span>


                    </div>
                </div>
                <div className="p-3">
                    <div className="pb-2" style={titleColor}>{ t("Standards Alignment") }</div>
                    {
                        selectedKplInfo.standard_alignment === "" ?
                        <img className="p-2 rounded" style={{ background: Colors.white }} width={"100px"} src={digitalEdgeIcon} />
                        :
                        <div className="">{selectedKplInfo.standard_alignment}</div>
                    }
                    
                </div>
                
            </div>
        );
    }))



const dataSetDemo = 
{
    "Respect for Resources" :[
    {
        sdg_id: 4,
        sdg_title: "GHG Emissions",
        color: Colors.Chart_Color[0],
        sector_id: 2,
        group_name: "0",
        sector_name: "Scope 1 and 2",
        value: 1,
        result: 1,
    },
    {
        sdg_id: 4,
        sdg_title: "GHG Emissions",
        color: Colors.Chart_Color[0],
        sector_id: 2,
        group_name: "0",
        sector_name: "Emission reduction and Measurement",
        value: 1,
        result: 1.1,
    },
    {
        sdg_id: 4,
        sdg_title: "GHG Emissions",
        color: Colors.Chart_Color[0],
        sector_id: 2,
        group_name: "1",
        sector_name: "Energy Measurement",
        user_id: 1,
        value: 1,
        result: 2,
    },
    {
        sdg_id: 4,
        sdg_title: "GHG Emissions",
        color: Colors.Chart_Color[0],
        sector_id: 2,
        group_name: "1",
        sector_name: "Certified green building increment",
        user_id: 1,
        value: 1,
        result: 2.1,
    },
    {
        sdg_id: 4,
        sdg_title: "Energy Management",
        color: Colors.Chart_Color[1],
        group_name: "2",
        sector_id: 2,
        sector_name: "PUE Measurement",
        user_id: 1,
        value: 1,
        result: 3,
    },
    {
        sdg_id: 4,
        sdg_title: "Energy Management",
        color: Colors.Chart_Color[1],
        sector_id: 2,
        group_name: "3",
        sector_name: "PUE Measurement",
        user_id: 1,
        value: 1,
        result: 4,
    },
    {
        sdg_id: 4,
        sdg_title: "Water Management",
        color: Colors.Chart_Color[2],
        group_name: "4",
        sector_id: 2,
        sector_name: "WEU Measurement",
        user_id: 1,
        value: 1,
        result: 5,
    },
    {
        sdg_id: 4,
        sdg_title: "Water Management",
        color: Colors.Chart_Color[2],
        group_name: "5",
        sector_id: 2,
        sector_name: "Total water Measurement",
        user_id: 1,
        value: 1,
        result: 6,
    },
    {
        sdg_id: 4,
        sdg_title: "Water Management",
        color: Colors.Chart_Color[2],
        group_name: "5",
        sector_id: 2,
        sector_name: "Water consumed percentage",
        user_id: 1,
        value: 1,
        result: 6.1,
    },
    {
        sdg_id: 4,
        sdg_title: "Circular Economy",
        color: Colors.Chart_Color[3],
        group_name: "6",
        sector_id: 2,
        sector_name: "Minimum of one circular economy",
        user_id: 1,
        value: 1,
        result: 7,
    },

],
"Respect for Transparency":[
    {
        sdg_id: 4,
        sdg_title: "Business Ethics",
        color: Colors.Chart_Color[0],
        sector_id: 2,
        group_name: "0",
        sector_name: "Anti-corruption training",
        value: 1,
        result: 16,
    },
    {
        sdg_id: 4,
        sdg_title: "Customer Privacy & Data Security",
        color: Colors.Chart_Color[1],
        sector_id: 2,
        group_name: "0",
        sector_name: "Information Security & Management System",
        value: 1,
        result: 17,
    },
    {
        sdg_id: 4,
        sdg_title: "Responsible Supply Chain",
        color: Colors.Chart_Color[2],
        sector_id: 2,
        group_name: "1",
        sector_name: "Supplier Measurement",
        user_id: 1,
        value: 1,
        result: 18,
    },
    {
        sdg_id: 4,
        sdg_title: "Business Continuity",
        color: Colors.Chart_Color[3],
        sector_id: 2,
        group_name: "1",
        sector_name: "Data center uptime Measurement",
        user_id: 1,
        value: 1,
        result: 19,
    },
    {
        sdg_id: 4,
        sdg_title: "Economic Performance",
        color: Colors.Chart_Color[4],
        group_name: "2",
        sector_id: 2,
        sector_name: "Locations, square footage and megawatt Measurement",
        user_id: 1,
        value: 1,
        result: 20,
    },
    {
        sdg_id: 4,
        sdg_title: "Customer Privacy & Data Security",
        color: Colors.Chart_Color[5],
        sector_id: 2,
        group_name: "3",
        sector_name: "User Measurement",
        user_id: 1,
        value: 1,
        result: 21,
    },
    {
        sdg_id: 4,
        sdg_title: "Customer Privacy & Data Security",
        color: Colors.Chart_Color[5],
        sector_id: 2,
        group_name: "3",
        sector_name: "Monetary losses Measurement",
        user_id: 1,
        value: 1,
        result: 22,
    },
    {
        sdg_id: 4,
        sdg_title: "Customer Privacy & Data Security",
        color: Colors.Chart_Color[5],
        sector_id: 2,
        group_name: "3",
        sector_name: "Enforcement requests Measurement",
        user_id: 1,
        value: 1,
        result: 23,
    },
    {
        sdg_id: 4,
        sdg_title: "Customer Privacy & Data Security",
        color: Colors.Chart_Color[5],
        sector_id: 2,
        group_name: "3",
        sector_name: "Enforcement user requests Measurement",
        user_id: 1,
        value: 1,
        result: 23.1,
    },{
        sdg_id: 4,
        sdg_title: "Customer Privacy & Data Security",
        color: Colors.Chart_Color[5],
        sector_id: 2,
        group_name: "3",
        sector_name: "Resulting in disclosure percentage",
        user_id: 1,
        value: 1,
        result: 23.2,
    },{
        sdg_id: 4,
        sdg_title: "Customer Privacy & Data Security",
        color: Colors.Chart_Color[5],
        sector_id: 2,
        group_name: "3",
        sector_name: "Data breaches Measurement",
        user_id: 1,
        value: 1,
        result: 24,
    },{
        sdg_id: 4,
        sdg_title: "Customer Privacy & Data Security",
        color: Colors.Chart_Color[5],
        sector_id: 2,
        group_name: "3",
        sector_name: "Personal identifiable information percentage",
        user_id: 1,
        value: 1,
        result: 24.1,
    },{
        sdg_id: 4,
        sdg_title: "Customer Privacy & Data Security",
        color: Colors.Chart_Color[5],
        sector_id: 2,
        group_name: "3",
        sector_name: "Number of users affected",
        user_id: 1,
        value: 1,
        result: 24.2,
    },
    {
        sdg_id: 4,
        sdg_title: "Business Continuity",
        color: Colors.Chart_Color[6],
        sector_id: 2,
        group_name: "3",
        sector_name: "Performance issues Measurement",
        user_id: 1,
        value: 1,
        result: 25,
    },
    {
        sdg_id: 4,
        sdg_title: "Business Continuity",
        color: Colors.Chart_Color[6],
        sector_id: 2,
        group_name: "3",
        sector_name: "Service disruptions Measurement",
        user_id: 1,
        value: 1,
        result: 25.1,
    },{
        sdg_id: 4,
        sdg_title: "Business Continuity",
        color: Colors.Chart_Color[6],
        sector_id: 2,
        group_name: "3",
        sector_name: "Total customer downtime Measurement",
        user_id: 1,
        value: 1,
        result: 25.2
    },

],
"Respect for People & Communities":[
    {
        sdg_id: 4,
        sdg_title: "Health & Safety",
        color: Colors.Chart_Color[0],
        sector_id: 2,
        group_name: "0",
        sector_name: "Operational TRIR; Construction TRIR",
        value: 1,
        result: 8
    },
    {
        sdg_id: 4,
        sdg_title: "Health & Safety",
        color: Colors.Chart_Color[0],
        sector_id: 2,
        group_name: "0",
        sector_name: "Environment Mgt System",
        value: 1,
        result: 9
    },
    {
        sdg_id: 4,
        sdg_title: "Diversity, Equity & Inclusion",
        color: Colors.Chart_Color[1],
        sector_id: 2,
        group_name: "1",
        sector_name: "Employee Measurement(Foreign nationals)",
        user_id: 1,
        value: 1,
        result: 10.1
    },
    {
        sdg_id: 4,
        sdg_title: "Diversity, Equity & Inclusion",
        color: Colors.Chart_Color[1],
        sector_id: 2,
        group_name: "1",
        sector_name: "Employee Measurement(Located offshore)",
        user_id: 1,
        value: 1,
        result: 10.2
    },
    {
        sdg_id: 4,
        sdg_title: "Diversity, Equity & Inclusion",
        color: Colors.Chart_Color[1],
        sector_id: 2,
        group_name: "1",
        sector_name: "Employee Measurement(Local jobs created)",
        user_id: 1,
        value: 1,
        result: 10.3
    },
    {
        sdg_id: 4,
        sdg_title: "Diversity, Equity & Inclusion",
        color: Colors.Chart_Color[1],
        sector_id: 2,
        group_name: "1",
        sector_name: "Women Measurement(Director level and above)",
        user_id: 1,
        value: 1,
        result: 12.1
    },
    {
        sdg_id: 4,
        sdg_title: "Diversity, Equity & Inclusion",
        color: Colors.Chart_Color[1],
        sector_id: 2,
        group_name: "1",
        sector_name: "Women Measurement(below Director level)",
        user_id: 1,
        value: 1,
        result: 12.2
    },
    {
        sdg_id: 4,
        sdg_title: "Diversity, Equity & Inclusion",
        color: Colors.Chart_Color[1],
        sector_id: 2,
        group_name: "1",
        sector_name: "Nationalities Measurement",
        user_id: 1,
        value: 1,
        result: 12.2
    },
    {
        sdg_id: 4,
        sdg_title: "Human Capital Management",
        color: Colors.Chart_Color[2],
        group_name: "5",
        sector_id: 2,
        sector_name: "Employee satisfaction Measurement and Engagement",
        user_id: 1,
        value: 1,
        result: 11
    },
    {
        sdg_id: 4,
        sdg_title: "Human Capital Management",
        color: Colors.Chart_Color[2],
        group_name: "5",
        sector_id: 2,
        sector_name: "Employee turnover rate Measurement",
        user_id: 1,
        value: 1,
        result: 14
    },
    {
        sdg_id: 4,
        sdg_title: "Community Relations",
        color: Colors.Chart_Color[3],
        group_name: "6",
        sector_id: 2,
        sector_name: "Total capital expenditure Measurement",
        user_id: 1,
        value: 1,
        result: 15
    },

]}
;