import { inject, observer } from "mobx-react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { toJS } from "mobx";
import { useTranslation } from "react-i18next";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

export const AuditGroupTab = inject("store")(

    observer((props) => {
        const { theme } = props.store.common;
        const {sourceTabsOptions, selectedSourceTab, _handleChangeSourceTab} = props.store.audit;
        const {t} = useTranslation("audit");

        const labelWithIcon = (label, icon) => {
            return (
                <span><i className={`${icon} px-1`} style={{fontSize:20}}/>{t(label)}</span>
            );
        }
    
        return (
                <Box sx={{ borderBottom: 1, borderColor: 'divider', color: Colors[theme].textColor }}>
                    <Tabs  value={selectedSourceTab} onChange={_handleChangeSourceTab} aria-label="sourceTabs">
                        {sourceTabsOptions.map((v,k) => 
                            <Tab
                                label={labelWithIcon(v.label, v.icon)} id={`source-tab-${v.value}`} 
                                sx={{color: Colors[theme].textColor, fontSize: 15, fontWeight: 'bold',
                                    borderBottom: selectedSourceTab === v.value ? 2 : null, 
                                    borderBottomColor: selectedSourceTab === v.value ? "#0056D2" : "",
                                    textTransform:"none"}}
                            />
                        )
                        }
                    </Tabs>
                </Box>
        );
    })
);