import { Colors } from "../../Assets/Config/color.config";
import { MyButton } from "../../Tools";
import { LineChart, XAxis, Line } from "recharts";
import leaf from "../../Assets/images/leaf.png";
import { inject, observer } from "mobx-react";
import { useEffect } from "react";
import { toJS } from "mobx";

export const SiteInfo = inject("store")(
    observer((props) => {
        const { role, getBuildingList, permittedBuilding } = props.store.common

        useEffect(() => {
            getBuildingList();
        },[]);

        const data = [
            { year: 2019, value: 2 },
            { year: 2020, value: 5 },
            { year: 2021, value: 22 },
        ];

        return (
            <div style={{ minWidth: 300 }}>
                {permissions[role].filter(v => v === "Framework").length > 0 ? <Framework /> : null}
                {permissions[role].filter(v => v === "TotalSite").length > 0 ? <TotalSite building={permittedBuilding} /> : null}
                {permissions[role].filter(v => v === "BuildingInfo").length > 0 ? <BuildingInfo /> : null}
                {permissions[role].filter(v => v === "CommulativeChart").length > 0 ? <CommulativeChart data={data} /> : null}
            </div>
        );
    }))

    const TotalSite = (props) => {
        
        return (
            <div className="cardStyle">
                <span className="d-flex justify-content-between align-items-center" style={{ fontSize: 15, fontWeight: 'bold' }}>
                    <span><span style={{ color: Colors.Dark[3], fontSize: 20 }}><i className="bi bi-building pe-2" /></span>Total Sites</span>
                    <MyButton text='View Detail' small /></span>
                <div style={{ fontSize: 30 }} className="text-end">{props.building? (props.building.length) : 0}</div>
            </div>
        )
    }

const CommulativeChart = ({ data }) => {
    return (
        <div className="cardStyle">
            <span style={{ fontSize: 15, fontWeight: 'bold' }}>Cumulative Line Chart 1</span>
            <LineChart
                width={250}
                height={300}
                data={data}
                margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
            >
                <Line type="monotone" dataKey="value" stroke={Colors.water} dot={false} />
                <XAxis dataKey="year" />
            </LineChart></div>
    )
}

const Framework = () => {
    return (
        <div className="cardStyle">
            <span style={{ fontSize: 15, fontWeight: 'bold' }}>
                ESG Framework
            </span>
            <hr className='my-2' />
            <img src={leaf} width='20' /> Environmental
        </div>
    )
}

const permissions = { user: ["Framework", "BuildingInfo"], admin: ["Framework", "TotalSite", "CommulativeChart"] }

const BuildingInfo = () => {
    return (
        <div className="cardStyle">
            <span className="d-flex justify-content-between align-items-center" style={{ fontSize: 15, fontWeight: 'bold' }}>
                <span><span style={{ color: Colors.Dark[3], fontSize: 20 }}><i className="bi bi-building pe-2" /></span>Building Info</span>
                <MyButton text='View Detail' small /></span>
        </div>
    )
}