import { toJS, transaction } from "mobx";
import { inject, observer } from "mobx-react";
import { useEffect, Fragment, useState } from "react";
import { addNewBuilding, baseURL, baseURLImage } from "../../API/URL";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import building from "../../Store/building";
import allCountry from "../../Assets/countries.json";
import {
  IconCard,
  MyButton,
  MyInput,
  Selector,
  MyUploadImage,
} from "../../Tools";
import { toast } from "react-toastify";
import { useTranslation } from 'react-i18next';
import SelectLocationModal from "./SelectLocationModal";
import { GetBaselineModal } from "./GetBaselineModal";

export const BuildingRegistration = inject("store")(
  observer((props) => {
    const {
      getAccessmentFramework,
      getAllBuildingType,
      theme,
      accessmentList,
      getBuildingList,
    } = props.store.common;
    const { setPageIndex, pageIndex } = props.store.specialOneTime;
    const { buildingArr, editMode, permittedBuilding } = props.store.building;

    const { t } = useTranslation("one_time");

    useEffect(() => {
      getAccessmentFramework();
      getAllBuildingType();
      getBuildingList();
    }, []);
    // console.log(toJS(editMode), toJS(permittedBuilding))

    return (
      <div
        className="cardStyle px-4"
        style={{ background: Colors[theme].secondBackground }}
      >
        <Header editMode={editMode} theme={theme} step={pageIndex + 1} />
        {accessmentList && <SiteInfo />}
        <div className="text-end pt-2">
          <MyButton
            disabled={buildingArr?.length === 0}
            onClick={() => setPageIndex(1)}
            style={{ width: 150 }}
            rightIcon={<i className="bi bi-arrow-right" />}
            text={t("NEXT")}
            customColor={Colors.water}
            title={t("No new building added yet")}
          />
        </div>
      </div>
    );
  })
);

const Header = ({ step, theme, editMode }) => {
  const { t } = useTranslation("one_time");
  return (
    <div style={{ fontSize: 25, color: invertDark(theme) }}>
      {editMode === '' ? <><strong>{t("Step", { count: step })} :</strong> {t("Create New Site")}</> : 'Edit Site'}
    </div>
  );
};

const SiteInfo = inject("store")(
  observer((props) => {
    const {
      allBuildingType,
      accessmentList,
      permittedBuilding,
      getBuildingList,
      _handleDeleteBuildingFromOneTimePage,
      addDefaultSrc,
      theme,
      permittedBuildingLoading,
      showBaseLineModal
    } = props.store.common;
    const {
      setInputs,
      buildingName,
      buildingType,
      uploadLogoImage,
      _handleUploadImage,
      photo,
      buildingArr,
      _handleRemove,
      _handleAddLocation,
      showModal,
      framework,
      _handleCreateOneTimeSite,
      _handleFrameworkCheck,
      _resetImage,
      isDisabled,
      country,
      city,
      defaultProps,
      inputs,
      _handleSetBuildingLocation,
      lat,
      lng,
      setEditMode,
      editMode,
      editBuilding,
      _handleEditBuilding,
      showGHGModal,
      unit_floor,
      _handleAddUnit_Floor,
      _handleDeleteUnit_Floor
    } = props.store.building;

    const buildingTypeOptions = allBuildingType?.map((building) => ({
      value: building._id,
      label: building.building_type,
    }));
    const CountryOptions = allCountry?.map((country) => ({
      value: country.name,
      label: country.name,
      code: country.code.toLowerCase(),
    }));

    const activeCheckedStyle = {
      cursor: "pointer",
      opacity: 1,
    };

    const activeStyle = {
      cursor: "pointer",
      opacity: 0.7,
    };
    const noActiveStyle = {
      cursor: "pointer",
      opacity: 0.7,
    };

    const { t } = useTranslation("one_time");

    // console.log('accessmentLIt....', toJS(accessmentList))
    const animateOnChange = (value) => {
      const btnStyle = {
        opacity: (value === editMode) || hoverId === value ? 1 : 0.6,
        // fontSize: (value === editMode)|| hoverId===value ? null : 20,
        transitionProperty: 'all',
        transitionDuration: '0.2s',
        cursor: 'pointer',
        margin: '1px 0px 1px 0px',
      }
      return btnStyle;
    }
    const [hoverId, setHoverId] = useState('');
    const windowWidth = window.innerWidth;

    return (
      <Fragment>
        <div
          className="d-flex row justify-content-between py-3"
          style={{ color: invertDark(theme) }}
        >
          <div
            id="site_info"
            className="col-lg-8 col-md-7 col-sm-12 my-2 rounded"
          >
            <div
              className="p-3 me-2"
              style={{ background: Colors[theme].active, borderRadius: 5 }}
            >
              <h5>{t("Site Information")}</h5>
              <div className="row">
                <div className="col-lg-3 col-sm-12 align-items-center">
                  <MyUploadImage
                    theme={theme}
                    _handleUploadImage={_handleUploadImage}
                    photo={photo}
                    _resetImage={_resetImage}
                    uploadLogoImage={uploadLogoImage}
                    width={"100%"}
                  />
                </div>
                <div className="col-lg-4 col-sm-12 align-items-center">
                  <span className="my-2">
                    <MyInput
                      label={t("Site Name")}
                      required={true}
                      id={"buildingName"}
                      type={"text"}
                      value={buildingName}
                      onChange={(e) => setInputs("buildingName", e)}
                      placeHolder={t("Site Name")}
                    />
                  </span>
                  {/* <span className="col-lg-3 col-sm-12 p-0 m-0">Type</span> */}
                  <span className="">
                    <Selector
                      label={t("Country")}
                      placeholder={t("Select Country")}
                      menuListColor={Colors.Dark[0]}
                      options={CountryOptions}
                      value={CountryOptions?.filter(
                        (option) => option.value === country
                      )}
                      _handleSelect={(e) => setInputs("country", e)}
                    />
                  </span>
                  <span className="my-2">
                    <MyInput
                      label={t("Latitude")}
                      required={true}
                      id={"Latitude"}
                      type={"number"}
                      value={inputs.lat}
                      onChange={(e) => setInputs("Latitude", e)}
                      placeHolder={t("Latitude")}
                    />
                  </span>
                  <div className="text-left" style={{ cursor: "pointer" }}>
                    {/* <MyButton
                      className="mt-3 w-200"
                      data-bs-toggle="modal" 
                    data-bs-target="#SelectLocationModal"
                      text={t("Select Location")}
                      customColor={Colors.water}
                    /> */}
                    <button
                      className="btn px-4 mt-3"
                      style={{
                        background: "rgb(41, 130, 255)",
                        color: "#fff",
                      }}
                      data-bs-toggle="modal"
                      data-bs-target="#SelectLocationModal"
                    >{t("Select Location")}</button>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-12 align-items-center ms-lg-5 ms-xl-5">
                  <span className="my-2">
                    <Selector
                      label={t("Type")}
                      placeholder={t("Select Building Type")}
                      menuListColor={Colors.Dark[0]}
                      options={buildingTypeOptions}
                      value={buildingTypeOptions?.filter(
                        (option) => option.value === buildingType
                      )}
                      _handleSelect={(e) => setInputs("buildingType", e)}
                    />
                  </span>
                  <span className="">
                    <MyInput
                      label={t("City")}
                      required={true}
                      id={"city"}
                      type={"text"}
                      value={city}
                      onChange={(e) => setInputs("city", e)}
                      placeHolder={t("City")}
                    />
                  </span>
                  <span>
                    <MyInput
                      label={t("Longtitude")}
                      required={true}
                      id={"Longtitude"}
                      type={"number"}
                      value={inputs.lng}
                      onChange={(e) => setInputs("Longtitude", e)}
                      placeHolder={t("Longtitude")}
                    />
                  </span>
                  <div>
                    <div className="d-flex justify-content-between align-items-center">
                      <div style={{ fontSize:12, padding: '2px',color: Colors[theme]?.textColor }}>{'Unit/Floor'} :</div>
                      <i 
                        title={'Add Unit/Floor'} 
                        className="bi bi-plus-square-fill me-1" 
                        style={{ fontSize: 16, color: 'rgb(41, 130, 255)', cursor: 'pointer'}}
                        onClick={_handleAddUnit_Floor}
                      ></i>
                    </div>
                    {
                      unit_floor.map((v,k)=> {
                        return(
                          <div key={k} className="d-flex justify-content-between align-items-center mb-1">
                            <div className="" style={{ width: unit_floor?.length > 1 ? '90%' : '100%'}}>
                              <MyInput
                                  required={true}
                                  id={"unit_floor"+k}
                                  type={"text"}
                                  value={v}
                                  onChange={(e) => setInputs("unit_floor", e, k)}
                                  placeHolder={t("Unit/Floor")}
                                />
                            </div>
                            {
                              unit_floor?.length > 1 ?
                                <i
                                  title="Delete Unit/Floor" 
                                  className="bi bi-dash-square-fill mx-1" 
                                  style={{ fontSize: 16, color: Colors.low, cursor: 'pointer' }}
                                  onClick={()=>_handleDeleteUnit_Floor(k)}
                                ></i>
                              : null
                            }
                          </div>
                        )
                      })
                    }
                    
                  </div>
                </div>
              </div>
              <hr />
              <div className="row m-0">
                <span className="col" style={{}}>
                  <h6>{t("Select Frameworks")}</h6>
                </span>
              </div>
              <div className="row m-0">
                <div className="flex-col d-flex flex-wrap justify-content-start m-0 pt-3">
                  {accessmentList?.map((accessment, k) => {
                    let index = editMode ? -1 : framework?.findIndex((v) => v === accessment._id);
                    return (
                      <span
                        // data-bs-toggle={accessment.name === 'GHG (Version 2)' &&"modal"}
                        // data-bs-target={accessment.name === 'GHG (Version 2)' &&"#getBaselineModal"}
                        className="ps-1 m-0"
                        key={k}
                        onClick={() =>
                          accessment.active
                            ?  _handleFrameworkCheck(accessment._id)
                            : toast.error(accessment.status)
                        }
                        style={
                          index === -1
                            ? accessment.active
                              ? activeStyle
                              : noActiveStyle
                            : activeCheckedStyle
                        }
                        title={accessment.surveyData.surveyHeader.headerName}
                      >
                        <IconCard
                          cardSize={60}
                          width={30}
                          icon={accessment._id}
                          style={
                            index === -1 ? noActiveStyle : activeCheckedStyle
                          }
                          
                          imgURL={baseURLImage + accessment.imageURL}
                          label={accessment.name}
                        />
                      </span>
                    );
                  })}
                </div>
              </div>
              <div className="text-center" style={{ cursor: "pointer" }}>
                <MyButton
                  disabled={isDisabled}
                  // onClick={() => _handleCreateNewSite()}
                  onClick={() => editMode === '' ? _handleCreateOneTimeSite(permittedBuilding) : _handleEditBuilding()}
                  className="col-3"
                  text={editMode !== '' ? t("Save Changes") : t("Add")}
                  customColor={Colors.water}
                />
              </div>
            </div>
          </div>

          <div
            className="col-lg-4 col-md-5 col-sm-12 my-2 rounded"
          // style={{ height: document.getElementById('site_info')?.offsetHeight, }}
          >
            <div
              className="h-100"
              style={{
                background: Colors[theme].active,
                borderRadius: 5,
                position: "relative",
                minHeight: 400,
              }}
            >
              <h5 className="p-3">{t("Existing Site List")}</h5>
              <div
                className="px-3"
                style={{
                  position: "absolute",
                  height: "83%",
                  overflowY: "scroll",
                  width: "100%",
                }}
              >
                {permittedBuildingLoading ? (
                  <i className="spinner-border mt-2 mx-auto d-flex" />
                ) : permittedBuilding || permittedBuilding?.length > 0 ? (
                  permittedBuilding?.map((b, i) => (
                    <div onMouseOver={() => setHoverId(b._id)}
                      onMouseLeave={() => setHoverId('')} style={{ fontWeight: editMode === b._id ? 'bold' : '', ...animateOnChange(b._id) }} key={i} className="d-flex align-items-center p-2 justify-content-between">
                      <div className="d-flex align-items-center">
                        <img
                          onError={addDefaultSrc}
                          crossorigin="anonymous"
                          src={baseURLImage + b.photo}
                          width={50}
                        // height={30}
                        />


                        <span style={{ fontSize: '1rem', width: windowWidth > 768 ? editMode !== b._id ? 200 : 160 : null, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }} className="ps-2" >{b.buildingName}</span>
                      </div>
                      <div >{editMode !== b._id ? <i onClick={() => setEditMode(b._id)} style={{ fontSize: '1rem', cursor: 'pointer' }} className="bi bi-pencil-square" /> :

                        <span onClick={() => setEditMode('')} className="text-end border border-secondary rounded p-2" style={{ fontSize: '14', cursor: 'pointer', color: hoverId === b._id ? Colors.water : '' }}>
                          <i className="bi bi-x-square pe-1"></i>{t("Cancel")}</span>

                      }  </div>
                    </div>
                  ))
                ) : (
                  <div className="text-secondary h6 text-center">
                    {t("No Data")}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <SelectLocationModal />
       {showBaseLineModal&& <GetBaselineModal from='buildingRegistration'/>}
      </Fragment>
    );
  })
);

// export const BuildingRegistration = inject("store")(
//   observer((props) => {
//     const { getAccessmentFramework, getAllBuildingType, theme,accessmentList } = props.store.common;
//     const { setPageIndex, pageIndex } = props.store.specialOneTime;
//     const { buildingArr } = props.store.building;
//     useEffect(() => {
//       getAccessmentFramework();
//       getAllBuildingType();
//     }, []);

//     return (
//       <div className="cardStyle px-4" style={{ background: Colors[theme].secondBackground }}>
//         <Header theme={theme} step={pageIndex + 1} />
//         {accessmentList&&<SiteInfo />}
//         <div className="text-end pt-2">
//           <MyButton
//             disabled={buildingArr?.length === 0}
//             onClick={() => setPageIndex(1)}
//             style={{ width: 150 }}
//             rightIcon={<i className="bi bi-arrow-right" />}
//             text={"NEXT"}
//             customColor={Colors.water}
//             title={"No new building added yet!"}
//           />
//         </div>
//       </div>
//     );
//   })
// );

// const Header = ({ step, theme }) => {
//   return (
//     <div style={{ fontSize: 25, color: invertDark(theme) }}>
//       <strong>Step {step}:</strong> Create New Site
//     </div>
//   );
// };

// const SiteInfo = inject("store")(
//   observer((props) => {
//     const { allBuildingType, accessmentList, permittedBuilding, getBuildingList, _handleDeleteBuildingFromOneTimePage, addDefaultSrc, theme, permittedBuildingLoading } = props.store.common;
//     const {
//       setInputs,
//       buildingName,
//       buildingType,
//       uploadLogoImage,
//       _handleUploadImage,
//       photo,
//       buildingArr,
//       _handleRemove,
//       framework,
//       _handleCreateOneTimeSite,
//       _handleFrameworkCheck,
//       _resetImage,
//       isDisabled,
//       country,
//       city,
//     } = props.store.building;
//     const buildingTypeOptions = allBuildingType?.map((building) => ({
//       value: building._id,
//       label: building.building_type,
//     }));

//     const CountryOptions = allCountry?.map((country) => (
//       {
//         value: country.name,
//         label: country.name,
//         code: country.code
//       }
//     ))

//     const activeCheckedStyle = {
//       cursor: "pointer",
//       opacity: 1,
//     };

//     const activeStyle = {
//       cursor: "pointer",
//       opacity: 0.7,
//     };
//     const noActiveStyle = {
//       cursor: "pointer",
//       opacity: 0.7,
//     };

//     useEffect(() => {
//       getBuildingList()
//     }, []);

//     return (
//       <div
//         className="d-flex justify-content-between py-3"
//         style={{ color: invertDark(theme), maxHeight: '100%', height: '100%' }}
//       >
//         <div id='site_info' className=" col-8 rounded" style={{}}>
//           <div
//             className="p-3 me-2"
//             style={{ background: Colors[theme].active, borderRadius: 5 }}
//           >
//             <h5>Site Information</h5>
//             <div className="row">
//               <div className="col-3">
//                 <MyUploadImage
//                   theme={theme}
//                   _handleUploadImage={_handleUploadImage}
//                   photo={photo}
//                   _resetImage={_resetImage}
//                   uploadLogoImage={uploadLogoImage}
//                 />
//               </div>
//               <div className="col-9">
//                 <div className="row">
//                   <div className="col-6">
//                     <span className="my-2">
//                       <MyInput
//                         label='Site Name'
//                         required={true}
//                         id={"buildingName"}
//                         type={"text"}
//                         value={buildingName}
//                         onChange={(e) => setInputs("buildingName", e)}
//                         placeHolder={"Site Name"}
//                       />
//                     </span>
//                     <span className="my-2">
//                       <Selector
//                         label='Country'
//                         placeholder="Select Country"
//                         menuListColor={Colors.Dark[0]}
//                         options={CountryOptions}
//                         value={CountryOptions?.filter(
//                           (option) => option.value === country
//                         )}
//                         _handleSelect={(e) => setInputs("country", e)}
//                       />
//                     </span>

//                   </div>
//                   <div className="col-6">
//                     <span className="my-2">
//                       <Selector
//                         label='Type'
//                         placeholder="Select Building Type"
//                         menuListColor={Colors.Dark[0]}
//                         options={buildingTypeOptions}
//                         value={buildingTypeOptions?.filter(
//                           (option) => option.value === buildingType
//                         )}
//                         _handleSelect={(e) => setInputs("buildingType", e)}
//                       />
//                     </span>
//                     <span className="my-2">
//                       <MyInput
//                         label='City'
//                         required={true}
//                         id={"city"}
//                         type={"text"}
//                         value={city}
//                         onChange={(e) => setInputs("city", e)}
//                         placeHolder={"City"}
//                       />
//                     </span>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <hr />
//             <div className="row m-0">
//               <span className="col" style={{}}>
//                 <h6>Select Frameworks</h6>
//               </span>
//             </div>
//             <div className="row m-0">
//               <div className="flex-col d-flex flex-wrap justify-content-start m-0 pt-3">
//                 {accessmentList?.map((accessment, k) => {
//                   let index = framework?.findIndex(
//                     (v) => v === accessment._id
//                   );
//                   return (
//                     <span
//                       className="ps-1 m-0"
//                       key={k}
//                       onClick={() => accessment.active ? _handleFrameworkCheck(accessment._id) : toast.error(accessment.status)}
//                       style={index === -1 ? (accessment.active ? activeStyle : noActiveStyle) : activeCheckedStyle}
//                       title={accessment.status}
//                     >
//                       <IconCard
//                         cardSize={50}
//                         width={30}
//                         icon={accessment._id}
//                         style={index === -1 ? noActiveStyle : activeCheckedStyle}
//                         imgURL={baseURLImage + accessment.imageURL}
//                       />
//                     </span>
//                   );
//                 })}
//               </div>
//             </div>
//             <div className="text-center" style={{ cursor: "pointer" }}>
//               <MyButton
//                 disabled={isDisabled}
//                 // onClick={() => _handleCreateNewSite()}
//                 onClick={() => _handleCreateOneTimeSite()}
//                 className="col-3"
//                 text={"Add"}
//                 customColor={Colors.water}
//               />
//             </div>
//           </div>
//         </div>

//         <div className=" col-4 rounded " style={{ height: document.getElementById('site_info')?.offsetHeight, }}>
//           <div
//             className=""
//             style={{
//               background: Colors[theme].active,
//               borderRadius: 5,
//               height: document.getElementById('site_info')?.offsetHeight,
//             }}
//           >
//             <h5 className="p-3">Existing Site List</h5>
//             <div className="px-3" style={{ height: '83%', overflowY: 'auto' }}>
//               {permittedBuildingLoading ?
//                 <i className="spinner-border mt-2 mx-auto d-flex" />
//                 :
//                 ((permittedBuilding || permittedBuilding?.length > 0) ?
//                   permittedBuilding?.map((b, i) => (
//                     <div key={i} className="d-flex align-items-center pb-2">
//                       <span className="col pe-1">
//                         <img
//                           onError={addDefaultSrc}
//                           src={
//                             baseURLImage + b.photo
//                           }
//                           width={50}
//                           height={50}
//                         />
//                       </span>
//                       <span className="col-lg-10 col-md-9 col-sm-9" >
//                         <h6>{b.buildingName}</h6>
//                       </span>

//                       {/* <span className="col-1">
//                     <span
//                       className="d-flex justify-content-center align-items-center text-white"
//                       style={{
//                         background: "red",
//                         borderRadius: "50%",
//                         width: 30,
//                         height: 30,
//                         fontSize: 15,
//                         cursor: "pointer",
//                       }}
//                       onClick={() => _handleDeleteBuildingFromOneTimePage(b._id)}
//                     >
//                       <i className="bi bi-trash3"></i>
//                     </span>
//                   </span> */}
//                     </div>
//                   ))
//                   : <div className="text-secondary h6 text-center">No Data!</div>)}
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   })
// );
