import * as API from "./URL";
import i18n from "../i18n";

export const fetchSurvey = async (surveyHeaderId, callback) => {
  return fetch(API.surveyInfo+surveyHeaderId, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err.toString(), null));
};

export const fetchFrameworkAnsCount = async (buildingId, frameworkId,year, callback) => {
  
  return fetch(API.frameworkAnsCount({buildingId, frameworkId,year}), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
      "Accept-Language": i18n.resolvedLanguage
    },
  })
  .then((res) => res.json())
  .then((data) => callback(null, data))
  .catch((err) => callback(err.toString(), null));
}

export const fetchQuestion= async (params, callback) => {
  return fetch(API.question(params), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
      "Accept-Language": i18n.resolvedLanguage
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err.toString(), null));
};

export const fetchSurveyHeader = async (buildingId, callback) => {
  return fetch(API.surveyList+buildingId, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err.toString(), null));
};


export const fetchDownloadFramework = async (buildingId, frameworkId) => {
  return fetch(API.downloadFramework(buildingId, frameworkId), {
    method: "GET",
    headers: {
      //"Content-Type": "application/pdf; charset=utf-8",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
      "Accept-Language": i18n.resolvedLanguage
    },
  })
  .then((res) => res);
}

export const deleteFramework = async(buildingId, frameworkId, callback) => {
  return fetch(API.addNewFramework+ "?buildingId=" + buildingId + "&frameworkId=" + frameworkId, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",

      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
    },
  }).then((res) => res.json())
  .then((data) => callback(null, data))
  .catch((err) => callback(err.toString(), null));
}

export const checkManualEntry = async(buildingId, frameworkId, year, type, callback) => {
  return fetch(API.checkManualEntryAPI(buildingId, frameworkId, year, type), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
    },
  }).then((res) => res.json())
  .then((data) => callback(null, data))
  .catch((err) => callback(err.toString(), null));
}

export const calculateEmissionBaseOnUsage = async(buildingId, frameworkId, year, callback) => {
  return fetch(API.calculateEmissionBaseOnUsage(buildingId, frameworkId, year), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
    },
  }).then((res) => res.json())
  .then((data) => callback(null, data))
  .catch((err) => callback(err.toString(), null));
}

export const getEmissionByCalculatedMethod = async(type, calculatedMethod, unit, typeOfEmission, scope, category, vehicleType, callback) => {
  return fetch(API.getEmissionByCalculatedMethod(type, calculatedMethod, unit, typeOfEmission, scope, category, vehicleType),{
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
    },
  }).then((res) => res.json())
  .then((data) => callback(null,data))
  .catch((err) => callback(err.toString(), null));
}

export const fetchCalApproaches = async(type,emissionFactor,scope, callback) => {  console.log("factor>>>>", emissionFactor);
  const api_ = emissionFactor === 'Custom emission factor' ? API.customCalApproachesAPI : API.calApproachesAPI
  // console.log("fetchCalApproaches >> ", api_+ `?type=${type}` + `&scope=${scope}`);
  return fetch(api_+ `?type=${type}` + `&scope=${scope}` + `&typeOfEmission=${emissionFactor}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",

      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
    },
  }).then((res) => res.json())
  .then((data) => callback(null, data))
  .catch((err) => callback(err.toString(), null));
}

export const fetchAddCustomCalApproach = async(data, callback) => {
  
  return fetch(API.addCustomCalAPI, {
    method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(data)
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err.toString(), null));
}

export const fetchUpdateUnit = async(data, callback) => {
  
  return fetch(API.updateUnitAPI, {
    method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(data)
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err.toString(), null));
}

export const updateUploadDescription = async(params, data, callback) => {
  if(params.answer_id){
    return fetch(API.updateUploadedFileForSurveyAnswer(params),{
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(data)
    })
      .then((res) => res.json())
      .then((data) => callback(null, data))
      .catch((err) => callback(err.toString(), null));
  }else if (params.category){
    return fetch(API.updateUploadedFileForCategory(params), {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(data)
    })
      .then((res) => res.json())
      .then((data) => callback(null, data))
      .catch((err) => callback(err.toString(), null));
  }else {
    return fetch(API.updateUploadedFileForAnswer(params), {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(data)
    })
      .then((res) => res.json())
      .then((data) => callback(null, data))
      .catch((err) => callback(err.toString(), null));
  }
  
}

export const fetchExcelUpload = async(buildingId,frameworkId, data, callback) => {
  
  return fetch(API.excelUpload+`?buildingId=${buildingId}&frameworkId=${frameworkId}`, {
    method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(data)
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err.toString(), null));
}

export const fetchDeleteSetup = async(buildingId, frameworkId, uploadedId, type, callback) => {
  const URL = type === 1 ? API.deleteScope1SetupAPI : API.deleteScope2SetupAPI;
  return fetch(URL+`?buildingId=${buildingId}&frameworkId=${frameworkId}&uploadedId=${uploadedId}`, {
    method: "DELETE",
    headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        "Access-Control-Allow-Origin": "*",
    },
  })
  .then((res) => res.json())
  .then((data) => callback(null, data))
  .catch((err) => callback(err.toString(), null));
}

export const fetchScope0Data = async(params,callback) => {
  return fetch(API.getScope0API(params), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",

      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
    },
  }).then((res) => res.json())
  .then((data) => callback(null, data))
  .catch((err) => callback(err.toString(), null));
}

export const fetchUpdateScope0Data = async(params, data, callback) => {
  return fetch(API.updateScope0API(params), {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify(data)
  }).then((res) => res.json())
  .then((data) => callback(null, data))
  .catch((err) => callback(err.toString(), null));
}

export const fetchEqDataByDeviceType = async(params, data, callback) => {
  return fetch(API.getEqDataByDeviceAPI(params), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",

      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({listDeviceType:data})
  }).then((res) => res.json())
  .then((data) => callback(null, data))
  .catch((err) => callback(err.toString(), null));
}


export const getS1EmissionVal = async(params,callback) => {
  return fetch(API.getS1EmissionValAPI(params),{
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
    },
  }).then((res) => res.json())
  .then((data) => callback(null,data))
  .catch((err) => callback(err.toString(), null));
}

export const fetchFile = async (bodyData, callback) => {
  return fetch(API.fetchFileDownload, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
      responseType: "blob",
    },
    body: JSON.stringify({ bodyData }),
  })
    .then((res) => callback(null, res))
    .catch((err) => callback(err.toString(), null));
};