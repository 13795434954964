import { Trans } from 'react-i18next';

const makeNsTrans = (namespace) => {
    return function(props) {
        let {children, ...others} = props;
        return <Trans {...others} ns={namespace}>
            {children}
        </Trans>
    }
};

export default makeNsTrans;