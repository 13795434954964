import { Colors } from "../../Assets/Config/color.config";
import { ProgressBar } from "../../Tools/ProgressBar";
import site from "../../Assets/site.json";
import RadarChart from "../../Assets/Charts/RadarChart";
import { inject, observer } from "mobx-react";
import { useEffect } from "react";
import { useTranslation } from 'react-i18next';

export const SitePerformance = inject("store")(
  observer((props) => {
    const { rightArrow } = props;
    const { permittedBuilding, theme, demoSitePerformanceData } = props.store.common;
    const width = window.innerWidth
    const { t } = useTranslation("dashboard");
    let values = [];

    useEffect(() => {
      permittedBuilding && permittedBuilding.map((value, key) => values.push(Math.floor(Math.random() * 100) + 1))
    }, []);

    return (
      <div className="cardStyle col" style={{ color: Colors[theme].textColor,marginBottom:15 }}>
        <span className="d-flex justify-content-between">
          <span className="cardTitle">{ t("Site Performance") }</span>
          <span style={{ color: Colors.water }}>
            { t("See More") }
            <img src={rightArrow} className="ps-1" />
          </span>
        </span>
        <hr className="my-2" />
        <div style={{ height: 170, overflowY: 'scroll' }}>
          {
            permittedBuilding ? permittedBuilding.map((value, key) => (
              <div className="d-flex justify-content-between py-2" key={key}>
                <span className="col">{value.buildingName}</span>
                <span className="col text-end">
                  <ProgressBar width={width > 1400 ? '60%' : '67%'} progress={demoSitePerformanceData[value.buildingName] || 0 } />
                </span>
              </div>
            )) : <div className="text-center"><i className="spinner-border mt-5" /></div>
          }
        </div>
        <div>
          {/* <span className="cardTitle">Average Emission</span> */}

          {/* <RadarChart /> */}
        </div>
      </div>
    );
  })
);
