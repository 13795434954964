import { Cookies } from "react-cookie";
import { Colors } from "../../Assets/Config/color.config";
import { MyButton, MyInput, Selector } from "../../Tools";
import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import { toJS } from "mobx";
import { MyFileInput } from "../../Tools";
import { useTranslation } from 'react-i18next';

const SiteMangementForm = (props) => {
    const {
        editFormList,
        existingFloors,
        newFloorInput,
        _handleCloseForm,
        _handleInputChange,
        _handleSubmitUpdate,
        _handleAddExistingFloors,
        _handleNewFloorInput,
        _handleRemoveExistingFloors,
        selectedSiteType,
        _handleSiteTypeChange,
        siteImage,
        _handleChangeSiteImage
    } = props.store.siteManagement;
    const [] = useState();
    const { siteTypeOption } = props;
    const { theme } = props.store.common;

    const cookies = new Cookies();
    const currentUserId = cookies.get("userId");
    const { t } = useTranslation("site_management");

    return (
        <div
            className="p-3"
            style={{ background: theme === 'dark' ? Colors[theme].secondBackground : Colors.Dark[5], borderRadius: 5, boxShadow: '2px 4px 11px rgba(0,0,0,0.25)', borderRadius: 5 }}
        >
            <div className="d-flex justify-content-start px-3">
                <h5 className="p-0 m-0">
                    { t("Edit Site") }
                </h5>
            </div>
            <div
                className="col-11 mx-auto mb-3 py-2"
                style={{ borderBottom: "1px solid #919191" }}
            >
                <div className="row d-flex align-items-center my-2">
                    <div className="col-4 fw-bold">
                        { t("Name") }
                    </div>
                    <div className="col">
                        <MyInput
                            required={true}
                            id={"buildingName"}
                            type={"text"}
                            value={editFormList.buildingName}
                            onChange={(e) => _handleInputChange(e)}
                            placeHolder={ t("Site Name") }
                        />
                    </div>
                </div>
                <div className="row d-flex align-items-center my-2">
                    <div className="col-4 fw-bold">
                        { t("Type") }
                    </div>
                    <div className="col">
                        {/* <MyInput
                            required={true}
                            id={"siteType"}
                            type={"text"}
                            value={""}
                            onChange={(e) => _handleInputChange(e)}
                            placeHolder={"Site Name"}
                        /> */}
                        <Selector
                            menuListColor={Colors.Dark[0]}
                            options={siteTypeOption}
                            value={selectedSiteType || siteTypeOption.filter(v => v.value === editFormList.type)}
                            placeholder={ t("Select site type") }
                            _handleSelect={(e) => _handleSiteTypeChange(e)}
                        />
                    </div>
                </div>
                <div className="row d-flex align-items-center">
                    <div className="col-4 fw-bold">
                        { t("Image") }
                    </div>
                    <div className="col-8">
                        <MyFileInput
                            fontSize={"15px"}
                            fileType={"image/*"}
                            file={siteImage}
                            onChange={(e) => _handleChangeSiteImage(e)}
                        />
                    </div>
                </div>
                <div className="row d-flex align-items-center my-2 py-2 rounded"
                    style={{ background: theme === 'dark' ? Colors.primaryBule : Colors.blue, color: Colors.white }}
                >
                    <div className="col">{ t("Unit or Floor") }</div>
                    <div className="col d-flex justify-content-end">
                        <button className="btn btn-sm btn-primary py-0 px-1"
                            onClick={_handleAddExistingFloors}>
                            <i className="bi bi-plus fa-lg"></i>
                        </button>
                    </div>
                </div>
                {existingFloors.length > 0 &&
                    existingFloors.map((v, k) => (
                        <div className="row d-flex align-items-center" key={k}>
                            <div className="col-12">
                                <div className="d-flex flex-row py-1">
                                    <div className="col-11">
                                        <MyInput
                                            id={"floorOrUnit" + k}
                                            type={"text"}
                                            value={v.unitName}
                                            onChange={(e) => _handleNewFloorInput(e, k)}
                                            placeHolder={ t("Unit or Floor") }
                                        />
                                    </div>
                                    <div className="col-1">
                                        <button className="btn btn-sm btn-danger py-0 px-1 mx-1 my-2" onClick={() => _handleRemoveExistingFloors(k)}>
                                            <i className="bi bi-dash"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
            <div className="row d-flex my-1 justify-content-center">
                <MyButton
                    text={ t("Close") }
                    fontSize={15}
                    rounded
                    customColor={Colors.primaryBule}
                    style={{ width: "30%" }}
                    onClick={_handleCloseForm}
                />
                <MyButton
                    text={ t("Update") }
                    fontSize={15}
                    rounded
                    customColor={"#0d6efd"}
                    style={{ width: "30%", marginLeft: '10px' }}
                    onClick={_handleSubmitUpdate}
                />
            </div>
        </div>
    );
};

export default inject("store")(observer(SiteMangementForm));
