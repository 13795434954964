import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { inject, observer } from "mobx-react";
import { makeStyles } from "@material-ui/core";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { toJS } from "mobx";
import { baseURL, baseURLImage } from "../../API/URL";
import { Logger } from 'logging-library';
import { CustomErrorComponent } from 'custom-error';
import { useEffect } from "react";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { MyButton } from "../../Tools";
import "./preview-pdf.css";

function createData(
  time,
  framework,
  section,
  question,
  answer,
  attachedFile,
  remark,
  action,
  actionId,
  actionType,
  month,
  filePath,
  category
) {
  return {
    time,
    framework,
    section,
    question,
    answer,
    attachedFile,
    remark,
    action,
    actionId,
    actionType,
    month,
    filePath,
    category
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "time",
    numeric: false,
    disablePadding: false,
    label: "Time",
  },
  {
    id: "framework",
    numeric: false,
    disablePadding: false,
    label: "Framework",
  },
  {
    id: "section",
    numeric: false,
    disablePadding: false,
    label: "Section",
  },
  {
    id: "question",
    numeric: false,
    disablePadding: false,
    label: "Question",
  },
  {
    id: "answer",
    numeric: false,
    disablePadding: false,
    label: "Answer",
  },
  {
    id: "attachedFile",
    numeric: false,
    disablePadding: false,
    label: "Attached File",
  },
  {
    id: "remark",
    numeric: false,
    disablePadding: false,
    label: "Description",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
  },
];

function EnhancedTableHead(props) {
  const {
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    theme,
  } = props;
  const {t} = useTranslation("audit");
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const useStyles = makeStyles({
    root: {
      "&$active": {
        color: "#ffff !important",
        textShadow: "1px 1px #fff6",
      },
      "&:hover": {
        color: "#ffff !important",
        textShadow: "1px 1px #fff6",
      },
    },
    active: {}, // pseudo
  });
  const classes = useStyles();

  useEffect(()=> {
    //http://localhost:8000/upload/data/a01d5607-b61d-4a7e-859a-724e468ea0d0.pdf 
    //http://localhost:8000/upload/building/no_image.png
     
  }, [])

  return (
    <TableHead sx={{ background: theme === "dark" ? "#121839" : Colors.water, position:'sticky', top:0 , zIndex:10}}>
      <TableRow>
        {headCells.map((headCell, id) => (
          <TableCell
            sx={{
              color: "white",
              borderLeft: id !== 0 && `1px solid ${theme === 'dark' ? '#ffffff2b': '#dbdbdb'}`,
            }}
            key={headCell.id}
            align={headCell.numeric ? "right" : "center"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              classes={{ root: classes.root, active: classes.active }}
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {t(headCell.label)}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  // onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export const VerificationTable = inject("store")(
  observer((props) => {
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("framework");
    const [selected, setSelected] = React.useState([]);
    const { theme } = props.store.common;
    const {verificationData,_handleAuditActionChange,_handlePreviewAttachedFile, previewFile} = props.store.audit;
    const params = new URLSearchParams(window.location.search);
    const buildingId = params.get("buildingId");
    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    };
  
    const rows = verificationData?.map((v) =>{
      const ans = v.answerOption?.length > 0 ? (v.answerOption?.find(a=> v.answer===a?._id)?.text) : (v.answer);
     
     
      return createData(
        v.date ? moment(v.date).format("DD-MMM-YYYY") : '-',
        v.framework||'-',
        v.section||'-',
        v.question||'-',
        (ans !== null && ans !== undefined) ? `${ans} ${v.unit? `( ${v.unit} )`:''}` : '-',
        // v.answerOption?.find(a=>v.answerId===a?._id)?.text||'-',
        v.fileData?.fileName||'-',
        v.description||'-',
        v.surveyAnswerAudition,
        v.actionId,
        v.actionType,
        v.month,
        v.fileData?.filePath,
        v?.category
      )}
    );

    const MyHeader = (state, previousDocument, nextDocument) => {
      const {t} = useTranslation("audit");
      if (!state.currentDocument || state.config?.header?.disableFileName) {
        return null;
      }
      const action = {text: 'Download', id: 'download', onClick: ()=>{}, current: null, leftIcon: <i class="bi bi-download"></i>}
  
      return (
        <div className="row" color={invertDark(theme)}>
         <div className="col-2">

         
          <MyButton
                      text={t(action?.text)}
                      id={action?.id}
                      onClick={action?.onClick}
                      textAlign={"text-start"}
                      className={action?.current ? "shadow" : ""}
                      noTxtLight
                      style={{ width: "100%", fontSize: 14, fontWeight: "bold", color: (theme === "light" && action?.current) ? "#303030" : "#f8f9fa"}}
                     // customColor={action?.current ? selectedBtnColor : defaultBtnColor}
                      leftIcon={action?.leftIcon} />
</div>
                       {/* <div>{state.currentDocument.uri || ""}</div>
          <div>
            <button
              onClick={previousDocument}
              disabled={state.currentFileNo === 0}
            >
              Previous Document
            </button>
            <button
              onClick={nextDocument}
              disabled={state.currentFileNo >= state.documents.length - 1}
            >
              Next Document
            </button>
          </div> */}
          </div>

         
        
      );
    }

    const MyNoRenderer = ({ document, fileName }) => {
      const fileText = fileName || document?.fileType || "";
  
      
      if (fileText) {
        return (<div><iframe src={`https://docs.google.com/viewer?url=${fileText.fileName}&pid=explorer&efh=false&a=v&chrome=false&embedded=true`} width="580px" height="480px"></iframe></div>)
      }
    
      return <div>No Renderer Error!</div>;
    };

    const handleClick = (event, name) => {
      const selectedIndex = selected.indexOf(name);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }

      setSelected(newSelected);
    };

    
    if(verificationData)
    {return (
      <Box sx={{ width: "100%", boxShadow: '0 4px 4px rgba(0, 0, 0, 0.25)' }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <TableContainer sx={{maxHeight:'65vh', background: Colors[theme].background}}>
            <Table
              sx={{ minWidth: 750, background: Colors[theme].background }}
              aria-labelledby="tableTitle"
              size={"small"}
            >
              <EnhancedTableHead
                sx={{position:'sticky'}}
                theme={theme}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rows?.length}
              />
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy)).map(
                  (row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    const {t} = useTranslation("audit");
                    const tSurvey = useTranslation("survey");
                    const tMonth = useTranslation("month");
                    
                    return (
                      <>
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.time)}
                        role="checkbox"
                        tabIndex={-1}
                        key={index*300}
                      >
                        <TableCell
                          sx={{
                            color: invertDark(theme),
                            borderBottom: "0px solid white",
                            borderTop: `1px solid ${theme === 'dark' ? '#ffffff2b': '#dbdbdb'}`,
                            borderRight: `1px solid ${theme === 'dark' ? '#ffffff2b': '#dbdbdb'}`,
                          }}
                          align="center"
                        >
                          {row.time}
                        </TableCell>
                        <TableCell
                          sx={{
                            color: invertDark(theme),
                            borderBottom: "0px solid white",
                            borderTop: `1px solid ${theme === 'dark' ? '#ffffff2b': '#dbdbdb'}`,
                            borderRight: `1px solid ${theme === 'dark' ? '#ffffff2b': '#dbdbdb'}`,
                          }}
                          align="center"
                        >
                          {row.framework}
                        </TableCell>
                        <TableCell
                          sx={{
                            color: invertDark(theme),
                            borderBottom: "0px solid white",
                            borderTop: `1px solid ${theme === 'dark' ? '#ffffff2b': '#dbdbdb'}`,
                            borderRight: `1px solid ${theme === 'dark' ? '#ffffff2b': '#dbdbdb'}`,
                          }}
                          align="center"
                        >
                          {row.framework === "GHG" ? tSurvey.t(row.section) : row.section}
                        </TableCell>
                        <TableCell
                          sx={{
                            color: invertDark(theme),
                            borderBottom: "0px solid white",
                            borderTop: `1px solid ${theme === 'dark' ? '#ffffff2b': '#dbdbdb'}`,
                            borderRight: `1px solid ${theme === 'dark' ? '#ffffff2b': '#dbdbdb'}`,
                          }}
                          align="left"
                        >
                          {row.framework === "GHG" ? tSurvey.t(row.question.split(" : ")[0])+" : "+tMonth.t(row.question.split(" : ")[1]) : row.section}
                        </TableCell>
                        <TableCell
                          sx={{
                            color: invertDark(theme),
                            borderBottom: "0px solid white",
                            borderTop: `1px solid ${theme === 'dark' ? '#ffffff2b': '#dbdbdb'}`,
                            borderRight: `1px solid ${theme === 'dark' ? '#ffffff2b': '#dbdbdb'}`,
                          }}
                          align={row.answer === '-' ? "center" : "left"}
                        >
                          {row?.answer%1 > 0 ? Number(row?.answer)?.toFixed(2) : row.answer}
                        </TableCell>
                        <TableCell
                          sx={{
                            color: invertDark(theme),
                            borderBottom: "0px solid white",
                            borderTop: `1px solid ${theme === 'dark' ? '#ffffff2b': '#dbdbdb'}`,
                            borderRight: `1px solid ${theme === 'dark' ? '#ffffff2b': '#dbdbdb'}`,
                          }}
                          align="center"
                          onClick={()=> _handlePreviewAttachedFile(row.filePath,index)}
                        >
                          {row.attachedFile==='-'?'-':<div className="d-flex justify-content-center align-items-center">
                            <div
                              className="px-2 py-1 me-2"
                              style={{
                                background: "#00d2133d",
                                borderRadius: 15,
                                cursor: 'pointer'
                              }}
                              title="Click to preview"
                             
                            >
                              {row.attachedFile}
                            </div>
                            {/* <i className="bi bi-download" /> */}
                          </div>}
                        </TableCell>
                        <TableCell
                          sx={{
                            color: invertDark(theme),
                            borderBottom: "0px solid white",
                            borderTop: `1px solid ${theme === 'dark' ? '#ffffff2b': '#dbdbdb'}`,
                            borderRight: `1px solid ${theme === 'dark' ? '#ffffff2b': '#dbdbdb'}`,
                          }}
                          align="center"
                        >
                          {row.remark}
                        </TableCell>
                        <TableCell
                          sx={{
                            color: invertDark(theme),
                            borderBottom: "0px solid white",
                            borderTop: `1px solid ${theme === 'dark' ? '#ffffff2b': '#dbdbdb'}`,
                          }}
                          align={row.actionId ? "left" : "center"}
                        >
                          {
                            row.actionId ?
                            <>
                              <div><label style={{cursor:'pointer', color:Colors.greenMaterial,opacity:row.action!=='ACCEPT'&&0.7}}><input onChange={(e)=>_handleAuditActionChange(e,buildingId,row.actionId,row.actionType,row.month, row.category)} style={{cursor:'pointer'}} id="accept" value='ACCEPT'  className="me-2" name={index} type='radio' checked={row.action==='ACCEPT'}/><i>{t("Accept")}</i></label></div>
                              <div><label style={{cursor:'pointer', color:Colors.low,opacity:row.action!=='REJECT'&&0.7}}><input onChange={(e)=>_handleAuditActionChange(e,buildingId,row.actionId,row.actionType,row.month, row.category)} style={{cursor:'pointer'}} id="reject" value='REJECT'  className="me-2" name={index} type='radio' checked={row.action==='REJECT'}/><i>{t("Reject")}</i></label></div>
                              <div><label style={{cursor:'pointer',opacity:row.action!=='NOT_APPLICABLE'&&0.7}}><input onChange={(e)=>_handleAuditActionChange(e,buildingId,row.actionId,row.actionType,row.month, row.category)} style={{cursor:'pointer'}} id="notApplicable" value='NOT_APPLICABLE'  className="me-2" name={index} type='radio' checked={row.action==='NOT_APPLICABLE'}/><i>{t("Not Applicable")}</i></label></div>
                            </>
                            :
                            <>
                              <i
                                className="bi bi-exclamation-circle me-2"
                                style={{ fontSize: 13 }}
                              ></i>
                              Not Applicable
                            </>
                          }
                          
                        </TableCell>
                      </TableRow>
                      {
                        
                        previewFile?.index === index && previewFile.blockLink ?
                          <TableRow key={previewFile.blockLink}>
                            <TableCell align="center" colSpan={8} sx={{
                                color: invertDark(theme),
                                borderBottom: "0px solid white",
                                borderTop: `1px solid ${theme === 'dark' ? '#ffffff2b': '#dbdbdb'}`
                              }}>
                                {/* <iframe
                                  id="myFrame"
                                  src={previewFile?.blobLink}
                                  frameBorder="0"
                                  border="0"
                                  cellSpacing="0"
                                  style={{ borderStyle: "none", width: "100%", height: "450px" }}
                                ></iframe> */}
                                

                                <DocViewer 
                                  documents={[
                                    { uri: previewFile?.blockLink }
                                  ]}
                                  style={{height: 400}}
                                  theme={{
                                    primary: "red",
                                    secondary: "green",
                                    tertiary: theme === "dark" ? "#121839" : Colors.water,
                                    textPrimary: invertDark(theme),
                                    textSecondary: invertDark(theme),
                                    textTertiary: "cyan",
                                    disableThemeScrollbar: false,
                                  }}
                                  pluginRenderers={DocViewerRenderers}
                                  prefetchMethod="GET"
                                  config={{                                   
                                    header: {
                                      //overrideComponent: MyHeader,
                                      disableHeader: true,
                                      disableFileName: false,
                                      retainURLParams: false,
                                    },
                                    pdfZoom: {
                                      defaultZoom: 0.5, // 1 as default,
                                      zoomJump: 0.2, // 0.1 as default,
                                    },
                                  }}
                                />
                            </TableCell>
                          </TableRow>
                        : null
                      }
                      </>
                    );
                  }
                )}
                
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    );}
  })
);

const Preview = () => {
  
  return(
    <>
     {/* <PDFViewer
            document={{
                url: 'https://arxiv.org/pdf/quant-ph/0410100.pdf',
            }}
        /> */}
        {/* <iframe 
          className={'pdf'} 
          width="100%" 
          height="200" 
          frameborder="0" 
          scr
          src={`https://docs.google.com/gview?url=${'http://localhost:8000/upload/data/a01d5607-b61d-4a7e-859a-724e468ea0d0.pdf'}&embedded=true`}
        ></iframe> */}
    </>
    
  )
}
