import environmentLogo from "../../Assets/images/leaf.png";
import handshakeLogo from "../../Assets/images/handshake.png";
import socialLogo from "../../Assets/images/social.png";
import { IconCard } from "../../Tools";
import Store from "../../Store";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { useTranslation } from 'react-i18next';

export const EsgProgramme = (props) => {
  const { theme } = props;
  const { t } = useTranslation("target_setting");
  return (
    <div className="my-1">
      <span style={{ fontSize: 20, color: invertDark(theme) }}>{ t("ESG Programme") }</span>
      <div className="my-2 d-flex justify-content-start">
        <IconCard width={60} icon='leaf' label={t("Environmental")} shadow={'2px 4px 11px rgba(0,0,0,0.15)'} />
        <IconCard width={80} style={{opacity: 0.3}} icon='handshake' label={t("Social")} shadow={'2px 4px 11px rgba(0,0,0,0.15)'} />
        <IconCard width={70} style={{opacity: 0.3}} icon='social' label={t("Governance")} shadow={'2px 4px 11px rgba(0,0,0,0.15)'} />
      </div>
    </div>
  );
};
