import * as React from "react";
import { useNavigate } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { MyButton } from "../../Tools";
import { useTranslation } from "react-i18next";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { TableBody } from "@mui/material";
import "./groupview.css";
import { Colors } from "../../Assets/Config/color.config";
import { baseURLImage,noImageUrl } from "../../API/URL";
import { BuildingEditModal } from "./BuildingEditModal";
import SelectLocationModal from "../SpecialOneTime/SelectLocationModal";
import { GetBaselineModal } from "../SpecialOneTime/GetBaselineModal";
import {
  MyInput,
  MuiNonLabelSelector,
  MuiMultiSelector,
  MyDatepicker,
} from "../../Tools";
import { toast } from "react-toastify";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "td, th": {
    border: "1px solid #8C8C8C41",
    textAlign: "center",
    padding: "0px 5px",
    height: "60px",
  },
  th: {
    borderTop: 0,
  },
  td: {
    borderBottom: 0,
  },
}));

export const BuildingTable = inject("store")(
  observer((props) => {
    const { t } = useTranslation("groupView");
    const { editTypes, deviceTypeOption, listGroup, tableColumns,getAssetData } =
      props.store.groupmanagement;
    
    const {permittedBuilding,showBaseLineModal} = props.store.common;
    const {setEditMode} = props.store.building;
    
    const {
      data,
      theme,
      _handleTextChange,
      selectedView,
      _handleSelectChange,
    } = props;
    const navigate = useNavigate();


    return (
      <>
        {data ? (
          <TableContainer
            component={Paper}
            sx={{ backgroundColor: "transparent", border: 0, overflow: "auto", maxHeight: 280 }}
          >
            <Table aria-label="customized table" stickyHeader>
              <TableHead>
                <StyledTableRow>
                  {tableColumns
                    .filter((col) => col.cat === data.title)[0]
                    ?.dataFields.map((v, index) => {
                      return (
                        <TableCell
                          key={index}
                          className={
                            theme === "dark"
                              ? "table-header-cell-dark"
                              : "table-header-cell-white"
                          }
                          style={{
                            borderLeft: index === 0 ? 0 : "",
                            borderRight:
                              tableColumns.filter(
                                (col) => col.cat === data.title
                              )[0]?.dataFields.length ===
                              index + 1
                                ? 0
                                : "",
                            backgroundColor: Colors[theme].tableBgColor,
                          }}
                        >
                          {v.label}
                        </TableCell>
                      );
                    })}
                  <TableCell
                    className={
                      theme === "dark"
                        ? "table-header-cell-dark"
                        : "table-header-cell-white"
                    }
                    style={{
                      // borderLeft: index === 0 ? 0 : "",
                      borderRight: 0,
                      backgroundColor: Colors[theme].tableBgColor,
                    }}
                  ></TableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {data?.data.map((row, rowIndex) => {
                  const userCreatedBuilding = permittedBuilding?.find(p => p._id == row._id);
                  return (
                    <StyledTableRow key={rowIndex}>
                      {tableColumns
                        .filter((col) => col.cat === data.title)[0]
                        ?.dataFields.map((header, cellIndex) => {
                          return (
                            <TableCell
                              key={rowIndex + "-" + cellIndex}
                              className={
                                theme === "dark"
                                  ? "table-body-cell-dark"
                                  : "table-body-cell-white"
                              }
                              style={{
                                borderLeft: cellIndex === 0 ? 0 : "",
                                borderRight:
                                  tableColumns.filter(
                                    (col) => col.cat === data.title
                                  )[0]?.dataFields.length ===
                                  cellIndex + 1
                                    ? 0
                                    : "",
                                backgroundColor: "#020845",
                              }}
                            >
                              {
                                header.key == "buildingName" ?
                                (
                                  <div className="d-flex align-items-center justify-content-center">
                                    <img crossorigin="anonymous" src={baseURLImage + row.photo} style={{ width : 30 ,marginRight : 10}} />
                                    {row[header.key]}
                                  </div>
                                ) : (
                                  header.dataType == "multiSelectInput"
                                  ? (row[header.key] ?
                                  row[header.key]
                                  .map((r) => {
                                    if (header.key === "group") {
                                      return listGroup.find(
                                        (g) => g._id === r
                                      )?.groupName;
                                    }
                                    return r;
                                  })
                                  .join(", ") : null)
                                  : row[header.key]
                                )
                              }
                              {/* {header.dataType == "multiSelectInput"
                                ? row[header.key]
                                    .map((r) => {
                                      console.log("r", r);
                                      if (header.key === "group") {
                                        return listGroup.find(
                                          (g) => g._id === r
                                        ).groupName;
                                      }
                                      return r;
                                    })
                                    .join(", ")
                                : row[header.key]} */}
                            </TableCell>
                          );
                        })}
                      <TableCell
                        className={
                          theme === "dark"
                            ? "table-body-cell-dark"
                            : "table-body-cell-white"
                        }
                        style={{
                          borderRight: 0,
                          backgroundColor: "#020845",
                        }}
                      >
                        <div className="d-flex align-items-center justify-content-center">
                          {
                            userCreatedBuilding ? (
                              <>
                                <MyButton
                                  disabled={userCreatedBuilding ? false : true}
                                  onClick={() =>
                                    navigate(
                                      `/rapid-assessment/?site=${row._id}&name=${row.buildingName}`
                                    )
                                  }
                                  className="me-4 text-nowrap"
                                  text={"View"}
                                  customColor={"#031B7F"}
                                  leftIcon={(<i class="bi bi-eye"></i>)}
                                />
                                <span
                                  data-bs-toggle="modal"
                                  data-bs-target="#buildingEditModal"
                                >
                                  <MyButton
                                    onClick={() => setEditMode(row._id)}
                                    text={"Edit"}
                                    className="text-nowrap"
                                    customColor={"#252950"}
                                    leftIcon={(<i class="bi bi-pencil-square"></i>)}
                                  />
                                </span>
                              </>
                              
                            ) :
                            (
                              <>
                                <MyButton
                                  disabled={true}
                                  className="me-4 text-nowrap"
                                  text={"View"}
                                  customColor={"#031B7F"}
                                  data-toggle="tooltip" data-placement="top" title="You haven't permission to access! "
                                  leftIcon={(<i class="bi bi-eye"></i>)}
                                />
                                <MyButton
                                  disabled={true}
                                  text={"Edit"}
                                  className="text-nowrap"
                                  customColor={"#252950"}
                                  data-toggle="tooltip" data-placement="top" title="You haven't permission to access! "
                                  leftIcon={(<i class="bi bi-pencil-square"></i>)}
                                />
                              </>
                              
                            )
                          }
                          
                        </div>
                      </TableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : null}
        <BuildingEditModal
          listGroup={listGroup}
          getAssetData={getAssetData}
        />
        <SelectLocationModal />
        {showBaseLineModal&& <GetBaselineModal from='buildingRegistration'/>}
      </>
    );
  })
);
