export const MySwitch = ({active, onChange, title}) => {

  return (
    <div className="form-check form-switch" title={title}>
      <input
        className="form-check-input"
        type="checkbox"
        role="switch"
        id="flexSwitchCheckChecked"
        checked={active}
        onChange={onChange}
        style={{ cursor: 'pointer'}}
      />
    </div>
  );
};
