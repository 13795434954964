import * as React from "react";
import { inject, observer } from "mobx-react";
import { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { TableBody } from "@mui/material";
import "./groupview.css";
import { Colors } from "../../Assets/Config/color.config";
import { MyInput, MuiNonLabelSelector, MuiMultiSelector, MyDatepicker } from "../../Tools";
import { format } from "date-fns";
import { FileOverviewModal } from "./FileOverviewModal";
import { ForceDeleteConfirmDialog } from "./ForceDeleteConfirmDialog";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  'td, th': {
    border: '1px solid #8C8C8C41',
    textAlign: 'center',
    padding: '0px 5px',
    height: '60px',
  },
  'th': {
    borderTop: 0
  },
  'td': {
    borderBottom: 0
  }
}));

export const AssetTables = inject("store")(
  observer((props) => {
    const { t } = useTranslation("assetSetupAndGroup");
    const t1 = useTranslation("equipment");
    const t2 = useTranslation("transport");
    const t3 = useTranslation("common");
    const t4 = useTranslation("country");
    const { 
      minBaselineYear,
      siteModuleData,
      _handleGetYearOptions,
      yearOptions
    } = props.store.common;

    const { 
      editTypes, 
      listGroup, 
      tableColumns, 
      validationErr, 
      deleteAssetTbls, 
      _handleSelectDeleteRows, 
      deleteAssetRows , 
      _getCompanyFileList,
      companyFileList,
      _handleChangeCompanyFile,
      companyFile,
      _handleChangeCompanyFileName,
      _handleChangeRemark,
      _handleChangeCompanyYear,
      _handleCompanyFileUpload,
      _handleChangeCompanyFileType,
      _handleSelectedRowIndex,
      showForceDeleteConfirmDialog
    } = props.store.groupmanagement;

    const {
      activityTypeOptions,
      // yearOptions,
      vehicleTypeOptions,
      emissionFactorOptions,
      deviceTypeOptions,
      unitOptions,
      calculationApproachOptions,
      transportModeOptions,
      calculateTypeOptions,
      sourceDataList,
      _getEnergySourceList
    } = props.store.quickOrganizationSetUp;

    const { data, theme, _handleTextChange, selectedView, _handleSelectChange, selectedGroupId } = props;

    useEffect(() => {
      _getEnergySourceList();
      if (!siteModuleData) {
        return
      }
      const baselineYear = siteModuleData.baselineYear ?? minBaselineYear
      _handleGetYearOptions(baselineYear)
      _handleChangeCompanyYear(baselineYear)
    }, [_getEnergySourceList, _handleChangeCompanyYear, _handleGetYearOptions, minBaselineYear, siteModuleData]);

    data?.data.map(v => {
      if(!v.assetFile){
        v.assetFile = null;
        v.refFileName = "No File Uploaded";
      }
    });

    const isDeleteMode = deleteAssetTbls.includes(selectedGroupId + selectedView + data.title)

    return (
      <div className="py-2" style={{overflowX: "auto", padding: "10px"}}>
        {data ?
          (
            <TableContainer component={Paper} sx={{ backgroundColor: "transparent", border: 0, maxHeight: 300 }}>
              <Table aria-label="customized table" stickyHeader sx={{ minWidth: 450}}>
                <TableHead>
                  <StyledTableRow>
                    {
                      isDeleteMode ?
                        <TableCell className={theme === 'dark' ? 'table-header-cell-dark' : 'table-header-cell-white'} style={{backgroundColor: Colors[theme].tableBgColor, width: 50}} />
                        : null
                    }
                    {tableColumns.filter((col) => col.cat === data.title)[0]?.dataFields.map(
                      (v, index) => {
                        return (
                          <TableCell key={index} className={theme === 'dark' ? 'table-header-cell-dark' : 'table-header-cell-white'}
                            style={{ borderLeft: index === 0 ? 0 : '', borderRight: tableColumns.filter((col) => col.cat === data.title)[0]?.dataFields.length === index + 1 ? 0 : '', backgroundColor: Colors[theme].tableBgColor }}>
                            {t(v.label)}
                          </TableCell>
                        )
                      }
                    )}
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {editTypes.includes(selectedGroupId + selectedView + data.title) ?
                    data?.data.map((row, rowIndex) => {
                      return (
                        <StyledTableRow key={data.title + rowIndex}>
                          {tableColumns.filter((col) => col.cat === data.title)[0]?.dataFields.map((header, cellIndex) => {
                            return (
                              <TableCell key={rowIndex + '-' + cellIndex} className={(validationErr.findIndex(verr => verr === (data.title + "_" + row._id)) > -1) ? 'table-body-cell-invalid' : theme === 'dark' ? 'table-body-cell-dark' : 'table-body-cell-white'}
                                style={{ borderLeft: cellIndex === 0 ? 0 : '', borderRight: tableColumns.filter((col) => col.cat === data.title)[0]?.dataFields.length === cellIndex + 1 ? 0 : '' }}>
                                {
                                  header.dataType == "textInput" ?
                                    <MyInput
                                      style={{ minWidth: 100 }}
                                      required={true}
                                      id={data.title + rowIndex + cellIndex}
                                      value={row[header.key]}
                                      onChange={(e) => _handleTextChange(e, header.key, data.title, rowIndex, cellIndex)}
                                    />
                                  : header.dataType == "selectInput" && header.key === "fuelType" ?
                                    <MuiNonLabelSelector
                                      menuListColor={Colors.Dark[0]}
                                      tran={t1.t}
                                      options={(sourceDataList.filter(o => o.title === data.title))[0].list.filter(o => o.sourceKey === header.key)}
                                      value={(sourceDataList.filter(o => o.title === data.title))[0].list.find(v => v.label === row[header.key])?.label || ''}
                                      handleSelect={(e) => _handleSelectChange(e, header.key, data.title, rowIndex, cellIndex)}
                                    />
                                  : header.dataType == "selectInput" && header.key === "transportMode" ?
                                    <MuiNonLabelSelector
                                      menuListColor={Colors.Dark[0]}
                                      tran={t2.t}
                                      options={transportModeOptions?.sort((a, b) => b.label > a.label ? -1 : 0)}
                                      value={transportModeOptions.find(v => v.label === row[header.key])?.label || ''}
                                      handleSelect={(e) => _handleSelectChange(e, header.key, data.title, rowIndex, cellIndex)}
                                    />
                                  : header.dataType == "selectInput" && header.key === "vehicleType" ?
                                    data.title === "Mobile Combustion" ?
                                      <MuiNonLabelSelector
                                        menuListColor={Colors.Dark[0]}
                                        tran={t1.t}
                                        options={(vehicleTypeOptions.filter(vt => vt.fuelType === row["fuelType"]))?.sort((a, b) => b.label > a.label ? -1 : 0)}
                                        value={vehicleTypeOptions.find(v => v.label === row[header.key])?.label || ''}
                                        handleSelect={(e) => _handleSelectChange(e, header.key, data.title, rowIndex, cellIndex)}
                                      />
                                    :
                                      <MuiNonLabelSelector
                                        menuListColor={Colors.Dark[0]}
                                        tran={t2.t}
                                        options={(sourceDataList.filter(o => o.title === data.title))[0].list.filter(o => o.sourceKey === header.key && o.sourceType === row["transportMode"])}
                                        value={(sourceDataList.filter(o => o.title === data.title))[0].list.find(v => v.label === row[header.key] && v.sourceType === row["transportMode"])?.label || ''}
                                        handleSelect={(e) => _handleSelectChange(e, header.key, data.title, rowIndex, cellIndex)}
                                      />

                                  : header.dataType == "selectInput" && header.key === "unit" ?
                                    data.title === "Refrigerants and Fugitives" || data.title === "Stationary Combustion" ?
                                      <MuiNonLabelSelector
                                        menuListColor={Colors.Dark[0]}
                                        tran={t1.t}
                                        options={(unitOptions.filter(v => v.activityType === data.title))?.sort((a, b) => b.label > a.label ? -1 : 0)}
                                        value={unitOptions.find(v => v.label === row[header.key])?.label || ''}
                                        handleSelect={(e) => _handleSelectChange(e, header.key, data.title, rowIndex, cellIndex)}
                                      />
                                    : data.title === "Mobile Combustion" ?
                                      <MuiNonLabelSelector
                                        menuListColor={Colors.Dark[0]}
                                        tran={t1.t}
                                        options={(unitOptions.filter(v => v.activityType === row["activityType"]))?.sort((a, b) => b.label > a.label ? -1 : 0)}
                                        value={unitOptions.find(v => v.label === row[header.key])?.label || ''}
                                        handleSelect={(e) => _handleSelectChange(e, header.key, data.title, rowIndex, cellIndex)}
                                      />
                                    : data.title === "Imported energy (steam, heating, cooling and compressed air)" ?
                                      <MuiNonLabelSelector
                                        menuListColor={Colors.Dark[0]}
                                        tran={t1.t}
                                        options={(unitOptions.filter(v => v.activityType === 'scope2'))?.sort((a, b) => b.label > a.label ? -1 : 0)}
                                        value={unitOptions.find(v => v.label === row[header.key])?.label || ''}
                                        handleSelect={(e) => _handleSelectChange(e, header.key, data.title, rowIndex, cellIndex)}
                                      />
                                    :
                                      <MuiNonLabelSelector
                                        menuListColor={Colors.Dark[0]}
                                        tran={t1.t}
                                        options={(unitOptions.filter(v => v.activityType === 'scope3'))?.sort((a, b) => b.label > a.label ? -1 : 0)}
                                        value={unitOptions.find(v => v.label === row[header.key])?.label || ''}
                                        handleSelect={(e) => _handleSelectChange(e, header.key, data.title, rowIndex, cellIndex)}
                                      />
                                  : header.dataType == "selectInput" && header.key === "activityType" ?
                                    <MuiNonLabelSelector
                                      menuListColor={Colors.Dark[0]}
                                      tran={t1.t}
                                      options={(activityTypeOptions.filter(at => at.category === data.title))?.sort((a, b) => b.label > a.label ? -1 : 0)}
                                      value={activityTypeOptions.find(v => v.label === row[header.key])?.label || ''}
                                      handleSelect={(e) => _handleSelectChange(e, header.key, data.title, rowIndex, cellIndex)}
                                    />
                                  : header.dataType == "selectInput" && header.key === "equipmentType" ?
                                    <MuiNonLabelSelector
                                      menuListColor={Colors.Dark[0]}
                                      tran={t1.t}
                                      options={(deviceTypeOptions.filter(dt => dt.category === data.title))?.sort((a, b) => b.label > a.label ? -1 : 0)}
                                      value={deviceTypeOptions.find(v => v.label === row[header.key])?.label || ''}
                                      handleSelect={(e) => _handleSelectChange(e, header.key, data.title, rowIndex, cellIndex)}
                                    />

                                  : header.dataType == "selectInput" && header.key === "emissionFactor" ?
                                    <MuiNonLabelSelector
                                      menuListColor={Colors.Dark[0]}
                                      tran={t2.t}
                                      options={(emissionFactorOptions.filter(dt => dt.category === data.title))?.sort((a, b) => b.label > a.label ? -1 : 0)}
                                      value={emissionFactorOptions.find(v => v.label === row[header.key])?.label || ''}
                                      handleSelect={(e) => _handleSelectChange(e, header.key, data.title, rowIndex, cellIndex)}
                                    />

                                  : header.dataType == "selectInput" && header.key === "year" ?
                                    <MuiNonLabelSelector
                                      menuListColor={Colors.Dark[0]}
                                      options={yearOptions}
                                      value={yearOptions.find(v => v.value === row[header.key])?.label || ''}
                                      handleSelect={(e) => _handleSelectChange(e, header.key, data.title, rowIndex, cellIndex)}
                                    />
                                  : header.dataType == "selectInput" && header.key === "calculationApproach" ?
                                    <MuiNonLabelSelector
                                      menuListColor={Colors.Dark[0]}
                                      tran={t3.t}
                                      options={calculationApproachOptions?.sort((a, b) => b.label > a.label ? -1 : 0)}
                                      value={calculationApproachOptions.find(v => v.label === row[header.key])?.label || ''}
                                      handleSelect={(e) => _handleSelectChange(e, header.key, data.title, rowIndex, cellIndex)}
                                    />
                                  : header.dataType == "selectInput" && header.key === "calculateType" ?
                                    <MuiNonLabelSelector
                                      menuListColor={Colors.Dark[0]}
                                      options={calculateTypeOptions?.sort((a, b) => b.label > a.label ? -1 : 0)}
                                      value={calculateTypeOptions.find(v => v.label === row[header.key])?.label || ''}
                                      handleSelect={(e) => _handleSelectChange(e, header.key, data.title, rowIndex, cellIndex)}
                                    />

                                  : header.dataType == "selectInput" && header.key === "calculateMethod" ?
                                    <MuiNonLabelSelector
                                      menuListColor={Colors.Dark[0]}
                                      tran={row["calculationApproach"] === "Heat/Steam" ? t3.t : t4.t}
                                      options={(sourceDataList.filter(o => o.title === data.title))[0].list.filter(o => o.sourceKey === header.key && o.sourceType === row["calculationApproach"])}
                                      value={(sourceDataList.filter(o => o.title === data.title))[0].list.find(v => v.label === row[header.key] && v.sourceType === row["calculationApproach"])?.label || ''}
                                      handleSelect={(e) => _handleSelectChange(e, header.key, data.title, rowIndex, cellIndex)}
                                    />
                                  : header.key === "refFileName" ?
                                    <div
                                      data-bs-toggle="modal"
                                      data-bs-target="#fileOverviewModal"
                                      onClick={() => _handleSelectedRowIndex(rowIndex, data.title)}
                                      className={theme === 'dark' ? "refFileName-link-dark" : "refFileName-link-white"}>
                                        {companyFileList?.find(file => file._id == row.assetFile)?.fileName || t("No File Uploaded")}
                                    </div>
                                  : header.dataType == "dateInput" ?
                                    <MyDatepicker 
                                      value={row[header.key]}
                                      _handleChange={(e) => _handleTextChange(e, header.key, data.title, rowIndex, cellIndex)}
                                    />
                                  : header.dataType == "multiSelectInput" ?
                                    <MuiMultiSelector
                                      style={{ width: '200px' }}
                                      menuListColor={Colors.Dark[0]}
                                      options={listGroup.filter(gp => gp._id !== -1 && gp._id !== -2).map(gp1 => ({ label: gp1.groupName, id: gp1._id }))}
                                      value={row[header.key]}
                                      isMulti
                                      placeholder={"Select a group"}
                                      _handleChange={(e) =>
                                        _handleSelectChange(e, header.key, data.title, rowIndex, cellIndex)
                                      }
                                    />
                                    : row[header.key]
                                }
                              </TableCell>
                            )
                          })}
                        </StyledTableRow>
                      )
                    })
                    :
                    data?.data.map((row, rowIndex) => {
                      return (
                        <StyledTableRow key={rowIndex}>
                          {
                            isDeleteMode ?
                              <TableCell className={theme === 'dark' ? 'table-body-cell-dark' : 'table-body-cell-white'} style={{ backgroundColor: "#020845" }} >
                                <input 
                                  type={'checkbox'} 
                                  checked={deleteAssetRows.includes(row._id)}
                                  onChange={(e)=> _handleSelectDeleteRows(row._id, e.target.checked)}
                                />
                              </TableCell>
                              : null
                          }
                          {tableColumns.filter((col) => col.cat === data.title)[0]?.dataFields.map((header, cellIndex) => {
                            console.log("header====>",header.key)
                            return (
                              <TableCell key={rowIndex + '-' + cellIndex} className={theme === 'dark' ? 'table-body-cell-dark' : 'table-body-cell-white'}
                                style={{ borderLeft: cellIndex === 0 ? 0 : '', borderRight: tableColumns.filter((col) => col.cat === data.title)[0]?.dataFields.length === cellIndex + 1 ? 0 : '', backgroundColor: "#020845" }}>
                                {
                                  header.dataType == "multiSelectInput" ? (
                                    row[header.key].map(r => {
                                      if (listGroup.find((g) => g._id === r))
                                        return listGroup.find((g) => g._id === r).groupName;
                                    }).join(", ")
                                  ) : header.key === "commissionedDate" ?
                                    (row[header.key] ? format(new Date(row[header.key]), "dd-MMM-yyyy") : '')
                                    : header.key === "refFileName" ?
                                      companyFileList?.find(file => file._id == row.assetFile)?.fileName || t("No File Uploaded")
                                    : data.title === "Upstream T&D" ? t2.t(row[header.key]) : header.key === "calculateMethod" ? t4.t(row[header.key]) : t1.t(row[header.key])
                                }
                              </TableCell>
                            )
                          })}
                        </StyledTableRow>
                      )
                    })
                  }
                </TableBody>
              </Table>
            </TableContainer>
          )
          : null
        }
        <FileOverviewModal 
          companyFileList={companyFileList} 
          _getCompanyFileList={_getCompanyFileList} 
          _handleChangeCompanyFile={_handleChangeCompanyFile} 
          companyFile={companyFile} 
          _handleChangeCompanyFileName={_handleChangeCompanyFileName}
          _handleChangeRemark={_handleChangeRemark}
          _handleChangeCompanyYear={_handleChangeCompanyYear}
          yearOptions={yearOptions}
          _handleCompanyFileUpload={_handleCompanyFileUpload}
          _handleChangeCompanyFileType={_handleChangeCompanyFileType}
        />
        <ForceDeleteConfirmDialog 
          showDialog={showForceDeleteConfirmDialog}
        />
      </div>
    )
  }))