import { Fragment, useEffect } from "react";
import { inject, observer } from "mobx-react";
import { SurveySideBar } from "../../Components/Survey/SurveySidebar";
import { SurveyHeaderbar } from "../../Components/Survey/SurveyHeaderbar";
import { QuestionComponent } from "../../Components/Survey/QuestionComponent";
import { SurveySelector } from "../../Components/Survey/SurveySelector";
import { EquipmentSetup } from "../../Components/Survey/EquipmentSetup";
import ManualDataInput from "../../Components/Survey/ManualDataInput";
import { useTranslation } from 'react-i18next';
import { toJS } from "mobx";
import { AlertDialog } from "../../Components/Survey/AlertDialog";
import { Fab, Action } from 'react-tiny-fab';
import { Colors } from "../../Assets/Config/color.config";
import { FrameworkEditModal } from "../../Components/Survey/FrameworkEditModal";
import NoFramework from "../../Assets/images/no-framework.png";
import Link from '@mui/material/Link';

export const SurveyContainer = inject("store")(
  observer((props) => {
    const { surveyInfo, getAllSurvey, surveyFramework, reset, isSetup, isManualSetup, frameWorkIndex, showDialog,saveMoveToNext, discardMoveToNext, onDiscardClick } =
      props.store.survey;
    const params = new URLSearchParams(window.location.search);

    const { theme } = props.store.common;
    const { t } = useTranslation("survey");
    
    //const surveyHeaderId = params.get("survey");
    const buildingId = params.get("site");
    const width = window.innerWidth;
    const { i18n } = useTranslation();
    const frameworkId = surveyFramework?.[frameWorkIndex]?.framework_id;

    useEffect(() => {
      getAllSurvey(buildingId);
      //getAllSection(buildingId);//need to fixed building
      return () => {
        reset();
      };
    }, [getAllSurvey, buildingId, reset, i18n.resolvedLanguage]);

    return (
      <>
      {frameworkId === undefined?
      <Fragment>
        <div style={{padding: 30 }}>
          <div style={{textAlign: "center", paddingTop: "15%", height: "70vh", background: theme === "dark" ? Colors.darkBlue : '#eee',
          borderRadius: 10,
          boxShadow: Colors.shadow,}}> <img
          className="pe-2 pt-2"
          width={"60px"}
          src={NoFramework}
          alt={t("Digital Edge Icon")}
        />
        <span style={{color: theme === "dark" ? "#ccc" : '#000'}}>{t("There is no framework")}</span> 
        <Link href="#" underline="none" data-bs-toggle="modal" data-bs-target="#frameworkEditModal">{t("Add Framework")}</Link>
        </div>
        </div>   
        <Fab mainButtonStyles={{ width: 50, height: 50, backgroundColor: Colors.water }} style={{ bottom: 0, left: 0 }} icon={<i className="bi bi-gear-fill"></i>} alwaysShowTitle={false}>             
          <Action style={{ backgroundColor: Colors.water, color: Colors.white }} text={t("Add Framework")} data-bs-toggle="modal" data-bs-target="#frameworkEditModal">
            <i className="bi bi-file-earmark-plus" />
          </Action>
        </Fab>
        {<FrameworkEditModal  buildingId={buildingId} />}
      </Fragment>:
      <Fragment>
        <AlertDialog 
          showDialog={showDialog}
          saveMoveToNext={()=> saveMoveToNext(frameworkId,buildingId)}
          discardMoveToNext={discardMoveToNext}
          onDiscardClick={onDiscardClick}
        />
        {width < 700 && <SurveySelector />}
        {surveyInfo.surveySection && (
          <div className="row d-flex flex-nowrap m-0">
            {surveyFramework && (
              <div style={{ width: 100, padding: 0 }}>
                <SurveyHeaderbar />
              </div>
            )}
            <div className="col-11 d-flex justify-content-center">
              {width > 700 && (
                <div className="col-3 pe-2">
                  <SurveySideBar />
                </div>
              )}

              {/* {!isSetup ?  
                (<div
                  className={
                    width > 700
                      ? "col-9 col-sm-8 col-lg-8 col-xl-9 col-xs-9 pe-2"
                      : " col-9 pe-2"
                  }
                >
                  <QuestionComponent />
                </div>)
                : (
                  <EquipmentSetup />
                )
              } */}
              {
                isSetup ?
                <EquipmentSetup />
                :
                isManualSetup ?
                (<div
                  className={
                    width > 700
                      ? "col-9 col-sm-8 col-lg-8 col-xl-9 col-xs-9 pe-2"
                      : " col-9 pe-2"
                  }
                >                  
                  <ManualDataInput />
                  </div>
                )
                :
                (<div
                  className={
                    width > 700
                      ? "col-9 col-sm-8 col-lg-8 col-xl-9 col-xs-9 pe-2"
                      : " col-9 pe-2"
                  }
                >
                  <QuestionComponent />
                </div>)
              }
            </div>
          </div>
        )}
        {/* <SurveyAlertModal/> */}
      </Fragment>
      }
    </>
    );
  })
);

// <div className="text-center" style={{ opacity: 0.5, marginTop: 150 }}>
//   <span>
//     <i
//       className="bi bi-file-earmark-text-fill"
//       style={{ fontSize: 50, color: Colors.primaryBule }}
//     />
//   </span>
//   <br />
//   Please select a section to start survey.
// </div>
