import { inject, observer } from "mobx-react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import EquipmentCheckList from "../../Components/QuickOrganizationSetUp/EquipmentCheckList";
import EquipmentQtySetup from "../../Components/QuickOrganizationSetUp/EquipmentQtySetup";
import { EquipmentListDetails } from "../../Components/QuickOrganizationSetUp/EquipmentListDetails";
import { StepPagination } from "../../Components/QuickOrganizationSetUp/StepPagination";
import { Colors } from "../../Assets/Config/color.config";
import { MyButton } from "../../Tools";
import { useTranslation } from "react-i18next";
import Industry from "../../Components/QuickOrganizationSetUp/Industry";
import { toJS } from "mobx";
import SetUpGuide from "../../Components/QuickOrganizationSetUp/SetUpGuide";
import { Cookies } from "react-cookie";
import ConfirmBackModal from "../../Components/QuickOrganizationSetUp/ConfirmBackModal";

export const QuickOrganizationSetUpContainer = inject("store")(
    observer((props) => {
        const { selectedPageIndex, _handleSetPageIndex, selectedEquipments , checkedIndustry, _handleAddOrganizationSetUp } = props.store.quickOrganizationSetUp;
        const navigate = useNavigate();
        const { theme, siteModuleData, _handleGetTableColumns, tableColumns, groupStatus } = props.store.common;
        const cookies = new Cookies();
        const userName = cookies.get("userName");
        const { t } = useTranslation("assetSetupAndGroup");

        useEffect(() => {
            _handleGetTableColumns();
        },[]);

        return (
            <div className="container">
                <div className="cardStyle"
                    style={{
                        backgroundColor: Colors[theme].active,
                        color: Colors[theme].textColor,
                        borderRadius: 15
                    }}>
                    <div className="row py-2 mx-4 px-3" style={{ color: Colors[theme].textColor }}>
                        <h4 className=""><span><i className="bi bi-building px-2"></i>{t("Company Information Setup")}</span></h4>
                    </div>
                    <div className="row justify-content-center py-3">
                        <StepPagination steps={[
                            'Setup Guide',
                            'Which industry are you in?',
                            'Which equipments emit C02 in your company/organization?',
                            'Selected Equipment',
                            'Equipment List',
                        ]} />
                    </div>
                    <div className="d-flex justify-content-center px-3">
                        <div className="container">
                            {/* each page's component */}
                            {
                                selectedPageIndex === 0 ? <SetUpGuide />
                                : selectedPageIndex === 1 ?  <Industry />  
                                : selectedPageIndex === 2 ? <EquipmentCheckList />
                                : selectedPageIndex === 3 ? <EquipmentQtySetup />
                                : <EquipmentListDetails tableColumns={tableColumns[0]?.dataColumns} />
                            }
                        </div>
                    </div>
                    <div className="d-flex justify-content-end px-3 pt-3">
                        <div className="mx-1" >
                            {
                                selectedPageIndex === 0 ?
                                    <MyButton
                                        style={{ width: 150 }}
                                        text={t("Setup Later")}
                                        customColor={Colors.water}
                                        onClick={() => navigate(groupStatus ? "/home-group": "/home")}
                                        // onClick={()=> { userName === 'Kumo Admin' ? navigate("/home-group") : navigate("/home") }}
                                    />
                                    : selectedPageIndex !== 0 ?
                                    <MyButton
                                        style={{ minWidth: 150 }}
                                        text={t("BACK")}
                                        leftIcon={<i className="bi bi-arrow-left" />}
                                        customColor={Colors.water}
                                        dataToggle={selectedPageIndex === 4 ? "modal": null}
                                        dataTarget={selectedPageIndex === 4 ? "#confirmBackModal" : null}
                                        onClick={()=> selectedPageIndex === 4 ? null : _handleSetPageIndex("Back")}
                                    />
                                    : null
                            }
                        </div>
                        {
                            selectedPageIndex < 4 ?
                            <MyButton
                                disabled={
                                    selectedPageIndex === 1 ? (checkedIndustry === null ? true : false)
                                        : selectedPageIndex === 2 ? selectedEquipments.length > 0 ?
                                            (selectedEquipments?.map(m => m.equipments.length > 0)?.some(s => s === false) ? true : false)
                                        : true
                                            : selectedPageIndex === 3 ? (false)
                                                : false
                                }
                                onClick={() => _handleSetPageIndex("Next")}
                                style={{ width: 150 }}
                                rightIcon={<i className="bi bi-arrow-right" />}
                                text={t("NEXT")}
                                customColor={Colors.water}
                            />:
                            <MyButton
                                disabled={
                                    selectedPageIndex === 1 ? (checkedIndustry === null ? true : false)
                                        : selectedPageIndex === 2 ? selectedEquipments.length > 0 ?
                                        (selectedEquipments?.map(m => m.equipments.length > 0)?.some(s => s === false) ? true : false)
                                        : true
                                            : selectedPageIndex === 3 ? (false)
                                                : false
                                }
                                onClick={() => _handleAddOrganizationSetUp(navigate, siteModuleData._id)}
                                style={{ width: 150 }}
                                rightIcon={<i className="bi bi-arrow-right" />}
                                text={ t("FINISH")}
                                customColor={Colors.water}
                            />
                        }
                        
                    </div>
                </div>
                <ConfirmBackModal />
            </div>

        );
    })
);
 