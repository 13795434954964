import * as API from "./URL";

export const FetchLogin = async (data, callback) => {
  return fetch(API.loginAPI, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      email: data.eMail,
      password: data.password,
    }),
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const FetchRegister = async (data, callback) => {
  return fetch(API.signupAPI, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      userName: data.userName,
      email: data.email,
      password: data.password,
      role: data.role,
      buildingId: data.buildingId,
      unit: data["unit/floor"],
      companyName: data.companyName,
      units: data.units
    }),
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const FetchUsers = async (selfEditMode, callback) => {
  const url = selfEditMode === false ? API.userListApi+"?selfEditMode=false" : API.userListApi;
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const FetchDeleteUser = async (id, callback) => {
  return fetch(API.deleteUserApi(id), {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const FetchEditUser = async (data, callback) => {
  return fetch(API.editUserApi, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      _id: data._id,
      userName: data.userName,
      email: data.email,
      password: data.password,
      role: data.role,
      buildingId: data.buildingId,
      frameworks: data.frameworks,
      unit: data["unit/floor"],
      companyName: data.companyName,
      units: data.units
    }),
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const FetchVerifyOTP = async (otp, callback) => {
  return fetch(API.verifyOTP, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      otp,
    }),
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const FetchResendOTP = async ( callback) => {
  return fetch(API.resendOTP, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const fetchActivityLog = async (status,callback) => {
  return fetch(API.getActivityLogAPI+"?all="+status?.all, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const fetchGroupReports = async (year,callback) => {
  return fetch(API.getGroupReportsAPI(year), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const fetchY2yEnergy = async (callback) => {
  return fetch(API.getY2yEnergy, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const fetchY2yS1Emissions = async (callback) => {
  return fetch(API.getY2yS1Emissions, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const fetchY2yS2Emissions = async (callback) => {
  return fetch(API.getY2yS2Emissions, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const fetchY2yS3Emissions = async (callback) => {
  return fetch(API.getY2yS3Emissions, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const fetchY2yWater = async (callback) => {
  return fetch(API.getY2yWater, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const fetchSiteModule = async (callback) => {
  return fetch(API.getSiteModule, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
}