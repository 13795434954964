import { inject, observer } from "mobx-react";
import { Colors, invertDark } from "../../Assets/Config/color.config";

export const KPIListTable = inject("store")(
    observer((props) => {
        const { theme } = props.store.common;
        const {_handleEditKPIModal, kpiManagements} = props.store.kpiManagement;
        return (
            <div style={{ fontSize: 14, color: invertDark(theme) }}>
                <h5
                    className="pb-2"
                    style={{
                        color: theme === "dark" ? Colors.Dark[5] : Colors.Dark[0],
                    }}
                >KPI List</h5>
                <div className="px-3">
                    <div className="row fw-bold py-2" style={{ color: theme === 'dark' ? Colors.Dark[5] : Colors.Dark[0] }}>
                        <span className="col-1">ESG Pillars</span>
                        <span className="col-2">Materiality Topics/ESG Sub-Pillars</span>
                        <span className="col-1">Base Year</span>
                        <span className="col-2">Objectives</span>
                        <span className="col-2">KPIs</span>
                        <span className="col-1">KPI Owner</span>
                        <span className="col-1">Key Dependencies</span>
                        <span className="col">Target</span>
                        <span className="col"></span>
                    </div>
                    {kpiManagements?.length > 0 ?
                        kpiManagements.map((v,k) => (
                            <div
                                key={k}
                                className="row py-1"
                                style={{
                                    borderTop:
                                        theme === "dark"
                                            ? "1px solid rgb(255,255,255,10%)"
                                            : "1px solid rgb(0,0,0,10%)",
                                }}
                            >
                                {
                                    <>
                                        <span className="col-1">{v.pillar.pillarName}</span>
                                        <span className="col-2">{v.subPillar.subPillarName}</span>
                                        <span className="col-1">{v.baseYear.yearName}</span>
                                        <span className="col-2">{v.objectives}</span>
                                        <span className="col-2">{v.kpi}</span>
                                        <span className="col-1">{v.kpiOwnerTitle?.ownerTitle}, {v.kpiOwnerName?.owner}</span>
                                        <span className="col-1">{v.keyDependency.dependencyName}</span>
                                        <span className="col">{v.establishedTarget}</span>
                                        <span 
                                        onClick={()=> _handleEditKPIModal(v)}
                                        data-bs-toggle="modal"
                                        data-bs-target="#kpiEditModal"
                                        className="col text-end"
                                        style={{ cursor: 'pointer' }}>
                                            <i className="bi bi-pencil-square" />
                                        
                                        </span>
                                    </>
                                }
                            </div>
                        ))
                        : (<div>
                            <i className="spinner-border mt-2 mx-auto d-flex" />
                        </div>)
                    }
                </div>
            </div>
        );
    })
);

const KPIListData = [
    {
        pillar: "Respect for Resources",
        subPillar: "GHG Emissions",
        baseYear: "2020",
        objectives: "Be a carbon-neutral data center organization by 2030",
        kpi: "GHG Protocol Scope 1 and 2: Measure and reduce absolute",
        kpiOwner: "VP Operations, Thiam Poh",
        keyDependencies: "ESG Director, Site Ops"
    },
    {
        pillar: "Respect for Resources",
        subPillar: "GHG Emissions",
        baseYear: "2020",
        objectives: "Be a carbon-neutral data center organization by 2030",
        kpi: "GHG Protocol Scope 1 and 2: Measure and reduce absolute",
        kpiOwner: "VP Operations, Thiam Poh",
        keyDependencies: "ESG Director, Site Ops"
    },
    {
        pillar: "Respect for Resources",
        subPillar: "GHG Emissions",
        baseYear: "2020",
        objectives: "Be a carbon-neutral data center organization by 2030",
        kpi: "GHG Protocol Scope 1 and 2: Measure and reduce absolute",
        kpiOwner: "VP Operations, Thiam Poh",
        keyDependencies: "ESG Director, Site Ops"
    }
];
