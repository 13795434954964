import { action, makeObservable, observable, runInAction, toJS } from "mobx";
import { toast } from "react-toastify";
import { kpiManagementFormValidation } from "../util/formValidation";
import {
  createKPIManagement,
  updateKPIManagement,
  fetchAllKPIManagements,
  deleteKPIManamgement,
  fetchBaseYears,
  fetchKPIOwnerTitles,
  fetchKPIOwnerNames,
  fetchUnitOfMeasurement,
  fetchFreqOfMeasurement,
  fetchKeyDependency,
  fetchStandardAlignment,
  getKPIByPillar,
} from "../API/api.kpiManagement";
import { fetchAllPillars } from "../API/api.esg";
import { Colors } from "../Assets/Config/color.config";

class KPIManagement {
  kpiManagements = [];

  createKpiList = {
    pillar: null,
    subPillar: null,
  };
  expandKpiList = {
    kpi: "",
    objectives: "",
    dataSource: "",
    targetSet: "",
    baseYear: null,
    ownerTitle: null,
    ownerName: null,
    status: "",
    unit: null,
    frequency: null,
    dependencies: null,
    standards: null,
    notes: "",
    progress: "",
    result: ""
  };
  createKpiListEdit = {
    pillar: null,
    subPillar: null,
  };
  editKpiData = {
    kpi: "",
    objectives: "",
    dataSource: "",
    targetSet: "",
    baseYear: null,
    ownerTitle: null,
    ownerName: null,
    status: "",
    unit: null,
    frequency: null,
    dependencies: null,
    standards: null,
    notes: "",
  };
  esgPillarOption = [
    { value: 1, label: "option 1" },
    { value: 2, label: "option 2" },
    { value: 3, label: "option 3" },
  ];
  subPillarOption = [
    { value: 1, label: "option 1" },
    { value: 2, label: "option 2" },
    { value: 3, label: "option 3" },
  ];
  targetOption = [
    { value: 1, label: "Yes" },
    { value: 0, label: "No" },
  ];
  baseYearOption = [];
  ownerTitleOption = [];
  ownerNameOption = [];
  unitOption = [];
  frequencyOption = [];
  dependenciesOption = [];
  standardsOption = [];
  isExpand = false;
  isExpandEdit = true;
  actionMode = null;
  kpiByPillar = [];

  pillars = [];

  pillarId = "";
  subPillarId = "";
  pillarOption = [];

  pillar = {};
  selectedPillarIndex = {};

  kpiListEdit = {};

  editKpi;

  sankeyData = [
    // {
    //   sdg_id: 4,
    //   sdg_title: "GHG Emissions",
    //   color: Colors.Chart_Color[0],
    //   sector_id: 2,
    //   group_name: "0",
    //   sector_name: "Scope 1 and 2",
    //   value: 1,
    //   result: 1,
    // },
    // {
    //   sdg_id: 4,
    //   sdg_title: "GHG Emissions",
    //   color: Colors.Chart_Color[0],
    //   sector_id: 2,
    //   group_name: "0",
    //   sector_name: "Emission reduction and Measurement",
    //   value: 1,
    //   result: 1.1,
    // },
    // {
    //   sdg_id: 4,
    //   sdg_title: "GHG Emissions",
    //   color: Colors.Chart_Color[0],
    //   sector_id: 2,
    //   group_name: "1",
    //   sector_name: "Energy Measurement",
    //   user_id: 1,
    //   value: 1,
    //   result: 2,
    // },
  ];

  constructor(props) {
    makeObservable(this, {
      kpiByPillar: observable,
      createKpiListEdit: observable,
      createKpiList: observable,
      expandKpiList: observable,
      isExpandEdit: observable,
      editKpiData: observable,
      esgPillarOption: observable,
      subPillarOption: observable,
      targetOption: observable,
      isExpand: observable,
      baseYearOption: observable,
      ownerTitleOption: observable,
      ownerNameOption: observable,
      unitOption: observable,
      frequencyOption: observable,
      dependenciesOption: observable,
      standardsOption: observable,
      actionMode: observable,
      kpiManagements: observable,
      pillar: observable,
      pillarOption: observable,
      sankeyData: observable,
      kpiListEdit: observable,

      pillars: observable,
      pillarId: observable,

      _handleInputChange: action.bound,
      _handleSelectChange: action.bound,
      _handleCreateKpi: action.bound,
      _handleRadioChange: action.bound,
      _handleConfirmReset: action.bound,
      _handleEditKPIModal: action.bound,
      _handleCloseKPIModal: action.bound,
      _createKPIManagement: action.bound,
      _updateKPIManagement: action.bound,
      _deleteKPIManagement: action.bound,
      _fetchAllKPIManagement: action.bound,
      _fetchBaseYears: action.bound,
      _fetchKPIOwnerNames: action.bound,
      _fetchKPIOwnerTitles: action.bound,
      _fetchUnitOfMeasurement: action.bound,
      _fetchFreqOfMeasurement: action.bound,
      _fetchStandardAligment: action.bound,
      _fetchKeyDependencies: action.bound,
      _fetchAllPillars: action.bound,
      _handlePillarChange: action.bound,
    });
  }

  _handleCreateKpi = () => {
    if (this.createKpiList.pillar && this.createKpiList.subPillar) {
      runInAction(() => {
        this.isExpand = true;
      });
    } else {
      if (this.createKpiList.pillar === null) {
        toast.error("Please select ESG Pillar.");
      } else if (this.createKpiList.subPillar === null) {
        toast.error("Please select ESG Sub Pillar.");
      }
    }
  };
  _handleRadioChange = (e) => {
    const newObj = { ...this.expandKpiList };

    newObj.targetSet = e.target.value;
    runInAction(() => {
      this.expandKpiList = newObj;
    });
  };
  _handleInputChange = (e, type, k) => {
    const key = k;
    const val = e.target.value;
    const newObj =
      type === "expandForm"
        ? { ...this.expandKpiList }
        : { ...this.createKpiList };

    if (key === "kpi") {
      newObj["kpi"] = val;
    } else if (key === "objectives") {
      newObj["objectives"] = val;
    } else if (key === "dataSource") {
      newObj["dataSource"] = val;
    } else if (key === "status") {
      newObj["status"] = val;
    } else if (key === "notes") {
      newObj["notes"] = val;
    } else if (key == "establishedTarget") {
      newObj["establishedTarget"] = val;
    } else if (key === "progress") {
      newObj["progress"] = val;
    } else if (key === "result") {
      newObj["result"] = val;
    }

    runInAction(() => {
      if (type === "expandForm") {
        this.expandKpiList = newObj;
      } else {
        this.createKpiList = newObj;
      }
    });
  };
  _handleSelectChange = (e, type) => {
    const createObj = { ...this.createKpiList };
    const expandObj = { ...this.expandKpiList };

    if (type === "pillar") {
      createObj["pillar"] = e.value;
    } else if (type === "subPillar") {
      createObj["subPillar"] = e.value;
    } else if (type === "baseYear") {
      expandObj["baseYear"] = e.value;
    } else if (type === "ownerTitle") {
      expandObj["ownerTitle"] = e.value;
    } else if (type === "ownerName") {
      expandObj["ownerName"] = e.value;
    } else if (type === "unit") {
      expandObj["unit"] = e.value;
    } else if (type === "frequency") {
      expandObj["frequency"] = e.value;
    } else if (type === "dependencies") {
      expandObj["dependencies"] = e.value;
    } else if (type === "standards") {
      expandObj["standards"] = e.value;
    }
    runInAction(() => {
      if (createObj !== this.createKpiList) {
        this.createKpiList = createObj;
      }
      if (expandObj !== this.expandKpiList) {
        this.expandKpiList = expandObj;
      }
    });
  };

  _handleConfirmReset = () =>
  (this.expandKpiList = {
    kpi: "",
    objectives: "",
    dataSource: "",
    targetSet: "",
    baseYear: "",
    ownerTitle: "",
    ownerName: "",
    status: "",
    unit: "",
    frequency: "",
    dependencies: "",
    standards: "",
    notes: "",
    progress: "",
    result: ""
  });

  _handleEditKPIModal = (data) => {
    this.editKpi = data;
    runInAction(() => {
      this.isExpandEdit = true;
      this.actionMode = "edit";
      this.kpiListEdit = {
        pillar: data.pillar.pillarName,
        subPillar: data.subPillar.subPillarName
      }

      this.createKpiList = {
        pillar: data.pillar._id,
        subPillar: data.subPillar._id,
      };
      
      this.expandKpiList = {
        _id: data._id,
        kpi: data.kpi,
        objectives: data.objectives,
        dataSource: data.dataSource,
        targetSet: data.target,
        baseYear: data.baseYear._id,
        ownerTitle: data.kpiOwnerTitle?._id,
        ownerName: data.kpiOwnerName?._id,
        status: data.status,
        unit: data.unitOfMeasurement._id,
        frequency: data.freqMeasurement._id,
        dependencies: data.keyDependency._id,
        standards: data.standardAlignment._id,
        notes: data.notes,
        establishedTarget: data.establishedTarget,
        progress: data.progress,
        result: data.result,
      };
    });
  };

  _handleCloseKPIModal = () => {
    runInAction(() => {
      this.isExpand = false;
      this.actionMode = null;
      this.expandKpiList = {
        ...this.expandKpiList,
        kpi: "",
        objectives: "",
        dataSource: "",
        targetSet: "",
        baseYear: "",
        ownerTitle: "",
        ownerName: "",
        status: "",
        unit: "",
        frequency: "",
        dependencies: "",
        standards: "",
        notes: "",
        establishedTarget: "",
        progress: "",
        result: ""
      };
    });
  };

  _handlePillarChange = (e, type) => {
    if (type === "edit") this.editPillar = e.value;
    else {
      this.pillar = e.label;
      this.pillarId = e.label;
      this.selectedPillarIndex = e.index;

      this.reloadChartData(e.value);
      //this.selectedKplInfo = kplChartData[this.pillar][0];
    }
  };

  _deleteKPIManagement = () => {
    deleteKPIManamgement(10, (err, data) => {
      if (err) {
        toast.error(err);
      } else {
        if (data.error) {
          toast.error(data.error);
        } else {
          toast.success(data.payload);
        }
      }
    });
  };

  _fetchAllKPIManagement = () => {
    fetchAllKPIManagements((err, data) => {
      if (err) {
        toast.error(err);
      } else {
        if (data.error) {
          toast.error(data.error);
        } else {
          
          this.kpiManagements = data.payload;
        }
      }
    });
  };

  _updateKPIManagement = () => {
    const kpiManagementData = {
      kpi: this.expandKpiList.kpi,
      objectives: this.expandKpiList.objectives,
      dataSource: this.expandKpiList.dataSource,
      target: this.expandKpiList.targetSet,
      baseYear: this.expandKpiList.baseYear,
      kpiOwnerTitle: this.expandKpiList.ownerTitle,
      kpiOwnerName: this.expandKpiList.ownerName,
      status: this.expandKpiList.status,
      unitOfMeasurement: this.expandKpiList.unit,
      freqMeasurement: this.expandKpiList.frequency,
      keyDependency: this.expandKpiList.dependencies,
      standardAlignment: this.expandKpiList.standards,
      notes: this.expandKpiList.notes,
      establishedTarget: this.expandKpiList.establishedTarget,
      progress: this.expandKpiList.progress,
      result: this.expandKpiList.result,
    };

    const ValidatedErr = kpiManagementFormValidation(kpiManagementData);

    if (ValidatedErr.pillarErr) {
      toast.error(ValidatedErr.pillarErr);
    } else if (ValidatedErr.subPillarErr) {
      toast.error(ValidatedErr.subPillarErr);
    } else if (ValidatedErr.kpiErr) {
      toast.error(ValidatedErr.kpiErr);
    } else if (ValidatedErr.objectivesErr) {
      toast.error(ValidatedErr.objectivesErr);
    } else if (ValidatedErr.dataSourceErr) {
      toast.error(ValidatedErr.dataSourceErr);
    } else if (ValidatedErr.targetSetErr) {
      toast.error(ValidatedErr.targetSetErr);
    } else if (ValidatedErr.baseYearErr) {
      toast.error(ValidatedErr.baseYearErr);
    } else if (ValidatedErr.ownerTitleErr) {
      toast.error(ValidatedErr.ownerTitleErr);
    } else if (ValidatedErr.ownerNameErr) {
      toast.error(ValidatedErr.ownerNameErr);
    } else if (ValidatedErr.unitErr) {
      toast.error(ValidatedErr.unitErr);
    } else if (ValidatedErr.dependenciesErr) {
      toast.error(ValidatedErr.dependenciesErr);
    } else if (ValidatedErr.standardsErr) {
      toast.error(ValidatedErr.standardsErr);
    } else if (ValidatedErr.frequencyErr) {
      toast.error(ValidatedErr.frequencyErr);
    } else {
      updateKPIManagement(kpiManagementData, this.editKpi._id, (err, data) => {
        if (err) {
          toast.error(err);
        } else {
          if (!data.error) {
            toast.success(data.payload);
            this._handleCloseKPIModal();
            this._fetchAllKPIManagement();



          } else {
            toast.error(data.error);
          }
        }
      });
    }
  };

  _createKPIManagement = () => {
    const kpiManagementData = {
      kpi: this.expandKpiList.kpi,
      objectives: this.expandKpiList.objectives,
      dataSource: this.expandKpiList.dataSource,
      target: this.expandKpiList.targetSet,
      baseYear: this.expandKpiList.baseYear,
      kpiOwnerTitle: this.expandKpiList.ownerTitle,
      kpiOwnerName: this.expandKpiList.ownerName,
      status: this.expandKpiList.status,
      unitOfMeasurement: this.expandKpiList.unit,
      freqMeasurement: this.expandKpiList.frequency,
      keyDependency: this.expandKpiList.dependencies,
      standardAlignment: this.expandKpiList.standards,
      notes: this.expandKpiList.notes,
      establishedTarget: this.expandKpiList.establishedTarget,
      progress: this.expandKpiList.progress,
      result: this.expandKpiList.result,
    };

    const ValidatedErr = kpiManagementFormValidation(kpiManagementData);

    if (ValidatedErr.pillarErr) {
      toast.error(ValidatedErr.pillarErr);
    } else if (ValidatedErr.subPillarErr) {
      toast.error(ValidatedErr.subPillarErr);
    } else if (ValidatedErr.kpiErr) {
      toast.error(ValidatedErr.kpiErr);
    } else if (ValidatedErr.objectivesErr) {
      toast.error(ValidatedErr.objectivesErr);
    } else if (ValidatedErr.dataSourceErr) {
      toast.error(ValidatedErr.dataSourceErr);
    } else if (ValidatedErr.targetSetErr) {
      toast.error(ValidatedErr.targetSetErr);
    } else if (ValidatedErr.baseYearErr) {
      toast.error(ValidatedErr.baseYearErr);
    } else if (ValidatedErr.ownerTitleErr) {
      toast.error(ValidatedErr.ownerTitleErr);
    } else if (ValidatedErr.ownerNameErr) {
      toast.error(ValidatedErr.ownerNameErr);
    } else if (ValidatedErr.unitErr) {
      toast.error(ValidatedErr.unitErr);
    } else if (ValidatedErr.dependenciesErr) {
      toast.error(ValidatedErr.dependenciesErr);
    } else if (ValidatedErr.standardsErr) {
      toast.error(ValidatedErr.standardsErr);
    } else if (ValidatedErr.frequencyErr) {
      toast.error(ValidatedErr.frequencyErr);
    } else {

      createKPIManagement(
        kpiManagementData,
        this.createKpiList.pillar,
        this.createKpiList.subPillar,
        (err, data) => {
          if (err) {
            toast.error(err);
          } else {
            if (!data.error) {
              toast.success(data.payload);
              this._handleConfirmReset();
              this.isExpand = false;
              this._fetchAllKPIManagement();

            } else {
              toast.error(data.error);
            }
          }
        }
      );
    }
  };

  _fetchBaseYears = () => {
    fetchBaseYears((err, data) => {
      if (err) {
        toast.error(err);
      } else {
        if (data.error) {
          toast.error(data.error);
        } else {
          data.payload?.map((v) => {
            this.baseYearOption.push({
              value: v._id,
              label: v.yearName,
            });
          });
        }
      }
    });
  };

  _fetchKPIOwnerTitles = () => {
    fetchKPIOwnerTitles((err, data) => {
      if (err) {
        toast.error(err);
      } else {
        if (data.error) {
          toast.error(data.error);
        } else {
          data.payload?.map((v) => {
            this.ownerTitleOption.push({
              value: v._id,
              label: v.ownerTitle,
            });
          });
        }
      }
    });
  };

  _fetchKPIOwnerNames = () => {
    fetchKPIOwnerNames((err, data) => {
      if (err) {
        toast.error(err);
      } else {
        if (data.error) {
          toast.error(data.error);
        } else {
          data.payload?.map((v) => {
            this.ownerNameOption.push({
              value: v._id,
              label: v.owner,
            });
          });
        }
      }
    });
  };

  _fetchUnitOfMeasurement = () => {
    fetchUnitOfMeasurement((err, data) => {
      if (err) {
        toast.error(err);
      } else {
        if (data.error) {
          toast.error(data.error);
        } else {
          data.payload?.map((v) => {
            this.unitOption.push({
              value: v._id,
              label: v.unitName,
            });
          });
        }
      }
    });
  };

  _fetchFreqOfMeasurement = () => {
    fetchFreqOfMeasurement((err, data) => {
      if (err) {
        toast.error(err);
      } else {
        if (data.error) {
          toast.error(data.error);
        } else {
          data.payload?.map((v) => {
            this.frequencyOption.push({
              value: v._id,
              label: v.unitName,
            });
          });
        }
      }
    });
  };

  _fetchKeyDependencies = () => {
    fetchKeyDependency((err, data) => {
      if (err) {
        toast.error(err);
      } else {
        if (data.error) {
          toast.error(data.error);
        } else {
          data.payload?.map((v) => {
            this.dependenciesOption.push({
              value: v._id,
              label: v.dependencyName,
            });
          });
        }
      }
    });
  };

  _fetchStandardAligment = () => {
    fetchStandardAlignment((err, data) => {
      if (err) {
        toast.error(err);
      } else {
        if (data.error) {
          toast.error(data.error);
        } else {
          data.payload?.map((v) => {
            this.standardsOption.push({
              value: v._id,
              label: v.standardName,
            });
          });
        }
      }
    });
  };

  _fetchAllPillars = () => {
    fetchAllPillars((err, data) => {
      if (err) {
        toast.error(err);
      } else {
        if (data.error) {
          toast.error(data.error);
        } else {
          this.pillarOption = [];
          data.payload?.map((v) => {
            this.pillarOption.push({
              value: v._id,
              label: v.pillarName,
            });
          });

          this.pillarId = this.pillarOption[0].label;
          this.pillar = this.pillarOption[0].label;
          this.reloadChartData(this.pillarOption[0].value);
        }
      }
    });
  };

  reloadChartData = (selectedPillarId) => {
    getKPIByPillar(selectedPillarId, (err, data) => {
      if (err) {
        toast.error(err);
      } else {
        if (data.error) {
          toast.error(data.error);
        } else {
          this.kpiByPillar = data.payload;

          //   data.payload?.map((v) => {

          //   });
          let nextColorIndex = 0;
          let collectedMap = new Map();
          data.payload.forEach((value) => {

            let kpi = value.kpi.substring(0, 40) + "...";
              let chartData = {
                id: value._id,
                sdg_id: value.subPillar._id,
                sdg_title: value.subPillar.subPillarName,
                color: Colors.Chart_Color[nextColorIndex],
                sector_id: value.pillar._id,
                group_name: "0",
                sector_name: kpi,
                value: 1,
                result: "",
                toSort: kpi.substring(kpi.indexOf("(") + 1, kpi.indexOf(")"))
              };
              nextColorIndex++;
              if (nextColorIndex === 8) {
                nextColorIndex = 0;
              }

              collectedMap.set(value.subPillar.subPillarName + value.kpi, chartData);

            // if (collectedMap.has(value.subPillar.subPillarName + value.kpi)) {
            //   let arr = collectedMap.get(
            //     value.subPillar.subPillarName + value.kpi
            //   );
            //   arr.value = arr.value + 1;
            //   collectedMap.set(value.subPillar.subPillarName + value.kpi, arr);
            // } else {
            //   let kpi = value.kpi.substring(0, 40) + "...";
            //   let chartData = {
            //     id: value._id,
            //     sdg_id: value.subPillar._id,
            //     sdg_title: value.subPillar.subPillarName,
            //     color: Colors.Chart_Color[nextColorIndex],
            //     sector_id: value.pillar._id,
            //     group_name: "0",
            //     sector_name: kpi,
            //     value: 1,
            //     result: "",
            //     toSort: kpi.substring(kpi.indexOf("(") + 1, kpi.indexOf(")"))
            //   };
            //   nextColorIndex++;
            //   if (nextColorIndex === 8) {
            //     nextColorIndex = 0;
            //   }

            //   collectedMap.set(value.subPillar.subPillarName + value.kpi, chartData);
            // }
          });          

          let collectedValues = [...collectedMap.values()]

          collectedValues.sort(function (a, b) {
            let af = a.toSort.toLowerCase();
            let bf = b.toSort.toLowerCase();

           
            if (af > bf) {
              return 1;
            }
            if (af < bf) {
              return -1;
            }
            return 0;
          });

         

          runInAction(() => {
            this.sankeyData = this.sankeyData.filter(v => false);
            this.sankeyData.push(...collectedValues);
          });

        }
      }
    });
  };
}

export default new KPIManagement();
