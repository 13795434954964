import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { toJS, transaction } from "mobx";
import { useEffect, Fragment, useState } from "react";
import { addNewBuilding, baseURL, baseURLImage } from "../../API/URL";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import building from "../../Store/building";
import allCountry from "../../Assets/countries.json";
import {
  IconCard,
  MyButton,
  MyInput,
  Selector,
  MyUploadImage,
  MuiMultiSelector
} from "../../Tools";

export const BuildingEditModal = inject("store")(
  observer((props) => {
    const { listGroup , getAssetData } = props;

      const {
        allBuildingType,
        getAccessmentFramework,
        getAllBuildingType,
        accessmentList,
        permittedBuilding,
        theme,
        showBaseLineModal
      } = props.store.common;
      const {
        setInputs,
        buildingName,
        buildingType,
        uploadLogoImage,
        _handleUploadImage,
        photo,
        framework,
        _handleCreateOneTimeSite,
        _handleFrameworkCheck,
        _resetImage,
        isDisabled,
        country,
        city,
        group,
        inputs,
        editMode,
        _handleEditBuilding,
        unit_floor,
        _handleAddUnit_Floor,
        _handleDeleteUnit_Floor,
        _handleGroup
      } = props.store.building;
  
      const { t } = useTranslation("one_time");

      const buildingTypeOptions = allBuildingType?.map((building) => ({
        value: building._id,
        label: building.building_type,
      }));
      const CountryOptions = allCountry?.map((country) => ({
        value: country.name,
        label: country.name,
        code: country.code.toLowerCase(),
      }));
  
      const activeCheckedStyle = {
        cursor: "pointer",
        opacity: 1,
      };
  
      const activeStyle = {
        cursor: "pointer",
        opacity: 0.7,
      };
      const noActiveStyle = {
        cursor: "pointer",
        opacity: 0.7,
      };

      useEffect(() => {
        getAccessmentFramework();
        getAllBuildingType();
      }, []);

      const _handleUpdate = async () => {

        await editMode === "" ? _handleCreateOneTimeSite(permittedBuilding) : _handleEditBuilding();
        document
              .getElementById("buildingEditModal")
              .setAttribute("data-bs-dismiss", "modal");
          let clickEvent = new MouseEvent("click", {
              view: window,
              bubbles: true,
              cancelable: false,
          });
          document
              .getElementById("buildingEditModal")
              .dispatchEvent(clickEvent);

          document
              .getElementById("buildingEditModal")
              .removeAttribute("data-bs-dismiss");
          
          getAssetData();
      }

    return (
      <div
        className={`modal fade ${theme}`}
        id="buildingEditModal"
        tabIndex="-1"
        aria-labelledby="buildingEditModal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div
            className="modal-content"
            style={{
              background: Colors[theme].background,
              borderRadius: 10,
            }}
          >
            <div className="modal-header">
              <h4 style={{ color: "rgb(198, 198, 198)" }}>
                {t("Edit Building")}
              </h4>
              <i
                // onClick={() => clearData()}
                style={{ cursor: "pointer", color: "rgb(198, 198, 198)" }}
                className="bi bi-x-lg "
                data-bs-dismiss="modal"
                aria-label="Close"
              ></i>
            </div>
            <div className="modal-body">
              <div
                className="d-flex row justify-content-between"
                style={{ color: invertDark(theme) }}
              >
                <div
                  id="site_info"
                  className="my-2 rounded"
                >
                  <div
                    className="p-3 me-2"
                    style={{
                      background: Colors[theme].active,
                      borderRadius: 5,
                    }}
                  >
                    <h5>{t("Site Information")}</h5>
                    <div className="row">
                      <div className="col-lg-3 col-sm-12 align-items-center">
                        <MyUploadImage
                          theme={theme}
                          _handleUploadImage={_handleUploadImage}
                          photo={photo}
                          _resetImage={_resetImage}
                          uploadLogoImage={uploadLogoImage}
                          width={"100%"}
                        />
                      </div>
                      <div className="col-lg-4 col-sm-12 align-items-center">
                        <span className="">
                          <MyInput
                            label={t("Site Name")}
                            required={true}
                            id={"buildingName"}
                            type={"text"}
                            value={buildingName}
                            onChange={(e) => setInputs("buildingName", e)}
                            placeHolder={t("Site Name")}
                          />
                        </span>
                        {/* <span className="col-lg-3 col-sm-12 p-0 m-0">Type</span> */}
                        <span className="">
                          <Selector
                            label={t("Country")}
                            placeholder={t("Select Country")}
                            menuListColor={Colors.Dark[0]}
                            options={CountryOptions}
                            value={CountryOptions?.filter(
                              (option) => option.value === country
                            )}
                            _handleSelect={(e) => setInputs("country", e)}
                          />
                        </span>
                        <span className="my-2">
                          <MyInput
                            label={t("Latitude")}
                            required={true}
                            id={"Latitude"}
                            type={"text"}
                            value={inputs.lat}
                            onChange={(e) => setInputs("Latitude", e)}
                            placeHolder={t("Latitude")}
                          />
                        </span>
                        <span>
                          <MyInput
                            label={t("Longtitude")}
                            required={true}
                            id={"Longtitude"}
                            type={"text"}
                            value={inputs.lng}
                            onChange={(e) => setInputs("Longtitude", e)}
                            placeHolder={t("Longtitude")}
                          />
                        </span>
                        <div
                          className="text-left"
                          style={{ cursor: "pointer" }}
                        >
                          {/* <MyButton
                      className="mt-3 w-200"
                      data-bs-toggle="modal" 
                    data-bs-target="#SelectLocationModal"
                      text={t("Select Location")}
                      customColor={Colors.water}
                    /> */}
                          <button
                            className="btn px-4 mt-3"
                            style={{
                              background: "rgb(41, 130, 255)",
                              color: "#fff",
                            }}
                            data-bs-toggle="modal"
                            data-bs-target="#SelectLocationModal"
                          >
                            {t("Select Location")}
                          </button>
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-12 align-items-center ms-lg-5 ms-xl-5">
                        <span className="my-2">
                          <Selector
                            label={t("Type")}
                            placeholder={t("Select Building Type")}
                            menuListColor={Colors.Dark[0]}
                            options={buildingTypeOptions}
                            value={buildingTypeOptions?.filter(
                              (option) => option.value === buildingType
                            )}
                            _handleSelect={(e) => setInputs("buildingType", e)}
                          />
                        </span>
                        <span className="">
                          <MyInput
                            label={t("City")}
                            required={true}
                            id={"city"}
                            type={"text"}
                            value={city}
                            onChange={(e) => setInputs("city", e)}
                            placeHolder={t("City")}
                          />
                        </span>
                        <div>
                          <span>Group:</span>
                          <span>
                            <MuiMultiSelector
                              menuListColor={Colors.Dark[0]}
                              options={listGroup.filter(gp => gp._id !== -1 && gp._id !== -2).map(gp1 => ({ label: gp1.groupName, id: gp1._id }))}
                              value={group? group : []}
                              isMulti
                              disabled={editMode == "" ? true : false}
                              placeholder={"Select a group"}
                              _handleChange={(e) =>
                                _handleGroup(e.target.value)
                              }
                            />
                          </span>
                        </div>
                        
                        <div>
                          <div className="d-flex justify-content-between align-items-center">
                            <div
                              style={{
                                fontSize: 12,
                                padding: "2px",
                                color: "rgb(198, 198, 198)",
                              }}
                            >
                              {"Unit/Floor"} :
                            </div>
                            <i
                              title={"Add Unit/Floor"}
                              className="bi bi-plus-square-fill me-1"
                              style={{
                                fontSize: 16,
                                color: "rgb(41, 130, 255)",
                                cursor: "pointer",
                              }}
                              onClick={_handleAddUnit_Floor}
                            ></i>
                          </div>
                          {unit_floor.map((v, k) => {
                            return (
                              <div
                                key={k}
                                className="d-flex justify-content-between align-items-center mb-1"
                              >
                                <div
                                  className=""
                                  style={{
                                    width:
                                      unit_floor?.length > 1 ? "90%" : "100%",
                                  }}
                                >
                                  <MyInput
                                    required={true}
                                    id={"unit_floor" + k}
                                    type={"text"}
                                    value={v}
                                    onChange={(e) =>
                                      setInputs("unit_floor", e, k)
                                    }
                                    placeHolder={t("Unit/Floor")}
                                  />
                                </div>
                                {unit_floor?.length > 1 ? (
                                  <i
                                    title="Delete Unit/Floor"
                                    className="bi bi-dash-square-fill mx-1"
                                    style={{
                                      fontSize: 16,
                                      color: Colors.low,
                                      cursor: "pointer",
                                    }}
                                    onClick={() => _handleDeleteUnit_Floor(k)}
                                  ></i>
                                ) : null}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="row m-0">
                      <span className="col" style={{}}>
                        <h6>{t("Select Frameworks")}</h6>
                      </span>
                    </div>
                    <div className="row m-0">
                      <div className="flex-col d-flex flex-wrap justify-content-start m-0 pt-3">
                        {accessmentList?.map((accessment, k) => {
                          let index = editMode
                            ? -1
                            : framework?.findIndex((v) => v === accessment._id);
                          return (
                            <span
                              // data-bs-toggle={accessment.name === 'GHG (Version 2)' &&"modal"}
                              // data-bs-target={accessment.name === 'GHG (Version 2)' &&"#getBaselineModal"}
                              className="ps-1 m-0"
                              key={k}
                              onClick={() =>
                                accessment.active
                                  ? _handleFrameworkCheck(accessment._id)
                                  : toast.error(accessment.status)
                              }
                              style={
                                index === -1
                                  ? accessment.active
                                    ? activeStyle
                                    : noActiveStyle
                                  : activeCheckedStyle
                              }
                              title={
                                accessment.surveyData.surveyHeader.headerName
                              }
                            >
                              <IconCard
                                cardSize={60}
                                width={30}
                                icon={accessment._id}
                                style={
                                  index === -1
                                    ? noActiveStyle
                                    : activeCheckedStyle
                                }
                                imgURL={baseURLImage + accessment.imageURL}
                                label={accessment.name}
                              />
                            </span>
                          );
                        })}
                      </div>
                    </div>
                    <div className="text-center" style={{ cursor: "pointer" }}>
                      <MyButton
                        disabled={isDisabled}
                        onClick={() =>
                          _handleUpdate()
                        }
                        className="col-3"
                        text={editMode !== "" ? "update" : t("Add")}
                        customColor={Colors.water}
                      />
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  })
);
