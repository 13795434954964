import { inject, observer } from "mobx-react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { MyButton, MyInput, Selector } from "../../Tools";
import { useTranslation } from "react-i18next";

export const UploadedFileDescriptionModal = inject("store")(
  observer((props) => {
    const {modalId,btnId} = props;

    const { t } = useTranslation("common");
    const { theme } = props.store.common;
    const { _handleSaveUploadedFile, _handleDescriptionChange, uploadDescription, selectedMonth, selectedYear} =
      props.store.survey;
    return (
      <div
        className={`modal fade text-dark ${theme}`}
        id={modalId}
        tabIndex="-1"
        aria-labelledby="uploadedFileDescriptionModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
      >
        <div className="modal-dialog modal-sm">
          <div
            className="modal-content"
            style={{
              background: Colors[theme].background,
              borderRadius: 10,
              color: invertDark(theme),
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title">{t("uploaded_file_description")}</h5>
            </div>
            <div className={`modal-body`}>
              <div className="row d-flex">
                <div className="col">
                  <MyInput
                    id={selectedMonth+"_"+selectedYear}
                    required={true}
                    isTextArea={true}
                    label={t("Description of the uploaded file")}
                    value={uploadDescription?uploadDescription:""}
                    onChange={(e) => _handleDescriptionChange(e, selectedMonth+"_"+selectedYear)}
                  />
                </div>
              </div>
            </div>
            <div className={`modal-footer mx-3 px-0 d-flex justify-content-center`}>
              <div className="d-flex justify-content-center">
                <MyButton
                  text={t("Cancel")}
                  rounded
                  fontSize={15}
                  dataDismiss={"modal"}
                  customColor={
                    theme === "dark" ? Colors.lightBlue : Colors.blue
                  }
                />               
                <div id={btnId} className="mx-2" />
                <MyButton
                  text={t("Save")}
                  customColor={Colors.water}
                  onClick={() => _handleSaveUploadedFile(btnId, modalId === "CateUploadedFileDescriptionModal")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  })
);
