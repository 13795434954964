import { action, makeObservable, observable, runInAction } from "mobx"
import { fetchLogHistory } from "../API/api.historyData"

class HistoryData {
    
    histroyTableData = []
    // {
    //     "_id": "63fedc96e983491af749608b",
    //     "companyName": "All",
    //     "detailName": "View 1",
    //     "updatedId": "6398352b41785b58a65c64c3",
    //     "updatedCategory": "Group",
    //     "framework": "GHG",
    //     "updatedValue": "{\"_id\":{\"$oid\":\"6398352b41785b58a65c64c3\"},\"groupName\":\"View 1\",\"company\":{\"$oid\":\"62e8a25373060f7017646d2d\"},\"userId\":{\"$oid\":\"62f081812cb4a66a8a916aef\"}}",
    //     "type": "Updated",
    //     "actionCreator": "User",
    //     "message": "Kumo Admin Updated - View 1 Group",
    //     "updatedDate": "2023-03-01T05:03:18.574Z",
    //     "__v": 0
    // }
    
    
    constructor(props){
        makeObservable(this, {
            histroyTableData: observable,
            _getHistoryData: action.bound
        })
    }

    _getHistoryData = () => {
        fetchLogHistory((err, data) => {
            if (data.payload) {
                runInAction(()=>{
                    this.histroyTableData = this.histroyTableData.filter(p=>false);
                    this.histroyTableData = [...data.payload]
                })
            }
        });
    }

}

export default new HistoryData();