import { Colors, invertDark } from "../../Assets/Config/color.config";
import { inject, observer } from "mobx-react";
import { MyButton, MyInput, FileUploadSelector, NumericInput } from "../../Tools";
import Stack from "@mui/material/Stack";
import SurveyFileUploadingModal from "../../Components/Survey/SurveyFileUploadingModal";
import { useTranslation } from "react-i18next";
import InputAdornment from "@mui/material/InputAdornment";
import Link from "@mui/material/Link";
import { toJS } from "mobx";
import Upload from "@mui/icons-material/Upload";
import "../../App.css";

export const ManualEntryDetailForm = inject("store")(
  observer((props) => {
    const {
      theme,
      manualEntryData,
      _handleManualInputChange,
      _handleAddManualEntry,
      selectedField,
      selectedYear,
      isNewVal,
      _handleResetManualEntry,
      selectedCarbonTab,
      manualEditMode,
      uploadedFiles,
      frameworkId,
      buildingId,
      selectedUnit,
    } = props;

    const { linkedFileId, linkedFileName, linkedFileMonth } =
      props.store.siteRelevant;

    const { _handleDescriptionChange, editedMonth, handleDataChange } =
      props.store.survey;

    const { t } = useTranslation("common");

    const auditionStatus = (data) => {
      const status =
        selectedField === "carbonEmission"
          ? selectedCarbonTab === "scope1Emission"
            ? data.scope1EmissionAudition
            : selectedCarbonTab === "scope2Emission"
            ? data.scope2EmissionAudition
            : data.scope3EmissionAudition
          : selectedField === "energyConsumption"
          ? data.energyConsumptionAudition
          : data.waterConsumptionAudition;
      return status;
    };

    const auditionStatusComp = (data) => {
      const comp =
        auditionStatus(data) === "ACCEPT" ? (
          <span className="text-success">
            <img
              src="/greenCertified.svg"
              style={{ width: "30px", height: "28px" }}
              alt="accept"
            />
          </span>
        ) : auditionStatus(data) === "NOT_APPLICABLE" ||
          auditionStatus(data) === undefined ? (
          <span className="text-warning py-2">N/A</span>
        ) : (
          <span className="text-danger py-2">
            <i className="bi bi-x-circle-fill"></i>
          </span>
        );

      return comp;
    };

    return (
      <div className="mx-2 m-3 px-1" style={{ color: invertDark(theme) }}>
        <div className="row d-flex flex-wrap align-items-center pb-2">
          {manualEntryData.map((data, key) => {
            const status = auditionStatus(data);
            return (
              <div key={key} className="col-lg-4 col-md-6 col-sm-12 p-1">
                <div
                  className="p-2"
                  style={{
                    border: `1px solid ${
                      status === "ACCEPT"
                        ? "#04c500"
                        : status === "NOT_APPLICABLE" || status === undefined
                        ? "#f0b505"
                        : "#b80000"
                    }`,
                    borderRadius: 5,
                  }}
                >
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={1}
                    style={{
                      padding: "5px",
                      fontSize: "12px",
                      background: theme === "dark" ? "#202b60" : "#ddd",
                    }}
                  >
                    <div className="d-flex w-100 justify-content-between">
                      <label className="py-2">{data.month}</label>
                      {auditionStatusComp(toJS(data))}
                    </div>
                    <Link
                      href="#"
                      underline="none"
                      style={{
                        color: "#fff",
                        background: "#2982ff",
                        padding: "0px 4px",
                        borderRadius: "2px",
                        display: editedMonth.includes(data.month)
                          ? "block"
                          : "none",
                      }}
                      onClick={() => _handleAddManualEntry()}
                    >
                      {t("Save")}
                    </Link>
                  </Stack>
                  <div style={{ width: "100%",position: 'relative' }}>
                    <label style={{ fontSize: "12px" }}>{t("Usage")}</label>
                    <p className="input-adornment-detail">{selectedUnit}</p>
                    <NumericInput
                      id={data.month + "-" + selectedField}
                      readOnly={!manualEditMode}
                      value={
                        selectedField === "carbonEmission"
                          ? (data[selectedCarbonTab] ? Number(data[selectedCarbonTab]) : 0 )
                          : (data[selectedField] ? Number(data[selectedField]) : 0)
                      }
                      onChange={(e) =>
                        _handleManualInputChange(
                          e,
                          selectedField === "carbonEmission"
                            ? selectedCarbonTab
                            : selectedField,
                          data.month
                        )
                      }
                    />
                  </div>
                  <div style={{ width: "100%" }} id={"uploaded_file_selector"}>
                    <label style={{ fontSize: "12px" }}>
                      {t("Data Validation File")}
                    </label>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={0}
                    >
                      <FileUploadSelector
                        width="200px"
                        id={data.month + "_" + selectedYear}
                        disabled={
                          !manualEditMode ||
                          (selectedField === "carbonEmission"
                            ? !data[selectedCarbonTab]
                            : !data[selectedField])
                        }
                        options={uploadedFiles}
                        menuListColor={Colors.Dark[0]}
                        value={
                          linkedFileId &&
                          linkedFileName &&
                          linkedFileMonth === data.month
                            ? { label: linkedFileName, value: linkedFileId }
                            : uploadedFiles.filter((v) =>
                                selectedField === "energyConsumption"
                                  ? v.value === data.energyLinkedFileId
                                  : selectedField === "waterConsumption"
                                  ? v.value === data.waterLinkedFileId
                                  : selectedField === "carbonEmission"
                                  ? selectedCarbonTab === "scope1Emission"
                                    ? v.value === data.scope1LinkedFileId
                                    : selectedCarbonTab === "scope2Emission"
                                    ? v.value === data.scope2LinkedFileId
                                    : selectedCarbonTab === "scope3Emission"
                                    ? v.value === data.scope3LinkedFileId
                                    : null
                                  : null
                              )
                        }
                        _handleSelect={(e) =>
                          handleDataChange(
                            e.value,
                            selectedField === "energyConsumption"
                              ? "energyLinkedFileId"
                              : selectedField === "waterConsumption"
                              ? "waterLinkedFileId"
                              : selectedField === "carbonEmission"
                              ? selectedCarbonTab.split("Emission")[0] +
                                "LinkedFileId"
                              : null,
                            data.month
                          )
                        }
                      />
                      <span
                        data-bs-toggle="modal"
                        data-bs-target="#surveyFileModal"
                        style={{ cursor: "pointer" }}
                      >
                        <button title={t("Browse your files")}
                          onClick={(e) =>
                            handleDataChange(
                              "uploadBtn",
                              selectedField === "energyConsumption"
                                ? "energyLinkedFileId"
                                : selectedField === "waterConsumption"
                                ? "waterLinkedFileId"
                                : selectedField === "carbonEmission"
                                ? selectedCarbonTab.split("Emission")[0] +
                                  "LinkedFileId"
                                : null,
                              data.month
                            )
                          }
                          style={{
                            border:
                              theme === "dark"
                                ? "1px solid #5e5e5e"
                                : "1px solid #c6c6c6",
                            minWidth: "36px !important",
                            maxWidth: "36px !important",
                            padding: "7px",
                            borderRadius: "5px",
                            background:
                              theme === "dark" ? "transparent" : "#fff",
                          }}
                        >
                          <Upload style={{ color: "rgb(4, 197, 0)" }} />
                        </button>
                      </span>
                    </Stack>
                  </div>
                  <label style={{ fontSize: "12px" }}>
                    {t("Description of the uploaded file")}
                  </label>
                  <MyInput
                    id={data.month + "__" + selectedField}
                    style={{ height: "43px", marginBottom: "10px" }}
                    disabled={
                      !manualEditMode ||
                      (selectedField === "carbonEmission"
                        ? !data[selectedCarbonTab]
                        : !data[selectedField])
                    }
                    required={true}
                    isTextArea={true}
                    value={
                      selectedField === "energyConsumption"
                        ? data.energyDescription
                        : selectedField === "waterConsumption"
                        ? data.waterDescription
                        : selectedCarbonTab === "scope1Emission"
                        ? data.scope1Description
                        : selectedCarbonTab === "scope2Emission"
                        ? data.scope2Description
                        : selectedCarbonTab === "scope3Emission"
                        ? data.scope3Description
                        : null
                    }
                    onChange={(e) =>
                      _handleDescriptionChange(
                        e,
                        selectedField === "carbonEmission"
                          ? selectedCarbonTab
                          : selectedField,
                        data.month
                      )
                    }
                  />
                  <SurveyFileUploadingModal
                    modalId={"surveyFileModal"}
                    frameworkId={frameworkId}
                    buildingId={buildingId}
                    btnId="save_file_and_description"
                  />
                </div>
              </div>
            );
          })}
        </div>
        {manualEditMode && <hr />}
        {manualEditMode && (
          <div className="d-flex justify-content-end pt-2">
            <MyButton
              text={t("Cancel")}
              rounded
              fontSize={15}
              onClick={() => _handleResetManualEntry()}
              customColor={theme === "dark" ? Colors.lightBlue : "#919191"}
            />
            <div className="mx-2" />
            <MyButton
              disabled={!isNewVal}
              text={t("Save Progress")}
              customColor={Colors.water}
              onClick={() => _handleAddManualEntry()}
            />
          </div>
        )}
      </div>
    );
  })
);
