import { inject, observer } from "mobx-react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { MyButton, MyInput, Selector, MyDatepicker } from "../../Tools";
import { useTranslation } from 'react-i18next';
import { useEffect } from "react";

export const Scope3SetUpModal = inject("store")(
    observer((props) => {
        const { _handleChangeDate } = props.store.survey;
        const { theme, siteModuleData, tableColumns, yearOptions, _handleGetYearOptions, minBaselineYear } = props.store.common;
        const { activeDialog, selectedGroupId, _handleChangeAssetData, listGroup, _handleCreateCompanySetup, companyAssetData, assetEmissionFactorValue, _handleGetEmissionByCalculatedMethod, clearData } = props.store.groupmanagement;
        const {
            unitOptions,
            transportModeOptions,
            sourceDataList,
            activityTypeOptions,
            _getEnergySourceList
          } = props.store.quickOrganizationSetUp;
        const { t } = useTranslation("transport");

        const scope = tableColumns[0]?.dataColumns.filter(c => c.cat === activeDialog)[0]?.scope;

        useEffect(() => {
            _getEnergySourceList();
            if(siteModuleData){
                if(siteModuleData.baselineYear){
                    _handleGetYearOptions(siteModuleData.baselineYear);
                }else{
                    _handleGetYearOptions(minBaselineYear);
                }                
            }
          }, []);

        useEffect(() => {        
            companyAssetData[0].companyAsset[0].assets[0].vehicleType = '';
        },[companyAssetData[0].companyAsset[0].assets[0].transportMode])

        useEffect(() => {
            if(
                activeDialog && scope === 3 &&
                companyAssetData[0].companyAsset[0].assets[0].vehicleType && 
                companyAssetData[0].companyAsset[0].assets[0].unit
            ){ 
                _handleGetEmissionByCalculatedMethod('scope3', activeDialog);
            }
        },[activeDialog, companyAssetData[0].companyAsset[0].assets[0].vehicleType, companyAssetData[0].companyAsset[0].assets[0].unit])

        return (
            <div
                className={`modal fade ${theme}`}
                id="scope3SetUpModal"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="addEquipmentModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-lg">
                    <div
                        className="modal-content"
                        style={{
                            background: Colors[theme].background,
                            borderRadius: 10,
                            color: invertDark(theme)
                        }}
                    >
                        <div className="modal-header">
                            <h4>{t("Add Data for Transportation")}</h4>
                            <i
                                onClick={() => clearData()}  
                                style={{ cursor: "pointer",color: 'rgb(198, 198, 198)' }}
                                className="bi bi-x-lg "
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></i>
                        </div>
                        <div className="modal-body">
                            <div className="row py-1">
                                <div className="col-lg-6 col-6 col-sm-12 px-2 py-sm-1">
                                    <div className="row d-flex">
                                        <div className="col">
                                            <Selector
                                                disabled={props.isNewDailyData}
                                                label={t("Year")}
                                                menuListColor={Colors.Dark[0]}
                                                options={yearOptions}
                                                value={yearOptions.filter(v => v.label === companyAssetData[0].companyAsset[0].assets[0].year)}
                                                placeholder={t("Select year")}
                                                _handleSelect={(e) => _handleChangeAssetData(3, selectedGroupId, activeDialog, e.label, "year")}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-6 col-sm-12 px-2 pt-sm-1">
                                    <div className="row d-flex">
                                        <div className="col">
                                            <Selector
                                                getOptionLabel={(e) => (
                                                    <div style={{ display: "flex", textAlign: "left" }}>                                                    
                                                    <span style={{ marginLeft: 5 }}>{t(e.label)}</span>
                                                    </div>
                                                )}
                                                disabled
                                                label={t("Category")}
                                                menuListColor={Colors.Dark[0]}
                                                options={categoryOption?.sort((a, b)=> b.label > a.label ? -1 : 0)}
                                                value={[{value: activeDialog, label: activeDialog}].filter((v) => v.label === activeDialog)}
                                                placeholder={t("Select Category")}                                                
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row py-1">
                                <div className="col-lg-6 col-6 col-sm-12 px-2 py-sm-1">
                                    <div className="row d-flex">
                                        <div className="col">
                                            <Selector
                                                getOptionLabel={(e) => (
                                                    <div style={{ display: "flex", textAlign: "left" }}>                                                    
                                                    <span style={{ marginLeft: 5 }}>{t(e.label)}</span>
                                                    </div>
                                                )}
                                                disabled={props.isNewDailyData}
                                                label={t("Mode of transport")}
                                                menuListColor={Colors.Dark[0]}
                                                options={transportModeOptions?.sort((a, b) => b.label > a.label ? -1 : 0)}
                                                value={transportModeOptions.filter(v => v.label === companyAssetData[0].companyAsset[0].assets[0].transportMode)}
                                                placeholder={t("Select Mode of transport")}
                                                _handleSelect={(e) => _handleChangeAssetData(3, selectedGroupId, activeDialog, e.label, "transportMode")}
                                                
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-6 col-sm-12 px-2 pt-sm-1">
                                    <div className="row d-flex">
                                        <div className="col">
                                            <Selector
                                                getOptionLabel={(e) => (
                                                    <div style={{ display: "flex", textAlign: "left" }}>                                                    
                                                    <span style={{ marginLeft: 5 }}>{t(e.label)}</span>
                                                    </div>
                                                )}
                                                disabled={props.isNewDailyData}
                                                label={t("Vehicle Type")}
                                                menuListColor={Colors.Dark[0]}
                                                options={(sourceDataList.filter(o => o.title === activeDialog))[0]?.list.filter(o => o.sourceKey === "vehicleType" && o.sourceType === companyAssetData[0].companyAsset[0].assets[0].transportMode)}
                                                value={(sourceDataList.filter(o => o.title === activeDialog))[0]?.list.filter(v => v.label === companyAssetData[0].companyAsset[0].assets[0].vehicleType && v.sourceType === companyAssetData[0].companyAsset[0].assets[0].transportMode)}
                                                placeholder={t("Select Vehicle Type")}
                                                _handleSelect={(e) => _handleChangeAssetData(3, selectedGroupId, activeDialog, e.label, "vehicleType")}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row py-1">
                                <div className="col-lg-6 col-6 col-sm-12 px-2 py-sm-1">
                                    <div className="row d-flex">
                                        <div className="col">
                                            <Selector
                                                getOptionLabel={(e) => (
                                                    <div style={{ display: "flex", textAlign: "left" }}>                                                    
                                                    <span style={{ marginLeft: 5 }}>{t(e.label)}</span>
                                                    </div>
                                                )}
                                                disabled={props.isNewDailyData}
                                                label={t("Activity Type")}
                                                menuListColor={Colors.Dark[0]}
                                                options={(activityTypeOptions.filter(v => v.category === activeDialog))?.sort((a, b) => b.label > a.label ? -1 : 0)}
                                                value={activityTypeOptions.filter(v => v.label === companyAssetData[0].companyAsset[0].assets[0].activityType)}
                                                placeholder={t("Activity Type")}
                                                _handleSelect={(e) => _handleChangeAssetData(3, selectedGroupId, activeDialog, e.label, "activityType")}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-6 col-sm-12 px-2 pt-sm-1">
                                    <div className="row d-flex">
                                        <div className="col">
                                            <Selector
                                                getOptionLabel={(e) => (
                                                    <div style={{ display: "flex", textAlign: "left" }}>                                                    
                                                    <span style={{ marginLeft: 5 }}>{t(e.label)}</span>
                                                    </div>
                                                )}
                                                disabled={props.isNewDailyData}
                                                label={t("Unit")}
                                                menuListColor={Colors.Dark[0]}
                                                options={(unitOptions.filter(v => v.activityType === 'scope3'))?.sort((a, b) => b.label > a.label ? -1 : 0)}
                                                value={unitOptions.filter(v => v.label === companyAssetData[0].companyAsset[0].assets[0].unit)}
                                                placeholder={t("Unit")}
                                                _handleSelect={(e) => _handleChangeAssetData(3, selectedGroupId, activeDialog, e.label, "unit")}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-6 col-sm-12 px-2 pt-sm-1">
                                    <div className="row d-flex">
                                        <div className="col">
                                            <MyInput                                            
                                                disabled={props.isNewDailyData}
                                                label={t("Name")}
                                                required={true}
                                                id={"name"}
                                                type={"text"}
                                                value={companyAssetData[0].companyAsset[0].assets[0].transportationName}
                                                onChange={(e) => _handleChangeAssetData(3, selectedGroupId, activeDialog, e.target.value, "transportationName")}
                                                placeHolder={t("Transportation Name")}
                                            />
                                        </div>
                                    </div>
                                </div> 
                                <div className="col-lg-6 col-6 col-sm-12 px-2 pt-sm-1">
                                    <div className="row d-flex">
                                        <div className="col">
                                            <MyInput
                                                disabled
                                                label={t("View")}
                                                id={"group"}
                                                type={"text"}
                                                value={listGroup.filter(g => g._id === selectedGroupId)[0]?.groupName}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-end mt-4">
                                <div className="col-lg-6 col-6 col-sm-12 px-2 pt-sm-1">
                                    <div className="row d-flex">
                                        <div className="col">
                                            <MyDatepicker
                                                label={t("Date")}
                                                _handleChange={(e) => _handleChangeDate(e, "transportation")}
                                                value={companyAssetData[0].companyAsset[0].assets[0].commissionedDate}
                                            />
                                        </div>
                                    </div>
                                </div> 
                                <div className="col-lg-6 col-6 col-sm-12 px-2 pt-sm-1">
                                    <div className="row d-flex">
                                        <div className="col">
                                            {
                                                activeDialog &&
                                                companyAssetData[0].companyAsset[0].assets[0].vehicleType && 
                                                companyAssetData[0].companyAsset[0].assets[0].unit
                                                ? 
                                                (
                                                    <MyInput
                                                        disabled={true}
                                                        label={"Emission Factor Value"}
                                                        required={true}
                                                        type={"number"}
                                                        id={"emissionFactorValue"}
                                                        value={assetEmissionFactorValue}
                                                        placeHolder={"Emission Factor Value"}
                                                    />                        

                                                ) : ''
                                            } 
                                        </div>
                                    </div>
                                </div>                                                                
                            </div>                              
                                                                  
                        </div>
                        <div className="modal-footer mx-3 d-flex justify-content-end">
                            <span data-bs-dismiss="modal" aria-label="Close">
                                <MyButton
                                    style={{ width: 200 }}
                                    text={t("Cancel")}
                                    customColor={theme === 'dark' ? Colors.primaryBule : '#888'}
                                    customHoverColor={theme === 'dark' ? Colors.lightBlue : '#aaa'}
                                    onClick={() => clearData()}
                                />
                            </span>
                            <span id={'create_asset_scope3'}>
                                <MyButton
                                    onClick={() => _handleCreateCompanySetup(siteModuleData._id, "create_asset_scope3")}
                                    style={{ width: 200 }}
                                    text={t("Add")}
                                    customColor="#066CFF"
                                    customHoverColor={Colors.water}
                                />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    })
);


const categoryOption = [
    { value: 1, label: "Upstream T&D" },
    { value: 2, label: "Business Travel" },
    { value: 3, label: "Employee Commute" },
];
