import { Colors, invertDark } from "../../Assets/Config/color.config";

export const ConfirmDelteModal = (props) => {
    const { handleDelete, theme } = props;

    return (
      <div
        className={`modal fade ${theme}`}
        id="setUpConfirmModal"
        tabIndex="-1"
        aria-labelledby="setUpConfirmModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div
            className="modal-content"
            style={{
              background: Colors[theme].secondBackground,
              borderRadius: 10,
              color: invertDark(theme),
            }}
          >
            <div className="modal-header mx-3 px-0">
              <h4 className="modal-title" id="setUpConfirmModalLabel">
                Confirm Delete
              </h4>
              <i
                style={{ cursor: "pointer" }}
                className="bi bi-x-lg "
                data-bs-dismiss="modal"
                aria-label="Close"
                // onClick={() => _handleClose()}
              ></i>
            </div>
            <div className="modal-body">Are you sure want to delete</div>
            <div className="modal-footer d-flex justify-content-center mx-3">
              <button
                type="button"
                className="btn col-2 text-white"
                style={{
                  background: theme === 'dark' ? Colors.darkBlue : '#888',
                }}
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                data-bs-dismiss="modal"
                className="btn col-2 text-white"
                style={{
                  background: "rgb(255 0 0 / 50%)",
                }}
                onClick={handleDelete}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    )
};
