import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import {
  MyFileInput,
  MyButton,
  MyInput,
  Selector,
  MuiNonLabelSelector,
} from "../../Tools";
import "./groupview.css";

export const FileOverviewModal = inject("store")(
  observer((props) => {
    const {
      _getCompanyFileList,
      companyFileList,
      _handleChangeCompanyFile,
      companyFile,
      _handleChangeCompanyFileName,
      _handleChangeRemark,
      _handleChangeCompanyYear,
      yearOptions,
      _handleCompanyFileUpload,
      _handleChangeCompanyFileType
    } = props;

    const {theme} = props.store.common;

    const {
      selectedRowIndexForUploadedFile,
      selectedTitleForUploadedFile,
      _handleChangeSelectedFile,
      selectedView,
      selectedYear,
      _handleCheckUploadedFile
    } = props.store.groupmanagement;

    const fileList = selectedView === "Consumption View" ? companyFileList.filter(f => f.fileType === companyFile.fileType) : companyFileList;

      const { t } = useTranslation("one_time");

    useEffect(() => {
      _getCompanyFileList();
    },[_getCompanyFileList])

    useEffect(() => {
      if(selectedView === "Consumption View"){
        _handleChangeCompanyFileType("Consumption Data");
        _handleChangeCompanyYear(selectedYear);
      }
    },[])

    return (
      <div
        className={`modal fade ${theme}`}
        id="fileOverviewModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="fileOverviewModal"
        aria-hidden="true"
        data-bs-focus="false"
      >
        <div className="modal-dialog" >
          <div
            className="modal-content"
            style={{
              background: Colors[theme].background,
              borderRadius: 10,
              width : "600px"
            }}
          >
            <div className="modal-header">
              <h4 style={{ color: "rgb(198, 198, 198)" }}>
                {t("File Overview")}
              </h4>
              <i
                style={{ cursor: "pointer", color: "rgb(198, 198, 198)" }}
                className="bi bi-x-lg"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></i>
            </div>
            <div className="modal-body">
            <div
                className="d-flex row justify-content-between"
                style={{ color: invertDark(theme) }}
              >
                <div
                  id="site_info"
                  className="my-2 rounded"
                >
                  <div
                    className="p-3"
                    style={{
                      background: Colors[theme].active,
                      borderRadius: 5,
                    }}
                  >
                    <div className="row">
                      <div className="col-4" style={{ borderRight : `1px solid ${theme === 'dark' ? 'rgb(255, 255, 255, 0.1)' : 'rgba(83, 83, 83, 0.2)'}`}}>
                          <div style={{borderBottom : "1px solid rgb(255, 255, 255, 0.5)"}} className="pb-2">
                              <p style={{color : invertDark(theme) , fontSize : 15}} className="fw-bold mb-0">{t("Choose Uploaded File")}</p>
                          </div>
                          <div className="fileList">
                            {
                              fileList?.map((f,i) => {
                                return (
                                  <div 
                                    className="w-100 py-2 ps-2 list d-flex justify-content-between align-items-center"
                                    style={{
                                      borderBottom: "1px solid rgb(255, 255, 255, 0.1)",
                                      cursor: 'pointer'
                                    }}
                                    >
                                    <div
                                      data-bs-dismiss="modal"
                                      id={f._id}
                                      key={f._id}
                                      style={{ fontSize: 14, color: invertDark(theme), float: 'left', width: '100%' }}
                                      className=""
                                      onClick={() => _handleChangeSelectedFile(f._id, f.fileName, selectedRowIndexForUploadedFile, selectedTitleForUploadedFile)}
                                    >
                                      {f.fileName}
                                    </div>
                                    <div style={{ float: 'right', width: 20, fontSize: 16 }} title={t("DELETE")}>
                                      <i onClick={() => _handleCheckUploadedFile(f._id,f.fileName) } className="bi bi-trash text-danger me-1"></i>
                                    </div>
                                  </div>
                                )
                              })
                            }
                          </div>
                      </div>
                      <div className="col-8">
                          <div className="container">
                              <div className="mb-3">
                                  <p className="mb-0" style={{color : invertDark(theme),fontSize : 14}}>{t("New File Upload")}</p>
                                  <MyFileInput
                                    text="(.xlsx, .pdf, .txt, image/*)"
                                    theme={theme}
                                    fontSize={"15px"}
                                    file={companyFile.file}
                                    onChange={(e) => _handleChangeCompanyFile(e)}
                                  />
                              </div>
                              <div className="mb-3 row">
                                  <div className="col-3 d-flex align-items-center">
                                    <p className="mb-0 text-nowrap " style={{color : invertDark(theme),fontSize : 14}}>{t("File Name")}</p>
                                  </div>
                                  <div className="col-9 d-flex align-items-center">
                                    <MyInput
                                      required={true}
                                      id={"companyFileName"}
                                      type={"text"}
                                      value={companyFile.fileName}
                                      onChange={(e) => _handleChangeCompanyFileName(e)}
                                      placeHolder={t("File Name")}
                                    />
                                  </div>
                                  
                              </div>
                              <div className="mb-3 row">
                                <div className="col-3 d-flex align-items-center">
                                  <p className="mb-0 text-nowrap " style={{color : invertDark(theme),fontSize : 14}}>{t("File Type")}</p>
                                </div>
                                <div className="col-9 d-flex align-items-center">
                                  <MuiNonLabelSelector
                                    options={fileTypeList}
                                    tran={t}
                                    value={
                                      fileTypeList.find(v => v.label == companyFile.fileType)?.label || ""
                                    }
                                    // disabled={selectedView === "Consumption View" ? true : false}
                                    style={{width : "100%"}}
                                    placeholder={t("Select File Type")}
                                    menuListColor={Colors.Dark[0]}
                                    handleSelect={(e) => _handleChangeCompanyFileType(e.target.value) }
                                  />
                                </div>                                
                              </div>
                              {companyFile.fileType && (companyFile.fileType === 'Consumption Data' || companyFile.fileType === 'Emission Data') && <div className="mb-3 row">
                                  <div className="col-3 d-flex align-items-center">
                                    <p className="mb-0 text-nowrap " style={{color : invertDark(theme),fontSize : 14}}>{t("Year")}</p>
                                  </div>
                                  <div className="col-9 d-flex align-items-center">
                                    <Selector
                                      options={yearOptions}
                             
                                      value={
                                        yearOptions?.find(v => v.label == companyFile.year)
                                      }
                                      style={{ width: "100%" }}
                                      placeholder={t("Select Year")}
                                      menuListColor={Colors.Dark[0]}
                                      _handleSelect={(e) =>
                                        _handleChangeCompanyYear(e.value)
                                      }
                                    />
                                  </div>
                              </div>}
                              <div className="mb-3 row">
                                  <div className="col-3 d-flex align-items-center">
                                    <p className="mb-0 text-nowrap " style={{color : invertDark(theme),fontSize : 14}}>{t("Remark")}</p>
                                  </div>
                                  <div className="col-9 d-flex align-items-center">
                                    <MyInput
                                      required={true}
                                      id={"remark"}
                                      type={"text"}
                                      value={companyFile.remark}
                                      onChange={(e) => _handleChangeRemark(e)}
                                      placeHolder={t("type remark")}
                                    />
                                  </div>
                                  
                              </div>
                              <div className="d-flex justify-content-end">
                                <MyButton
                                  text={t("Save")}
                                  customColor={Colors.water}
                                  className="me-3 px-3"
                                  onClick={() => _handleCompanyFileUpload()}
                                />
                                <MyButton
                                  text={t("Close")}
                                  rounded
                                  fontSize={15}
                                  className="px-3"
                                  dataDismiss={"modal"}
                                  customColor={
                                    theme === "dark" ? Colors.lightBlue : Colors.blue
                                  }
                                />               
                              </div>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  })
);

const fileTypeList = [
  {"value" : 1,"label": "Asset Specifications"},
  {"value" : 2,"label": "Consumption Data"},
  {"value" : 3,"label": "Emission Data"},
  {"value" : 4,"label": "Other"},
]