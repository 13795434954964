import { inject, observer } from "mobx-react";
import { useEffect, useState, Fragment } from "react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import SurveyFileUploadingModal from "./SurveyFileUploadingModal";
import Stack from "@mui/material/Stack";
import Upload from "@mui/icons-material/Upload";
import { MyInput, Selector, FileUploadSelector } from "../../Tools";
import { toJS } from "mobx";
import { useTranslation } from "react-i18next";

export const CustomInputs = inject("store")(
  observer((props) => {
    const {
      questions,
      type,
      childSections,
      siteListData,
      buildingId,
      frameworkId,
    } = props;
    const {
      answerArray,
      _handleRadioChange,
      _handleSelectAnswer,
      _handleTextChange,
      _handleNumberChange,      
      _handleOpenAllQuestions,
      _handleCloseAllQuestions,
      _handleSurveyFileChange,
      _handleScope2DescriptionChange,
    } = props.store.survey;
    const { theme, viewStatus } = props.store.common;
    const { t } = useTranslation("questions");
    const selectorOptions = (options) => {
      return options?.map((opt) => {
        return { value: opt._id, label: opt.text };
      });
    };
    const [modifiedQuestions, setModifiedQuestions] = useState([]);

    const findQuesId = (questionId) => toJS(answerArray)?.filter((v) => v.questionId === questionId);

    useEffect(() => {
      const yes_showAll_ques = toJS(questions).find(
        (v) => v.inputAction === "yes_showAll"
      );

      if (yes_showAll_ques) {
        const index = toJS(questions).findIndex(
          (v) => v._id === yes_showAll_ques._id
        );
        const optionMap = {};
        yes_showAll_ques.answerOption.forEach(
          (opt) => (optionMap[opt.text] = opt._id)
        );

        const YesAns_inQues = yes_showAll_ques?.answer[0];
        const YesAns_inAns = toJS(answerArray).find(
          (v) => v.questionId === yes_showAll_ques._id
        )?.answer;

        const isYesAnswer = YesAns_inAns || YesAns_inQues;

        if (isYesAnswer === optionMap[t("Yes")]) {
          setModifiedQuestions(questions);
          _handleOpenAllQuestions();
        } else {
          setModifiedQuestions(questions.slice(0, index + 1));
          _handleCloseAllQuestions();
        }
      } else {
        setModifiedQuestions(questions);
        _handleOpenAllQuestions();
      }
    }, [questions, answerArray]);

    return modifiedQuestions.map((q, i) => {
      const options_ = selectorOptions(q.answerOption);
      const optionMap = {};
      q.answerOption.forEach((opt) => (optionMap[opt.text] = opt._id));

      return (
        <div className="p-3 my-3 tableStyle" key={i} style={{ borderRadius: 5, color: invertDark(theme), backgroundColor: theme==="dark"?"#141c43":"#fff" }}>
          <div className="row">
            <div className={viewStatus === "detailsView" ? "col-8" : "col-12"}>
              <div className="pb-2">
                <div className="mb-2">
                  <span className="fw-bold pe-2">{"Q" + (i + 1)}</span>
                  {q.questionName}{" "}
                </div>
                <div>
                  {q.inputType === "textBox" ? (
                    <div className="row d-flex">
                      <div className={`${viewStatus === "detailsView"? "col-lg-4 col-12" : "col-lg-6 col-md-6 col-12"}`}>
                        <div className="d-flex" style={{ maxWidth: 300, paddingLeft: 29 }}>
                          <MyInput
                            id={q._id}
                            type={"text"}
                            onChange={(e) =>
                              _handleTextChange(e, q._id, childSections, null, i, null,
                                answerArray?.filter(a => a.questionId === q._id).length > 0
                                ? answerArray.filter(a => a.questionId === q._id)[0]?.linkedFileId
                                : q?.auditData?.linkedFileId,
                                answerArray?.filter(a => a.questionId === q._id).length > 0
                                ? answerArray?.filter(a => a.questionId === q._id)[0]?.description
                                : q?.auditData?.description
                              )
                            }
                            value={
                              answerArray?.filter((v) => v.questionId === q._id).length > 0 
                              ? answerArray?.filter(v => v.questionId === q._id)[0].answer
                              : q.answer[0]
                            }
                          />
                        </div>
                      </div>
                    </div>
                  ) : q.inputType === "textArea" ? (
                    <div className="row d-flex">
                      <div className={`${viewStatus === "detailsView" ? "col-lg-6 col-12" : "col-lg-6 col-md-6 col-12"}`}>
                        <MyInput
                          id={q._id}
                          onChange={(e) => _handleTextChange(e, q._id, childSections, null, i, null,
                              answerArray?.filter((a) => a.questionId === q._id).length > 0
                              ? answerArray.filter(a => a.questionId === q._id)[0]?.linkedFileId
                              : q?.auditData?.linkedFileId,
                              answerArray?.filter((a) => a.questionId === q._id).length > 0
                              ? answerArray?.filter(a => a.questionId === q._id)[0]?.description
                              : q?.auditData?.description
                            )
                          }
                          isTextArea={true}
                          value={
                            childSections.length > 0
                              ? (answerArray?.filter(v => v.questionId === q._id).length > 0
                              ? answerArray?.filter(v => v.questionId === q._id)[0].childSection.answer
                              : q.answer[0])
                              : (answerArray?.filter(v => v.questionId === q._id).length > 0
                              ? answerArray?.filter(v => v.questionId === q._id)[0].answer
                              : q.answer[0])
                          }
                        />
                      </div>
                    </div>
                  ) : q.inputType === "number" ? (
                    <div className="row d-flex">
                      <div className="col-lg-4 col-12">
                        <div style={{ maxWidth: 300, paddingLeft: 29 }}>
                          <MyInput
                            id={q._id}
                            type={"number"}
                            onChange={(e) =>
                              _handleNumberChange(e, q._id, childSections, null, i, null, 0,
                                answerArray?.filter(a => a.questionId === q._id).length > 0
                                ? answerArray.filter(a => a.questionId === q._id)[0]?.linkedFileId
                                : q?.auditData?.linkedFileId,
                                answerArray?.filter(a => a.questionId === q._id).length > 0
                                ? answerArray?.filter(a => a.questionId === q._id)[0]?.description
                                : q?.auditData?.description
                              )
                            }
                            pattern="[0-9]*"
                            value={
                              answerArray?.filter((v) => v.questionId === q._id).length > 0
                              ? answerArray?.filter(v => v.questionId === q._id)[0].answer
                              : q.answer[0]
                            }
                          />
                        </div>
                      </div>
                    </div>
                  ) : q.inputType === "dropDown" ? (
                    <div className="row" style={{ paddingLeft: 29 }}>
                      <div className="col-12 col-sm-12 col-lg-12 col-xl-3 col-xs-3 col-md-12 my-1">
                        <div className="" style={{ maxWidth: 300 }}>
                          <Selector
                            id={q._id}
                            options={options_}
                            menuListColor={Colors.Dark[0]}
                            value={
                              findQuesId(q._id)?.length > 0
                                ? options_?.filter(option => option.value === findQuesId(q._id)[0].answer)[0]
                                : options_?.filter(option => option.value === q.answer[0])[0]
                            }
                            _handleSelect={(e) =>
                              _handleSelectAnswer(e, q._id, i,
                                answerArray?.filter((a) => a.questionId === q._id).length > 0
                              ? answerArray.filter(a => a.questionId === q._id)[0]?.linkedFileId
                              : q?.auditData?.linkedFileId,
                              answerArray?.filter((a) => a.questionId === q._id).length > 0
                              ? answerArray?.filter(a => a.questionId === q._id)[0]?.description
                              : q?.auditData?.description)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  ) : q.inputType === "creatableDropDown" ? (
                    <div className="row" style={{ paddingLeft: 29 }}>
                      <div className="col-12 col-sm-12 col-lg-12 col-xl-3 col-xs-3 col-md-12 my-1">
                        <div style={{ maxWidth: 300 }}>          
                          <Selector
                            id={q._id}
                            options={options_}
                            menuListColor={Colors.Dark[0]}
                            value={findQuesId(q._id)?.length > 0
                                ? findQuesId(q._id)[0].answer === "" || !options_?.some(option => option.value === findQuesId(q._id)[0].answer)
                                ? options_[options_.length - 1]
                                : options_?.filter(option => option.value === findQuesId(q._id)[0].answer)[0]
                                : q.answer.length > 0 && (q.answer[0] === "" || !options_?.some(option => option.value === q.answer[0]))
                                ? options_[options_.length - 1]
                                : options_?.filter(option => option.value === q.answer[0])[0]
                            }
                            _handleSelect={(e) => _handleSelectAnswer(e, q._id, i,
                              answerArray?.filter((a) => a.questionId === q._id).length > 0
                            ? answerArray.filter(a => a.questionId === q._id)[0]?.linkedFileId
                            : q?.auditData?.linkedFileId,
                            answerArray?.filter((a) => a.questionId === q._id).length > 0
                            ? answerArray?.filter(a => a.questionId === q._id)[0]?.description
                            : q?.auditData?.description)}
                          />
                        </div>
                      </div>
                      {q.inputAction === "other_textBox" &&
                        (findQuesId(q._id).length === 0
                          ? q.answer.length !== 0 && (q.answer[0] === "" || options_.findIndex(f => f.value === q.answer[0]) < 0)
                          : options_.findIndex(f => f.value === findQuesId(q._id)[0]?.answer) < 0) 
                          && (
                          <div className="text-light col-12 col-sm-12 col-lg-12 col-xl-3 col-xs-3 col-md-12 my-1">
                            <div style={{ maxWidth: 300, paddingLeft: 29 }}>
                              <MyInput
                                id={q._id}
                                placeHolder={t("Type Your Answer")}
                                type={type}
                                onChange={(e) =>
                                  _handleTextChange(e, q._id, childSections, true, i, null,
                                    answerArray?.filter(a => a.questionId === q._id).length > 0
                                    ? answerArray.filter(a => a.questionId === q._id)[0]?.linkedFileId
                                    : q?.auditData?.linkedFileId,
                                    answerArray?.filter(a => a.questionId === q._id).length > 0
                                    ? answerArray?.filter(a => a.questionId === q._id)[0]?.description
                                    : q?.auditData?.description
                                  )
                                }
                                value={
                                  findQuesId(q._id)?.length > 0
                                    ? findQuesId(q._id)?.filter(v => v.questionId === q._id)[0].answer
                                    : q.answer[0]
                                }
                              />
                            </div>
                          </div>
                        )}
                    </div>
                  ) : q.inputType === "radio" ? (
                    q.questionType === "no_inputByuser" ? (
                      <Fragment>
                        <div className="row" style={{ paddingLeft: 29 }}>
                          {q.answerOption.map((ao, index) => {
                            return (
                              <div className="col-6" key={index}>
                                <label
                                  className="w-100 p-2 my-1"
                                  style={{
                                    background: Colors[theme].secondBackground,
                                    border: `1px solid ${Colors.Dark[4]}`,
                                    borderRadius: 5,
                                    cursor: "pointer",
                                  }}
                                  htmlFor={q._id + index}
                                >
                                  <input
                                    id={q._id + index}
                                    type="radio"
                                    className="me-2"
                                    name={q._id}
                                    value={ao._id}
                                    onChange={(e) => _handleRadioChange(ao, q._id, childSections, q.questionType, i, null,
                                      answerArray?.filter(a => a.questionId === q._id).length > 0
                                      ? answerArray.filter(a => a.questionId === q._id)[0]?.linkedFileId
                                      : q?.auditData?.linkedFileId,
                                      answerArray?.filter(a => a.questionId === q._id).length > 0
                                      ? answerArray?.filter(a => a.questionId === q._id)[0]?.description
                                      : q?.auditData?.description)}
                                    checked={
                                      ao.text === t("Yes")
                                        ? findQuesId(q._id).length === 0
                                          ? q.answer[0] === ao._id
                                          : findQuesId(q._id)?.[0]?.answer ===
                                            ao._id
                                        : findQuesId(q._id).length === 0
                                        ? q.answer[0] !== optionMap[t("Yes")] &&
                                          q.answer[0] !== null &&
                                          q.answer[0] !== undefined
                                        : findQuesId(q._id)?.[0]?.answer !==
                                            optionMap[t("Yes")] &&
                                          findQuesId(q._id)?.[0]?.answer !==
                                            null &&
                                          findQuesId(q._id)?.[0]?.answer !==
                                            undefined
                                    }
                                  />
                                  {ao.text}
                                </label>
                              </div>
                            );
                          })}
                        </div>
                        {(findQuesId(q._id).length === 0
                          ? q.answer[0] !== optionMap[t("Yes")] &&
                            q.answer[0] !== null &&
                            q.answer[0] !== undefined
                          : findQuesId(q._id)?.[0]?.answer !==
                              optionMap[t("Yes")] &&
                            findQuesId(q._id)?.[0]?.answer !== null &&
                            findQuesId(q._id)?.[0]?.answer !== undefined) && (
                          <div className="my-1" style={{ maxWidth: 300, paddingLeft: 29 }}>
                            <MyInput
                              id={q._id}
                              type={type}
                              onChange={(e) =>
                                _handleTextChange(e, q._id, childSections, q.questionType, i, null,
                                  answerArray?.filter(a => a.questionId === q._id).length > 0
                                  ? answerArray.filter(a => a.questionId === q._id)[0]?.linkedFileId
                                  : q?.auditData?.linkedFileId,
                                  answerArray?.filter(a => a.questionId === q._id).length > 0
                                  ? answerArray?.filter(a => a.questionId === q._id)[0]?.description
                                  : q?.auditData?.description
                                )
                              }
                              value={findQuesId(q._id).length > 0 ? findQuesId(q._id)?.[0].answer : q.answer[0]}
                            />
                          </div>
                        )}
                      </Fragment>
                    ) : (
                      <div className="row" style={{ paddingLeft: 29 }}>
                        {q.answerOption.map((ao, index) => {
                          return (
                            <div className="col-6" key={index}>
                              <label
                                className="w-100 p-2 my-1"
                                style={{
                                  background: Colors[theme].secondBackground,
                                  border: `1px solid ${Colors.Dark[4]}`,
                                  borderRadius: 5,
                                  cursor: "pointer",
                                }}
                                htmlFor={q._id + index}
                              >
                                <input
                                  id={q._id + index}
                                  type="radio"
                                  className="me-2"
                                  name={q._id}
                                  value={ao._id}
                                  onChange={(e) =>
                                    _handleRadioChange(ao, q._id, childSections, q.questionType, i, null,
                                      answerArray?.filter((a) => a.questionId === q._id).length > 0
                                    ? answerArray.filter(a => a.questionId === q._id)[0]?.linkedFileId
                                    : q?.auditData?.linkedFileId,
                                    answerArray?.filter((a) => a.questionId === q._id).length > 0
                                    ? answerArray?.filter(a => a.questionId === q._id)[0]?.description
                                    : q?.auditData?.description)}
                                  checked={findQuesId(q._id)?.length > 0 ? findQuesId(q._id)?.[0]?.answer === ao._id : q.answer[0] == ao._id}
                                />
                                {ao.text}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    )
                  ) : null}
                </div>
              </div>
            </div>
            {viewStatus === "detailsView" && (
              <div className="col-4 tableStyle" style={{backgroundColor: Colors[theme].active, color: invertDark(theme)}}>
                <div className="mb-2">{t("Data Validation")}</div>
                <div className="col-12">
                  <div className="row">
                    <div className="col-lg-12 col-12 col-sm-12 mb-1">
                      <label style={{ fontSize: "12px" }}>
                        {t("Data Validation File")}
                      </label>
                      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={0}>
                        <FileUploadSelector
                          width="200px"
                          id={q._id}
                          options={siteListData}
                          menuListColor={Colors.Dark[0]}
                          value={siteListData.find(v => 
                                v.value === (answerArray?.filter(a => a.questionId === q._id).length > 0
                                ? answerArray.filter(a => a.questionId === q._id)[0]?.linkedFileId
                                : q.auditData.linkedFileId)
                          )}
                          _handleSelect={(e) => {
                            _handleSurveyFileChange( e.value, q._id,
                              childSections, // childSection,
                              i, //question index
                              null, i,
                              answerArray?.filter(a => a.questionId === q._id).length > 0
                              ? answerArray?.filter(a => a.questionId === q._id)[0]?.description
                              : q?.auditData?.description,
                              answerArray?.filter(v => v.questionId === q._id).length > 0
                              ? answerArray?.filter(v => v.questionId === q._id)[0].answer
                              : q.answer[0]
                            );
                          }}
                        />
                        <span data-bs-toggle="modal" data-bs-target="#surveyFileModal" style={{ cursor: "pointer" }}>                        
                        <button
                            title={t("Browse your files")}
                            onClick={(e) =>
                              _handleSurveyFileChange("uploadBtn", q._id,
                                childSections, // childSection,
                                i, //question index
                                null, i,
                                answerArray?.filter(a => a.questionId === q._id).length > 0
                                ? answerArray?.filter(a => a.questionId === q._id)[0]?.description
                                : q?.auditData?.description,
                                answerArray?.filter(v => v.questionId === q._id).length > 0
                                ? answerArray?.filter(v => v.questionId === q._id)[0].answer
                                : q.answer[0]
                              )
                            }
                            style={{
                              border:
                                theme === "dark"
                                  ? "1px solid #5e5e5e"
                                  : "1px solid #c6c6c6",
                              minWidth: "36px !important",
                              maxWidth: "36px !important",
                              padding: "7px",
                              borderRadius: "5px",
                              background:
                                theme === "dark" ? "transparent" : "#fff",
                            }}
                          >
                            <Upload style={{ color: "rgb(4, 197, 0)" }} />
                          </button>
                        </span>
                      </Stack>
                    </div>
                    <div className="col-lg-12 col-12 col-sm-12 mb-1">
                      <label style={{ fontSize: "12px" }}>
                        {t("Description of the uploaded file")}
                      </label>
                      <MyInput
                        id={q._id + "description"}
                        required={true}
                        isTextArea={true}
                        placeHolder={t("Description of the uploaded file")}
                        value={answerArray?.filter((a) => a.questionId === q._id).length > 0
                            ? answerArray?.filter(a => a.questionId === q._id)[0]?.description
                            : q?.auditData?.description
                        }
                        onChange={(e) =>
                          _handleScope2DescriptionChange(e, q._id, childSections, null, i, null, null,
                            answerArray?.filter((a) => a.questionId === q._id).length > 0
                            ? answerArray.filter(a => a.questionId === q._id)[0]?.linkedFileId
                            : q?.auditData?.linkedFileId,
                            childSections.length > 0
                              ? (answerArray?.filter(v => v.questionId === q._id).length > 0
                              ? answerArray?.filter(v => v.questionId === q._id)[0].childSection.answer
                              : q.answer[0])
                              : (answerArray?.filter(v => v.questionId === q._id).length > 0
                              ? answerArray?.filter(v => v.questionId === q._id)[0].answer
                              : q.answer[0])
                          )
                        }
                      />
                    </div>
                  </div>
                  <SurveyFileUploadingModal modalId="surveyFileModal" frameworkId={frameworkId} buildingId={buildingId} btnId="surveyAns_File_Upload_NotGHG"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      );
    });
  })
);
