import { observable, action, makeObservable, runInAction, toJS } from "mobx";
import { toast } from "react-toastify";

import {
    fetchCompanyList,fetchAuditOverviewList, fetchCompanyVerification, updateAuditorAction,
    fetchSurveyHeaderInfo, fetchGroupList,fetchBaseLineYear, fetchGroupDetail, updateAssetAction
  } from "../API/api.audit";
import { baseURL } from "../API/URL";
import common from "./common";


class Audit{
    companyList = null;
    auditOverviewList = [];
    verificationData = null;
    selectedFramework= null;
    frameworkOptions = [];
    yearOptions = [];
    selectedYear= null;
    scopeOptions = [
        {value: 'scope1', label: 'Scope 1'},
        {value: 'scope2', label: 'Scope 2'},
        {value: 'scope3', label: 'Scope 3'},
        {value: 'energy', label: 'Energy'},
        {value: 'water', label: 'Water'},
    ];
    sourceTabsOptions = [
        {value: 0, label: 'Stationary Combustion', icon: "bi bi-fire"},
        {value: 1, label: 'Mobile Combustion', icon: "bi bi-truck"},
        {value: 2, label: 'Refrigerants and Fugitives', icon: "bi bi-fan"},
        {value: 3, label: 'Purchased Electricity', icon: "bi bi-lightning-charge"},
    ]
    selectedSourceTab = this.sourceTabsOptions[0].value;
    selectedScope = null;
    previewFile= null;
    groupList=[];
    groupListTblLoading= false;
    groupDetailInfo = {}; carbonDetailList = [];
    selectedDetailYear = 2022;
    groupDetailTableLoading = false;
    
    constructor() {
        makeObservable(this,{
            companyList : observable,
            auditOverviewList: observable,
            verificationData: observable,
            selectedFramework: observable,
            frameworkOptions: observable,
            yearOptions: observable,
            selectedYear: observable,
            scopeOptions: observable,
            selectedScope: observable,
            previewFile: observable,
            groupList: observable,
            groupListTblLoading: observable,
            selectedSourceTab: observable,
            groupDetailInfo: observable,
            selectedDetailYear: observable,
            carbonDetailList: observable,
            groupDetailTableLoading: observable,

            getCompanyList: action.bound,
            getAuditOverviewList: action.bound,
            getCompanyVerification: action.bound,
            _handleAuditActionChange: action.bound,
            setYearSelectorDatum: action.bound,
            _handleSelectSelector: action.bound,
            getSurveyHeaderInfo: action.bound,
            _handlePreviewAttachedFile: action.bound,
            getGroupListData: action.bound,
            _handleChangeSourceTab: action.bound,
            getBaseLineYear: action.bound,
            getGroupDetailList: action.bound,
            _handleAssetActionChange: action.bound,
        })
    }

      getCompanyList() {
        fetchCompanyList((err, data) => {
            if (data) {
                runInAction(() => {
                    this.companyList = data.payload
                })
            } else {
                console.log(err);
                toast.error('Permission Denied');
            }
          });
      }

      getAuditOverviewList(companyName) {
        this.selectedFramework = null;
        this.selectedScope = null;
        this.selectedYear = null;
        fetchAuditOverviewList(companyName,(err, data) => {
            if (data) {
                
                runInAction(() => {
                    this.auditOverviewList = data.payload
                })
            } else {
                console.log(err);
                toast.error('Permission Denied');
            }
          });
      }

      getCompanyVerification=(buildingId)=> {
        // this.verificationData = null
        const isGhgFw = this.frameworkOptions?.find((f) => f.value === this.selectedFramework)?.label === 'GHG';
        const params = isGhgFw
                        ?{
                            buildingId: buildingId,
                            frameworkId: this.selectedFramework,
                            year: this.selectedYear,
                            scope: this.selectedScope
                         }
                        :{
                            buildingId: buildingId,
                            frameworkId: this.selectedFramework
                        };
        fetchCompanyVerification(isGhgFw,params,(err, data) => {
            if (data) {
                runInAction(() => {
                    this.previewFile = null
                    this.verificationData = data.payload
                })
            } else {
                this.verificationData=[];
                console.log(err);
                toast.error('Permission Denied');
            }
          });
      }

      _handleAuditActionChange = (e,buildingId,actionId, actionType, month, category) =>{
        
        const isGhgFw = this.frameworkOptions?.find((f) => f.value === this.selectedFramework)?.label === 'GHG';
        const params = isGhgFw
                        ?{
                            buildingId: buildingId,
                            frameworkId: this.selectedFramework,
                            year: this.selectedYear,
                            scope: this.selectedScope,
                            actionId: actionId,
                            actionType: actionType,
                            month: month,
                            category: category
                         }
                        :{
                            buildingId: buildingId,
                            frameworkId: this.selectedFramework,
                            actionId: actionId,
                            actionType: actionType
                        };
        
        const data={surveyAnswerAudition:e.target.value}
        updateAuditorAction(isGhgFw,params,data,(err, data) => {
            if (data.success) {
                runInAction(() => {                   
                    this.getCompanyVerification(buildingId,this.selectedFramework)
                })
            } else {
                toast.error('Something went wrong!');
            }
          });
      }

      getSurveyHeaderInfo=(buildingId) => {
        fetchSurveyHeaderInfo(buildingId,(err, data) => {
            if (data.success) {
                const temp = data.payload?.surveyHeader?.map(v=> ({
                    value: v.framework_id,
                    label: v.name
                }))
                runInAction(() => {
                   this.frameworkOptions = temp;
                //    this.selectedFramework = temp[0]?.value || null
                })
            } else {
                console.log(err);
                toast.error('Permission Denied');
            }
          });
      }

      setYearSelectorDatum = () => {
        const currentYear = new Date().getFullYear();
        // this.selectedYear = currentYear;
        let temp = [];
        for(let i=2019; i<=currentYear; i++){
            temp.push({value: i, label:i})
        }
        this.yearOptions = temp;
      }

      _handleSelectSelector = (key, value) => {
        this.verificationData=null;
        if(key === 'framework'){
            this.selectedFramework = value
        }else if(key === 'year'){
            this.selectedYear = value;
        }else if(key === 'scope'){
            this.selectedScope = value;
        }
      }

      _handleDetailYearSelect = (value) => {
        this.selectedDetailYear = value;
      }

      _handlePreviewAttachedFile = (filePath, index) => {
        // const blobLink = URL.createObjectURL(blob);
        // console.log(blobLink);
       // this.previewFile = {blobLink: 'blobLink', index};
        //0b5a2a0d-c172-4d99-9050-3c544357c33b.pdf
        //1f922d21-073f-44be-a315-00e1ec2ffdff.xlsx
        let url = baseURL.substring(0, baseURL.length-1);
        runInAction(() => {
            console.log("File Path",url, filePath);
            var filename = filePath.substring(filePath.lastIndexOf('/')+1);
            let newPath = "/upload/pdf/data?filename="+filename;
            this.previewFile = {blockLink: url+newPath, index};
        //     console.log("Run In")
        //    //let type = "pdf"
        //    //this.previewFile = {blockLink: "http://localhost:8000/upload/data/a94615f4-b65d-4f5e-a9ae-77a72e3bdfb3.docx", index};
        //     fetch(
        //         'http://localhost:8000/upload/data/1f922d21-073f-44be-a315-00e1ec2ffdff.xlsx',
        //         {
        //             headers: {
        //                 Accept: "*/*",
        //                 // "Content-Type": "application/pdf",
        //                 "Access-Control-Allow-Origin": "*",
        //             },
        //         },
        //     )
        //         //.then((res) => res)
        //         .then(async(res) => {
        //              console.log("Blob", res);
        //              const blob = await res.blob();
        //              //const newBlob = new Blob([blob]);
                 
        //              const blobUrl = window.URL.createObjectURL(blob);
        //             //const blockLink = URL.createObjectURL(blob);
        //             this.previewFile = {blockLink: blobUrl, index};
        //             // console.log(blobLink);
                   
    
        //             // let file = new File([blob], "Sample.xlsx");
        //             // console.log('file >> ', file)
    
        //             // const reader = new FileReader();
        //             // reader.readAsDataURL(file);
        //             // reader.onload = (readerEvent) => {
        //             //       const result = readerEvent.target.result;
        //             //       console.log('result is ', typeof result,'\n', result)
        //             //        this.previewFile = {blobLink:result, index};
        //             //   };
    
        //             //res.arrayBuffer().then((v) => {
        //                 //const blob = new Blob([res], {type: "octet/stream"});
        //               // const lnk = URL.createObjectURL(blob);
        //                // console.log('pdfBlobLink == ' , lnk)
                       
        //                 //this.previewFile = {blobLink: lnk, index};
        //            //   });
        //         })
        //         .catch(
        //             (err) => {
        //                 console.log(err)
        //             })

        })
        
        
      }

      getGroupListData=(companyId, year)=>{
        this.groupListTblLoading = true
        fetchGroupList(companyId,year,(err, data) => {
            if (data) {
                runInAction(() => {
                    this.groupList = data.payload;
                    this.groupListTblLoading = false;
                })
            } else {
                console.log(err);
                toast.error('Permission Denied');
            }
          });
      }

      _handleChangeSourceTab = (event, value) => {
        console.log("tab value > ", value);
        this.selectedSourceTab = value;
      }
      
      getBaseLineYear = (companyId) => {
        fetchBaseLineYear(companyId,(err,data) => {
            if(data.success){
                if(data.payload.baselineYear){
                    runInAction(()=>{
                        this.selectedYear = data.payload.baselineYear;
                        common._handleGetYearOptions(data.payload.baselineYear);
                    })
                }else{
                    runInAction(()=>{
                        this.selectedYear = 2018;
                        common._handleGetYearOptions(2018);
                    }) 
                }
            }
        })
      }

      getGroupDetailList = (groupId, hideLoading) => {
        const year = this.selectedDetailYear;
        this.groupDetailTableLoading = hideLoading ? false : true;
        fetchGroupDetail(groupId, year , (err, data) => {
            if(data.success){
                if(data.payload){
                    runInAction(()=>{
                        this.groupDetailInfo =  data.payload.groupInfo;
                        this.carbonDetailList = data.payload.carbonData;
                        this.groupDetailTableLoading = false;
                    })
                }
            }
        });
      }

      _handleAssetActionChange = (e, assetId, month) =>{
        const params = {
            assetId,
            year: this.selectedDetailYear,
            month
        }
        const urlParams = new URLSearchParams(window.location.search);
        const groupId = urlParams.get("groupId");
        updateAssetAction(params,e.target.id,(err, data) => {
            if (data.success) {
                runInAction(() => {                   
                    this.getGroupDetailList(groupId, true)
                })
            } else {
                toast.error('Something went wrong!');
            }
        });
      }
}

export default new Audit();


