import * as React from 'react';
import { Label, Pie, PieChart, Tooltip } from "recharts";
import { ProgressBar } from "../../Tools/ProgressBar";
import { inject, observer } from "mobx-react";
import { useState } from "react";
import { toJS } from "mobx";
import { Cookies } from "react-cookie";

import { MultiLineApexChart } from "../../Assets/Charts/MultiLineApexChart";

import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Grid,
  TableSortLabel,
  Button
} from '@mui/material';

import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { makeStyles } from "@material-ui/core/styles";

import { Colors, invertDark } from '../../Assets/Config/color.config';
import Capella from '../../Assets/images/capella.png'
import Conrad from '../../Assets/images/conrad.png'
import { SummaryDonutChart } from '../AuditCompanyOverview/SummaryDonutChart';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MyButton } from '../../Tools';
import { ReportPreviewModal } from './ReportPreviewModal';
import { Fragment } from 'react';


export const GroupReport = inject("store")(
  observer((props) => {

    const cookies = new Cookies();
    const userName = cookies.get("userName");
    const navigate = useNavigate();

    const headers = [
      { id: "View", numeric: false, label: "View Name" },
      { id: "Emission", numeric: false, label: "Emission(KgCO2)" },
      { id: "Percent", numeric: false, label: "Percent" },
      { id: "Asset", numeric: true, label: "Total Asset" }
    ]
    const { groupReports, theme } = props.store.common;

    const { t } = useTranslation("dashboard");

    return (
      <div className="cardStyle col my-2" style={{ color: Colors[theme].textColor, minHeight: 200 }}>
        <span className="d-flex justify-content-between align-items-center">
          <span className="cardTitle">{t("Report")}</span>
          {/* <span style={{ color: Colors.water }}>
            See More
            <img src={rightArrow} className="ps-1" />
          </span> */}

          <span className="d-flex justify-content-between align-items-center">

            {
              (userName === 'Kumo Admin' || userName === 'ITRIdemo') ?
                <MyButton
                  id={"reportConfiguration"}
                  text={t("Report Configuration")}
                  customColor={theme === "dark" ? Colors.lightBlue : Colors.blue}
                  customHoverColor={theme === "dark" ? '#35469b' : '#0148AE'}
                  small={true}
                  style={{ fontSize: 12 ,marginRight:10}}

                  onClick={() => { navigate('/report-configure') }}
                >
                </MyButton>
                : null
            }


            {/* <MyButton
              id={"downloadPDF"}
              text={t("Download PDF")}
              customColor={theme === "dark" ? Colors.lightBlue : Colors.blue}
              customHoverColor={theme === "dark" ? '#35469b' : '#0148AE'}
              small={true}
              style={{ fontSize: 12 }}
              dataToggle={'modal'}
              dataTarget={'#reportPreviewModal'}
              onClick={() => null}
            >
            </MyButton> */}
          </span>
        </span>
        <hr className="my-2" />
        <div>
          {toJS(groupReports) === null ?
            <div className="text-center"><i className="spinner-border mt-5" /></div>
            : toJS(groupReports).length ?
              <TableContainer style={{ overflowX: 'hidden', maxHeight: 300, background: 'transparent', boxShadow: 'none' }} component={Paper}>
                <Table style={{ width: "100%", }} aria-label="">
                  <TableHead>
                    <TableRow style={{ position: 'sticky', top: 0, background: theme === 'dark' ? '#192354' : '#F6F6F6', zIndex: 1 }}>

                      {headers.map((headCell, index) => (
                        <TableCell
                          key={headCell.id}

                          style={{
                            color: invertDark(theme),
                            fontWeight: "bold",
                            padding: "10px 13px 10px 13px",
                            textAlign: "center",
                            position: "static",
                          }}
                        >
                          {t(headCell.label)}

                        </TableCell>
                      ))}
                      {/* <TableCell /> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {toJS(groupReports).map((row, index) => {
                      row.piechartData = row.scopes.map(s => {
                        return {
                          name: s.scope === 1 ? 'scope1' : s.scope === 2 ? 'scope2' : 'scope3',
                          value: s.totalScopePercent,
                          fill: s.scope === 1 ? '#B0D86F' : s.scope === 2 ? '#8CDDD7' : '#F26B74'
                        }
                      });
                      return (
                        <Rows key={row.groupId+index} index={index} cellData={row} t={t} theme={theme} />
                      )
                    })
                    }
                  </TableBody>
                </Table>
              </TableContainer>
              :

              <div className="text-center">No Report found!</div>
          }
        </div>
        <ReportPreviewModal headers={headers} thousandSeparator={thousandSeparator} />
      </div>
    );
  })
);

const Rows = (props) => {
  const { cellData, theme, t, index } = props;
  const [open, setOpen] = React.useState(false);
  const tableCellStyle = {
    border: 0,
    color: invertDark(theme),
    padding: '10px 13px',

  };

  return (
    <React.Fragment key={index}>

      <TableRow  style={{ background: Colors[theme].secondBackground, }} sx={{ '& > *': { borderBottom: 'unset' } }}>

        <TableCell style={tableCellStyle} align="center">{cellData.groupName}</TableCell>
        <TableCell style={tableCellStyle} align="center">{thousandSeparator((cellData.totalGroupEmission % 1) === 0 ? cellData.totalGroupEmission : cellData.totalGroupEmission.toFixed(2))}</TableCell>
        <TableCell style={tableCellStyle} align="center">{thousandSeparator((cellData.totalGroupPercent % 1) === 0 ? cellData.totalGroupPercent : cellData.totalGroupPercent.toFixed(2))}%</TableCell>
        <TableCell style={{ ...tableCellStyle, position: 'relative' }} align="center">

          <span>{cellData.totalGroupAsset}</span>
          <IconButton
            style={{
              position: 'absolute',
              right: '13px',
              top: '6px',
            }}
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {
              open ?
                <ArrowDropDownIcon style={{ color: Colors.water, fontWeight: 'bold' }} /> :
                <ArrowRightIcon style={{ color: Colors.water, fontWeight: 'bold' }} />
            }
          </IconButton>

        </TableCell>
      </TableRow>

      <TableRow style={{ background: Colors[theme].secondBackground, padding: 0, margin: 0, borderSpacing: 0 }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, border: 0, color: invertDark(theme), borderBottomRightRadius: 4, borderBottomLeftRadius: 4 }} colSpan={4}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box >
              <hr />
              {/* <Typography variant="h6" gutterBottom component="div">
                {t("Summary")}
              </Typography> */}

              <Table size="small" aria-label="">
                <TableBody>
                  {cellData.scopes?.map((td, index) => (
                    <Fragment key={td.scope+index}>
                      <TableRow className=''  style={{ background: Colors[theme].active, }}>

                        <TableCell style={{ width: '105px', whiteSpace: 'nowrap', alignItems: 'center', overflow: 'hidden', color: invertDark(theme), borderRight: '3px solid transparent', borderBottom: 'none' }}>

                          <div style={{ display: 'inline-block' }}>{t(`Scope${td.scope}`)}</div>
                        </TableCell>

                        <TableCell style={{ width: '215px', textAlign: 'center', color: invertDark(theme), borderRight: `2px solid ${theme === 'light' ? '#FFFFFF' : '#182149'}`, borderBottom: 'none' }}>
                          {thousandSeparator((td.totalEmissionScope % 1) === 0 ? td.totalEmissionScope : td.totalEmissionScope.toFixed(2))}
                        </TableCell>

                        <TableCell style={{ width: '115px', textAlign: 'center', color: invertDark(theme), borderRight: `2px solid ${theme === 'light' ? '#FFFFFF' : '#182149'}`, borderBottom: 'none' }}>
                          {thousandSeparator((td.totalScopePercent % 1) === 0 ? td.totalScopePercent : td.totalScopePercent.toFixed(2))}%
                        </TableCell>

                        <TableCell style={{ textAlign: 'center', color: invertDark(theme), border: 0 }}>
                          {thousandSeparator((td.totalAssetScope % 1) === 0 ? td.totalAssetScope : td.totalAssetScope.toFixed(2))}
                        </TableCell>

                      </TableRow>

                      <TableRow style={{ background: 'transparent', height: 4 }} />
                    </Fragment>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>


      <TableRow style={{ background: Colors[theme].secondBackground, padding: 0, margin: 0, borderSpacing: 0 }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, border: 0, color: invertDark(theme), borderBottomRightRadius: 4, borderBottomLeftRadius: 4 }} colSpan={4}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box >
              <hr />
              <MultiLineApexChart data={cellData.scopes} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>

      <TableRow style={{ background: 'transparent', height: 6 }} />
    </React.Fragment>
  );
}

function thousandSeparator(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}



