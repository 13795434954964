import * as React from "react";
import { inject, observer } from "mobx-react";
import { useTranslation } from 'react-i18next';
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { TableBody } from "@mui/material";
import "./groupview.css";
import { NumberInput } from "../../Tools";
import { Colors } from "../../Assets/Config/color.config";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  'td, th': {
    border: '1px solid #8C8C8C41',
    textAlign: 'center',
    padding: '5px',   
  },
  '&:first-child th': {
    borderTop: 0
  },
  'td':{
    borderBottom: 0,
    height: '53px'
  },
  'th': {
    height: '30px',
  }
}));

export const EmissionGWPView = inject("store")(
  observer((props) => { 
    const { t } = useTranslation("assetSetupAndGroup");
    const tMonth = useTranslation("month");

    const { selectedView, data, theme, index, selectedGroupId } = props;    
    const { editTypes, _handleEmissionValueChange, months } = props.store.groupmanagement;

  return (
    <>
      { data ? 
        (
          <div className="emission-table-main-div">
            <TableContainer component={Paper} className="emission-table-container" sx={{overflow: "auto", maxHeight: 280, backgroundColor: 'transparent'}}>
              <Table stickyHeader aria-label="customized table" style={{ color: Colors[theme].textColor }}>
                <TableHead>
                  <StyledTableRow>
                    <TableCell sx={{ minWidth: "150px" }} className={theme === 'dark' ? 'table-header-cell-dark' : 'table-header-cell-white'} rowSpan="2" style={{ borderLeft: 0 }}>{t("Asset Name")}</TableCell>
                    <TableCell className={theme === 'dark' ? 'table-header-cell-dark' : 'table-header-cell-white'} colSpan="12" style={{ borderRight: 0 }}>GWP/KgCO2</TableCell>
                  </StyledTableRow>
                  <StyledTableRow>  
                    {months.map((monthValue, monthIndex) =>                       
                      (
                        <TableCell key={monthIndex} className={theme === 'dark' ? 'table-header-cell-dark' : 'table-header-cell-white'}
                          style={{ borderLeft: monthIndex === 0 ? 0 : '', borderRight: monthIndex === 11 ? 0 : '', top:34 }}>
                          {tMonth.t(monthValue.monthName)}
                        </TableCell>
                      )                     
                    )} 
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                {editTypes.includes(selectedGroupId + selectedView + data.title)?
                  data?.data.map((value, dataIndex) => {
                    return (
                      <StyledTableRow  key={dataIndex}>
                        <TableCell className={theme === 'dark' ? 'table-body-cell-dark' : 'table-body-cell-white'} style={{ borderLeft: 0, width: 150, backgroundColor: "#020845" }}>{value.assetName || value.supplierName || value.transportationName}</TableCell>
                        {value.emissionData.map((month, monthIndex) => 
                          (
                            <TableCell key={data.title + dataIndex + "-" + monthIndex} className={theme === 'dark' ? 'table-body-cell-dark' : 'table-body-cell-white'}
                              style={{ borderRight: monthIndex === 11 ? 0 : '',  backgroundColor: "#020845" }}>
                              <NumberInput style={{margin: 0}} value={month.value} id={data.title + dataIndex + "-" + monthIndex}
                              onChange={e => _handleEmissionValueChange(e, index, dataIndex, monthIndex, data.title, value.assetId)} />
                            </TableCell>
                          )
                        )}
                      </StyledTableRow>
                    )
                  })
                  :
                  data?.data.map((row, dataIndex) => {
                    return (
                      <StyledTableRow  key={dataIndex}>
                        <TableCell className={theme === 'dark' ? 'table-body-cell-dark' : 'table-body-cell-white'} style={{ borderLeft: 0, width: 150}}>{row.assetName || row.supplierName || row.transportationName}</TableCell>
                        {row.emissionData?.map((month, monthIndex) => 
                          (<TableCell style={{ borderRight: monthIndex === 11 ? 0 : '' }} key={dataIndex + "-" + monthIndex} className={theme === 'dark' ? 'table-body-cell-dark' : 'table-body-cell-white'}>{month.value?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>)
                        )}
                      </StyledTableRow>
                    )
                  })
                }                  
                </TableBody>
              </Table>
            </TableContainer>          
          </div>
        )
        :
        ("")
      }      
    </>
  )
}))