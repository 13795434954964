import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { MyButton, MyInput } from "../../Tools";
import { AlertDialog } from "./AlertDialog";
import { useTranslation } from "react-i18next";
import { Scope0Modal } from "./Scope0Modal";
import {Scope0PurchasedModal} from "./Scope0PurchasedModal";
import { MultiMCSetupModal } from "./MultiMCSetupModal";

import { Cookies } from "react-cookie";
const cookies = new Cookies();
export const Scope0Questionary = inject("store")(
    
    observer((props) => {
        const { theme } = props.store.common;
        const {
            scope0ans,
            _handleCheckChange,
            _handleQtyChange,
            scope0Index,
            _handleQuesClick,
            _handlePreviousQuesClick,
            _handleNextQuesClick,
            showScope0Dialog,
            saveMoveToNext_scpoe0,
            discardMoveToNext_scpoe0,
            onDiscardClick_scpoe0,
            scope0Data,
            _handleSetScope0AnsRow
        } = props.store.survey;

        const defaultStyle = {
            width: 38,
            height: 38,
            background: Colors[theme].active,
            lineHeight: 2.3,
            cursor: "pointer",
            transition: "0.1s",
        };
        const selectedStyle = {
            ...defaultStyle,
            width: 45,
            height: 45,
            lineHeight: 2.8,
            fontWeight: "bold",
            boxShadow: Colors.shadow,
            //  borde r:"3px solid #fff"
        };

        const question = questions[scope0Index];
        const { t } = useTranslation("scope0");

        return (
            <>
                <div className="d-flex flex-row flex-wrap text-center justify-content-between">
                    <div className="d-flex flex-row flex-wrap text-center">
                        {
                            questions.map((q, qi) => {
                                return (
                                    <span
                                        key={qi}
                                        id={qi}
                                        className="align-self-center m-2 rounded"
                                        style={qi === scope0Index ? selectedStyle : defaultStyle}
                                        onClick={() => _handleQuesClick(qi)}
                                    >
                                        {"Q"}
                                        {qi + 1}
                                    </span>
                                )
                            })
                        }
                    </div>
                    <div id=''>
                        <MyButton
                            text={t("Save Progress")}
                            customColor={Colors.greenMaterial}
                            leftIcon={<i className="bi bi-save pe-2" />}
                            dataToggle={"modal"}
                            dataTarget={scope0Index<3&&'#scope0Modal'}
                            onClick={_handleSetScope0AnsRow}
                            disabled={
                                scope0ans.length === 0 ? true : false
                            }
                        />
                    </div>

                </div>
                <div
                    className="p-3 my-2"
                    style={{ borderRadius: 5, color: invertDark(theme) }}
                >
                    <div className="pb-2">
                        <span className="fw-bold pe-2">{"Q" + (scope0Index + 1)}</span>
                        {t(question.questionName)}{" "}
                    </div>
                    <div className="row flex-wrap">
                        {
                            question.answerOption.map((o, oi) => {
                                const ischecked = (scope0ans?.length > 0 ?
                                    (scope0ans.filter(dt => dt.questionId === question.questionId && dt.deviceType === o).length > 0 ?
                                        (scope0ans.find(dt => dt.questionId === question.questionId && dt.deviceType === o)?.status) : (scope0Data.find(dt => dt.deviceType === o) ? true : false))
                                    : (scope0Data.find(dt => dt.deviceType === o) ? true : false)) || false;
                                const value = (scope0ans?.length > 0 ?
                                    (scope0ans.filter(dt => dt.questionId === question.questionId && dt.deviceType === o).length > 0 ?
                                        (scope0ans.find(dt => dt.questionId === question.questionId && dt.deviceType === o)?.qty) : (scope0Data.find(dt => dt.deviceType === o)?.qty))
                                    : (scope0Data.find(dt => dt.deviceType === o)?.qty));
                                return (
                                    <div key={oi} className="col-lg-4 col-md-6 col-sm-11 py-1" style={{}}>
                                        <div className="d-flex align-items-center justify-content-between p-2" style={{ boxShadow: `0 3px 10px rgb(0 0 0 / 0.16)`, borderRadius: 5 }}>
                                            {scope0Index < 1 ? <div className="d-flex">
                                                <input id={o} checked={ischecked} onChange={e => _handleCheckChange(e, question.questionId, o)} className="me-2" type={'checkbox'} style={{ cursor: 'pointer' }} />
                                                <label htmlFor={o} className="col" style={{ cursor: 'pointer' }}>{t(o)}</label>
                                            </div> :
                                                
                                                    <label htmlFor={o} className="col" style={{ cursor: 'pointer' }}>{t(o)}</label>

                                               }
                                            {
                                                scope0Index < 1 ?
                                                    <div style={{ width: 80, minWidth: 80 }}>
                                                        <MyInput
                                                            id={o}
                                                            type={'number'}
                                                            disabled={!ischecked}
                                                            placeHolder={t('qty')}
                                                            value={value}
                                                            onChange={e => _handleQtyChange(e, question.questionId, o)}
                                                        />
                                                    </div>
                                                    : scope0Index === 1 || scope0Index === 2?
                                                    <div onChange={e => _handleCheckChange(e, question.questionId, o)}>                                                            
                                                            <input value="Yes" checked={cookies.get(o)=='Yes'?"checked":""} name={o}  className="me-1" type={'radio'} style={{ cursor: 'pointer' }} /><i style={{color:Colors.greenMaterial}}>Yes</i>
                                                            <input value="No" checked={cookies.get(o)=='No'?"checked":""} name={o}  className="ms-3 me-1" type={'radio'} style={{ cursor: 'pointer' }} /><i style={{color:Colors.low}}>No</i>
                                                        </div>
                                                    //: scope0Index === 2 ?
                                                //     <MyInput
                                                //     id={o}
                                                //     type={'number'}
                                                //     disabled={!ischecked}
                                                //     placeHolder={'qty'}
                                                //     value={value}
                                                //     onChange={e => _handleQtyChange(e, question.questionId, o)}
                                                // />
                                                 : <div onChange={e => _handleCheckChange(e, question.questionId, o)}>                                                            
                                                            <input value="Yes" checked={cookies.get(o)=='Yes'?"checked":""} name={o}  className="me-1" type={'radio'} style={{ cursor: 'pointer' }} /><i style={{color:Colors.greenMaterial}}>Yes</i>
                                                            <input value="No" checked={cookies.get(o)=='No'?"checked":""} name={o}  className="ms-3 me-1" type={'radio'} style={{ cursor: 'pointer' }} /><i style={{color:Colors.low}}>No</i>
                                                        </div>
                                            }

                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="d-flex flex-row justify-content-end pt-1 mb-3">
                    {scope0Index > 0 && (
                        <MyButton
                            text={t("Previous")}
                            customColor={theme === 'dark' ? Colors.primaryBule : Colors.blue}
                            onClick={_handlePreviousQuesClick}
                            leftIcon={<i className="bi bi-caret-left-fill" />}
                        />
                    )}

                    {scope0Index + 1 === questions.length ? (
                        <div className="ms-2">
                            <MyButton
                                text={t("Save Progress")}
                                customColor={Colors.greenMaterial}
                                leftIcon={<i className="bi bi-save pe-2" />}
                                onClick={() => console.log('Saving the progress ... ', toJS(scope0ans))}
                                disabled={
                                    scope0ans.length === 0 ? true : false
                                }
                            />
                        </div>
                    ) : (
                        <div className="ms-2">
                            <MyButton
                                text={t("Next")}
                                onClick={_handleNextQuesClick}
                                customColor={theme === 'dark' ? Colors.primaryBule : Colors.blue}
                                rightIcon={<i className="bi bi-caret-right-fill" />}
                            />
                        </div>
                    )}
                </div>

                <AlertDialog
                    showDialog={showScope0Dialog}
                    saveMoveToNext={saveMoveToNext_scpoe0}
                    discardMoveToNext={discardMoveToNext_scpoe0}
                    onDiscardClick={onDiscardClick_scpoe0}

                    dataBsToggle={"modal"}
                    dataBsTarget={"#scope0Modal"}
                />
                {
                    scope0Index === 0 ?
                    <Scope0Modal />
                    : 
                    scope0Index === 2 ?
                    <MultiMCSetupModal />
                    :null
                    //<Scope0PurchasedModal />
                }
                
                
            </>
        )
    })
)

const questions = [
    {
        questionId: 'scope0ques1',
        questionName: 'Does the company own or use any of the following large equipment?',
        answerOption: ['Boiler', 'Heater', 'Convector', 'Kiln', 'Furnace', 'Dryer', 'Backup generator', 'Combustion Turbine', 'Gas Stove', 'Pulverized-coal fired boiler', 'Incinerator', 'Flare Tower', 'Regenerative Thermal Oxidizer (RTO)', 'Chillers']
    },
    {
        questionId: 'scope0ques2',
        questionName: 'Does the company own or use any of the following small equipment?',
        answerOption: ['Fire extinguisher', 'GCB/GIS'],        
    },
    {
        questionId: 'scope0ques3',
        questionName: 'Does the company own or lease any of the assets for transportation purposes?',
        answerOption: ['Internal Combustion Engine Vehicles', 'Ship/boat', 'Airplanes']
    },
    {
        questionId: 'scope0ques4',
        questionName: 'Does the company have any waste treatment process or facility on-site?',
        answerOption: ['Waste water treatment', 'Landfill', 'Recycle', 'Anaerobic digestion of sludge', 'Septic Tank'],    
    },
    {
        questionId: 'scope0ques5',
        questionName: 'Does the company have any of the following manufacturing process?',
        answerOption: ['Semicon', 'LCD', 'PV'],        
    },
]