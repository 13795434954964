import { inject, observer } from "mobx-react";
import { Colors } from "../../Assets/Config/color.config";
import { useTranslation } from 'react-i18next';
import { toJS } from "mobx";
import './table.css';

export const BaselineTbl = inject("store")(
  observer((props) => {
    const { theme, companyName } = props.store.common;
    const { tableHeaders, data, baselineAverage } = props;
    const { t } = useTranslation("summary");

    return (
      <div className="p-2" 
        style={{
          boxShadow: "2px 4px 11px rgba(0, 0, 0, 0.25)",
          borderRadius: "10px",
          marginBottom: 8,
          color: "#c6c6c6"
        }}
      >
         <div
        className="baselineTbl-scrollbar"
        style={{
          background: theme === "dark" ? Colors.darkBlue : Colors.white,
          maxHeight: 220, overflow:"auto"
          // height: 222,
        }}
      >
        <table
          className="table mb-0"
          style={{
            color: Colors[theme].textColor,
            borderColor: "transparent",
            fontSize: 14
          }}
        >
          {/* <thead> */}
          <tr style={{top: -5, position: "sticky", width:"100%", color: '#f0f0f0'}}>
            <th
              className="p-2"
              // rowSpan="2"
              style={{
                padding: 5,
                top:0, position: "sticky", fontSize: 15,
                backgroundColor: Colors[theme].tableBgColor
              }}
            >
              {tableHeaders[0]}
            </th>
            <th
              //   colSpan="4"
              //   className="text-center"
              style={{
                // padding: 8,
                top:0, position: "sticky", fontSize: 15,
                textAlign: "center",
                borderLeft: " 1px solid",
                padding: 5,
                borderLeftColor:
                  theme === "dark" ? "rgb(255, 255, 255,0.1)" : Colors.Dark[4],
                  backgroundColor: Colors[theme].tableBgColor
              }}
            >
              {tableHeaders[1]}
            </th>
            <th
              //   colSpan="4"
              //   className="text-center"
              style={{
                // padding: 8,
                top:0, position: "sticky", fontSize: 15,
                textAlign: "center", padding: 5,
                borderLeft: " 1px solid",
                borderLeftColor:
                  theme === "dark" ? "rgb(255, 255, 255,0.1)" : Colors.Dark[4],
                  backgroundColor: Colors[theme].tableBgColor
              }}
            >
              {tableHeaders[2]}
            </th>
            <th
              scope="col"
              className="text-center"
              style={{
                // padding: 8,
                padding: 5,
                top:0, position: "sticky", fontSize: 15,
                backgroundColor: Colors[theme].tableBgColor
              }}
            ></th>
          </tr>
          {/* </thead> */}
          {companyName === 'Syscom' && data?.map((d, k1) => {

            const values = d.data.map(v=> Number(v.value));
            const minimum = Math.min(...values);
            const index = values.indexOf(minimum);
            const miniData = d.data[index];
            return (
              <tr key={k1}>
                <td className="px-3 py-2">
                  {t(d.label)} 
                </td>

                <td
                  align="center"
                  className="my-1"
                  style={{
                    borderLeft: " 1px solid",
                    borderLeftColor:
                      theme === "dark"
                        ? "rgb(255, 255, 255,0.1)"
                        : Colors.Dark[4],
                  }}
                >
                  {miniData.value?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }) || "-"}
                </td>
                <td
                  align="center"
                  className="my-1"
                  style={{
                    borderLeft: " 1px solid",
                    borderLeftColor:
                      theme === "dark"
                        ? "rgb(255, 255, 255,0.1)"
                        : Colors.Dark[4],
                  }}
                >
                  {d.baseline}
                </td>
                <td align="center">
                  {miniData.verified ? (
                    <i
                      style={{ color: Colors.greenMaterial }}
                      className="bi bi-check-circle"
                      title="Verified"
                    />
                  ) : (
                    <i
                      style={{ color: Colors.energy }}
                      className="bi bi-exclamation-circle"
                      title="Not Verified"
                    />
                  )}
                </td>
              </tr>
            );
          })}
          <tbody>
          
          {companyName !== 'Syscom' && baselineAverage && baselineAverage.map((d, k1) => {
            return (
              <tr key={k1} style={{}}>
                <td className="px-3 py-2" style={{}}>
                  {t(d.target)}
                </td>

                <td
                  align="center"
                  className="my-1"
                  style={{
                    borderLeft: " 1px solid",
                    borderLeftColor:
                      theme === "dark"
                        ? "rgb(255, 255, 255,0.1)"
                        : Colors.Dark[4],
                  }}
                >
                  {d.baselineEmission?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }) || "-"}
                </td>
                <td
                  align="center"
                  className="my-1"
                  style={{
                    borderLeft: " 1px solid",
                    borderLeftColor:
                      theme === "dark"
                        ? "rgb(255, 255, 255,0.1)"
                        : Colors.Dark[4],
                  }}
                >
                  {
                    d.target === 'Scope 2' && d.baselineYear === '-' ?
                    (2019)
                    : d.baselineYear
                  }
                </td>
                <td align="center">
                  {d.verified ? (
                    <i
                      style={{ color: Colors.greenMaterial }}
                      className="bi bi-check-circle"
                      title="Verified"
                    />
                  ) : (
                    <i
                      style={{ color: Colors.energy }}
                      className="bi bi-exclamation-circle"
                      title="Not Verified"
                    />
                  )}
                </td>
              </tr>
            )
          })}
          </tbody>
        </table>
      </div>
      </div>
     
    );
  })
);