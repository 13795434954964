import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import { MyInput, MuiSelector , FileUploadSelector } from ".";
import { Colors } from "../Assets/Config/color.config";
import { useTranslation } from 'react-i18next';
import { UploadedFileDescriptionModal } from "../Components/Survey/UploadedFileDescriptionModal";
import SurveyFileUploadingModal from "../Components/Survey/SurveyFileUploadingModal";
import Stack from "@mui/material/Stack";
import Upload from '@mui/icons-material/Upload';

export const MyDynamicTable = inject("store")(
  observer((props) => {
    const { category, questions, id, equipmentData, childSection , muiSelector , options } = props;
    const { answerArray, _handleNumberChange, _handleScope2DescriptionChange,  _handleSurveyFileChange, surveyFramework, frameWorkIndex, buildingId } = props.store.survey;
    const { theme } = props.store.common;
    const { t } = useTranslation("common");

    const questionList = toJS(questions)?.slice(0,1);
    const frameworkId = surveyFramework?.[frameWorkIndex]?.framework_id;

    
    //
    return (
      <div
        className="tableStyle table-responsive"
        style={{
          borderRadius: 10,
          backgroundColor: theme==="dark"?"#141c43":"#fff"
          // border: " 1px solid",
        }}
      >
        <table
          className="table mb-0"
          style={{ color: Colors[theme].textColor, borderColor: "transparent" }}
        >
          <thead>
            <tr>
              <th />
              {category?.map((v, k) => (
                <th className="text-start" style={{ minWidth: 300 }} key={k}>
                  {v==="Supplier" ? t("Energy Source") : t(v)}
                </th>
              ))}
              {questionList?.map((v, k) => (
                <th
                  colSpan={2}
                  className="text-center"
                  style={{
                    width: "200px",
                    borderLeft: " 1px solid",
                    borderLeftColor:
                      theme === "dark"
                        ? "rgb(255, 255, 255,0.1)"
                        : Colors.Dark[4],
                  }}
                  key={k}
                >
                  {t(v.questionName)} - Yearly
                </th>
              ))}
              {muiSelector ? 
              (
                <th 
                  className="text-center"
                  style={{
                    width: "200px",
                    borderLeft: " 1px solid",
                    borderLeftColor:
                      theme === "dark"
                        ? "rgb(255, 255, 255,0.1)"
                        : Colors.Dark[4],
                  }}
                >
                </th>
              ) : ''}
            </tr>
          </thead>
          <tbody style={{ verticalAlign : 'middle'}}>
            {equipmentData.map((v, k) => (
              <tr
                key={k}
                style={{
                  borderBottom: k + 1 !== equipmentData.length && " 1px solid",
                  borderBottomColor:
                    k + 1 !== equipmentData.length &&
                    (theme === "dark"
                      ? "rgb(255, 255, 255,0.1)"
                      : Colors.Dark[4]),
                  borderTop: " 1px solid",
                  borderTopColor:
                    theme === "dark"
                      ? "rgb(255, 255, 255,0.1)"
                      : Colors.Dark[4],
                }}
              >
                <td className="text-center fw-bold p-3" style={{ width: 40 }}>
                  {k + 1}
                </td>
                <td className="p-3">{t(v[category[0]?.toLowerCase()]) || t(v.equipmentName) || t(v.category) || t(v.supplier) }</td>
                {questionList?.map((q, index) => (
                  <>
                    <td
                      key={q._id + k}
                      className="text-center"
                      style={{
                        minWidth: 100,
                        borderLeft: " 1px solid",
                        borderLeftColor:
                          theme === "dark"
                            ? "rgb(255, 255, 255,0.1)"
                            : Colors.Dark[4],
                      }}
                    >
                      <MyInput
                        id={q._id + k}
                        type={"number"}
                        onChange={(e) =>
                          _handleNumberChange(e, q._id, childSection, null, index, v._id, null, q.answer.find(qa=>qa.dynamicDataId === v._id)?.linkedFileId, q.answer.find(qa=>qa.dynamicDataId === v._id)?.description)
                        }
                        pattern="[0-9]*"                        
                        //Take value from same Question ID and Dynamic ID
                        value={
                          answerArray?.filter((a) => a.questionId === q._id&&a.dynamicDataId===v._id )
                            .length > 0
                            ? answerArray?.filter(
                                (a) => a.questionId === q._id &&a.dynamicDataId===v._id 
                              )[0].answer
                            : q.answer.find(qa=>qa.dynamicDataId===v._id)?.answer[0]
                        }
                      />
                    </td>
                    <td className="p-3">{index === 0 ? v.unit : q.units}</td>
                    {muiSelector ? 
                      (
                        <td
                          className="text-center"
                          style={{
                            minWidth: 100,
                            borderLeft: " 1px solid",
                            borderLeftColor:
                              theme === "dark"
                                ? "rgb(255, 255, 255,0.1)"
                                : Colors.Dark[4],
                          }}
                        >
                          {muiSelector ? 
                          (<Stack direction="row" justifyContent="space-between" alignItems="center" spacing={0}>
                            <MuiSelector 
                            style={{ width: "200px" }}
                            options={options || []} 
                            value={options.find(o => o.value === (answerArray?.filter(
                              (a) =>
                                a.questionId === q._id &&
                                a.dynamicDataId === v._id
                            ).length > 0
                              ? answerArray?.filter(
                                  (a) =>
                                    a.questionId === q._id &&
                                    a.dynamicDataId === v._id
                                )[0]?.linkedFileId:q.answer.find(k=>k.dynamicDataId === v._id)?.linkedFileId))?.value === undefined?"":options.find(o => o.value === (answerArray?.filter(
                                  (a) =>
                                    a.questionId === q._id &&
                                    a.dynamicDataId === v._id
                                ).length > 0
                                  ? answerArray?.filter(
                                      (a) =>
                                        a.questionId === q._id &&
                                        a.dynamicDataId === v._id
                                    )[0]?.linkedFileId:q.answer.find(k=>k.dynamicDataId === v._id)?.linkedFileId))?.value}
                            disabled={q.answer.find(qa => qa.dynamicDataId === v._id) ? false : true} 
                            _handleSelect = {(e) => _handleSurveyFileChange(
                              e.target.value, 
                              q._id, 
                              childSection, 
                              index, 
                              v._id, 
                             index,
                              answerArray?.filter(
                                (a) =>
                                  a.questionId === q._id &&
                                  a.dynamicDataId === v._id
                              ).length > 0
                                ? answerArray?.filter(
                                    (a) =>
                                      a.questionId === q._id &&
                                      a.dynamicDataId === v._id
                                  )[0]?.description
                                : q.answer.find(
                                    (qa) => qa.dynamicDataId === v._id
                                  )?.description,
                              answerArray?.filter(
                              (a) =>
                                a.questionId === q._id &&
                                a.dynamicDataId === v._id
                                ).length > 0
                                  ? answerArray?.filter(
                                      (a) =>
                                        a.questionId === q._id &&
                                        a.dynamicDataId === v._id
                                    )[0]?.answer
                                  : q.answer.find(
                                      (qa) => qa.dynamicDataId === v._id
                                    )?.answer[0])
                              }
                            />
                            <span data-bs-toggle="modal" data-bs-target="#Scope2SurveyFileModal" style={{ cursor: "pointer" }}>
                            <button title={t("Browse your files")} onClick={(e) =>
                            _handleSurveyFileChange(
                              "uploadBtn", 
                              q._id, 
                              childSection, 
                              index, 
                              v._id, 
                             index,
                              answerArray?.filter(
                                (a) =>
                                  a.questionId === q._id &&
                                  a.dynamicDataId === v._id
                              ).length > 0
                                ? answerArray?.filter(
                                    (a) =>
                                      a.questionId === q._id &&
                                      a.dynamicDataId === v._id
                                  )[0]?.description
                                : q.answer.find(
                                    (qa) => qa.dynamicDataId === v._id
                                  )?.description,
                              answerArray?.filter(
                              (a) =>
                                a.questionId === q._id &&
                                a.dynamicDataId === v._id
                                ).length > 0
                                  ? answerArray?.filter(
                                      (a) =>
                                        a.questionId === q._id &&
                                        a.dynamicDataId === v._id
                                    )[0]?.answer
                                  : q.answer.find(
                                      (qa) => qa.dynamicDataId === v._id
                                    )?.answer[0])
                              } style={{ marginBottom: "-5px", border: theme === "dark"? "1px solid #5e5e5e" : "1px solid #c6c6c6", minWidth: "36px !important", maxWidth: "36px !important", padding: "9px", borderRadius: "5px", background: theme === "dark"?"transparent" : "#fff"}}><Upload style={{ color: "rgb(4, 197, 0)" }}/></button>
                              </span>
                            </Stack>
                            ) 
                            : ''}                         
                          <SurveyFileUploadingModal modalId="Scope2SurveyFileModal" btnId="scope2_save_file_and_description" frameworkId={frameworkId} buildingId={buildingId} />
                          {muiSelector ?
                        (<div id="uploaded_file_byCat" style={{marginTop: 3}}>
                        <MyInput
                          // disabled
                          id={q._id + k + index + "_"}
                          required={true}
                          isTextArea={true}
                          value={answerArray?.filter(
                            (a) =>
                              a.questionId === q._id &&
                              a.dynamicDataId === v._id
                          ).length > 0
                            ? answerArray?.filter(
                                (a) =>
                                  a.questionId === q._id &&
                                  a.dynamicDataId === v._id
                              )[0]?.description
                            : q.answer.find(
                                (qa) => qa.dynamicDataId === v._id
                              )?.description}
                          onChange={(e) =>
                            _handleScope2DescriptionChange(
                              e, 
                              q._id, 
                              childSection, 
                              null, 
                              index, 
                              v._id, 
                              index, 
                              answerArray?.filter(
                                (a) =>
                                  a.questionId === q._id &&
                                  a.dynamicDataId === v._id
                              ).length > 0
                                ? answerArray?.filter(
                                    (a) =>
                                      a.questionId === q._id &&
                                      a.dynamicDataId === v._id
                                  )[0]?.linkedFileId
                                : q.answer.find(
                                    (qa) => qa.dynamicDataId === v._id
                                  )?.linkedFileId,
                              answerArray?.filter(
                              (a) =>
                                a.questionId === q._id &&
                                a.dynamicDataId === v._id
                                ).length > 0
                                  ? answerArray?.filter(
                                      (a) =>
                                        a.questionId === q._id &&
                                        a.dynamicDataId === v._id
                                    )[0]?.answer
                                  : q.answer.find(
                                      (qa) => qa.dynamicDataId === v._id
                                    )?.answer[0])
                              }
                          />   
                        </div>) : ''
                        }
                        </td>
                      ) : ''}
                  </>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  })
);

