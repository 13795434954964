import * as React from "react";
import PropTypes from "prop-types";
import Slider, { SliderThumb } from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import { Colors } from "../Assets/Config/color.config";



export const MySlider = (props) => {
  const { baseLine, value, theme, unit } = props;
  const [sliderValue, setSliderValue] = React.useState(value);
  var num = baseLine

  const PrettoSlider = styled(Slider)({
    color: Colors.water,
    height: 8,
    "& .MuiSlider-track": {
      border: "none",
      height:10
    },
    "& .MuiSlider-thumb": {
      height: 20,
      width: 10,
      backgroundColor: '#1D53DC',
      borderRadius:10,
      // border: "1px solid white",
      "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
        boxShadow: "inherit",
      },
      "&:before": {
        display: "none",
      },
    },
    "& .MuiSlider-rail": {
      backgroundColor: theme === "dark" ? ' rgb(255, 255, 255,0.1) ' : Colors.Dark[4],
      height:10,
      opacity: '0.5'
    },
    "& .MuiSlider-valueLabel": {
      lineHeight: 1.2,
      fontSize: 12,
      background: "unset",
      padding: 0,
      width: 32,
      height: 32,
      borderRadius: "50% 50% 50% 0",
      backgroundColor: Colors.water,
      transformOrigin: "bottom left",
      transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
      "&:before": { display: "none" },
      "&.MuiSlider-valueLabelOpen": {
        transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
      },
      "& > *": {
        transform: "rotate(45deg)",
      },
    },
  });
  
  function _handleChanges(v){
    
    setSliderValue(v);
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Typography>
        <span style={{fontSize:12,}} className="d-flex justify-content-end m-0 p-0">
          {(num - (sliderValue * num) / 100).toFixed(2) + " " + unit}
        </span>
      </Typography>
      <PrettoSlider
        valueLabelFormat={(sliderValue) => "-"+sliderValue + "%"}
        valueLabelDisplay="auto"
        aria-label="pretto slider"
        defaultValue={sliderValue}
        value={sliderValue}
        // onChange={(e) => _handleChanges(e.target.value)}
      />
    </Box>
  );
};
