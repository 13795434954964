import * as React from "react";
import MuiToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { styled } from "@mui/material/styles";
import "../App.css";
import { inject, observer } from "mobx-react";
import Store from "../Store";
import { toJS } from "mobx";
import { Colors, invertDark } from "../Assets/Config/color.config";
import { useTranslation } from "react-i18next";;

export const MyToggleButton = inject("store")(
  observer((props) => {
    const { viewStatus, _handleChangeDetailView, theme } = Store.common;
    const {t} = useTranslation("common")

    const handleChange = () => {
      _handleChangeDetailView(viewStatus === "normalView" ? "detailsView" : "normalView");
    };

    const ToggleButton = styled(MuiToggleButton)({
        color: theme === "dark" ? Colors.white : Colors.dark[4],
      "&.Mui-selected, &.Mui-selected:hover": {
        color: "white",
        backgroundColor: theme === "dark" ? Colors.lightBlue : Colors.blue,
        borderColor: "none",
        boxShadow: "0 0 10px #333333",
        textTransform: "none"
      }
    });

    return (
      <ToggleButtonGroup
        size="small"
        value={viewStatus}
        // exclusive
        onChange={handleChange}
        aria-label="Platform"
      >
        <ToggleButton value="normalView">{t("Normal View")}</ToggleButton>
        <ToggleButton value="detailsView">{t("Details View")}</ToggleButton>
      </ToggleButtonGroup>
    );
  })
);
