import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import { Colors } from "../../Assets/Config/color.config";
import { useTranslation } from 'react-i18next';
import './table.css';

export const EmissionTbl = inject("store")(
  observer((props) => {
    const { theme, companyName } = props.store.common;
    const { tableHeaders, data, subHeaders, baselineAverage } = props;
    const { t } = useTranslation("summary");

    // console.log("EmissionTbl", toJS(data));
    
    const printableNumber = (n) => { return (n > 0) ? "+" + n : n; };
    return (
      <div className="p-2" 
        style={{
          boxShadow: "2px 4px 11px rgba(0, 0, 0, 0.25)",
          borderRadius: "10px",
          marginBottom: 8,
          color: "#c6c6c6"
        }}
      >
      <div
        className="emissionTbl-scrollbar"
        style={{
          background: theme === "dark" ? Colors.darkBlue : Colors.white,
          maxHeight: 220, overflow:"auto"
          // height: 222,
        }}
      >
        <table
          className="table mb-0 "
          style={{ color: Colors[theme].textColor, 
            borderColor: "transparent", fontSize: 14 }}
        >
          <tr style={{ color: '#f0f0f0' }}>
            <th
              className="p-2"
              rowSpan="2"
              style={{
                padding: 5,
                top:0, position: "sticky", fontSize: 15,
                backgroundColor: Colors[theme].tableBgColor
              }}
            >
              {tableHeaders[0]}
            </th>
            <th
              colSpan="5"
              className="text-center"
              style={{
                top:0, position: "sticky", fontSize: 15,
                textAlign: "center",
                borderLeft: " 1px solid",
                padding: 5,
                borderLeftColor:
                  theme === "dark" ? "rgb(255, 255, 255,0.1)" : Colors.Dark[4],
                  backgroundColor: Colors[theme].tableBgColor
              }}
            >
              {tableHeaders[1]}
            </th>
          </tr>
          <tr style={{ color: '#f0f0f0' }}>
            {subHeaders.map((v, k) => {
              return (
                <th
                  className="text-center"
                  key={k}
                  style={{
                    top:32, position: "sticky", fontSize: 15,
                    textAlign: "center",
                    borderLeft: " 1px solid",
                    padding: 5,
                    borderLeftColor:
                      theme === "dark" ? "rgb(255, 255, 255,0.1)" : Colors.Dark[4],
                      backgroundColor: Colors[theme].tableBgColor
                  }}
                >
                  {v}

                </th>
              );
            })}
          </tr>
          {data?.map((v1, k1) => {
            return (
              <tr key={k1} style={{}}>
                <td className="px-3 py-2" style={{}}>
                  {t(v1.label)}
                </td>
                {/* <td className="px-3 py-2" style={{}}>
                
                
                </td> */}
                {subHeaders.map((v2, k2) => {
                  const ispercent = k2 !== 0;
                  return (
                    <td
                      key={k2}
                      className="px-3 my-1"
                      style={{
                        borderLeft: " 1px solid",
                        borderLeftColor:
                          theme === "dark"
                            ? "rgb(255, 255, 255,0.1)"
                            : Colors.Dark[4],
                        position: "sticky",
                        top:0,
                        minWidth: 200,
                        textAlign: "center",
                      }}
                    >
                      {ispercent ? (
                        <div
                          className="d-flex flex-nowrap row p-0"
                          style={{
                            height: "100%",
                            // width: "100%",
                            // position: "absolute",
                            top: 0,
                            left: 11,
                            textAlign: "center",
                          }}
                        >
                          <div className="col py-2 m-0">
                            {v1.data
                              .find((aa) => aa.year == v2)
                              ?.value?.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }) || "-"}
                          </div>
                          <div
                            className="col py-2 m-0"
                            style={{
                              // borderLeft: " 1px solid",
                              // borderLeftColor:
                              //   theme === "dark"
                              //     ? "rgb(255, 255, 255,0.1)"
                              //     : Colors.Dark[4],
                              color: (v1.data
                                .find((aa) => aa.year === v2)
                                ?.value > v1.data.find((aa) => aa.year === subHeaders[0])?.value) ? Colors.low : Colors.greenMaterial,
                            }}
                          >
                            {
                              v1.data.find((aa) => aa.year === ( v1.label === 'Scope 1' && companyName==='Syscom' ? subHeaders[1] :subHeaders[0]))?.value &&  v1.data.find((aa) => aa.year == v2)?.value !==undefined
                                ? printableNumber((
                                  ((v1.data.find((aa) => aa.year === v2)
                                    ?.value -
                                    v1.data.find((aa) => aa.year === (v1.label === 'Scope 1'&& companyName==='Syscom'  ? subHeaders[1] : subHeaders[0]))
                                      ?.value) 
                                     /
                                  v1.data.find((aa) => aa.year === (v1.label === 'Scope 1'&& companyName==='Syscom'  ? subHeaders[1] : subHeaders[0]))?.value)*100
                                ).toFixed(2)) + "%"
                                : "-"}
                          </div>
                        </div>
                      ) : (
                        <>
                          {v1.data
                            .find((aa) => aa.year === v2)
                            ?.value?.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }) || "-"}
                          {v1.data.find((aa) => aa.year === v2)?.verified === true ? (
                            <span>
                              <i
                                className="bi bi-check-circle"
                                style={{ color: Colors.greenMaterial }}
                                title="Verified"
                              />
                            </span>
                          ) : v1.data.find((aa) => aa.year === v2)?.verified === false ?
                            (
                              <span>
                                <i
                                  className="bi bi-exclimation-circle"
                                  style={{ color: Colors.greenMaterial }}
                                  title="Verified"
                                />
                              </span>
                            ) : null}
                        </>
                      )}
                    </td>
                  );
                })}

                {/* <td className="px-3 my-1" style={{
                                        borderLeft: " 1px solid",
                                        borderLeftColor:
                                            theme === "dark"
                                                ? "rgb(255, 255, 255,0.1)"
                                                : Colors.Dark[4],
                                    }}>{v1[2020]}</td>
                                    <td className="px-3 my-1" style={{
                                        borderLeft: " 1px solid",
                                        borderLeftColor:
                                            theme === "dark"
                                                ? "rgb(255, 255, 255,0.1)"
                                                : Colors.Dark[4],
                                    }}>{v1[2021]}</td>
                                    <td className="px-3 py-0 my-0" style={{
                                        borderLeft: " 1px solid",
                                        borderLeftColor:
                                            theme === "dark"
                                                ? "rgb(255, 255, 255,0.1)"
                                                : Colors.Dark[4],
                                    }}>

                                        <div className="d-flex p-0 m-0">
                                            <di className='col p-0 m-0'>
                                                {v1[2022]}
                                            </di>
                                            <di className='col border p-0 m-0'>
                                                100%
                                            </di>
                                        </div>
                                    </td> */}
              </tr>
            );
          })}
        </table>
      </div>
      </div>
     
    );
  })
);
