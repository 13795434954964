import {
    action,
    makeObservable,
    observable,
    runInAction,
    set,
    toJS,
} from "mobx";
import { toast } from "react-toastify";
import { updateSiteInfo } from "../API/api.building";

class SiteManagement {
    editFormList = [];
    newFloorInput = "";
    existingFloors = [];
    selectedSiteType = null;
    siteImage = null;

    constructor() {
        makeObservable(this, {
            editFormList: observable,
            newFloorInput: observable,
            existingFloors: observable,
            selectedSiteType: observable,
            siteImage: observable,

            _handleEditRow: action.bound,
            _handleSubmitUpdate: action.bound,
            _handleCloseForm: action.bound,
            _handleInputChange: action.bound,
            _handleAddExistingFloors: action.bound,
            _handleNewFloorInput: action.bound,
            _handleRemoveExistingFloors: action.bound,
            _handleSiteTypeChange: action.bound,
            _handleChangeSiteImage: action.bound
        });
    }

    _handleEditRow = (list) => {
        this.editFormList = list;
        this.newFloorInput = "";
        this.siteImage = null;
        this.existingFloors = list.unit || [];
    }
    _handleInputChange = (e) => {
        const key = e.target.id;
        const value = e.target.value;
        const newObj = {...this.editFormList};
        newObj[key] = value;
        runInAction(() => {
            this.editFormList = newObj;
        })
    }
    _handleChangeSiteImage = (e) => {
        runInAction(() => {
            this.siteImage = e.target.files[0];
        })
    }
    _handleSiteTypeChange = (e) => {
        runInAction(() => {
            this.selectedSiteType = e;
        });
    } 
    _handleNewFloorInput = (e, index) => {
        const temp = this.existingFloors;
        // temp[index] = {_id: 'unit01', unitName: e.target.value};/////////////temporary unit id///////
        runInAction(() => {
            this.existingFloors = temp;
        });
    }
    _handleAddExistingFloors = () => {
        const temp = [...this.existingFloors];
        temp.push(this.newFloorInput);
        runInAction(() => {
            this.existingFloors = temp;
            this.newFloorInput = "";
        });
    }
    _handleRemoveExistingFloors = (index) => {
        const temp = [...this.existingFloors];
        temp.splice(index,1);
        runInAction(() => {
            this.existingFloors = temp;
        });
    }
    _handleSubmitUpdate = () => {
        if(this.editFormList){
            const list = {
                buildingName: this.editFormList.buildingName,
                buildingType: this.selectedSiteType ? this.selectedSiteType.label : this.editFormList.type,
                image: this.siteImage,
                floorOrUnit: toJS(this.existingFloors)
            }
            var formdata = new FormData();
           
            formdata.append("buildingName", toJS(this.editFormList.buildingName));
            formdata.append("buildingType", this.selectedSiteType ? toJS(this.selectedSiteType.label) : toJS(this.editFormList.type));
            if(this.siteImage){
                formdata.append("siteImage", toJS(this.siteImage));
            }
            formdata.append("floorOrUnit", toJS(this.existingFloors));

            const buildingId = this.editFormList.id;

            // updateSiteInfo(buildingId, formdata, (err, data) => {
            //     if(err){
            //         toast.error(err);
            //     }else {
            //         if(!data.error){
            //             toast.success(data.payload);
            //             this._handleCloseForm();
            //             window.location.reload();
            //         }else toast.error(data.error);
            //     }
            // });
        }
    }

    _handleCloseForm = () => {
        this.editFormList = [];
        this.newFloorInput = "";
        this.siteImage = null;
    }
   
}

export default new SiteManagement();