import Select from "react-select";
import { Colors, invertDark } from "../Assets/Config/color.config";
import { useTranslation } from "react-i18next";

export const FileUploadSelector = (props) => {
  const { t } = useTranslation("common");
  const {
    options,
    value,
    _handleSelect,
    disabled,
    menuListColor,
    isMulti,
    id,
    isSearchable,
    placeholder = t("Select_"),
    background,
    getOptionLabel,
    noBorder,
    width,
  } = props;

  const theme = localStorage.getItem("theme");
  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: background ? background : Colors[theme].secondBackground,
      color: theme === "dark" ? Colors.Dark[4] : Colors.Dark[1],
      minHeight: 43,
      fontSize: 14,
      borderColor: noBorder
        ? "transparent"
        : theme === "dark"
        ? Colors.Dark[2]
        : Colors.Dark[4],
      width: width?width:"",
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        borderColor: Colors.Dark[3],
        cursor: "pointer",
      },
    }),
    menu: (base) => ({
      ...base,
      fontSize: 14,
      borderBottomRadius: 10,
      zIndex: 9999,
    }),
    menuList: (base) => ({
      ...base,
      color: menuListColor ? menuListColor : null,
    }),
    input: (base, state) => ({
      ...base,
      color: "inherit",
    }),
    singleValue: (base) => ({
      ...base,
      color: disabled ? "#808080" : invertDark(theme),
    }),
  };
  const Icon = ({ innerRef, innerProps }) => (
    <span
      className="px-2"
      role="img"
      aria-label="Dropdown emoji"
      ref={innerRef}
      {...innerProps}
    >
      <i
        className="bi bi-caret-down-fill"
        style={{ color: Colors.greenMaterial }}
      />
    </span>
  );
  const components = {
    DropdownIndicator: Icon,
  };

  return (
    <div
      id={id}
      style={{ width: window.screen.width < 330 && 200 }}
      className={`${id === "manualSector" && "d-flex m-2"}`}
    >
      <Select
        getOptionLabel={getOptionLabel}
        placeholder={placeholder}
        isSearchable={isSearchable}
        value={value}
        closeMenuOnSelect={isMulti ? false : true}
        isMulti={isMulti ? true : false}
        styles={customStyles}
        options={options}
        defaultValue={value}
        onChange={(e) => _handleSelect(e)}
        isDisabled={disabled}
        components={components}
      />
    </div>
  );
};
