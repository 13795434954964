import { action, observable, makeObservable, toJS, runInAction } from "mobx";
import { addOrganizationSetUp, fetchAllEnergySource } from "../API/api.quickOrganizationSetUp";
import { getEmissionByCalculatedMethod } from "../API/api.survey";
import { toast } from "react-toastify";
import common from "./common";
import { MyConstants } from '../Constants/MyConstants';
import { Cookies } from "react-cookie";

class QuickOrganizationSetUp {
  cookies = new Cookies();
  siteModuleData = this.cookies.get("siteModuleData");
  selectedEquipments = [];
  equipmentListDetail = [];
  selectedPageIndex = 0;
  checkedIndustry = null;
  energySourceList = [];
  industries = [];
  validationErr = [];
  validDetail = [];
  loading = false;
  activityTypeOptions = MyConstants.ACTIVITY_TYPE_OPTIONS;
  yearOptions = MyConstants.YEAR_OPTIONS;
  vehicleTypeOptions = MyConstants.VEHICLE_TYPE_OPTIONS;
  emissionFactorOptions = MyConstants.EMISSION_FACTOR_OPTIONS;
  deviceTypeOptions = MyConstants.DEVICE_TYPE_OPTIONS;
  unitOptions = MyConstants.UNIT_OPTIONS;
  calculationApproachOptions = MyConstants.CALCULATION_APPROACH_OPTIONS;
  calculateTypeOptions = MyConstants.CALCULATE_TYPE_OPTIONS;
  transportModeOptions = MyConstants.TRANSPORT_MODE_OPTIONS;

  sourceDataList = [];

  constructor() {
    makeObservable(this, {
      selectedEquipments: observable,
      selectedPageIndex: observable,
      checkedIndustry: observable,
      equipmentListDetail: observable,
      energySourceList: observable,
      industries: observable,
      validationErr: observable,
      validDetail: observable,
      sourceDataList: observable,
      loading: observable,
      siteModuleData: observable,
      unitOptions: observable,

      _handleSetPageIndex: action.bound,
      _handleQtyChange: action.bound,
      _handleCheckEquipment: action.bound,
      _handleCheckIndustry: action.bound,
      _handleSetEquipmentListDetails: action.bound,
      _handleEquipTextChange: action.bound,
      _handleAddOrganizationSetUp: action.bound,
      _getEnergySourceList: action.bound,
      getIndustriesData: action.bound,
      _handleGetEmissionByCalculatedMethod: action.bound
    })
  }


  _handleAddOrganizationSetUp = async (navigate, moduleId) => {
    let validationErr = [];
    let validDetail = [];
    for (let i = 0; i < this.equipmentListDetail.length; i++) {
      const dt = await this.equipmentListDetail[i];
      const category = await dt.category;
      const assets = await dt.companyAsset.reduce((r, c) => {
        const R = [...r];
        const arr = c.assets.map((a, ai) => ({
          asset: a,
          index: ai,
          title: c.title
        }))
        R.push(...arr)

        return R
      }, []);

      for (let j = 0; j < assets.length; j++) {
        const asset = assets[j].asset;
        const index = assets[j].index;
        const title = assets[j].title;

        // if (!asset.baselineYear || asset.baselineYear === "") {
        //   validationErr.push((title + '_' + index))
        //   validDetail.push({"baselineYear" : title + '_' + index})
        // }

        // if(!asset.unit || asset.unit === ""){
        //   validationErr.push((title + '_' + index))
        //   validDetail.push({"unit" : title + '_' + index})
        // }
       
        if (category === "Stationary Combustion") {

          if (!asset.baselineYear || asset.baselineYear === "") {
            validationErr.push((title + '_' + index))
            validDetail.push({"baselineYear" : title + '_' + index})
          }
  
          if(!asset.unit || asset.unit === ""){
            validationErr.push((title + '_' + index))
            validDetail.push({"unit" : title + '_' + index})
          }

          if (!asset.fuelType || asset.fuelType === "" || !asset.commissionedDate || asset.commissionedDate === ""){
            validationErr.push((title + '_' + index))
          }

          if(!asset.assetName || asset.assetName === ""){
            validationErr.push((title + '_' + index))
            validDetail.push({"assetName" : title + '_' + index})
          }

          if(!asset.equipmentType || asset.equipmentType === ""){
            validationErr.push((title + '_' + index))
            validDetail.push({"equipmentType" : title + '_' + index})
          }

          if(!asset.brand || asset.brand === ""){
            validationErr.push((title + '_' + index))
            validDetail.push({"brand" : title + '_' + index})
          }

        } else if (category === "Mobile Combustion") {

          if (!asset.baselineYear || asset.baselineYear === "") {
            validationErr.push((title + '_' + index))
            validDetail.push({"baselineYear" : title + '_' + index})
          }
  
          if(!asset.unit || asset.unit === ""){
            validationErr.push((title + '_' + index))
            validDetail.push({"unit" : title + '_' + index})
          }

          if (!asset.fuelType || asset.fuelType === "" || !asset.commissionedDate || asset.commissionedDate === "" ) {
            validationErr.push((title + '_' + index))
          }

          if(!asset.assetName || asset.assetName === ""){
            validationErr.push((title + '_' + index))
            validDetail.push({"assetName" : title + '_' + index})
          }

          if(!asset.activityType || asset.activityType === ""){
            validationErr.push((title + '_' + index))
            validDetail.push({"activityType" : title + '_' + index})
          }

          if(!asset.vehicleType || asset.vehicleType === ""){
            validationErr.push((title + '_' + index))
            validDetail.push({"vehicleType" : title + '_' + index})
          }

          if(!asset.vehicleType || asset.vehicleType === ""){
            validationErr.push((title + '_' + index))
            validDetail.push({"model" : title + '_' + index})
          }
            
        } else if (category === "Refrigerants and Fugitives") {

          if (!asset.baselineYear || asset.baselineYear === "") {
            validationErr.push((title + '_' + index))
            validDetail.push({"baselineYear" : title + '_' + index})
          }
  
          if(!asset.unit || asset.unit === ""){
            validationErr.push((title + '_' + index))
            validDetail.push({"unit" : title + '_' + index})
          }

          if ( !asset.fuelType || asset.fuelType === "" || !asset.commissionedDate || asset.commissionedDate === "") {
            validationErr.push((title + '_' + index))
          }

          if(!asset.assetName || asset.assetName === ""){
            validationErr.push((title + '_' + index))
            validDetail.push({"assetName" : title + '_' + index})
          }

          if(!asset.brand || asset.brand === ""){
            validationErr.push((title + '_' + index))
            validDetail.push({"brand" : title + '_' + index})
          }

        } else if (category === "Imported energy (steam, heating, cooling and compressed air)") {

          if (!asset.baselineYear || asset.baselineYear === "") {
            validationErr.push((title + '_' + index))
            validDetail.push({"baselineYear" : title + '_' + index})
          }
  
          if(!asset.unit || asset.unit === ""){
            validationErr.push((title + '_' + index))
            validDetail.push({"unit" : title + '_' + index})
          }

          if (!asset.calculateMethod || asset.calculateMethod === "" || !asset.calculateType || asset.calculateType === "" || !asset.calculationApproach || asset.calculationApproach === "") {
            validationErr.push((title + '_' + index))
          }

          if(!asset.year || asset.year === ""){
            validationErr.push((title + '_' + index))
            validDetail.push({"year" : title + '_' + index})
          }

          if(!asset.supplierName || asset.supplierName === ""){
            validationErr.push((title + '_' + index))
            validDetail.push({"supplierName" : title + '_' + index})
          }

          if(!asset.emissionFactor || asset.emissionFactor === ""){
            validationErr.push((title + '_' + index))
            validDetail.push({"emissionFactor" : title + '_' + index})
          }

        } else if (category === "Upstream T&D") {

          if (!asset.baselineYear || asset.baselineYear === "") {
            validationErr.push((title + '_' + index))
            validDetail.push({"baselineYear" : title + '_' + index})
          }
  
          if(!asset.unit || asset.unit === ""){
            validationErr.push((title + '_' + index))
            validDetail.push({"unit" : title + '_' + index})
          }

          if(!asset.transportMode || asset.transportMode === ""){
            validationErr.push((title+'_'+index))
          }

          if(!asset.year || asset.year === ""){
            validationErr.push((title + '_' + index))
            validDetail.push({"year" : title + '_' + index})
          }

          if(!asset.transportationName || asset.transportationName === ""){
            validationErr.push((title + '_' + index))
            validDetail.push({"transportationName" : title + '_' + index})
          }

          if(!asset.vehicleType || asset.vehicleType === ""){
            validationErr.push((title + '_' + index))
            validDetail.push({"vehicleType" : title + '_' + index})
          }

          if(!asset.activityType || asset.activityType ===""){
            validationErr.push((title + '_' + index))
            validDetail.push({"activityType" : title + '_' + index})
          }

        } else if( asset.category === "Emission Removal & Mitigation" ){

          if (!asset.baselineYear || asset.baselineYear === "") {
            validationErr.push((title + '_' + index))
            validDetail.push({"baselineYear" : title + '_' + index})
          }
  
          if(!asset.unit || asset.unit === ""){
            validationErr.push((title + '_' + index))
            validDetail.push({"unit" : title + '_' + index})
          }

          if(!asset.emissionFactor){
            validationErr.push((title + '_' + index))
            validDetail.push({"emissionFactor" : title + '_' + index})
          }

          if(!asset.name){
            validationErr.push((title + '_' + index))
            validDetail.push({"name" : title + '_' + index})
          }

          if(!asset.year){
            validationErr.push((title + '_' + index))
            validDetail.push({"year" : title + '_' + index})
          }

        } else if(asset.category === "Waste"){

          if (!asset.baselineYear || asset.baselineYear === "") {
            validationErr.push((title + '_' + index))
            validDetail.push({"baselineYear" : title + '_' + index})
          }
  
          if(!asset.unit || asset.unit === ""){
            validationErr.push((title + '_' + index))
            validDetail.push({"unit" : title + '_' + index})
          }

          if(!asset.wasteTreatmentMethod || !asset.transportMode){
            validationErr.push((title + '_' + index));
          }

          if(!asset.name){
            validationErr.push((title + '_' + index))
            validDetail.push({"name" : title + '_' + index})
          }

          if(!asset.year){
            validationErr.push((title + '_' + index))
            validDetail.push({"year" : title + '_' + index})
          }

        } else if(asset.category === "Purchased Goods"){

          if (!asset.baselineYear || asset.baselineYear === "") {
            validationErr.push((title + '_' + index))
            validDetail.push({"baselineYear" : title + '_' + index})
          }
  
          if(!asset.unit || asset.unit === ""){
            validationErr.push((title + '_' + index))
            validDetail.push({"unit" : title + '_' + index})
          }

          if(!asset.supplierName){
            validationErr.push((title + '_' + index))
            validDetail.push({"supplierName" : title + '_' + index})
          }

          if(!asset.year){
            validationErr.push((title + '_' + index))
            validDetail.push({"year" : title + '_' + index})
          }
        }
      }
    }
    if (validationErr.length === 0) {
      let newListArray = [];
      for(let a = 0; a < this.equipmentListDetail.length; a++){
        if(this.equipmentListDetail[a].companyAsset.length > 0){
          newListArray.push(toJS(this.equipmentListDetail[a]));
        }
      }

        for(let a = 0; a < newListArray.length; a++){
          if(newListArray[a].companyAsset.length > 0){
            for(let b = 0; b < newListArray[a].companyAsset.length; b++){
              for(let c = 0; c < newListArray[a].companyAsset[b].assets.length; c++){
                let category = newListArray[a].companyAsset[b].assets[c].category;
                let scope = newListArray[a].companyAsset[b].scope;
                let type = null;
                let calculatedMethod = null;
                let unit = newListArray[a].companyAsset[b].assets[c].unit;
                let emissionFactor = null;
                let vehicleType = null;
                if(newListArray[a].companyAsset[b].scope === "1"){
                  scope = "scope" + newListArray[a].companyAsset[b].scope;
                  if(newListArray[a].companyAsset[b].assets[c].category === 'Refrigerants and Fugitives'){
                    type = "Refrigerants";
                  }else if(newListArray[a].companyAsset[b].assets[c].category === "Mobile Combustion"){
                    type = "Mobile Combustion";
                    vehicleType = newListArray[a].companyAsset[b].assets[c].vehicleType;
                  }else{
                    type = "Fuel Type";
                  }
                  calculatedMethod = newListArray[a].companyAsset[b].assets[c].fuelType;
                  emissionFactor = null;
                }else if(newListArray[a].companyAsset[b].scope === "2"){     

                  scope = "scope" + newListArray[a].companyAsset[b].scope;            
                  if(newListArray[a].companyAsset[b].assets[c].type === "Location Based"){
                    type = "Location Based";
                  }else if(newListArray[a].companyAsset[b].assets[c].type === "Market Based"){
                    type = "Market Based";
                  }else{
                    type = "Steam and Heat";
                  }
                  calculatedMethod = newListArray[a].companyAsset[b].assets[c].calculateMethod;
                  emissionFactor = newListArray[a].companyAsset[b].assets[c].emissionFactor;

                }else if(newListArray[a].companyAsset[b].scope === "3"){

                  scope = "scope" + newListArray[a].companyAsset[b].scope;
                  if(newListArray[a].companyAsset[b].assets[c].category === 'Waste'){
                    console.log("waste==>", newListArray[a].companyAsset[b].assets[c].category)
                    type = "Waste Treatment Method";
                    calculatedMethod = newListArray[a].companyAsset[b].assets[c].wasteTreatmentMethod;
                  }else{
                    type = "Lookup name";
                    calculatedMethod = newListArray[a].companyAsset[b].assets[c].vehicleType;                    
                  }    
                  emissionFactor = null;    

                }else{

                  scope = newListArray[a].companyAsset[b].scope;
                  type = "Emission Removal & Mitigation";
                  calculatedMethod = newListArray[a].companyAsset[b].assets[c].emissionFactor;
                  emissionFactor = newListArray[a].companyAsset[b].assets[c].emissionFactor;
                  
                }

                  let aLength = newListArray.length - 1;
                  let bLength = newListArray[a].companyAsset.length - 1;
                  let cLength = newListArray[a].companyAsset[b].assets.length - 1;
  
                  this._handleGetEmissionByCalculatedMethod(type,  calculatedMethod, unit,  emissionFactor,  scope, a,  b, c, navigate, moduleId, aLength, bLength, cLength, category, vehicleType);           
              }
            }
          }
          
        };
    } else {
      toast.error("Plase fill data completely!")
      this.validationErr = validationErr;
      this.validDetail = validDetail;
    }

  }

  _handleGetEmissionByCalculatedMethod = (type,  calculateMethod, unit,  emissionFactor,  scope, catIndex, comIndex, assetIndex, navigate, companyId, aLength, bLength, cLength, category, vehicleType) => {
    getEmissionByCalculatedMethod(
      type,
      calculateMethod,
      unit,
      emissionFactor,
      scope,
      category,
      vehicleType,
      (err, data) => {
        if (data) {
          if (data.error) {
            toast.error(data.error);
          } else {
            runInAction(() => {
              const newArray = [...this.equipmentListDetail];
              const eFactorValue = data.payload === null? 0 : data.payload;
              newArray[catIndex].companyAsset[comIndex].assets[assetIndex].emissionFactorValue = eFactorValue ;  
              if(eFactorValue >= 0 && catIndex === aLength && comIndex === bLength && assetIndex === cLength){
                this.handleSubmit(this.equipmentListDetail, navigate, companyId);
              }
            });
          }
        }
      }
    );
  };

  handleSubmit = (dataBody, navigate, companyId) => {
    addOrganizationSetUp({ moduleId: companyId }, dataBody, (err, data) => {
      if (data && data.success) {
        toast.success("Successfully Save!");
        navigate('/group-view?groupId=-1&view=Emission Activity');
      } else {
        toast.error(err);
      }
    });
  }

  _handleCheckIndustry = (value) => {
    runInAction(() => {
      this.checkedIndustry = value;
      const selectedEq = this.industries.find(v => v.title === value)?.equipments.map((v1) => (
        {
          equipments: [],
          id: v1.id,
          name: v1.name,
          scope: 1
        }
      ));

      if (this.siteModuleData?.companyName === 'ITRI' &&
        selectedEq.length === 1 &&
        selectedEq[0].name === 'Stationary Combustion') {

        this.selectedEquipments = [{
          equipments: [],
          id: "1_Mobile Combustion",
          name: "Mobile Combustion",
          scope: 1
        }]

      } else {
        this.selectedEquipments = selectedEq;
      }

    })
  }

  _handleSetPageIndex = (mode) => {
    if (mode === 'Next') this.selectedPageIndex = this.selectedPageIndex + 1;
    else this.selectedPageIndex = this.selectedPageIndex - 1;

  }

  _handleQtyChange = (e, name) => {
    let newSelectedEqList = [...this.selectedEquipments];
    let eq_index = newSelectedEqList.findIndex(v => v.name === name);
    let type_index = newSelectedEqList[eq_index]?.equipments.findIndex(v => v.subId === e.target.id);
    newSelectedEqList[eq_index].equipments[type_index].qty = e.target.value;
    this.selectedEquipments = newSelectedEqList;
  }

  _handleCheckEquipment = (dataObj, id, name, subName, subId,itSecTitle) => {   

    let equipAry = [...toJS(this.selectedEquipments)];
    const index = equipAry.findIndex((f) => f.name === name); ///////index for parent checkbox//////
    const subIndex = equipAry[index]?.equipments?.findIndex(
      (f) => f.name === subName && f.subId === subId
    ); ///////index for children checkbox group///////////
  
    if (index > -1 && !subName) {
      equipAry.splice(index, 1); ///////remove if uncheck parent//////
    }
    else {
      let duplicateItem;
      if (subIndex > -1 && subName) {
        ///////remove if uncheck child//////

        duplicateItem = [...equipAry[index]?.equipments];
        if (subIndex === 0)
          duplicateItem?.shift();
        else duplicateItem?.splice(subIndex, 1);

      }

      let equipObj;
      if (!subName) {
        ///////for setting object for parent///////
        equipObj = {
          id: id,
          type: dataObj.type,
          nameKey: dataObj.nameKey,
          catKey: dataObj.categoryKey,
          catValue: dataObj.category,
          // sourceType: dataObj.sourceType,
          sourceKey: dataObj.sourceKey, //////type's key
          name: name,
          scope: (common.tableColumns[0]?.dataColumns)?.filter(c => c.cat === name)[0]?.scope,
          equipments: [],
        };
      } else {
        ///////for setting object for child///////
        equipObj = {
          id: id,
          type: dataObj.type,
          name: name,
          nameKey: dataObj.nameKey,
          catKey: dataObj.categoryKey,
          catValue: dataObj.category,
          sourceKey: dataObj.sourceKey, //////type's key
          scope: (common.tableColumns[0]?.dataColumns)?.filter(c => c.cat === name)[0]?.scope,
          equipments:
            equipAry.length > 0
              ? Object.keys(equipAry[index]).length > 0
                ? (subIndex > -1 && subName) ? duplicateItem : [...equipAry[index]?.equipments, { name: subName, qty: 1, subId: subId, sourceType: dataObj.sourceType,itSecTitle }]
                : [{ name: subName, qty: 1, subId: subId, sourceType: dataObj.sourceType,itSecTitle }]
              : [],
        };
      }

      if (index > -1) {
        equipAry.splice(index, 1, equipObj);
      } else {
        equipAry.push(equipObj);
      }
    }
    this.selectedEquipments = equipAry;
    
  };

  _handleSetEquipmentListDetails = () => {
    let testArray = [];
    this.validationErr = [];
    this.selectedEquipments?.map((dataByCategory, a) => {
      testArray[a] = {};
      testArray[a].category = dataByCategory.name;
      testArray[a].companyAsset = [];
      dataByCategory.equipments.map((equipmentData, i) => {   
        (testArray[a].companyAsset)[i] = {};
        (testArray[a].companyAsset)[i].catValue = (equipmentData.itSecTitle ? equipmentData.itSecTitle : dataByCategory.catValue);
        (testArray[a].companyAsset)[i].nameKey = dataByCategory.nameKey;
        (testArray[a].companyAsset)[i].sourceKey = dataByCategory.sourceKey;
        (testArray[a].companyAsset)[i].sourceType = dataByCategory.sourceType;
        (testArray[a].companyAsset)[i].title = (equipmentData.itSecTitle?equipmentData.itSecTitle:dataByCategory.name) + " - " + equipmentData.name;
        (testArray[a].companyAsset)[i].checkedValue = equipmentData.name;
        (testArray[a].companyAsset)[i].scopeName = (equipmentData.itSecTitle ? equipmentData.itSecTitle : dataByCategory.catValue);;
        (testArray[a].companyAsset)[i].scope = dataByCategory.scope;
        (testArray[a].companyAsset)[i].assets = [];
        for (let index = 0; index < equipmentData.qty; index++) {
          if(dataByCategory.scope === "1"){
            ((testArray[a].companyAsset)[i].assets).push({
              commissionedDate: new Date(), userId: common.userId
            })
          }else if(dataByCategory.scope === "2"){
            ((testArray[a].companyAsset)[i].assets).push({
              type: equipmentData.sourceType, calculateType: equipmentData.sourceType, calculationApproach: (equipmentData.subId).split("_")[1], commissionedDate: new Date(), userId: common.userId
            });
          }else if(dataByCategory.scope === "3"){
            ((testArray[a].companyAsset)[i].assets).push({
              transportMode: equipmentData.sourceType, commissionedDate: new Date(), userId: common.userId
            });
          }else{
            ((testArray[a].companyAsset)[i].assets).push({
              transportMode: equipmentData.sourceType, userId: common.userId
            })
          }
        }
      });         
    });
    // if(this.siteModuleData?.companyName === 'ITRI'){
      const sindex = testArray.findIndex(t=> t.category === "Mobile Combustion")
      if(sindex > -1){
        let sList = testArray[sindex].companyAsset.filter(s => s.scopeName === "Stationary Combustion")
        if(sList.length){
          const sobj = {
            category: "Stationary Combustion",
            companyAsset:sList
          }
          const mobj = {
            category: "Mobile Combustion",
            companyAsset:testArray[sindex].companyAsset.filter(s => s.scopeName === "Mobile Combustion")
          }
          testArray.splice(sindex, 1);
          const rindex = testArray.findIndex(t=> t.category === "Refrigerants and Fugitives")
          if(rindex > -1){
            const robj = {
              category: testArray[rindex].category,
              companyAsset:testArray[rindex].companyAsset
            }
            testArray.splice(rindex, 1);
            testArray = [robj,sobj,mobj].concat([...testArray])
          }else{
            testArray = [sobj,mobj].concat([...testArray])
          }
          
        }
      }
    // }
    this.equipmentListDetail = testArray;
  }

  _handleEquipTextChange = (e, catgoryIndex, equipmentIndex, subIndex, keyField, catValue, nameKey, sourceKey, sourceType, name) => {
    
    let tempListDetail = [...this.equipmentListDetail];

    console.log("tempListDetail=>", toJS(tempListDetail[catgoryIndex]))


    if(keyField === 'commissionedDate'){
      ((tempListDetail[catgoryIndex].companyAsset)[equipmentIndex].assets)[subIndex][keyField] = new Date(e);
    }else if(keyField === 'year'|| keyField === 'baselineYear'){
      ((tempListDetail[catgoryIndex].companyAsset)[equipmentIndex].assets)[subIndex][keyField] = Number(e);
    }else{
      ((tempListDetail[catgoryIndex].companyAsset)[equipmentIndex].assets)[subIndex][keyField] = e;
    }
    if(nameKey !== keyField && catValue !== "Emission Removal & Mitigation"){
      ((tempListDetail[catgoryIndex].companyAsset)[equipmentIndex].assets)[subIndex][nameKey] = name;
    }
    ((tempListDetail[catgoryIndex].companyAsset)[equipmentIndex].assets)[subIndex].category = catValue;

    //For temp
    if(!((tempListDetail[catgoryIndex].companyAsset)[equipmentIndex].assets)[subIndex]["baselineYear"]){
      ((tempListDetail[catgoryIndex].companyAsset)[equipmentIndex].assets)[subIndex]["baselineYear"] = 2018;
    }

    if(tempListDetail[catgoryIndex].category === "Stationary Combustion"){
      if(!((tempListDetail[catgoryIndex].companyAsset)[equipmentIndex].assets)[subIndex]["equipmentType"]){
        ((tempListDetail[catgoryIndex].companyAsset)[equipmentIndex].assets)[subIndex]["equipmentType"] = "Backup Generator";
      }

      if(!((tempListDetail[catgoryIndex].companyAsset)[equipmentIndex].assets)[subIndex]["unit"]){
        ((tempListDetail[catgoryIndex].companyAsset)[equipmentIndex].assets)[subIndex]["unit"] = "Btu";
      }
    }    

    if(tempListDetail[catgoryIndex].category === "Refrigerants and Fugitives"){
      if(!((tempListDetail[catgoryIndex].companyAsset)[equipmentIndex].assets)[subIndex]["equipmentType"]){
        ((tempListDetail[catgoryIndex].companyAsset)[equipmentIndex].assets)[subIndex]["equipmentType"] = "Chillers";
      }

      if(!((tempListDetail[catgoryIndex].companyAsset)[equipmentIndex].assets)[subIndex]["unit"]){
        ((tempListDetail[catgoryIndex].companyAsset)[equipmentIndex].assets)[subIndex]["unit"] = "Kg";
      }
    }

    if(tempListDetail[catgoryIndex].category === "Imported energy (steam, heating, cooling and compressed air)"){
      if(!((tempListDetail[catgoryIndex].companyAsset)[equipmentIndex].assets)[subIndex]["emissionFactor"]){
        ((tempListDetail[catgoryIndex].companyAsset)[equipmentIndex].assets)[subIndex]["emissionFactor"] = "Grid Average / Location Based";
      }

      if(!((tempListDetail[catgoryIndex].companyAsset)[equipmentIndex].assets)[subIndex]["unit"]){
        ((tempListDetail[catgoryIndex].companyAsset)[equipmentIndex].assets)[subIndex]["unit"] = "kWh";
      }

      if(!((tempListDetail[catgoryIndex].companyAsset)[equipmentIndex].assets)[subIndex]["year"]){
        ((tempListDetail[catgoryIndex].companyAsset)[equipmentIndex].assets)[subIndex]["year"] = 2018;
      }
    }

    if(tempListDetail[catgoryIndex].category === "Upstream T&D"){
      if(!((tempListDetail[catgoryIndex].companyAsset)[equipmentIndex].assets)[subIndex]["activityType"]){
        ((tempListDetail[catgoryIndex].companyAsset)[equipmentIndex].assets)[subIndex]["activityType"] = "Distance";
      }

      if(!((tempListDetail[catgoryIndex].companyAsset)[equipmentIndex].assets)[subIndex]["unit"]){
        ((tempListDetail[catgoryIndex].companyAsset)[equipmentIndex].assets)[subIndex]["unit"] = "km";
      }

      if(!((tempListDetail[catgoryIndex].companyAsset)[equipmentIndex].assets)[subIndex]["year"]){
        ((tempListDetail[catgoryIndex].companyAsset)[equipmentIndex].assets)[subIndex]["year"] = 2018;
      }
    }

    if(tempListDetail[catgoryIndex].category === "Waste"){
      if(!((tempListDetail[catgoryIndex].companyAsset)[equipmentIndex].assets)[subIndex]["unit"]){
        ((tempListDetail[catgoryIndex].companyAsset)[equipmentIndex].assets)[subIndex]["unit"] = "km";
      }

      if(!((tempListDetail[catgoryIndex].companyAsset)[equipmentIndex].assets)[subIndex]["year"]){
        ((tempListDetail[catgoryIndex].companyAsset)[equipmentIndex].assets)[subIndex]["year"] = 2018;
      }
    }

    if(tempListDetail[catgoryIndex].category === "Emission Removal & Mitigation"){
      if(!((tempListDetail[catgoryIndex].companyAsset)[equipmentIndex].assets)[subIndex]["emissionFactor"]){
        ((tempListDetail[catgoryIndex].companyAsset)[equipmentIndex].assets)[subIndex]["emissionFactor"] = "Singapore";
      }

      if(!((tempListDetail[catgoryIndex].companyAsset)[equipmentIndex].assets)[subIndex]["unit"]){
        ((tempListDetail[catgoryIndex].companyAsset)[equipmentIndex].assets)[subIndex]["unit"] = "km";
      }

      if(!((tempListDetail[catgoryIndex].companyAsset)[equipmentIndex].assets)[subIndex]["year"]){
        ((tempListDetail[catgoryIndex].companyAsset)[equipmentIndex].assets)[subIndex]["year"] = 2018;
      }
    }

    if(tempListDetail[catgoryIndex].category === "Purchased Goods"){
      if(!((tempListDetail[catgoryIndex].companyAsset)[equipmentIndex].assets)[subIndex]["unit"]){
        ((tempListDetail[catgoryIndex].companyAsset)[equipmentIndex].assets)[subIndex]["unit"] = "kg";
      }

      if(!((tempListDetail[catgoryIndex].companyAsset)[equipmentIndex].assets)[subIndex]["year"]){
        ((tempListDetail[catgoryIndex].companyAsset)[equipmentIndex].assets)[subIndex]["year"] = 2018;
      }
    }
    
    
  }

  getIndustriesData = () => {
    this.industries = dummyIndustries;
  }

  _getEnergySourceList = () => {
    this.loading = true;
    fetchAllEnergySource((err, data) => {
      if (data && data.success) {
        runInAction(() => {
          this.energySourceList = data.payload;

          data.payload.map(source => {
            if(source.title === "Stationary Combustion"){
              const list = source.equipDetails[0].typeList.map(v=>{
                return {
                  value: v,
                  label: v,
                  sourceKey: "fuelType",
                  sourceType: source.equipDetails[0].type
                };
              });
              this.sourceDataList.push({title: source.title, list: list})
            }else if(source.title === "Mobile Combustion"){
              const list = source.equipDetails[0].typeList.map(v=>{
                return {
                  value: v,
                  label: v,
                  sourceKey: "fuelType",
                  sourceType: source.equipDetails[0].type
                };
              });
              this.sourceDataList.push({title: source.title, list: list})
            }else if(source.title === "Refrigerants and Fugitives"){
              const list = source.equipDetails[0].typeList.map(v=>{
                return {
                  value: v,
                  label: v,
                  sourceKey: "fuelType",
                  sourceType: source.equipDetails[0].type
                };
              });
              this.sourceDataList.push({title: source.title, list: list})
            }else if(source.title === "Imported energy (steam, heating, cooling and compressed air)"){
              const list = [];              
              source.equipDetails.map(e => {
                e.typeList.map(v=>{
                  return list.push({
                    value: v,
                    label: v,
                    sourceKey: "calculateMethod",
                    sourceType: e.type
                  });
                })
              })              
              this.sourceDataList.push({title: "Purchased Electricity", list: list})                        
            }else{
              const list = [];              
              source.equipDetails.map(e => {
                e.typeList.map(v=>{
                  return list.push({
                    value: v,
                    label: v,
                    sourceKey: "vehicleType",
                    sourceType: e.type
                  });
                })
              })              
              this.sourceDataList.push({title: "Upstream T&D", list: list})
            }
          })
          this.loading = false;
        })
      } else {
        this.loading = false;
        toast.error(err);
      }
    });
  }
}

const dummyIndustries = [
  {
    title: "Transportation",
    description: "Transportation industry include logistics and air freight or airlines, marine, road and rail, and their respective infrastructures, etc.",
    equipments: [{ name: 'Mobile Combustion', id: '1_Mobile Combustion' }]
  },
  {
    title: "Manufacturing",
    description: "Manufacturing industry include chemicals, clothing, computers, consumer electronics, electrical equipment, furniture, heavy machinery, refined petroleum products, ships, steel, aircraft,automobiles and tools, etc.",
    equipments: [{ name: 'Stationary Combustion', id: '0_Stationary Combustion' }]
  },
  {
    title: "Commercial (Hotel,Mall,etc)",
    description: "Commercial industry includes office buildings, shophouses, retail malls, and hotels.",
    equipments: [{ name: 'Refrigerants and Fugitives', id: '2_Refrigerants and Fugitives' }]
  },
  {
    title: "Data Center",
    description: "Data Center include Cooling System, Electricity Usage.",
    equipments: [{ name: 'Refrigerants and Fugitives', id: '2_Refrigerants and Fugitives' }]
  },
  {
    title: "Construction",
    description: "Construction industry include carpentry, road construction, bridge development, and home design.",
    equipments: [{ name: 'Refrigerants and Fugitives', id: '2_Refrigerants and Fugitives' }]
  }
]

export default new QuickOrganizationSetUp();
