import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import { useEffect } from "react";
import { useState } from "react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { MyButton, MyInput, Selector } from "../../Tools";
import { UploadFileStepPagination } from "../SpecialOneTime/StepPagination";

export const UploadFileModal = inject("store")(
  observer((props) => {
    const { readUploadFile } = props;
    const { theme } = props.store.common;
    const {
      setFileUploadData,
      excelRawData,
      setPageIndex,
      pageIndex,
      _handleExcelUploadNext,
      _handleResetExcel,
      _handleClose
    } = props.store.survey;
    const headerFromExcel = Object.keys(excelRawData[0]);

    return (
      <div
        className={`modal fade show`}
        id="uploadFile"
        tabIndex="-1"
        aria-labelledby="uploadFile"
        aria-hidden="true"
        role="dialog"
        style={{ display: "block" }}
      >
        <div className="modal-dialog  modal-xl modal-dialog-centered">
          <div
            className="modal-content"
            style={{
              background: Colors[theme].background,
              borderRadius: 10,
              color: invertDark(theme),
            }}
          >
            <div className="modal-header text-white">
              <h4>{pageIndex === 0 ? 'Upload File' : pageIndex === 1 ? 'Please Select Headers to Import Data' : 'Preview Data'}</h4>
              <i
                style={{ cursor: "pointer" }}
                className="bi bi-x-lg "
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => _handleClose()}
              ></i>
            </div>
            <div className="modal-body">
              <div className="col-12 d-flex justify-content-center pb-3">
                <UploadFileStepPagination
                  steps={[
                    "Create New Site",
                    "Account Management and Creation",
                    "Completed Setup",
                  ]}
                />
              </div>
              {pageIndex === 0 ? (
                <FirstStep headerFromExcel={headerFromExcel} theme={theme} />
              ) : pageIndex === 1 ? (
                <SecondStep theme={theme} />
              ) : (
                <ThirdStep theme={theme} />
              )}
            </div>
            <div className="mx-3 d-flex justify-content-end">
              {/* <span>
                <MyButton
                  // onClick={() => _handleCancelCustomEmission()}
                  style={{ width: 200 }}
                  text={"Previous"}
                  onClick={()=>_handleExcelUploadNext('back')}
                  customColor={Colors.primaryBule}
                  customHoverColor={Colors.lightBlue}
                />
              </span> */}
              <span>
                <MyButton
                  // onClick={() => _handleAddCustomEmission()}
                  style={{ width: 200 }}
                  onClick={() => _handleExcelUploadNext()}
                  text={"Next"}
                  customColor="#066CFF"
                  customHoverColor={Colors.water}
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  })
);

const FirstStep = inject("store")(
  observer((props) => {
    const { theme, headerFromExcel } = props;
    const [isCheck, setIsCheck] = useState(false);
    const { checkedArray } = props.store.survey;

    const selectAll = (v) => {
      checkedArray.splice(0, checkedArray.length);
      var selec = document.getElementsByName("header");
      for (var i = 0; i < selec.length; i++) {
        if (selec[i].type == "checkbox") selec[i].checked = v;
        if (v === true) checkedArray.push(selec[i].value);
        if (v === false) checkedArray.splice(0, checkedArray.length);
      }
      setIsCheck(v);
    };
    var headers = document.getElementsByName("header");

    const select = (v) => {
      const headerArray = [];
      const selectAll = document.getElementById("selectAll");
      const index = checkedArray.findIndex((d) => d === v.value);

      if (v.checked === false) {
        selectAll.checked = false;
        setIsCheck(false);
        checkedArray.splice(index, 1);
      } else checkedArray.push(v.value);
    };

    return (
      <div
        className="row mx-2  py-3"
        style={{ background: Colors.primaryBule, borderRadius: 10 }}
      >
        <div
          className="col-4"
          style={{
            borderRight: "1px solid gray",
            maxHeight: 500,
            overflowY: "scroll",
          }}
        >
          <div
            className="position-sticky pb-2"
            style={{
              top: 0,
              color: Colors.Dark[5],
              fontSize: 18,
              background: Colors.primaryBule,
            }}
          >
            Please select headers to import data
          </div>
          <input
            className="ms-3 "
            style={{ cursor: "pointer", margin: 5 }}
            onClick={(e) => selectAll(!isCheck)}
            type="checkbox"
            id={"selectAll"}
            name={"selectAll"}
            value={"selectAll"}
          />
          Select All
          {headerFromExcel.map((v, k) => (
            <div className="ms-5" id={k}>
              {
                <input
                  style={{ cursor: "pointer", margin: 5 }}
                  onClick={() => select(headers[k])}
                  type="checkbox"
                  id={k}
                  name={"header"}
                  value={v}
                />
              }
              {v}
            </div>
          ))}
        </div>
        <div
          className="col-8"
          style={{
            maxHeight: 500,
            overflowY: "scroll",
          }}
        >
          <div
            style={{
              color: Colors.Dark[5],
              fontSize: 18,
            }}
          >
            Selected Columns
          </div>
          <div className="row ps-5">
            {checkedArray.map((v) => (
              <div
                className="col-3 px-2 py-1 m-2 d-flex justify-content-center align-items-center"
                style={{
                  background: "rgb(255,255,255,0.1)",
                  boxShadow: "0 2px 4px rgb(0,0,0,0.25)",
                  borderRadius: 15,
                  fontSize: 14,
                  color: "#fff",
                  // marginBottom:100
                }}
              >
                {v}

              </div>
            ))}
          </div>
        </div>
      </div>
    );
  })
);

const SecondStep = inject("store")(
  observer((props) => {
    const {
      checkedArray,
      requiredField,
      _handleSelectExlHeader,
      excelDefaultValue,
      _handleDefaultExcelInputChange,
      createTransportationList,
    } = props.store.survey;
    const { theme } = props;
    const options = checkedArray.map((v) => ({ label: v, value: v }));

    return (
      <div
        className="mx-2  py-3"
        style={{
          background: Colors[theme].glass,
          minHeight: 500,
          borderRadius: 10,
        }}
      >
        <div className="row text-white">
          <span className="col-5 text-center"><i style={{ fontSize: 20 }} className="bi bi-filetype-xlsx pe-2"></i>Columns from excel file</span>
          <span className="col-2"> </span>
          <span className="col-5 row">
            <span className="col-6"><i style={{ fontSize: 20 }} className="bi bi-layout-text-window pe-2"></i>Require Fields</span>
            <span className="col-6"><i style={{ fontSize: 20 }} className="bi bi-keyboard pe-2"></i>Default Value</span>
          </span>
        </div>
        {requiredField.map((v, k) => (
          <div className="row pt-2">
            <div className="col-5 d-flex justify-content-center">
              <div style={{ width: 350 }}>
                <Selector
                  // value={options?.filter(
                  //   (option) => option.value === country
                  // )}
                  menuListColor={Colors.Dark[0]}
                  options={options}
                  _handleSelect={(e) => _handleSelectExlHeader(e, v)}
                />
              </div>
            </div>
            <div className="col-2 text-center">
              <i className="bi bi-arrow-right"></i>
            </div>
            <div className="col-5 row">
              <span className="col-6">{v}</span>
              <span className="col-6">
                {/* <MyInput type="text" value={excelDefaultValue[v]} onChange={(e)=>_handleDefaultExcelInputChange(e,v)}/> */}

                {v === "category" ? (
                  <Selector
                    // label={"Category"}
                    menuListColor={Colors.Dark[0]}
                    options={categoryOption}
                    value={
                      categoryOption.filter(
                        (v) => v.label === excelDefaultValue.category
                      ) || ""
                    }
                    placeholder={"Select category"}
                    _handleSelect={(e) =>
                      _handleDefaultExcelInputChange(
                        e,
                        v,
                        "transportation"
                      )
                    }
                  />
                ) : v === "emissionFactor" ? (
                  <Selector
                    // label={"Emission Factor"}
                    menuListColor={Colors.Dark[0]}
                    options={emissionFactorOption}
                    value={
                      emissionFactorOption.filter(
                        (v) => v.label === excelDefaultValue.emissionFactor
                      ) || ""
                    }
                    placeholder={"Select Emission Factor"}
                    _handleSelect={(e) =>
                      _handleDefaultExcelInputChange(
                        e,
                        v,
                        "transportation"
                      )
                    }
                  />
                ) : v === "transportMode" ? (
                  <Selector
                    // label={"Mode of transport"}
                    menuListColor={Colors.Dark[0]}
                    options={transportOption}
                    value={
                      transportOption.filter(
                        (v) => v.label === excelDefaultValue.transportMode
                      ) || ""
                    }
                    placeholder={"Select Mode of transport"}
                    _handleSelect={(e) =>
                      _handleDefaultExcelInputChange(
                        e,
                        v,
                        "transportation"
                      )
                    }
                  />
                ) : v === "vehicleType" ? (
                  <Selector
                    // label={"Vehicle Type"}
                    menuListColor={Colors.Dark[0]}
                    options={vehicleOption.filter(
                      (dt) =>
                        dt.transportMode === excelDefaultValue.transportMode
                    )}
                    value={
                      vehicleOption.filter(
                        (v) => v.label === excelDefaultValue.vehicleType
                      ) || ""
                    }
                    placeholder={"Select Vehicle Type"}
                    _handleSelect={(e) =>
                      _handleDefaultExcelInputChange(
                        e,
                        v,
                        "transportation"
                      )
                    }
                  />
                ) : v === "unit" ? (
                  <Selector
                    // label={"Unit"}
                    menuListColor={Colors.Dark[0]}
                    options={unitOption}
                    value={
                      unitOption.filter(
                        (v) => v.label === excelDefaultValue.unit
                      ) || ""
                    }
                    placeholder={"Unit"}
                    _handleSelect={(e) =>
                      _handleDefaultExcelInputChange(
                        e,
                        v,
                        "transportation"
                      )
                    }
                  />
                ) : v === "activityType" ? (
                  <Selector
                    // label={"Activity Type"}
                    menuListColor={Colors.Dark[0]}
                    options={activityOption}
                    value={
                      activityOption.filter(
                        (v) => v.label === excelDefaultValue.activityType
                      ) || ""
                    }
                    placeholder={"Activity Type"}
                    _handleSelect={(e) =>
                      _handleDefaultExcelInputChange(
                        e,
                        "activityType",
                        "transportation"
                      )
                    }
                  />
                ) : (
                  <MyInput
                    id={v}
                    type="text"
                    value={excelDefaultValue[v]}
                    onChange={(e) => _handleDefaultExcelInputChange(e, v)}
                  />
                )}
              </span>
            </div>
          </div>
        ))}
      </div>
    );
  })
);

const ThirdStep = inject("store")(
  observer((props) => {
    const { theme } = props;
    const { requiredField, lastExcelData, excelDefaultValue } = props.store.survey;
    return (
      <div
        className=" mx-2"
        style={{
          background: Colors[theme].glass,
          minHeight: 500,
          maxHeight: 600,
          borderRadius: 10,
          overflow: 'scroll'
        }}
      >
        <span style={{ fontSize: 18 }}>Preview</span>
        <table style={{ color: invertDark(theme) }} className="table px-3 mb-0">
          <thead className='position-sticky' style={{ top: 0, background: 'rgb(32, 43, 96)', borderTopRightRadius: 5 }}>
            <tr style={{ borderTopRightRadius: 10, borderTopLeftRadius: 10 }}>

              <th>Name</th>
              <th>Date</th>
              <th>Category</th>
              <th>Emission Factor</th>
              <th>Transport Mode</th>
              <th>Activity Type</th>
              <th>Vehicle Type</th>
              <th>Unit</th>
              <th>Distance</th>
              <th>Carbon Emission</th>
            </tr>
          </thead>
          <tbody >
            {lastExcelData?.map((v, k) => (
              <tr style={{ borderBottom: theme === 'dark' ? '1px solid rgb(255,255,255,10%)' : '1px solid rgb(0,0,0,10%)' }} key={k}>
                <td>{v.name}</td>
                <td>{v.date}</td>
                <td>{v.category}</td>
                <td>{v.emissionFactor}</td>
                <td>{v.transportMode}</td>
                <td>{v.activityType}</td>
                <td>{v.vehicleType}</td>
                <td>{v.unit}</td>
                <td>{v.distance}</td>
                <td>{v.carbonEmission}</td>

              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }));

const categoryOption = [
  { value: 1, label: "Upstream T&D" },
  { value: 2, label: "Business Travel" },
  { value: 3, label: "Employee Commute" },
];

const unitOption = [
  { value: 1, label: "mile" },
  { value: 2, label: "km" },
  { value: 3, label: "passenger-mile" },
  { value: 4, label: "passenger-km" },
];

const yearOption = [
  { value: 1, label: "2018" },
  { value: 2, label: "2019" },
  { value: 3, label: "2020" },
  { value: 4, label: "2021" },
  { value: 5, label: "2022" },
  { value: 6, label: "2023" },
];

const activityOption = [
  { value: 1, label: "Distance" },
  { value: 2, label: "Passenger Distance" },
  { value: 3, label: "Vehicle Distance" },
  { value: 4, label: "Weight Distance" },
];

const transportOption = [
  { value: 1, label: "Car" },
  { value: 2, label: "Air" },
  { value: 3, label: "Bus" },
  { value: 4, label: "Rail" },
  { value: 5, label: "Ferry" },
];

const vehicleOption = [
  {
    value: 1,
    transportMode: "Air",
    label: "Air Travel - Short Haul (< 300 miles) ",
  },
  {
    value: 2,
    transportMode: "Air",
    label: "Air Travel - Medium Haul (>= 300 miles, < 2300 miles) ",
  },
  {
    value: 3,
    transportMode: "Air",
    label: "Air Travel - Long Haul (>= 2300 miles) ",
  },
  { value: 4, transportMode: "Car", label: "Passenger Car A" },
  { value: 5, transportMode: "Car", label: "Light-Duty Truck B" },
  { value: 6, transportMode: "Car", label: "Motorcycle" },
  { value: 7, transportMode: "Bus", label: "Bus" },
  { value: 8, transportMode: "Rail", label: "Intercity Rail (i.e. Amtrak) C" },
  { value: 9, transportMode: "Rail", label: "Commuter Rail D" },
  {
    value: 10,
    transportMode: "Rail",
    label: "Transit Rail (i.e. Subway, Tram) E",
  },
];

const emissionFactorOption = [
  { value: 1, label: "US EPA" },
  { value: 2, label: "UK DEFRA" },
  { value: 3, label: "Custom emission factor" },
];
