import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { MyButton, MyInput, Selector } from "../../Tools";

export const CustomEmissionFactorModal = inject("store")(
    observer((props) => {
        const { theme } = props.store.common;
        const { 
            customEmissionFactor, 
            _handleSelectCustomEmission, 
            _handleChangeInput,
            _handleAddCustomEmission,
            _handleCancelCustomEmission
         } = props.store.survey;
        return (
            <div
                className={`modal fade ${theme}`}
                id="addCustomEmission"
                tabIndex="-1"
                aria-labelledby="addCustomEmissionLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-lg">
                    <div
                        className="modal-content"
                        style={{
                            background: Colors[theme].background,
                            borderRadius: 10,
                            color: invertDark(theme)
                        }}
                    >
                        <div className="modal-header">
                            <h4>Add Emission Factor</h4>
                            <i
                                style={{ cursor: "pointer" }}
                                className="bi bi-x-lg "
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></i>
                        </div>
                        <div className="modal-body">
                            <div className="row py-1">
                                <div className="col-lg-6 col-6 col-sm-12 px-2 py-sm-1">
                                    <div className="row d-flex">
                                        <div className="col">
                                            <Selector
                                                label={"Calculation Approach"}
                                                menuListColor={Colors.Dark[0]}
                                                options={approachOption}
                                                value={
                                                    approachOption.filter(
                                                        (v) => v.value === customEmissionFactor.type
                                                    ) || ""
                                                }
                                                placeholder={"Select Calculation Approach"}
                                                _handleSelect={(e) => _handleSelectCustomEmission("type", e)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-6 col-sm-12 px-2 py-sm-1">
                                    <div className="row d-flex">
                                    <div className="col">
                                            <MyInput
                                                label={"Emission Factor Name"}
                                                required={true}
                                                id={"customEFName"}
                                                type={"text"}
                                                value={customEmissionFactor.customEFName}
                                                onChange={(e) => _handleChangeInput(e, "customEmissionFactor")}
                                                placeHolder={"Emission Factor Name"}
                                            />
                                        </div>
                                    </div>
                                </div>
                                
                            </div>

                            <div className="row py-1">
                                <div className="col-lg-6 col-6 col-sm-12 px-2 py-sm-1">
                                    <div className="row d-flex">
                                        <div className="col">
                                            <MyInput
                                                label={"Source of Emission"}
                                                required={true}
                                                id={"sourceOfEmission"}
                                                type={"text"}
                                                value={customEmissionFactor.sourceOfEmission}
                                                onChange={(e) => _handleChangeInput(e, "customEmissionFactor")}
                                                placeHolder={"Source of Emission"}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-6 col-sm-12 px-2 py-sm-1">
                                    <div className="row d-flex">
                                    <div className="col">
                                            <MyInput
                                                label={"Emission Value"}
                                                required={true}
                                                id={"co2Factor"}
                                                type={"number"}
                                                value={customEmissionFactor.co2Factor}
                                                onChange={(e) => _handleChangeInput(e, "customEmissionFactor")}
                                                placeHolder={"Emission value"}
                                            />
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        <div className="modal-footer mx-3 d-flex justify-content-end">
                            <span data-bs-dismiss="modal" aria-label="Close">
                                <MyButton
                                    onClick={() => _handleCancelCustomEmission()}
                                    style={{ width: 200 }}
                                    text={"Cancel"}
                                    customColor={theme === 'dark' ? Colors.primaryBule : '#888'}
                                    customHoverColor={theme === 'dark' ? Colors.lightBlue : '#aaa'}
                                />
                            </span>
                            <span>
                                <MyButton
                                    onClick={() => _handleAddCustomEmission()}
                                    style={{ width: 200 }}
                                    text={"Add"}
                                    customColor="#066CFF"
                                    customHoverColor={Colors.water}
                                />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        )
    })
)

const approachOption = [
    { value: "Location Based", label: "Purchased Electricity - Location Based" },
    { value: "Market Based", label: "Purchased Electricity - Market Based" },
    { value: "Steam and Heat", label: "Heat/Steam" }
];