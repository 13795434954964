import { toJS } from "mobx";
import * as API from "./URL";
import queryString from "querystring";

export const uploadSiteRelevantFile = async (params, data, callback) => {
  return fetch(API.uploadSiteRelevantFile(params), {
    method: "POST",
    headers: {
      //"Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
    },
    body: data,
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err.toString(), null));
};

export const fetchGetSiteReleventData = async (param, callback) => {
  const queryParam = queryString.stringify(param);
  return fetch(API.siteRelevantUrl + "?" + queryParam, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
      // "token": `${token}`
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err.toString(), null));
};

export const fetchBaselineEmission = async (callback) => {
  return fetch(API.getBaselineEmission , {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
      // "token": `${token}`
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err.toString(), null));
};

export const fetchScopeAverage = async (callback) => {
  return fetch(API.getScopeAverage , {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
      // "token": `${token}`
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err.toString(), null));
}

export const fetchDownloadFile = async (path, callback) => {
  return fetch(API.baseURLImage + path, {
    method: "GET",
    headers: {
      "Content-Type": "blob",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((resp) => resp.blob())
    .then((blob) => callback(null, blob))
    .catch((err) => callback(err.toString(), null));
};

export const fetchDeleteSite = async (params, callback) => {
  return fetch(API.removeSiteRelevantFile(params), {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
      // "token": `${token}`
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err.toString(), null));
};

export const addManualentryData = async (
  siteRelevantId,
  year,
  buildingId,
  frameworkId,
  data,
  callback
) => {
  return fetch(API.addManualEntryDataAPI(siteRelevantId, year, buildingId, frameworkId), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err.toString(), null));
};
