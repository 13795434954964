import { Colors } from "../../Assets/Config/color.config"
import { inject, observer } from "mobx-react";
import { toJS } from "mobx";
import ActivityLogTable from "./ActivityLogTable";
import { useEffect } from "react";
import { useTranslation } from 'react-i18next';

const ActivityLogModal = inject("store")(
    observer((props) => {
        const { activityLog, theme } = props.store.common;
        const { t } = useTranslation("dashboard");
        return (
            <div
                className={`modal fade ${theme}`}
                id="activityLogModal"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-xl">
                    <div
                        className="modal-content"
                        style={{
                            background: Colors[theme].background,
                            borderRadius: 10,
                        }}
                    >
                        <div className="modal-header mx-3 px-0">
                            <h4>{ t("Activity Log") }</h4>
                            <i
                                style={{ cursor: "pointer" }}
                                className="bi bi-x-lg "
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            // onClick={() => _handleClose()}
                            ></i>
                        </div>
                        <div className={`modal-body ${!activityLog && 'd-flex justify-content-center align-items-center'}`} style={{ minHeight: 500 }}>
                            {activityLog ? <ActivityLogTable /> : <i className="spinner-border" />}

                        </div>

                    </div>
                </div>
            </div>
        )
    }))
export default ActivityLogModal