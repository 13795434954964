import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { MyButton, MyInput, Selector } from "../../Tools";
import { index } from "d3";
import { logDOM } from "@testing-library/react";

export const ESGKPISetupModal = inject("store")(
    observer((props) => {
        const { theme } = props.store.common;
        const { _handleCloseKPIModal } = props.store.kpiManagement;
        const { isEditEsgKpi, _fetchAllPillars, _fetchAllSubPillars } = props.store.esg;
        useEffect(() => {
            _fetchAllPillars();
            _fetchAllSubPillars();
        },[])


        return (
            <div
                className={`modal fade large ${theme}`}
                id="esgKpiModal"
                tabIndex="-1"
                aria-labelledby="kpiEditModal"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-lg">
                    <div
                        className="modal-content"
                        style={{
                            background: Colors[theme].background,
                            borderRadius: 10,
                            color: theme === 'dark' ? Colors.Dark[5] : Colors.Dark[0]
                        }}
                    >
                        <div className="modal-header mx-3 px-0">
                            <h5>{isEditEsgKpi ? "Editing" : "Add"} ESG KPIs - Targets & Results</h5>
                            <i
                                onClick={() => _handleCloseKPIModal()}
                                style={{ cursor: "pointer" }}
                                className="bi bi-x-lg"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></i>
                        </div>
                        <div className="modal-body px-3" style={{ fontSize: 14 }}>
                            <ESGKPISetup />
                        </div>
                    </div>
                </div>
            </div>
        );
    })
);

export const ESGKPISetup = inject("store")(
    observer((props) => {
        const { theme, _handleGetYearOptions, yearOptions } = props.store.common;
        const { 
            baseYearOption,
        } = props.store.kpiManagement;

        const {
            esgKpiListEdit,
            isEditEsgKpi,
            expandESGKpiList,
            pillarOption, 
            subPillarOption,
            _handleSelectChange,
            _handleInputChange,
            createEsgKpiList,
            _handleCreateESGKpi,
            _updateEsgKPI,
            _deleteEsgKpi
        } = props.store.esg;

        useEffect(()=> {
            _handleGetYearOptions(2021)
        }, [])

        const _addNewInput = () => {
            expandESGKpiList.yearlyResult.push({year:'',result:''})
        }
        const _removeInput = (k) => {
            expandESGKpiList.yearlyResult.splice(k, 1)
        }


        return (
            <div style={{ fontSize: 14, color: invertDark(theme) }}>
                <form className="px-2">
                    <div className="d-flex align-items-center justify-content-between row my-2 ps-1 pb-3" style={{ borderBottom: isEditEsgKpi ? `1px solid ${theme === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(83, 83, 83, 0.1)'}` : "" }}>
                        {
                            isEditEsgKpi ?
                                <>
                                    <div className="py-1 d-flex align-items-center">
                                        <span className="" style={{ fontWeight: 'bold', color: theme === 'dark' ? Colors.Dark[5] : Colors.Dark[0] }}>
                                            ESG Pillar :
                                        </span>
                                        <span className="px-2 col" style={{ color: theme === 'dark' ? Colors.Dark[5] : Colors.Dark[0], fontWeight: 'normal' }}>
                                            {esgKpiListEdit?.pillar}
                                        </span>
                                    </div>
                                    <div className="py-1 d-flex align-items-center">
                                        <span className="" style={{ fontWeight: 'bold' }}>
                                            Materiality Topic :
                                        </span>
                                        <span className="px-2 col" style={{ color: theme === 'dark' ? Colors.Dark[5] : Colors.Dark[0], fontWeight: 'normal' }}>
                                            {esgKpiListEdit?.subPillar}
                                        </span>
                                    </div>
                                </>
                                :
                                <div className="d-flex py-1" style={{color: invertDark(theme)}}>
                                    <div className="col-4 p-1">
                                    <Selector
                                        id={"pillarSelector"}
                                        _handleSelect={(e) => _handleSelectChange(e, "pillar")}
                                        options={pillarOption}
                                        menuListColor={Colors.Dark[0]}
                                        value={pillarOption.filter((option) => option.value === createEsgKpiList.pillar)}
                                        placeholder={"Select ESG Pillar"}
                                        label={<span style={{ fontWeight: 'bold', color: theme === 'dark' ? Colors.Dark[5] : Colors.Dark[0] }}>ESG Pillar</span>}
                                    />
                                    </div>
                                    <div className="col-8 p-1">
                                    <Selector
                                        id={"subPillarSelector"}
                                        placeholder={"Select Materiality Topic"}
                                        _handleSelect={(e) => _handleSelectChange(e, "subPillar")}
                                        options={subPillarOption}
                                        menuListColor={Colors.Dark[0]}
                                        value={subPillarOption.filter((option) => option.value === createEsgKpiList.subPillar)}
                                        label={<span style={{ fontWeight: 'bold', color: theme === 'dark' ? Colors.Dark[5] : Colors.Dark[0] }}>Materiality Topic</span>}
                                    />
                                    </div>
                                </div>
                        }


                    </div>

                    <div className="" style={{ color: theme === 'dark' ? Colors.Dark[5] : Colors.Dark[0] }}>
                        <div className="d-flex py-1">
                            <div className="col-4 p-1">
                                <Selector
                                    id={"editBaseYearSelector"}
                                    _handleSelect={(e) => _handleSelectChange(e, "baseYear")}
                                    options={baseYearOption}
                                    menuListColor={Colors.Dark[0]}
                                    value={baseYearOption.filter((option) => (option.value) === (expandESGKpiList.baseYear))[0]}
                                    placeholder={"Select base year"}
                                    label={<span style={{ fontWeight: 'bold', color: theme === 'dark' ? Colors.Dark[5] : Colors.Dark[0] }}>Base Year</span>}
                                />
                            </div>
                            <div className="col-8 p-1">
                                <MyInput
                                    id="establishedTarget"
                                    placeHolder={"Please insert target"}
                                    isTextArea={true}
                                    value={expandESGKpiList.establishedTarget}
                                    onChange={(e) => _handleInputChange(e, "expandForm", "establishedTarget")}
                                    label={<span style={{ fontWeight: 'bold', color: theme === 'dark' ? Colors.Dark[5] : Colors.Dark[0] }}>Target</span>}
                                />
                            </div>
                        </div>

                       {
                       expandESGKpiList?.yearlyResult?.map( (v, index) =>   
                        <div className="d-flex py-1" key={index} style={{position:'relative'}}>
                            <div className="col-4 p-1">
                                <Selector
                                    id={"editYearSelector"}
                                    _handleSelect={(e) => _handleSelectChange(e, "year", index)}
                                    options={yearOptions}
                                    menuListColor={Colors.Dark[0]}
                                    value={yearOptions.filter((option) => (option.value) == (v.year))[0]}
                                    placeholder={"Select year"}
                                    label={<span style={{ fontWeight: 'bold', color: theme === 'dark' ? Colors.Dark[5] : Colors.Dark[0] }}>Year</span>}
                                />
                            </div>
                            <div className="col-8 p-1">
                                <MyInput
                                    id="result"
                                    isTextArea={true}
                                    placeHolder={"Please enter result"}
                                    value={v.result}
                                    onChange={(e) => _handleInputChange(e, "expandForm", "result", index)}
                                    label={<span style={{ fontWeight: 'bold', color: theme === 'dark' ? Colors.Dark[5] : Colors.Dark[0] }}>Result</span>}
                                />
                            </div>
                            <div className="d-flex gap-1" style={{position:"absolute",bottom:72, right:10 }}>
                                { index + 1  == expandESGKpiList.yearlyResult.length ?  
                                    <span onClick={() => _addNewInput()} style={{cursor: "pointer"}}><i className="bi bi-plus-square"></i></span> : ''
                                    }
                                <span onClick={() => _removeInput(index)} style={{cursor: "pointer"}}><i className="bi bi-trash3-fill"></i></span></div>

                        </div>
                       ) 
                       }
                        <div className="d-flex justify-content-end">
                            <div className="d-flex col-lg-6 col-md-6 col-sm-8 col-9 py-2 justify-content-end gap-1">
                                {
                                    isEditEsgKpi ?
                                       <span
                                            className="col-6 d-flex gap-1"
                                            data-bs-dismiss="modal"
                                            data-bs-toggle="modal"
                                            data-bs-target="#confirmDeleteModel"
                                            >
                                              <MyButton
                                                style={{ width: "90%" }}
                                                text="Delete"
                                                customColor={theme === 'dark' ? Colors.primaryBule : '#d32f2f'}
                                                customHoverColor={theme === 'dark' ? Colors.lightBlue : '#D44E4E'} 
                                            />
                                        </span>
                                        : null
                                }

                                <span
                                    className="col-6"
                                    // data-bs-dismiss="modal"
                                    // aria-label="Close"
                                    >
                                    <MyButton
                                        id={isEditEsgKpi ? "edit" : "create"}
                                        style={{ width: "90%", textAlign: "end" }}
                                        text={isEditEsgKpi ? "Update" : "Add"}
                                        onClick={(e) => isEditEsgKpi ? _updateEsgKPI() : _handleCreateESGKpi()}
                                        customColor='#066CFF'
                                        customHoverColor={Colors.water}
                                    />
                                </span>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    })
) 