import { inject, observer } from "mobx-react";
import { toJS } from "mobx";
import { useEffect, useState } from "react";
import { MyCheckbox } from "../../Tools";
import { useTranslation } from "react-i18next";
import { Colors, invertDark } from "../../Assets/Config/color.config";

const EquipmentCheckList = inject("store")(
    observer((props) => {
        const { theme,companyName } = props.store.common;
        const { loading, selectedEquipments, _handleCheckEquipment, energySourceList, _getEnergySourceList } = props.store.quickOrganizationSetUp;
        
        const [itEnergySourceList, setiEnergySourceList] = useState([]);
        const { t } = useTranslation("assetSetupAndGroup");
        const t1 = useTranslation("equipment");
        const t2 = useTranslation("transport");
        const t3 = useTranslation("country");

        // const purchased = {
        //     title: "Purchased Goods",
        //     itTitle: "Purchased Goods",
        //     _id: "PurchasedGoods",
        //     itSecList: [{
        //         itSecTitle: 'Purchased',
        //         equipDetails: [{
        //             category: "Purchased Goods",
        //             categoryKey: "purchased",
        //             nameKey: "fuelType",
        //             sourceKey: "purchased",
        //             sourceType: "Purchased",
        //             type: "Purchased",
        //             typeList: ["Cement", "Plaster", "Paint", "Timber", "Concrete", "Plastic(PS)", "Plastic(ABS)", "PET(flim)", "Aluminum", "Steel", "Cyclohexane", "Epoxy resin", "Copper", "Glass"]
        //         }]
        //     }],
        //     equipDetails: [{
        //         category: "Purchased Good",
        //         categoryKey: "purchased",
        //         nameKey: "fuelType",
        //         sourceKey: "purchased",
        //         sourceType: "Purchased",
        //         type: "Purchased",
        //         typeList: ["Cement", "Plaster", "Paint", "Timber", "Concrete", "Plastic(PS)", "Plastic(ABS)", "PET(flim)", "Aluminum", "Steel", "Cyclohexane", "Epoxy resin", "Copper", "Glass"]
        //     }]
        // }

        // const wasteWater = {
        //     title: "Waste & Water",
        //     itTitle: "Waste & Water",
        //     _id: "wasteWater",
        //     itSecList: [{
        //         itSecTitle: 'Waste & Water',
        //         equipDetails: [{
        //             category: "Waste & Water",
        //             categoryKey: "WasteWater",
        //             nameKey: "fuelType",
        //             sourceKey: "WasteWater",
        //             sourceType: "Waste and Water",
        //             type: "Waste and Water",
        //             typeList: ["Water", "Food waste", "Garden waste", "Plastics", "Paper", "Textile", "Leather/rubgber", "Glass", "Metal", "Hazardous waste", "Others"]
        //         }]
        //     }],
        //     equipDetails: [{
        //         category: "Waste & Water",
        //         categoryKey: "WasteWater",
        //         nameKey: "fuelType",
        //         sourceKey: "WasteWater",
        //         sourceType: "Waste and Water",
        //         type: "Waste and Water",
        //         typeList: ["Water", "Food waste", "Garden waste", "Plastics", "Paper", "Textile", "Leather/rubgber", "Glass", "Metal", "Hazardous waste", "Others"]
        //     }]
        // }

        // const removal = {
        //     title: "Emission Removal & Mitigation",
        //     itTitle: "Emission Removal & Mitigation",
        //     _id: "removal",
        //     itSecList: [{
        //         itSecTitle: 'Removal',
        //         equipDetails: [{
        //             category: "Emission Removal & Mitigation",
        //             categoryKey: "Removal",
        //             nameKey: "fuelType",
        //             sourceKey: "Removal",
        //             sourceType: "Removal",
        //             type: "Removal",
        //             typeList: ["Timber(CLT)", "Biochar (slow-pyrolysis)", "Bioethanol (EtOH-CCS)", "Bioethanol (CE-CCS)", "Bioethanol (CE-CCS+)", "Biodiesel (FTL-CCS)", "Biodiesel (FTL-CCS+)", "Biohydrogen (H2-CCS)", "Biohydrogen (H2-CCS+)", "Biosteel (BECCS-BF)", "Biosteel (BECCS-DRI)", "Bioelectricty (Bipower-CCS)"]
        //         }]
        //     }],
        //     equipDetails: [{
        //         category: "Emission Removal & Mitigation",
        //         categoryKey: "Removal",
        //         nameKey: "fuelType",
        //         sourceKey: "Removal",
        //         sourceType: "Removal",
        //         type: "Removal",
        //         typeList: ["Timber(CLT)", "Biochar (slow-pyrolysis)", "Bioethanol (EtOH-CCS)", "Bioethanol (CE-CCS)", "Bioethanol (CE-CCS+)", "Biodiesel (FTL-CCS)", "Biodiesel (FTL-CCS+)", "Biohydrogen (H2-CCS)", "Biohydrogen (H2-CCS+)", "Biosteel (BECCS-BF)", "Biosteel (BECCS-DRI)", "Bioelectricty (Bipower-CCS)"]
        //     }]
        // }

        useEffect(() => {
            _getEnergySourceList();
        }, []);

        useEffect(() => {

            // if (companyName !== 'ITRI') return;

            let list = toJS(energySourceList).map(e => {
                if (e.title === 'Refrigerants and Fugitives') {
                    e.itTitle = 'Refrigerants & Fugitive Gas';
                    e.equipDetails = e.equipDetails.map(q => {
                        q.itType = 'Type';
                        return q
                    })
                    e.itSecList = [{ itSecTitle: 'Gas', equipDetails: e.equipDetails }]
                } else if (e.title === 'Stationary Combustion' || e.title === 'Mobile Combustion') {
                    e.itTitle = 'Fuel';
                    e.equipDetails = e.equipDetails.map(q => {
                        q.itType = e.title === 'Stationary Combustion' ? '(Stationary Fuel)' : '(Mobile Fuel)';
                        return q
                    })
                    e.itSecList = [{ itSecTitle: e.title, equipDetails: e.equipDetails }]

                } else if (e.title === 'Imported energy (steam, heating, cooling and compressed air)') {
                    e.itTitle = 'Imported energy';
                    e.equipDetails = e.equipDetails.map(q => {
                        q.itType = q.type === "Heat/Steam" ? 'Steam and Heat' : 'Grid Region - Location Based';
                        return q
                    })
                    const a = e.equipDetails[0]
                    e.equipDetails[0] = e.equipDetails[1]
                    e.equipDetails[1] = a
                    e.itSecList = [{ itSecTitle: 'Scope 2 - Purchased Electricity', equipDetails: e.equipDetails }]
                } else if (e.title === 'Upstream T&D') {
                    e.itTitle = 'Transportation';
                    e.equipDetails = e.equipDetails.map(q => {
                        q.itType = q.type;
                        return q
                    })
                    e.itSecList = [{ itSecTitle: 'Scope 3 - Upstream T&D', equipDetails: e.equipDetails }]
                } else if (e.title === 'Waste') {
                    e.itTitle = 'Waste';
                    e.equipDetails = e.equipDetails.map(q => {
                        q.itType = q.type;
                        return q
                    })
                    e.itSecList = [{ itSecTitle: 'Scope 3 - Waste', equipDetails: e.equipDetails }]
                } else if (e.title === 'Emission Removal & Mitigation') {
                    e.itTitle = 'Emission Removal & Mitigation';
                    e.equipDetails = e.equipDetails.map(q => {
                        q.itType = q.type;
                        return q
                    })
                    e.itSecList = [{ itSecTitle: 'Emission Removal & Mitigation', equipDetails: e.equipDetails }]
                } else if (e.title === 'Purchased Goods') {
                    e.itTitle = 'Purchased Goods';
                    e.equipDetails = e.equipDetails.map(q => {
                        q.itType = q.type;
                        return q
                    })
                    e.itSecList = [{ itSecTitle: 'Purchased Goods', equipDetails: e.equipDetails }]
                }
                return e;

            })
            if (!list.length) return
            let [firstC, secC] = [...list.filter(l => l.itTitle === 'Fuel')]
            secC.itSecList = [...firstC?.itSecList, ...secC?.itSecList]
            list = list.filter(f => f.title !== 'Stationary Combustion')
            // list = [...list, purchased, wasteWater, removal]
            // list = [...list, purchased]
            const firstIndex = list[0]
            list[0] = list[1]
            list[1] = firstIndex
            setiEnergySourceList(list)

        }, [companyName, energySourceList]);



        const alertStatus = (index1,list) => {
            const test = selectedEquipments.filter(f => f.name === list[index1]?.title);
            let status = false;
            if (test.length) {
                status = !(test[0].equipments.length > 0)
            }
            return status;
        }

        return (
            <div className="d-flex justify-content-center">
                <div
                    className="w-100"
                    style={{ color: invertDark(theme), borderRadius: 10, background: Colors[theme]?.background, margin: '20px 0' }}
                >
                    <div
                        style={{
                            background: Colors[theme].headerBgColor,
                            padding: '15px 25px',
                            borderTopLeftRadius: 10,
                            borderTopRightRadius: 10,
                            fontSize: 20,
                            color: Colors.white
                        }}
                    >
                        <h5>
                            {t("Which equipments emit CO2 in your company/organization?")}
                        </h5>
                    </div>

                    <div className="m-4">
                        <div className="d-flex mx-3">
                            <i className="bi bi-info-circle px-1" />
                            <h6 className="m-0">{t("Please choose at least one scope data.")}</h6>
                        </div>
                        {
                            loading ?
                                <div className="d-flex justify-content-center">
                                    <i className="spinner-border mt-2" />
                                </div> 
                                // : companyName === 'ITRI' ?
                                    : itEnergySourceList.map((value, key1) => {
                                        return (
                                            <div className="py-2 px-3 mx-3" key={key1}>

                                                <label htmlFor={key1} style={{ fontSize: 16, fontWeight: 'bold', cursor: 'pointer' }}>
                                                    <MyCheckbox
                                                        id={key1}
                                                        value={value.title}
                                                        onChange={(e) => _handleCheckEquipment(e, key1 + "_" + value.title, value.title)}
                                                        checked={selectedEquipments.filter(f => f.name === value.title).length > 0}
                                                    />
                                                    <span className="px-2">{t1.t(value.itTitle)}</span>
                                                    {alertStatus(key1,itEnergySourceList) ?
                                                        <span className="text-danger" style={{ fontSize: 12, fontWeight: 'normal' }}>
                                                            <i className="bi bi-info-circle px-1"></i>
                                                            {t("Please choose at least one energy source below to calculate emission.")}
                                                        </span>
                                                        : null}
                                                </label>

                                                {selectedEquipments.findIndex(f => f.name === value.title) > -1
                                                    ?
                                                    value.itSecList?.map((it, itkey) => {
                                                        return (
                                                            <div className="mx-4 py-2" key={itkey}>
                                                                <span style={{ fontSize: 15, fontWeight: 'bold' }}>{t1.t(it.itSecTitle)}</span>
                                                                {it.itSecTitle === "Scope 3 - Upstream T&D" ? <div className="mx-4 py-2">
                                                                    <span style={{ fontSize: 15, fontWeight: 'bold' }}>{t1.t('Transport')}</span>
                                                                </div> : null}
                                                                {
                                                                    it.equipDetails?.map((ed, key2) => {
                                                                        return (
                                                                            <div style={{ marginLeft: it.itSecTitle === "Scope 3 - Upstream T&D" && 45 }} className={it.itSecTitle === "Scope 3 - Upstream T&D" ? "py-2" : "mx-4 py-2"} key={key2}>
                                                                                <span style={{ fontSize: 15, fontWeight: 'bold' }}>{t1.t(ed.itType)}</span>

                                                                                <div className="d-flex flex-row flex-wrap py-1" style={{ overflow: "auto", maxHeight: "100px" }}>

                                                                                    {ed.typeList?.map((tl, key3) => {
                                                                                        return (
                                                                                            <label key={key3} className="col-4" htmlFor={key3 + tl + "_" + ed.type} style={{ fontSize: 13, cursor: 'pointer' }}>
                                                                                                <MyCheckbox
                                                                                                    id={key3 + tl + "_" + ed.type}
                                                                                                    value={tl}
                                                                                                    onChange={() => _handleCheckEquipment(ed, key1 + "_" + value.title, value.title, tl, key3 + tl + "_" + ed.type,value.title ==='Mobile Combustion'?it.itSecTitle:null)}
                                                                                                    checked={selectedEquipments?.filter(f => f.name === value.title).length > 0
                                                                                                        ? selectedEquipments.filter(f => f.name === value.title)?.[0].equipments?.filter(eq => eq.name === tl && eq.subId === key3 + tl + "_" + ed.type).length > 0
                                                                                                        : false}
                                                                                                />
                                                                                                <span className="px-2">{value.title === "Upstream T&D" ? t2.t(tl) : (ed.type === "Purchased Electricity(Location Based)" ? t3.t(tl) : t1.t(tl))}</span>
                                                                                            </label>
                                                                                        )
                                                                                    }
                                                                                    )}

                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }

                                                                    )
                                                                }
                                                            </div>
                                                        )
                                                    }

                                                    ) : null
                                                }
                                            </div>
                                        )
                                    }

                                    ) 
                                    // :
                                    // energySourceList?.map((value, key1) => {
                                    //     return (
                                    //         <div className="py-2 px-3 mx-3" key={key1}>

                                    //             <label htmlFor={key1} style={{ fontSize: 16, fontWeight: 'bold', cursor: 'pointer' }}>
                                    //                 <MyCheckbox
                                    //                     id={key1}
                                    //                     value={value.title}
                                    //                     onChange={(e) => _handleCheckEquipment(e, key1 + "_" + value.title, value.title)}
                                    //                     checked={selectedEquipments.filter(f => f.name === value.title).length > 0}
                                    //                 />
                                    //                 <span className="px-2">{t(value.title)}</span>
                                    //                 {alertStatus(key1,energySourceList) ?
                                    //                     <span className="text-danger" style={{ fontSize: 12, fontWeight: 'normal' }}>
                                    //                         <i className="bi bi-info-circle px-1"></i>{t("Please choose at least one energy source below to calculate emission.")}
                                    //                     </span>
                                    //                     : null}
                                    //             </label>

                                    //             {selectedEquipments.findIndex(f => f.id === key1 + "_" + value.title) > -1
                                    //                 ? value.equipDetails?.map((ed, key2) => {
                                    //                     return (
                                    //                         <div className="mx-4 py-2" key={key2}>
                                    //                             <span style={{ fontSize: 15, fontWeight: 'bold' }}>{t(ed.type)}</span>

                                    //                             <div className="d-flex flex-row flex-wrap py-1" style={{ overflow: "auto", maxHeight: "100px" }}>

                                    //                                 {ed.typeList?.map((tl, key3) => {
                                    //                                     return (
                                    //                                         <label key={key3} className="col-4" htmlFor={key3 + tl + "_" + ed.type} style={{ fontSize: 13, cursor: 'pointer' }}>
                                    //                                             <MyCheckbox
                                    //                                                 id={key3 + tl + "_" + ed.type}
                                    //                                                 value={tl}
                                    //                                                 onChange={() => _handleCheckEquipment(ed, key1 + "_" + value.title, value.title, tl, key3 + tl + "_" + ed.type)}
                                    //                                                 checked={selectedEquipments?.filter(f => f.name === value.title).length > 0
                                    //                                                     ? selectedEquipments.filter(f => f.name === value.title)?.[0].equipments?.filter(eq => eq.name === tl && eq.subId === key3 + tl + "_" + ed.type).length > 0
                                    //                                                     : false}
                                    //                                             />
                                    //                                             <span className="px-2">{value.title === "Upstream T&D" ? t2.t(tl) : (ed.type === "Purchased Electricity(Location Based)" ? t3.t(tl) : t1.t(tl))}</span>
                                    //                                         </label>
                                    //                                     )
                                    //                                 }
                                    //                                 )}

                                    //                             </div>
                                    //                         </div>
                                    //                     )
                                    //                 }

                                    //                 ) : null
                                    //             }
                                    //         </div>
                                    //     )
                                    // }

                                    // )
                        }
                    </div>
                </div>
            </div>
        )
    })
);

export default EquipmentCheckList;