
import * as API from "./URL";

export const fetchBuildingListByUserId = async (userId, callback) => {
  return fetch(API.getBuildingList+userId, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err.toString(), null));
};

export const fetchBuildingList = async (callback) => {
  return fetch(API.getBuildingList, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err.toString(), null));
};

export const fetchAssetList = async (callback) => {
  return fetch(API.getAssetList, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err.toString(), null));
};

export const updateSiteInfo = async (buildingId, data, callback) => {
  return fetch(API.updateSiteInfo(buildingId), {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
      // "token": `${token}`
    },
    body: JSON.stringify(data)
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err.toString(), null));
};

export const fetchBuildingType =  async (callback) => {
  return fetch(API.getBuildingType, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err.toString(), null));
};

export const addNewBuilding =  async (data,callback) => {
  return fetch(API.addNewBuilding, {
    method: "POST",
    headers: {
      // "Content-Type": "application/json",
      Accept: "*/*",
      // "Access-Control-Allow-Origin": "*",
    },
    body: (data)
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err.toString(), null));
};

export const addOneTimeBuilding =  async (data,callback) => {
  return fetch(API.addNewBuilding, {
    method: "POST",
    headers: {
      // "Content-Type": "application/json",
      Accept: "*/*",

      
      // "Access-Control-Allow-Origin": "*",
    },
    body: data
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err.toString(), null));
};

export const fetchDeleteBuilding=async(data,callback)=>{
  return fetch(API.deleteBuilding, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify(data)
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err.toString(), null));
}

export const fetchUpdateBuilding =  async (buildingId,data,callback) => {
  return fetch(API.updateBuildingAPI+buildingId, {
    method: "POST",
    headers: {
      // "Content-Type": "application/json",
      Accept: "*/*",
      // "Access-Control-Allow-Origin": "*",
    },
    body:  data
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err.toString(), null));
};