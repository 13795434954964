import * as API from "./URL";

export const createNewPillar = async (pillarData, callback) => {
    return fetch(API.pillarSetup, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(pillarData)
    })
        .then((res) => res.json())
        .then((data) => callback(null, data))
        .catch((err) => callback(err.toString(), null));
}


export const editPillarData = async (pillarData, pillarId, callback) => {
    return fetch(API.pillarSetup + "/" + pillarId, {

        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(pillarData)
    })
        .then((res) => res.json())
        .then((data) => callback(null, data))
        .catch((err) => callback(err.toString(), null));
}

export const fetchPillars = async (callback) => {
    return fetch(API.pillarSetup, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            "Access-Control-Allow-Origin": "*",
        },
    })
        .then((res) => res.json())
        .then((data) => callback(null, data))
        .catch((err) => callback(err.toString(), null));
}

export const deletePillar = async (pillarId, callback) => {
    return fetch(API.pillarSetup + pillarId, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            "Access-Control-Allow-Origin": "*",
        },
    })
        .then((res) => res.json())
        .then((data) => callback(null, data))
        .catch((err) => callback(err.toString(), null));
}

export const averageFrameworkProtocol = async (callback) => {
    return fetch(API.averageFrameworkProtocol, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            "Access-Control-Allow-Origin": "*",
        },
    })
        .then((res) => res.json())
        .then((data) => callback(null, data))
        .catch((err) => callback(err.toString(), null));
} 

export const fetchAllPillars = async (callback) => {
    return fetch(API.pillars, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            "Access-Control-Allow-Origin": "*",
        },
    })
        .then((res) => res.json())
        .then((data) => callback(null, data))
        .catch((err) => callback(err.toString(), null));
}

export const fetchAllSubPillars = async (callback) => {
    return fetch(API.sub_pillars, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            "Access-Control-Allow-Origin": "*",
        },
    })
        .then((res) => res.json())
        .then((data) => callback(null, data))
        .catch((err) => callback(err.toString(), null));
} 

export const fetchESGTrackingCount = async (callback) => {
    return fetch(API.pillarCountByKpi, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            "Access-Control-Allow-Origin": "*",
        },
    })
        .then((res) => res.json())
        .then((data) => callback(null, data))
        .catch((err) => callback(err.toString(), null));
}

export const fetchAllESGKPI = async (callback) => {
    return fetch(API.esgKpiT_R + "all", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            "Access-Control-Allow-Origin": "*",
        },
    })
        .then((res) => res.json())
        .then((data) => callback(null, data))
        .catch((err) => callback(err.toString(), null));
}

export const fetchCreateESGKPI = async (kpiManagementData, pillar, subPillar, callback) => {
    return fetch(API.esgKpiT_R + "?pillar=" + pillar + "&subPillar=" + subPillar, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(kpiManagementData)
    })
        .then((res) => res.json())
        .then((data) => callback(null, data))
        .catch((err) => callback(err.toString(), null));
}

export const fetchUpdateESGKPI = async (kpiManagementData, kpi_id, callback) => {
    return fetch(API.esgKpiT_R + kpi_id, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(kpiManagementData)
    })
        .then((res) => res.json())
        .then((data) => callback(null, data))
        .catch((err) => callback(err.toString(), null));
}

export const fetchDeleteESGKPI = async ( kpi_id, callback) => {
    return fetch(API.esgKpiT_R + kpi_id, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            "Access-Control-Allow-Origin": "*",
        },
    })
        .then((res) => res.json())
        .then((data) => callback(null, data))
        .catch((err) => callback(err.toString(), null));
}