import { toJS } from "mobx";
import React from "react";
import { Cookies } from "react-cookie";
import { Colors } from "../../Assets/Config/color.config";
import { MyButton, MyInput, Selector , MuiMultiSelector , MuiGroupMultiSelector } from "../../Tools";
import { useTranslation } from 'react-i18next';

const UserForm = (props) => {
  const {
    formMode,
    editedUser,
    formData,
    visible,
    permittedBuilding,
    _handleCloseForm,
    _handleView,
    _handleInputChange,
    _handleSelect,
    _handleRoleSelect,
    _handleSubmit,
    theme,
  } = props;

  const siteModuleData = new Cookies().get("siteModuleData");

  const { t } = useTranslation("user_management");
  const t1 = useTranslation("common");

  const buildingOptions_ = permittedBuilding
    ? permittedBuilding.map((v) => ({
      id: v._id,
      value: v.buildingName,
      label: v.buildingName,
    }))
    : [];

  const handleChange = (event, selectKey) => {
    if(selectKey === 'units'){
      let duplicated = toJS(event.target.value).filter((c,index)=>{
        return toJS(event.target.value).map(v=>v._id).indexOf(c._id) !== index;
      });  
  
      if(duplicated && duplicated.length > 0 && toJS(event.target.value).length > 2){
        toJS(event.target.value).map((dv, idx) => {
          const objWithIdIndex = toJS(event.target.value).findIndex((obj) => obj._id === duplicated[0]._id);
          if (objWithIdIndex > -1) {            
            toJS(event.target.value).splice(objWithIdIndex, 1);
          }
        });               
      }else if (duplicated && duplicated.length > 0 && toJS(event.target.value).length === 2){      
        event.target.value = []
      }else{
        event.target.value = event.target.value;
      }

      
      const {
        target: { value },
      } = event;
      _handleSelect({data : value , key : selectKey})
    }else{
      const {
        target: { value },
      } = event;
      _handleSelect({data : value , key : selectKey})
    }    
  };

  let units = [];

  if (formData.buildingId) {
    formData.buildingId.forEach((b) => {
      if (permittedBuilding.find(p => p._id === b)) {
        const label = permittedBuilding.find(p => p._id === b).buildingName;
        const unit = permittedBuilding.find(p => p._id === b).unit;
        
        if(unit.length===0) return;
        units.push({
          buildingId: b,
          label,
          units:unit
        })
      }
    })
  }

  const roleOptions_ = [
    { label: "User", value: "user" },
    { label: "Admin", value: "admin" },
    { label: "Viewer", value: "viewer" },
  ];

  const cookies = new Cookies();
  const currentUserId = cookies.get("userId");

  return (
    <div
      className=" p-3"
      style={{ background: theme === 'dark' ? Colors[theme].secondBackground : Colors.Dark[5], borderRadius: 5, boxShadow: '2px 4px 11px rgba(0,0,0,0.25)' }}
    >
      <div className="d-flex row justify-content-around align-items-center">
        <div
          className={`${formMode === "Create" || currentUserId === editedUser._id
            ? "col-12"
            : "col-lg-9 col-md-8 col-sm-10"
            }`}
        >
          <h5 className="p-0 m-0">
            {formMode === "Create" ? t("Create User") : t("Editing User", { user: editedUser.name })}
          </h5>
        </div>

        {formMode === "Editing" && currentUserId !== editedUser._id && (
          <div className="col-lg-3 col-md-4 col-sm-4 d-flex justify-content-end">
            <span
              data-bs-toggle={"modal"}
              data-bs-target={"#confirmModal"}
              style={{
                cursor: "pointer",
              }}
            >
              <MyButton text={t("Delete")} customColor={"rgb(225, 0, 0, 80%)"} />
            </span>
          </div>
        )}
      </div>
      <div
        className="col-11 mx-auto mb-3 py-2"
        style={{ borderBottom: "1px solid #919191" }}
      >
        <div className="row d-flex align-items-center my-2 ">
          <span
            style={{ background: theme === 'dark' ? Colors.primaryBule : Colors.blue, color: Colors.white }}
            className="p-2 mb-2 rounded fw-bold"
          >
            {t("User Information")}
          </span>
          <div className="col-4 fw-bold">{t1.t("Role")}</div>
          <div className="col">
            <Selector
              getOptionLabel={(e) => (
                <div style={{ display: "flex", textAlign: "left" }}>
                  <span style={{ marginLeft: 5 }}>{t(e.label)}</span>
                </div>
              )}
              menuListColor={Colors.Dark[0]}
              options={roleOptions_}
              value={roleOptions_.filter((r) => r.value === formData.role)}
              placeholder={t("Select Role")}
              _handleSelect={(e) => _handleRoleSelect(e)}
            />
          </div>
        </div>
        <div className="row d-flex align-items-center my-2 ">
          <div className="col-4 fw-bold">{t1.t("Email")}</div>
          <div className="col">
            <MyInput
              required={true}
              id={"email"}
              type={"email"}
              value={formData.email ? formData.email : ""}
              onChange={(e) => _handleInputChange(e)}
              placeHolder={t("Email")}
            />
          </div>
        </div>
        <div className="row d-flex align-items-center my-2 ">
          <div className="col-4 fw-bold">{t1.t("User Name")}</div>
          <div className="col">
            <MyInput
              required={true}
              id={"userName"}
              type={"text"}
              autoComplete={"new-password"}
              value={formData.userName ? formData.userName : ""}
              onChange={(e) => _handleInputChange(e)}
              placeHolder={t("User Name")}
            />
          </div>
        </div>
        {formMode !== "Editing" && (
          <div className="row d-flex align-items-center my-2 ">
            <div className="col-4 fw-bold">{t1.t("Password")}</div>
            <div className="col">
              <MyInput
                required={true}
                id={"password"}
                type={"password"}
                autoComplete={"new-password"}
                value={formData.password ? formData.password : ""}
                onChange={(e) => _handleInputChange(e)}
                placeHolder={t("Password")}
                _handleView={_handleView}
                visible={visible}
              />
            </div>
          </div>
        )}
        <div className="row d-flex align-items-center my-2 ">
          <div className="col-4 fw-bold">{t("Company Name")}</div>
          <div className="col">

            <MyInput
              disabled={siteModuleData.companyName == "All" ? false : true}
              required={true}
              id={"companyName"}
              type={"text"}
              disable
              value={formData.companyName ? formData.companyName : ""}
              onChange={(e) => _handleInputChange(e)}
              placeHolder={t("Company Name")}
            />
          </div>
        </div>
        <div className="row d-flex align-items-center my-2 ">
          <span
            style={{ background: theme === 'dark' ? Colors.primaryBule : Colors.blue, color: Colors.white }}
            className="p-2 rounded fw-bold"
          >
            {t("Site Information")}
          </span>
        </div>
        
        <div className="row d-flex align-items-center my-2 ">
          <div className="col-4 fw-bold">{t("Building Name")}</div>
          <div className="col-8">
            {
              formData.role === "admin" ?
              (
                <MuiMultiSelector
                  menuListColor={Colors.Dark[0]}
                  options={buildingOptions_}
                  value={formData.buildingId ? formData.buildingId : []}
                  isMulti
                  placeholder={ t("Select a building") }
                  _handleChange={(e) => {
                    handleChange(e, "buildingId")
                  }}
                />
              ) : (
                <Selector
                  menuListColor={Colors.Dark[0]}
                  options={buildingOptions_}
                  value={buildingOptions_.find((b) => b.id === (toJS(formData.buildingId)?.length > 0 && toJS(formData.buildingId)[0])) || ""}
                  placeholder={t("Select a building")}
                  _handleSelect={(e) => _handleSelect({data : [e.id] , key : "buildingId"})
                }
                />
              )
            }
          </div>
        </div>
        {units.length?<div className="row d-flex align-items-center my-2 ">
          <div className="col-4 fw-bold">{t("Unit or Floor")}</div>
          <div className="col-8">
          <MuiGroupMultiSelector
              menuListColor={Colors.Dark[0]}
              options={units}
              value={formData.units ? formData.units : []}
              isMulti
              placeholder={ t("Select a Unit") }
              _handleChangeUnit={(e) => {
                handleChange(e, "units")
              }}
            />                
          </div>
        </div>:''
        }
      </div>
      <div className="row d-flex my-1 justify-content-center">
        <MyButton
          text={t("Close")}
          fontSize={15}
          rounded
          customColor={
            theme === "dark" ? Colors.primaryBule : '#919191'
          }
          style={{ width: "30%" }}
          onClick={_handleCloseForm}
        />
        <MyButton
          text={t(formMode === "Create" ? "Create" : "Update")}
          fontSize={15}
          rounded
          customColor={"#0d6efd"}
          style={{ width: "30%", marginLeft: '10px' }}
          onClick={_handleSubmit}
        />
      </div>
    </div>
  );
};

export default UserForm;