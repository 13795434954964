import { inject, observer } from "mobx-react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { MyButton, MyInput, Selector } from "../../Tools";
import Store from "../../Store";
import { toJS } from "mobx";
import { useEffect } from "react";
import { useTranslation } from 'react-i18next';

export const UserRegistration = inject("store")(
  observer((props) => {
    const { _handleCloseForm } = Store.manageUsers;
    const { setPageIndex, pageIndex } = props.store.specialOneTime;
    const { theme } = props.store.common;
    const { t } = useTranslation("one_time");

    return (
      <div
        className="cardStyle px-4"
        style={{ backgroundColor: Colors[theme].secondBackground }}
      >
        <Header theme={theme} step={2} _handleCloseForm={_handleCloseForm} />
        <hr />
        <ModalFilterBar />
        <div className="row pt-2 m-0">
          <div className="col-6"></div>
          <div className="col-6 p-0">
            <div className="row d-flex justify-content-end m-0">
              <MyButton
                style={{ width: 150, marginRight: 10 }}
                leftIcon={<i className="bi bi-arrow-left"></i>}
                text={ t("BACK") }
                onClick={() => setPageIndex(pageIndex - 1)}
                customColor={theme === 'dark' ? Colors.primaryBule : '#888'}
                customHoverColor={theme === 'dark' ? Colors.lightBlue : '#aaa'}
              />
              <MyButton
                style={{ width: 150 }}
                rightIcon={<i className="bi bi-arrow-right"></i>}
                text={ t("NEXT") }
                onClick={() => setPageIndex(pageIndex + 1)}
                customColor={Colors.water}
              />
            </div>
          </div>
        </div>
      </div>
    );
  })
);

const Header = ({ step, _handleCloseForm,theme }) => {
  const { t } = useTranslation("one_time");
  return (
    <div className="d-flex justify-content-between">
      <span style={{ fontSize: 25, color:invertDark(theme) }}>
        <strong>{ t("Step", {count: step}) } :</strong> { t("Account Management and Creation") }
      </span>
      {/* <div onClick={() => _handleCloseForm("newAccount")} className="my-auto" style={{ color: Colors.water, fontSize: 15, cursor: 'pointer' }}>
        <i class="bi bi-plus"></i>Add New User
      </div> */}
    </div>
  );
};

const UserTable = ({ data, _handleDeleteUser, permittedBuilding }) => {
  const { t } = useTranslation("one_time");
  return (
    <div>
      <div
        className="row fw-bold"
        style={{ borderBottom: " 1px solid rgb(255, 255, 255,0.1)" }}
      >
        <div className="col-5">{ t("User Name") }</div>
        <div className="col-5">{ t("Building") }</div>
      </div>
      <div style={{ maxHeight: 318, overflowY: "auto", overflowX: "hidden" }}>
        {data && permittedBuilding ? (
          data.length > 0 ? (
            data.map((v, k) => {
              return (
                <div key={k} className="d-flex flex-row py-1">
                  <div className="col-5">{v.userName}</div>
                  <div className="col-5">
                    {permittedBuilding?.find((v1) => v1._id === v.buildingId)
                      ?.buildingName || "-"}
                  </div>
                  <div className="d-flex flex-col-2">
                    <span
                      className="bg-danger d-flex align-items-center px-1 justify-content-center"
                      style={{
                        borderRadius: '50%',
                        height: 20,
                        width: 20,
                        cursor: "pointer",
                        color: '#fff'
                      }}
                      onClick={() => _handleDeleteUser(v._id)}
                    >
                      <i className="bi bi-trash"></i>
                    </span>
                  </div>
                </div>
              );
            })
          ) : (
            <h5 className="text-center pt-5">
              { t("No User Found") }
            </h5>
          )
        ) : (
          <div className="text-center pt-5">
            <i className="spinner-border mt-2" />
          </div>
        )}
      </div>
    </div>
  );
};

const ModalFilterBar = inject("store")(
  observer((props) => {
    const {
      newAccountData,
      visible,
      _handleView,
      _handleInputChange,
      _handleSelect,
      _handleAddNewUser,
      newUserList,
      _handleDeleteUser,
      getUserData,
      allUsers,
      _handleRoleSelect
    } = Store.manageUsers;

    const { getBuildingList, permittedBuilding, theme } = props.store.common;

    useEffect(() => {
      getBuildingList();
      getUserData(false);
    }, []);

    const buildingOptions_ = permittedBuilding?.map((v) => ({
      id: v._id,
      value: v.buildingName,
      label: v.buildingName,
    }));

    const roleOptions_ = [
      {
        value: 'user',
        label: 'User',
      },
      {
        value: 'admin',
        label: 'Admin',
      },
      {
        value: 'viewer',
        label: 'Viewer',
      },
    ];

    const { t } = useTranslation("one_time");

    return (
      <div
        className="row m-0 py-2"
        style={{ borderBottom: " 1px solid rgb(255, 255, 255,0.1)", color:invertDark(theme) }}
      >
        <div className="col-6 p-0">
          <div
            className="p-3 me-2"
            style={{ backgroundColor: Colors[theme].active, borderRadius: 5 }}
          >
            <h5 className="m-1">
              { t("Create New User") }
            </h5>
            {/* <form onSubmit={_handleAddNewUser}> */}
            <div className="row d-flex align-items-center m-2">
              <div className="col-4 fw-bold">
                { t("User Name") }
              </div>
              <div className="col">
                <MyInput
                  required={true}
                  id={"userName"}
                  type={"text"}
                  value={newAccountData.userName ? newAccountData.userName : ""}
                  onChange={(e) => _handleInputChange(e, "newAccount")}
                  placeHolder={ t("User Name") }
                />
              </div>
            </div>
            <div className="row d-flex align-items-center m-2">
              <div className="col-4 fw-bold">
                { t("Email") }
              </div>
              <div className="col">
                <MyInput
                  required={true}
                  id={"email"}
                  type={"email"}
                  value={newAccountData.email ? newAccountData.email : ""}
                  onChange={(e) => _handleInputChange(e, "newAccount")}
                  placeHolder={ t("Email") }
                />
              </div>
            </div>
            <div className="row d-flex align-items-center m-2 ">
              <div className="col-4 fw-bold">
                { t("Password") }
              </div>
              <div className="col">
                <MyInput
                  required={true}
                  id={"password"}
                  type={"password"}
                  value={newAccountData.password ? newAccountData.password : ""}
                  onChange={(e) => _handleInputChange(e, "newAccount")}
                  placeHolder={ t("Password") }
                  _handleView={_handleView}
                  visible={visible}
                />
              </div>
            </div>
            <div className="row d-flex align-items-center m-2 ">
              <div className="col-4 fw-bold">
                { t("Role") }
              </div>
              <div className="col">
                <Selector
                  menuListColor={Colors.Dark[0]}
                  options={roleOptions_}
                  value={roleOptions_?.filter(
                    (r) => r.value === newAccountData.role
                  )}
                  placeholder={ t("Select Role") }
                  _handleSelect={(e) => _handleRoleSelect(e, "newAccount")}
                />
              </div>
            </div>
            <div className="row d-flex align-items-center m-2 ">
              <div className="col-4 fw-bold">
                { t("Building") }
              </div>
              <div className="col">
                <Selector
                  menuListColor={Colors.Dark[0]}
                  options={buildingOptions_}
                  value={buildingOptions_?.filter(
                    (bd) => bd.id === newAccountData.building
                  )}
                  placeholder={ t("Select a building") }
                  _handleSelect={(e) => _handleSelect({data:e, type:"newAccount"})}
                />
              </div>
            </div>
            <div className="row d-flex align-items-center m-2 ">
              <div className="col-4 fw-bold">
                { t("Unit or Floor") }
              </div>
              <div className="col">
                <MyInput
                  required={true}
                  id={"unit/floor"}
                  type={"text"}
                  value={
                    newAccountData["unit/floor"]
                      ? newAccountData["unit/floor"]
                      : ""
                  }
                  onChange={(e) => _handleInputChange(e, "newAccount")}
                  placeHolder={ t("Unit or Floor") }
                />
              </div>
            </div>
            <div className="row d-flex m-4">
              <MyButton
                type="submit"
                text={ t("Add User") }
                customColor={Colors.water}
                onClick={_handleAddNewUser}
              />
            </div>
            {/* </form> */}
          </div>
        </div>
        <div className="col-6 p-0">
          <div
            className="p-3 ms-2"
            style={{
              backgroundColor: Colors[theme].active,
              borderRadius: 5,
              height: "100%",
            }}
          >
            <h5 className="m-1">
              { t("Added User List") }
            </h5>
            <UserTable
              data={toJS(allUsers)}
              permittedBuilding={toJS(permittedBuilding)}
              _handleDeleteUser={_handleDeleteUser}
            />
          </div>
        </div>
      </div>
    );
  })
);
