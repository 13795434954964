import { Cookies } from "react-cookie";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import Store from "../../Store";
import { useTranslation } from "react-i18next";
import { MyCircularSlider, Selector } from "../../Tools";
import { Tracking_Table } from "./TrackingTable";
import trackingDatas from "../../Assets/targetTrackingData.json";
import digitalEdgeTrackingData from "../../Assets/digitalEdgeDummyTracking.json";
import syscomData from "../../Assets/syscomData.json"
import noImage from "../../Assets/images/leaf.png";
import { useEffect, useState } from "react";
import Pie_Chart from "../../Assets/Charts/PieChart";
import { toJS } from "mobx";
import { RadialBarChart } from "../../Assets/Charts/RadialBarChart";
import { inject, observer } from "mobx-react";

export const DummyTracking = inject("store")(
    observer((props) => {
    const { theme, companyName, _handleGetYearOptions, yearOptions } = props.store.common;
    const { trakingValue, columNames, index, permittedBuilding, focusArea } = props;
    const tracking = props.trakingValue;
    const { getScopeDataByCategory, individualTrackingData, fetchTrackingData, dataFillPercent,dataFillSiteCount, selectedTrackingTblYear, _handleSelectTrackingTblYear, countryOptions, _handleGetCountry_n_SiteOptions} = props.store.tracking;


    const { t } = useTranslation("tracking");

    // const [totalBaselineYr, setTotalBaselineYr] = useState(0);
    // const [totalCurrentYr, setTotalCurrentYr] = useState(0);

    useEffect(() => {
        permittedBuilding &&
          focusArea !== "Waste" &&
          focusArea !== "Green Material" &&
          !(companyName === 'Enterprise SG' && (focusArea === 'Scope 1 Emission' || focusArea === 'Scope 3 Emission')) &&
          fetchTrackingData(permittedBuilding, selectedTrackingTblYear);
      }, [permittedBuilding, selectedTrackingTblYear]);

    useEffect(() => {
        const minBaselineYear = individualTrackingData?.map(v=> String(v.baseline_year)||String(v.baselineYear))?.filter(f=> f!== undefined).sort((a,b)=> a-b)?.[0] || new Date().getFullYear();

        if (minBaselineYear) {
            _handleGetYearOptions(minBaselineYear)
        }
    }, [individualTrackingData])

    // useEffect(() => {
    //     let baseline = 0;
    //     let current = 0;
    //     if (companyName === 'Syscom') {
    //         syscomData[focusArea]?.map((value) => {
    //             baseline += value.baseline_year;
    //             current += value.current_year;
    //         })
    //         setTotalBaselineYr(baseline);
    //         setTotalCurrentYr(current);
    //     }
    //     else if (companyName === 'Enterprise SG') {
    //         if (focusArea === 'Scope 1 Emission') {
    //             individualTrackingData?.map((value) => {
    //                 baseline += Number(value.baseline);
    //                 current += Number(value.current);
    //             })
    //             setTotalBaselineYr(baseline);
    //             setTotalCurrentYr(current);
    //         } else {
    //             individualTrackingData?.map((value) => {
    //                 baseline += value.baseline;
    //                 current += value.current;
    //             })
    //             setTotalBaselineYr(baseline);
    //             setTotalCurrentYr(current);
    //         }

    //     }
    // }, [individualTrackingData]);

    useEffect(() => {
        if (companyName === 'Enterprise SG' && (focusArea === 'Scope 1 Emission' || focusArea === 'Scope 3 Emission')) {
            getScopeDataByCategory(focusArea)
        }
    }, [])

    const siteData = companyName === 'Digital Edge' || companyName == "ClientA" ?
        digitalEdgeTrackingData[focusArea]?.map((value, k) => ({
            siteName: value.supplier,
            consumptionLastYr: value.baseline_year,
            consumptionCurrentYr: value.current_year,
            progress: value.total_progress,
            image: "no_image.png",
            allItem: 31,
            greenItem: 25,
            estDate: "Q1 2023",
        }))
        :
        companyName === 'Syscom' ?
            syscomData['Water'].map((value, k) => ({
                siteName: value.supplier,
                consumptionLastYr: value.baseline_year,
                consumptionCurrentYr: value.current_year,
                progress: value.total_progress,
                image: "no_image.png",
                allItem: 31,
                greenItem: 25,
                estDate: "Q1 2023",
            }))
            :
            trackingDatas[focusArea]?.map((value, k) => ({
                siteName: value.supplier,
                consumptionLastYr: value.baseline_year,
                consumptionCurrentYr: value.current_year,
                progress: value.total_progress,
                image: "no_image.png",
                allItem: 31,
                greenItem: 25,
                estDate: "Q1 2023",
            }));
    // console.log('permittedBuilding ======> ', toJS(permittedBuilding), toJS(individualTrackingData))
    const pieChartData = companyName === 'Digital Edge' || companyName == "ClientA" ?
        (
            permittedBuilding?.reduce((r, c) => {
                const R = [...r];
                const i = R.findIndex(v => v.buildingType === c.type?.building_type);
                const currentYearVal = individualTrackingData?.find(v1 => (v1?.building).trim() === (c.buildingName)?.trim())?.current;
 
                if (i > -1) {
                    R[i].totalCount += 1;
                    R[i].filledCount += currentYearVal ? 1 : 0;
                } else {
                    R.push({
                        buildingType: c.type?.building_type,
                        totalCount: 1,
                        filledCount: currentYearVal ? 1 : 0
                    })
                }
                return R
            }, [])
        ) : null

    return (
        <div className="row m-0" style={{ color: invertDark(theme) }}>
            {
                (companyName !== "Digital Edge" && companyName !== "ClientA") ?
                    <div className="d-flex justify-content-start text-center ">
                        <div className="py-3">
                            {
                                (focusArea === 'Waste' && companyName === "Enterprise SG") ?
                                    <span className="fw-bold">Eliminate plastic drinking bottles</span>
                                    :
                                    <>
                                        <span className="fw-bold"> {focusArea === 'Scope 1 Emission' ? 'TARGET 1' : trakingValue?.target} : </span>{" "}
                                        {trakingValue.description ? trakingValue.description : focusArea === 'Scope 1 Emission' ? 'To reduce scope 1 emission by 20% p.a. relative to baseline year' : trakingValue.description}
                                    </>
                            }

                        </div>
                    </div>
                : null
            }
            {
                (companyName !== "Digital Edge" && companyName !== "ClientA") ?
                    <div className="d-flex align-self-stretch col-lg-3 col-md-4 col-sm-12 col-12 my-1">
                        <div
                            className="cardStyle me-1 row d-flex align-items-center w-100"
                            style={{
                                background: Colors[theme].secondBackground,
                                // minWidth: 300,
                                color: Colors[theme].textColor,
                            }}
                        >

                            <div
                                className={`d-flex flex-row align-self-start align-items-center justify-content-between flex-wrap`}
                                style={{ fontSize: 23, color: invertDark(theme) }}
                            >
                                <div>{t("Overall Tracking")}</div>
                                {
                                    companyName === "Enterprise SG" ? (
                                        <div className="w-100" style={{ fontSize: 17 }}>{t("Total Emission")}</div>
                                    )
                                        : companyName === 'Digital Edge' || companyName == "ClientA" ? (
                                            <div style={{ fontSize: 17 }}>{t("Current Year: ") + (new Date()).getFullYear()}</div>
                                        )
                                            : null
                                }
                            </div>

                            <div
                                className="text-center py-2 d-flex align-self-start flex-wrap justify-content-center align-items-center"
                                style={{}}
                            >
                                {
                                    companyName === 'Digital Edge' || companyName == "ClientA" ?
                                        <>
                                            <div className="row d-flex justify-content-center">
                                                <div className="col-12">
                                                <RadialBarChart 
                                                    colors={COLORS}
                                                    data={pieChartData?.map((v) => ({
                                                        name: v.buildingType,
                                                        value: v?.totalCount,
                                                        percent: ((v.filledCount/v.totalCount)*100)?.toFixed(1)
                                                    }))}
                                                />
                                                    {/* <Pie_Chart id={'overall_tracking'} data={pieChartData?.map(v => ({ name: v.buildingType, value: v?.totalCount }))} /> */}
                                                </div>

                                            </div>
                                            <span className="text-start" style={{ fontSize: 16, width: 'auto', }}>
                                                {
                                                    pieChartData?.map((dt, key) => (
                                                        <div className="d-flex" key={key}>
                                                            <span
                                                                className="me-1 mt-2"
                                                                style={{
                                                                    width: '12px',
                                                                    height: '12px',
                                                                    // maxWidth: 12,
                                                                    // display: 'inline-block',
                                                                    background: COLORS[key]
                                                                }}
                                                            ></span>
                                                            <span className="col">
                                                            {
                                                                dt.filledCount === 0 ?
                                                                `${dt.buildingType} : no site fill up data`
                                                                :
                                                                `${dt.buildingType} : ${dt.filledCount} of ${dt.totalCount} site(s) fill up data`
                                                            }
                                                            </span>
                                                        </div>
                                                    ))
                                                }
                                            </span>
                                        </>
                                        :
                                        <div className="row">
                                            <div className="col-12">
                                                <MyCircularSlider
                                                    label=" "
                                                    value={
                                                        // (siteDataKW[0].consumptionCurrentYr /
                                                        //   siteDataKW[0].consumptionLastYr) *
                                                        // 100 +
                                                        // (parseInt(index) * 8)
                                                        
                                                        // trakingValue.title === "green" || trakingValue.title === "waste"
                                                        //     ? 100
                                                        //     : (trakingValue.title === "water" && companyName === 'Syscom') ? 100
                                                        //         : ((focusArea === 'Waste' || focusArea === 'Scope 1 Emission') && companyName === 'Enterprise SG')
                                                        //             ? 75 : companyName === 'Digital Edge' ? 0 : 75

                                                        dataFillPercent
                                                    }
                                                    color={trakingValue.progressBarColor}
                                                    knobSize={0}
                                                    theme={theme}
                                                />
                                            </div>

                                            <div className="pt-3 col-12" style={{ fontSize: 16 }}>
                                                {" "}
                                                {
                                                    dataFillSiteCount==0? t("No site filled up data") : `${dataFillSiteCount} ` + t("sites filled up data") 
                                                }
                                                {/* {companyName === "Digital Edge"
                                                    ? (tracking.title === "water" || tracking.title === "energy") ? "12 sites Filled up data" : "No site Filled up data"
                                                    : companyName === "Syscom" ? "1 site filled up data"
                                                        : (companyName !== "Digital Edge" && (trakingValue.title === "green" || trakingValue.title === "waste"))
                                                            ? "4 sites filled up data"
                                                            : ((focusArea === 'Scope 3 Emission' || focusArea === 'Scope 1 Emission') && companyName === 'Enterprise SG') ? "" : focusArea === 'Waste' ? "No site filled up data" : "3 sites filled up data"} */}
                                            </div>
                                        </div>
                                }
                            </div>

                        </div>
                    </div>
                : null
            }

            <div className={` ${(companyName !== "Digital Edge" && companyName !== "ClientA") ? "col-lg-9 col-md-8 col-sm-12 col-12" : "col-12"}  my-1`}>
                <div className="cardStyle me-1 row d-flex align-items-center">
                    <div className="d-flex justify-content-between align-items-center mb-2">
                        <div className="" style={{ fontSize: 23, color: invertDark(theme) }}>
                            {t("Individual Tracking")}
                        </div>
                        {
                            companyName === 'Digital Edge' || companyName === "ClientA" ?
                                <div className="me-2">
                                    <Selector
                                        getOptionLabel={(e) => (
                                            <div style={{ display: "flex", textAlign: "left" }}>

                                                <span style={{ marginLeft: 5 }}>{t(e.label)}</span>
                                            </div>
                                        )}
                                        options={yearOptions}
                                        menuListColor={Colors.Dark[0]}
                                        value={yearOptions?.find(v => v.value === selectedTrackingTblYear)}
                                        _handleSelect={e => _handleSelectTrackingTblYear(e.value)}
                                    />
                                </div>
                                : null
                        }
                    </div>
                    <Tracking_Table
                        companyName={companyName}
                        CommulativeConsumption={true}
                        column={columNames}
                        siteData={siteData}
                        focusArea={props.focusArea}
                        permittedBuilding={permittedBuilding}
                        countryOptions={countryOptions}
                    />

                </div>
            </div>
        </div>
        );
    })
);

const COLORS = [
    "#663567", 
    "#B04E6F", 
    "#E77866",
    "#607d8b",
    "#ffeb3b",
    "#1273DE",
    "#388e3c",
    "#7b64ff",
    "#86442d",
    "#7bdcb5",
  ];