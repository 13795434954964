import NavButton from "../Tools/NavButton";
import { Colors } from "../Assets/Config/color.config";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const UserMenuItem = (props) => {
    const navigate = useNavigate();
    const {
      userName,
      theme,
      showDropDown,
      _handleLogout,
      ...others
    } = props;
    const { t } = useTranslation("authentication");
  
    return <>
      <NavButton
        startIcon={<AccountCircleIcon/>}
        {...others}
        style={{ color: Colors[theme].primaryColor, padding: '6px 8px' }}
        theme={theme}
      >
        {userName}
      </NavButton>
      
      {showDropDown && (
        <div
          className="text-center p-2 hover cardStyle"
          style={{
            position: "absolute",
            fontSize: 16,
            top: 65,
            right: 20,
            background: Colors[theme].background,
            color: Colors[theme].primaryColor,
            zIndex: 1,
            borderRadius: 5,
            width: 150,
            cursor: "pointer",
            border: `1px solid ${Colors.lightBlue}`,
          }}
        >
          {/* <div onClick={()=> navigate('/building-and-user-registration')} className="p-1 pb-2" style={{ borderBottom: '1px solid gray' }}>Registration</div> */}
          <div className="p-1" onClick={() => _handleLogout(navigate)}>
            <i className="bi bi-box-arrow-in-left pe-2"></i>
            { t("Log Out") }
          </div>
        </div>
      )}
    </>
    
  }

  export default UserMenuItem;