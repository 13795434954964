import { ProgressBar } from "./ProgressBar";
import BarChart from "../Assets/Charts/BarChart";
import { MySlider } from "./MySlider";
import { Colors, invertDark } from "../Assets/Config/color.config";
import Store from "../Store";
import { baseURLImage } from "../API/URL";
import { Cookies } from "react-cookie";
import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import {ThousandSeparator} from "../Assets/Config/thousandSeparator.config";
import { useTranslation } from 'react-i18next';
import "../App.css";

export const MyTable = inject("store")(
  observer((props) => {
 
  const {column,data,isDetail,targetSettingData,focusArea} = props;
  const { _openNewTab } = Store.tracking;
  const { addDefaultSrc, permittedBuilding, theme } = Store.common;
  const {baselineYear} = props.store.trackingDetail;
  var currentTime = new Date()
  var year = currentTime.getFullYear()

  const { t } = useTranslation("target_setting");

  return (
    <div className="myTable-scrollbar" style={{
      width: "100%", fontSize: 14,
      marginTop: 12, marginBottom:3, borderTopLeftRadius: 5, 
      borderTopRightRadius: 5, maxHeight: 350, overflow:"auto"
    }}>
      <table className="table">
        <thead>
          <tr style={{top: -5, position: "sticky", width:"100%"}}>
            {column.map((v, k) => (
              <th key={k}
                className={`py-3 ${v === "Target (2022)" ||
                  (v === "Est. Date of Completion" && "text-center")
                  }`}
                style={{ 
                  // color: targetSettingData ? invertDark(theme) : (Colors.white),
                  color: Colors.white,
                  position: "sticky",
                  top: 0, 
                  // zIndex: 1,
                  backgroundColor: Colors[theme].tableBgColor,
                  // backgroundColor: targetSettingData ? "" : (theme === 'dark' ? Colors.primaryBule : Colors.blue), 
                  minWidth:30 , minWidth: k == 0 ? '50px': "auto"
                }}
              >
                {v}
              </th>
            ))}
          </tr>
        </thead>
        <tbody style={{
          color: invertDark(theme),
        }}>
          {isDetail
            ?
            (
              data?.length > 0 ?
                (data.map((v, k) => {
                  const value = focusArea === 'Energy' ? v?.energyConsumption : focusArea === 'Water' ? v?.waterConsumption :(focusArea === 'Scope 1 Emission' || focusArea === 'Scope 3 Emission') ? (v?.scope1Emission || v?.value): focusArea === 'Scope 2 Emission' ? v.scope2Emission : v.carbonEmission
            
                  // const value = focusArea === 'Energy' ? v?.energyConsumption : focusArea === 'Water' ? v?.waterConsumption :focusArea === 'Scope 1 Emission' ? v?.scope1Emission: focusArea === 'Scope 2 Emission' ? v.scope2Emission: ''
                  return(
                  /*v.year>=baselineYear&&*/<tr key={k} style={{ border: `0px solid ${theme === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(83, 83, 83, 0.2)'}` }}>
                    <td style={{ height: 50, paddingTop: 15 }}>{v.year}</td>
                    <td style={{ height: 50, paddingTop: 15 }}>
                      {value ? ThousandSeparator(Number.isInteger ? Number(value)?.toFixed(2) :(value)?.toFixed(2)?.toLocaleString('en-US')) : value}
                    </td>
                    <td style={{ height: 50, paddingTop: 15, color: v.delta <= 0 ? 'green' : 'red' }}>
                      { v.delta !== "Infinity" ? v.delta: '-'}
                      {v.delta !== "-" && v.delta !== "Infinity" && "%"}
                    </td>
                    <td style={{ height: 50, paddingTop: 15 }}>
                      {" "}
                      {v.success ? (
                        <i
                          className="bi bi-check2"
                          style={{ color: Colors.greenMaterial, fontSize: 20 }}
                        ></i>
                      ) : (
                        "-"
                      )}
                    </td>
                  </tr>
                )}))
                :
                ([2019, 2020, 2021, 2022].map((v, k) => (
                  <tr key={k} style={{ border: "0px solid rgba(255, 255, 255, 0.1)" }}>
                    <td style={{ height: 50, paddingTop: 15 }}>{v}</td>
                    <td className="text-center" style={{ height: 50, paddingTop: 15 }}>
                      0
                    </td>
                    <td className="text-center" style={{ height: 50, paddingTop: 15 }}>
                      0
                    </td>

                  </tr>
                )))
              // <tr className="pb-3 m-0" style={{ borderBottom: "1px solid rgba(255, 255, 255, 0.1)" }}>
              //   <td colSpan={3} style={{ textAlign: 'center' }}>No Data!</td>
              // </tr>
            )
            : targetSettingData
              ? targetSettingData.map((v, i) => {
                return (
                  <tr
                    key={i}
                    style={{
                      border:
                        targetSettingData.length !== i + 1
                          ? "0px solid rgba(255, 255, 255, 0.05)"
                          : "hidden",
                      borderTop: `1px solid ${theme === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(83, 83, 83, 0.1)'} `,
                    }}
                  >
                    <td
                      className="col-1"
                      style={{ paddingTop: 22, paddingRight: 0 }}
                    >
                      <img
                        //src={v.dataSource.toLowerCase().includes('carbon')?'dataSources/Energy.svg':'dataSources/water.svg'}
                        src={`dataSources/${v.image}`}
                        width={25}
                        height={25}
                      ></img>
                    </td>
                    <td className="col-3" style={{ paddingTop: 25 }}>
                      {t(v.dataSource)}
                    </td>
                    <td className="col-2" style={{ paddingTop: 25 }}>
                      {v.baselineYear}
                    </td>
                    <td className="col-2" style={{ paddingTop: 25 }}>
                      {v.baselineValue + ' ' + v.unit}
                    </td>
                    <td className="col-2" style={{ paddingTop: 25 }}>
                      {v.targetYear}
                    </td>
                    <td className="col-2" style={{ paddingTop: 25 }}>
                      {v.targetValue + ' ' + v.unit}
                      {/* <MySlider
                        baseLine={v.targetValue}
                        value={v.targetValue}
                        unit={v.unit}
                        theme={theme}
                      /> */}
                    </td>
                    <td className="col-2" style={{ paddingTop: 25, }}>
                      {v.targetPercent}
                    </td>
                  </tr>
                );
              })
              : data?.map((v, k) => (
                <tr
                  key={k}
                  style={{
                    cursor: "pointer",
                    border: "0px solid rgba(255, 255, 255, 0.1)",
                  }}
                  onClick={() => {
                    _openNewTab({ siteName: v?.siteName, focusArea: focusArea, siteImage: permittedBuilding[k].photo, permittedBuilding: permittedBuilding });
                  }}
                >
                  <td>
                    <img
                    crossorigin="anonymous"
                      onError={addDefaultSrc}
                      className="me-2"
                      src={baseURLImage + permittedBuilding[k].photo}
                      width={30}
                      height={30}
                    />
                    {v?.siteName}
                  </td>
                  {focusArea === "Waste" ? null : focusArea ===
                    "greenMaterial" ? (
                    <>
                      <td className="text-center">{v?.allItem}</td>
                      <td className="text-center">{v?.greenItem}</td>
                    </>
                  ) : (
                    <>
                      <td className="text-center">{v?.consumptionLastYr}</td>
                      <td className="text-center">{v?.consumptionCurrentYr}</td>
                    </>
                  )}

                  <td className="p-0">
                    <div className="mt-2">
                      <ProgressBar
                        noTarget
                        progress={Math.floor(Math.random() * 100) + 1}
                      />
                    </div>
                  </td>
                  {focusArea === "waste" ? (
                    <td className="text-center">{v?.estDate}</td>
                  ) : null}
                  {/* <td>
                    <BarChart />
                  </td> */}
                </tr>
              ))}
        </tbody>
      </table>
    </div>
  );
}));

export const MyTableKW = ({
  column,
  data,
  isDetail,
  targetSettingData,
  focusArea,
}) => {
  const { _openNewTab } = Store.tracking;
  const { permittedBuilding, theme } = Store.common;
  return (
    data && (
      <div style={{ width: "100%", fontSize: 14 }}>
        <table className="table">
          <thead style={{ color: Colors.Dark[5] }}>
            <tr>
              {column.map((v, k) => (
                <th
                  key={k}
                  className={`py-4 ${v === "Target (2022)" ||
                    (v === "Est. Date of Completion​" && "text-center")
                    }`}
                >
                  {v} AA
                </th>
              ))}
            </tr>
          </thead>
          <tbody style={{ color: invertDark("dark") }}>
            {isDetail
              ? data.map((v, k) => (
                <tr key={k} style={{ border: "0px solid rgba(255, 255, 255, 0.1)" }}>
                  <td style={{ height: 50, paddingTop: 15 }}>{v.year}</td>
                  <td style={{ height: 50, paddingTop: 15 }}>
                    {v.energyConsumption || v.waterConsumption}
                  </td>
                  <td style={{ height: 50, paddingTop: 15 }}>
                    {v.delta}
                    {v.delta !== "-" && "%"}
                  </td>
                  <td style={{ height: 50, paddingTop: 15 }}>
                    {" "}
                    {v.success ? (
                      <i
                        className="bi bi-check2"
                        style={{ color: Colors.greenMaterial, fontSize: 20 }}
                      ></i>
                    ) : (
                      "-"
                    )}
                  </td>
                </tr>
              ))
              : targetSettingData
                ? targetSettingData.map((v, i) => {
                  return (
                    <tr
                      key={i}
                      style={{
                        border:
                          targetSettingData.length !== i + 1
                            ? "0px solid rgba(255, 255, 255, 0.05)"
                            : "hidden",
                        borderTop: `1px solid ${theme === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(83, 83, 83, 0.1)'} `,
                      }}
                    >
                      <td
                        className="col-1"
                        style={{ paddingTop: 22, paddingRight: 0 }}
                      >
                        <img
                          src={`dataSources/${v.image}`}
                          width={25}
                          height={25}
                        ></img>
                      </td>
                      <td className="col-3" style={{ paddingTop: 25 }}>
                        {v.dataSource}
                      </td>
                      <td className="col-3" style={{ paddingTop: 25 }}>
                        {v.baseLine}
                      </td>
                      <td className="col-5">
                        <MySlider
                          baseLine={v.baseLine}
                          value={v.progressTarget}
                        />
                      </td>
                    </tr>
                  );
                })
                : data.map((v, k) => (
                  <tr
                    key={k}
                    style={{
                      cursor: "pointer",
                      border: "0px solid rgba(255, 255, 255, 0.1)",
                    }}
                    onClick={() => _openNewTab({ siteName: v.siteName, focusArea: focusArea, permittedBuilding: permittedBuilding })}
                  >
                    <td>
                      <img
                        className="me-2"
                        src={`sites/${v.image}`}
                        width={30}
                        height={30}
                      />
                      {v.siteName}
                    </td>
                    {focusArea === "waste" ? null : focusArea ===
                      "greenMaterial" ? (
                      <>
                        <td className="text-center">{v.allItem}</td>
                        <td className="text-center">{v.greenItem}</td>
                      </>
                    ) : (
                      <>
                        <td className="text-center">{v.consumptionLastYr}</td>
                        <td className="text-center">
                          {v.consumptionCurrentYr}
                        </td>
                      </>
                    )}

                    <td className="p-0">
                      <div className="mt-2">
                        <ProgressBar
                          noTarget
                          progress={(focusArea === "greenMaterial"
                            ? (100 * v.greenItem) / v.allItem
                            : Math.abs(
                              v.consumptionCurrentYr - v.consumptionLastYr
                            ) / v.consumptionCurrentYr || 0
                          )?.toFixed(2)}
                        />
                      </div>
                    </td>
                    {focusArea === "waste" ? (
                      <td className="text-center">{v.estDate}</td>
                    ) : null}
                    {/* <td>
                    <BarChart />
                  </td> */}
                  </tr>
                ))}
          </tbody>
        </table>
      </div>
    )
  );
};
