import React, { useState, Fragment, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { inject, observer } from "mobx-react";
import { MyButton, MyFileInput } from "../../Tools";
import { useTranslation } from "react-i18next";
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ReactMde from 'react-mde';
import "react-mde/lib/styles/css/react-mde-all.css";
import { toJS } from 'mobx';
import { getDefaultToolbarCommands } from 'react-mde';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

import FroalaEditorComponent from 'react-froala-wysiwyg';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/js/plugins.pkgd.min.js';

import 'froala-editor/js/froala_editor.pkgd.min.js';
import FroalaEditor from "froala-editor";

import { baseURL } from '../../API/URL';

import { CKEditor } from '@ckeditor/ckeditor5-react';
// import Editor from 'ckeditor5-custom-build/build/ckeditor';


import { DynamicComponents } from "./DynamicComponents";




export const ReportComponentView = inject("store")(
  observer((props) => {
    const { t } = useTranslation("assetSetupAndGroup");
    const { theme } = props.store.common;
    const { updateCustomCom, componentList, editingContent,
       _handleChangeEditingContent, getEditCom, selectedIndex, _handleSelectedIndex,
      _handleChangeImage, 
      _handleResizeImage, resizedImage, 
      _handleLockCells, _handleLockText, _handleLockImage,
      getDyEnergyConsumption,
      getDyBaselineEmission,
      getDyGHGVerification,
      getDyGHGEmissionAndTarget,
      getDyAssetEmissionFactor,
      getDyAnalysisGHGEmission,
      reportCustomConfigList,
      dyBaselineEmission,
      dynEnergyComparison,
      dyGHGVerification,
      dyAssetEmissionFactor,
      dyGHGEmissionAndTarget,
      dyAnalysisGHGEmission,
      dyStationaryCombustion,
      dyPurchaseElectricity,
      dyUpstream,
      dyConEmisReport,
      dyEmisYearlyReport,
      dyData

    } = props.store.reportConfigure;

 


    useEffect(() => {
      getEditCom()

      // getDyEnergyConsumption();
      // getDyBaselineEmission();
      // getDyGHGVerification();
      // getDyGHGEmissionAndTarget();
      // getDyAssetEmissionFactor();
      // getDyAnalysisGHGEmission();
    }, [getEditCom, selectedIndex]);


    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event, newValue) => {
      setTabValue(newValue);
    };

    const listGroup = ['Editor View', 'Preview']

    const classes = useStyles();

    const navigate = useNavigate();

    return (
      <Fragment>
        {
        editingContent &&
        document.getElementById("report_component_container") ? (
          <div className="row">
            <div className="col-12">
              {/*User Editor*/}

              {
              editingContent?.contentType !== "Generated" ? (
                <EditorView
                  editingContent={editingContent}
                  _handleChangeEditingContent={_handleChangeEditingContent}
                />
              ) : (
                <div
                  style={{
                    color: "black",
                    backgroundColor: "white",
                    padding: 99,
                  }}
                >
                  <DynamicComponents
                    dyData={toJS(dyData)}
                  />
                </div>
              )}

              {/* to lock text in editor for developer */}

              {/* <EditorDeveloperLock 
                            editingContent={editingContent} 
                            _handleChangeEditingContent={_handleChangeEditingContent}
                            _handleLockCells={_handleLockCells}
                            _handleLockText={_handleLockText}
                            _handleLockImage={_handleLockImage}
                        /> */}
            </div>
          </div>
        ) :  
        reportCustomConfigList?
        ( <div className="m-4 d-flex justify-content-center">
        <i className="spinner-border mt-2 " style={{color:'white'}}/>
        </div>):''
        }
      </Fragment>
    );
  })
);


const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  btyh: {

  }
}));


const EditorDeveloperLock = (props) => {
  const { editingContent, _handleChangeEditingContent, _handleLockText, _handleLockCells, _handleLockImage } = props;

  const editable = editingContent?.editable;
  const content = editingContent?.content;

  FroalaEditor.DefineIcon('buttonIcon', { NAME: 'star' })
  FroalaEditor.RegisterCommand('lockButton', {
    title: 'Lock',
    icon: '<i className="bi bi-lock-fill" style="font-size: 16px"></i>',
    undo: true,
    focus: true,
    showOnMobile: true,
    refreshAfterCallback: true,
    callback: function () {
      _handleLockText(this)
    },
  })

  FroalaEditor.DefineIcon('buttonIcon', { NAME: 'star' })
  FroalaEditor.RegisterCommand('lockCells', {
    title: 'Lock Cell(s)',
    icon: '<i className="bi bi-lock-fill" style="font-size: 16px"></i>',
    undo: true,
    focus: true,
    showOnMobile: true,
    refreshAfterCallback: true,
    callback: function () {
      _handleLockCells(this)
    },
  })


  FroalaEditor.DefineIcon('buttonIcon', { NAME: 'star' })
  FroalaEditor.RegisterCommand('lockImage', {
    title: 'Lock Image',
    icon: '<i className="bi bi-lock-fill" style="font-size: 16px"></i>',
    undo: true,
    focus: true,
    showOnMobile: true,
    refreshAfterCallback: true,
    callback: function () {
      _handleLockImage(this)
    },
  })

  const config = {
    toolbarButtons: {
      moreText: {
        buttons: ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontSize', 'lockButton', 'clearFormatting']
      },
      moreParagraph: {
        buttons: ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'lineHeight', 'outdent', 'indent']
      },
      moreRich: {
        buttons: ['insertImage', 'insertTable', 'specialCharacters', 'insertHR']
      },
      moreMisc: {
        buttons: ['undo', 'redo', 'selectAll',]
      }
    },
    // Set the image upload parameter.
    imageUploadParam: 'upload',
    imageUploadParams: {
      baseURL: baseURL
    },

    // Set the image upload URL.
    imageUploadURL: baseURL + 'upload',

    // Set request type.
    imageUploadMethod: 'POST',

    // Set max image size to 2MB.
    imageMaxSize: 2 * 1024 * 1024,

    // Allow to upload PNG and JPG.
    imageAllowedTypes: ['jpeg', 'jpg', 'png', 'gif'],
    imageInsertButtons: ['imageUpload'],
    htmlUntouched: true,
    indentMargin: 10,
    keepFormatOnDelete: true,
    attribution: false,
    charCounterCount: false,
    height: document.getElementById("report_component_container")?.offsetHeight ? document.getElementById("report_component_container").offsetHeight - 145 : 300,
    heightMin: document.getElementById("report_component_container")?.offsetHeight ? document.getElementById("report_component_container").offsetHeight - 145 : 300,
    heightMax: document.getElementById("report_component_container")?.offsetHeight ? document.getElementById("report_component_container").offsetHeight - 145 : 300,
    tableEditButtons: ['tableHeader', 'tableRemove', '|', 'tableRows', 'tableColumns', '-', 'tableCells', 'lockCells', 'tableCellVerticalAlign', 'tableCellHorizontalAlign',],
    imageEditButtons: ['lockImage'],
    events: {
      initialized: function () {
      },
      click: function () {
        var cell = this.table.selectedCells()[0];
        if (cell?.getAttribute('contentEditable') === 'false') {
          document.getElementsByClassName('fr-popup')[0].classList.remove('fr-active')
        }
      }
    }
  }

  return (
    <div className="" style={{}}>
      <FroalaEditorComponent
        tag='textarea'
        config={config}
        model={content}
        onModelChange={_handleChangeEditingContent}
      />
    </div>
  );
};

const EditorView = (props) => {
  const { editingContent, _handleChangeEditingContent } = props;

  const editable = editingContent?.editable;
  const content = editingContent?.content;
  
  const config = {
    toolbarButtons: {
      moreText: {
        buttons: ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontSize', 'clearFormatting']
      },
      moreParagraph: {
        buttons: ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'lineHeight', 'outdent', 'indent']
      },
      moreRich: {
        buttons: ['insertImage', 'insertTable', 'specialCharacters', 'insertHR']
      },
      moreMisc: {
        buttons: ['undo', 'redo', 'selectAll',]
      }
    },
    // Set the image upload parameter.
    imageUploadParam: 'upload',
    imageUploadParams: {
      baseURL: baseURL
    },

    // Set the image upload URL.
    imageUploadURL: baseURL + 'upload',

    // Set request type.
    imageUploadMethod: 'POST',

    // Set max image size to 2MB.
    imageMaxSize: 2 * 1024 * 1024,

    // Allow to upload PNG and JPG.
    imageAllowedTypes: ['jpeg', 'jpg', 'png', 'gif'],
    imageInsertButtons: ['imageUpload'],
    htmlUntouched: true,
    indentMargin: 10,
    keepFormatOnDelete: true,
    attribution: false,
    charCounterCount: false,
    height: document.getElementById("report_component_container")?.offsetHeight ? document.getElementById("report_component_container").offsetHeight - 145 : 300,
    heightMin: document.getElementById("report_component_container")?.offsetHeight ? document.getElementById("report_component_container").offsetHeight - 145 : 300,
    heightMax: document.getElementById("report_component_container")?.offsetHeight ? document.getElementById("report_component_container").offsetHeight - 145 : 300,
    events: {
      initialized: function () {
      },
      click: function () {
        var cell = this.table.selectedCells()[0];
        if (cell?.getAttribute('contentEditable') === 'false') {
          document.getElementsByClassName('fr-popup')[0].classList.remove('fr-active')
        }
      },
      contentChanged: function () {
        // console.log('already content change ..........');
      }
    },
    contextMenu: false
  }

  return (
    <div className="" style={{}}>
      <CKEditor
        style={{ minHeight: 300 }}
        // editor={Editor}
        data={content}
        onChange={(event, editor) => {
          const data = editor.getData();
          _handleChangeEditingContent(data)
        }}

        config={{

          toolbar: [
            'heading',
            '|',
            'bold',
            'italic',
            'underline',
            'alignment',
            '|',
            'bulletedList',
            'numberedList',
            'outdent',
            'indent',
            '|',
            'imageUpload',
            'insertTable',
            '|',
            'undo',
            'redo'],
          allowedContent: true,
          contentFilter: {
            allowedAttributes: {
              p: ["class"],
            },
          }

        }}
        onReady={editor => {
          editor.editing.view.change((writer) => {
            writer.setStyle(
              "height",
              (document.getElementById('report_component_container').offsetHeight - 127) + "px",
              editor.editing.view.document.getRoot()
            );
          });
        }}
      />


    </div>
  );
};







