import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { Fragment, useEffect } from "react";
import { Colors } from "../../Assets/Config/color.config";
import { LoadingPage } from "../../Page/Loading";
import { MyButton, MyLink } from "../../Tools";
import { Pagination } from "../../Tools/MyPagination";
import { Navigator } from "./Navigator";
import { useTranslation } from "react-i18next";
import { Subsection } from "./Subsection";
import { t } from "i18next";
import Navigator_ from "../../Tools/MyNavigator";
import { MyEmptyComponent } from "../../Tools/MyEmptyComponent";
import { toJS } from "mobx";
import { AlertDialog } from "./AlertDialog";

export const QuestionComponent = inject("store")(
  observer((props) => {
    const {
      sectionIndex,
      subSectionIndex,
      getAllQuestion,
      surveyYear,
      surveyInfo,
      subSectionArray,
      _handleAnswerSubmit,
      answerObj,
      surveyFramework,
      frameWorkIndex,
      buildingId,
      getManualEntryDataByCategory,
      ghgDataType,
      _handleSetGhgDataType,
      isNewVal,
      categoryArray
    } = props.store.survey;

    const { theme, siteModuleData } = props.store.common;
    const { t, i18n } = useTranslation("survey");

    const { sectionName, sectionHeader } =
      surveyInfo?.surveySection[sectionIndex];

    const frameworkId = surveyFramework?.[frameWorkIndex].framework_id;
    const surveyTitle = surveyFramework?.[frameWorkIndex].name;
    // const { t } = useTranslation("authentication");

    useEffect(() => {
      if(surveyYear !== ""){
        frameworkId && getAllQuestion(frameworkId, surveyTitle);
      }
    }, [sectionIndex, frameworkId, i18n.resolvedLanguage, surveyYear]);

    // console.log("subSectionArray > ", toJS(subSectionArray));
    // console.log('sectionName ------> ', toJS(subSectionArray), toJS(subSectionIndex))
    const currentSectionName = surveyInfo.surveySection[sectionIndex]?.sectionName;

    useEffect(()=> {
      if(sectionName === 'Scope 1' || sectionName === 'Scope 2' || sectionName === 'Scope 3'){
        _handleSetGhgDataType()
      }
    }, [sectionName])
    
    useEffect(()=> {
      if(ghgDataType === 'Category'){
        const subSectionHeader = subSectionArray?.[subSectionIndex]?.subSectionHeader;
        frameworkId && getManualEntryDataByCategory(frameworkId,subSectionHeader)
      }
    }, [sectionName, subSectionArray, subSectionIndex])

    return (
      <div>
        {/* <div className="d-flex flex-row flex-wrap justify-content-between pb-1">
          <span style={{ fontSize: 18, color: Colors[theme].textColor }}>
            <strong className="fw-bold">{sectionName + " : "}</strong>{" "}
            {sectionHeader}
          </span>
          <MyLink
            url={"/home"}
            text={ t("Back to menu") }
            color={Colors[theme].textColor}
            leftIcon={<i className="bi bi-caret-left-fill pe-1" />}
          />
        </div> */}
        <Navigator_
          pageName={
            <span style={{ fontSize: 18, color: Colors[theme].textColor }}>
              <strong className="fw-bold">{t(sectionName)  + " : "}</strong>{" "}
              {sectionHeader}
            </span>
          }
          goTo={t("Back")}
          handleBack = {() => window.history.back()}
          surveyNav={true}
        />

        {subSectionArray !== null ? (
          subSectionArray.length > 0 ? (
            <Fragment>
              <div className="d-flex flex-row flex-wrap justify-content-between">
                <Pagination />

                <div className="align-self-center">
                  <MyButton
                    text={t("Save Progress")}
                    customColor={Colors.greenMaterial}
                    leftIcon={<i className="bi bi-save pe-2" />}
                    onClick={() => _handleAnswerSubmit(frameworkId, buildingId)}
                    disabled={
                      !isNewVal
                      // answerObj.childSection
                      //   ? !answerObj.childSection
                      //   : answerObj.answer == null
                    }
                  />
                </div>
              </div>
              <Subsection />
              <Navigator />
            </Fragment>
          ) : (
            <LoadingPage />
            // <MyEmptyComponent text={"No Data Found."}/>
          )
        ) : (
          <MyEmptyComponent text={"No Data Found."} currentSectionName={currentSectionName} />
        )}
      </div>
    );
  })
);

{
  /* <div style={{ lineHeight: 4 }}>
                {answerCount}/{quesCount} Answered
              </div> */
}

{
  /* <QuestionCard /> */
}
