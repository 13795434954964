import { useEffect } from "react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { inject, observer } from "mobx-react";
import { MyRadioButton } from "../../Tools";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useTranslation } from "react-i18next";

const Industry = inject("store")(
    observer((props) => {
        const { theme } = props.store.common;
        const { checkedIndustry , _handleCheckIndustry, industries, getIndustriesData } = props.store.quickOrganizationSetUp;
        const { t } = useTranslation("quickOrganizationSetUp");   

        useEffect(()=> {
            getIndustriesData();
        }, [])

        return (
            <div>
                <div className={``}
                    style={{ borderRadius : 10 , width : '100%', background:Colors[theme]?.background , margin : '20px 0'}}
                >
                    <div 
                        className={`fw-bold `}
                        style={{ 
                            background : Colors[theme].headerBgColor,
                            padding : '15px 25px' , 
                            borderTopLeftRadius : 10 , 
                            borderTopRightRadius : 10 , 
                            fontSize: 20 , 
                            color: Colors.white
                        }}
                    >
                        {t("Which Industry are you in?")}
                    </div>
                    <div className={`row`}>
                        <div className={`col-6`}>
                            <div className={`p-5`} style={{ borderRight : '1px solid #ffffff20'}}>
                                {industries.map((v,i) => (
                                    <div key={i} style={{ marginBottom : '10px' ,color: invertDark(theme) , fontSize: 14  }} className={`fw-bold`} >
                                        <MyRadioButton
                                            onChange={(e) => _handleCheckIndustry(e.target.value)}
                                            value={v.title}
                                            text={t(v.title)}
                                            id={v.title}
                                            checked={checkedIndustry === v.title}
                                        />
                                    </div>
                                    
                                ))}
                            </div>
                        </div>
                        <div className={`col-6`}>
                            <div className={`py-5 px-3`}>
                                {checkedIndustry ? (
                                    <>
                                        <div className={`mb-3 d-flex`}>
                                            <ErrorOutlineIcon style={{ marginRight : '3px', color: invertDark(theme) , fontSize : 20}}/>
                                            <p style={{color: invertDark(theme) , fontSize: 14}} className={`mb-0 fw-bold`}>{t(checkedIndustry)}</p>
                                        </div>
                                        <p style={{color: invertDark(theme) , fontSize: 14}} className={`fw-bold`}>{ t(industries.find(v => v.title === checkedIndustry).description) }</p>
                                    </>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        )
    })
)

export default Industry;

