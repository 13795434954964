import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { baseURLImage } from "../../API/URL";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { MyUploadImage, MyInput } from "../../Tools";
import { MyCheckbox } from "../../Tools";

export const FrameworkModal = inject("store")(
  observer((props) => {
    const {
      getAccessmentFramework,
      accessmentList,
      _handleCreateFramework,
      theme,
      permittedBuilding,
    } = props.store.common;

    const { currentBuildingId } = props.store.target;
    const { frameWorkIndex, surveyFramework } = props.store.survey;
    const { framework, disabledSaveFramework, _handleCheckBox, getCheckedFramework, allFrameworks, checkFrameworks } = props.store.building;

    const activeStyle = {
      background: Colors[theme].secondBackground,
      cursor: "pointer",
      borderRadius: 5,
    };
    const noActiveStyle = {
      background: Colors[theme].secondBackground,
      cursor: "pointer",
      borderRadius: 5,
    };
    const queryParams = new URLSearchParams(window.location.search);
    const site = queryParams.get("site");
    const buildingId = site ? site : currentBuildingId;
    const buildingIndex = permittedBuilding.findIndex(v => v._id === buildingId);
    const surveyHeader = permittedBuilding[buildingIndex]?.surveyHeader[0]?.id;
 
    useEffect(() => {
      getAccessmentFramework();
    }, []);

    useEffect(() => {
      if (accessmentList && permittedBuilding) {
        
        getCheckedFramework(accessmentList, permittedBuilding?.find((v1) => v1._id === currentBuildingId)?.surveyHeader)
      }
    }, [accessmentList, permittedBuilding, currentBuildingId]);
    // const newFramework=accessmentList.filter(v=>v._id!===)   

    return (
      <div
        className={`modal fade text-dark ${theme}`}
        id="frameworkModal"
        tabIndex="-1"
        aria-labelledby="frameworkModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div
            className="modal-content"
            style={{
              background: Colors[theme].background,
              borderRadius: 10,
              color: invertDark(theme),
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add New Framework
              </h5>
              <i
                className="bi bi-x"
                data-bs-dismiss="modal"
                aria-label="Close"
                style={{ cursor: "pointer" }}
              />
              {/* <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                style={{cursor:'pointer'}}
              ></button> */}
            </div>
            <div className="modal-body">
              {allFrameworks?.map((v, i) => (
                <label
                  onClick={() => !v.active && toast.error(v.status)}
                  key={i}
                  htmlFor={v._id}
                  style={{ display: "block" }}
                >
                  <div
                    className="d-flex justify-content-between align-items-center p-2 mb-2"
                    style={v.active ? activeStyle : noActiveStyle}
                    title={!v.checked && v.status }
                  >
                    <span>
                      <img
                        className="me-2"
                        crossorigin="anonymous"
                        src={baseURLImage + v.imageURL}
                        height={25}
                        style={{ borderRadius: 2 }}
                      />
                      {v.name}
                    </span>
                    {v.checked === true ? <i className="bi bi-check2" style={{ color: Colors.greenMaterial}}/>  : <div>
                      <>
                        <MyCheckbox
                          id={v._id}
                          value={v.name}
                          setInputs={_handleCheckBox}
                          checked={v.checked}
                        />
                      </>
                    </div> }
                    
                  </div>
                </label>
              ))}
            </div>
            {(allFrameworks?.length === allFrameworks.filter(f => f.checked)?.length)
              && <span className="mx-4 mb-2" style={{fontSize: 12}}>
                  <i className="bi bi-info-circle pe-1"></i>
                  
                  All frameworks are added. If you want to delete framework,
                   <a className="px-1" href={`/rapid-assessment/?site=${buildingId}&survey=${surveyHeader}&name=${permittedBuilding[buildingIndex].buildingName}`}>Click here.</a> 
                  
                </span>
            }
            <div className="modal-footer d-flex justify-content-center w-100">
              <button
                type="button"
                disabled={disabledSaveFramework}
                className="btn btn-primary w-100"
                data-bs-dismiss="modal"
                style={{ cursor: "pointer" }}
                onClick={() => _handleCreateFramework(buildingId, allFrameworks, checkFrameworks)}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  })
);

// <div className="modal-body">
//               <div className="row">
//                 <div className="col-lg-4 col-sm-12 align-items-center">
//                   <MyUploadImage
//                     theme={theme}
//                     _handleUploadImage={_handleUploadImage}
//                     photo={photo}
//                     _resetImage={_resetImage}
//                     uploadLogoImage={uploadLogoImage}
//                     label={"Add framework logo"}
//                   />
//                 </div>
//                 <div className="col-lg-8 col-sm-12 align-items-center">
//                     {/* <span className="col-lg-4 col-sm-12 p-0 m-0">Title</span> */}
//                     <span className="col-12">
//                       <MyInput
//                         required={true}
//                         id={"frameworkTitle"}
//                         type={"text"}
//                         value={frameworkTitle}
//                         onChange={(e) => _handleFrameworkTitle(e)}
//                         placeHolder={"Framework Title"}
//                         label={"Title"}
//                       />
//                     </span>
//                   <span className="col-12">
//                       <MyInput
//                         required={true}
//                         id={"Description"}
//                         type={"text"}
//                         value={frameworkDescription}
//                         onChange={(e) => _handleFrameworkDescription(e)}
//                         placeHolder={"Framework Description"}
//                         label={"Description"}
//                       />
//                     </span>
//                 </div>

//                 <div className="row col-lg-3 align-items-center"/>
//               </div>
//             </div>
