import { useState } from "react"
import '../../App.css'
import { Colors } from "../../Assets/Config/color.config"
import Store from "../../Store"
import { useTranslation } from 'react-i18next';


export const Breadcrumb = () => {
    const [hover, setHover] = useState(null)
    const { theme } = Store.common;
    const { t } = useTranslation("target_setting");
    return (
        <nav aria-label="breadcrumb" style={{}} >
            <ol className="breadcrumb">
                {/* <li 
                    className={`breadcrumb-item`}
                >
                    <a 
                        href="/dashboard" 
                        className={``}   
                        style={{ textDecoration: 'none'}}                    
                    >
                        { t("Dashboard") }
                    </a>
                </li> */}
                <li 
                    className={`breadcrumb-item`}
                >
                    <a 
                        // href="/rapid-assessment" 
                        onClick={() => window.history.back()}
                        className={``}
                        style={{ textDecoration: 'none'}}
                    >
                        { t("Rapid Assessment") }
                    </a>
                </li>
                <li className="breadcrumb-item fw-bold" style={{ color: Colors[theme].textColor }}>{ t("Site Relevant Data") }</li>
            </ol>
        </nav>
    )
}