import React,{useState,useEffect} from "react";
import {
  ComposedChart,
  Label,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Scatter,
  ResponsiveContainer,
} from "recharts";
import { Colors, invertDark } from "../../Assets/Config/color.config";


import ReactDOMServer from "react-dom/server";

export const DyComparisonChart = (props) => {
  const theme = "light";
  const data = props.data.chartData.data;

  const dataKeys = data.length ? Object.keys(data[0]) : [];

  const lineColors = [
    "#BD5943",
    "#2982ff",
    "#005A34",
    "#0200FF",
    "#FF00F2",
    "#C7C7F1",
  ];

  const formatTick = (value) => {
    if (value >= 1000000000) {
      return `${(value / 1000000000).toFixed(2)}B`;
    } else if (value >= 1000000) {
      return `${(value / 1000000).toFixed(2)}M`;
    } else if (value >= 1000) {
      return `${(value / 1000).toFixed(2)}K`;
    } else {
      return value;
    }
  };

  const CustomizedYAxisTick = (props) => {
    const { x, y, payload } = props;
    return (
      <text x={x} y={y} dy={0} fontSize={12} textAnchor="end" fill="#666">
        {formatTick(payload.value)}
      </text>
    );
  };

  return (
    <div style={{ textAlign:'center',marginBottom: 20, border: "1px solid #E5DFDF", padding: 10 }}>
      <div
        className="d-flex"
        style={{
          fontSize: 22,
          justifyContent: "center",
          color: "gray",
          margin: 10,
        }}
      >
        {"Carbon Reduction Year to Year Comparison"}
      </div>
      <ResponsiveContainer
        width={"100%"}
        height={300}
        style={{
          background: theme === "dark" ? Colors.darkBlue : Colors.white,
          color: invertDark(theme),
        }}
      >
        <ComposedChart
          style={{
            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10,
          }}
          width={50}
          height={300}
          data={data}
        >
          <CartesianGrid stroke="rgb(255,255,255,0.3)" />

          {/* #2982ff */}
          <XAxis dataKey="year" scale="auto" />
          <YAxis tick={CustomizedYAxisTick}>
            <Label
              value="Emission kg CO2"
              position="insideLeft"
              angle={-90}
              style={{ textAnchor: "middle" }}
            />
          </YAxis>

          <Tooltip />
          <Legend />
          {dataKeys?.length ? (
            dataKeys.map(
              (v, i) =>
                v !== "year" && (
                  <Area
                    key={i}
                    type="monotone"
                    dataKey={v}
                    fill={lineColors[i]}
                    stroke={lineColors[i]}
                  />
                )
            )
          ) : (
            <></>
          )}
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};



// export const DyComparisonChart = (props) => {
//   const [chartHtmlState, setChartHtmlState] = useState('');

//   useEffect(() => {
//     const chartHtml = ReactDOMServer.renderToStaticMarkup(
//       <DynamicChart data={props.data} />
//     );
//     setChartHtmlState(chartHtml);
//   }, [props.data]);


//   return <div dangerouslySetInnerHTML={{ __html: chartHtmlState }} />;
// };
