import { Colors } from "../Assets/Config/color.config";
import { MyButton } from "../Tools";
import { MyInput } from "../Tools";
import Store from "../Store";
import { inject, observer } from "mobx-react";
import { Selector } from "../Tools";

const RegistrationForm = (props) => {
  const {
    _handleRegister,
    regEmail,
    _handleRegEmailChange,
    regName,
    _handleRegNameChange,
    regPassword,
    _handleRegPasswordChange,
    _handleView,
    visible,
    confirmPassword,
    _handleConfirmPasswordChange,
    selectedSite,
    setSelectedSite
  } = Store.login;

  return (
    <form
      className="col-lg-3 col-md-8 col-sm-10"
      style={{
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        borderRadius: "15px",
        boxShadow: Colors.shadow,
      }}
    >
      <div
        className="p-4 mb-0"
        style={{
          borderBottom: `1px solid ${Colors.Dark[3]}`,
          background: Colors.lightBlue,
          borderRadius: "15px 15px 0 0",
        }}
      >
        <h5 style={{ fontWeight: "bold" }}>PONTIAC</h5>
        <span>Carbon Abatement Create Account</span>
      </div>
      <div>
        <div className="mx-5 my-3">
          <div className="py-3">
            User Name
            <MyInput
              required={true}
              id={"userName"}
              type={"text"}
              value={regName}
              onChange={(e) => _handleRegNameChange(e)}
              placeHolder={"user name"}
            />
          </div>
          <div className="pb-3">
            Email
            <MyInput
              required={true}
              id={"email"}
              type={"email"}
              value={regEmail}
              onChange={(e) => _handleRegEmailChange(e)}
              placeHolder={"e-mail"}
            />
          </div>
          <div className="pb-3">
            Password
            <MyInput
              required={true}
              id={"password"}
              type={"password"}
              placeHolder={"password"}
              value={regPassword}
              _handleView={_handleView}
              onChange={(e) => _handleRegPasswordChange(e)}
              visible={visible}
            />
          </div>
          <div className="pb-3">
            Confirm Password
            <MyInput
              required={true}
              id={"password"}
              type={"password"}
              value={confirmPassword}
              onChange={(e) => _handleConfirmPasswordChange(e)}
              placeHolder={"confirm password"}
              _handleView={_handleView}
              visible={visible}
            />
          </div>
          Select Site
          <Selector
          menuListColor={Colors.Dark[0]}
            options={sites}
            value={sites.filter((option) => option.value === selectedSite)}
            _handleSelect={(e) => setSelectedSite(e.label)}
          />
          <hr
            className="mt-3"
            style={{
              color: "#dadde1",
              backgroundColor: Colors.Dark[3],
              height: 0.5,
              borderColor: "#dadde1",
            }}
          />
          <div className="pb-3">
            <MyButton
              text={"Create User"}
              type={"submit"}
              id={"SignUp"}
              onClick={(e) => _handleRegister(e)}
              style={{ width: "100%" }}
            />
          </div>
        </div>
      </div>
      <footer className="m-2 mb-3 text-center">
        <a href="/login">Back to login</a>
      </footer>
    </form>
  );
};
export default inject("store")(observer(RegistrationForm));

const sites = [
  { value: 1, label: "Conrad" },
  { value: 2, label: "Capella" },
  { value: 3, label: "Regent" },
  { value: 4, label: "RCMS" },
];
