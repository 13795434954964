import { Colors, invertDark } from "../../Assets/Config/color.config"
import { inject, observer } from "mobx-react";
import { toJS } from "mobx";
import { useTranslation } from 'react-i18next';
import GoogleMapReact from 'google-map-react';

const GOOGLD_API_KEY = "AIzaSyAcWK8WHabUh0BMDZuIIPo0qfWXWarBzoo";
const Marker = () => <i className="bi bi-geo-alt-fill" style={{ fontSize: 30, color: 'red' }}></i>;

const SelectLocationModal = inject("store")(
    observer((props) => {
        const { inputs, defaultProps, _handleAddLocation, _handleSetBuildingLocation } = props.store.building;
        const { theme } = props.store.common;
        const { t } = useTranslation("landing");
        // 

       const mapStyle= [
            {
              "elementType": "geometry",
              "stylers": [
                {
                  "color": "#1d2c4d"
                }
              ]
            },
            {
              "elementType": "labels.text.fill",
              "stylers": [
                {
                  "color": "#8ec3b9"
                }
              ]
            },
            {
              "elementType": "labels.text.stroke",
              "stylers": [
                {
                  "color": "#1a3646"
                }
              ]
            },
            {
              "featureType": "administrative.country",
              "elementType": "geometry.stroke",
              "stylers": [
                {
                  "color": "#4b6878"
                }
              ]
            },
            {
              "featureType": "administrative.land_parcel",
              "elementType": "labels.text.fill",
              "stylers": [
                {
                  "color": "#64779e"
                }
              ]
            },
            {
              "featureType": "administrative.province",
              "elementType": "geometry.stroke",
              "stylers": [
                {
                  "color": "#4b6878"
                }
              ]
            },
            {
              "featureType": "landscape.man_made",
              "elementType": "geometry.stroke",
              "stylers": [
                {
                  "color": "#334e87"
                }
              ]
            },
            {
              "featureType": "landscape.natural",
              "elementType": "geometry",
              "stylers": [
                {
                  "color": "#023e58"
                }
              ]
            },
            {
              "featureType": "poi",
              "elementType": "geometry",
              "stylers": [
                {
                  "color": "#283d6a"
                }
              ]
            },
            {
              "featureType": "poi",
              "elementType": "labels.text.fill",
              "stylers": [
                {
                  "color": "#6f9ba5"
                }
              ]
            },
            {
              "featureType": "poi",
              "elementType": "labels.text.stroke",
              "stylers": [
                {
                  "color": "#1d2c4d"
                }
              ]
            },
            {
              "featureType": "poi.park",
              "elementType": "geometry.fill",
              "stylers": [
                {
                  "color": "#023e58"
                }
              ]
            },
            {
              "featureType": "poi.park",
              "elementType": "labels.text.fill",
              "stylers": [
                {
                  "color": "#3C7680"
                }
              ]
            },
            {
              "featureType": "road",
              "elementType": "geometry",
              "stylers": [
                {
                  "color": "#304a7d"
                }
              ]
            },
            {
              "featureType": "road",
              "elementType": "labels.text.fill",
              "stylers": [
                {
                  "color": "#98a5be"
                }
              ]
            },
            {
              "featureType": "road",
              "elementType": "labels.text.stroke",
              "stylers": [
                {
                  "color": "#1d2c4d"
                }
              ]
            },
            {
              "featureType": "road.highway",
              "elementType": "geometry",
              "stylers": [
                {
                  "color": "#2c6675"
                }
              ]
            },
            {
              "featureType": "road.highway",
              "elementType": "geometry.stroke",
              "stylers": [
                {
                  "color": "#255763"
                }
              ]
            },
            {
              "featureType": "road.highway",
              "elementType": "labels.text.fill",
              "stylers": [
                {
                  "color": "#b0d5ce"
                }
              ]
            },
            {
              "featureType": "road.highway",
              "elementType": "labels.text.stroke",
              "stylers": [
                {
                  "color": "#023e58"
                }
              ]
            },
            {
              "featureType": "transit",
              "elementType": "labels.text.fill",
              "stylers": [
                {
                  "color": "#98a5be"
                }
              ]
            },
            {
              "featureType": "transit",
              "elementType": "labels.text.stroke",
              "stylers": [
                {
                  "color": "#1d2c4d"
                }
              ]
            },
            {
              "featureType": "transit.line",
              "elementType": "geometry.fill",
              "stylers": [
                {
                  "color": "#283d6a"
                }
              ]
            },
            {
              "featureType": "transit.station",
              "elementType": "geometry",
              "stylers": [
                {
                  "color": "#3a4762"
                }
              ]
            },
            {
              "featureType": "water",
              "elementType": "geometry",
              "stylers": [
                {
                  "color": "#0e1626"
                }
              ]
            },
            {
              "featureType": "water",
              "elementType": "labels.text.fill",
              "stylers": [
                {
                  "color": "#4e6d70"
                }
              ]
            }
          ]
    
    const _mapLoaded = (mapProps, map) => {
    map.setOptions({
      styles: mapStyle
    })
  }

        return (
            <div
                className={`modal fade ${theme}`}
                id="SelectLocationModal"
                tabIndex="-1"
                aria-labelledby="SelectLocationModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered modal-xl">
                    <div
                        className="modal-content"
                        style={{
                            background: Colors[theme].secondBackground,
                            borderRadius: 10,
                        }}
                    >
                        <div className="modal-header mx-3 px-0">
                            <h4 className="modal-title" style={{ color: theme === 'dark' ? '#fff' : '#000' }} id="SelectLocationModalLabel">
                                {t("Select Location")}
                            </h4>
                            <i
                                style={{ cursor: "pointer" }}
                                className="bi bi-x-lg "
                                data-bs-dismiss="modal"
                                aria-label={t("Close")}
                            // onClick={() => _handleClose()}
                            ></i>
                        </div>
                        <div className="modal-body" style={{ color: invertDark(theme) }}>
                            <div style={{ height: '70vh', width: '100%' }}>
                                <GoogleMapReact onClick={(e) => _handleSetBuildingLocation(e)}
                                    options={{
                                        styles: mapStyle,
                                        zoomControl: false,
                                    }}
                            
                                    bootstrapURLKeys={{ key: GOOGLD_API_KEY }}
                                    defaultCenter={defaultProps.center}
                                    yesIWantToUseGoogleMapApiInternals
                                    defaultZoom={0}
                                    zoom={-10}
                                    
                                >
                                    <Marker
                                        lat={inputs.lat}
                                        lng={inputs.lng}
                                    />
                                </GoogleMapReact>
                            </div>
                        </div>
                        <div className="modal-footer d-flex justify-content-center mx-3">
                            <button
                                type="button"
                                // onClick={() => _handleCreateNewSite()}
                                className="btn col-2 text-white"
                                style={{
                                    background: theme === 'dark' ? Colors.primaryBule : '#888'
                                }}
                                data-bs-dismiss="modal"
                            >
                                {t("Cancel")}
                            </button>
                            <button
                                type="button"
                                data-bs-dismiss="modal"
                                style={{ cursor: "pointer" }}
                                className="btn btn-primary col-2"
                            >
                                {t("Confirm")}
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        )
    }))
export default SelectLocationModal