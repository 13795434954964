import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import { useEffect } from "react";
import { useState } from "react";
import { Colors } from "../../Assets/Config/color.config";
import { FileUploadSelector, MyInput, MuiSelector } from "../../Tools";
import { UploadedFileDescriptionModal } from "./UploadedFileDescriptionModal";
import SurveyFileUploadingModal from "./SurveyFileUploadingModal";
import { useTranslation } from "react-i18next";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";
import Upload from '@mui/icons-material/Upload';

export const EntryDataByCateTbl = inject("store")(
  observer((props) => {
    const { theme, viewStatus } = props.store.common;
    const {
      manualDataByCategory,
      answerArray,
      allManualDataByCategory,
      _handleManualNumberChange,
      surveyYear,
      subSectionArray,
      subSectionIndex,
      sectionIndex,
      _handleSelectedFile,
      categoryArray,
      _handleCateDescriptionChange,
      _handleCategorySubmit,
      setSelectedMonth,
    } = props.store.survey;

    const subSectionHeader =
      subSectionArray?.[subSectionIndex]?.subSectionHeader;
    const { siteListData, frameworkId, buildingId } = props;
    const [tblData, setTblData] = useState([]);

    const { t } = useTranslation("common");

    useEffect(() => {
      const tblData =
        manualDataByCategory?.length > 0
          ? manualDataByCategory
          : [
              {
                _id: surveyYear + sectionIndex + subSectionHeader,
                year: surveyYear,
                category: subSectionHeader,
                scope: sectionIndex + 1,
                manualEntryData: [
                  { month: "January", value: null },
                  { month: "February", value: null },
                  { month: "March", value: null },
                  { month: "April", value: null },
                  { month: "May", value: null },
                  { month: "June", value: null },
                  { month: "July", value: null },
                  { month: "August", value: null },
                  { month: "September", value: null },
                  { month: "October", value: null },
                  { month: "November", value: null },
                  { month: "December", value: null },
                ],
              },
            ];
      setTblData(tblData);
    }, [manualDataByCategory]);

    console.log("tblData >> ", toJS(tblData));
    
    return (
      <>
        {tblData.length > 0 ? (
          <div
            className="tableStyle table-responsive my-2 monthlyCustomTbl"
            style={{
              borderRadius: 10,
              marginBottom: 10,
              backgroundColor: theme==="dark"?"#141c43":"#fff"
              // border: " 1px solid",
            }}
          >
            <table
              className="table mb-4"
              style={{
                color: Colors[theme].textColor,
                borderColor: "transparent",
              }}
            >
              <tr>
                <th rowSpan="2" style={{ padding: 8 }}>
                  Year
                </th>
                <th
                  colSpan="12"
                  className="text-center"
                  style={{
                    padding: 8,
                    borderLeft: " 1px solid",
                    borderLeftColor:
                      theme === "dark"
                        ? "rgb(255, 255, 255,0.1)"
                        : Colors.Dark[4],
                    borderBottom: " 1px solid",
                    borderBottomColor:
                      theme === "dark"
                        ? "rgb(255, 255, 255,0.1)"
                        : Colors.Dark[4],
                  }}
                >
                  Category Entry - kgCO2
                </th>
              </tr>
              <tr>
                {months.map((month, index) => {
                  return (
                    <th
                      className="text-center"
                      key={index}
                      style={{
                        width: "200px",
                        padding: 8,
                        borderLeft: " 1px solid",
                        borderLeftColor:
                          theme === "dark"
                            ? "rgb(255, 255, 255,0.1)"
                            : Colors.Dark[4],
                      }}
                    >
                      {month}
                    </th>
                  );
                })}
              </tr>

              {tblData.map((v, k) => {
                return (
                  <tr
                    key={k}
                    style={{
                      borderTop: " 1px solid",
                      borderTopColor:
                        theme === "dark"
                          ? "rgb(255, 255, 255,0.1)"
                          : Colors.Dark[4],
                    }}
                  >
                    <td className="p-3">{v.year}</td>
                    {months.map((m, mi) => {
                      const val =
                        answerArray.length > 0 &&
                        answerArray.findIndex((a) => a._id === v._id) > -1
                          ? answerArray
                              .find(
                                (a) =>
                                  a.category === v.category && a.year === v.year
                              )
                              ?.manualEntryData.find((dt) => dt.month === m)
                              ?.value
                          : v.manualEntryData.find((dt) => dt.month === m)
                              ?.value;

                      const linkedFileId =
                        categoryArray.length > 0
                          ? ( categoryArray.filter((c) => c.catMonth === m)?.length > 0 ?
                            (categoryArray.find((c) => c.catMonth === m)
                              ?.linkedFileId)
                              : (v.manualEntryData.find((i) => i.month === m)
                                ?.linkedFileId)
                            )
                          : v.manualEntryData.find((i) => i.month === m)
                              ?.linkedFileId;


                      return (
                        <td
                          key={k + mi}
                          className="text-center px-2"
                          style={{
                            minWidth: viewStatus === "detailsView" ? 200 : 100,
                            // minWidth: 100,
                            borderLeft: " 1px solid",
                            borderLeftColor:
                              theme === "dark"
                                ? "rgb(255, 255, 255,0.1)"
                                : Colors.Dark[4],
                          }}
                        >
                          {/* <MyInput
                            id={v.id + "_" + mi}
                            type={"number"}
                            onChange={(e) => _handleManualNumberChange(e, m, v)}
                            pattern="[0-9]*"
                            value={val}
                          /> */}

                          {
                            viewStatus === "detailsView" ? (
                              <>
                                {/* <div className="d-flex row py-0 px-2"> */}
                                <div className="row px-3">
                                  <p
                                    className="px-0"
                                    style={{
                                      fontSize: "12px",
                                      textAlign: "left",
                                      margin: 0,
                                    }}
                                  >
                                    {t("Usage")}
                                  </p>
                                
                                  <MyInput
                                    id={v.id + "_" + mi}
                                    type={"number"}
                                    onChange={(e) =>
                                      _handleManualNumberChange(e, m, v)
                                    }
                                    pattern="[0-9]*"
                                    value={val}
                                  />
                                </div>
                                <div className="px-2 row" id="uploaded_file_muiSelector">
                                  <p style={{ fontSize: "12px", textAlign: "left", margin: 0}}>
                                    {t("Data Validation File")}
                                  </p>
                                  <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={0}>                           
                                    <MuiSelector               
                                      style={{ width : '200px' }} 
                                      options={siteListData}
                                      value={siteListData.find(
                                        (s) => s.value === linkedFileId
                                      )?.label}
                                      _handleSelect={(e) =>
                                        _handleSelectedFile(
                                          e.target.value,
                                          m + "_" + v.year,
                                          "category",
                                          v._id,
                                          3
                                        )
                                      }                      
                                    />
                                    <span data-bs-toggle="modal" data-bs-target="#Scope3SurveyFileModal" style={{ cursor: "pointer" }}>
                                    <button title={t("Browse your files")} 
                                      onClick={(e) => {
                                        setSelectedMonth(m)
                                      }}      
                                      style={{ marginBottom: "-5px", border: theme === "dark"? "1px solid #5e5e5e" : "1px solid #c6c6c6", minWidth: "36px !important", maxWidth: "36px !important", padding: "9px", borderRadius: "5px", background: theme === "dark"?"transparent" : "#fff"}}>
                                        <Upload style={{ color: "rgb(4, 197, 0)" }}/>
                                    </button>
                                  </span>
                                  </Stack>
                                </div>
                                <SurveyFileUploadingModal modalId="Scope3SurveyFileModal" btnId="scope3_save_file_and_description" frameworkId={frameworkId} buildingId={buildingId} />
                                {/* <div
                                  className="row p-1"
                                  id={"cate_uploaded_file_selector"}
                                >
                                  <FileUploadSelector
                                    options={siteListData}
                                    menuListColor={Colors.Dark[0]}
                                    value={siteListData.find(
                                      (s) => s.value === linkedFileId
                                    )}
                                    _handleSelect={(e) =>
                                      _handleSelectedFile(
                                        e.value,
                                        m + "_" + v.year,
                                        "category",
                                        v._id,
                                        3
                                      )
                                    }
                                  />
                                </div> */}
                                {/* </div> */}
                                <div className="px-2">
                                  {/* <div className="col-12 p-1"> */}
                                    <p style={{ fontSize: "12px", textAlign: "left", margin: 0, padding: 0 }}>
                                      {t("Description of the uploaded file")}
                                    </p>
                                    <MyInput
                                      // disabled
                                      id={m + "description"}
                                      required={true}
                                      isTextArea={true}
                                      placeHolder={t(
                                        "Description of the uploaded file"
                                      )}
                                      onChange={(e) =>
                                        _handleCateDescriptionChange(
                                          e,
                                          v._id,
                                          m + "_" + v.year,
                                          3
                                        )
                                      }
                                      value={
                                        categoryArray?.filter(
                                          (c) =>
                                            c._id === v._id &&
                                            c.monthYear === m + "_" + v.year
                                        )?.length > 0
                                          ? categoryArray?.find(
                                              (c) =>
                                                c._id === v._id &&
                                                c.monthYear === m + "_" + v.year
                                            )?.description
                                          : v.manualEntryData.find(
                                              (dt) => dt.month === m
                                            )?.description
                                      }
                                    />
                                    <Stack
                                      direction="row"
                                      justifyContent="flex-end"
                                      alignItems="center"
                                      spacing={1}
                                      style={{
                                        padding: "5px",
                                        fontSize: "12px",
                                        height: "20px",
                                      }}
                                    >
                                      <Link
                                        href="#"
                                        underline="none"
                                        style={{
                                          color: "#fff",
                                          background: "#2982ff",
                                          padding: "0px 4px",
                                          borderRadius: "2px",
                                          display:
                                            categoryArray.filter(
                                              (c) =>
                                                c.monthYear === m + "_" + v.year
                                            ).length > 0
                                              ? "block"
                                              : "none",
                                        }}
                                        onClick={() =>
                                          _handleCategorySubmit(
                                            m + "_" + v.year,
                                            buildingId,
                                            frameworkId,
                                            3
                                          )
                                        }
                                      >
                                        {t("Save")}
                                      </Link>
                                    </Stack>
                                  {/* </div> */}
                                </div>
                              </>
                            ) : (
                              <MyInput
                                id={v.id + "_" + mi}
                                type={"number"}
                                onChange={(e) =>
                                  _handleManualNumberChange(e, m, v)
                                }
                                pattern="[0-9]*"
                                value={val}
                              />
                            )
                          }
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </table>
          </div>
        ) : (
          <EmptyComponent />
        )}
        <UploadedFileDescriptionModal
          modalId="CateUploadedFileDescriptionModal"
          btnId="save_file"
        />
        <SurveyFileUploadingModal
          modalId={"cateSurveyFileModal"}
          frameworkId={frameworkId}
          buildingId={buildingId}
          btnId="save_file_and_description"
        />
      </>
    );
  })
);

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const EmptyComponent = () => {
  return (
    <div className="text-center my-2">
      <div className="rounded p-4" style={{ fontSize: 18 }}>
        <i className="bi bi-file-earmark-fill pe-1" />
        No equipment added yet.{" "}
      </div>
    </div>
  );
};
