import { inject, observer } from "mobx-react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { MyButton, Selector, MyCreatableSelector } from "../../Tools";
import { MyFileInput } from "../../Tools";
import { toJS } from "mobx";
import { useTranslation } from 'react-i18next';


const SiteRelevantModal = inject("store")(
    observer((props) => {
        const { frameworkId, buildingId } = props;
        const { theme } = props.store.common;
        const { categoryList, selectedCategory, file, _handleSelectCategory, _handleUploadFile, _handleChangeFile, _handleCloseModal, _handleChangeText } = props.store.siteRelevant;
        const creatableSelectOption = toJS(selectedCategory) && { value: categoryList.length+1, label: selectedCategory};
        const { t } = useTranslation("target_setting");
        
        return (
            <div
                className={`modal fade`}
                id="siteRelevantUpload"
                tabIndex="-1"
                aria-labelledby="siteRelevantUploadLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog">
                    <div
                        className="modal-content mx-3"
                        style={{
                            background: Colors[theme].background,
                            borderRadius: 10,
                            color:invertDark(theme)
                        }}
                    >
                        <div className="modal-header mx-3 px-0">
                            <h4>
                                { t("Upload New File") }
                            </h4>
                            <i
                                style={{ cursor: "pointer" }}
                                className="bi bi-x-lg "
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={_handleCloseModal}
                            ></i>
                        </div>
                        <div className="modal-body mx-3">
                            <div className="row py-1">
                                <div className="col-3">
                                    { t("File") }
                                </div>
                                <div className="col-9">
                                    <MyFileInput
                                        text='(.xlsx, .pdf, .txt, image/*)'
                                        theme={theme}
                                        fontSize={"15px"}
                                        file={file}
                                        onChange={(e) => _handleChangeFile(e)}
                                    />
                                </div>
                            </div>
                            <div className="row py-1">
                                <div className="col-3">
                                    { t("Category") }
                                </div>
                                <div className="col-9">
                                    {/* <Selector
                                        background={Colors.primaryBule}
                                        menuListColor={Colors.Dark[0]}
                                        options={categoryList}
                                        value={categoryList.filter((option) => option.value === selectedCategory)}
                                        placeholder={'Select category'}
                                        _handleSelect={(e) => _handleSelectCategory(e)}
                                    /> */}

                                    <MyCreatableSelector
                                        theme={theme}
                                        id={"siteRelevantUpload"}
                                        value={categoryList.find((option) => option.value === selectedCategory) || creatableSelectOption}
                                        options={categoryList}
                                        menuListColor={Colors.Dark[0]}
                                        _handleSelect={(e) => _handleSelectCategory(e)}
                                        placeholder={ t("Select Category") }
                                        textChange={(e) => _handleChangeText(e)}
                                        />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer d-flex justify-content-center mx-3">
                            <MyButton
                                style={{ width: '100%' }}
                                text={ t("Upload Now") }
                                customColor={Colors.water}
                                onClick={() => _handleUploadFile(buildingId, frameworkId)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    })
);
export default SiteRelevantModal;