import "./App.css";
import {
  DashboardContainer,
  LandingContainer,
  LoginContainer,
  SurveyContainer,
  TargetSettingContainer,
  TrackingContainer,
  AuditContainer
} from "./Containers";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import NavBar from "./App/NavBar";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TrackingDetail from "./Components/Tracking/TrackingDetail";
import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import { Cookies } from "react-cookie";
import UserManagementContainer from "./Containers/UserManagement/UserManagementContainer";

import { AuthInterceptor } from "./util/AuthInterceptor";
import SpecialOneTimeContainer from "./Containers/SpecialOneTimePage/SpecialOneTimeContainer";
import { OTP_Verification_Page } from "./Page/OTP";
import SiteManagementContainer from "./Containers/SiteManagement/SiteManagementContainer";
import SiteRelevantContainer from "./Containers/SiteRelevant/SiteRelevantContainer";
import ESGTracking, { ESGTrackingContainer } from "./Containers/ESG/ESGTrackingContainer";
import { KPIManagement } from "./Containers/KPIManagement/KPIManagement";
import { ESGPillarSetup } from "./Components/ESG/ESGPillarSetup";
import { YearToYearComparison } from "./Containers/YearToYearComparison/YearToYearComparison";
import { SummaryContainer } from './Containers/Summary/SummaryContainer';
import SiteLocationContainer from './Containers/SiteLocation/SiteLocationContainer'
import { AuditCompanyOverviewContainer } from "./Containers/AuditCompanyOverview/AuditCompanyOverviewContainer";
import { AuditCompanyVerification } from "./Containers/AuditCompanyOverview/AuditCompanyVerification";
import { QuickOrganizationSetUpContainer } from "./Containers/QuickOrganizationSetUp/QuickOrganizationSetUpContainer";
import CompGroupView from "./Components/GroupView/GroupView";
import { GroupOverView } from "./Containers/GroupOverView/GroupOverView";
import { LandingGroupContainer } from "./Containers/LandingGroup/LandingGroupContainer";
import { ReportConfigureContainer } from "./Containers/ReportConfigureOverview/ReportConfigureContainer";
import { AnalysisResultContainer } from "./Containers/AnalysisReport/AnalysisReportContainer";
import { AuditGroupListContainer } from "./Containers/AuditGroup/AuditGroupList";
import AuditGroupVerification from "./Containers/AuditGroup/AuditGroupVerification";

const PrivateRoute = ({ auth: { isAuthenticated }, tokenStatus, children }) => {

  // const location = useLocation();

  return isAuthenticated ? 
    (tokenStatus === "INACTIVE" ? 
    (
      <OTP_Verification_Page />
    )
    :(
      children
    )) 
  : (
    <LoginContainer />
  );
};
const cookies = new Cookies();
// const buildingCount = cookies.get("buildingCount");
//const {token}=Store.common;

// const tokenStatus = cookies.get("tokenStatus") || "ACTIVE";

const App = inject("store")(
  observer((props) => {

    const {theme, role} = props.store.common;
    const localTheme = localStorage.getItem("theme");
    const { token } = props.store.login;
    const cookies = new Cookies();
    const tokenCookie = cookies.get("Token");

    const auth = token;
    AuthInterceptor();
    const location = useLocation();
    const components = [
      {
        path: "/dashboard",
        component: <DashboardContainer />,
      },
      {
        path: "/home",
        component: <LandingContainer />,
      },
      {
        path: "/rapid-assessment",
        component: <SurveyContainer />,
      },
      { path: "/tracking", component: <TrackingContainer /> },
      {
        path: "/tracking-detail",
        component: <TrackingDetail />,
      },
      {
        path: "/target-setting",
        component: <TargetSettingContainer />,
      },
      {
        path: "/site-relevant",
        component: <SiteRelevantContainer />,
      },
      {
        path: "/user-management&creation",
        component: <UserManagementContainer />,
      },
      {
        path: "/site-location",
        component: <SiteLocationContainer />,
      },
      {
        path: "/building-and-user-registration",
        component: <SpecialOneTimeContainer />,
      },
      {
        path: "/site-management",
        component: <SiteManagementContainer />
      },
      {
        path: "/esg-tracking",
        component: <ESGTrackingContainer />
      },
      {
        path: "/esg-pillar-setup",
        component: <ESGPillarSetup />
      },
      {
        path: "/esg-pillar-setup/kpi-management",
        component: <KPIManagement />
      },
      {
        path: "/year-to-year-comparison",
        component: <YearToYearComparison />
      },
      {
        path: "/auditor-overview",
        component: <AuditContainer />
      },
      // {
      //   path: "/otp-verification",
      //   component: <OTP_Verification_Page />,
      // },
      {
        path: "/summary",
        component: <SummaryContainer />
      },
      {
        path: '/audit-company-overview',
        component: <AuditCompanyOverviewContainer />
      },
      {
        path: '/audit-company-verification',
        component: <AuditCompanyVerification />
      },
      {
        path: '/audit-group-list',
        component: <AuditGroupListContainer />
      },
      {
        path: '/audit-group-verification',
        component: <AuditGroupVerification />
      },
      {
        path: '/quick-organization-setup',
        component: <QuickOrganizationSetUpContainer />
      },
      {
        path: '/groupviewContainer',
        component: <CompGroupView/>
      },
      {
        path: '/group-view',
        component: <GroupOverView />
      },
      {
        path: "/home-group",
        component: <LandingGroupContainer />,
      },
      {
        path: "/analysis-report",
        component: <AnalysisResultContainer />,
      },
      {
        path: "/report-configure",
        component: <ReportConfigureContainer />,
      }
    ];
    
    const pathName = window.location.pathname;

    useEffect(() => {
      var x = document.getElementsByTagName("BODY")[0];
      
      // if (!theme) {
      //   console.log("NOT LOCALTHEEME")
      //   localStorage.setItem("theme", "dark");
      // } else if ((pathName === "/" || pathName === "/login") && theme === "light") {
      // if(localStorage.getItem("i18nextLng") == "zh"){
      //     localStorage.setItem("i18nextLng", "en");
      // }
          
      localStorage.setItem("theme", theme);
      // }
  
      !theme || theme === "dark"
        ? (x.style.backgroundImage = "linear-gradient(-60deg,#101841,#202b60)")
        : (x.style.backgroundImage = "linear-gradient(-60deg,#F6F6F6,#F6F6F6)");
    }, [theme]); 

    useEffect(()=> {
      if(
        (role === 'user') && 
        (pathName === '/building-and-user-registration')
      ){
        window.history.back()
      }
    }, [pathName]);

    // useEffect(()=> {
    //   if(pathName !== '/login' && !token){
    //     window.history.back()
    //   }
    // }, [pathName])
  

    const authenticated = pathName !== "/login" && pathName !== "/" && pathName !== "/otp-verification" && auth && true;

    useEffect(() => { // **important** block inspect elements for users
      console.log('pathName',pathName)
     
      
      // if(pathName === '/report-configure'){
      //   document.addEventListener('contextmenu', function (e) {
      //     return false
      //   });
      //   document.onkeydown = function (e) {
      //     if (e.keyCode == 123) {
      //       return false;
      //     }
      //     if (e.ctrlKey && e.shiftKey && e.keyCode == 'I'.charCodeAt(0)) {
      //       return false;
      //     }
      //     if (e.ctrlKey && e.shiftKey && e.keyCode == 'J'.charCodeAt(0)) {
      //       return false;
      //     }
      //     if (e.ctrlKey && e.keyCode == 'U'.charCodeAt(0)) {
      //       return false;
      //     }
      //     if (e.ctrlKey && e.shiftKey && e.keyCode == 'C'.charCodeAt(0)) {
      //       return false;
      //     }
      //   }
      // }
      
    }, [pathName])

    return (
      <div className="body-scroll" style={{}}>
        {/* <LoadingPage/> */}
        <NavBar authenticated={authenticated}/>
        <Routes>
          <Route exact path="/login" element={<LoginContainer />} />
          {components.map((v, k) => (
            <Route
              path={"ACTIVE" === "ACTIVE" ? v.path : "/otp-verification"}
              key={k}
              element={
                <PrivateRoute auth={{ isAuthenticated: auth && tokenCookie &&  true }} tokenStatus={'ACTIVE'}>
                  {v.component}
                </PrivateRoute>
              }
            />
          ))}
          {/* <Route
            path="*"
            element={auth ? <PageNotFound /> : <Navigate to="/login" replace />}
          />    */}
          <Route
            path="*"
            element={
              auth ? 
                // tokenStatus == "INACTIVE" && <Navigate to="/otp-verification" replace />
                <Navigate to="/login" replace />
              : <Navigate to="/login" replace />
            }
          />  
          {/* {
            role !== 'user' &&
            <Route path="/building-and-user-registration" component={<SpecialOneTimeContainer />} />
          }       */}
          <Route path="/" element={<Navigate to="/login" replace />} />
        </Routes>      
        <ToastContainer autoClose={1500} limit={1} />
      </div>
    );
  })
);

export default App;
