import React, { useState, Fragment, useEffect, useRef } from "react";
import moment from "moment";
import { inject, observer } from "mobx-react";

export const DynamicTable = (props) => {
  
  // const [title, setTitle] = useState(null);
  // const [headers, setHeaders] = useState(null);
  // const [rows, setRows] = useState(null);
  // const [footers, setFooters] = useState(null);

  const { data } = props;
 
  const { title, headers, rows, footers } = { ...data };

  // useEffect(() => {
    

  //   setTitle(title);
  //   setHeaders(headers);
  //   setRows(rows);
  //   setFooters(footers);
  // }, [data]);



  // const jsonData1 = {
  //   "headers": [
  //     {
  //       cells:[
  //         {
  //           "title": "Header 1",
  //           "colspan": 2,
  //           "rowspan": 2
  //         },
  //         {
  //           "title": "Header 2",
  //           "colspan": 2
  //         },
  //         {
  //           "title": "Header 3",
  //           "colspan": 3
  //         }
  //       ]
  //     }
  //   ],
  //   "rows": [
  //     {
  //       "cells": [
  //         {
  //           "value": "Data 1",
  //           "rowspan": 3
  //         },
  //         {
  //           "value": "Data 2"
  //         },
  //         {
  //           "value": "Data 3"
  //         },
  //         {
  //           "value": "Data 4"
  //         },
  //         {
  //           "value": "Data 5",
  //           "colspan": 2
  //         }
  //       ]
  //     },
  //     {
  //       "cells": [
  //         {
  //           "value": "Data 6"
  //         },
  //         {
  //           "value": "Data 7"
  //         },
  //         {
  //           "value": "Data 8"
  //         }
  //       ]
  //     },
  //     {
  //       "cells": [
  //         {
  //           "value": "Data 9"
  //         },
  //         {
  //           "value": "Data 10",
  //           "colspan": 2
  //         }
  //       ]
  //     }
  //   ]
  // };

  const headerRows = headers ? (
    headers.map((header, i) => {
      const cells = header.cells.map((cell, j) => {
        return (
          <th
            style={{
              verticalAlign: "middle",
              textAlign: cell.colspan > 1 ? "center" : "left",
              padding: '6px',
              border: '1px solid #c8ccd0',
            }}
            key={cell?.title}
            colSpan={cell?.colspan}
            rowSpan={cell?.rowspan}
          >
            {cell?.title}
          </th>
        );
      });
      return (
        <tr key={i} style={{ border: '1px solid #c8ccd0',pageBreakBefore: 'auto',pageBreakInside: 'avoid'}}>
          {cells}
        </tr>
      );
    })
  ) : (
    <tr></tr>
  );

  const bodyRows = rows ? (
    rows.map((row, i) => {
      const cells = row.cells.map((cell, j) => {
        return (
          <td
            style={{
             
              padding: '6px',
              border: '1px solid #c8ccd0',
              verticalAlign: "middle",
              color: String(cell?.value).includes("%") && (/^\d+$/.test(String(cell?.value)))? "#2bad5d" : "#000",
              wordBreak:'break-word'
            }}
            key={i + "-" + j}
            colSpan={cell?.colspan}
            rowSpan={cell?.rowspan}
          >
            {
           cell?.value
              }
          </td>
        );
      });
      return (
        <tr key={i} style={{ border: '1px solid #c8ccd0',pageBreakBefore: 'auto',pageBreakInside: 'avoid' }}>
          {cells}
        </tr>
      );
    })
  ) : (
    <tr></tr>
  );

  const footerRows = footers ? (
    footers.map((row, i) => {
      const cells = row.cells.map((cell, j) => {
        return (
          <td
            style={{
              padding: '6px',
              border: '1px solid #c8ccd0',
              verticalAlign: "middle",
              color: String(cell?.value).includes("%") ? "#2bad5d" : "#000",
              wordBreak:'break-word'
            }}
            key={i + "-" + j}
            colSpan={cell?.colspan}
            rowSpan={cell?.rowspan}
          >
            {cell?.title}
          </td>
        );
      });
      return (
        <tr key={i} style={{ border: '1px solid #c8ccd0',pageBreakBefore: 'auto',pageBreakInside: 'avoid'}}>
          {cells}
        </tr>
      );
    })
  ) : (
    <tr></tr>
  );

  return (
    <div style={{width: "100%",height: "100%"}}>
      <p style={{ fontSize: 16, color: "#000000",fontWeight:'bold' }}>{title}</p>
      <table style={{width: "100%",display:'table',pageBreakInside: 'auto'}}>
        <thead style={{ border: '0.5px solid #c8ccd0'}}>{headerRows}</thead>
        <tbody style={{ border: '0.5px solid #c8ccd0'}}>{bodyRows}</tbody>
        <tfoot style={{ border: '0.5px solid #c8ccd0'}}>{footerRows}</tfoot>
      </table>
    </div>
  );
};
