import { action, makeObservable, observable, runInAction, toJS } from "mobx";
import { Cookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  createTargetSetting,
  fetchTargetSetting,
} from "../API/api.targetSetting";

class Target {
  selectedIndex = 0;
  selectedTargetData = null;
  currentBuildingId = null;
  settings = [];
  selectedBaselineYear = "";
  selectedTargetYear = "";
  settingTable = [];
  openModal = false;
  dataSource = null;
  unit = null;

  targetSettingHeaders = [
    { value: "Data Source", type: null },
    { value: "Baseline (2019)", type: "baselineSelectBox" },
    { value: "Target (2022)", type: "targetSelectBox" },
    { value: "Unit", type: null },
  ];
  baseLineOption = [
    { label: "Baseline (2019)", value: "Baseline (2019)" },
    { label: "Baseline (2020)", value: "Baseline (2020)" },
    { label: "Baseline (2021)", value: "Baseline (2021)" },
  ];

  targetOption = [
    { label: "Target (2022)", value: "Target (2022)" },
    { label: "Target (2023)", value: "Target (2023)" },
    { label: "Target (2024)", value: "Target (2024)" },
    { label: "Target (2025)", value: "Target (2025)" },
  ];
  constructor() {

    makeObservable(this, {
      selectedIndex: observable,
      selectedTargetData: observable,
      currentBuildingId: observable,
      targetSettingHeaders: observable,
      settings: observable,
      selectedBaselineYear: observable,
      selectedTargetYear: observable,
      settingTable: observable,
      openModal: observable,
      dataSource: observable,
      unit: observable,

      _handlePageClick: action.bound,
      _handleCurrentBuildingId: action.bound,
      _handleTargetChange: action.bound,
      _handleHeaderChange: action.bound,
      _handleAddSetting: action.bound,
      _createTargetSetting: action.bound,
      _fetchTargetSetting: action.bound,
      _handleRemoveSetting: action.bound,
      _handleSettingData: action.bound,
      _goToTargetSetting: action.bound,
      _handleCloseTarget: action.bound,
      _handleAddDataSource: action.bound
    });
  }

  _handleSettingData = () => {
    this.settings = [];
    this.settingTable.map((value) => {
      this.settings.push({
        "_id": value._id,
        "dataSource": value.dataSource,
        "baselineValue": value.baselineValue,
        "targetValue": value.targetValue,
        "unit": value.unit,
        "targetYear": value.targetYear,
        "baselineYear": value.baselineYear,
        "targetPercent": value.targetPercent,
      })
    })
    this.dataSource = null;
    // if(this.settings.length === 0){
    //   this.settings = [defaultData];
    // }
  }

  _handleRemoveSetting = (index) => {
    this.settings.splice(index, 1);
  }

  _handleAddSetting = () => {
    this.settings.push(defaultData);
  }

  _handlePageClick(buildingId, index) {
    runInAction(() => {
      this._fetchTargetSetting(buildingId);
      this.currentBuildingId = buildingId;
      this.selectedIndex = index || 0;
      this.selectedTargetData = dummyTargetData[0];
    });
  }

  _handleCurrentBuildingId(id) {
    runInAction(() => {
      this.currentBuildingId = id;
      if (id != "0") {
        this._fetchTargetSetting(id);
      }
    });
  }

  _handleCloseTarget() {
    this.dataSource = null;
  }

  _handleTargetChange(index, key, e) {
    // for input box => e.target.value
    // for select box => e.value
    runInAction(() => {
      if (key === "dataSource") {
        this.dataSource = e.value;
      } else if (key === "baseline") {
        this.settings[index].baselineValue = e.target.value;
      } else if (key === "target") {
        this.settings[index].targetValue = e.target.value;
      } else if (key === "unit") {
        this.unit = e.value;
      } else if (key === "baselineYear") {
        this.settings[index].baselineYear = e.value;
      } else if (key === "dataSourceText") {
        this.dataSource = e.target.value;
        // this.settings[index].dataSource = e.target.value;
        // const obj = {...defaultData, dataSource: e.target.value};
        // this.settings[index] = obj;
      } else if (key === "targetYear") {
        this.settings[index].targetYear = e.value;
      }
      else if (key === "targetPercent") {
        this.settings[index].targetPercent = e.target.value;
      }
    });
  }

  _handleAddDataSource() {
    const temp = [...this.settings];
    const findMatchDataSrc = temp.find(v => v.dataSource === this.dataSource);
    if (findMatchDataSrc) {
      toast.warn(`${this.dataSource} is already existed in table list.`);
    } else {
      const obj = { ...defaultData, dataSource: toJS(this.dataSource), unit: toJS(this.unit) };
      temp.push(obj);
      runInAction(() => {
        this.settings = temp;
        this.dataSource = null;
        this.unit = null;
      });
    }
  }

  _handleHeaderChange = (v, e) => {
    v === "baseline"
      ? (this.selectedBaselineYear = e.value)
      : (this.selectedTargetYear = e.value);
  };

  _fetchTargetSetting = (buildingId) => {
    if (buildingId && buildingId != "0") {
      fetchTargetSetting(buildingId, (err, data) => {
        if (err) {
          toast.error(err);
        } else {
          if (data.error) {
            toast.error(data.error);
          } else {
            this.settings = [];
            this.settingTable = [];
            if (data.payload) {
              let result = data.payload;
              this.selectedTargetYear = result.targetYear;
              result.settings.map((value) => {
                this.settings.push({
                  "_id": value._id,
                  "dataSource": value.dataSource,
                  "baselineValue": value.baselineValue,
                  "targetValue": value.targetValue,
                  "unit": value.unit,
                  "targetYear": value.targetYear,
                  "baselineYear": value.baselineYear,
                  "targetPercent": value.targetPercent
                })
              })

              result.settings.map((value) => {
                this.settingTable.push({
                  "_id": value._id,
                  "dataSource": value.dataSource,
                  "baselineValue": value.baselineValue,
                  "targetValue": value.targetValue,
                  "unit": value.unit,
                  "targetYear": value.targetYear,
                  "baselineYear": value.baselineYear,
                  "targetPercent": value.targetPercent,
                  "image": dataAndImage.filter((v) => v.data === value.dataSource).length > 0 ?
                    (dataAndImage.filter((v) => v.data === value.dataSource)[0].image) : "target.png"
                })
              })
            }

            // else {
            //   this.settings.push(defaultData);
            // }
          }
        }
      })
    }
  }

  //targetPercent default value
  // Water   5%			0.95	 
  // Scope1   5%			0.95
  // Scope2	 80%			0.20	0.95 for syscom
  // Scope3   1%			0.99
  // Energy Consumption	80%	0.20

  _createTargetSetting = () => {
    const defaultVals = [
      {dataSource: 'Energy Consumption', val: '20'},
      {dataSource: 'Water Consumption', val: '5'},
      {dataSource: 'Waste Recycling', val: '20'},
      {dataSource: 'Scope1 Emission', val: '5'},
      {dataSource: 'Scope2 Emission', val: '20'},
      {dataSource: 'Scope3 Emission', val: '1'},

    ]
    let targetSetting = {
      "buildingId": this.currentBuildingId,
      "settings": this.settings?.map(v=> {
        return({
          ...v,
          targetPercent: (v.targetPercent === "" || !v.targetPercent)
                          ?(defaultVals?.find(dt=> dt.dataSource === v.dataSource)?.val)
                          : v.targetPercent
        })
      })
    }
    createTargetSetting(targetSetting, (err, data) => {
      if (err) {
        toast.error(err)
      } else {
        if (!data.error) {
          toast.success(data.payload);
          this._fetchTargetSetting(this.currentBuildingId);
          this._handleCloseTarget();
          window.location.reload();
        } else {
          toast.error(data.error);
        }
      }
    })
  }
  _goToTargetSetting = (navigate, buildingName) => {
    navigate('/target-setting?buildingName=' + encodeURIComponent(buildingName));
  }
}

const defaultData = {
  _id: "0",
  dataSource: "",
  baselineValue: 0,
  targetValue: 0,
  unit: "",
  baselineYear: "",
  targetYear: "",
  targetPercent: ""
}

const dataAndImage = [
  { data: "Energy Consumption", image: "Energy.svg" },
  { data: "Water Consumption", image: "water.svg" },
  { data: "Carbon Emission", image: "co2.svg" },
  { data: "Waste Recycling", image: "recycle.svg" },
]


export default new Target();

const cookies = new Cookies();
const siteName = cookies.get("userName");

const dummyTargetData = [
  {
    buildingName: "Conard",
    targetSettingData:
      siteName === "ISCA Trial"
        ? [
          {
            dataSource: "Energy Consumption",
            baseLine: "0",
            target: "",
            targetPercent: "",
            image: "Energy.svg",
            progressTarget: 0,
            unit: "MWH",
          },
          {
            dataSource: "Water Consumption",
            baseLine: "0",
            target: "",
            targetPercent: "",
            image: "water.svg",
            progressTarget: 0,
            unit: "L",

          },
        ]
        : [
          {
            dataSource: "Energy Consumption",
            baseLine: "320.15",
            target: "",
            targetPercent: "",
            image: "Energy.svg",
            progressTarget: 40,
            unit: "MWH",

          },
          {
            dataSource: "Water Consumption",
            baseLine: "320.15",
            target: "",
            targetPercent: "",
            image: "water.svg",
            progressTarget: 30,
            unit: "L",

          },
          {
            dataSource: "Carbon Emission",
            baseLine: "320.15",
            target: "",
            targetPercent: "",
            image: "co2.svg",
            progressTarget: 20,
            unit: "tonnes",

          },
          {
            dataSource: "Waste Recycling",
            baseLine: "320.15",
            target: "",
            targetPercent: "",
            image: "recycle.svg",
            progressTarget: 10,
            unit: "tonnes",

          },
        ],
    construction:
      siteName === "King Wan" || siteName === "ISCA Trial"
        ? [
          { year: "2019", progress: 0, isCurrent: false },
          { year: "2022", progress: 0, isCurrent: true },
        ]
        : [
          { year: "2019", progress: "10", isCurrent: false },
          { year: "2022", progress: "20", isCurrent: true },
        ],
  },
  {
    buildingName: "RCMS",
    targetSettingData: [
      {
        dataSource: "Energy Consumption",
        baseLine: "220.15",
        target: "",
        targetPercent: "",
        image: "Energy.svg",
        progressTarget: 10,
        unit: "MWH",

      },
      {
        dataSource: "Water Consumption",
        baseLine: "520.15",
        target: "",
        targetPercent: "",
        image: "water.svg",
        progressTarget: 20,
        unit: "L",

      },
      {
        dataSource: "Carbon Emission",
        baseLine: "220.15",
        target: "",
        targetPercent: "",
        image: "co2.svg",
        progressTarget: 30,
        unit: "tonnes",

      },
      {
        dataSource: "Waste Recycling",
        baseLine: "520.15",
        target: "",
        targetPercent: "",
        image: "recycle.svg",
        progressTarget: 40,
        unit: "tonnes",

      },
    ],
    construction:
      siteName === "King Wan" || siteName === "ISCA Trial"
        ? [
          { year: "2019", progress: 0, isCurrent: false },
          { year: "2022", progress: 0, isCurrent: true },
        ]
        : [
          { year: "2019", progress: "30", isCurrent: false },
          { year: "2022", progress: "50", isCurrent: true },
        ],
  },
  {
    buildingName: "Conard",
    targetSettingData:
      siteName === "King Wan" || siteName === "ISCA Trial"
        ? [
          {
            dataSource: "Energy Consumption",
            baseLine: 0,
            target: "",
            targetPercent: "",
            image: "Energy.svg",
            progressTarget: 0,
            unit: "MWH",

          },
          {
            dataSource: "Water Consumption",
            baseLine: 0,
            target: "",
            targetPercent: "",
            image: "water.svg",
            progressTarget: 0,
            unit: "L",

          },
        ]
        : [
          {
            dataSource: "Energy Consumption",
            baseLine: "320.15",
            target: "",
            targetPercent: "",
            image: "Energy.svg",
            progressTarget: 40,
            unit: "MWH",

          },
          {
            dataSource: "Water Consumption",
            baseLine: "320.15",
            target: "",
            targetPercent: "",
            image: "water.svg",
            progressTarget: 30,
            unit: "L",

          },
          {
            dataSource: "Carbon Emission",
            baseLine: "320.15",
            target: "",
            targetPercent: "",
            image: "co2.svg",
            progressTarget: 20,
            unit: "tonnes",

          },
          {
            dataSource: "Waste Recycling",
            baseLine: "320.15",
            target: "",
            targetPercent: "",
            image: "recycle.svg",
            progressTarget: 10,
            unit: "tonnes",

          },
        ],
    construction:
      siteName === "King Wan" || siteName === "ISCA Trial"
        ? [
          { year: "2019", progress: 0, isCurrent: false },
          { year: "2022", progress: 0, isCurrent: true },
        ]
        : [
          { year: "2019", progress: "10", isCurrent: false },
          { year: "2022", progress: "20", isCurrent: true },
        ],
  },
  {
    buildingName: "RCMS",
    targetSettingData: [
      {
        dataSource: "Energy Consumption",
        baseLine: "220.15",
        target: "",
        targetPercent: "",
        image: "Energy.svg",
        progressTarget: 10,
        unit: "MWH",

      },
      {
        dataSource: "Water Consumption",
        baseLine: "520.15",
        target: "",
        targetPercent: "",
        image: "water.svg",
        progressTarget: 20,
        unit: "L",

      },
      {
        dataSource: "Carbon Emission",
        baseLine: "220.15",
        target: "",
        targetPercent: "",
        image: "co2.svg",
        progressTarget: 30,
        unit: "tonnes",

      },
      {
        dataSource: "Waste Recycling",
        baseLine: "520.15",
        target: "",
        targetPercent: "",
        image: "recycle.svg",
        progressTarget: 40,
        unit: "tonnes",

      },
    ],
    construction:
      siteName === "King Wan" || siteName === "ISCA Trial"
        ? [
          { year: "2019", progress: 0, isCurrent: false },
          { year: "2022", progress: 0, isCurrent: true },
        ]
        : [
          { year: "2019", progress: "30", isCurrent: false },
          { year: "2022", progress: "50", isCurrent: true },
        ],
  },
  {
    buildingName: "Conard",
    targetSettingData:
      siteName === "King Wan" || siteName === "ISCA Trial"
        ? [
          {
            dataSource: "Energy Consumption",
            baseLine: 0,
            target: "",
            targetPercent: "",
            image: "Energy.svg",
            progressTarget: 0,
            unit: "MWH",
          },
          {
            dataSource: "Water Consumption",
            baseLine: 0,
            target: "",
            targetPercent: "",
            image: "water.svg",
            progressTarget: 0,
            unit: "L",
          },
        ]
        : [
          {
            dataSource: "Energy Consumption",
            baseLine: "320.15",
            target: "",
            targetPercent: "",
            image: "Energy.svg",
            progressTarget: 40,
            unit: "MWH",

          },
          {
            dataSource: "Water Consumption",
            baseLine: "320.15",
            target: "",
            targetPercent: "",
            image: "water.svg",
            progressTarget: 30,
            unit: "L",

          },
          {
            dataSource: "Carbon Emission",
            baseLine: "320.15",
            target: "",
            targetPercent: "",
            image: "co2.svg",
            progressTarget: 20,
            unit: "tonnes",

          },
          {
            dataSource: "Waste Recycling",
            baseLine: "320.15",
            target: "",
            targetPercent: "",
            image: "recycle.svg",
            progressTarget: 10,
            unit: "tonnes",

          },
        ],
    construction:
      siteName === "King Wan" || siteName === "ISCA Trial"
        ? [
          { year: "2019", progress: 0, isCurrent: false },
          { year: "2022", progress: 0, isCurrent: true },
        ]
        : [
          { year: "2019", progress: "10", isCurrent: false },
          { year: "2022", progress: "20", isCurrent: true },
        ],
  },
  {
    buildingName: "RCMS",
    targetSettingData: [
      {
        dataSource: "Energy Consumption",
        baseLine: "220.15",
        target: "",
        targetPercent: "",
        image: "Energy.svg",
        progressTarget: 10,
        unit: "MWH",

      },
      {
        dataSource: "Water Consumption",
        baseLine: "520.15",
        target: "",
        targetPercent: "",
        image: "water.svg",
        progressTarget: 20,
        unit: "L",

      },
      {
        dataSource: "Carbon Emission",
        baseLine: "220.15",
        target: "",
        targetPercent: "",
        image: "co2.svg",
        progressTarget: 30,
        unit: "tonnes",

      },
      {
        dataSource: "Waste Recycling",
        baseLine: "520.15",
        target: "",
        targetPercent: "",
        image: "recycle.svg",
        progressTarget: 40,
        unit: "tonnes",

      },
    ],
    construction:
      siteName === "King Wan" || siteName === "ISCA Trial"
        ? [
          { year: "2019", progress: 0, isCurrent: false },
          { year: "2022", progress: 0, isCurrent: true },
        ]
        : [
          { year: "2019", progress: "30", isCurrent: false },
          { year: "2022", progress: "50", isCurrent: true },
        ],
  },
  {
    buildingName: "Conard",
    targetSettingData:
      siteName === "King Wan" || siteName === "ISCA Trial"
        ? [
          {
            dataSource: "Energy Consumption",
            baseLine: 0,
            target: "",
            targetPercent: "",
            image: "Energy.svg",
            progressTarget: 0,
            unit: "MWH",

          },
          {
            dataSource: "Water Consumption",
            baseLine: 0,
            target: "",
            targetPercent: "",
            image: "water.svg",
            progressTarget: 0,
            unit: "L",

          },
        ]
        : [
          {
            dataSource: "Energy Consumption",
            baseLine: "320.15",
            target: "",
            targetPercent: "",
            image: "Energy.svg",
            progressTarget: 40,
            unit: "MWH",
          },
          {
            dataSource: "Water Consumption",
            baseLine: "320.15",
            target: "",
            targetPercent: "",
            image: "water.svg",
            progressTarget: 30,
            unit: "L",
          },
          {
            dataSource: "Carbon Emission",
            baseLine: "320.15",
            target: "",
            targetPercent: "",
            image: "co2.svg",
            progressTarget: 20,
            unit: "tonnes",
          },
          {
            dataSource: "Waste Recycling",
            baseLine: "320.15",
            target: "",
            targetPercent: "",
            image: "recycle.svg",
            progressTarget: 10,
            unit: "tonnes",
          },
        ],
    construction:
      siteName === "King Wan" || siteName === "ISCA Trial"
        ? [
          { year: "2019", progress: 0, isCurrent: false },
          { year: "2022", progress: 0, isCurrent: true },
        ]
        : [
          { year: "2019", progress: "10", isCurrent: false },
          { year: "2022", progress: "20", isCurrent: true },
        ],
  },
  {
    buildingName: "RCMS",
    targetSettingData: [
      {
        dataSource: "Energy Consumption",
        baseLine: "220.15",
        target: "",
        targetPercent: "",
        image: "Energy.svg",
        progressTarget: 10,
        unit: "MWH",
      },
      {
        dataSource: "Water Consumption",
        baseLine: "520.15",
        target: "",
        targetPercent: "",
        image: "water.svg",
        progressTarget: 20,
        unit: "L",
      },
      {
        dataSource: "Carbon Emission",
        baseLine: "220.15",
        target: "",
        targetPercent: "",
        image: "co2.svg",
        progressTarget: 30,
        unit: "tonnes",
      },
      {
        dataSource: "Waste Recycling",
        baseLine: "520.15",
        target: "",
        targetPercent: "",
        image: "recycle.svg",
        progressTarget: 40,
        unit: "tonnes",
      },
    ],
    construction:
      siteName === "King Wan" || siteName === "ISCA Trial"
        ? [
          { year: "2019", progress: 0, isCurrent: false },
          { year: "2022", progress: 0, isCurrent: true },
        ]
        : [
          { year: "2019", progress: "30", isCurrent: false },
          { year: "2022", progress: "50", isCurrent: true },
        ],
  },
  {
    buildingName: "Conard",
    targetSettingData:
      siteName === "King Wan" || siteName === "ISCA Trial"
        ? [
          {
            dataSource: "Energy Consumption",
            baseLine: 0,
            target: "",
            targetPercent: "",
            image: "Energy.svg",
            progressTarget: 0,
            unit: "MWH",
          },
          {
            dataSource: "Water Consumption",
            baseLine: 0,
            target: "",
            targetPercent: "",
            image: "water.svg",
            progressTarget: 0,
            unit: "L",
          },
        ]
        : [
          {
            dataSource: "Energy Consumption",
            baseLine: "320.15",
            target: "",
            targetPercent: "",
            image: "Energy.svg",
            progressTarget: 40,
            unit: "MWH",
          },
          {
            dataSource: "Water Consumption",
            baseLine: "320.15",
            target: "",
            targetPercent: "",
            image: "water.svg",
            progressTarget: 30,
            unit: "L",
          },
          {
            dataSource: "Carbon Emission",
            baseLine: "320.15",
            target: "",
            targetPercent: "",
            image: "co2.svg",
            progressTarget: 20,
            unit: "tonnes",
          },
          {
            dataSource: "Waste Recycling",
            baseLine: "320.15",
            target: "",
            targetPercent: "",
            image: "recycle.svg",
            progressTarget: 10,
            unit: "tonnes",
          },
        ],
    construction:
      siteName === "King Wan" || siteName === "ISCA Trial"
        ? [
          { year: "2019", progress: 0, isCurrent: false },
          { year: "2022", progress: 0, isCurrent: true },
        ]
        : [
          { year: "2019", progress: "10", isCurrent: false },
          { year: "2022", progress: "20", isCurrent: true },
        ],
  },
  {
    buildingName: "RCMS",
    targetSettingData: [
      {
        dataSource: "Energy Consumption",
        baseLine: "220.15",
        target: "",
        targetPercent: "",
        image: "Energy.svg",
        progressTarget: 10,
        unit: "MWH",
      },
      {
        dataSource: "Water Consumption",
        baseLine: "520.15",
        target: "",
        targetPercent: "",
        image: "water.svg",
        progressTarget: 20,
        unit: "L",
      },
      {
        dataSource: "Carbon Emission",
        baseLine: "220.15",
        target: "",
        targetPercent: "",
        image: "co2.svg",
        progressTarget: 30,
        unit: "tonnes",
      },
      {
        dataSource: "Waste Recycling",
        baseLine: "520.15",
        target: "",
        targetPercent: "",
        image: "recycle.svg",
        progressTarget: 40,
        unit: "tonnes",
      },
    ],
    construction:
      siteName === "King Wan" || siteName === "ISCA Trial"
        ? [
          { year: "2019", progress: 0, isCurrent: false },
          { year: "2022", progress: 0, isCurrent: true },
        ]
        : [
          { year: "2019", progress: "30", isCurrent: false },
          { year: "2022", progress: "50", isCurrent: true },
        ],
  },
  {
    buildingName: "Conard",
    targetSettingData:
      siteName === "King Wan" || siteName === "ISCA Trial"
        ? [
          {
            dataSource: "Energy Consumption",
            baseLine: 0,
            target: "",
            targetPercent: "",
            image: "Energy.svg",
            progressTarget: 0,
            unit: "MWH",
          },
          {
            dataSource: "Water Consumption",
            baseLine: 0,
            target: "",
            targetPercent: "",
            image: "water.svg",
            progressTarget: 0,
            unit: "L",
          },
        ]
        : [
          {
            dataSource: "Energy Consumption",
            baseLine: "320.15",
            target: "",
            targetPercent: "",
            image: "Energy.svg",
            progressTarget: 40,
            unit: "MWH",
          },
          {
            dataSource: "Water Consumption",
            baseLine: "320.15",
            target: "",
            targetPercent: "",
            image: "water.svg",
            progressTarget: 30,
            unit: "L",
          },
          {
            dataSource: "Carbon Emission",
            baseLine: "320.15",
            target: "",
            targetPercent: "",
            image: "co2.svg",
            progressTarget: 20,
            unit: "tonnes",
          },
          {
            dataSource: "Waste Recycling",
            baseLine: "320.15",
            target: "",
            targetPercent: "",
            image: "recycle.svg",
            progressTarget: 10,
            unit: "tonnes",
          },
        ],
    construction:
      siteName === "King Wan" || siteName === "ISCA Trial"
        ? [
          { year: "2019", progress: 0, isCurrent: false },
          { year: "2022", progress: 0, isCurrent: true },
        ]
        : [
          { year: "2019", progress: "10", isCurrent: false },
          { year: "2022", progress: "20", isCurrent: true },
        ],
  },
  {
    buildingName: "RCMS",
    targetSettingData: [
      {
        dataSource: "Energy Consumption",
        baseLine: "220.15",
        target: "",
        targetPercent: "",
        image: "Energy.svg",
        progressTarget: 10,
        unit: "MWH",
      },
      {
        dataSource: "Water Consumption",
        baseLine: "520.15",
        target: "",
        targetPercent: "",
        image: "water.svg",
        progressTarget: 20,
        unit: "L",
      },
      {
        dataSource: "Carbon Emission",
        baseLine: "220.15",
        target: "",
        targetPercent: "",
        image: "co2.svg",
        progressTarget: 30,
        unit: "tonnes",
      },
      {
        dataSource: "Waste Recycling",
        baseLine: "520.15",
        target: "",
        targetPercent: "",
        image: "recycle.svg",
        progressTarget: 40,
        unit: "tonnes",
      },
    ],
    construction:
      siteName === "King Wan" || siteName === "ISCA Trial"
        ? [
          { year: "2019", progress: 0, isCurrent: false },
          { year: "2022", progress: 0, isCurrent: true },
        ]
        : [
          { year: "2019", progress: "30", isCurrent: false },
          { year: "2022", progress: "50", isCurrent: true },
        ],
  },
  {
    buildingName: "Conard",
    targetSettingData:
      siteName === "King Wan" || siteName === "ISCA Trial"
        ? [
          {
            dataSource: "Energy Consumption",
            baseLine: "320.15",
            target: "",
            targetPercent: "",
            image: "Energy.svg",
            progressTarget: 40,
            unit: "MWH",
          },
          {
            dataSource: "Water Consumption",
            baseLine: "320.15",
            target: "",
            targetPercent: "",
            image: "water.svg",
            progressTarget: 30,
            unit: "tonnes",
          },
        ]
        : [
          {
            dataSource: "Energy Consumption",
            baseLine: "320.15",
            target: "",
            targetPercent: "",
            image: "Energy.svg",
            progressTarget: 40,
            unit: "MWH",
          },
          {
            dataSource: "Water Consumption",
            baseLine: "320.15",
            target: "",
            targetPercent: "",
            image: "water.svg",
            progressTarget: 30,
            unit: "L",
          },
          {
            dataSource: "Carbon Emission",
            baseLine: "320.15",
            target: "",
            targetPercent: "",
            image: "co2.svg",
            progressTarget: 20,
            unit: "tonnes",
          },
          {
            dataSource: "Waste Recycling",
            baseLine: "320.15",
            target: "",
            targetPercent: "",
            image: "recycle.svg",
            progressTarget: 10,
            unit: "tonnes",
          },
        ],
    construction:
      siteName === "King Wan" || siteName === "ISCA Trial"
        ? [
          { year: "2019", progress: 0, isCurrent: false },
          { year: "2022", progress: 0, isCurrent: true },
        ]
        : [
          { year: "2019", progress: "10", isCurrent: false },
          { year: "2022", progress: "20", isCurrent: true },
        ],
  },
  {
    buildingName: "RCMS",
    targetSettingData: [
      {
        dataSource: "Energy Consumption",
        baseLine: "220.15",
        target: "",
        targetPercent: "",
        image: "Energy.svg",
        progressTarget: 10,
        unit: "MWH",
      },
      {
        dataSource: "Water Consumption",
        baseLine: "520.15",
        target: "",
        targetPercent: "",
        image: "water.svg",
        progressTarget: 20,
        unit: "L",
      },
      {
        dataSource: "Carbon Emission",
        baseLine: "220.15",
        target: "",
        targetPercent: "",
        image: "co2.svg",
        progressTarget: 30,
        unit: "tonnes",
      },
      {
        dataSource: "Waste Recycling",
        baseLine: "520.15",
        target: "",
        targetPercent: "",
        image: "recycle.svg",
        progressTarget: 40,
        unit: "tonnes",
      },
    ],
    construction:
      siteName === "King Wan" || siteName === "ISCA Trial"
        ? [
          { year: "2019", progress: 0, isCurrent: false },
          { year: "2022", progress: 0, isCurrent: true },
        ]
        : [
          { year: "2019", progress: "30", isCurrent: false },
          { year: "2022", progress: "50", isCurrent: true },
        ],
  },
  {
    buildingName: "Conard",
    targetSettingData:
      siteName === "King Wan" || siteName === "ISCA Trial"
        ? [
          {
            dataSource: "Energy Consumption",
            baseLine: 0,
            target: "",
            targetPercent: "",
            image: "Energy.svg",
            progressTarget: 0,
            unit: "MWH",
          },
          {
            dataSource: "Water Consumption",
            baseLine: 0,
            target: "",
            targetPercent: "",
            image: "water.svg",
            progressTarget: 0,
            unit: "L",
          },
        ]
        : [
          {
            dataSource: "Energy Consumption",
            baseLine: "320.15",
            target: "",
            targetPercent: "",
            image: "Energy.svg",
            progressTarget: 40,
            unit: "MWH",
          },
          {
            dataSource: "Water Consumption",
            baseLine: "320.15",
            target: "",
            targetPercent: "",
            image: "water.svg",
            progressTarget: 30,
            unit: "L",
          },
          {
            dataSource: "Carbon Emission",
            baseLine: "320.15",
            target: "",
            targetPercent: "",
            image: "co2.svg",
            progressTarget: 20,
            unit: "tonnes",
          },
          {
            dataSource: "Waste Recycling",
            baseLine: "320.15",
            target: "",
            targetPercent: "",
            image: "recycle.svg",
            progressTarget: 10,
            unit: "tonnes",
          },
        ],
    construction:
      siteName === "King Wan" || siteName === "ISCA Trial"
        ? [
          { year: "2019", progress: 0, isCurrent: false },
          { year: "2022", progress: 0, isCurrent: true },
        ]
        : [
          { year: "2019", progress: "10", isCurrent: false },
          { year: "2022", progress: "20", isCurrent: true },
        ],
  },
  {
    buildingName: "RCMS",
    targetSettingData: [
      {
        dataSource: "Energy Consumption",
        baseLine: "220.15",
        target: "",
        targetPercent: "",
        image: "Energy.svg",
        progressTarget: 10,
        unit: "MWH",
      },
      {
        dataSource: "Water Consumption",
        baseLine: "520.15",
        target: "",
        targetPercent: "",
        image: "water.svg",
        progressTarget: 20,
        unit: "L",
      },
      {
        dataSource: "Carbon Emission",
        baseLine: "220.15",
        target: "",
        targetPercent: "",
        image: "co2.svg",
        progressTarget: 30,
        unit: "tonnes",
      },
      {
        dataSource: "Waste Recycling",
        baseLine: "520.15",
        target: "",
        targetPercent: "",
        image: "recycle.svg",
        progressTarget: 40,
        unit: "tonnes",
      },
    ],
    construction:
      siteName === "King Wan" || siteName === "ISCA Trial"
        ? [
          { year: "2019", progress: 0, isCurrent: false },
          { year: "2022", progress: 0, isCurrent: true },
        ]
        : [
          { year: "2019", progress: "30", isCurrent: false },
          { year: "2022", progress: "50", isCurrent: true },
        ],
  },
  {
    buildingName: "Conard",
    targetSettingData:
      siteName === "King Wan" || siteName === "ISCA Trial"
        ? [
          {
            dataSource: "Energy Consumption",
            baseLine: 0,
            target: "",
            targetPercent: "",
            image: "Energy.svg",
            progressTarget: 0,
            unit: "MWH",
          },
          {
            dataSource: "Water Consumption",
            baseLine: 0,
            target: "",
            targetPercent: "",
            image: "water.svg",
            progressTarget: 0,
            unit: "L",
          },
        ]
        : [
          {
            dataSource: "Energy Consumption",
            baseLine: "320.15",
            target: "",
            targetPercent: "",
            image: "Energy.svg",
            progressTarget: 40,
            unit: "MWH",
          },
          {
            dataSource: "Water Consumption",
            baseLine: "320.15",
            target: "",
            targetPercent: "",
            image: "water.svg",
            progressTarget: 30,
            unit: "L",
          },
          {
            dataSource: "Carbon Emission",
            baseLine: "320.15",
            target: "",
            targetPercent: "",
            image: "co2.svg",
            progressTarget: 20,
            unit: "tonnes",
          },
          {
            dataSource: "Waste Recycling",
            baseLine: "320.15",
            target: "",
            targetPercent: "",
            image: "recycle.svg",
            progressTarget: 10,
            unit: "tonnes",
          },
        ],
    construction:
      siteName === "King Wan" || siteName === "ISCA Trial"
        ? [
          { year: "2019", progress: 0, isCurrent: false },
          { year: "2022", progress: 0, isCurrent: true },
        ]
        : [
          { year: "2019", progress: "10", isCurrent: false },
          { year: "2022", progress: "20", isCurrent: true },
        ],
  },
  {
    buildingName: "RCMS",
    targetSettingData: [
      {
        dataSource: "Energy Consumption",
        baseLine: "220.15",
        target: "",
        targetPercent: "",
        image: "Energy.svg",
        progressTarget: 10,
        unit: "MWH",
      },
      {
        dataSource: "Water Consumption",
        baseLine: "520.15",
        target: "",
        targetPercent: "",
        image: "water.svg",
        progressTarget: 20,
        unit: "L",
      },
      {
        dataSource: "Carbon Emission",
        baseLine: "220.15",
        target: "",
        targetPercent: "",
        image: "co2.svg",
        progressTarget: 30,
        unit: "tonnes",
      },
      {
        dataSource: "Waste Recycling",
        baseLine: "520.15",
        target: "",
        targetPercent: "",
        image: "recycle.svg",
        progressTarget: 40,
        unit: "tonnes",
      },
    ],
    construction:
      siteName === "King Wan" || siteName === "ISCA Trial"
        ? [
          { year: "2019", progress: 0, isCurrent: false },
          { year: "2022", progress: 0, isCurrent: true },
        ]
        : [
          { year: "2019", progress: "30", isCurrent: false },
          { year: "2022", progress: "50", isCurrent: true },
        ],
  },
  {
    buildingName: "Conard",
    targetSettingData:
      siteName === "King Wan" || siteName === "ISCA Trial"
        ? [
          {
            dataSource: "Energy Consumption",
            baseLine: 0,
            target: "",
            targetPercent: "",
            image: "Energy.svg",
            progressTarget: 0,
            unit: "MWH",
          },
          {
            dataSource: "Water Consumption",
            baseLine: 0,
            target: "",
            targetPercent: "",
            image: "water.svg",
            progressTarget: 0,
            unit: "L",
          },
        ]
        : [
          {
            dataSource: "Energy Consumption",
            baseLine: "320.15",
            target: "",
            targetPercent: "",
            image: "Energy.svg",
            progressTarget: 40,
            unit: "MWH",
          },
          {
            dataSource: "Water Consumption",
            baseLine: "320.15",
            target: "",
            targetPercent: "",
            image: "water.svg",
            progressTarget: 30,
            unit: "L",
          },
          {
            dataSource: "Carbon Emission",
            baseLine: "320.15",
            target: "",
            targetPercent: "",
            image: "co2.svg",
            progressTarget: 20,
            unit: "tonnes",
          },
          {
            dataSource: "Waste Recycling",
            baseLine: "320.15",
            target: "",
            targetPercent: "",
            image: "recycle.svg",
            progressTarget: 10,
            unit: "tonnes",
          },
        ],
    construction:
      siteName === "King Wan" || siteName === "ISCA Trial"
        ? [
          { year: "2019", progress: 0, isCurrent: false },
          { year: "2022", progress: 0, isCurrent: true },
        ]
        : [
          { year: "2019", progress: "10", isCurrent: false },
          { year: "2022", progress: "20", isCurrent: true },
        ],
  },
  {
    buildingName: "RCMS",
    targetSettingData: [
      {
        dataSource: "Energy Consumption",
        baseLine: "220.15",
        target: "",
        targetPercent: "",
        image: "Energy.svg",
        progressTarget: 10,
        unit: "MWH",
      },
      {
        dataSource: "Water Consumption",
        baseLine: "520.15",
        target: "",
        targetPercent: "",
        image: "water.svg",
        progressTarget: 20,
        unit: "L",
      },
      {
        dataSource: "Carbon Emission",
        baseLine: "220.15",
        target: "",
        targetPercent: "",
        image: "co2.svg",
        progressTarget: 30,
        unit: "tonnes",
      },
      {
        dataSource: "Waste Recycling",
        baseLine: "520.15",
        target: "",
        targetPercent: "",
        image: "recycle.svg",
        progressTarget: 40,
        unit: "tonnes",
      },
    ],
    construction:
      siteName === "King Wan" || siteName === "ISCA Trial"
        ? [
          { year: "2019", progress: 0, isCurrent: false },
          { year: "2022", progress: 0, isCurrent: true },
        ]
        : [
          { year: "2019", progress: "30", isCurrent: false },
          { year: "2022", progress: "50", isCurrent: true },
        ],
  },
  {
    buildingName: "Conard",
    targetSettingData:
      siteName === "King Wan" || siteName === "ISCA Trial"
        ? [
          {
            dataSource: "Energy Consumption",
            baseLine: 0,
            target: "",
            targetPercent: "",
            image: "Energy.svg",
            progressTarget: 0,
            unit: "MWH",
          },
          {
            dataSource: "Water Consumption",
            baseLine: 0,
            target: "",
            targetPercent: "",
            image: "water.svg",
            progressTarget: 0,
            unit: "L",
          },
        ]
        : [
          {
            dataSource: "Energy Consumption",
            baseLine: "320.15",
            target: "",
            targetPercent: "",
            image: "Energy.svg",
            progressTarget: 40,
            unit: "MWH",
          },
          {
            dataSource: "Water Consumption",
            baseLine: "320.15",
            target: "",
            targetPercent: "",
            image: "water.svg",
            progressTarget: 30,
            unit: "L",
          },
          {
            dataSource: "Carbon Emission",
            baseLine: "320.15",
            target: "",
            targetPercent: "",
            image: "co2.svg",
            progressTarget: 20,
            unit: "tonnes",
          },
          {
            dataSource: "Waste Recycling",
            baseLine: "320.15",
            target: "",
            targetPercent: "",
            image: "recycle.svg",
            progressTarget: 10,
            unit: "tonnes",
          },
        ],
    construction:
      siteName === "King Wan" || siteName === "ISCA Trial"
        ? [
          { year: "2019", progress: 0, isCurrent: false },
          { year: "2022", progress: 0, isCurrent: true },
        ]
        : [
          { year: "2019", progress: "10", isCurrent: false },
          { year: "2022", progress: "20", isCurrent: true },
        ],
  },
  {
    buildingName: "RCMS",
    targetSettingData: [
      {
        dataSource: "Energy Consumption",
        baseLine: "220.15",
        target: "",
        targetPercent: "",
        image: "Energy.svg",
        progressTarget: 10,
        unit: "MWH",
      },
      {
        dataSource: "Water Consumption",
        baseLine: "520.15",
        target: "",
        targetPercent: "",
        image: "water.svg",
        progressTarget: 20,
        unit: "L",
      },
      {
        dataSource: "Carbon Emission",
        baseLine: "220.15",
        target: "",
        targetPercent: "",
        image: "co2.svg",
        progressTarget: 30,
        unit: "tonnes",
      },
      {
        dataSource: "Waste Recycling",
        baseLine: "520.15",
        target: "",
        targetPercent: "",
        image: "recycle.svg",
        progressTarget: 40,
        unit: "tonnes",
      },
    ],
    construction:
      siteName === "King Wan" || siteName === "ISCA Trial"
        ? [
          { year: "2019", progress: 0, isCurrent: false },
          { year: "2022", progress: 0, isCurrent: true },
        ]
        : [
          { year: "2019", progress: "30", isCurrent: false },
          { year: "2022", progress: "50", isCurrent: true },
        ],
  },
  {
    buildingName: "Conard",
    targetSettingData:
      siteName === "King Wan" || siteName === "ISCA Trial"
        ? [
          {
            dataSource: "Energy Consumption",
            baseLine: 0,
            target: "",
            targetPercent: "",
            image: "Energy.svg",
            progressTarget: 0,
            unit: "MWH",
          },
          {
            dataSource: "Water Consumption",
            baseLine: 0,
            target: "",
            targetPercent: "",
            image: "water.svg",
            progressTarget: 0,
            unit: "L",
          },
        ]
        : [
          {
            dataSource: "Energy Consumption",
            baseLine: "320.15",
            target: "",
            targetPercent: "",
            image: "Energy.svg",
            progressTarget: 40,
            unit: "MWH",
          },
          {
            dataSource: "Water Consumption",
            baseLine: "320.15",
            target: "",
            targetPercent: "",
            image: "water.svg",
            progressTarget: 30,
            unit: "L",
          },
          {
            dataSource: "Carbon Emission",
            baseLine: "320.15",
            target: "",
            targetPercent: "",
            image: "co2.svg",
            progressTarget: 20,
            unit: "tonnes",
          },
          {
            dataSource: "Waste Recycling",
            baseLine: "320.15",
            target: "",
            targetPercent: "",
            image: "recycle.svg",
            progressTarget: 10,
            unit: "tonnes",
          },
        ],
    construction:
      siteName === "King Wan" || siteName === "ISCA Trial"
        ? [
          { year: "2019", progress: 0, isCurrent: false },
          { year: "2022", progress: 0, isCurrent: true },
        ]
        : [
          { year: "2019", progress: "10", isCurrent: false },
          { year: "2022", progress: "20", isCurrent: true },
        ],
  },
  {
    buildingName: "RCMS",
    targetSettingData: [
      {
        dataSource: "Energy Consumption",
        baseLine: "220.15",
        target: "",
        targetPercent: "",
        image: "Energy.svg",
        progressTarget: 10,
        unit: "MWH",
      },
      {
        dataSource: "Water Consumption",
        baseLine: "520.15",
        target: "",
        targetPercent: "",
        image: "water.svg",
        progressTarget: 20,
        unit: "L",
      },
      {
        dataSource: "Carbon Emission",
        baseLine: "220.15",
        target: "",
        targetPercent: "",
        image: "co2.svg",
        progressTarget: 30,
        unit: "tonnes",
      },
      {
        dataSource: "Waste Recycling",
        baseLine: "520.15",
        target: "",
        targetPercent: "",
        image: "recycle.svg",
        progressTarget: 40,
        unit: "tonnes",
      },
    ],
    construction:
      siteName === "King Wan" || siteName === "ISCA Trial"
        ? [
          { year: "2019", progress: 0, isCurrent: false },
          { year: "2022", progress: 0, isCurrent: true },
        ]
        : [
          { year: "2019", progress: "30", isCurrent: false },
          { year: "2022", progress: "50", isCurrent: true },
        ],
  },
];
