import { Colors } from "../../Assets/Config/color.config";
// import activityLog from "../../Assets/activityLog.json";
import Store from "../../Store";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { toJS } from "mobx";

export function ActivityLog(props) {
  const { rightArrow } = props;
  const { activityLog, theme, getActivityLog } = Store.common;
  const fixLength = 10;
  
  const { t } = useTranslation("activityLog");
  
  return (
    <div
      className="cardStyle text-center"
      style={{ color: Colors[theme].textColor }}
    >
      <span className="d-flex justify-content-between">
        <span className="cardTitle">{t("Activity Log")}</span>
        <span
          style={{ color: Colors.water, cursor: "pointer" }}
          data-bs-toggle="modal"
          data-bs-target="#activityLogModal"
          onClick={() => {
            getActivityLog({ all: true });
          }}
        >
          {t("See More")}
          <img src={rightArrow} className="ps-1" />
        </span>
      </span>
      <hr className="my-2" />

      {activityLog ? (
        activityLog?.length? (
          activityLog
            ?.slice(-fixLength)
            ?.reverse()
            .map((log, i) => (
              <span
                className="d-flex justify-content-between pb-1 text-start"
                key={i}
              >
                {log.framework ? (
                  <div>
                    {log.updatedBy +
                      " " +
                      t(`${log.type}`) +                      
                      (log.framework ? " " + log.framework + " " : " ") +
                      t(`${log.field}`)+                      
                      " of " +
                      "Section " + log.updatedValue                   
                    }
                  </div>
                ) : (
                  <div>
                    {log.updatedBy +
                      " " +
                      t(`${log.type}`) +
                      (log.framework ? " " + log.framework : "") +
                      " " +
                      log.updatedValue +
                      " " +
                      t(`${log.field}`)}
                  </div>
                )}

                <span style={{ color: Colors.Dark[3] }}>
                  {moment(new Date(log.updatedDate).toString()).format(
                    "DD-MMM-YYYY HH:MM"
                  )}
                </span>
              </span>
            ))
        ) : (
          <div>No activity found!</div>
        )
      ) : (
        <i className="spinner-border mt-2" />
      )}
    </div>
  );
}

// export function ActivityLog1(props) {
//   const { rightArrow } = props

//   return (
//     <div className="cardStyle">
//       <span className="d-flex justify-content-between">
//         <span className="cardTitle">Activity Log</span>
//         <span style={{ color: Colors.water }}>
//           See More
//           <img src={rightArrow} className="ps-1" />
//         </span>
//       </span>
//       <hr className="my-2" />
//       {activityLogKW.map((log, i) => (
//         <span className="d-flex justify-content-between pb-1" key={i}>
//           {log.siteName} fill-up site data.
//           <span style={{ color: Colors.Dark[3] }}>{log.time}</span>
//         </span>
//       ))}
//     </div>
//   );
// }

const activityLogKW = [
  {
    siteName: "User 1",
    time: "2022-02-01 16:44",
  },
  {
    siteName: "User 1",
    time: "2022-02-01 16:55",
  },
  {
    siteName: "User2",
    time: "2022-02-01 16:56",
  },
  {
    siteName: "User 1",
    time: "2022-02-01 16:57",
  },
  {
    siteName: "User2",
    time: "2022-02-01 17:44",
  },
  {
    siteName: "User 3",
    time: "2022-02-01 18:14",
  },
  {
    siteName: "User2",
    time: "2022-02-01 18:44",
  },
  {
    siteName: "User 3",
    time: "2022-02-01 19:53",
  },
  {
    siteName: "User 1",
    time: "2022-02-01 20:01",
  },
];
