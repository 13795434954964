import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from "@material-ui/core";
import { inject, observer } from "mobx-react";
import { toJS } from "mobx";
import { useTranslation } from 'react-i18next';

export const AlertDialog = inject("store")(observer((props) => {
    const {
        clickManualAction
    } = props.store.survey;
    const {
        dataDismiss,
        dataTarget,
        dataToggle,

        showDialog,
        saveMoveToNext,
        discardMoveToNext,
        onDiscardClick,

        dataBsTarget,
        dataBsToggle,
    } = props;

    const { t } = useTranslation("common");

    return (
        <div>
            <Dialog
                open={showDialog}
                onClose={onDiscardClick}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t('Do you want to keep your changes ?')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions className='justify-content-center my-2'>
                    <Button data-dismiss={dataDismiss} data-bs-toggle={dataBsToggle} data-bs-target={dataBsTarget} data-toggle={dataToggle} data-target={dataTarget} onClick={saveMoveToNext} color="primary" size="small" className="shadow">
                        {t('Yes')}
                    </Button>
                    <Button data-dismiss={dataDismiss} data-toggle={dataToggle} data-target={dataTarget} onClick={discardMoveToNext} color="primary" size="small" className="shadow">
                        {t('No')}
                    </Button>
                    {!clickManualAction && <Button onClick={onDiscardClick} color="primary" size="small" className="shadow">
                        {t('Cancel')}
                    </Button>}
                </DialogActions>
            </Dialog>
        </div>
    )
}))