import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useTranslation } from "react-i18next";
import { Colors,invertDark } from "../Assets/Config/color.config";
import "../Style/QuickOrganizationSetUp.style.css"
import { PlayCircleFilledWhite } from '@material-ui/icons';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
     // maxWidth: 200,
    },
  },
};

export const MuiSelectorWithDefaultValue = (props) => {

  const { t } = useTranslation("common");
  const {
    options,
    value,
    handleSelect,
    style,
    disabled,
    background,
    tran
  } = props;

  const theme = localStorage.getItem("theme");

  const customStyle = {
    ...style,
    background: background ? background : Colors[theme].secondBackground,
    color: theme === "dark" ? '#c6c6c6 !important' : '#303030 !important',
    minHeight: 43,
    maxHeight: 43,
    fontSize: 14,
    border: "1px solid",
    borderColor: theme === "dark"?Colors.Dark[2]:Colors.Dark[4],
    cursor: disabled ? 'not-allowed !important' : 'default',
    '.MuiFormControl-root': {
      borderColor: theme === "dark"?Colors.Dark[2]:Colors.Dark[4],
    },
    '&.Mui-focused .MuiFormControl-root': {
      borderColor: theme === "dark"?Colors.Dark[2]:Colors.Dark[4],
    },
    '&:hover .MuiFormControl-root': {
      borderColor: theme === "dark"?Colors.Dark[2]:Colors.Dark[4],
    },
    '.MuiSvgIcon-root ': {
      fill: 'rgb(4, 197, 0)',
    },
    '.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input' : {
      paddingTop : 0,
      paddingBottom: 0,
      // color: theme === "dark" ? '#c6c6c6 !important' : '#303030 !important'
    },
    '.MuiSelect-select': {
      cursor : disabled ? 'not-allowed !important' : 'default',
     
    },
    '.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled': {
      "-webkit-text-fill-color" : `${invertDark(theme)} !important`
    }
  };
  
  return (
    <div className="cus-class">
      {console.log("value==>", value)}
      <FormControl fullWidth>
        <Select
          // displayEmpty
          disabled={disabled}
          value={value? value : options[0].label}
          onChange={handleSelect}
          input={<OutlinedInput />}
          sx={customStyle}
          renderValue={(selected) => {
            if (!selected) {
              return t("Select_");
            }
            return tran ? tran(selected) : selected;
          }}
          MenuProps={MenuProps}
          inputProps={{ 'aria-label': 'Without label' }}
        >
          {options.map((option,index) => (
            <MenuItem 
              sx={{ fontSize: 14 }}
              key={index}
              value={option.label}
            >
              { tran ? tran(option.label) : option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}